import React, { useEffect, useState } from 'react';
import styles from './FilterComponent.module.css';

const formatNumber = (number) => {
  return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 0,
  }).format(number);
};

const removeDuplicates = (arr) => {
  const trimmedArray = arr.map(value => value.trim());
  return [...new Set(trimmedArray)];
};

export default function FilterComponent({ filters, onChange, filterValues }) {
  const [uniqueCities, setUniqueCities] = useState([]);
  const [uniquePropertyTypes, setUniquePropertyTypes] = useState([]);

  useEffect(() => {
    const cities = filterValues.cities || [];
    const propertyTypes = filterValues.propertyTypes || [];
    
    const formattedCities = removeDuplicates(cities).sort();
    const formattedPropertyTypes = removeDuplicates(propertyTypes).sort();
    
    setUniqueCities(formattedCities);
    setUniquePropertyTypes(formattedPropertyTypes);
  }, [filterValues]);

  return (
    <div className={styles.filters}>
      <h4>Filters</h4>
      <div className={styles.filterItem}>
        <label>City:</label>
        <select name="city" value={filters.city || ''} onChange={onChange}>
          <option value="">Select City</option>
          {uniqueCities.map((city) => (
            <option key={city} value={city}>{city}</option>
          ))}
        </select>
      </div>
      <div className={styles.filterItem}>
        <label>Property Type:</label>
        <select name="property_type" value={filters.property_type || ''} onChange={onChange}>
          <option value="">Select Type</option>
          {uniquePropertyTypes.map((type) => (
            <option key={type} value={type}>{type}</option>
          ))}
        </select>
      </div>
      <div className={styles.filterItem}>
        <label>EMD Amount: <span>{formatNumber(filters.emd_amount || 0)}</span></label>
        <input
          type="range"
          name="emd_amount"
          min="0"
          max={filterValues.maxEmd || 100000}
          value={filters.emd_amount || 0}
          onChange={onChange}
        />
      </div>
      <div className={styles.filterItem}>
        <label>Reserve Price: <span>{formatNumber(filters.reserve_price || 0)}</span></label>
        <input
          type="range"
          name="reserve_price"
          min="0"
          max={filterValues.maxReservePrice || 100000}
          value={filters.reserve_price || 0}
          onChange={onChange}
        />
      </div>
    </div>
  );
}
