import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import MUIDataTable from 'mui-datatables';
import 'react-toastify/dist/ReactToastify.css';
import styles from './css/ListLeadComponent.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { fetchWithQueryAPI } from '../../../../Api/v1/Util/Api';
import { estimate_fetch_by_reference, generic_type, lead_fetch } from '../../../../Api/APIUrl';
import { format } from 'date-fns';
export default function ListEstimate({ typeProps, showFullNav, routeProps, lableProps, userTypeProps, isClientProps }) {
    const [estimate, setEstimate] = useState([]);
    const state = useSelector((state) => state);
    const navigate = useNavigate();
    const { id } = useParams();

    const fetchLeads = async () => {
        try {
            const response = await fetchWithQueryAPI(state.auth.auth.token, estimate_fetch_by_reference + id + generic_type + typeProps);
            if (response && response.statusCode === 200) {
                setEstimate(response.data);
            } else {
                toast.error("Failed to fetch estimate");
            }
        } catch (error) {
            toast.error("An error occurred while fetching estimate");
        }
    };

    useEffect(() => {
        fetchLeads();
    }, []);

    const handleView = (leadId) => {
        navigate(`/freelancer/leads/view-lead/${leadId}`);
    };

    const handleEdit = (leadId) => {
        toast.info(`Editing lead with ID: ${leadId}`);
    };

    const columns = [
        {
            name: 'title',
            label: 'title',
            // options: {
            //     customBodyRender: (value, tableMeta) => (
            //         <span onClick={() => handleView(tableMeta.rowData[6])} style={{ cursor: 'pointer' }}>
            //             {value}
            //         </span>
            //     ),
            // },
        },
        {
            name: 'estimate_date',
            label: 'Estimate Date',
            options: {
                customBodyRender: (value) => format(new Date(value), 'dd/MM/yyyy HH:mm:ss')
            }
        },
        {
            name: 'valid_until',
            label: 'Valid Until',
            options: {
                customBodyRender: (value) => format(new Date(value), 'dd/MM/yyyy HH:mm:ss')
            }
        },
        { name: 'tax', label: 'Primary Tax' },
        { name: 'second_tax', label: 'Secondary Tax' },
        { name: 'amount', label: 'Amount' },
        { name: 'status', label: 'Status' },
        // {
        //     name: '_id',
        //     label: 'Actions',
        //     options: {
        //         customBodyRender: (value, tableMeta) => (
        //             <>
        //                 <IconButton onClick={() => handleView(tableMeta.rowData[6])} color="primary">
        //                     <VisibilityIcon />
        //                 </IconButton>
        //                 {/* <IconButton onClick={() => handleEdit(tableMeta.rowData[6])} color="secondary">
        //                     <EditIcon />
        //                 </IconButton> */}
        //             </>
        //         ),
        //     },
        // },
    ];

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
    };

    const customTheme = createTheme({
        components: {
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#dc3545', // Change this to your desired color
                        color: 'white'
                    },
                },
            },
        },
    });

    return (
        <>
            <ToastContainer />
            <div>
                <div className='row'>
                    <div className='col-md-12'>
                        {
                            typeProps === "Proposal" ?
                                <button className="btn btn-primary mb-2" onClick={() => navigate(`/${userTypeProps}/${lableProps}/create-new-proposal/${id}`)} style={{float:'right'}}>
                                    Create Proposal
                                </button> :
                                <button className="btn btn-primary mb-2" onClick={() => navigate(`/${userTypeProps}/${lableProps}/create-new-estimate/${id}`)} style={{float:'right'}}>
                                    Create Estimate
                                </button>
                        }
                    </div>
                </div>


                <ThemeProvider theme={customTheme}>
                    <MUIDataTable
                        title={typeProps + ` List (${estimate?.total_count ?? 0})`}
                        data={estimate.estimations}
                        columns={columns}
                        options={options}
                    />
                </ThemeProvider>
            </div>
        </>
    );
}
