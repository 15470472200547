import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AdminNavbar from "../../../../Admin/Component/Navbar/AdminNavbar";
import ScheduleMeetingDetails from "../../../../Components/v1/Admin/ScheduleMeeting/ScheduleMeetingDetails";

export default function ScheduleMeeting() {
  const [value, setValue] = React.useState('Draft');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div>
      <ToastContainer />
      <AdminNavbar />
      {/* <Box> */}
      <div className="main">
        <Tabs value={value} onChange={handleChange} aria-label="Schedule Meeting Details">
          <Tab label="In Draft" value="Draft" />
          <Tab label="New" value="New" />
          <Tab label="Review" value="Review" />
          <Tab label="Meeting Scheduled" value="MeetingScheduled" />
          <Tab label="Rescheduled" value="Rescheduled" />
          <Tab label="Completed" value="Completed" />
        </Tabs>
        {/* <div className="main" style={{ marginTop: "50px" }}> */}
          <ScheduleMeetingDetails statusTypeProps={value} />
        </div>
      {/* // </Box> */}
    </div>
  );
}