import React, { useEffect } from 'react';
import Footer from "../Components/Footer/Footer";
import Navigation from "../Components/Navbar/Navigation";
import BSPlotBanner from "../Components/Buy_Sell/BSPlotBanner";
import BSPlotSpecific from "../Components/Buy_Sell/BSPlotSpecific";
export default function BSPlotDetails() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="innerpages-nav">
       <div id="container" >
      <Navigation />
      <BSPlotBanner />
      <BSPlotSpecific />
      <Footer />
      </div>
    </div>
  );
}
