import React, { useEffect, useState } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";
import AdminService from "../../../Assets/Images/admin-service.webp";
import packageService from "../../../Assets/Images/legal-modal-nav.webp";
import { useSelector } from "react-redux";
import {
  CreateOrder,
  CreatePaymentPackage,
  GetALLPACKAGE,
  GetAllSubAdminAndAdmin,
  GetUser,
  getAllPackagesAuto,
  getAllPackagesUser,
  getAllPackagesUserAuto,
  getSpecificPackagee,
} from "../../../Api/Api";
import { ToastContainer, toast } from "react-toastify";
import CustomModal from "../../../Components/CustomModal";
import Spinner from "../../../Spinner/Spinner";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import {
  HandleCCAvenuePaymentCustPack,
  HandleCCAvenuePaymentPkg,
} from "../../../Store/util";
export default function Purchase() {
  const [loading, setLoading] = useState(true);
  const [packages, setPackages] = useState([]);
  const [selectedTab, setSelectedTab] = React.useState("Dalmaf");
  const AllTabs = ["Dalmaf", "Custom"];
  const [specificPackageModal, setSpecificPackageModal] = useState(false);
  const [specificPackageModal1, setSpecificPackageModal1] = useState(false);
  useEffect(() => {
    window.scroll(0, 0);

    GetPackages("Auto");
    getUserProfile();
    GetAllSubadmins();
  }, []);

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 3000,
    });
  };
  const state = useSelector((state) => state);
  const GetPackages = async (value) => {
    setLoading(true);
    if (value === "Auto") {
      const id = state?.auth?.auth?._id;
      const data = await getAllPackagesAuto(id);
      if (data && data.statusCode === 200) {
        let data1 = data?.data.filter((e) => e.customPackage === value);

        let temp = [];
        data1.map((data, i) => {
          let dummy = {
            _id: data?._id,
            title: data?.title,
            price: data?.price,
            gstPercentage: data?.gstPercentage,
            package_create_path: data?.package_create_path,
            createdAt: moment(data?.createdAt).format("DD/MM/YYYY"),
            status: data?.status,
            paidStatus: data?.paidStatus,
            customPackageMerage: "Auto",
          };
          temp.push(dummy);
          return null;
        });
        setPackages(temp);
        setLoading(false);
      } else {
        setPackages([]);
        setLoading(false);
        //console.log("Error while getting packages");
      }
    } else {
      const token = state?.auth?.auth?.token;

      const id = state?.auth?.auth?._id;
      const data = await GetALLPACKAGE(id, token);
      if (data && data.statusCode === 200) {
        let temp = [];
        data.data.map((data, i) => {
          let dummy = {
            _id: data?._id,
            title: data?.title,
            price: data?.price,
            // gstPercentage:data?.gstPercentage,
            createdAt: moment(data?.createdAt).format("DD/MM/YYYY"),
            status: data?.status,
            paidStatus: data?.paidStatus,
            customPackage: data?.customPackage,
          };
          temp.push(dummy);
          return null;
        });
        setPackages(temp);
        setLoading(false);
      } else {
        setPackages([]);
        setLoading(false);
        //console.log("Error while getting packages");
      }
    }
  };

  const [specificPackage, setSpecificPackage] = useState([]);
  const userid = state?.auth?.auth?._id;
  const getSpecificPackage = async (id) => {
    setLoading(true);
    console.log(id, "iuytyu");
    console.log(userid, "tesjuygtft");
    const data = await getSpecificPackagee(id, userid);
    if (data && data.statusCode === 200) {
      setSpecificPackage(data.data);
      setLoading(false);
    } else {
      setSpecificPackage([]);
      setLoading(false);
      //console.log("Error while getting specific packages");
    }
  };

  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "title",
      label: "Title ",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              {tableMeta.rowData[7] === "Auto" ? (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(val) => {
                    getSpecificPackage(tableMeta.rowData[0]);
                    setSpecificPackageModal(true);
                  }}
                >
                  {value}
                </div>
              ) : (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(val) => {
                    getSpecificPackage(tableMeta.rowData[0]);
                    setSpecificPackageModal1(true);
                  }}
                >
                  {value}
                </div>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "price",
      label: "Price",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              {tableMeta.rowData[7] === "Auto" ? (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(val) => {
                    getSpecificPackage(tableMeta.rowData[0]);
                    setSpecificPackageModal(true);
                    // navigate(`/LeadEdit/${tableMeta.rowData[0]}`);
                  }}
                >
                  ₹{value}
                </div>
              ) : (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(val) => {
                    getSpecificPackage(tableMeta.rowData[0]);
                    setSpecificPackageModal1(true);
                    // navigate(`/LeadEdit/${tableMeta.rowData[0]}`);
                  }}
                >
                  ₹{value}
                </div>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "createdAt",
      label: "Created At ",
      options: {
        customBodyRender: (value, tableMeta) => {
          return <div>{value}</div>;
        },
      },
    },

    {
      name: "status",
      label: " Select the Status",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>{value}</div>
            // <select
            //   className="form-control form-control-lg"
            //   defaultValue={value}
            //   onChange={(e) => {
            //     handleSubmitUpdate(
            //       e,
            //       e.target.value,
            //       tableMeta.rowData[0],
            //       value
            //     );
            //   }}
            // >
            //   <option value="inprogress">Inprogress</option>
            //   <option value="rejected">Rejected</option>
            //   <option value="hold">Hold</option>
            //   <option value="open">Open</option>
            //   <option value="converted">Converted</option>
            // </select>
          );
        },
      },
    },
    {
      name: "paidStatus",
      label: "Payment Status",
      options: {
        customBodyRender: (value, tableMeta) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "customPackage",
      label: "Custom Package",
      options: {
        display: false,
      },
    },
    {
      name: "customPackageMerage",
      label: "custom PackageMerage",
      options: {
        display: false,
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    setRowProps: (row) => {
      if (row[5] === false) {
        return {
          style: { background: "#DBDFEA", cursor: "pointer" },
        };
      } else {
        return {
          style: { background: "white", cursor: "pointer" },
        };
      }
    },
  };
  const [userProfile, setUserProfile] = useState([]);
  const getUserProfile = async () => {
    const id = state?.auth?.auth?._id;
    const token = state.auth.auth.token;
    const response = await GetUser(token, id);
    if (response.statusCode === 200) {
      setUserProfile(response.data);
    } else {
      //console.log("error while getting user profile info");
    }
  };

  const [allSubadmin, setAllSubadmin] = useState([]);
  const GetAllSubadmins = async () => {
    setLoading(true);
    const token = state?.auth?.auth?.token;
    let data = await GetAllSubAdminAndAdmin(token);
    if (data && data.statusCode === 200) {
      setAllSubadmin(data.data);
      setLoading(false);
    } else {
      setAllSubadmin([]);
      setLoading(false);
      //console.log("Error while getting all subadmins");
    }
  };
  const handleOrder = async (e, amount, type, id) => {
    e.preventDefault();
    const Payload = {
      price: amount * 100,
    };
    const response = await CreateOrder(Payload);
    if (response && response.statusCode === 200) {
      handleSubmitPayment(
        e,
        response?.data?.id,
        response?.data?.amount,
        type,
        id
      );
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };

  const handleSubmitPayment = (e, orderId, amount, type, id) => {
    e.preventDefault();
    var options = {
      key: "rzp_test_BPipIoC6fqmzK2",
      key_secret: "vEMlC3fZ6XTSh4i8kq03FYXj",
      order_id: orderId,
      amount: amount,
      currency: "INR",
      description: `Buy ${type}`,
      handler: function (response) {
        if (response) {
          if (type === "package") {
            handlePaymentPackage(e, amount / 100, "Paid", response, type, id);
          } else {
            handlePaymentPackageCustom(
              e,
              amount / 100,
              "Paid",
              response,
              type,
              id
            );
          }
        } else {
          if (type === "package") {
            handlePaymentPackage(e, amount / 100, "Failed", response, type, id);
          } else {
            handlePaymentPackageCustom(
              e,
              amount / 100,
              "Paid",
              response,
              type,
              id
            );
          }
        }
      },
      prefill: {
        name: state?.auth?.auth?.name,
        email: state?.auth?.auth?.email,
        contact: userProfile?.phoneNumber,
      },
      notes: {
        address: "Razorpay Corporate office",
      },
      theme: {
        color: "#3399cc",
      },
    };
    var pay = new window.Razorpay(options);
    pay.open();
  };

  const handlePaymentPackageCustom = async (
    e,
    amount,
    paymentStatus,
    paymentResponse,
    type,
    id
  ) => {
    e.preventDefault();

    const Payload = {
      purchaseType: type,
      purchasePackageId: id,
      price: amount,
      paymentInfo: paymentResponse,
      status: paymentStatus,
      // purchaseType: "custom-package",
      // purchasePackageId: id,
      // price: amount,
      // serviceRequestId: reqId,
      // paymentInfo: paymentResponse,
      // status: paymentStatus,
    };
    const token = state?.auth?.auth?.token;
    const response = await CreatePaymentPackage(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      setSpecificPackageModal1(false);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };

  const handlePaymentPackage = async (
    e,
    amount,
    paymentStatus,
    paymentResponse,
    type,
    id
  ) => {
    e.preventDefault();

    const Payload = {
      purchaseType: type,
      packagePurchaseId: id,
      price: amount,
      paymentInfo: paymentResponse,
      status: paymentStatus,
    };
    const token = state?.auth?.auth?.token;
    const response = await CreatePaymentPackage(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      setSpecificPackageModal(false);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };

  return (
    <div>
      <AdminNavbar />
      <ToastContainer />
      <div className="main">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div className="">
              <h5 className="mb-0 fw-bold color-maroon">Packages</h5>
            </div>
            <div className="my-4">
              <div className="container">
                <div className="row">
                  <div className="col-lg-7 px-0">
                    <div className="tab-section">
                      <ul className="ps-0">
                        {AllTabs.map((tab, index) => {
                          return (
                            <div
                              key={index}
                              className={
                                selectedTab === tab
                                  ? "serviceTab active"
                                  : "serviceTab inactive"
                              }
                              onClick={() => {
                                if (tab === "Dalmaf") {
                                  GetPackages("Auto");
                                } else if (tab === "Custom") {
                                  GetPackages("Custom");
                                }
                                setSelectedTab(tab);
                              }}
                            >
                              <li>
                                <span className="align-middle">{tab}</span>
                              </li>
                            </div>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <MUIDataTable
                title={"Dalmaf - Packages"}
                data={packages}
                columns={columns}
                options={options}
              />
            </div>

            <CustomModal
              open={specificPackageModal}
              onClickOutside={() => {
                setSpecificPackageModal(false);
              }}
            >
              <div
                className="modal-content  m-auto pack-mod-admin"
                style={{ backgroundColor: "#540326" }}
              >
                <div className="modal-body p-0 ">
                  <div className="container">
                    <span
                      className="material-symbols-rounded filled-icon close-icon"
                      style={{
                        textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                      }}
                      onClick={() => {
                        setSpecificPackageModal(false);
                      }}
                    >
                      close
                    </span>
                    <div className="row d-flex align-items-center justify-content-center  mt-0">
                      <div className="container ">
                        <div className="row justify-content-center">
                          <div className="col-lg-12">
                            <div className="row justify-content-center ">
                              <div className="col-lg-4  d-none d-lg-block  px-0">
                                <div className="">
                                  <img
                                    className="img-section "
                                    src={packageService}
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="col-lg-8 col-md-12">
                                <div className="modal-drop-box my-2 pt-0 nav-modal-overflow">
                                  <div className="  px-3">
                                    <div className="row justify-content-center mt-2">
                                      <div className="col-lg-12">
                                        <div className="">
                                          <div className="mt-3">
                                            <h6 className="">
                                              <div className="row">
                                                <div className="col-md-3 text-head" style={{color:"black"}}>
                                                  Title
                                                </div>
                                                <div className="col-md-9 text-content">
                                                  {specificPackage?.title}
                                                </div>
                                              </div>
                                            </h6>

                                            <hr />
                                            <h6 className="">
                                              <div className="row">
                                                <div className="col-md-3 text-head">
                                                  Services Included
                                                </div>
                                                <div className="col-md-6 text-content">
                                                  {specificPackage?.services?.map(
                                                    (s) => {
                                                      return (
                                                        <ul>
                                                          <li
                                                            style={{
                                                              listStyleType:
                                                                "none",
                                                            }}
                                                          >
                                                            {s.servicesName}
                                                          </li>
                                                        </ul>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              </div>
                                            </h6>
                                            <hr />
                                            <h6 className="">
                                              <div className="row">
                                                <div className="col-md-4 text-head">
                                                  Applicable For
                                                </div>
                                                <div className="col-md-8 text-content">
                                                  {specificPackage?.toWhom}
                                                </div>
                                              </div>
                                            </h6>
                                            <hr />
                                            <h6 className="">
                                              <div className="row">
                                                <div className="col-md-4 text-head">
                                                  Amount
                                                </div>

                                                <div className="col-md-8 text-content">
                                                  ₹
                                                  {Number(
                                                    specificPackage?.price
                                                  ) +
                                                    Number(
                                                      specificPackage?.gstPercentage
                                                    )}{" "}
                                                  (Inclusive of gst)
                                                </div>
                                              </div>
                                            </h6>
                                            <hr />
                                            <h6 className="">
                                              <div className="row">
                                                <div className="col-md-3 text-head">
                                                  Description
                                                </div>
                                                <div
                                                  className="col-md-9 text-content"
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      specificPackage?.description,
                                                  }}
                                                ></div>
                                              </div>
                                            </h6>
                                            <hr />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-center">
          
                                {specificPackage?.paidStatus === "Paid" ? (
                                  <button
                                    style={{ position: "sticky", bottom: 0,  }}
                                    type="button"
                                    className="btn btn-admin px-5  "
                                  >
                                    Purchased
                                  </button>
                                ) : (
                                  <button
                                    style={{ position: "sticky", bottom: 0 }}
                                    type="button"
                                    className="btn btn-admin px-5 "
                                    onClick={(val) => {
                                      // HandleCCAvenuePaymentPkg(
                                      //   parseInt(specificPackage?.price) +
                                      //   parseInt(specificPackage?.gstPercentage),
                                      //     specificPackage?._id,
                                      //   true,
                                      //   state?.auth?.auth?.token
                                      // );
                                      if (selectedTab === "Dalmaf") {
                                        HandleCCAvenuePaymentPkg(
                                          parseInt(specificPackage?.price) +
                                            parseInt(
                                              specificPackage?.gstPercentage
                                            ),
                                          specificPackage?._id,
                                          true,
                                          state?.auth?.auth?.token
                                        );
                                      } else {
                                        HandleCCAvenuePaymentCustPack(
                                          specificPackage?._id,
                                          parseInt(specificPackage?.price),
                                          true,
                                          state?.auth?.auth?.token
                                        );
                                      }
                                    }}
                                  >
                                    Purchase
                                  </button>
                                )} 
                                </div>
                              
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CustomModal>
            <CustomModal
              open={specificPackageModal1}
              onClickOutside={() => {
                setSpecificPackageModal1(false);
              }}
            >
              {/* <div className="modal-content w-75 m-auto pack-mod-admin"  style={{backgroundColor: '#540326',height:'65vh'}}>
                <div className="modal-body p-0 ">
                  <div className="container">
                    <span
                      className="material-symbols-rounded filled-icon close-icon"
                      style={{
                        textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                      }}
                      onClick={() => {
                        setSpecificPackageModal1(false);
                      }}
                    >
                      close
                    </span>
                    <div className="row d-flex align-items-center justify-content-center  mt-0">
                      <div className="container ">
                        <div className="row">
                          <div className="col-lg-4 px-0 ">
                            <div>
                              <img
                                className="img-section "
                                src={packageService}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="col-lg-8">
                            <div className=" my-2 px-3">
                              <div className="row justify-content-center mt-2">
                                <div className="col-lg-12">
                                  <div className="">
                                    <div className="mt-3">
                                      <h6 className="">
                                        <div className="row">
                                          <div className="col-md-3 text-head">
                                            Title
                                          </div>
                                          <div className="col-md-9 text-content">
                                            {specificPackage?.title}
                                          </div>
                                        </div>
                                      </h6>
                                      <hr />
                                      <h6 className="">
                                        <div className="row">
                                          <div className="col-md-3 text-head">
                                            Name
                                          </div>
                                          <div className="col-md-9 text-content">
                                            {specificPackage?.userId?.name}
                                          </div>
                                        </div>
                                      </h6>
                                      <hr />
                                      <h6 className="">
                                        <div className="row">
                                          <div className="col-md-3 text-head">
                                            Email
                                          </div>
                                          <div className="col-md-9 text-content">
                                            {specificPackage?.userId?.email}
                                          </div>
                                        </div>
                                      </h6>
                                      <hr />
                                      <h6 className="">
                                        <div className="row">
                                          <div className="col-md-3 text-head">
                                            Amount
                                          </div>
                                          <div className="col-md-9 text-content">
                                            ₹{specificPackage?.price}{" "}
                                          </div>
                                        </div>
                                      </h6>
                                      <hr />
                                      <h6 className="">
                                        <div className="row">
                                          <div className="col-md-3 text-head">
                                            Reffered By
                                          </div>

                                          <div className="col-md-9 text-content">
                                            {allSubadmin
                                              ?.filter(
                                                (name) =>
                                                  name._id ===
                                                  specificPackage?.referredBy
                                              )
                                              .map((id) => {
                                                return id.name;
                                              })}
                                          </div>
                                        </div>
                                      </h6>
                                      <hr />
                                      <h6 className="">
                                        <div className="row">
                                          <div className="col-md-3 text-head">
                                            Description
                                          </div>
                                          <div
                                            className="col-md-9 text-content"
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                specificPackage?.description,
                                            }}
                                          ></div>
                                        </div>
                                      </h6>
                                      <hr />
                                      {console.log()}
                                      {specificPackage?.purchasepackage
                                        ?.status === "Paid" ? (
                                        <button
                                          type="button"
                                          className="btn btn-admin px-5 "
                                        >
                                          Paid
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="btn btn-admin px-5 "
                                          onClick={(val) => {
                                            if (selectedTab === "Dalmaf") {
                                              HandleCCAvenuePaymentPkg(
                                                parseInt(
                                                  specificPackage?.price
                                                ) +
                                                  parseInt(
                                                    specificPackage?.gstPercentage
                                                  ),
                                                specificPackage?._id,
                                                true,
                                                state?.auth?.auth?.token
                                              );
                                            } else {
                                              HandleCCAvenuePaymentCustPack(
                                                specificPackage?._id,
                                                parseInt(
                                                  specificPackage?.price
                                                ),
                                                true,
                                                state?.auth?.auth?.token
                                              );
                                            }
                                          }}
                                        >
                                          Purchase
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div
                className="modal-content  m-auto pack-mod-admin"
                style={{ backgroundColor: "#540326" }}
              >
                <div className="modal-body p-0 ">
                  <div className="container">
                    <span
                      className="material-symbols-rounded filled-icon close-icon"
                      style={{
                        textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                      }}
                      onClick={() => {
                        setSpecificPackageModal1(false);
                      }}
                    >
                      close
                    </span>
                    <div className="row d-flex align-items-center justify-content-center  mt-0">
                      <div className="container ">
                        <div className="row justify-content-center">
                          <div className="col-lg-12">
                            <div className="row justify-content-center ">
                              <div className="col-lg-4  d-none d-lg-block  px-0">
                                <div className="">
                                  <img
                                    className="img-section "
                                    src={packageService}
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="col-lg-8 col-md-12">
                                <div className="modal-drop-box my-4 pt-0 nav-modal-overflow">
                                  <div className="  px-3">
                                    <div className="row justify-content-center mt-2">
                                      <div className="col-lg-12">
                                        <div className="">
                                          <div className="mt-3">
                                            <h6 className="">
                                              <div className="row">
                                                <div className="col-md-3 text-head">
                                                  Title
                                                </div>
                                                <div className="col-md-9 text-content">
                                                  {specificPackage?.title}
                                                </div>
                                              </div>
                                            </h6>
                                            <hr />
                                            <h6 className="">
                                              <div className="row">
                                                <div className="col-md-3 text-head">
                                                  Name
                                                </div>
                                                <div className="col-md-9 text-content">
                                                  {specificPackage?.name}
                                                </div>
                                              </div>
                                            </h6>
                                            <hr />
                                            <h6 className="">
                                              <div className="row">
                                                <div className="col-md-3 text-head">
                                                  Email
                                                </div>
                                                <div className="col-md-9 text-content">
                                                  {specificPackage?.email}
                                                </div>
                                              </div>
                                            </h6>
                                            <hr />
                                            <h6 className="">
                                              <div className="row">
                                                <div className="col-md-3 text-head">
                                                  Amount
                                                </div>
                                                <div className="col-md-9 text-content">
                                                  ₹{specificPackage?.price}{" "}
                                                </div>
                                              </div>
                                            </h6>
                                            <hr />
                                            <h6 className="">
                                              <div className="row">
                                                <div className="col-md-3 text-head">
                                                  Reffered By
                                                </div>

                                                <div className="col-md-9 text-content">
                                                  {
                                                    specificPackage?.referredBy
                                                      ?.name
                                                  }{" "}
                                                  {
                                                    // allSubadmin
                                                    //   ?.filter(
                                                    //     (name) =>
                                                    //       name._id ===
                                                    //       specificPackage?.referredBy
                                                    //   )
                                                    //   .map((id) => {
                                                    //     return id.name;
                                                    //   })
                                                  }
                                                </div>
                                              </div>
                                            </h6>
                                            <hr />
                                            <h6 className="">
                                              <div className="row">
                                                <div className="col-md-3 text-head">
                                                  Description
                                                </div>
                                                <div
                                                  className="col-md-9 text-content"
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      specificPackage?.description,
                                                  }}
                                                ></div>
                                              </div>
                                            </h6>
                                            <hr />

                                            {specificPackage?.paidStatus ===
                                            "Paid" ? (
                                              <button
                                                style={{
                                                  position: "sticky",
                                                  bottom: 0,
                                                }}
                                                type="button"
                                                className="btn btn-admin px-5 "
                                                // onClick={(val) => {
                                                //   if (
                                                //     selectedTab === "Dalmaf"
                                                //   ) {
                                                //     HandleCCAvenuePaymentPkg(
                                                //       parseInt(
                                                //         specificPackage?.price
                                                //       ) +
                                                //         parseInt(
                                                //           specificPackage?.gstPercentage
                                                //         ),
                                                //       specificPackage?._id,
                                                //       true,
                                                //       state?.auth?.auth?.token
                                                //     );
                                                //   } else {
                                                //     HandleCCAvenuePaymentCustPack(
                                                //       specificPackage?._id,
                                                //       parseInt(
                                                //         specificPackage?.price
                                                //       ),
                                                //       true,
                                                //       state?.auth?.auth?.token
                                                //     );
                                                //   }
                                                // }}
                                              >
                                                Purchased
                                              </button>
                                            ) : (
                                              <button
                                                style={{
                                                  position: "sticky",
                                                  bottom: 0,
                                                }}
                                                type="button"
                                                className="btn btn-admin px-5 "
                                                onClick={(val) => {
                                                  if (
                                                    selectedTab === "Dalmaf"
                                                  ) {
                                                    HandleCCAvenuePaymentPkg(
                                                      parseInt(
                                                        specificPackage?.price
                                                      ) +
                                                        parseInt(
                                                          specificPackage?.gstPercentage
                                                        ),
                                                      specificPackage?._id,
                                                      true,
                                                      state?.auth?.auth?.token
                                                    );
                                                  } else {
                                                    HandleCCAvenuePaymentCustPack(
                                                      specificPackage?._id,
                                                      parseInt(
                                                        specificPackage?.price
                                                      ),
                                                      true,
                                                      state?.auth?.auth?.token
                                                    );
                                                  }
                                                }}
                                              >
                                                Purchase
                                              </button>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CustomModal>
          </>
        )}
      </div>
    </div>
  );
}
