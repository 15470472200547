// export const EmailRegex =
//   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//export const PhoneNumberRegex = /^[0-9].{11}$/;

import moment from "moment";

export const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const PhoneNumberRegex = /^[0-9]{10}/;
//export const PhoneNumberRegex = /^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/;

export const NumberRegex = /^[0-9]/;
export const StringRegex = /^[A-Za-z ]+$/;
export const AlphaNumericRegex = /^[A-Za-z0-9 ]/;
export const CityRegex = /^[A-Za-z0-9,-. ]+$/;
export const QualificationRegex = /\b(([\w]+[.][\w]+)([.][\w])*)/;
export const URLregex =
  /((?:(?:http?|ftp)[s]*:\/\/)?[a-z0-9-%\/\&=?\.]+\.[a-z]{2,4}\/?([^\s<>\#%"\,\{\}\\|\\\^\[\]`]+)?)/gi;

var regex = new RegExp(URLregex);

export const NumericRangeRegex = /^[0-9-$ ]+$/;

// export function PhoneNumberValidation(value) {
//   if (value === undefined) {
//     return { status: false, message: "cannot be empty" };
//   } else if (!value?.length) {
//     return { status: false, message: "cannot be empty" };
//   }
//   return !PhoneNumberRegex.test(value)
//     ? { status: false, message: "is invalid" }
//     : { status: true, message: "" };
// }

//

// export function PhoneNumberValidation(value) {
//   //console.log(value)
//   if (value.toString() === undefined || !value.toString().length) {
//     return { status: false, message: " cannot be empty" };
//   } else if (!/^[0-9]+$/.test(value)) {
//     return {
//       status: false,
//       message: " must contain only numeric digits",
//     };
//   } else if (value.toString().length !== 10) {
//     return {
//       status: false,
//       message: " must be 10 digits",
//     };
//   }
//   if (!/^[1-9][0-9]{9}$/.test(value)) {
//     return {
//       status: false,
//       message: " must not start with 0",
//     };
//   } else {
//     return { status: true, message: "" };
//   }
// }

// export const NumericRangeRegex = /^[0-9]{10}$/;

// export function PhoneNumberValidation(value) {
//   if (value === undefined) {
//     return { status: false, message: "cannot be empty" };
//   } else if (!value?.length) {
//     return { status: false, message: "cannot be empty" };
//   } else if (value.length !== 10) {
//     return { status: false, message: "must be 10 digits" };
//   }
//   return !PhoneNumberRegex.test(value)
//     ? { status: false, message: "is invalid" }
//     : { status: true, message: "" };
// }

//

export function PhoneNumberValidation(value) {
  if (value === undefined) {
    return { status: false, message: "cannot be empty" };
  } else if (!value?.toString().length) {
    return { status: false, message: "cannot be empty" };
  }

  if (!/^[0-9]+$/.test(value)) {
    return {
      status: false,
      message: "must contain only numeric digits",
    };
  } else if (value.toString().length !== 10) {
    return {
      status: false,
      message: "must be 10 digits",
    };
    
  }

  if (!/^[1-9][0-9]{9}$/.test(value)) {
    return {
      status: false,
      message: "must not start with 0",
    };
  } else {
    return { status: true, message: "" };
  }
}


const ERROR_EMPTY = "Please select an option";

export function DropdownValidation(value) {
  if (!value) {
    return { status: false, message: ERROR_EMPTY };
  }

  return { status: true, message: "" };
}

export function uploadfilevalidation(value) {
  if (!value) {
    return { status: false, message: "please upload a file" };
  }

  return { status: true, message: "" };
}

export function AmountValidation(value) {
  if (value === undefined) {
    return { status: false, message: "cannot be empty" };
  } else if (!value?.length) {
    return { status: false, message: "cannot be empty" };
  }

  const amountRegex = /^[0-9]+(\.[0-9]{1,2})?$/;
  return !amountRegex.test(value)
    ? { status: false, message: "should be a valid amount" }
    : { status: true, message: "" };
}

//check date
export function validateDates(startDate, endDate) {
  if (startDate === undefined) {
    return { status: false, message: "Start Date cannot be empty" };
  } else if (!startDate?.length) {
    return { status: false, message: "Start Date cannot be empty" };
  }

  if (endDate === undefined) {
    return { status: false, message: "End Date cannot be empty" };
  } else if (!endDate?.length) {
    return { status: false, message: "End Date cannot be empty" };
  }

  const start = new Date(startDate);
  const end = new Date(endDate);

  if (start > end) {
    return {
      status: false,
      message: "Start Date cannot be greater than End Date",
    };
  }

  if (end < start) {
    return {
      status: false,
      message: "End Date cannot be earlier than Start Date",
    };
  }

  return { status: true, message: "" };
}

export function PasswordValidation(value) {
  if (!value?.length) {
    return { status: false, message: "cannot be empty" };
  } else if (value?.length > 30) {
    return {
      status: false,
      message: "must allowed maximum 30 characters only",
    };
  }
  const password = [
    /[a-z]/,
    /[A-Z]/,
    /[0-9]/,
    /[.,:;\'!@#$%^&*_+=|(){}[?\-\]\/\\]/,
  ].every((pattern) => pattern.test(value));
  return !password
    ? { status: false, message: "is invalid" }
    : { status: true, message: "" };
}

export function PincodeValidation(value) {
  const pincodeRegex = /^\d{6}$/;
  if (!value?.toString().length) {
    return { status: false, message: "cannot be empty" };
  } else if (!pincodeRegex.test(value)) {
    return {
      status: false,
      message: "must be a 6-digit number",
    };
  }
  return { status: true, message: "" };
}

export function RePasswordValidation(value, value1) {
  if (!value?.length) {
    return { status: false, message: "cannot be empty" };
  } else if (value !== value1) {
    return {
      status: false,
      message: "must be same",
    };
  } else if (value?.length > 30) {
    return {
      status: false,
      message: "must allowed maximum 30 characters only",
    };
  }
  const password = [
    /[a-z]/,
    /[A-Z]/,
    /[0-9]/,
    /[.,:;\'!@#$%^&*_+=|(){}[?\-\]\/\\]/,
  ].every((pattern) => pattern.test(value));
  return !password
    ? { status: false, message: "is invalid" }
    : { status: true, message: "" };
}

const testArray = ["gmail.com", "yahoo.com", "hotmail.com"];
export function EmailCompanyValidation(value, value1) {
  if (!value?.trim()?.length) {
    return { status: false, message: "is required" };
  } else if (value?.includes(" ")) {
    return { status: false, message: "cannot be include space" };
  } else if (testArray.includes(value.split("@").pop()) === true) {
    return { status: false, message: "should not be personal mail id" };
  } else if (value === value1) {
    return { status: false, message: "cannot be same" };
  }

  return !EmailRegex.test(value)
    ? { status: false, message: "is invalid" }
    : { status: true, message: "" };
}

// export function EmailValidation(value) {
//   if (!value?.trim()?.length) {
//     return { status: false, message: "cannot be empty" };
//   } else if (value?.includes(" ")) {
//     return { status: false, message: "cannot be include space" };
//   }
//   return !EmailRegex.test(value)
//     ? { status: false, message: "is invalid" }
//     : { status: true, message: "" };
// }

//

export function EmailValidation(value) {
  if (!value?.trim()?.length) {
    return { status: false, message: " cannot be empty." };
  } else if (value?.includes(" ")) {
    return { status: false, message: "cannot include spaces." };
  } else if (/^\d/.test(value)) {
    return { status: false, message: "cannot start with a number" };
  } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
    return { status: false, message: "is invalid" };
  }

  return { status: true, message: "" };
}

export function NumberValidation(value) {
  if (!value?.toString().length) {
    return { status: false, message: "cannot be empty" };
  } else if (value?.toString().includes(" ")) {
    return { status: false, message: "cannot be include space" };
  } else if (Number(value) === 0) {
    return { status: false, message: "cannot be Zero" };
  } else if (value < 0) {
    return { status: false, message: "cannot be Less than Zero" };
  }
  return !NumberRegex.test(value)
    ? { status: false, message: "is only a number" }
    : { status: true, message: "" };
}

export function ExperienceLevelValidation(value) {
  if (!value?.length) {
    return { status: false, message: "cannot be empty" };
  } else if (Number(value) === 0) {
    return { status: false, message: "cannot be Zero" };
  }
  return value < 0
    ? { status: false, message: "cannot be Less than Zero" }
    : { status: true, message: "" };
}
export function NumberMaxValidation(value, value1) {
  if (!value?.length) {
    return { status: false, message: "cannot be empty" };
  } else if (value?.includes(" ")) {
    return { status: false, message: "cannot be include space" };
  } else if (Number(value) < Number(value1)) {
    return { status: false, message: "is not lesser than Min Salary" };
  } else if (Number(value) === 0) {
    return { status: false, message: "cannot be Zero" };
  } else if (value < 0) {
    return { status: false, message: "cannot be Less than Zero" };
  }
  return !NumberRegex.test(value)
    ? { status: false, message: "is only a number" }
    : { status: true, message: "" };
}

export function SalaryRangeValidation(value, value1) {
  if (!value?.length) {
    return { status: false, message: "cannot be empty" };
  } else if (value === value1) {
    return { status: false, message: "is required" };
  } else if (Number(value) === 0) {
    return { status: false, message: "cannot be Zero" };
  }
  return value < 0
    ? { status: false, message: "cannot be Less than Zero" }
    : { status: true, message: "" };
}

export function CompanyNameValidation(value) {
  if (!value?.length) {
    return { status: false, message: "cannot be empty" };
  } else if (Number(value) === 0) {
    return { status: false, message: "cannot be Zero" };
  }
  return value < 0
    ? { status: false, message: "cannot be Less than Zero" }
    : { status: true, message: "" };
}
export function CompanyNameChangeValidation(value, value1) {
  if (!value?.length) {
    return { status: false, message: "cannot be empty" };
  } else if (Number(value) === 0) {
    return { status: false, message: "cannot be Zero" };
  } else if (value === value1) {
    return { status: false, message: "cannot be same" };
  }
  return value < 0
    ? { status: false, message: "cannot be Less than Zero" }
    : { status: true, message: "" };
}

export function NonEmptyValidation(value) {
  if (!value?.length) {
    return { status: false, message: "cannot be empty" };
  } else if (value === null) {
    return { status: false, message: "cannot be empty" };
  } else if (value === undefined) {
    return { status: false, message: "cannot be empty" };
  } else if (!value) {
    return { status: false, message: "is required" };
  }
  return value < 0
    ? { status: false, message: "cannot be Less than Zero" }
    : { status: true, message: "" };
}

export function NonEmptyValidationDes(value) {
  if (!value?.toString().length) {
    return { status: false, message: "cannot be empty" };
  } else if (value === null) {
    return { status: false, message: "cannot be empty" };
  } else if (value === undefined) {
    return { status: false, message: "cannot be empty" };
  } else if (!value) {
    return { status: false, message: "is required" };
  }
  return value < 0
    ? { status: false, message: "cannot be Less than Zero" }
    : { status: true, message: "" };
}

export function NonEmptyValidationn(value) {
  if (!value?.length) {
    return { status: false, message: "is mandatory" };
  } else if (value === null) {
    return { status: false, message: "cannot be empty" };
  } else if (value === undefined) {
    return { status: false, message: "cannot be empty" };
  } else if (!value) {
    return { status: false, message: "is required" };
  }
  return value < 0
    ? { status: false, message: "cannot be Less than Zero" }
    : { status: true, message: "" };
}
export function NonEmptyEditorValidationn(val) {
      const editorRegex = /(<([^>]+)>)/gi;
         const value = val.replace(editorRegex, "");
  if (!value?.length) {
    return { status: false, message: "is mandatory" };
  } else if (value === null) {
    return { status: false, message: "cannot be empty" };
  } else if (value === undefined) {
    return { status: false, message: "cannot be empty" };
  } else if (!value) {
    return { status: false, message: "is required" };
  }
  return value < 0
    ? { status: false, message: "cannot be Less than Zero" }
    : { status: true, message: "" };
}

export function AcceptTermsValidation(value) {
  if (value?.length === 0) {
    return { status: false, message: "cannot be empty" };
  } else if (value === null) {
    return { status: false, message: "cannot be empty" };
  } else if (value === undefined) {
    return { status: false, message: "cannot be empty" };
  } else if (value === false) {
    return { status: false, message: "cannot be empty" };
  }

  return value < 0
    ? { status: false, message: "cannot be Less than Zero" }
    : { status: true, message: "" };
}

export function NonEmptyValidationForDate(value) {
  if (!value) {
    return { status: false, message: "cannot be empty" };
  } else if (value === null) {
    return { status: false, message: "cannot be empty" };
  } else if (value === undefined) {
    return { status: false, message: "cannot be empty" };
  }
  return value
    ? { status: true, message: "" }
    : { status: false, message: "cannot be empty" };
}

export function NonEmptyValidationForEndDate(value) {
  if (value === null) {
    return { status: false, message: "cannot be empty" };
  } else if (value === undefined) {
    return { status: false, message: "cannot be empty" };
  } else if (value === "") {
    return { status: true, message: "" };
  }
  return value
    ? { status: true, message: "" }
    : { status: false, message: "cannot be empty" };
}

export function StringValidation(value) {
  if (!value || !value?.length) {
    return { status: false, message: "cannot be empty" };
  } else if (!isNaN(value)) {
    return { status: false, message: "is invalid" };
  } else if (value === undefined) {
    return { status: false, message: "cannot be empty" };
  } else if (value === "") {
    return { status: false, message: "cannot be empty" };
  } else if (value === 0) {
    return { status: false, message: "cannot be Zero" };
  } else if (value < 0) {
    return { status: false, message: "cannot be Less than Zero" };
  } else if (value.length <= 2) {
    return { status: false, message: "must be maximum 3 letters" };
  }

  return !StringRegex.test(value)
    ? { status: false, message: "is only Alphabets" }
    : { status: true, message: "" };
}

export function AlphaNumericValidation(value) {
  if (!value?.trim()?.length) {
    return { status: false, message: "is required" };
  } else if (!isNaN(value)) {
    return { status: false, message: "is invalid" };
  }

  return AlphaNumericRegex.test(value)
    ? { status: true, message: "" }
    : { status: false, message: "is only Alphabets & Numbers" };
}

export function URLValidation(value) {
  if (!value?.length) {
    return { status: false, message: "cannot be empty" };
  } else if (value?.includes(" ")) {
    return { status: false, message: "cannot be include space" };
  } else if (!isNaN(value)) {
    return { status: false, message: "is invalid." };
  } else if (value.match(regex)) {
    if (value.split(".")[0].length === 3) {
      return {
        status: true,
        message: "",
      };
    }
  }

  return {
    status: false,
    message: "is invalid",
  };
}

export function lastNameValidation(value) {
  if (!value?.length) {
    return { status: false, message: "cannot be empty" };
  } else if (typeof value !== "string") {
    return { status: false, message: "must be a string" };
  } else if (value?.includes(" ")) {
    return { status: false, message: "cannot be include space" };
  }

  return !StringRegex.test(value)
    ? { status: false, message: "is only Alphabets" }
    : { status: true, message: "" };
}
export function DateValidation(value) {
  var todayDate = new Date();
  var month = todayDate.getMonth() + 1;
  var year = todayDate.getUTCFullYear();
  var tdate = todayDate.getDate();
  if (month < 10) {
    month = "0" + month;
  }
  if (tdate < 10) {
    tdate = "0" + tdate;
  }
  var minDate = year + "-" + month + "-" + tdate;
  if (!value?.length) {
    return { status: false, message: "is required" };
  } else if (value?.includes(" ")) {
    return { status: false, message: "cannot be include space" };
  } else if (value?.length <= 3) {
    return { status: false, message: "not a valid year" };
  } else if (!NumberRegex.test(value)) {
    return { status: false, message: "is only a number" };
  }
  return minDate >= value
    ? { status: true, message: "" }
    : { status: false, message: "cannot be past date" };
}

export function DateValidationn(value) {
  var todayDate = new Date();
  var month = todayDate.getMonth() + 1;
  var year = todayDate.getUTCFullYear();
  var tdate = todayDate.getDate();
  if (month < 10) {
    month = "0" + month;
  }
  if (tdate < 10) {
    tdate = "0" + tdate;
  }
  var minDate = year + "-" + month + "-" + tdate;

  if (!value?.trim()?.length) {
    return { status: false, message: "is required" };
  } else if (value?.includes(" ")) {
    return { status: false, message: "cannot be include space" };
  } else if (value?.length <= 3) {
    return { status: false, message: "not a valid year" };
  } else if (!NumberRegex.test(value)) {
    return { status: false, message: "is only a number" };
  }
  return minDate <= value
    ? { status: true, message: "" }
    : { status: false, message: "cannot be past date" };
}

export function DropdownArray(value) {
  if (!value || !value.length) {
    return { status: false, message: "is required" };
  }
  return { status: true, message: "" };
}

export function NumericRangeValidation(value) {
  if (!value?.trim()?.length) {
    return { status: false, message: "is required" };
  } else if (!isNaN(value)) {
    return { status: false, message: "is invalid" };
  }

  return NumericRangeRegex.test(value)
    ? {
        status: true,
        message: "",
      }
    : {
        status: false,
        message: "is only Numbers & Hyphens",
      };
}
export function NonEmptyObjectValidation(value) {
  if (!value) {
    return { status: false, message: "is required" };
  } else if (!isNaN(value)) {
    return { status: false, message: "is invalid" };
  }

  return { status: true, message: "" };
}

export function NonEmptyKeyValidation(value) {
  if (value === undefined) {
    return { status: false, message: "is required" };
  }
  return { status: true, message: "" };
}

export function QualificationValidation(value) {
  if (!value?.trim()?.length) {
    return { status: false, message: "is required" };
  } else if (!isNaN(value)) {
    return { status: false, message: "is invalid" };
  }

  return {
    status: true,
    message: "",
  };
}

export function CityValidation(value) {
  if (!value?.trim()?.length) {
    return { status: false, message: "is required" };
  } else if (!isNaN(value)) {
    return { status: false, message: "is invalid" };
  }

  return CityRegex.test(value)
    ? {
        status: true,
        message: "",
      }
    : {
        status: false,
        message: "is invalid",
      };
}
export function NoOfPositionNumberValidation(value) {
  if (!value?.length) {
    return { status: false, message: "cannot be empty" };
  } else if (value?.length > 3) {
    return { status: false, message: "cannot be more than 3-digits" };
  } else if (value?.includes(" ")) {
    return { status: false, message: "cannot be include space" };
  } else if (Number(value) < 1) {
    return { status: false, message: "cannot be Zero" };
  } else if (value < 0) {
    return { status: false, message: "cannot be Less than Zero" };
  } else if (Number(value) === "00") {
    return { status: false, message: "cannot be Zero" };
  } else if (Number(value) === "000") {
    return { status: false, message: "cannot be Zero" };
  }
  return !NumberRegex.test(value)
    ? { status: false, message: "is only a number" }
    : { status: true, message: "" };
}

export function ExpireDateValidation(value, value1) {
  if (!value1?.length) {
    return { status: false, message: "cannot be empty" };
  } else if (value1 === null) {
    return { status: false, message: "cannot be empty" };
  } else if (value1 === undefined) {
    return { status: false, message: "cannot be empty" };
  } else if (value1 === "") {
    return { status: true, message: "" };
  }
  return value > value1
    ? { status: false, message: "must be future date" }
    : { status: true, message: "" };
}
export function ExpireDateValidationn(value, value1) {
  const [sd, sm, sy] = moment(value).format("DD/MM/YYYY").split("/");
  const [ed, em, ey] = moment(value1).format("DD/MM/YYYY").split("/");
  if (!value1?.length) {
    return { status: false, message: "cannot be empty" };
  } else if (value1 === null) {
    return { status: false, message: "cannot be empty" };
  } else if (value1 === undefined) {
    return { status: false, message: "cannot be empty" };
  } else if (value1 === "") {
    return { status: true, message: "" };
  }
  return value >= value1
    ? { status: false, message: "must be future date" }
    : { status: true, message: "" };
}

export function StartDateValidation(startDate, expireDate) {
  if (!startDate?.length) {
    return { status: false, message: "Start date cannot be empty" };
  } else if (startDate === null) {
    return { status: false, message: "Start date cannot be empty" };
  } else if (startDate === undefined) {
    return { status: false, message: "Start date cannot be empty" };
  } else if (startDate === "") {
    return { status: true, message: "" };
  }

  if (expireDate && startDate <= expireDate) {
    return {
      status: false,
      message: "Start date cannot be less than the expire date",
    };
  }

  return { status: true, message: "" };
}

export function PDFValidation(value) {
  return value === "pdf"
    ? { status: true, message: "" }
    : { status: false, message: "Accepts only pdf file" };
}
