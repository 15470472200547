import React, { useEffect, useState } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { 
  GetUser,
  GetLevel2,
  GetMain,
  GetLevel1,
  UpdateMain,
  GetSpecificMain,
  UploadDocument,
  CreateMain,
  GetLevel2All,
  GetLevel1All,
  GetAllSubAdminAndAdmin,
} from "../../../Api/Api";

import { alpha, styled } from "@mui/material/styles";
import { pink } from "@mui/material/colors";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useSelector } from "react-redux";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  NonEmptyValidation,
  NonEmptyValidationDes,
  NumberValidation,
  StringValidation,
} from "../../../Store/validate";

import CustomModal from "../../../Components/CustomModal";
import Spinner from "../../../Spinner/Spinner";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";

let htmlToDraft = null;
if (typeof window === "object") {
  htmlToDraft = require("html-to-draftjs").default;
}

export default function Level2() {
  const [selectedTab, setSelectedTab] = React.useState(
    "Active Level 2 Services"
  );
  const AllTabs = ["Active Level 2 Services", "In- Active Level 2 Services"];
  const navigate = useNavigate();
  const [newTask, setNewTask] = useState(true);
  const [editModal, setEditModal] = useState(false);

  const [loading, setLoading] = useState(true);

  const [getMyTask, setGetMyTask] = useState([]);

  const state = useSelector((state) => state);
  const [getMyTaskMain, setGetMyTaskMain] = useState([]);
  const [getMyTasklevel1, setGetMyTasklevel1] = useState([]);
  const GetMyTsksMain = async () => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await GetMain("active");
    if (data && data.statusCode === 200) {
      setGetMyTaskMain(data.data);
      setLoading(false);
    } else {
      setGetMyTaskMain([]);
      setLoading(false);
      //console.log("Error while getting my task");
    }
  };

  const GetMyTsksLevel1 = async (status, id) => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await GetLevel1(status, id);
    if (data && data.statusCode === 200) {
      setGetMyTasklevel1(data.data);
      setLoading(false);
    } else {
      setGetMyTasklevel1([]);
      setLoading(false);
      //console.log("Error while getting my task");
    }
  };
  const GetMyTsksLevel1All = async (status) => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await GetLevel1All(status);
    if (data && data.statusCode === 200) {
      setGetMyTasklevel1(data.data);
      setLoading(false);
    } else {
      setGetMyTasklevel1([]);
      setLoading(false);
      //console.log("Error while getting my task");
    }
  };

  const GetMyTsks = async (status, id, id1) => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await GetLevel2(status, id, id1);
    if (data && data.statusCode === 200) {
      setGetMyTask(data.data);
      setLoading(false);
    } else {
      setGetMyTask([]);
      setLoading(false);
      //console.log("Error while getting my task");
    }
  };

  const GetMyTsksAll = async (status) => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await GetLevel2All(status);
    if (data && data.statusCode === 200) {
      let temp = [];
      data.data.map((data, i) => {
        let tempId = userProfile[i]?._id;
        let addBy = userProfile?.filter((e)=>e._id===data.addBy)?.map((name)=>{
          return name.name
        })
        let dummy = {
          _id: data?._id,
          title: data.title,
          mainServiceId: data.mainServiceId.title,
          subServiceL1Id: data.subServiceL1Id.title,
          icon: data.icon,
          displayOrder: data.displayOrder,
          status: data.status,
          addBy: addBy,
          // addBy: userProfile?.filter((e)=>e._id===data.addBy)?.map((name)=>{return name.name}),
          // addBy: data.addBy,
        };
        temp.push(dummy);
        return null;
      });
      setGetMyTask(temp);
      // setGetMyTask(data.data);
      setLoading(false);
    } else {
      setGetMyTask([]);
      setLoading(false);
      //console.log("Error while getting my task");
    }
  };

  const [main, setMain] = useState("");
  const [main1, setMain1] = useState("");
  const [myTask1, setMyTask1] = useState({});
  const [myTaskValidation1, setMyTaskValidation1] = useState({});

  const setMyTaskValue1 = (key, value) => {
    setMyTask1({ ...myTask1, [key]: value });
    if (myTaskValidation1[key]) delete myTaskValidation1[key];
  };

  const setMyTaskValidationValue1 = (key, value) => {
    setMyTaskValidation1({ ...myTaskValidation1, [key]: value });
  };
  const GetMySpecificTsks = async (id) => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await GetSpecificMain(id);
    if (data && data.statusCode === 200) {
      let dummy = data.data;
      let cmsDescription = EditorState.createEmpty();
      if (dummy.description) {
        let blocksFromHtml = htmlToDraft(dummy.description);
        let { contentBlocks, entityMap } = blocksFromHtml;
        let contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );

        cmsDescription = EditorState.createWithContent(contentState);
      }

      dummy.description = cmsDescription;
      setMyTask1(dummy);
      setLoading(false);
    } else {
      setMyTask1([]);
      setLoading(false);
      //console.log("Error while getting my specific task");
    }
  };

  const [profile, setProfileDetails] = useState([]);
  async function fetchDataProfile() {
    setLoading(true);
    const id = state?.auth?.auth?._id;
    const token = state?.auth?.auth?.token;
    const getBasicDetails = await GetUser(token, id);
    if (getBasicDetails && getBasicDetails?.statusCode === 200) {
      setProfileDetails(getBasicDetails.data);
      setLoading(false);
    } else {
      setProfileDetails([]);
      setLoading(false);
      //console.log("error while getting user profile info");
    }
  }

  useEffect(() => {
    GetMyTsksAll("active");
    fetchDataProfile();
    GetMyTsksMain();
    GetMyTsksLevel1All("active");
  }, []);

  const [myTask, setMyTask] = useState({});
  const [myTaskValidation, setMyTaskValidation] = useState({});

  const setMyTaskValue = (key, value) => {
    setMyTask({ ...myTask, [key]: value });
    if (myTaskValidation[key]) delete myTaskValidation[key];
  };

  const setMyTaskValidationValue = (key, value) => {
    setMyTaskValidation({ ...myTaskValidation, [key]: value });
  };

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 3000,
    });
  };

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleEditorChange = (state) => {
    setEditorState(state);
    let isNotEmpty = state && state.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(convertToRaw(state.getCurrentContent()));
      setMyTaskValue("description", html);
    }
  };

  const [editorState1, setEditorState1] = useState(() =>
    EditorState.createEmpty()
  );

  const handleEditorChange1 = (state) => {
    setEditorState1(state);
    let isNotEmpty = state && state.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(convertToRaw(state.getCurrentContent()));
      setMyTaskValue("requirement", html);
    }
  };
  const CeoRef = useRef();
  const CeoRef1 = useRef();

  const uploadCeoPic = async (e) => {
    const file = e.target.files[0];
    if (file) {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("fileName", e.target.files[0].name);
      formData.append("path", "Image/");

      let response = await UploadDocument(formData);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        setMyTaskValue("icon", response.data);
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    }
  };

  const uploadCeoPic1 = async (e) => {
    const file = e.target.files[0];
    if (file) {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("fileName", e.target.files[0].name);
      formData.append("path", "Image/");

      let response = await UploadDocument(formData);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        setMyTaskValue1("icon", response.data);
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    }
  };

  const [data, setData] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.title = NonEmptyValidation(myTask?.title);
    validate.icon = NonEmptyValidation(myTask?.icon);
    validate.description = NonEmptyValidation(myTask?.description);
    validate.mainServiceId = NonEmptyValidation(myTask?.mainServiceId);
    // validate.displayOrder = NonEmptyValidation(myTask?.displayOrder);
    if (data === "Man Power") {
      validate.subServiceL1Id = NonEmptyValidation(myTask?.subServiceL1Id);
    }

    setMyTaskValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        masterType: "subServiceL2",
        title: myTask.title,
        icon: myTask.icon,
        description: myTask.description,
        mainServiceId: myTask.mainServiceId,
        displayOrder: myTask.displayOrder,
        subServiceL1Id: myTask.subServiceL1Id,
        addBy: state?.auth?.auth?._id,
      };
      const token = state?.auth?.auth?.token;
      const response = await CreateMain(Payload, token);
      if (response && response.statusCode === 200) {
        setNewTask(true);
        GetMyTsksAll("active");
        showToastSuccessMsg(response.message);
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } else {
      //console.log("Error while update user details");
    }
  };

  const handleSubmitUpdate = async (e, value, id) => {
    e.preventDefault();
    const Payload = {
      _id: id,
      status: value,
      addBy: state?.auth?.auth?._id,
    };
    const token = state?.auth?.auth?.token;
    const response = await UpdateMain(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      if (value !== "active") {
        setSelectedTab("Active Level 2 Services");
        GetMyTsksAll("active");
      } else {
        setSelectedTab("In- Active Level 2 Services");
        GetMyTsksAll("inactive");
      }
      // GetMyTsks();
      //  window.location.reload()
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setfailureModal(true);
    }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.title = NonEmptyValidation(myTask1?.title);
    validate.icon = NonEmptyValidation(myTask1?.icon);
    validate.description = NonEmptyValidationDes(myTask1?.description);
    setMyTaskValidation1(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      let html;

      let Payload;

      html = draftToHtml(convertToRaw(myTask1.description.getCurrentContent()));

      Payload = {
        _id: myTask1._id,
        title: myTask1.title,
        icon: myTask1.icon,
        description: html,
        mainServiceId: myTask1.mainServiceId,
        subServiceL1Id: myTask1.subServiceL1Id,
        status: myTask1.status,
        masterType: "subServiceL2",
        addBy: state?.auth?.auth?._id,
      };

      const token = state?.auth?.auth?.token;
      const response = await UpdateMain(Payload, token);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        setEditModal(false);
        GetMyTsksAll("active");
        window.scrollTo(0, 0);
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } else {
      //console.log("Error while update user details");
    }
  };

  const handleSubmitUpdateDO = async (e, value, id) => {
    e.preventDefault();
    const Payload = {
      _id: id,
      displayOrder: value,
      addBy: state?.auth?.auth?._id,
    };
    const token = state?.auth?.auth?.token;
    const response = await UpdateMain(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      if (value !== "active") {
        setSelectedTab("Active Main Service");
        GetMyTsksAll("active");
      } else {
        setSelectedTab("Inactive Main Service");
        GetMyTsksAll("inactive");
      }
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };
  useEffect(() => {
    getUserProfile();
  }, []);
  const [userProfile, setUserProfile] = useState([]);
  const getUserProfile = async () => {
    // const id = state?.auth?.auth?._id;
    const token = state.auth.auth.token;
    const response = await GetAllSubAdminAndAdmin(token);
    if (response.statusCode === 200) {
      setUserProfile(response.data);
    } else {
      //console.log("error while getting user profile info");
    }
  };

  const PinkSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: pink[600],
      "&:hover": {
        backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: pink[600],
    },
  }));

  const label = { inputProps: { "aria-label": "Color switch demo" } };

  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "mainServiceId",
      label: "Main Service",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <div style={{ textTransform: "capitalize" }}>{value}</div>
            </div>
          );
        },
      },
    },
    {
      name: "subServiceL1Id",
      label: "Level 1",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <div style={{ textTransform: "capitalize" }}>{value}</div>
            </div>
          );
        },
      },
    },

    {
      name: "title",
      label: "Title",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "icon",
      label: "Icon",
      options: {
        customBodyRender: (value) => {
          return (
            <img style={{ width: "50px", height: "50px" }} src={value} alt="" />
          );
        },
      },
    },
    {
      name: "addBy",
      label: "Added By",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              {value}
              {/* {userProfile
                ?.filter((e) => e._id === value)
                ?.map((name) => (
                  <div
                    style={{ cursor: "pointer", textTransform: "capitalize" }}
                  >
                    {name?.name}
                  </div>
                ))} */}
            </div>
          );
        },
      },
    },
    {
      name: "displayOrder",
      label: "Display Order",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <input
                type="number"
                className="form-control form-control-lg"
                placeholder="Enter Display Order"
                defaultValue={value}
                onChange={(e) => {
                  handleSubmitUpdateDO(e, e.target.value, tableMeta.rowData[0]);
                }}
                autoComplete="off"
              />
            </div>
          );
        },
      },
    },
    // {
    //   name: "description",
    //   label: "Description",
    //   options: {
    //     customBodyRender: (value) => {
    //       return <div dangerouslySetInnerHTML={{
    //         __html: value.substring(0, 100),
    //       }}></div>;
    //     },
    //   },
    // },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="row">
              <div className="col-4">
                {value === "inactive" ? (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <PinkSwitch
                          {...label}
                          onChange={(e) => {
                            handleSubmitUpdate(
                              e,
                              "active",
                              tableMeta.rowData[0]
                            );
                          }}
                        />
                      }
                    />
                  </FormGroup>
                ) : (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <PinkSwitch
                          {...label}
                          defaultChecked
                          onChange={(e) => {
                            handleSubmitUpdate(
                              e,
                              "inactive",
                              tableMeta.rowData[0]
                            );
                          }}
                        />
                      }
                    />
                  </FormGroup>
                )}
              </div>
              <div className="col-4 mt-2"></div>
              <div className="col-4 mt-2">
                <i
                  onClick={() => {
                    GetMySpecificTsks(tableMeta.rowData[0]);
                    setEditModal(true);
                    //  navigate(`/JobEdit/${tableMeta.rowData[0]}`)
                  }}
                  className="fa fa-edit"
                  style={{
                    color: "#2596be",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
              <div className="col-4 mt-2"></div>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    setRowProps: (row) => {
      if (row[5] === false) {
        return {
          style: { background: "#DBDFEA", cursor: "pointer" },
        };
      } else {
        return {
          style: { background: "white", cursor: "pointer" },
        };
      }
    },
  };

  return (
    <div>
      <ToastContainer />
      <AdminNavbar />
      <div className="main">
        {loading ? (
          <Spinner />
        ) : (
          <>
            {newTask ? (
              <div>
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="mb-0 fw-bold color-maroon">Level 2</h5>
                  <div>
                    <button
                      type="button"
                      className="btn py-1 login-btn"
                      onClick={() => {
                        setNewTask(false);
                        localStorage.removeItem("main");
                        localStorage.removeItem("level2");
                      }}
                    >
                      Create Level 2
                    </button>
                    {/* <select
              className="form-control"
              style={{textTransform:"capitalize"}}
              value={main}
              onChange={(e) => {
                setMain(e.target.value)
                GetMyTsksLevel1("active",e.target.value)
              }}
            >
              <option value="">---Select Main Service---</option>
             {getMyTaskMain?.map((e)=>(
              <option  value={e._id}>{e.title}</option>
             ))} 
              
            </select>
            <select
              className="form-control"
              style={{textTransform:"capitalize"}}
              value={main1}
              onChange={(e) => {
                setMain1(e.target.value)
                GetMyTsks("active",main,e.target.value);
              }}
            >
              <option value="">---Select Level 1---</option>
             {getMyTasklevel1?.map((e)=>(
              <option  value={e._id}>{e.title}</option>
             ))} 
              
            </select> */}
                  </div>
                </div>
                <div className="my-4">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-7 px-0">
                        <div className="tab-section">
                          <ul className="ps-0">
                            {AllTabs.map((tab, index) => {
                              return (
                                <div
                                  key={index}
                                  className={
                                    selectedTab === tab
                                      ? "serviceTab active"
                                      : "serviceTab inactive"
                                  }
                                  onClick={() => {
                                    GetMyTsksAll(
                                      tab === "In- Active Level 2 Services"
                                        ? "inactive"
                                        : "active"
                                    );
                                    setSelectedTab(tab);
                                  }}
                                >
                                  <li>
                                    <span className="align-middle">{tab}</span>
                                  </li>
                                </div>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <MUIDataTable
                    title="Dalmaf - Level 2 Services"
                    data={getMyTask}
                    columns={columns}
                    options={options}
                  />
                </div>
              </div>
            ) : (
              <div>
                <div className="">
                  <button
                    type="button"
                    className="btn login-btn ms-2 "
                    onClick={() => {
                      setNewTask(true);
                    }}
                  >
                    Back
                  </button>
                </div>
                <div className=" my-4">
                  <div className="bg-historytablehead rounded-3 py-3 px-3">
                    <h6 className="mb-0">Create Level 2</h6>
                  </div>
                  <div className="container">
                    <div className="row justify-content-center py-5">
                      <div className="col-lg-11">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">Main Service</label>
                              <select
                                className="form-control"
                                style={{ textTransform: "capitalize" }}
                                aria-invalid={Boolean(
                                  myTaskValidation?.mainServiceId?.status ===
                                    false
                                )}
                                value={myTask?.mainServiceId}
                                onChange={(e) => {
                                  setMyTaskValue(
                                    "mainServiceId",
                                    e.target.value
                                  );
                                  GetMyTsksLevel1("active", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setMyTaskValidationValue(
                                    "mainServiceId",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                              >
                                <option value="">
                                  ---Select Main Service---
                                </option>
                                {getMyTaskMain?.map((e) => (
                                  <option value={e._id}>{e.title}</option>
                                ))}
                              </select>
                              <small className="text-danger">
                                {myTaskValidation?.mainServiceId?.message
                                  ? `Main Service ${myTaskValidation?.mainServiceId?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">Level 1</label>
                              <select
                                className="form-control"
                                style={{ textTransform: "capitalize" }}
                                aria-invalid={Boolean(
                                  myTaskValidation?.subServiceL1Id?.status ===
                                    false
                                )}
                                value={myTask?.subServiceL1Id}
                                onChange={(e) => {
                                  setData(e.target.name);
                                  setMyTaskValue(
                                    "subServiceL1Id",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setMyTaskValidationValue(
                                    "subServiceL1Id",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                              >
                                <option value="">---Select Level 1---</option>
                                {getMyTasklevel1?.map((e) => (
                                  <option name={e.title} value={e._id}>
                                    {e.title}
                                  </option>
                                ))}
                              </select>
                              <small className="text-danger">
                                {myTaskValidation?.subServiceL1Id?.message
                                  ? `Level 1 Service ${myTaskValidation?.subServiceL1Id?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">Title</label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter Title"
                                aria-invalid={Boolean(
                                  myTaskValidation?.title?.status === false
                                )}
                                value={myTask?.title}
                                onChange={(e) => {
                                  setMyTaskValue("title", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setMyTaskValidationValue(
                                    "title",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {myTaskValidation?.title?.message
                                  ? `Title ${myTaskValidation?.title?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">Icon</label>
                              <input
                                type="file"
                                className="form-control"
                                id="file"
                                ref={CeoRef}
                                onChange={(e) => {
                                  uploadCeoPic(e);
                                }}
                              />
                              <small className="text-danger">
                                {myTaskValidation?.icon?.message
                                  ? `Icon ${myTaskValidation?.icon?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">
                                Display Order
                              </label>
                              <input
                                type="number"
                                className="form-control form-control-lg"
                                placeholder="Enter Display Order"
                                aria-invalid={Boolean(
                                  myTaskValidation?.displayOrder?.status ===
                                    false
                                )}
                                defaultValue={myTask?.displayOrder}
                                onChange={(e) => {
                                  //console.log(e.target.value);
                                  setMyTaskValue(
                                    "displayOrder",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setMyTaskValidationValue(
                                    "displayOrder",
                                    NumberValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {myTaskValidation?.displayOrder?.message
                                  ? `Display Order ${myTaskValidation?.displayOrder?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">Description</label>
                              <div
                                className="form-control form-control-lg"
                                style={{ height: "250px" }}
                              >
                                <Editor
                                  wrapperClassName="wrapper-class"
                                  editorClassName="editor-class"
                                  toolbarClassName="toolbar-class"
                                  editorState={editorState}
                                  onEditorStateChange={(e) =>
                                    handleEditorChange(e)
                                  }
                                />
                              </div>

                              <small className="text-danger">
                                {myTaskValidation?.description?.message
                                  ? `Description ${myTaskValidation?.description?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <button
                              type="button"
                              className="btn white-btn me-2 "
                              onClick={() => {
                                setMyTask({});
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="btn login-btn ms-2 "
                              onClick={(e) => {
                                handleSubmit(e);
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <CustomModal
              open={editModal}
              onClickOutside={() => {
                setEditModal(false);
              }}
            >
              <div className="modal-content w-75 m-auto">
                <div className="modal-body p-0 ">
                  <div className="container">
                    <span
                      className="material-symbols-rounded filled-icon close-icon"
                      style={{
                        marginRight: "30px",
                        marginTop: "50px",
                        textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                      }}
                      onClick={() => {
                        setEditModal(false);
                      }}
                    >
                      close
                    </span>
                    <div className="row d-flex align-items-center justify-content-center  mt-0">
                      <div className="container ">
                        <div className="row justify-content-center">
                          <div className="col-lg-12">
                            <div className=" my-5 px-3">
                              <div className="row justify-content-center mt-2">
                                <div className="col-lg-12">
                                  <div className="admin-service">
                                    <div
                                      className="small-banner-img-head"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <h5 className="w-100 h-auto text-center">
                                        Update Level 2
                                      </h5>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Main Service
                                          </label>
                                          <select
                                            className="form-control"
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                            aria-invalid={Boolean(
                                              myTaskValidation1?.mainServiceId
                                                ?.status === false
                                            )}
                                            defaultValue={
                                              myTask1?.mainServiceId?.title
                                            }
                                            onChange={(e) => {
                                              let name = getMyTaskMain?.filter(
                                                (s) =>
                                                  s.title === e.target.value
                                              );
                                              let nameId = name?.map((n) => {
                                                return n._id;
                                              });
                                              setMyTaskValue1(
                                                "mainServiceId",
                                                nameId.toString()
                                              );
                                              GetMyTsksLevel1(
                                                "active",
                                                nameId.toString()
                                              );
                                            }}
                                            onBlur={(e) => {
                                              let name = getMyTaskMain?.filter(
                                                (s) =>
                                                  s.title === e.target.value
                                              );
                                              let nameId = name?.map((n) => {
                                                return n._id;
                                              });
                                              setMyTaskValidationValue1(
                                                "mainServiceId",
                                                NonEmptyValidation(
                                                  nameId.toString()
                                                )
                                              );
                                            }}
                                          >
                                            {getMyTaskMain?.map((e) => (
                                              <option value={e.title}>
                                                {e.title}
                                              </option>
                                            ))}
                                          </select>
                                          <small className="text-danger">
                                            {myTaskValidation1?.mainServiceId
                                              ?.message
                                              ? `Main Service ${myTaskValidation1?.mainServiceId?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-12">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Level 1
                                          </label>
                                          <select
                                            className="form-control"
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                            aria-invalid={Boolean(
                                              myTaskValidation1?.subServiceL1Id
                                                ?.status === false
                                            )}
                                            defaultValue={
                                              myTask1?.subServiceL1Id?.title
                                            }
                                            onChange={(e) => {
                                              let name =
                                                getMyTasklevel1?.filter(
                                                  (s) =>
                                                    s.title === e.target.value
                                                );
                                              let nameId = name?.map((n) => {
                                                return n._id;
                                              });
                                              setMyTaskValue1(
                                                "subServiceL1Id",
                                                nameId.toString()
                                              );
                                            }}
                                            onBlur={(e) => {
                                              let name =
                                                getMyTasklevel1?.filter(
                                                  (s) =>
                                                    s.title === e.target.value
                                                );
                                              let nameId = name?.map((n) => {
                                                return n._id;
                                              });
                                              setMyTaskValidationValue1(
                                                "subServiceL1Id",
                                                NonEmptyValidation(
                                                  nameId.toString()
                                                )
                                              );
                                            }}
                                          >
                                            <option
                                              value="
                                            "
                                            >
                                              ---Select Service---
                                            </option>
                                            {getMyTasklevel1?.map((e) => (
                                              <option value={e.title}>
                                                {e.title}
                                              </option>
                                            ))}
                                          </select>
                                          <small className="text-danger">
                                            {myTaskValidation1?.subServiceL1Id
                                              ?.message
                                              ? `Level 1 Service ${myTaskValidation1?.subServiceL1Id?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-12">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Title
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            placeholder="Enter Title"
                                            aria-invalid={Boolean(
                                              myTaskValidation1?.title
                                                ?.status === false
                                            )}
                                            defaultValue={myTask1?.title}
                                            onChange={(e) => {
                                              setMyTaskValue1(
                                                "title",
                                                e.target.value
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setMyTaskValidationValue1(
                                                "title",
                                                NonEmptyValidation(
                                                  e.target.value
                                                )
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {myTaskValidation1?.title?.message
                                              ? `Title ${myTaskValidation1?.title?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-12">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Icon
                                          </label>
                                          <input
                                            type="file"
                                            className="form-control"
                                            id="file"
                                            ref={CeoRef1}
                                            onChange={(e) => {
                                              uploadCeoPic1(e);
                                            }}
                                          />
                                          <small className="text-danger">
                                            {myTaskValidation1?.icon?.message
                                              ? `Icon ${myTaskValidation1?.icon?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-12">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Display Order
                                          </label>
                                          <input
                                            type="number"
                                            className="form-control form-control-lg"
                                            placeholder="Enter Display Order"
                                            aria-invalid={Boolean(
                                              myTaskValidation1?.displayOrder
                                                ?.status === false
                                            )}
                                            defaultValue={myTask1?.displayOrder}
                                            onChange={(e) => {
                                              setMyTaskValue1(
                                                "displayOrder",
                                                e.target.value
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setMyTaskValidationValue1(
                                                "displayOrder",
                                                NumberValidation(e.target.value)
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {myTaskValidation1?.displayOrder
                                              ?.message
                                              ? `Display Order ${myTaskValidation1?.displayOrder?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-12">
                                        <div className="mb-4">
                                          <img
                                            style={{
                                              width: "50px",
                                              height: "50px",
                                            }}
                                            src={myTask1?.icon}
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-12">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Description
                                          </label>
                                          <div
                                            className="form-control form-control-lg"
                                            style={{ height: "250px" }}
                                          >
                                            <Editor
                                              wrapperClassName="wrapper-class"
                                              editorClassName="editor-class"
                                              toolbarClassName="toolbar-class"
                                              editorState={myTask1?.description}
                                              onEditorStateChange={(e) =>
                                                setMyTaskValue1(
                                                  "description",
                                                  e
                                                )
                                              }
                                            />
                                          </div>

                                          <small classtaskDescription="text-danger">
                                            {myTaskValidation1?.description
                                              ?.message
                                              ? `Description ${myTaskValidation1?.description?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>

                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label ">
                                            Status
                                          </label>

                                          <select
                                            className="form-control form-control-lg"
                                            defaultValue={myTask1?.status}
                                            onChange={(e) => {
                                              setMyTaskValue1(
                                                "status",
                                                e.target.value
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setMyTaskValidationValue1(
                                                "status",
                                                NonEmptyValidation(
                                                  e.target.value
                                                )
                                              );
                                            }}
                                          >
                                            <option value="active">
                                              Active
                                            </option>

                                            <option value="inactive">
                                              Inactive
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-lg-12">
                                        <button
                                          type="button"
                                          className="btn login-btn ms-2 "
                                          onClick={(e) => {
                                            handleUpdateSubmit(e);
                                          }}
                                        >
                                          Update
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CustomModal>
          </>
        )}
      </div>
    </div>
  );
}
