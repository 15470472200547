import React, { useEffect } from 'react';
import Footer from '../Components/Footer/Footer'
import Navigation from '../Components/Navbar/Navigation'
import ServicesBanner from '../Components/Services.js/ServicesBanner'
import ServicesContent from '../Components/Services.js/ServicesContent'
import {Helmet} from "react-helmet";
export default function Services() {
    useEffect(() =>{
        window.scroll(0,0)
      },[])

  return (
    <div className="innerpages-nav">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Service | DALMaf Private Limited</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="DALMaf Service" />
        <meta name="keywords" content="DALMaf" />
        <meta name="author" content="DALMaf" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>
      <div id="container">
      <Navigation/>
      <ServicesBanner/>
      <ServicesContent/>
      <Footer/>
      </div>
    </div>
  )
}
