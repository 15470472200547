import React, { useEffect, useState } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {
  GetUser,
  GetLevel2,
  GetLevel1,
  GetMain,
  GetContent,
  GetSpecificContent,
  CreateContent,
  UpdateContent,
  GetContentAll,
  GetLevel2All,
  GetLevel1All,
  GetAllSubAdminAndAdmin,
} from "../../../Api/Api";

import { alpha, styled } from "@mui/material/styles";
import { pink } from "@mui/material/colors";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useSelector } from "react-redux";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  NonEmptyEditorValidationn,
  NonEmptyValidation,
  NonEmptyValidationDes,
  NumberValidation,
  StringValidation,
} from "../../../Store/validate";

import CustomModal from "../../../Components/CustomModal";
import Spinner from "../../../Spinner/Spinner";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { NonEmptyKeyValidation } from "../../../Store/validate";
import JoditEditor from "jodit-react";
import SunEditor from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';

let htmlToDraft = null;
if (typeof window === "object") {
  htmlToDraft = require("html-to-draftjs").default;
}

export default function Content() {
  const [selectedTab, setSelectedTab] = React.useState("Active Contents");
  const AllTabs = ["Active Contents", "Inactive Contents"];
  const navigate = useNavigate();
  const [newTask, setNewTask] = useState(true);
  const [editModal, setEditModal] = useState(false);

  const [loading, setLoading] = useState(true);

  const [getMyTask, setGetMyTask] = useState([]);

  const state = useSelector((state) => state);

  const [getMyTaskMain, setGetMyTaskMain] = useState([]);
  const [getMyTasklevel1, setGetMyTasklevel1] = useState([]);
  const [getMyTasklevel2, setGetMyTasklevel2] = useState([]);
  const GetMyTsksMain = async () => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await GetMain("active");
    if (data && data.statusCode === 200) {
      setGetMyTaskMain(data.data);
      setLoading(false);
    } else {
      setGetMyTaskMain([]);
      setLoading(false);
      //console.log("Error while getting my task");
    }
  };

  const GetMyTsksLevel1 = async (status, id) => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await GetLevel1(status, id);
    if (data && data.statusCode === 200) {
      setGetMyTasklevel1(data.data);
      setLoading(false);
    } else {
      setGetMyTasklevel1([]);
      setLoading(false);
      //console.log("Error while getting my task");
    }
  };
  const GetMyTsksLevel1All = async (status) => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await GetLevel1All(status);
    if (data && data.statusCode === 200) {
      setGetMyTasklevel1(data.data);
      setLoading(false);
    } else {
      setGetMyTasklevel1([]);
      setLoading(false);
      //console.log("Error while getting my task");
    }
  };

  const GetMyTsksLevel2 = async (status, id, id1) => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await GetLevel2(status, id, id1);
    if (data && data.statusCode === 200) {
      setGetMyTasklevel2(data.data);
      setLoading(false);
    } else {
      setGetMyTasklevel2([]);
      setLoading(false);
      //console.log("Error while getting my task");
    }
  };

  const GetMyTsksLevel2All = async (status) => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await GetLevel2All(status);
    if (data && data.statusCode === 200) {
      setGetMyTasklevel2(data.data);
      setLoading(false);
    } else {
      setGetMyTasklevel2([]);
      setLoading(false);
      //console.log("Error while getting my task");
    }
  };

  const GetMyTsks = async (status, id) => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await GetContent(status, id);
    if (data && data.statusCode === 200) {
      setGetMyTask(data.data);
      setLoading(false);
    } else {
      setGetMyTask([]);
      setLoading(false);
      //console.log("Error while getting my task");
    }
  };

  const GetMyTsksAll = async (status) => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await GetContentAll(status);
    if (data && data.statusCode === 200) {
      let temp = [];
      data.data.map((data, i) => {
        let tempId = userProfile[i]?._id;
        let addBy = userProfile?.filter((e)=>e._id===data.addBy)?.map((name)=>{
          return name.name
        })
        let dummy = {
          mainServiceId: data?.mainServiceId?.title,
          subServiceL1Id: data?.subServiceL1Id?.title,
          subServiceL2Id: data?.subServiceL2Id?.title,
          status: data?.status,
          _id: data?._id,
          // content:data.content,
          addBy: addBy,
          // addBy: data?.addBy?.name,
        };
        // console.log(userProfile[i]._id)
        temp.push(dummy);
        return null;
      });
      //console.log(temp);
      setGetMyTask(temp);
      // setGetMyTask(data.data);
      setLoading(false);
    } else {
      setGetMyTask([]);
      setLoading(false);
      //console.log("Error while getting my task");
    }
  };

  const [main, setMain] = useState("");
  const [main1, setMain1] = useState("");
  const [main2, setMain2] = useState("");

  const [myTask1, setMyTask1] = useState({});
  const [price, setPrice] = useState(false);
  const [price1, setPrice1] = useState(false);
  const [myTaskValidation1, setMyTaskValidation1] = useState({});

  const setMyTaskValue1 = (parentKey, childKey, value, type) => {
    if (type === "object") {
      setMyTask1({
        ...myTask1,
        ...{ [parentKey]: { ...myTask1[parentKey], [childKey]: value } },
      });
      if (myTaskValidation1[parentKey]) delete myTaskValidation1[parentKey];
      if (myTaskValidation1[childKey]) delete myTaskValidation1[childKey];
    } else {
      setMyTask1({
        ...myTask1,
        [childKey]: value,
      });
      if (myTaskValidation1[parentKey]) delete myTaskValidation1[parentKey];
      if (myTaskValidation1[childKey]) delete myTaskValidation1[childKey];
    }
  };

  const setMyTaskValidationValue1 = (parentKey, childKey, value, type) => {
    if (type === "object") {
      setMyTaskValidation1({
        ...myTaskValidation1,
        ...{
          [parentKey]: {
            ...myTaskValidation1[parentKey],
            [childKey]: value,
          },
        },
      });
    } else {
      setMyTaskValidation1({
        ...myTaskValidation1,
        [childKey]: value,
      });
    }
  };
  const GetMySpecificTsks = async (id) => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await GetSpecificContent(id);
    if (data && data.statusCode === 200) {
      let dummy = data.data;
      let cmsDescription = EditorState.createEmpty();
      if (dummy.content) {
        let blocksFromHtml = htmlToDraft(dummy.content);
        let { contentBlocks, entityMap } = blocksFromHtml;
        let contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        cmsDescription = EditorState.createWithContent(contentState);
      }
      dummy.content = cmsDescription;
      let cmsDescription1 = EditorState.createEmpty();
      if (dummy.serviceIncludedIn) {
        let blocksFromHtml = htmlToDraft(dummy.serviceIncludedIn);
        let { contentBlocks, entityMap } = blocksFromHtml;
        let contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        cmsDescription1 = EditorState.createWithContent(contentState);
      }
      dummy.serviceIncludedIn = cmsDescription1;
      setMyTask1(dummy);
      setLoading(false);
    } else {
      setMyTask1([]);
      setLoading(false);
      //console.log("Error while getting my specific task");
    }
  };

  const [profile, setProfileDetails] = useState([]);
  async function fetchDataProfile() {
    setLoading(true);
    const id = state?.auth?.auth?._id;
    const token = state?.auth?.auth?.token;
    const getBasicDetails = await GetUser(token, id);
    if (getBasicDetails && getBasicDetails?.statusCode === 200) {
      setProfileDetails(getBasicDetails.data);
      setLoading(false);
    } else {
      setProfileDetails([]);
      setLoading(false);
      //console.log("error while getting user profile info");
    }
  }

  useEffect(() => {
    GetMyTsksMain();
    GetMyTsksAll("active");
    fetchDataProfile();
    GetMyTsksLevel1All("active");
    GetMyTsksLevel2All("active");
  }, []);

  const [myTask, setMyTask] = useState({});
  const [content, setContent] = useState("");
  const [content1, setContent1] = useState("");
  const [myTaskValidation, setMyTaskValidation] = useState({});

  const setMyTaskValue = (key, value) => {
    setMyTask({ ...myTask, [key]: value });
    if (myTaskValidation[key]) delete myTaskValidation[key];
  };

  const setMyTaskValidationValue = (key, value) => {
    setMyTaskValidation({ ...myTaskValidation, [key]: value });
  };

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 3000,
    });
  };

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleEditorChange = (state) => {
    setEditorState(state);
    let isNotEmpty = state && state.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(convertToRaw(state.getCurrentContent()));
      setMyTaskValue("content", html);
    }
  };

  const [editorState1, setEditorState1] = useState(() =>
    EditorState.createEmpty()
  );

  const handleEditorChange1 = (state) => {
    setEditorState1(state);
    let isNotEmpty = state && state.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(convertToRaw(state.getCurrentContent()));
      setMyTaskValue("serviceIncludedIn", html);
    }
  };
// console.log(myTask)
  const [data, setData] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(myTask?.content);
    let validate = {};
    validate.mainServiceId = NonEmptyValidation(myTask?.mainServiceId);
    validate.subServiceL1Id = NonEmptyValidation(myTask?.subServiceL1Id);
    if (!data === "Man Power") {
      validate.subServiceL2Id = NonEmptyValidation(myTask?.subServiceL2Id);
    }
   
    // validate.content = NonEmptyEditorValidationn(content);
    // validate.dynamicPricingFlag = NonEmptyKeyValidation(myTask?.dynamicPricingFlag);
    // validate.dynamicServiceFlag = NonEmptyKeyValidation(myTask?.dynamicServiceFlag);
    //console.log(validate);
    setMyTaskValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        content: content,
        mainServiceId: myTask.mainServiceId,
        subServiceL1Id: myTask.subServiceL1Id,
        subServiceL2Id: myTask.subServiceL2Id,
        dynamicPricingFlag: myTask.dynamicPricingFlag === true ? true : false,
        addBy: state?.auth?.auth?._id,
        dynamicServiceFlag: myTask.dynamicServiceFlag === true ? true : false,
        serviceIncludedIn: content1,
        pricing: {
          marketPrice: myTask.marketPrice,
          companyPrice: myTask.companyPrice,
          gst: myTask.gst,
        },
      };
      const token = state?.auth?.auth?.token;
      const response = await CreateContent(Payload, token);
      if (response && response.statusCode === 200) {
        setNewTask(true);
        GetMyTsksAll("active");
        showToastSuccessMsg(response.message);
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } else {
      //console.log("Error while update user details");
    }
  };

  const handleSubmitUpdate = async (e, value, id) => {
    e.preventDefault();
    const Payload = {
      _id: id,
      status: value,
      addBy: state?.auth?.auth?._id,
    };
    const token = state?.auth?.auth?.token;
    const response = await UpdateContent(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      if (value !== "active") {
        setSelectedTab("Active Contents");
        GetMyTsksAll("active");
      } else {
        setSelectedTab("Inactive Contents");
        GetMyTsksAll("inactive");
      }
      // GetMyTsks();
      //  window.location.reload()
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setfailureModal(true);
    }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    // let validate = {};
    // validate.content = NonEmptyValidationDes(myTask1?.content);
    // setMyTaskValidation1(validate);
    // if (Object.values(validate).every((v) => v.status === true)) {
    let html;
    let html1;
    let Payload;
    html = draftToHtml(convertToRaw(myTask1.content.getCurrentContent()));
    html1 = draftToHtml(
      convertToRaw(myTask1.serviceIncludedIn.getCurrentContent())
    );

    Payload = {
      _id: myTask1._id,
      status: myTask1.status,
      content: html,
      mainServiceId: myTask1.mainServiceId,
      subServiceL1Id: myTask1.subServiceL1Id,
      subServiceL2Id: myTask1.subServiceL2Id,
      dynamicPricingFlag: myTask1.dynamicPricingFlag,
      dynamicServiceFlag: myTask1.dynamicServiceFlag,
      serviceIncludedIn: html1,
      addBy: state?.auth?.auth?._id,
      pricing: {
        marketPrice: myTask1.pricing.marketPrice,
        companyPrice: myTask1.pricing.companyPrice,
        gst: myTask1.pricing.gst,
      },
    };

    const token = state?.auth?.auth?.token;
    const response = await UpdateContent(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      setEditModal(false);
      GetMyTsksAll(myTask1.status);
      window.scrollTo(0, 0);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
    // } else {
    //   //console.log("Error while update user details");
    // }
  };

  const PinkSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: pink[600],
      "&:hover": {
        backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: pink[600],
    },
  }));

  const label = { inputProps: { "aria-label": "Color switch demo" } };
  useEffect(() => {
    getUserProfile();
  }, []);
  const [userProfile, setUserProfile] = useState([]);
  const getUserProfile = async () => {
    // const id = state?.auth?.auth?._id;
    const token = state.auth.auth.token;
    const response = await GetAllSubAdminAndAdmin(token);
    if (response.statusCode === 200) {
      setUserProfile(response.data);
    } else {
      //console.log("error while getting user profile info");
    }
  };
  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "mainServiceId",
      label: "Main Service",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <div style={{ textTransform: "capitalize" }}>{value}</div>
            </div>
          );
        },
      },
    },
    {
      name: "subServiceL1Id",
      label: "Level 1",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <div style={{ textTransform: "capitalize" }}>{value}</div>
            </div>
          );
        },
      },
    },
    {
      name: "subServiceL2Id",
      label: "Level 2",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <div style={{ textTransform: "capitalize" }}>{value}</div>
            </div>
          );
        },
      },
    },
    {
      name: "addBy",
      label: "Added By",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              {value}
              {/* {userProfile
                ?.filter((e) => e._id === value)
                ?.map((name) => (
                  <div
                    style={{ cursor: "pointer", textTransform: "capitalize" }}
                  >
                   
                    {name?.name}
                  </div>
                ))} */}
            </div>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="row">
              <div className="col-4">
                {value === "inactive" ? (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <PinkSwitch
                          {...label}
                          onChange={(e) => {
                            handleSubmitUpdate(
                              e,
                              "active",
                              tableMeta.rowData[0]
                            );
                          }}
                        />
                      }
                    />
                  </FormGroup>
                ) : (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <PinkSwitch
                          {...label}
                          defaultChecked
                          onChange={(e) => {
                            handleSubmitUpdate(
                              e,
                              "inactive",
                              tableMeta.rowData[0]
                            );
                          }}
                        />
                      }
                    />
                  </FormGroup>
                )}
              </div>
              <div className="col-4"></div>
              <div className="col-4 mt-2">
                <i
                  onClick={() => {
                    // GetMySpecificTsks(tableMeta.rowData[0]);
                    // setEditModal(true);
                    navigate(`/ContentEdit/${tableMeta.rowData[0]}`);
                  }}
                  className="fa fa-edit"
                  style={{
                    color: "#2596be",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
              <div className="col-4 mt-2"></div>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    setRowProps: (row) => {
      if (row[5] === false) {
        return {
          style: { background: "#DBDFEA", cursor: "pointer" },
        };
      } else {
        return {
          style: { background: "white", cursor: "pointer" },
        };
      }
    },
  };

  return (
    <div>
      <ToastContainer />
      <AdminNavbar />
      <div className="main">
        {loading ? (
          <Spinner />
        ) : (
          <>
            {newTask ? (
              <div>
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="mb-0 fw-bold color-maroon">Content</h5>
                  <div>
                    <button
                      type="button"
                      className="btn py-1 login-btn"
                      onClick={() => {
                        setNewTask(false);
                      }}
                    >
                      Create Content
                    </button>
                    {/* <select
              className="form-control"
              style={{textTransform:"capitalize"}}
              value={main}
              onChange={(e) => {
                setMain(e.target.value)
                GetMyTsksLevel1("active",e.target.value)
              }}
            >
              <option value="">---Select Main Service---</option>
             {getMyTaskMain?.map((e)=>(
              <option  value={e._id}>{e.title}</option>
             ))} 
              
            </select> */}
                    {/* <select
              className="form-control"
              style={{textTransform:"capitalize"}}
              value={main1}
              onChange={(e) => {
                setMain1(e.target.value)
                GetMyTsksLevel2("active",main,e.target.value);
              }}
            >
              <option value="">---Select Level 1---</option>
             {getMyTasklevel1?.map((e)=>(
              <option  value={e._id}>{e.title}</option>
             ))} 
              
            </select>
            <select
              className="form-control"
              style={{textTransform:"capitalize"}}
              value={main2}
              onChange={(e) => {
                setMain2(e.target.value)
                GetMyTsks("active",e.target.value);
              }}
            >
              <option value="">---Select Level 2---</option>
             {getMyTasklevel2?.map((e)=>(
              <option  value={e._id}>{e.title}</option>
             ))} 
              
            </select> */}
                  </div>
                </div>
                <div className="my-4">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-7 px-0">
                        <div className="tab-section">
                          <ul className="ps-0">
                            {AllTabs.map((tab, index) => {
                              return (
                                <div
                                  key={index}
                                  className={
                                    selectedTab === tab
                                      ? "serviceTab active"
                                      : "serviceTab inactive"
                                  }
                                  onClick={() => {
                                    GetMyTsksAll(
                                      tab === "Inactive Contents"
                                        ? "inactive"
                                        : "active"
                                    );
                                    setSelectedTab(tab);
                                  }}
                                >
                                  <li>
                                    <span className="align-middle">{tab}</span>
                                  </li>
                                </div>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <MUIDataTable
                    data={getMyTask}
                    columns={columns}
                    options={options}
                  />
                </div>
              </div>
            ) : (
              <div>
                <div className="">
                  <button
                    type="button"
                    className="btn login-btn ms-2 "
                    onClick={() => {
                      setNewTask(true);
                    }}
                  >
                    Back
                  </button>
                </div>
                <div className=" my-4">
                  <div className="bg-historytablehead rounded-3 py-3 px-3">
                    <h6 className="mb-0">Create Content</h6>
                  </div>
                  <div className="container">
                    <div className="row justify-content-center py-5">
                      <div className="col-lg-11">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">Main Service</label>
                              <select
                                className="form-control"
                                style={{ textTransform: "capitalize" }}
                                aria-invalid={Boolean(
                                  myTaskValidation?.mainServiceId?.status ===
                                    false
                                )}
                                value={myTask?.mainServiceId}
                                onChange={(e) => {
                                  setData(e.target.name);
                                  setMyTaskValue(
                                    "mainServiceId",
                                    e.target.value
                                  );
                                  GetMyTsksLevel1("active", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setMyTaskValidationValue(
                                    "mainServiceId",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                              >
                                <option value="">
                                  ---Select Main Service---
                                </option>
                                {getMyTaskMain?.map((e) => (
                                  <option name={e.title} value={e._id}>
                                    {e.title}
                                  </option>
                                ))}
                              </select>
                              <small className="text-danger">
                                {myTaskValidation?.mainServiceId?.message
                                  ? `Main Service ${myTaskValidation?.mainServiceId?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">Level 1</label>
                              <select
                                className="form-control"
                                style={{ textTransform: "capitalize" }}
                                aria-invalid={Boolean(
                                  myTaskValidation?.subServiceL1Id?.status ===
                                    false
                                )}
                                value={myTask?.subServiceL1Id}
                                onChange={(e) => {
                                  setMyTaskValue(
                                    "subServiceL1Id",
                                    e.target.value
                                  );
                                  GetMyTsksLevel2(
                                    "active",
                                    myTask?.mainServiceId,
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setMyTaskValidationValue(
                                    "subServiceL1Id",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                              >
                                <option value="">---Select Level 1---</option>
                                {getMyTasklevel1?.map((e) => (
                                  <option value={e._id}>{e.title}</option>
                                ))}
                              </select>
                              <small className="text-danger">
                                {myTaskValidation?.subServiceL1Id?.message
                                  ? `Level 1 Service ${myTaskValidation?.subServiceL1Id?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">Level 2</label>
                              <select
                                className="form-control"
                                style={{ textTransform: "capitalize" }}
                                aria-invalid={Boolean(
                                  myTaskValidation?.subServiceL2Id?.status ===
                                    false
                                )}
                                value={myTask?.subServiceL2Id}
                                onChange={(e) => {
                                  setMyTaskValue(
                                    "subServiceL2Id",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setMyTaskValidationValue(
                                    "subServiceL2Id",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                              >
                                <option value="">---Select Level 2---</option>
                                {getMyTasklevel2?.map((e) => (
                                  <option value={e._id}>{e.title}</option>
                                ))}
                              </select>
                              <small className="text-danger">
                                {myTaskValidation?.subServiceL2Id?.message
                                  ? `Level 2 Service ${myTaskValidation?.subServiceL2Id?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">Content</label>
                              <div
                                className="form-control form-control-lg"
                                // style={{ height: "600px", overflowY: "scroll" }}
                              >
                                {/* <JoditEditor
                                              
                                              value={myTask?.content}
                                              config={{
                                                uploader: {
                                                  insertImageAsBase64URI: true,
                                                }, // Exclude the 'image' button
                                              }}
                                              onChange={(e) => {
                                                setMyTaskValue(
                                                "content", e
                                                );
                                              }}
                                              onBlur={(newContent) =>
                                                setMyTaskValue1("content", newContent)
                                              }
                                            /> */}
                                <SunEditor
                                  setOptions={{
                                    height: 400,
                                    buttonList: [
                                      ["undo", "redo"],
                                      ["font", "fontSize", "formatBlock"],
                                      [
                                        "bold",
                                        "underline",
                                        "italic",
                                        "strike",
                                        "subscript",
                                        "superscript",
                                      ],
                                      ["fontColor", "hiliteColor", "textStyle"],
                                      ["removeFormat"],
                                      "/", // Line break
                                      [
                                        "align",
                                        "horizontalRule",
                                        "list",
                                        "lineHeight",
                                      ],
                                      ["table", "link", "image", "video"],
                                      ["fullScreen", "showBlocks", "codeView"],
                                      ["preview", "print"],
                                      ["save", "template"],
                                    ],
                                  }}
                                  setContents={content}
                                  onChange={(content) => {
                                    setContent(content);
                                  }}
                                />
                              </div>

                              <small className="text-danger">
                                {myTaskValidation?.content?.message
                                  ? `Content ${myTaskValidation?.content?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-4">
                              <input
                                type="checkbox"
                                defaultValue={myTask?.dynamicPricingFlag}
                                defaultChecked={
                                  myTask?.dynamicPricingFlag === true
                                }
                                onChange={(e) => {
                                  setMyTaskValue(
                                    "dynamicPricingFlag",
                                    e.target.checked
                                  );

                                  if (e.target.checked === true) {
                                    setPrice(true);
                                  } else {
                                    setPrice(false);
                                  }
                                }}
                              />{" "}
                              <label className="form-label">
                                {" "}
                                Dynamic Pricing Flag
                              </label>
                            </div>
                          </div>

                          {price || myTask?.dynamicPricingFlag === true ? (
                            <>
                              <div className="col-lg-12">
                                <div className="mb-4">
                                  <label className="form-label">
                                    Market Price
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    required
                                    aria-invalid={Boolean(
                                      myTaskValidation?.marketPrice?.status ===
                                        false
                                    )}
                                    value={myTask?.marketPrice}
                                    onChange={(e) => {
                                      setMyTaskValue(
                                        "marketPrice",
                                        e.target.value
                                      );
                                    }}
                                    onBlur={(e) => {
                                      setMyTaskValidationValue(
                                        "marketPrice",
                                        NumberValidation(e.target.value)
                                      );
                                    }}
                                    autoComplete="off"
                                  />

                                  <small className="text-danger">
                                    {myTaskValidation?.marketPrice?.message
                                      ? `Market Price ${myTaskValidation?.marketPrice?.message}`
                                      : ""}
                                  </small>
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="mb-4">
                                  <label className="form-label">
                                    Company Price
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    required
                                    aria-invalid={Boolean(
                                      myTaskValidation?.companyPrice?.status ===
                                        false
                                    )}
                                    value={myTask?.companyPrice}
                                    onChange={(e) => {
                                      setMyTaskValue(
                                        "companyPrice",
                                        e.target.value
                                      );
                                    }}
                                    onBlur={(e) => {
                                      setMyTaskValidationValue(
                                        "companyPrice",
                                        NumberValidation(e.target.value)
                                      );
                                    }}
                                    autoComplete="off"
                                  />

                                  <small className="text-danger">
                                    {myTaskValidation?.companyPrice?.message
                                      ? `Company Price ${myTaskValidation?.companyPrice?.message}`
                                      : ""}
                                  </small>
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="mb-4">
                                  <label className="form-label">GST</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    required
                                    aria-invalid={Boolean(
                                      myTaskValidation?.gst?.status === false
                                    )}
                                    value={myTask?.gst}
                                    onChange={(e) => {
                                      setMyTaskValue("gst", e.target.value);
                                    }}
                                    onBlur={(e) => {
                                      setMyTaskValidationValue(
                                        "gst",
                                        NumberValidation(e.target.value)
                                      );
                                    }}
                                    autoComplete="off"
                                  />

                                  <small className="text-danger">
                                    {myTaskValidation?.gst?.message
                                      ? `GST ${myTaskValidation?.gst?.message}`
                                      : ""}
                                  </small>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          <div className="col-lg-12">
                            <div className="mb-4">
                              <input
                                type="checkbox"
                                defaultValue={myTask?.dynamicServiceFlag}
                                onChange={(e) => {
                                  setMyTaskValue(
                                    "dynamicServiceFlag",
                                    e.target.checked
                                  );

                                  if (e.target.checked === true) {
                                    setPrice1(true);
                                  } else {
                                    setPrice1(false);
                                  }
                                }}
                              />{" "}
                              <label className="form-label">
                                Dynamic Service Flag
                              </label>
                            </div>
                          </div>
                          {price1 || myTask?.dynamicServiceFlag === true ? (
                            <>
                              <div className="col-lg-12">
                                <div className="mb-4">
                                  <label className="form-label">
                                    Service Included In
                                  </label>
                                  <div
                                    className="form-control form-control-lg"
                                    // style={{
                                    //   height: "400px",
                                    //   overflowY: "scroll",
                                    // }}
                                  >
                                    {/* <JoditEditor
                                              
                                              value={myTask?.serviceIncludedIn}
                                              config={{
                                                uploader: {
                                                  insertImageAsBase64URI: true,
                                                }, // Exclude the 'image' button
                                              }}
                                              onChange={(e) => {
                                                setMyTaskValue("serviceIncludedIn", e);
                                              }}
                                              onBlur={(e) =>
                                                setMyTaskValue("serviceIncludedIn", e)
                                              }
                                            /> */}
                                    {/* <Editor
                                      wrapperClassName="wrapper-class"
                                      editorClassName="editor-class"
                                      toolbarClassName="toolbar-class"
                                      editorState={editorState1}
                                      onEditorStateChange={(e) =>
                                        handleEditorChange1(e)
                                      }
                                    /> */}
                                       <SunEditor
                                  setOptions={{
                                    height: 400,
                                    buttonList: [
                                      ["undo", "redo"],
                                      ["font", "fontSize", "formatBlock"],
                                      [
                                        "bold",
                                        "underline",
                                        "italic",
                                        "strike",
                                        "subscript",
                                        "superscript",
                                      ],
                                      ["fontColor", "hiliteColor", "textStyle"],
                                      ["removeFormat"],
                                      "/", // Line break
                                      [
                                        "align",
                                        "horizontalRule",
                                        "list",
                                        "lineHeight",
                                      ],
                                      ["table", "link", "image", "video"],
                                      ["fullScreen", "showBlocks", "codeView"],
                                      ["preview", "print"],
                                      ["save", "template"],
                                    ],
                                  }}
                                  setContents={content1}
                                  onChange={(content) => {
                                    setContent1(content);
                                  }}
                                />
                                  </div>

                                  <small className="text-danger">
                                    {myTaskValidation?.serviceIncludedIn
                                      ?.message
                                      ? `Service Included In ${myTaskValidation?.serviceIncludedIn?.message}`
                                      : ""}
                                  </small>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          <div className="col-lg-12">
                            <button
                              type="button"
                              className="btn white-btn me-2 "
                              onClick={() => {
                                setMyTask({});
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="btn login-btn ms-2 "
                              onClick={(e) => {
                                handleSubmit(e);
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <CustomModal
              open={editModal}
              onClickOutside={() => {
                setEditModal(false);
              }}
            >
              <div className="modal-content w-100 m-auto">
                <div className="modal-body p-0 ">
                  <div className="container">
                    <span
                      className="material-symbols-rounded filled-icon close-icon"
                      style={{
                        marginRight: "30px",
                        marginTop: "50px",
                        textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                      }}
                      onClick={() => {
                        setEditModal(false);
                      }}
                    >
                      close
                    </span>
                    <div className="row d-flex align-items-center justify-content-center  mt-0">
                      <div className="container ">
                        <div className="row justify-content-center">
                          <div className="col-lg-12">
                            <div className=" my-5 px-3">
                              <div className="row justify-content-center mt-2">
                                <div className="col-lg-12">
                                  <div className="admin-service">
                                    <div
                                      className="small-banner-img-head"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <h5 className="w-100 h-auto text-center">
                                        Update Content
                                      </h5>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <div className="mb-4">
                                          <label>Main Service</label>
                                          <select
                                            className="form-control"
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                            aria-invalid={Boolean(
                                              myTaskValidation1?.mainServiceId
                                                ?.status === false
                                            )}
                                            // disabled
                                            defaultValue={
                                              myTask1?.mainServiceId?.title
                                            }
                                            onChange={(e) => {
                                              let name =
                                                getMyTasklevel1?.filter(
                                                  (s) =>
                                                    s.title === e.target.value
                                                );
                                              let nameId = name?.map((n) => {
                                                return n._id;
                                              });
                                              setMyTaskValue1(
                                                "",
                                                "mainServiceId",
                                                nameId.toString(),
                                                ""
                                              );
                                              GetMyTsksLevel1(
                                                "active",
                                                nameId.toString()
                                              );
                                            }}
                                            onBlur={(e) => {
                                              let name =
                                                getMyTasklevel1?.filter(
                                                  (s) =>
                                                    s.title === e.target.value
                                                );
                                              let nameId = name?.map((n) => {
                                                return n._id;
                                              });
                                              setMyTaskValidationValue1(
                                                "",
                                                "mainServiceId",
                                                NonEmptyValidation(
                                                  nameId.toString()
                                                ),
                                                ""
                                              );
                                            }}
                                          >
                                            {getMyTaskMain?.map((e) => (
                                              <option value={e.title}>
                                                {e.title}
                                              </option>
                                            ))}
                                          </select>
                                          <small className="text-danger">
                                            {myTaskValidation1?.mainServiceId
                                              ?.message
                                              ? `Main Service ${myTaskValidation1?.mainServiceId?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-12">
                                        <div className="mb-4">
                                          <label>Level 1</label>
                                          <select
                                            className="form-control"
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                            aria-invalid={Boolean(
                                              myTaskValidation1?.subServiceL1Id
                                                ?.status === false
                                            )}
                                            // disabled
                                            defaultValue={
                                              myTask1?.subServiceL1Id?.title
                                            }
                                            onChange={(e) => {
                                              let name =
                                                getMyTasklevel1?.filter(
                                                  (s) =>
                                                    s.title === e.target.value
                                                );
                                              let nameId = name?.map((n) => {
                                                return n._id;
                                              });
                                              setMyTaskValue1(
                                                "",
                                                "subServiceL1Id",
                                                nameId.toString(),
                                                ""
                                              );
                                              GetMyTsksLevel2(
                                                "active",
                                                myTask1?.mainServiceId,
                                                nameId.toString()
                                              );
                                            }}
                                            onBlur={(e) => {
                                              let name =
                                                getMyTasklevel1?.filter(
                                                  (s) =>
                                                    s.title === e.target.value
                                                );
                                              let nameId = name?.map((n) => {
                                                return n._id;
                                              });
                                              setMyTaskValidationValue(
                                                "",
                                                "subServiceL1Id",
                                                NonEmptyValidation(
                                                  nameId.toString()
                                                ),
                                                ""
                                              );
                                            }}
                                          >
                                            {getMyTasklevel1?.map((e) => (
                                              <option value={e.title}>
                                                {e.title}
                                              </option>
                                            ))}
                                          </select>
                                          <small className="text-danger">
                                            {myTaskValidation1?.subServiceL1Id
                                              ?.message
                                              ? `Level 1 Service ${myTaskValidation1?.subServiceL1Id?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-12">
                                        <div className="mb-4">
                                          <label>Level 2</label>
                                          <select
                                            className="form-control"
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                            aria-invalid={Boolean(
                                              myTaskValidation1?.subServiceL2Id
                                                ?.status === false
                                            )}
                                            // disabled
                                            defaultValue={
                                              myTask1?.subServiceL2Id?.title
                                            }
                                            onChange={(e) => {
                                              let name =
                                                getMyTasklevel1?.filter(
                                                  (s) =>
                                                    s.title === e.target.value
                                                );
                                              let nameId = name?.map((n) => {
                                                return n._id;
                                              });
                                              setMyTaskValue1(
                                                "",
                                                "subServiceL2Id",
                                                nameId.toString(),
                                                ""
                                              );
                                            }}
                                            onBlur={(e) => {
                                              let name =
                                                getMyTasklevel1?.filter(
                                                  (s) =>
                                                    s.title === e.target.value
                                                );
                                              let nameId = name?.map((n) => {
                                                return n._id;
                                              });
                                              setMyTaskValidationValue1(
                                                "",
                                                "subServiceL2Id",
                                                NonEmptyValidation(
                                                  nameId.toString()
                                                ),
                                                ""
                                              );
                                            }}
                                          >
                                            {getMyTasklevel2?.map((e) => (
                                              <option value={e.title}>
                                                {e.title}
                                              </option>
                                            ))}
                                          </select>
                                          <small className="text-danger">
                                            {myTaskValidation1?.subServiceL2Id
                                              ?.message
                                              ? `Level 2 Service ${myTaskValidation1?.subServiceL2Id?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-12">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Content
                                          </label>
                                          <div
                                            className="form-control form-control-lg"
                                            style={{
                                              height: "400px",
                                              overflowY: "scroll",
                                            }}
                                          >
                                            {/* <JoditEditor
                                              
                                              value={myTask1?.content}
                                              config={{
                                                uploader: {
                                                  insertImageAsBase64URI: true,
                                                }, // Exclude the 'image' button
                                              }}
                                              onChange={(e) => {
                                                setMyTaskValue1(
                                                  "","content", e,""
                                                );
                                              }}
                                              onBlur={(newContent) =>
                                                setMyTaskValue1("","content", newContent,"")
                                              }
                                            /> */}
                                            <Editor
                                              wrapperClassName="wrapper-class"
                                              editorClassName="editor-class"
                                              toolbarClassName="toolbar-class"
                                              editorState={myTask1?.content}
                                              onEditorStateChange={(e) =>
                                                setMyTaskValue1(
                                                  "",
                                                  "content",
                                                  e,
                                                  ""
                                                )
                                              }
                                            />
                                          </div>

                                          <small className="text-danger">
                                            {myTaskValidation1?.content?.message
                                              ? `Content ${myTaskValidation1?.content?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>

                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label ">
                                            Status
                                          </label>

                                          <select
                                            className="form-control form-control-lg"
                                            defaultValue={myTask1?.status}
                                            onChange={(e) => {
                                              setMyTaskValue1(
                                                "",
                                                "status",
                                                e.target.value,
                                                ""
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setMyTaskValidationValue1(
                                                "",
                                                "status",
                                                NonEmptyValidation(
                                                  e.target.value
                                                ),
                                                ""
                                              );
                                            }}
                                          >
                                            <option value="active">
                                              Active
                                            </option>

                                            <option value="inactive">
                                              Inactive
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-lg-12">
                                        <div className="mb-4">
                                          <input
                                            type="checkbox"
                                            defaultChecked={
                                              myTask1?.dynamicPricingFlag
                                            }
                                            defaultValue={
                                              myTask1?.dynamicPricingFlag
                                            }
                                            onChange={(e) => {
                                              setMyTaskValue1(
                                                "",
                                                "dynamicPricingFlag",
                                                e.target.value,
                                                ""
                                              );
                                              if (e.target.checked === true) {
                                                setPrice(true);
                                              } else {
                                                setPrice(false);
                                              }
                                            }}
                                          />{" "}
                                          <label>Dynamic Pricing Flag</label>
                                        </div>
                                      </div>
                                      {myTask1?.pricing || price ? (
                                        <>
                                          <div className="col-lg-6">
                                            <div className="mb-4">
                                              <label>Market Price</label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                required
                                                aria-invalid={Boolean(
                                                  myTaskValidation1?.pricing
                                                    ?.marketPrice?.status ===
                                                    false
                                                )}
                                                defaultValue={
                                                  myTask1?.pricing?.marketPrice
                                                }
                                                onChange={(e) => {
                                                  setMyTaskValue1(
                                                    "pricing",
                                                    "marketPrice",
                                                    e.target.value,
                                                    "object"
                                                  );
                                                }}
                                                onBlur={(e) => {
                                                  setMyTaskValidationValue1(
                                                    "pricing",
                                                    "marketPrice",
                                                    NumberValidation(
                                                      e.target.value
                                                    ),
                                                    "object"
                                                  );
                                                }}
                                                autoComplete="off"
                                              />

                                              <small className="text-danger">
                                                {myTaskValidation1?.pricing
                                                  ?.marketPrice?.message
                                                  ? `Market Price ${myTaskValidation1?.pricing?.marketPrice?.message}`
                                                  : ""}
                                              </small>
                                            </div>
                                          </div>
                                          <div className="col-lg-6">
                                            <div className="mb-4">
                                              <label>Company Price</label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                required
                                                aria-invalid={Boolean(
                                                  myTaskValidation1?.pricing
                                                    ?.companyPrice?.status ===
                                                    false
                                                )}
                                                defaultValue={
                                                  myTask1?.pricing?.companyPrice
                                                }
                                                onChange={(e) => {
                                                  setMyTaskValue1(
                                                    "pricing",
                                                    "companyPrice",
                                                    e.target.value,
                                                    "object"
                                                  );
                                                }}
                                                onBlur={(e) => {
                                                  setMyTaskValidationValue1(
                                                    "pricing",
                                                    "companyPrice",
                                                    NumberValidation(
                                                      e.target.value
                                                    ),
                                                    "object"
                                                  );
                                                }}
                                                autoComplete="off"
                                              />

                                              <small className="text-danger">
                                                {myTaskValidation1?.pricing
                                                  ?.companyPrice?.message
                                                  ? `Company Price ${myTaskValidation1?.pricing?.companyPrice?.message}`
                                                  : ""}
                                              </small>
                                            </div>
                                          </div>
                                          <div className="col-lg-6">
                                            <div className="mb-4">
                                              <label>GST</label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                required
                                                aria-invalid={Boolean(
                                                  myTaskValidation1?.pricing
                                                    ?.gst?.status === false
                                                )}
                                                defaultValue={
                                                  myTask1?.pricing?.gst
                                                }
                                                onChange={(e) => {
                                                  setMyTaskValue1(
                                                    "pricing",
                                                    "gst",
                                                    e.target.value,
                                                    "object"
                                                  );
                                                }}
                                                onBlur={(e) => {
                                                  setMyTaskValidationValue1(
                                                    "pricing",
                                                    "gst",
                                                    NumberValidation(
                                                      e.target.value
                                                    ),
                                                    "object"
                                                  );
                                                }}
                                                autoComplete="off"
                                              />

                                              <small className="text-danger">
                                                {myTaskValidation1?.pricing?.gst
                                                  ?.message
                                                  ? `GST ${myTaskValidation1?.pricing?.gst?.message}`
                                                  : ""}
                                              </small>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      <div className="col-lg-12">
                                        <div className="mb-4">
                                          <input
                                            type="checkbox"
                                            defaultValue={
                                              myTask1?.dynamicServiceFlag
                                            }
                                            defaultChecked={
                                              myTask1?.dynamicServiceFlag ===
                                              true
                                            }
                                            onChange={(e) => {
                                              setMyTaskValue1(
                                                "",
                                                "dynamicServiceFlag",
                                                e.target.checked,
                                                ""
                                              );

                                              if (e.target.checked === true) {
                                                setPrice1(true);
                                              } else {
                                                setPrice1(false);
                                              }
                                            }}
                                          />{" "}
                                          <label>Dynamic Service Flag</label>
                                        </div>
                                      </div>
                                      {price1 ||
                                      myTask1?.dynamicServiceFlag === true ? (
                                        <>
                                          <div className="col-lg-12">
                                            <div className="mb-4">
                                              <label className="form-label">
                                                Service Included In
                                              </label>
                                              <div
                                                className="form-control form-control-lg"
                                                style={{
                                                  height: "400px",
                                                  overflowY: "scroll",
                                                }}
                                              >
                                                {/* <JoditEditor
                                              
                                              value={myTask1?.serviceIncludedIn}
                                              config={{
                                                uploader: {
                                                  insertImageAsBase64URI: true,
                                                }, // Exclude the 'image' button
                                              }}
                                              onChange={(e) => {
                                                setMyTaskValue1(
                                                  "","serviceIncludedIn", e,""
                                                );
                                              }}
                                              onBlur={(newContent) =>
                                                setMyTaskValue1("","serviceIncludedIn", newContent,"")
                                              }
                                            /> */}
                                                <Editor
                                                  wrapperClassName="wrapper-class"
                                                  editorClassName="editor-class"
                                                  toolbarClassName="toolbar-class"
                                                  editorState={
                                                    myTask1?.serviceIncludedIn
                                                  }
                                                  onEditorStateChange={(e) =>
                                                    setMyTaskValue1(
                                                      "",
                                                      "serviceIncludedIn",
                                                      e,
                                                      ""
                                                    )
                                                  }
                                                />
                                              </div>

                                              <small className="text-danger">
                                                {myTaskValidation1
                                                  ?.serviceIncludedIn?.message
                                                  ? `Service Included In ${myTaskValidation1?.serviceIncludedIn?.message}`
                                                  : ""}
                                              </small>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      <div className="col-lg-12">
                                        <button
                                          type="button"
                                          className="btn login-btn ms-2 "
                                          onClick={(e) => {
                                            handleUpdateSubmit(e);
                                          }}
                                        >
                                          Update
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CustomModal>
          </>
        )}
      </div>
    </div>
  );
}
