import React, { useState, useEffect } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";

import OwlCarousel from "react-owl-carousel";
import { useParams } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import {
  GetAllEmployeeRole,
  Adminspecificlist,
  GetUser,
  UserProfileUpdate,
  AdminspecificlistScroll,
  AdminspecificlistSRScroll,
  ChangePassword,
} from "../../../Api/Api";
import { useSelector } from "react-redux";
import moment from "moment";

export default function EmployeeProfileDetails() {
  const state = useSelector((state) => state);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    window.scroll(0, 0);
    getEmployeeProfile();
    getSpecificEmployeeProfile();
    getSpecificEmployee2();

    getListEmployeeRole();
  }, []);

  const [employeeProfile, setEmployeeProfile] = useState([]);

  const getEmployeeProfile = async () => {
    const token = state?.auth?.auth?.token;
    const data = await GetUser(token, id);
    if (data && data.statusCode === 200) {
      setEmployeeProfile(data?.data);
    } else {
      setEmployeeProfile([]);
      //console.log("Error while getting user details");
    }
  };

  const [specificEmployee, setSpecificEmployee] = useState([]);
  const getSpecificEmployeeProfile = async () => {
    const token = state?.auth?.auth?.token;
    const data = await AdminspecificlistScroll(token, id);
    if (data && data.statusCode === 200) {
      setSpecificEmployee(data.data);
    } else {
      setSpecificEmployee([]);
      //console.log("Error while getting user details");
    }
  };
  const [specificEmployee2, setSpecificEmployee2] = useState([]);
  const getSpecificEmployee2 = async () => {
    const token = state?.auth?.auth?.token;
    const data = await AdminspecificlistSRScroll(token, id);
    if (data && data.statusCode === 200) {
      setSpecificEmployee2(data.data);
    } else {
      setSpecificEmployee2([]);
      //console.log("Error while getting user details");
    }
  };

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  const handleSubmitUpdate = async (e, value) => {
    e.preventDefault();
    const Payload = {
      _id: id,
      status: value,
    };
    const token = state?.auth?.auth?.token;
    const response = await UserProfileUpdate(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      window.location.reload();
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setfailureModal(true);
    }
  };

  const handleSubmitRole = async (e, value) => {
    e.preventDefault();
    const Payload = {
      _id: id,
      pageAccess: value,
    };
    const token = state?.auth?.auth?.token;
    const response = await UserProfileUpdate(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      window.location.reload();
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setfailureModal(true);
    }
  };

  const [employeeroleList, setEmployeeRoleList] = useState([]);
  const getListEmployeeRole = async (status) => {
    const token = state?.auth?.auth?.token;
    const response = await GetAllEmployeeRole(token, "active");
    if (response.statusCode === 200) {
      setEmployeeRoleList(response.data);
    } else {
      setEmployeeRoleList([]);
    }
  };

  useEffect(() => {
    getEmployeeProfileReq();
  }, []);
  const [employeeReq, setemployeeReq] = useState([]);

  const getEmployeeProfileReq = async () => {
    // setLoading(true);
    // const role = state?.auth?.auth?.role;
    const token = state.auth.auth.token;
    let response = await Adminspecificlist(token, id);

    if (response.statusCode === 200) {
      setemployeeReq(response.data);
      // setLoading(false);
    } else {
      setemployeeReq([]);

      //console.log("error while getting user profile info");
    }
  };

  const handleSubmitChangePassword = async (e) => {
    e.preventDefault();

    const Payload = {
      email: employeeProfile?.email,
      password: "Qwerty@123",
    };

    const response = await ChangePassword(Payload);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };

  return (
    <div>
      <AdminNavbar />
      <ToastContainer />
      <div className="main">
        <div>
          <div className="">
            <div className="row">
              <div className="col-6 text-start">
                <h5 className="mb-0 fw-bold color-maroon">Profile Details</h5>
              </div>
              <div className="col-6 text-end">
                <button
                  className="btn py-1  login-btn"
                  type="button"
                  onClick={(e) => {
                    navigate("/EmployeeManagement");
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
          <div className="">
            <div className="container py-4">
              <div className="row">
                <div className="col-lg-12">
                  <div className="">
                    <div className="profile-head">
                      <div className="profile-head1"></div>
                      <div className="d-flex profile-head2  align-items-center">
                        <div className="flex-shrink-0 ">
                          <img
                            className="admin-pro-img "
                            style={{ width: "100px", height: "100px" }}
                            src={employeeProfile?.profileImage}
                            alt="..."
                          />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <h6>
                            {employeeProfile?.name} {employeeProfile?.lastName}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="pro-body">
                      <div className="container">
                        <div className="row justify-content-center pt-5">
                          <div className="col-lg-5">
                            <div
                              className="bg-grey"
                              style={{
                                padding: "0",
                              }}
                            >
                              <h6 className="color-maroon  fw-bold p-3">
                                Info
                              </h6>
                              {employeeProfile && (
                                <>
                                  <div className="">
                                    <table className="table table-borderless admin-info">
                                      <tbody>
                                        <tr>
                                          <th>Full Name :</th>
                                          <td>{employeeProfile?.name}</td>
                                        </tr>
                                        <tr>
                                          <th>Mobile No :</th>
                                          <td>
                                            {employeeProfile?.phoneNumber}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th>E- Mail ID :</th>
                                          <td>{employeeProfile?.email}</td>
                                        </tr>
                                        <tr>
                                          <th>Created On :</th>
                                          <td>
                                            {moment(
                                              employeeProfile?.createdAt
                                            ).format("DD.MM.YYYY")}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th>Role :</th>
                                          <td>
                                            <div className="toggle-switch">
                                              <select
                                                className="form-control form-control-lg"
                                                value={
                                                  employeeProfile?.pageAccess
                                                    ?._id
                                                }
                                                onChange={(e) => {
                                                  handleSubmitRole(
                                                    e,
                                                    e.target.value
                                                  );
                                                }}
                                              >
                                                {employeeroleList?.map((e) => (
                                                  <option value={e._id}>
                                                    {e.title}
                                                  </option>
                                                ))}
                                              </select>
                                            </div>
                                          </td>
                                        </tr>

                                        <tr>
                                          <th>Status :</th>
                                          <td>
                                            <div className="toggle-switch">
                                              {employeeProfile?.status ===
                                              "active" ? (
                                                <input
                                                  type="checkbox"
                                                  defaultChecked
                                                  onChange={(e) => {
                                                    handleSubmitUpdate(
                                                      e,
                                                      "inactive"
                                                    );
                                                  }}
                                                  id="switch"
                                                />
                                              ) : (
                                                <input
                                                  type="checkbox"
                                                  onChange={(e) => {
                                                    handleSubmitUpdate(
                                                      e,
                                                      "active"
                                                    );
                                                  }}
                                                  id="switch"
                                                />
                                              )}
                                              <label for="switch">Toggle</label>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <th></th>
                                            <div>
                                              <button
                                                className="reset-btn"
                                                style={{
                                                  marginLeft: "14px",
                                                }}
                                                onClick={(e) => {
                                                  handleSubmitChangePassword(e);
                                                }}
                                              >
                                                Reset Password
                                              </button>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-7">
                            <div className="bg-grey p-4">
                              <div className="d-flex justify-content-between">
                                <h6 className="color-maroon  fw-bold">
                                  Task Details
                                </h6>
                                <p
                                  className="mb-0 color-maroon fw-bold cursor text-decoration-underline"
                                  onClick={() => {
                                    navigate(`/EmployeeTaskDetails/${id}`);
                                  }}
                                >
                                  View All
                                </p>
                              </div>
                              <div className="row">
                                <div className="col-lg-12">
                                  {specificEmployee?.length ? (
                                    <>
                                      <div className="task-details-carou pt-4">
                                        <OwlCarousel
                                          className="owl-theme"
                                          loop
                                          autoplay={true}
                                          items="3"
                                          dots={false}
                                          smartSpeed={2000}
                                          nav={true}
                                          margin={20}
                                          center={false}
                                          autoplayTimeout={5000}
                                          responsive={{
                                            360: {
                                              items: "1",
                                            },
                                            414: {
                                              items: "1",
                                            },
                                            670: {
                                              items: "2",
                                            },
                                            992: {
                                              items: "2",
                                            },
                                            1200: {
                                              items: "2",
                                            },
                                          }}
                                        >
                                          {specificEmployee?.map((e, i) => (
                                            <>
                                              {e.status === "Inprogress" ? (
                                                <div
                                                  className="inprogress-section"
                                                  key={i}
                                                >
                                                  <div className="d-flex justify-content-between align-items-center">
                                                    <h6 className="fw-bold color-maroon">
                                                      {e.taskType}{" "}
                                                    </h6>
                                                    <p className="mb-0 small color-yellow">
                                                      {e.status}
                                                    </p>
                                                  </div>
                                                  <div>
                                                    <p className="mb-2 small">
                                                      <span className="fw-bold ">
                                                        Assigned To :
                                                      </span>{" "}
                                                      <span>
                                                        {e?.assignedTo?.name}
                                                      </span>
                                                    </p>
                                                    <div className="d-flex justify-content-between ">
                                                      <p className="mb-2 small">
                                                        <span className="fw-bold ">
                                                          Start Date :
                                                        </span>{" "}
                                                        <span>
                                                          {moment(
                                                            e.startDate
                                                          ).format(
                                                            "DD/MM/YYYY"
                                                          )}
                                                        </span>
                                                      </p>
                                                      <p className="mb-2 small">
                                                        <span className="fw-bold ">
                                                          End Date :
                                                        </span>{" "}
                                                        <span>
                                                          {moment(
                                                            e.endDate
                                                          ).format(
                                                            "DD/MM/YYYY"
                                                          )}
                                                        </span>
                                                      </p>
                                                    </div>
                                                    {/* <p className="mb-2 small">
                                              <span className="fw-bold ">
                                                Last Login :
                                              </span>{" "}
                                              <span>4 Hours Ago</span>
                                            </p> */}
                                                  </div>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                              {e.status === "Completed" ? (
                                                <div className="completed-section">
                                                  <div className="d-flex justify-content-between align-items-center">
                                                    <h6 className="fw-bold color-maroon">
                                                      {e.taskType}{" "}
                                                    </h6>
                                                    <p className="mb-0 color-green2">
                                                      {e.status}
                                                    </p>
                                                  </div>
                                                  <div>
                                                    <p className="mb-2 small">
                                                      <span className="fw-bold ">
                                                        Assigned To :
                                                      </span>{" "}
                                                      <span>
                                                        {e.assignedTo?.name}
                                                      </span>
                                                    </p>
                                                    <div className="d-flex justify-content-between">
                                                      <p className="mb-2 small">
                                                        <span className="fw-bold ">
                                                          Start Date :
                                                        </span>{" "}
                                                        <span>
                                                          {moment(
                                                            e.startDate
                                                          ).format(
                                                            "DD/MM/YYYY"
                                                          )}
                                                        </span>
                                                      </p>
                                                      <p className="mb-2 small">
                                                        <span className="fw-bold ">
                                                          End Date :
                                                        </span>{" "}
                                                        <span>
                                                          {moment(
                                                            e.endDate
                                                          ).format(
                                                            "DD/MM/YYYY"
                                                          )}
                                                        </span>
                                                      </p>
                                                    </div>
                                                    {/* <p className="mb-2 small">
                                               <span className="fw-bold ">
                                                 Last Login :
                                               </span>{" "}
                                               <span>4 Hours Ago</span>
                                             </p> */}
                                                  </div>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                              {e.status === "Rejected" ? (
                                                <div className="rejected-section">
                                                  <div className="d-flex justify-content-between">
                                                    <h6 className="fw-bold color-maroon">
                                                      {e.taskType}{" "}
                                                    </h6>
                                                    <p className="mb-0 color-redish">
                                                      {e.status}
                                                    </p>
                                                  </div>
                                                  <div>
                                                    <p className="mb-2 small">
                                                      <span className="fw-bold ">
                                                        Assigned To :
                                                      </span>{" "}
                                                      <span>
                                                        {/* {e.assignedTo.name} */}
                                                      </span>
                                                    </p>
                                                    <div className="d-flex justify-content-between">
                                                      <p className="mb-2 small">
                                                        <span className="fw-bold ">
                                                          Start Date :
                                                        </span>{" "}
                                                        <span>
                                                          {moment(
                                                            e.startDate
                                                          ).format(
                                                            "DD/MM/YYYY"
                                                          )}
                                                        </span>
                                                      </p>
                                                      <p className="mb-2 small">
                                                        <span className="fw-bold ">
                                                          End Date :
                                                        </span>{" "}
                                                        <span>
                                                          {moment(
                                                            e.endDate
                                                          ).format(
                                                            "DD/MM/YYYY"
                                                          )}
                                                        </span>
                                                      </p>
                                                    </div>
                                                    {/* <p className="mb-2 small">
    <span className="fw-bold ">
      Last Login :
    </span>{" "}
    <span>4 Hours Ago</span>
  </p> */}
                                                  </div>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          ))}
                                        </OwlCarousel>
                                      </div>
                                    </>
                                  ) : (
                                    "No Record Found"
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4"></div>
                            <div className="col-lg-8">
                              <div
                                className="bg-grey"
                                style={{
                                  padding: "0 !important",
                                }}
                              >
                                <div className="d-flex justify-content-between">
                                  <h6 className="color-maroon  fw-bold">
                                    Service Request Details
                                  </h6>
                                  <p
                                    className="mb-0 color-maroon fw-bold cursor text-decoration-underline"
                                    onClick={(e) => {
                                      navigate(`/EmployeeServiceDetails/${id}`);
                                    }}
                                  >
                                    View All
                                  </p>
                                </div>
                                <div className="row">
                                  <div className="col-lg-12">
                                    {specificEmployee2?.length ? (
                                      <>
                                        <div className="task-details-carou pt-4">
                                          <OwlCarousel
                                            className="owl-theme"
                                            loop
                                            autoplay={true}
                                            items="3"
                                            dots={false}
                                            smartSpeed={2000}
                                            nav={true}
                                            margin={20}
                                            center={false}
                                            autoplayTimeout={5000}
                                            responsive={{
                                              360: {
                                                items: "1",
                                              },
                                              414: {
                                                items: "1",
                                              },
                                              670: {
                                                items: "2",
                                              },
                                              992: {
                                                items: "2",
                                              },
                                              1200: {
                                                items: "2",
                                              },
                                            }}
                                          >
                                            {/* {specificEmployee2?.createServicesRequest?.length ? (
                                          <> */}
                                            {specificEmployee2?.map((e, i) => (
                                              <>
                                                {e.status === "inprogress" ? (
                                                  <div
                                                    className="inprogress-section"
                                                    key={i}
                                                  >
                                                    <div className="d-flex justify-content-between">
                                                      <h6 className="fw-bold color-maroon">
                                                        {e?.servicesCategory}{" "}
                                                      </h6>
                                                      <p className="mb-0 small color-yellow">
                                                        {e.status}
                                                      </p>
                                                    </div>
                                                    <div>
                                                      <p className="mb-2 small">
                                                        <span className="fw-bold ">
                                                          User Name :
                                                        </span>{" "}
                                                        <span>{e?.name}</span>
                                                      </p>
                                                      <div className="d-flex justify-content-between">
                                                        <p className="mb-2 small">
                                                          <span className="fw-bold ">
                                                            Start Date :
                                                          </span>{" "}
                                                          <span>
                                                            {moment(
                                                              e.startDate
                                                            ).format(
                                                              "DD/MM/YYYY"
                                                            )}
                                                          </span>
                                                        </p>
                                                        <p className="mb-2 small">
                                                          <span className="fw-bold ">
                                                            End Date :
                                                          </span>{" "}
                                                          <span>
                                                            {moment(
                                                              e.endDate
                                                            ).format(
                                                              "DD/MM/YYYY"
                                                            )}
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                                {e.status === "unassigned" ? (
                                                  <div className="rejected-section">
                                                    <div className="d-flex justify-content-between">
                                                      <h6 className="fw-bold color-maroon">
                                                        {e.servicesCategory}{" "}
                                                      </h6>
                                                      <p className="mb-0 small color-redish">
                                                        {e.status}
                                                      </p>
                                                    </div>
                                                    <div>
                                                      <p className="mb-2 small">
                                                        <span className="fw-bold ">
                                                          Assigned To :
                                                        </span>{" "}
                                                        <span>{e.name}</span>
                                                      </p>
                                                      <div className="d-flex justify-content-between">
                                                        <p className="mb-2 small">
                                                          <span className="fw-bold ">
                                                            Start Date :
                                                          </span>{" "}
                                                          <span>
                                                            {" "}
                                                            {moment(
                                                              e.startDate
                                                            ).format(
                                                              "DD/MM/YYYY"
                                                            )}
                                                          </span>
                                                        </p>
                                                        <p className="mb-2 small">
                                                          <span className="fw-bold ">
                                                            End Date :
                                                          </span>{" "}
                                                          <span>
                                                            {moment(
                                                              e.endDate
                                                            ).format(
                                                              "DD/MM/YYYY"
                                                            )}
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                                {e.status === "completed" ? (
                                                  <div className="completed-section">
                                                    <div className="d-flex justify-content-between">
                                                      <h6 className="fw-bold color-maroon">
                                                        {e?.servicesCategory}{" "}
                                                      </h6>
                                                      <p className="mb-0 small color-yellow">
                                                        {e.status}
                                                      </p>
                                                    </div>
                                                    <div>
                                                      <p className="mb-2 small">
                                                        <span className="fw-bold ">
                                                          User Name :
                                                        </span>{" "}
                                                        <span>{e?.name}</span>
                                                      </p>
                                                      <div className="d-flex justify-content-between">
                                                        <p className="mb-2 small">
                                                          <span className="fw-bold ">
                                                            Start Date :
                                                          </span>{" "}
                                                          <span>
                                                            {moment(
                                                              e.startDate
                                                            ).format(
                                                              "DD/MM/YYYY"
                                                            )}
                                                          </span>
                                                        </p>
                                                        <p className="mb-2 small">
                                                          <span className="fw-bold ">
                                                            End Date :
                                                          </span>{" "}
                                                          <span>
                                                            {moment(
                                                              e.endDate
                                                            ).format(
                                                              "DD/MM/YYYY"
                                                            )}
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                                {e.status === "rejected" ? (
                                                  <div className="rejected-section">
                                                    <div className="d-flex justify-content-between">
                                                      <h6 className="fw-bold color-maroon">
                                                        {e?.servicesCategory}{" "}
                                                      </h6>
                                                      <p className="mb-0 small color-yellow">
                                                        {e.status}
                                                      </p>
                                                    </div>
                                                    <div>
                                                      <p className="mb-2 small">
                                                        <span className="fw-bold ">
                                                          User Name :
                                                        </span>{" "}
                                                        <span>{e?.name}</span>
                                                      </p>
                                                      <div className="d-flex justify-content-between">
                                                        <p className="mb-2 small">
                                                          <span className="fw-bold ">
                                                            Start Date :
                                                          </span>{" "}
                                                          <span>
                                                            {moment(
                                                              e.startDate
                                                            ).format(
                                                              "DD/MM/YYYY"
                                                            )}
                                                          </span>
                                                        </p>
                                                        <p className="mb-2 small">
                                                          <span className="fw-bold ">
                                                            End Date :
                                                          </span>{" "}
                                                          <span>
                                                            {moment(
                                                              e.endDate
                                                            ).format(
                                                              "DD/MM/YYYY"
                                                            )}
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                              </>
                                            ))}
                                            {/* </>
                                        ) : ("No Record Found")} */}
                                          </OwlCarousel>
                                        </div>
                                      </>
                                    ) : (
                                      "No Record Found"
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
