export const actionTypes = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGOUT: "LOGOUT",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  REGISTRATION_DETAILS: "REGISTRATION_DETAILS",
  DELETE_DETAILS: "DELETE_DETAILS",
  UPDATE_USER_DETAILS_KEY: "UPDATE_USER_DETAILS_KEY",
};

export function login(payload, token) {
  return { type: actionTypes.LOGIN_REQUEST, token, payload };
}

export function loginSuccess(payload, token) {
  return { type: actionTypes.LOGIN_SUCCESS, payload, token };
}

export function logOut() {
  return { type: actionTypes.LOGOUT };
}

export function logOutSuccess() {
  return { type: actionTypes.LOGOUT_SUCCESS };
}
export function registerDetails(payload) {
  return { type: actionTypes.REGISTRATION_DETAILS, payload };
}

export function registerDetailsDelete() {
  return { type: actionTypes.DELETE_DETAILS };
}
export function updateSpecificUserDetailsKeyinState(payload) {
  return { type: actionTypes.UPDATE_USER_DETAILS_KEY ,payload};
}
