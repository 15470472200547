import React, { useEffect } from 'react';
import Footer from "../Components/Footer/Footer";
import Navigation from "../Components/Navbar/Navigation";
import BSPlotBanner from "../Components/Buy_Sell/BSPlotBanner";
import BSPlotContent from "../Components/Buy_Sell/BSPlotContent";
export default function BSPlot() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="innerpages-nav">
        <div id="container">
      <Navigation />
      <BSPlotBanner />
      <BSPlotContent />
      <Footer />
      </div>
    </div>
  );
}
