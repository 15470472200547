import React, { useEffect, useState } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";


import {  GetUser,  GetAppliedJob, UpdateJobApplication } from "../../../Api/Api";
import { useSelector } from "react-redux";
import moment from "moment";

import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';

import Switch from "@mui/material/Switch";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import Spinner from "../../../Spinner/Spinner";

import { useNavigate, useParams } from "react-router-dom";
import MUIDataTable from "mui-datatables";

export default function JobApplication() {
 
  const [selectedTab, setSelectedTab] = React.useState("Open");
  const AllTabs = ["Open","In Progress","Rejected","Call for Interview","Selected"];

  const navigate = useNavigate();
  

  const {id} = useParams()

  const [loading, setLoading] = useState(true);

  const [getJobTask, setJobTask] = useState([]);

  const state = useSelector((state) => state);

  //getlead
  const GetJobTsks = async (status) => {
    setLoading(true);
    const token = state.auth.auth.token;
    // const creator_id = state.auth.auth._id;
    let data = await GetAppliedJob(token,id,status);
    if (data && data.statusCode === 200) {
      setJobTask(data.data);
      setLoading(false);
    } else {
       setJobTask([]);
       setLoading(false);
      //console.log("Error while getting my task");
    }
  };

 

  

  
  const [jobProfile, setJobProfileDetails] = useState([]);
  async function fetchjobProfile() {
    setLoading(true);
    const id = state?.auth?.auth?._id;
    const token = state?.auth?.auth?.token;
    const getBasicDetails = await GetUser(token, id);
    if (getBasicDetails && getBasicDetails?.statusCode === 200) {
      setJobProfileDetails(getBasicDetails.data);
      setLoading(false);
    } else {
      setJobProfileDetails([]);
      setLoading(false);
      //console.log("error while getting user jobProfile info");
    }
  }

  useEffect(() => {
    window.scroll(0, 0);

    GetJobTsks("open");
    fetchjobProfile();
  }, []);



  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

 

  const handleJobSubmitUpdate = async (e, value, id,value1) => {
    e.preventDefault();
    const Payload = {
      _id: id,
      status: value,
    };
   
    const token = state?.auth?.auth?.token;
    const response = await UpdateJobApplication(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      GetJobTsks(value1);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setfailureModal(true);
    }
  };

  

  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "firstName",
      label: "Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <div
                style={{ cursor: "pointer" }}
                onClick={(val) => {
                  navigate(`/JobApplicationEdit/${tableMeta.rowData[0]}`);
                }}
              >
                {value}
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "interestRole",
      label: "Interest Role",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "createdAt",
      label: "Created Date",
      options: {
        customBodyRender: (value) => {
          return <div>{moment(value).format("DD/MM/YYYY")}</div>;
        },
      },
    },
    
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value,tableMeta) => {
          return <select 
          className="form-control form-control-lg"
          defaultValue={value}
          onChange={(e) => {
           
            handleJobSubmitUpdate(e,e.target.value,tableMeta.rowData[0],value)
          }}
         
        >
          
          <option value="open">Open</option>
            <option value="inprogress">In Progress</option>
            <option value="reject">Rejected</option>
            <option value="callforinterview">Call for Interview</option>
            <option value="selected">Selected</option>
        
        </select>
        },
      },
    },
    {
      name: "admin_read",
      label: "Status",
      options: {
        display: false,
      },
    },
    {
      name: "subadmin_read",
      label: "Status",
      options: {
        display: false,
      },
    },
    
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    setRowProps: (row) => {
      if (row[5]===false) {
        return {
          style: { background: "#DBDFEA" , cursor:"pointer" },
        };
      } else {
        return {
          style: { background: "white", cursor:"pointer" },
        };
      }
    },
  };

  return (
    <div>
      <ToastContainer />
      <AdminNavbar />
      <div className="main">
        {loading ? (
          <Spinner />
        ) : (
          <div>
            <div className="d-flex justify-content-between">
              <h5 className="mb-0 fw-bold color-maroon">Applied Candidates</h5>

              <div className="row">
                <div className="col-12 text-end">
                  <button
                    type="button"
                    className="btn login-btn ms-2 "
                    onClick={(e) => {
                      navigate("/JobManagement");
                    }}
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
            <div className="my-4">
              <div className="container">
                <div className="row">
                  <div className="col-lg-7 px-0">
                    <div className="tab-section">
                      <ul className="ps-0">
                        {AllTabs.map((tab, index) => {
                          return (
                            <div
                              key={index}
                              className={
                                selectedTab === tab
                                  ? "serviceTab active"
                                  : "serviceTab inactive"
                              }
                              onClick={() => {
                                if (tab === "Rejected") {
                                  GetJobTsks("reject");
                                } else if (tab === "Call for Interview") {
                                  GetJobTsks("callforinterview");
                                } else if (tab === "Open") {
                                  GetJobTsks("open");
                                } else if (tab === "Selected") {
                                  GetJobTsks("selected");
                                } else if (tab === "In Progress") {
                                  GetJobTsks("inprogress");
                                }

                                setSelectedTab(tab);
                              }}
                            >
                              <li>
                                <span className="align-middle">{tab}</span>
                              </li>
                            </div>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <MUIDataTable
                title={"Dalmaf - Applied Candidates "}
                data={getJobTask}
                columns={columns}
                options={options}
              />
              {/* <table className="table">
                    <thead className="bg-historytablehead">
                      <tr>
                        <th scope="col" style={{ borderTopLeftRadius: "0" }}>
                          S. No
                        </th>
                        <th scope="col">client name</th>
                        <th scope="col">service request</th>
                        <th scope="col">Created Date</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>

                    {getJobTask?.map((e, index) => (
                      <tbody className="historytablebody">
                        <tr
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigate(`/LeadEdit/${e._id}`);
                          }}
                        >
                         
                          <td>
                            <div className="">
                              <h6 className="fw-bold">{index + 1}</h6>
                            </div>
                          </td>
                          <td scope="row">
                            <div className="d-flex align-items-center ">
                              <div className=" ms-3">
                                <h6 className="fw-bold">{e?.clientName}</h6>
                              </div>
                            </div>
                          </td>

                          <td>
                            <div className=" ms-3">
                              <h6 className="fw-bold">{e?.serviceRequest}</h6>
                            </div>
                          </td>

                          <td>
                            <h6 className="fw-bold">
                              {moment(e.createdAt).format("DD/MM/YYYY")}
                            </h6>
                          </td>

                          <td>
                            <h6 className="fw-bold color-red">{e.status}</h6>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
