import React, { useEffect, useRef, useState } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";

import Norecord from "../../../Assets/Images/MicrosoftTeams-image.webp";
import { EditorState, convertToRaw } from "draft-js";
import Si3 from "../../../Assets/Images/as3.webp";
import { useSelector } from "react-redux";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CreateOrder,
  CreatePaymentCustPack,
  CreatePaymentPackage,
  GetAllAdminRole,
  GetAllEmployeeRole,
  GetAllSubAdminAndAdmin,
  GetParticularServiceRequest,
  GetServiceRequestByAdmin,
  GetServiceRequestByRole,
  UpdatePaymentCustom,
  UpdateServiceRequest,
  UploadDocument,
  adminservicepakage,
} from "../../../Api/Api";
import moment from "moment";
import {
  EmailValidation,
  NonEmptyValidation,
  PhoneNumberValidation,
  StringValidation,
} from "../../../Store/validate";

import MUIDataTable from "mui-datatables";
import { useNavigate, useParams } from "react-router-dom";
import CustomModal from "../../../Components/CustomModal";
import {
  HandleCCAvenuePaymentCustSrv,
  HandleCCAvenuePaymentSrv,
  generateOrderId,
} from "../../../Store/util";

export default function ServiceRequestEdit() {
  const [ShowTable, setShowTable] = useState(true);
  // const [loading, setLoading] = useState(true);

  const [viewDetails, setViewDetails] = useState(true);
  const { id } = useParams();
  const state = useSelector((state) => state);
  const [onChangeAssignedTo, setOnchagneAssignedTo] = useState(false)
  const [onChangeStatus, setOnChangeStatus] = useState(false)
  const [reassign, setReassign] = useState(true);
  const [serviceReq, setServiceReq] = useState([]);
  const [serviceReqParticular, setServiceReqParticular] = useState({});
  const [serviceReqParticularValidation, setServiceReqParticularValidation] =
    useState({});

  const setServiceReqParticularValue = (parentKey, childKey, value, type) => {
    if (type === "object") {
      setServiceReqParticular({
        ...serviceReqParticular,
        ...{
          [parentKey]: {
            ...serviceReqParticular[parentKey],
            [childKey]: value,
          },
        },
      });
      if (serviceReqParticularValidation[parentKey])
        delete serviceReqParticularValidation[parentKey];
      if (serviceReqParticularValidation[childKey])
        delete serviceReqParticularValidation[childKey];
    } else {
      setServiceReqParticular({ ...serviceReqParticular, [childKey]: value });
      if (serviceReqParticularValidation[parentKey])
        delete serviceReqParticularValidation[parentKey];
      if (serviceReqParticularValidation[childKey])
        delete serviceReqParticularValidation[childKey];
    }
  };
  // useEffect(() => {
  //   getServiceReqParticular();
  // }, [id])
  const setServiceReqParticularValidationValue = (
    parentKey,
    childKey,
    value,
    type
  ) => {
    if (type === "object") {
      setServiceReqParticularValidation({
        ...serviceReqParticularValidation,
        ...{
          [parentKey]: {
            ...serviceReqParticularValidation[parentKey],
            [childKey]: value,
          },
        },
      });
    } else {
      setServiceReqParticularValidation({
        ...serviceReqParticularValidation,
        [childKey]: value,
      });
    }
  };
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [editorState1, setEditorState1] = useState(() =>
    EditorState.createEmpty()
  );
  const [price, setPrice] = useState();
  const [desc, setDesc] = useState();
  const [resolutionCommentValue,setResolutionCommentValue] = useState();
  const handleEditorChange = (state) => {
    setEditorState(state);
    let isNotEmpty = state && state.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(convertToRaw(state.getCurrentContent()));
      setDesc(html);
    }
  };

  const handleEditorChange1 = (state) => {
    setEditorState1(state);
    let isNotEmpty = state && state.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(convertToRaw(state.getCurrentContent()));
      setCommentsValue("comments", html);
      // setDesc(html);
    }
  };

  const handleEditorChange2 = (state) => {
    let isNotEmpty = state && state.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(convertToRaw(state.getCurrentContent()));
      setResolutionCommentValue(html)
      // setServiceReqParticularValue(
      //   "",
      //   "resolutionComment",
      //   html,
      //   ""
      // )
    }
  };

  

  useEffect(() => {
    if (state?.auth?.auth?.role === "superadmin") {
      getServiceReq("unassigned");
    } else if (state?.auth?.auth?.role === "subadmin") {
      getServiceReq("assigned");
    } else {
      getServiceReq("unassigned");
    }
    getListAdmin();
    getServiceReqParticular();
    GetAllSubadmins();
    getListEmployee();
  }, []);
  const [listAdmin, setListAdmin] = useState([]);
  const [listEmployee, setListEmployee] = useState([]);
  const getListAdmin = async () => {
    const token = state?.auth?.auth?.token;
    const response = await GetAllAdminRole(token, "active");
    if (response.statusCode === 200) {
      setListAdmin(response.data);
    } else {
      setListAdmin([]);
    }
  };

  const getListEmployee = async () => {
    const token = state?.auth?.auth?.token;
    const response = await GetAllEmployeeRole(token, "active");
    if (response.statusCode === 200) {
      setListEmployee(response.data);
    } else {
      setListEmployee([]);
    }
  };

  // //console.log(listEmployee)

  const getServiceReq = async (status) => {
    // setLoading(true);
    const role = state?.auth?.auth?.role;
    const id = state?.auth?.auth?._id;
    const token = state.auth.auth.token;
    let response;
    if (state?.auth?.auth?.role === "superadmin") {
      response = await GetServiceRequestByAdmin(token, role, status);
    } else {
      response = await GetServiceRequestByRole(token, role, status);
    }

    if (response.statusCode === 200) {
      let temp = [];
      response.data.map((data, i) => {
        let dummy = {
          _id: data?._id,
          name: data?.name,
          servicesCategory: data?.servicesCategory,
          assigned_by: data?.assigned_by?.name,
          assigned_to: data?.assigned_to?.name,
          // property_id: data?.property_id?.property_type,
          serviceRequestId: data?.serviceRequestId,
          createdAt: moment(data?.createdAt).format("DD/MM/YYYY"),
          status: data?.status,
          admin_read: data?.admin_read,
          subadmin_read: data?.subadmin_read,
        };
        temp.push(dummy);
        return null;
      });
      // setGetMyTask(temp);
      setServiceReq(temp);
      // setServiceReq(response.data);
      // setLoading(false);
    } else {
      setServiceReq([]);

      //console.log("error while getting user profile info");
    }
  };

  const [leadComment, setLeadComment] = useState("");
  const [commentsValidation, setCommentsValidation] = useState({});

  const setCommentsValue = (key, value) => {
    setLeadComment({ ...leadComment, [key]: value });

    if (commentsValidation[key]) delete commentsValidation[key];
  };

  const setCommentsLeadValidationValue = (key, value) => {
    setCommentsValidation({
      ...commentsValidation,

      [key]: value,
    });
  };

  const getServiceReqParticular = async () => {
    // setLoading(true);
    const token = state.auth.auth.token;
    const response = await GetParticularServiceRequest(token, id);
    if (response.statusCode === 200) {
      setServiceReqParticular(response.data);
      // console.log(response.data, "response.data")
      // setLoading(false);
    } else {
      setServiceReqParticular([]);

      //console.log("error while getting user profile info");
    }
  };

  const [allSubadmin, setAllSubadmin] = useState([]);
  const GetAllSubadmins = async () => {
    // setLoading(true);
    const token = state?.auth?.auth?.token;
    let data = await GetAllSubAdminAndAdmin(token);
    if (data && data.statusCode === 200) {
      setAllSubadmin(data.data);
      // setLoading(false);
    } else {
      setAllSubadmin([]);

      //console.log("Error while getting all subadmins");
    }
  };

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  const CeoRef = useRef();
  const uploadCeoPic = async (e) => {
    const file = e.target.files[0];
    if (file) {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("fileName", e.target.files[0].name);
      formData.append("path", "Image/");

      let response = await UploadDocument(formData);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);

        const data = response.data;

        if (serviceReqParticular?.fileUpload?.length) {
          let scheduleData = serviceReqParticular?.fileUpload;
          scheduleData.push(data);
          setServiceReqParticularValue("", "fileuploadTo", scheduleData, "");
        } else {
          setServiceReqParticularValue("", "fileuploadTo", [data], "");
        }
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    }
  };

  //console.log(serviceReqParticular?.fileUpload);
  const [assign, setAssign] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    // if(serviceReqParticular.type==="service"){
    //   validate.name = StringValidation(serviceReqParticular?.name);
    //   validate.email = NonEmptyValidation(
    //     serviceReqParticular?.email?.toLowerCase()
    //   );
    if (serviceReqParticular.status === "unassigned") {
      validate.assigned_to = NonEmptyValidation(
        serviceReqParticular?.assigned_to
      );
    } else {
      validate.assigned_to = NonEmptyValidation(
        serviceReqParticular?.assigned_to?.name
      );
    }

    setServiceReqParticularValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      let Payload;
      let actionType;
      // console.log("onChangeAssignedTo", onChangeAssignedTo)
      // console.log("onChangeStatus", onChangeStatus)
      if (onChangeAssignedTo && onChangeStatus) {
        actionType = 3;
      } else if (onChangeAssignedTo) {
        actionType = 2;
      } else if (onChangeStatus) {
        actionType = 1;
      }
      setOnChangeStatus(false)
      setOnchagneAssignedTo(false)
      if (serviceReqParticular.status === "unassigned") {
        Payload = {
          name: serviceReqParticular.name,
          type: serviceReqParticular.type,
          email: serviceReqParticular.email?.toLowerCase(),
          question: serviceReqParticular.question,
          mobileNumber: serviceReqParticular.mobileNumber,
          servicesCategory: serviceReqParticular.servicesCategory
            ? serviceReqParticular.servicesCategory
            : "",
          servicesSubcategory: serviceReqParticular.servicesSubcategory
            ? serviceReqParticular.servicesSubcategory
            : "",
          servicesTitle: serviceReqParticular.servicesTitle
            ? serviceReqParticular.servicesTitle
            : "",
          assigned_by: state?.auth?.auth?._id,
          assigned_to: serviceReqParticular.assigned_to,
          subject: serviceReqParticular.subject,
          userId: serviceReqParticular?.userId?._id,
          fileuploadTo: serviceReqParticular.fileuploadTo,
          _id: serviceReqParticular._id,
          status: "assigned",
          actionType: actionType
        };
      } else {
        Payload = {
          name: serviceReqParticular.name,
          type: serviceReqParticular.type,
          email: serviceReqParticular.email?.toLowerCase(),
          question: serviceReqParticular.question,
          mobileNumber: serviceReqParticular.mobileNumber,
          servicesCategory: serviceReqParticular.servicesCategory,
          servicesSubcategory: serviceReqParticular.servicesSubcategory,
          servicesTitle: serviceReqParticular.servicesTitle,
          assigned_to: assign
            ? serviceReqParticular.assigned_to?.name
            : serviceReqParticular.assigned_to?._id,
          subject: serviceReqParticular.subject,
          assigned_by: serviceReqParticular?.assigned_by?._id,
          fileuploadTo: serviceReqParticular.fileuploadTo,
          userId: serviceReqParticular?.userId?._id,
          _id: serviceReqParticular._id,
          resolutionComment: resolutionCommentValue,
          status: serviceReqParticular?.status,
          actionType: actionType
        };
      }

      if (serviceReqParticular?.status === "completed") {
        if (resolutionCommentValue && resolutionCommentValue.length > 5) {
          const token = state.auth.auth.token;

          const response = await UpdateServiceRequest(Payload, token);
          if (response && response.statusCode === 200) {
            showToastSuccessMsg(response.message);
            // getServiceReq("assigned");
            // setViewDetails(true);
            // window.location.reload();
          } else {
            showToastErrorMsg(
              response.message || response.error.response.data.message
            );
            //setfailureModal(true);
          }
        } else {
          showToastErrorMsg(
            "Resolution Comment is required"
          );
        }
      } else {
        const token = state.auth.auth.token;

        const response = await UpdateServiceRequest(Payload, token);
        if (response && response.statusCode === 200) {
          showToastSuccessMsg(response.message);
          // getServiceReq("assigned");
          // setViewDetails(true);
          // window.location.reload();
        } else {
          showToastErrorMsg(
            response.message || response.error.response.data.message
          );
          //setfailureModal(true);
        }
      }

    } else {
      //console.log("Error while assign service");
    }
  };
  const [comment, setComment] = useState("");
  const [PackageModal, setPackageModal] = useState(false);
  const handleSubmitComment = async (e) => {
    let validate = {};
    validate.comments = NonEmptyValidation(leadComment?.comments);
    setCommentsValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      e.preventDefault();
      let Payload = {
        _id: serviceReqParticular._id,
        comments: leadComment?.comments,
        actionType: 4
      };

      const token = state.auth.auth.token;

      const response = await UpdateServiceRequest(Payload, token);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        getServiceReqParticular();
        // window.location.reload();
        setComment("");
        e.target.reset();
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        //setfailureModal(true);
      }
    }
  };
  // const handleSubmitDelete = async (e, id, value) => {
  //   e.preventDefault();

  //   const token = state?.auth?.auth?.token;
  //   const response = await DeleteServicereq(id, token);
  //   if (response && response.statusCode === 200) {
  //     showToastSuccessMsg(response.message);

  //     //  window.location.reload()
  //   } else {
  //     showToastErrorMsg(response.message || response.error.response.data.message);
  //     //setfailureModal(true);
  //   }
  // };
  const handleSubmitPayment = async (e) => {
    e.preventDefault();
    let Payload = {
      _id: serviceReqParticular._id,
      payment: true,
    };

    const token = state.auth.auth.token;

    const response = await UpdateServiceRequest(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      getServiceReqParticular();
      // window.location.reload();
      setComment("");
      e.target.reset();
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setfailureModal(true);
    }
  };
  const handleSubmitUpdate = async (e, userId, value) => {
    e.preventDefault();
    let Payload;
    if (value === "subadmin") {
      Payload = {
        _id: userId,
        subadmin_read: true,
        // admin_read:"",
      };
    } else {
      Payload = {
        _id: userId,
        // subadmin_read:"",
        admin_read: true,
      };
    }
    const token = state.auth.auth.token;

    const response = await UpdateServiceRequest(Payload, token);
    if (response && response.statusCode === 200) {
      getServiceReq("assigned");
    } else {
      //console.log("Error");
    }
  };
  const navigate = useNavigate();

  const handleSubmitCustom = async (e, id) => {
    e.preventDefault();
    let Payload = {
      price: Number(price),
      referredBy: state?.auth?.auth?._id,
      title: serviceReqParticular.servicesTitle,
      serviceRequestId: serviceReqParticular.serviceRequestId,
      description: desc,
      package_create_path: "Request-Custom",
      phoneNumber: serviceReqParticular.mobileNumber,
      name: serviceReqParticular.name,
      email: serviceReqParticular.email,
      customPackage: "Custom",
    };

    try {
      const token = state?.auth?.auth?.token;
      const response = await adminservicepakage(token, Payload);
      if (response.statusCode === 200) {
        // showToastSuccessMsg(response.message);
        setPackageModal(false);
        handlePaymentPackage(
          e,
          response?.data?.price,
          "Not Paid",
          "",
          response?.data?._id,
          id
        );
        setPrice("");
        setDesc("");
      } else {
        showToastErrorMsg(
          response.message || response.error?.response?.data?.message
        );
      }
    } catch (error) {
      showToastErrorMsg(error.message);
    }
  };
  // UpdatePaymentCustom
  const handleOrder = async (e, amount, type, id) => {
    e.preventDefault();

    const Payload = {
      price: amount * 100,
    };
    const response = await CreateOrder(Payload);
    if (response && response.statusCode === 200) {
      handleSubmitPaymentt(
        e,
        response?.data?.id,
        response?.data?.amount,
        type,
        id
      );
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };

  const handleSubmitPaymentt = (e, orderId, amount, type, id) => {
    e.preventDefault();
    var options = {
      key: "rzp_test_BPipIoC6fqmzK2",
      key_secret: "vEMlC3fZ6XTSh4i8kq03FYXj",
      order_id: orderId,
      amount: amount,
      currency: "INR",
      description: `Buy ${type}`,
      handler: function (response) {
        if (response) {
          handleUpdatePaymentPackage(e, amount / 100, "Paid", response, id);
        } else {
          //console.log(response);

          handleUpdatePaymentPackage(e, amount / 100, "Failed", " ", id);
        }
      },
      prefill: {
        name: state?.auth?.auth?.name,
        email: state?.auth?.auth?.email,
        contact: state?.auth?.auth?.phoneNumber,
      },
      notes: {
        address: "Razorpay Corporate office",
      },
      theme: {
        color: "#3399cc",
      },
    };
    var pay = new window.Razorpay(options);
    pay.open();
  };
  const handleUpdatePaymentPackage = async (
    e,
    amount,
    paymentStatus,
    paymentResponse,
    id
  ) => {
    e.preventDefault();

    const Payload = {
      payment_id: id,
      paymentInfo: paymentResponse,
      status: paymentStatus,
      price: amount,
    };
    const token = state?.auth?.auth?.token;
    const response = await UpdatePaymentCustom(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      window.location.reload();
      // setBookNow(false)
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };

  const handlePaymentPackage = async (
    e,
    amount,
    paymentStatus,
    paymentResponse,
    id,
    reqId
  ) => {
    e.preventDefault();

    let orderId = generateOrderId("custom-package");

    const Payload = {
      //       purchaseType: type,
      //       packagePurchaseId: id,
      //  price:amount,
      // paymentInfo:paymentResponse,
      // status: paymentStatus
      purchaseType: "custom-package",
      purchasePackageId: id,
      price: amount,
      serviceRequestId: reqId,
      paymentInfo: paymentResponse,
      status: paymentStatus,
      is_logedin: true,
      orderId: orderId,
    };

    const token = state?.auth?.auth?.token;
    const response = await CreatePaymentCustPack(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg('Request Payment has been created Successfully');
      // window.location.reload();
      // setBookNow(false)
      getServiceReqParticular();
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };

  return (
    <div>
      <ToastContainer />
      <AdminNavbar />
      <div className="main">
        <div>
          <div className="">
            <button
              type="button"
              className="btn login-btn ms-2 "
              onClick={(e) => {
                navigate("/ServiceRequest");
              }}
            >
              Back
            </button>
          </div>
          <div className=" my-4">
            <div className="bg-historytablehead rounded-3 py-3 px-3">
              <h6 className="mb-0"> </h6>
            </div>
            <div className="container">
              <div className="row justify-content-center py-5">
                <div className="col-lg-11">
                  {/* <div className="row">
                  <div className="col-6">
                    {" "}
                    <p className="mb-0">
                      {" "}
                      <span
                        style={{ cursor: "pointer" }}
                        className="mb-0 fs-5 fw-bold color-maroon mr-2"
                        onClick={() => {
                          setViewDetails(true);
                          if (state?.auth?.auth?.role === "superadmin") {
                            getServiceReq("assigned");
                          } else {
                            getServiceReq("assigned");
                          }
                        }}
                      >
                        Services Request{" "}
                      </span>{" "}
                      <span className="mb-0 fs-6 color-maroon">
                        {" "}
                        \Service Details
                      </span>{" "}
                    </p>
                  </div>
                  <div className="col-6 text-end">
                    <button
                      className="btn login-btn"
                      onClick={() => {
                        setViewDetails(true);
                        getServiceReq("unassigned");
                      }}
                    >
                      Back
                    </button>
                  </div>
                </div> */}
                  <div className="row">
                    {state?.auth?.auth?.role === "superadmin" ? (
                      <>
                        {serviceReqParticular?.servicesCategory ? (
                          <div>
                            <div className=" my-4">
                              {/* <div className="bg-historytablehead rounded-3 py-3 px-3">
                            <h6 className="mb-0">Update Service</h6>
                          </div> */}
                              <div className="container">
                                <div className="row justify-content-center py-5">
                                  <div className="col-lg-11">
                                    <div className="row">
                                      <div className="col-12">
                                        {serviceReqParticular?.getPurchaseInfo
                                          ?.status === "Paid" ? (
                                          <div className="mb-4 fs-5 fw-bold color-maroon mr-2">
                                            <button className="btn btn-admin">
                                              Paid User
                                            </button>
                                          </div>
                                        ) : (
                                          <div className="mb-4 fs-5 fw-bold color-maroon mr-2">
                                            <button className="btn btn-admin">
                                              Non Paid User
                                            </button>
                                          </div>
                                        )}
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Name
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            placeholder="Enter the Name"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.name?.status === false
                                            )}
                                            value={serviceReqParticular?.name}
                                            onChange={(e) => {
                                              setServiceReqParticularValue(
                                                "",
                                                "name",
                                                e.target.value,
                                                ""
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setServiceReqParticularValidationValue(
                                                "",
                                                "name",
                                                StringValidation(
                                                  e.target.value
                                                ),
                                                ""
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.name?.message
                                              ? `Name ${serviceReqParticularValidation?.name?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Mobile No
                                          </label>
                                          <input
                                            type="tel"
                                            className="form-control form-control-lg"
                                            maxLength={10}
                                            placeholder="Enter the Mobile number"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.mobileNumber?.status === false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.mobileNumber
                                            }
                                            onChange={(e) => {
                                              setServiceReqParticularValue(
                                                "",
                                                "mobileNumber",
                                                e.target.value,
                                                ""
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setServiceReqParticularValidationValue(
                                                "",
                                                "mobileNumber",
                                                PhoneNumberValidation(
                                                  e.target.value
                                                ),
                                                ""
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.mobileNumber?.message
                                              ? `Mobile Number ${serviceReqParticularValidation?.mobileNumber?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Email Id
                                          </label>
                                          <input
                                            type="email"
                                            className="form-control form-control-lg"
                                            placeholder="Enter Email-Id"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.email?.status === false
                                            )}
                                            defaultValue={serviceReqParticular?.email?.toLowerCase()}
                                            onChange={(e) => {
                                              setServiceReqParticularValue(
                                                "",
                                                "email",
                                                e.target.value,
                                                ""
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setServiceReqParticularValidationValue(
                                                "",
                                                "email",
                                                EmailValidation(
                                                  e.target.value?.toLowerCase()
                                                ),
                                                ""
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.email?.message
                                              ? `Email Id ${serviceReqParticularValidation?.email?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Question
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            placeholder="Enter Your Question"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.question?.status === false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.question
                                            }
                                            onChange={(e) => {
                                              setServiceReqParticularValue(
                                                "",
                                                "question",
                                                e.target.value,
                                                ""
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setServiceReqParticularValidationValue(
                                                "",
                                                "question",
                                                NonEmptyValidation(
                                                  e.target.value
                                                ),
                                                ""
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.question?.message
                                              ? `Question ${serviceReqParticularValidation?.question?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>

                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Service Category
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            placeholder="Enter the Service Category"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.servicesCategory?.status ===
                                              false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.servicesCategory
                                            }
                                            onChange={(e) => {
                                              setServiceReqParticularValue(
                                                "",
                                                "servicesCategory",
                                                e.target.value,
                                                ""
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setServiceReqParticularValidationValue(
                                                "",
                                                "servicesCategory",
                                                NonEmptyValidation(
                                                  e.target.value
                                                ),
                                                ""
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.servicesCategory?.message
                                              ? `Category ${serviceReqParticularValidation?.servicesCategory?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Service Subcategory
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            placeholder="enter the Service Subcategory"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.servicesSubcategory
                                                ?.status === false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.servicesSubcategory
                                            }
                                            onChange={(e) => {
                                              setServiceReqParticularValue(
                                                "",
                                                "servicesSubcategory",
                                                e.target.value,
                                                ""
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setServiceReqParticularValidationValue(
                                                "",
                                                "servicesSubcategory",
                                                NonEmptyValidation(
                                                  e.target.value
                                                ),
                                                ""
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.servicesSubcategory?.message
                                              ? `Subcategory ${serviceReqParticularValidation?.servicesSubcategory?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Service Title
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            placeholder="Enter the Service Title"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.servicesTitle?.status ===
                                              false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.servicesTitle
                                            }
                                            onChange={(e) => {
                                              setServiceReqParticularValue(
                                                "",
                                                "servicesTitle",
                                                e.target.value,
                                                ""
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setServiceReqParticularValidationValue(
                                                "",
                                                "servicesTitle",
                                                NonEmptyValidation(
                                                  e.target.value
                                                ),
                                                ""
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.servicesTitle?.message
                                              ? `Title ${serviceReqParticularValidation?.servicesTitle?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>

                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label ">
                                            Assigned To
                                          </label>
                                          {serviceReqParticular?.status ===
                                            "unassigned" ? (
                                            <select
                                              className="form-control form-control-lg"
                                              defaultValue={
                                                serviceReqParticular?.assigned_to
                                              }
                                              onChange={(e) => {
                                                setOnchagneAssignedTo(!onChangeAssignedTo);
                                                setAssign(true);
                                                let name = allSubadmin?.filter(
                                                  (s) =>
                                                    s.name === e.target.value
                                                );
                                                let nameId = name?.map((n) => {
                                                  return n._id;
                                                });

                                                setServiceReqParticularValue(
                                                  "",
                                                  "assigned_to",
                                                  nameId.toString(),
                                                  ""
                                                );
                                              }}
                                              onBlur={(e) => {
                                                let name = allSubadmin?.filter(
                                                  (s) =>
                                                    s.name === e.target.value
                                                );
                                                let nameId = name?.map((n) => {
                                                  return n._id;
                                                });
                                                setServiceReqParticularValidationValue(
                                                  "assigned_to",
                                                  "name",
                                                  NonEmptyValidation(
                                                    nameId.toString()
                                                  ),
                                                  "object"
                                                );
                                              }}
                                            >
                                              {!serviceReqParticular
                                                ?.assigned_to?.name ? (
                                                <option value="">
                                                  ---Select Member---
                                                </option>
                                              ) : (
                                                ""
                                              )}
                                              {allSubadmin?.map((e) => (
                                                // <option value={e._id}>{e.name}</option>
                                                <option value={e.name}>
                                                  {e.name}
                                                </option>
                                              ))}
                                            </select>
                                          ) : (
                                            <>
                                              {reassign === true ? (
                                                <select
                                                  className="form-control form-control-lg"
                                                  value={
                                                    serviceReqParticular
                                                      ?.assigned_to?.name
                                                  }
                                                  onChange={(e) => {
                                                    setOnchagneAssignedTo(!onChangeAssignedTo);
                                                    setAssign(true);
                                                    let name =
                                                      allSubadmin?.filter(
                                                        (s) =>
                                                          s.name ===
                                                          e.target.value
                                                      );
                                                    let nameId = name?.map(
                                                      (n) => {
                                                        return n._id;
                                                      }
                                                    );

                                                    setServiceReqParticularValue(
                                                      "assigned_to",
                                                      "name",
                                                      nameId.toString(),
                                                      "object"
                                                    );
                                                  }}
                                                  onBlur={(e) => {
                                                    let name =
                                                      allSubadmin?.filter(
                                                        (s) =>
                                                          s.name ===
                                                          e.target.value
                                                      );
                                                    let nameId = name?.map(
                                                      (n) => {
                                                        return n._id;
                                                      }
                                                    );
                                                    setServiceReqParticularValidationValue(
                                                      "assigned_to",
                                                      "name",
                                                      NonEmptyValidation(
                                                        nameId.toString()
                                                      ),
                                                      "object"
                                                    );
                                                  }}
                                                  disabled={reassign === true}
                                                >
                                                  {!serviceReqParticular
                                                    ?.assigned_to?.name ? (
                                                    <option value="">
                                                      ---Select Member---
                                                    </option>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {allSubadmin?.map((e, i) => (
                                                    // <option value={e._id}>{e.name}</option>
                                                    <option value={e.name}>
                                                      {e.name}
                                                    </option>
                                                  ))}
                                                </select>
                                              ) : (
                                                <select
                                                  className="form-control form-control-lg"
                                                  defaultValue={
                                                    serviceReqParticular
                                                      ?.assigned_to?.name
                                                  }
                                                  onChange={(e) => {
                                                    setOnchagneAssignedTo(!onChangeAssignedTo);
                                                    setAssign(true);
                                                    let name =
                                                      allSubadmin?.filter(
                                                        (s) =>
                                                          s.name ===
                                                          e.target.value
                                                      );
                                                    let nameId = name?.map(
                                                      (n) => {
                                                        return n._id;
                                                      }
                                                    );

                                                    setServiceReqParticularValue(
                                                      "assigned_to",
                                                      "name",
                                                      nameId.toString(),
                                                      "object"
                                                    );
                                                  }}
                                                  onBlur={(e) => {
                                                    let name =
                                                      allSubadmin?.filter(
                                                        (s) =>
                                                          s.name ===
                                                          e.target.value
                                                      );
                                                    let nameId = name?.map(
                                                      (n) => {
                                                        return n._id;
                                                      }
                                                    );
                                                    setServiceReqParticularValidationValue(
                                                      "assigned_to",
                                                      "name",
                                                      NonEmptyValidation(
                                                        nameId.toString()
                                                      ),
                                                      "object"
                                                    );
                                                  }}
                                                  disabled={reassign === true}
                                                >
                                                  {!serviceReqParticular
                                                    ?.assigned_to?.name ? (
                                                    <option value="">
                                                      ---Select Member---
                                                    </option>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {allSubadmin?.map((e, i) => (
                                                    // <option value={e._id}>{e.name}</option>
                                                    <option value={e.name}>
                                                      {e.name}
                                                    </option>
                                                  ))}
                                                </select>
                                              )}
                                            </>
                                          )}

                                          {serviceReqParticularValidation?.assigned_to ? (
                                            <small className="text-danger">
                                              {serviceReqParticularValidation
                                                ?.assigned_to?.message
                                                ? `Assigned to ${serviceReqParticularValidation?.assigned_to?.message}`
                                                : ""}
                                            </small>
                                          ) : (
                                            ""
                                          )}
                                          {serviceReqParticularValidation
                                            ?.assigned_to?.name ? (
                                            <small className="text-danger">
                                              {serviceReqParticularValidation
                                                ?.assigned_to?.name?.message
                                                ? `Assigned to ${serviceReqParticularValidation?.assigned_to?.name?.message}`
                                                : ""}
                                            </small>
                                          ) : (
                                            ""
                                          )}
                                          {serviceReqParticular?.assigned_to
                                            ?.name ? (
                                            <button
                                              className="btn btn-sm btn-warning mt-2"
                                              onClick={() => {
                                                setReassign(false);
                                              }}
                                            >
                                              Reassign
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-lg-6 my">
                                        <div className="mb-3">
                                          <label
                                            htmlFor="fileUpload"
                                            className="form-label"
                                          >
                                            Select File
                                          </label>

                                          <input
                                            type="file"
                                            className="form-control"
                                            id="file"
                                            ref={CeoRef}
                                            onChange={(e) => {
                                              uploadCeoPic(e);
                                            }}
                                          />
                                        </div>
                                        {serviceReqParticular?.fileuploadTo?.map(
                                          (img, index) => (
                                            <>
                                              {index + 1}.{" "}
                                              <a
                                                href={img}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {img}
                                              </a>
                                            </>
                                          )
                                        )}
                                      </div>
                                      {serviceReqParticular?.status ===
                                        "unassigned" ? (
                                        ""
                                      ) : (
                                        <div className="col-lg-6">
                                          <div className="mb-3">
                                            <label
                                              for="services"
                                              className="form-label"
                                            >
                                              Status
                                            </label>
                                            <select
                                              className="form-control"
                                              value={
                                                serviceReqParticular?.status
                                              }
                                              onChange={(e) => {
                                                setOnChangeStatus(!onChangeStatus)
                                                setServiceReqParticularValue(
                                                  "",
                                                  "status",
                                                  e.target.value,
                                                  ""
                                                );
                                              }}
                                            >
                                              <option value="ToDo">
                                                To Do
                                              </option>
                                              <option value="assigned">
                                                Assigned
                                              </option>
                                              <option value="unassigned">
                                                Unassigned
                                              </option>
                                              <option value="inprogress">
                                                In Progress
                                              </option>
                                              <option value="completed">
                                                Completed
                                              </option>
                                            </select>
                                            <small className="text-danger">
                                              {serviceReqParticularValidation
                                                ?.status?.message
                                                ? `Status ${serviceReqParticularValidation?.status?.message}`
                                                : ""}
                                            </small>
                                          </div>
                                        </div>
                                      )}
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Subject
                                          </label>
                                          <textarea
                                            rows={6}
                                            className="form-control form-control-lg"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.subject?.status === false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.subject
                                            }
                                            onChange={(e) => {
                                              setServiceReqParticularValue(
                                                "",
                                                "subject",
                                                e.target.value,
                                                ""
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setServiceReqParticularValidationValue(
                                                "",
                                                "subject",
                                                NonEmptyValidation(
                                                  e.target.value
                                                ),
                                                ""
                                              );
                                            }}
                                            autoComplete="off"
                                          ></textarea>
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.subject?.message
                                              ? `Subject ${serviceReqParticularValidation?.subject?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>

                                      {serviceReqParticular?.getPurchaseInfo ? (
                                        <>
                                          <div className="col-lg-6">
                                            <div className="mb-4">
                                              <label className="form-label ">
                                                Package Details
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                value={
                                                  `Title: ${serviceReqParticular?.getPurchaseInfo
                                                    ?.PackageInfo?.title}`
                                                }
                                                disabled
                                              />
                                              <p
                                                className="form-control mt-2"
                                                disabled
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    `Description: ${serviceReqParticular?.getPurchaseInfo
                                                      ?.PackageInfo
                                                      ?.description}`,
                                                }}
                                              ></p>
                                              <input
                                                type="text"
                                                className="form-control"
                                                value={`Price: ₹${serviceReqParticular?.getPurchaseInfo
                                                  ?.price}`
                                                }
                                                disabled
                                              />

                                              <button className="btn-admin mt-3">
                                                Status :{" "}
                                                {
                                                  serviceReqParticular
                                                    ?.getPurchaseInfo?.status
                                                }
                                              </button>
                                              {/* <p className="form-control mt-2" disabled >{serviceReqParticular?.paymentStatus}</p> */}
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          {(state?.auth?.auth?.role ===
                                            "subadmin" &&
                                            serviceReqParticular?.getPurchaseInfo) ||
                                            (state?.auth?.auth?.role ===
                                              "admin" &&
                                              serviceReqParticular?.getPurchaseInfo) ? (
                                            <>
                                              <div className="col-lg-6">
                                                <div className="mb-4">
                                                  <label className="form-label ">
                                                    Package Details
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    value={
                                                      `Title: ${serviceReqParticular?.getPurchaseInfo
                                                        ?.PackageInfo?.title}`
                                                    }
                                                    disabled
                                                  />
                                                  <p
                                                    className="form-control mt-2"
                                                    disabled
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        `Description: ${serviceReqParticular?.getPurchaseInfo
                                                          ?.PackageInfo
                                                          ?.description}`,
                                                    }}
                                                  ></p>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    value={`Price: ₹${serviceReqParticular?.getPurchaseInfo
                                                      ?.price}`
                                                    }
                                                    disabled
                                                  />
                                                  <button className="btn-admin">
                                                    Status :{" "}
                                                    {
                                                      serviceReqParticular
                                                        ?.getPurchaseInfo
                                                        ?.status
                                                    }
                                                  </button>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {state?.auth?.auth?.role === "user" &&
                                            serviceReqParticular?.getPurchaseInfo ? (
                                            <>
                                              <>
                                                <div className="col-lg-6">
                                                  <div className="mb-4">
                                                    <label className="form-label ">
                                                      Package Details
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      value={
                                                        `Title: ${serviceReqParticular?.getPurchaseInfo
                                                          ?.PackageInfo?.title}`
                                                      }
                                                      disabled
                                                    />
                                                    <p
                                                      className="form-control mt-2"
                                                      disabled
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          `Description: ${serviceReqParticular?.getPurchaseInfo
                                                            ?.PackageInfo
                                                            ?.description}`,
                                                      }}
                                                    ></p>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      value={`Price: ₹${serviceReqParticular?.getPurchaseInfo
                                                        ?.price}`
                                                      }
                                                      disabled
                                                    />
                                                    <button className="btn-admin">
                                                      Status :{" "}
                                                      {
                                                        serviceReqParticular
                                                          ?.getPurchaseInfo
                                                          ?.status
                                                      }
                                                    </button>
                                                  </div>
                                                </div>
                                              </>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {state?.auth?.auth?.role === "user" &&
                                            serviceReqParticular?.getPurchaseInfo
                                              ?.status === "Not Paid" ? (
                                            <button
                                              className="login-btn"
                                              onClick={(e) => {
                                                HandleCCAvenuePaymentCustSrv(
                                                  serviceReqParticular
                                                    ?.getPackageInfo?.orderId,
                                                  serviceReqParticular
                                                    ?.getPurchaseInfo?._id,
                                                  state?.auth?.auth?.token
                                                );
                                              }}
                                            >
                                              Make Payment
                                            </button>
                                          ) : (
                                            ""
                                          )}

                                          {
                                            (state?.auth?.auth?.role ===
                                              "superadmin" &&
                                              serviceReqParticular?.getPurchaseInfo) ||
                                              (state?.auth?.auth?.role ===
                                                "admin" &&
                                                serviceReqParticular?.getPurchaseInfo)
                                              ? ""
                                              : ""
                                            // <button
                                            //   className="login-btn"
                                            //   onClick={(e) => {
                                            //     setPackageModal(true);
                                            //   }}
                                            // >
                                            //   Request Payment
                                            // </button>
                                          }
                                          {(state?.auth?.auth?.role ===
                                            "subadmin" &&
                                            !serviceReqParticular?.getPurchaseInfo) ||
                                            (state?.auth?.auth?.role ===
                                              "superadmin" &&
                                              !serviceReqParticular?.getPurchaseInfo) ||
                                            (state?.auth?.auth?.role ===
                                              "admin" &&
                                              !serviceReqParticular?.getPurchaseInfo) ? (
                                            <button
                                              className="login-btn"
                                              onClick={(e) => {
                                                setPackageModal(true);
                                              }}
                                            >
                                              Request Payment
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>

                                      {serviceReqParticular?.status === "completed" ? (
                                        <div className="col-lg-12">
                                          <div className="mb-4">
                                            <label className="form-label">
                                              Resolution Comment
                                            </label>
                                            {
                                              serviceReqParticular?.resolutionComment?
                                              <div
                                                className="form-control form-control-lg"
                                                style={{
                                                  overflow:"auto",
                                                  height: "150px",
                                                  border: "4px solid maroon",
                                                }}
                                                dangerouslySetInnerHTML={{
                                                  __html: serviceReqParticular?.resolutionComment
                                                }}
                                              >
                                                </div>:
                                                <div
                                                className="form-control form-control-lg"
                                                style={{
                                                  height: "250px",
                                                  border: "4px solid maroon",
                                                }}
                                              >
                                                <Editor
                                                  wrapperClassName="wrapper-class"
                                                  editorClassName="editor-class"
                                                  toolbarClassName="toolbar-class"
                                                  // editorState={serviceReqParticular?.resolutionComment}
                                                  onEditorStateChange={(e) =>
                                                    handleEditorChange2(e)
                                                    
                                                  }
                                                />
  
                                              </div>

                                            }
                                            
                                            
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      <div className="col-lg-12">
                                        <button
                                          type="button"
                                          className="btn login-btn ms-2 "
                                          onClick={(e) => {
                                            handleSubmit(e);
                                          }}
                                        >
                                          Save
                                        </button>
                                      </div>

                                      <>
                                        {/* {serviceReqParticular?.length && state?.auth?.auth?.role==="user" ? (<> */}
                                        {serviceReqParticular?.commentsTo?.map(
                                          (e, i) => (
                                            <>
                                              <div
                                                key={i}
                                                className="send-pro-bg mt-3"
                                                style={{
                                                  border: "1px solid black",
                                                  borderRadius: "8px",
                                                  marginBottom: "8px",
                                                }}
                                              >
                                                <div className="d-flex align-items-center justify-content-between">
                                                  <div className="d-flex   align-items-center">
                                                    <div className="flex-shrink-0 ">
                                                      {e.profileImage ? (
                                                        <img
                                                          className="admin-pro-img "
                                                          style={{
                                                            width: "50px",
                                                            height: "50px",
                                                          }}
                                                          src={e.profileImage}
                                                          alt="..."
                                                        />
                                                      ) : (
                                                        <span className="material-symbols-rounded filled-icon dash-icon">
                                                          person
                                                        </span>
                                                      )}
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                      <h6 className="fw-bold">
                                                        {e.name}
                                                      </h6>

                                                      {e.Designation ? (
                                                        <p
                                                          style={{
                                                            textTransform:
                                                              "capitalize",
                                                          }}
                                                          className="mb-0"
                                                        >
                                                          {e.Designation}
                                                        </p>
                                                      ) : (
                                                        <p
                                                          style={{
                                                            textTransform:
                                                              "capitalize",
                                                          }}
                                                          className="mb-0"
                                                        >
                                                          {e.role}
                                                        </p>
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div>
                                                    <p className="mb-0">
                                                      Asked{" "}
                                                      {moment(
                                                        e.createdAt
                                                      ).fromNow()}
                                                    </p>
                                                  </div>
                                                </div>
                                                <p
                                                  className="mb-0 mt-3 pl-send-pro-bg"
                                                  dangerouslySetInnerHTML={{
                                                    __html: e.comments,
                                                  }}
                                                >
                                                  {/* {e.comments} */}
                                                </p>
                                              </div>
                                            </>
                                          )
                                        )}

                                        <div className="input-group flex-nowrap  my-3">
                                          <div
                                            className="form-control form-control-lg"
                                            style={{ height: "250px" }}
                                          >
                                            <Editor
                                              wrapperClassName="wrapper-class"
                                              editorClassName="editor-class"
                                              toolbarClassName="toolbar-class"
                                              editorState={editorState1}
                                              onEditorStateChange={(e) =>
                                                handleEditorChange1(e)
                                              }
                                            />
                                          </div>
                                          {/* <input
                                              style={{
                                                border: "1px solid #c9c8c8",
                                                backgroundColor: "transparent",
                                                borderRight: "0px",
                                              }}
                                              type="text"
                                              className="form-control"
                                              placeholder="Your Message"
                                              aria-invalid={Boolean(
                                                commentsValidation?.comments
                                                  ?.status === false
                                              )}
                                              defaultValue={
                                                leadComment?.comments
                                              }
                                              onChange={(e) => {
                                                setCommentsValue(
                                                  "comments",
                                                  e.target.value
                                                );
                                              }}
                                              onBlur={(e) => {
                                                setCommentsLeadValidationValue(
                                                  "comments",
                                                  NonEmptyValidation(
                                                    e.target.value
                                                  )
                                                );
                                              }}
                                              aria-describedby="addon-wrapping"
                                            /> */}
                                          <span
                                            className="input-group-text"
                                            id="addon-wrapping"
                                            style={{
                                              border: "1px solid #c9c8c8",
                                              backgroundColor: "transparent",
                                              borderLeft: "0px",
                                            }}
                                          >
                                            <button
                                              className="btn py-1  login-btn"
                                              type="button"
                                              id="button-addon2"
                                              onClick={(e) => {
                                                handleSubmitComment(e);
                                              }}
                                            >
                                              Send
                                              <i className="fa-solid fa-paper-plane ms-2"></i>
                                            </button>
                                          </span>
                                        </div>
                                        <small className="text-danger">
                                          {commentsValidation?.comments?.message
                                            ? `comments ${commentsValidation?.comments?.message}`
                                            : ""}
                                        </small>
                                      </>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {serviceReqParticular?.property_id ? (
                          <div>
                            <div className=" my-4">
                              <div className="bg-historytablehead rounded-3 py-3 px-3">
                                <h6 className="mb-0">Update Property</h6>
                              </div>
                              <div className="container">
                                <div className="row justify-content-center py-5">
                                  <div className="col-lg-11">
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <div
                                          className="p-3"
                                          style={{ border: "1px solid black" }}
                                        >
                                          <div className="small-banner-img-head">
                                            <img
                                              style={{
                                                width: "100%",
                                                height: "200px",
                                              }}
                                              // className="w-100 h-auto"
                                              src={
                                                serviceReqParticular
                                                  ?.property_id?.cover_image
                                              }
                                              alt=""
                                            />
                                          </div>
                                          <div className="mt-3">
                                            <h6
                                              className="color-maroon fw-bold"
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              {
                                                serviceReqParticular
                                                  ?.property_id?.property_name
                                              }
                                            </h6>
                                            <p className="small">
                                              <span className="material-symbols-rounded align-middle me-1 filled-icon">
                                                location_on
                                              </span>
                                              {
                                                serviceReqParticular
                                                  ?.property_id
                                                  ?.property_information.address
                                              }
                                            </p>

                                            <p className="small">
                                              <b>Room:</b>{" "}
                                              {
                                                serviceReqParticular
                                                  ?.property_id
                                                  ?.property_information
                                                  .room_size
                                              }
                                              BHK
                                            </p>
                                            <p className="small">
                                              <b>SqFt :</b>{" "}
                                              {
                                                serviceReqParticular
                                                  ?.property_id
                                                  ?.property_information.sq_ft
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Name
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            placeholder="Enter the Name"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.name?.status === false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.name
                                            }
                                            onChange={(e) => {
                                              setServiceReqParticularValue(
                                                "",
                                                "name",
                                                e.target.value,
                                                ""
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setServiceReqParticularValidationValue(
                                                "",
                                                "name",
                                                StringValidation(
                                                  e.target.value
                                                ),
                                                ""
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.name?.message
                                              ? `Name ${serviceReqParticularValidation?.name?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Mobile No
                                          </label>
                                          <input
                                            type="tel"
                                            className="form-control form-control-lg"
                                            maxLength={10}
                                            placeholder="Enter the Mobile number"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.mobileNumber?.status === false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.mobileNumber
                                            }
                                            onChange={(e) => {
                                              setServiceReqParticularValue(
                                                "",
                                                "mobileNumber",
                                                e.target.value,
                                                ""
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setServiceReqParticularValidationValue(
                                                "",
                                                "mobileNumber",
                                                PhoneNumberValidation(
                                                  e.target.value
                                                ),
                                                ""
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.mobileNumber?.message
                                              ? `Mobile Number ${serviceReqParticularValidation?.mobileNumber?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Email Id
                                          </label>
                                          <input
                                            type="email"
                                            className="form-control form-control-lg"
                                            placeholder="Enter Email-Id"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.email?.status === false
                                            )}
                                            defaultValue={serviceReqParticular?.email?.toLowerCase()}
                                            onChange={(e) => {
                                              setServiceReqParticularValue(
                                                "",
                                                "email",
                                                e.target.value,
                                                ""
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setServiceReqParticularValidationValue(
                                                "",
                                                "email",
                                                EmailValidation(
                                                  e.target.value?.toLowerCase()
                                                ),
                                                ""
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.email?.message
                                              ? `Email Id ${serviceReqParticularValidation?.email?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Message
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            placeholder="Enter Your Question"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.question?.status === false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.question
                                            }
                                            onChange={(e) => {
                                              setServiceReqParticularValue(
                                                "",
                                                "question",
                                                e.target.value,
                                                ""
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setServiceReqParticularValidationValue(
                                                "",
                                                "question",
                                                NonEmptyValidation(
                                                  e.target.value
                                                ),
                                                ""
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.message?.message
                                              ? `Message ${serviceReqParticularValidation?.message?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>

                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Subject
                                          </label>
                                          <textarea
                                            rows={6}
                                            className="form-control form-control-lg"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.subject?.status === false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.subject
                                            }
                                            onChange={(e) => {
                                              setServiceReqParticularValue(
                                                "",
                                                "subject",
                                                e.target.value,
                                                ""
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setServiceReqParticularValidationValue(
                                                "",
                                                "subject",
                                                NonEmptyValidation(
                                                  e.target.value
                                                ),
                                                ""
                                              );
                                            }}
                                            autoComplete="off"
                                          ></textarea>
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.subject?.message
                                              ? `Subject ${serviceReqParticularValidation?.subject?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label ">
                                            Assigned To2
                                          </label>
                                          {serviceReqParticular?.status ===
                                            "unassigned" ? (
                                            <select
                                              className="form-control form-control-lg"
                                              defaultValue={
                                                serviceReqParticular?.assigned_to
                                              }
                                              onChange={(e) => {
                                                setAssign(true);
                                                let name = allSubadmin?.filter(
                                                  (s) =>
                                                    s.name === e.target.value
                                                );
                                                let nameId = name?.map((n) => {
                                                  return n._id;
                                                });

                                                setServiceReqParticularValue(
                                                  "",
                                                  "assigned_to",
                                                  nameId.toString(),
                                                  ""
                                                );
                                              }}
                                              onBlur={(e) => {
                                                let name = allSubadmin?.filter(
                                                  (s) =>
                                                    s.name === e.target.value
                                                );
                                                let nameId = name?.map((n) => {
                                                  return n._id;
                                                });
                                                setServiceReqParticularValidationValue(
                                                  "assigned_to",
                                                  "name",
                                                  NonEmptyValidation(
                                                    nameId.toString()
                                                  ),
                                                  "object"
                                                );
                                              }}
                                            >
                                              {!serviceReqParticular
                                                ?.assigned_to?.name ? (
                                                <option value="">
                                                  ---Select Member---
                                                </option>
                                              ) : (
                                                ""
                                              )}
                                              {allSubadmin?.map((e) => (
                                                // <option value={e._id}>{e.name}</option>
                                                <option value={e.name}>
                                                  {e.name}
                                                </option>
                                              ))}
                                            </select>
                                          ) : (
                                            <>
                                              {reassign === true ? (
                                                <select
                                                  className="form-control form-control-lg"
                                                  value={
                                                    serviceReqParticular
                                                      ?.assigned_to?.name
                                                  }
                                                  onChange={(e) => {
                                                    setAssign(true);
                                                    let name =
                                                      allSubadmin?.filter(
                                                        (s) =>
                                                          s.name ===
                                                          e.target.value
                                                      );
                                                    let nameId = name?.map(
                                                      (n) => {
                                                        return n._id;
                                                      }
                                                    );

                                                    setServiceReqParticularValue(
                                                      "assigned_to",
                                                      "name",
                                                      nameId.toString(),
                                                      "object"
                                                    );
                                                  }}
                                                  onBlur={(e) => {
                                                    let name =
                                                      allSubadmin?.filter(
                                                        (s) =>
                                                          s.name ===
                                                          e.target.value
                                                      );
                                                    let nameId = name?.map(
                                                      (n) => {
                                                        return n._id;
                                                      }
                                                    );
                                                    setServiceReqParticularValidationValue(
                                                      "assigned_to",
                                                      "name",
                                                      NonEmptyValidation(
                                                        nameId.toString()
                                                      ),
                                                      "object"
                                                    );
                                                  }}
                                                  disabled={reassign === true}
                                                >
                                                  {!serviceReqParticular
                                                    ?.assigned_to?.name ? (
                                                    <option value="">
                                                      ---Select Member---
                                                    </option>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {allSubadmin?.map((e, i) => (
                                                    // <option value={e._id}>{e.name}</option>
                                                    <option value={e.name}>
                                                      {e.name}
                                                    </option>
                                                  ))}
                                                </select>
                                              ) : (
                                                <select
                                                  className="form-control form-control-lg"
                                                  defaultValue={
                                                    serviceReqParticular
                                                      ?.assigned_to?.name
                                                  }
                                                  onChange={(e) => {
                                                    setAssign(true);
                                                    let name =
                                                      allSubadmin?.filter(
                                                        (s) =>
                                                          s.name ===
                                                          e.target.value
                                                      );
                                                    let nameId = name?.map(
                                                      (n) => {
                                                        return n._id;
                                                      }
                                                    );

                                                    setServiceReqParticularValue(
                                                      "assigned_to",
                                                      "name",
                                                      nameId.toString(),
                                                      "object"
                                                    );
                                                  }}
                                                  onBlur={(e) => {
                                                    let name =
                                                      allSubadmin?.filter(
                                                        (s) =>
                                                          s.name ===
                                                          e.target.value
                                                      );
                                                    let nameId = name?.map(
                                                      (n) => {
                                                        return n._id;
                                                      }
                                                    );
                                                    setServiceReqParticularValidationValue(
                                                      "assigned_to",
                                                      "name",
                                                      NonEmptyValidation(
                                                        nameId.toString()
                                                      ),
                                                      "object"
                                                    );
                                                  }}
                                                  disabled={reassign === true}
                                                >
                                                  {!serviceReqParticular
                                                    ?.assigned_to?.name ? (
                                                    <option value="">
                                                      ---Select Member---
                                                    </option>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {allSubadmin?.map((e, i) => (
                                                    // <option value={e._id}>{e.name}</option>
                                                    <option value={e.name}>
                                                      {e.name}
                                                    </option>
                                                  ))}
                                                </select>
                                              )}
                                            </>
                                          )}

                                          {serviceReqParticularValidation?.assigned_to ? (
                                            <small className="text-danger">
                                              {serviceReqParticularValidation
                                                ?.assigned_to?.message
                                                ? `Assigned to ${serviceReqParticularValidation?.assigned_to?.message}`
                                                : ""}
                                            </small>
                                          ) : (
                                            ""
                                          )}
                                          {serviceReqParticularValidation
                                            ?.assigned_to?.name ? (
                                            <small className="text-danger">
                                              {serviceReqParticularValidation
                                                ?.assigned_to?.name?.message
                                                ? `Assigned to ${serviceReqParticularValidation?.assigned_to?.name?.message}`
                                                : ""}
                                            </small>
                                          ) : (
                                            ""
                                          )}
                                          {serviceReqParticular?.assigned_to
                                            ?.name ? (
                                            <button
                                              className="btn btn-sm btn-warning mt-2"
                                              onClick={() => {
                                                setReassign(false);
                                              }}
                                            >
                                              Reassign
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-lg-6 my">
                                        <div className="mb-3">
                                          <label
                                            htmlFor="fileUpload"
                                            className="form-label"
                                          >
                                            Select File
                                          </label>

                                          <input
                                            type="file"
                                            className="form-control"
                                            id="file"
                                            ref={CeoRef}
                                            onChange={(e) => {
                                              uploadCeoPic(e);
                                            }}
                                          />
                                        </div>
                                        {serviceReqParticular?.fileUploadTo?.map(
                                          (img, index) => (
                                            <>
                                              {index + 1}.{" "}
                                              <a
                                                href={img}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {img}
                                              </a>
                                            </>
                                          )
                                        )}
                                      </div>
                                      {serviceReqParticular?.status ===
                                        "unassigned" ? (
                                        ""
                                      ) : (
                                        <div className="col-lg-6">
                                          <div className="mb-3">
                                            <label
                                              for="services"
                                              className="form-label"
                                            >
                                              Status
                                            </label>
                                            <select
                                              className="form-control"
                                              value={
                                                serviceReqParticular?.status
                                              }
                                              onChange={(e) => {
                                                setServiceReqParticularValue(
                                                  "",
                                                  "status",
                                                  e.target.value,
                                                  ""
                                                );
                                              }}
                                            >
                                              <option value="ToDo">
                                                To Do
                                              </option>
                                              <option value="assigned">
                                                Assigned
                                              </option>
                                              <option value="unassigned">
                                                Unassigned
                                              </option>
                                              <option value="inprogress">
                                                In Progress
                                              </option>
                                              <option value="completed">
                                                Completed
                                              </option>
                                            </select>
                                            <small className="text-danger">
                                              {serviceReqParticularValidation
                                                ?.status?.message
                                                ? `Status ${serviceReqParticularValidation?.status?.message}`
                                                : ""}
                                            </small>
                                          </div>
                                        </div>
                                      )}
                                      <div className="col-lg-12">
                                        <button
                                          type="button"
                                          className="btn login-btn ms-2 "
                                          onClick={(e) => {
                                            handleSubmit(e);
                                          }}
                                        >
                                          Save 
                                        </button>
                                      </div>
                                      <>
                                        {/* {serviceReqParticular?.length && state?.auth?.auth?.role==="user" ? (<> */}
                                        {serviceReqParticular?.commentsTo?.map(
                                          (e) => (
                                            <>
                                              <div
                                                className="send-pro-bg mt-4"
                                                style={{
                                                  border: "1px solid black",
                                                  borderRadius: "8px",
                                                  marginBottom: "8px",
                                                }}
                                              >
                                                <div className="d-flex align-items-center justify-content-between">
                                                  <div className="d-flex   align-items-center">
                                                    <div className="flex-shrink-0 ">
                                                      {e?.profileImage ? (
                                                        <img
                                                          className="admin-pro-img "
                                                          style={{
                                                            width: "50px",
                                                            height: "50px",
                                                          }}
                                                          src={e?.profileImage}
                                                          alt="..."
                                                        />
                                                      ) : (
                                                        <span className="material-symbols-rounded filled-icon dash-icon">
                                                          person
                                                        </span>
                                                      )}
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                      <h6 className="fw-bold">
                                                        {e?.name}
                                                      </h6>
                                                      {e?.pageAccess ? (
                                                        <>
                                                          {listEmployee
                                                            ?.filter(
                                                              (sa) =>
                                                                sa?._id ===
                                                                e?.pageAccess
                                                            )
                                                            .map((saa) => {
                                                              return (
                                                                <p
                                                                  style={{
                                                                    textTransform:
                                                                      "capitalize",
                                                                  }}
                                                                  className="mb-0"
                                                                >
                                                                  {saa?.title}
                                                                </p>
                                                              );
                                                            }) ||
                                                            listAdmin
                                                              ?.filter(
                                                                (sa) =>
                                                                  sa?._id ===
                                                                  e?.pageAccess
                                                              )
                                                              .map((saa) => {
                                                                return (
                                                                  <p
                                                                    style={{
                                                                      textTransform:
                                                                        "capitalize",
                                                                    }}
                                                                    className="mb-0"
                                                                  >
                                                                    {saa?.title}
                                                                  </p>
                                                                );
                                                              })}
                                                        </>
                                                      ) : (
                                                        <p
                                                          className="mb-0"
                                                          style={{
                                                            textTransform:
                                                              "capitalize",
                                                          }}
                                                        >
                                                          {e.role}
                                                        </p>
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div>
                                                    <p className="mb-0">
                                                      Asked{" "}
                                                      {moment(
                                                        e?.createdAt
                                                      ).fromNow()}
                                                    </p>
                                                  </div>
                                                </div>
                                                <p className="mb-0 mt-3 pl-send-pro-bg">
                                                  {e.comments}
                                                </p>
                                              </div>
                                            </>
                                          )
                                        )}
                                        {state?.auth?.auth?.role ===
                                          "subadmin" ||
                                          (serviceReqParticular?.commentsTo
                                            ?.length &&
                                            state?.auth?.auth?.role === "user") ||
                                          state?.auth?.auth?.role ===
                                          "superadmin" ||
                                          state?.auth?.auth?.role === "admin" ? (
                                          <>
                                            <div className="input-group flex-nowrap  my-3">
                                              <input
                                                style={{
                                                  border: "1px solid #c9c8c8",
                                                  backgroundColor:
                                                    "transparent",
                                                  borderRight: "0px",
                                                }}
                                                type="text"
                                                className="form-control"
                                                placeholder="Your Message"
                                                aria-invalid={Boolean(
                                                  commentsValidation?.comments
                                                    ?.status === false
                                                )}
                                                defaultValue={
                                                  leadComment?.comments
                                                }
                                                onChange={(e) => {
                                                  setCommentsValue(
                                                    "comments",
                                                    e.target.value
                                                  );
                                                }}
                                                onBlur={(e) => {
                                                  setCommentsLeadValidationValue(
                                                    "comments",

                                                    NonEmptyValidation(
                                                      e.target.value
                                                    )
                                                  );
                                                }}
                                              />
                                              <span
                                                className="input-group-text"
                                                id="addon-wrapping"
                                                style={{
                                                  border: "1px solid #c9c8c8",
                                                  backgroundColor:
                                                    "transparent",
                                                  borderLeft: "0px",
                                                }}
                                              >
                                                <button
                                                  className="btn py-1  login-btn"
                                                  type="button"
                                                  id="button-addon2"
                                                  onClick={(e) => {
                                                    handleSubmitComment(e);
                                                  }}
                                                >
                                                  Send
                                                  <i className="fa-solid fa-paper-plane ms-2"></i>
                                                </button>
                                              </span>
                                            </div>
                                            <small className="text-danger">
                                              {commentsValidation?.comments
                                                ?.message
                                                ? `comments ${commentsValidation?.comments?.message}`
                                                : ""}
                                            </small>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {serviceReqParticular?.goldauction_id ? (
                          <div>
                            <div className=" my-4">
                              <div className="bg-historytablehead rounded-3 py-3 px-3">
                                <h6 className="mb-0">Update Property</h6>
                              </div>
                              <div className="container">
                                <div className="row justify-content-center py-5">
                                  <div className="col-lg-11">
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <div
                                          className="p-3"
                                          style={{ border: "1px solid black" }}
                                        >
                                          {/* <div className="small-banner-img-head">
                                    <img
                                      style={{ width: "100%", height: "200px" }}
                                      // className="w-100 h-auto"
                                      src={
                                        serviceReqParticular?.goldauction_id
                                          ?.cover_image
                                      }
                                      alt=""
                                    />
                                  </div> */}
                                          <div className="mt-3">
                                            <h6
                                              className="color-maroon fw-bold"
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              <b>Type:</b>{" "}
                                              {
                                                serviceReqParticular
                                                  ?.goldauction_id?.goldType
                                              }
                                            </h6>
                                            <p className="small">
                                              {/* <span className="material-symbols-rounded align-middle me-1 filled-icon">
                                        location_on
                                      </span> */}
                                              <b>Carat:</b>{" "}
                                              {
                                                serviceReqParticular
                                                  ?.goldauction_id?.carat
                                              }
                                            </p>
                                            <p className="small">
                                              <b>Gram:</b>{" "}
                                              {
                                                serviceReqParticular
                                                  ?.goldauction_id?.gram
                                              }
                                            </p>
                                            <p className="small">
                                              <b>Price :</b>{" "}
                                              {
                                                serviceReqParticular
                                                  ?.goldauction_id?.bidingPrice
                                              }
                                            </p>
                                            <b>Description :</b>{" "}
                                            <p
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  serviceReqParticular
                                                    ?.goldauction_id
                                                    ?.description,
                                              }}
                                            >
                                              {/* <b>Description :</b>{" "} */}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Name
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            placeholder="Enter the Name"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.name?.status === false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.name
                                            }
                                            onChange={(e) => {
                                              setServiceReqParticularValue(
                                                "",
                                                "name",
                                                e.target.value,
                                                ""
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setServiceReqParticularValidationValue(
                                                "",
                                                "name",
                                                StringValidation(
                                                  e.target.value
                                                ),
                                                ""
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.name?.message
                                              ? `Name ${serviceReqParticularValidation?.name?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Mobile No
                                          </label>
                                          <input
                                            type="tel"
                                            className="form-control form-control-lg"
                                            maxLength={10}
                                            placeholder="Enter the Mobile number"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.mobileNumber?.status === false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.mobileNumber
                                            }
                                            onChange={(e) => {
                                              setServiceReqParticularValue(
                                                "",
                                                "mobileNumber",
                                                e.target.value,
                                                ""
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setServiceReqParticularValidationValue(
                                                "",
                                                "mobileNumber",
                                                PhoneNumberValidation(
                                                  e.target.value
                                                ),
                                                ""
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.mobileNumber?.message
                                              ? `Mobile Number ${serviceReqParticularValidation?.mobileNumber?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Email Id
                                          </label>
                                          <input
                                            type="email"
                                            className="form-control form-control-lg"
                                            placeholder="Enter Email-Id"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.email?.status === false
                                            )}
                                            defaultValue={serviceReqParticular?.email?.toLowerCase()}
                                            onChange={(e) => {
                                              setServiceReqParticularValue(
                                                "",
                                                "email",
                                                e.target.value,
                                                ""
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setServiceReqParticularValidationValue(
                                                "",
                                                "email",
                                                EmailValidation(
                                                  e.target.value?.toLowerCase()
                                                ),
                                                ""
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.email?.message
                                              ? `Email Id ${serviceReqParticularValidation?.email?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Message
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            placeholder="Enter Your Question"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.question?.status === false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.question
                                            }
                                            onChange={(e) => {
                                              setServiceReqParticularValue(
                                                "",
                                                "question",
                                                e.target.value,
                                                ""
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setServiceReqParticularValidationValue(
                                                "",
                                                "question",
                                                NonEmptyValidation(
                                                  e.target.value
                                                ),
                                                ""
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.message?.message
                                              ? `Message ${serviceReqParticularValidation?.message?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>

                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Subject
                                          </label>
                                          <textarea
                                            rows={6}
                                            className="form-control form-control-lg"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.subject?.status === false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.subject
                                            }
                                            onChange={(e) => {
                                              setServiceReqParticularValue(
                                                "",
                                                "subject",
                                                e.target.value,
                                                ""
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setServiceReqParticularValidationValue(
                                                "",
                                                "subject",
                                                NonEmptyValidation(
                                                  e.target.value
                                                ),
                                                ""
                                              );
                                            }}
                                            autoComplete="off"
                                          ></textarea>
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.subject?.message
                                              ? `Subject ${serviceReqParticularValidation?.subject?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label ">
                                            Assigned To3
                                          </label>
                                          {serviceReqParticular?.status ===
                                            "unassigned" ? (
                                            <select
                                              className="form-control form-control-lg"
                                              defaultValue={
                                                serviceReqParticular?.assigned_to
                                              }
                                              onChange={(e) => {
                                                setAssign(true);
                                                let name = allSubadmin?.filter(
                                                  (s) =>
                                                    s.name === e.target.value
                                                );
                                                let nameId = name?.map((n) => {
                                                  return n._id;
                                                });

                                                setServiceReqParticularValue(
                                                  "",
                                                  "assigned_to",
                                                  nameId.toString(),
                                                  ""
                                                );
                                              }}
                                              onBlur={(e) => {
                                                let name = allSubadmin?.filter(
                                                  (s) =>
                                                    s.name === e.target.value
                                                );
                                                let nameId = name?.map((n) => {
                                                  return n._id;
                                                });
                                                setServiceReqParticularValidationValue(
                                                  "assigned_to",
                                                  "name",
                                                  NonEmptyValidation(
                                                    nameId.toString()
                                                  ),
                                                  "object"
                                                );
                                              }}
                                            >
                                              {!serviceReqParticular
                                                ?.assigned_to?.name ? (
                                                <option value="">
                                                  ---Select Member---
                                                </option>
                                              ) : (
                                                ""
                                              )}
                                              {allSubadmin?.map((e) => (
                                                // <option value={e._id}>{e.name}</option>
                                                <option value={e.name}>
                                                  {e.name}
                                                </option>
                                              ))}
                                            </select>
                                          ) : (
                                            <>
                                              {reassign === true ? (
                                                <select
                                                  className="form-control form-control-lg"
                                                  value={
                                                    serviceReqParticular
                                                      ?.assigned_to?.name
                                                  }
                                                  onChange={(e) => {
                                                    setAssign(true);
                                                    let name =
                                                      allSubadmin?.filter(
                                                        (s) =>
                                                          s.name ===
                                                          e.target.value
                                                      );
                                                    let nameId = name?.map(
                                                      (n) => {
                                                        return n._id;
                                                      }
                                                    );

                                                    setServiceReqParticularValue(
                                                      "assigned_to",
                                                      "name",
                                                      nameId.toString(),
                                                      "object"
                                                    );
                                                  }}
                                                  onBlur={(e) => {
                                                    let name =
                                                      allSubadmin?.filter(
                                                        (s) =>
                                                          s.name ===
                                                          e.target.value
                                                      );
                                                    let nameId = name?.map(
                                                      (n) => {
                                                        return n._id;
                                                      }
                                                    );
                                                    setServiceReqParticularValidationValue(
                                                      "assigned_to",
                                                      "name",
                                                      NonEmptyValidation(
                                                        nameId.toString()
                                                      ),
                                                      "object"
                                                    );
                                                  }}
                                                  disabled={reassign === true}
                                                >
                                                  {!serviceReqParticular
                                                    ?.assigned_to?.name ? (
                                                    <option value="">
                                                      ---Select Member---
                                                    </option>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {allSubadmin?.map((e, i) => (
                                                    // <option value={e._id}>{e.name}</option>
                                                    <option value={e.name}>
                                                      {e.name}
                                                    </option>
                                                  ))}
                                                </select>
                                              ) : (
                                                <select
                                                  className="form-control form-control-lg"
                                                  defaultValue={
                                                    serviceReqParticular
                                                      ?.assigned_to?.name
                                                  }
                                                  onChange={(e) => {
                                                    setAssign(true);
                                                    let name =
                                                      allSubadmin?.filter(
                                                        (s) =>
                                                          s.name ===
                                                          e.target.value
                                                      );
                                                    let nameId = name?.map(
                                                      (n) => {
                                                        return n._id;
                                                      }
                                                    );

                                                    setServiceReqParticularValue(
                                                      "assigned_to",
                                                      "name",
                                                      nameId.toString(),
                                                      "object"
                                                    );
                                                  }}
                                                  onBlur={(e) => {
                                                    let name =
                                                      allSubadmin?.filter(
                                                        (s) =>
                                                          s.name ===
                                                          e.target.value
                                                      );
                                                    let nameId = name?.map(
                                                      (n) => {
                                                        return n._id;
                                                      }
                                                    );
                                                    setServiceReqParticularValidationValue(
                                                      "assigned_to",
                                                      "name",
                                                      NonEmptyValidation(
                                                        nameId.toString()
                                                      ),
                                                      "object"
                                                    );
                                                  }}
                                                  disabled={reassign === true}
                                                >
                                                  {!serviceReqParticular
                                                    ?.assigned_to?.name ? (
                                                    <option value="">
                                                      ---Select Member---
                                                    </option>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {allSubadmin?.map((e, i) => (
                                                    // <option value={e._id}>{e.name}</option>
                                                    <option value={e.name}>
                                                      {e.name}
                                                    </option>
                                                  ))}
                                                </select>
                                              )}
                                            </>
                                          )}

                                          {serviceReqParticularValidation?.assigned_to ? (
                                            <small className="text-danger">
                                              {serviceReqParticularValidation
                                                ?.assigned_to?.message
                                                ? `Assigned to ${serviceReqParticularValidation?.assigned_to?.message}`
                                                : ""}
                                            </small>
                                          ) : (
                                            ""
                                          )}
                                          {serviceReqParticularValidation
                                            ?.assigned_to?.name ? (
                                            <small className="text-danger">
                                              {serviceReqParticularValidation
                                                ?.assigned_to?.name?.message
                                                ? `Assigned to ${serviceReqParticularValidation?.assigned_to?.name?.message}`
                                                : ""}
                                            </small>
                                          ) : (
                                            ""
                                          )}
                                          {serviceReqParticular?.assigned_to
                                            ?.name ? (
                                            <button
                                              className="btn btn-sm btn-warning mt-2"
                                              onClick={() => {
                                                setReassign(false);
                                              }}
                                            >
                                              Reassign
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-lg-6 my">
                                        <div className="mb-3">
                                          <label
                                            htmlFor="fileUpload"
                                            className="form-label"
                                          >
                                            Select File
                                          </label>

                                          <input
                                            type="file"
                                            className="form-control"
                                            id="file"
                                            ref={CeoRef}
                                            onChange={(e) => {
                                              uploadCeoPic(e);
                                            }}
                                          />
                                        </div>
                                        {serviceReqParticular?.fileUploadTo?.map(
                                          (img, index) => (
                                            <>
                                              {index + 1}.{" "}
                                              <a href={img} target="_blank">
                                                {img}
                                              </a>
                                            </>
                                          )
                                        )}
                                      </div>
                                      {serviceReqParticular?.status ===
                                        "unassigned" ? (
                                        ""
                                      ) : (
                                        <div className="col-lg-6">
                                          <div className="mb-3">
                                            <label
                                              for="services"
                                              className="form-label"
                                            >
                                              Status
                                            </label>
                                            <select
                                              className="form-control"
                                              value={
                                                serviceReqParticular?.status
                                              }
                                              onChange={(e) => {
                                                setServiceReqParticularValue(
                                                  "",
                                                  "status",
                                                  e.target.value,
                                                  ""
                                                );
                                              }}
                                            >
                                              <option value="ToDo">
                                                To Do
                                              </option>
                                              <option value="assigned">
                                                Assigned
                                              </option>
                                              <option value="unassigned">
                                                Unassigned
                                              </option>
                                              <option value="inprogress">
                                                In Progress
                                              </option>
                                              <option value="completed">
                                                Completed
                                              </option>
                                            </select>
                                            <small className="text-danger">
                                              {serviceReqParticularValidation
                                                ?.status?.message
                                                ? `Status ${serviceReqParticularValidation?.status?.message}`
                                                : ""}
                                            </small>
                                          </div>
                                        </div>
                                      )}
                                      <div className="col-lg-12">
                                        <button
                                          type="button"
                                          className="btn login-btn ms-2 "
                                          onClick={(e) => {
                                            handleSubmit(e);
                                          }}
                                        >
                                          Save
                                        </button>
                                      </div>
                                      <>
                                        {/* {serviceReqParticular?.length && state?.auth?.auth?.role==="user" ? (<> */}
                                        {serviceReqParticular?.commentsTo?.map(
                                          (e) => (
                                            <>
                                              <div
                                                className="send-pro-bg mt-4"
                                                style={{
                                                  border: "1px solid black",
                                                  borderRadius: "8px",
                                                  marginBottom: "8px",
                                                }}
                                              >
                                                <div className="d-flex align-items-center justify-content-between">
                                                  <div className="d-flex   align-items-center">
                                                    <div className="flex-shrink-0 ">
                                                      {e?.profileImage ? (
                                                        <img
                                                          className="admin-pro-img "
                                                          style={{
                                                            width: "50px",
                                                            height: "50px",
                                                          }}
                                                          src={e?.profileImage}
                                                          alt="..."
                                                        />
                                                      ) : (
                                                        <span className="material-symbols-rounded filled-icon dash-icon">
                                                          person
                                                        </span>
                                                      )}
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                      <h6 className="fw-bold">
                                                        {e?.name}
                                                      </h6>
                                                      {e?.pageAccess ? (
                                                        <>
                                                          {listEmployee
                                                            ?.filter(
                                                              (sa) =>
                                                                sa?._id ===
                                                                e?.pageAccess
                                                            )
                                                            .map((saa) => {
                                                              return (
                                                                <p
                                                                  style={{
                                                                    textTransform:
                                                                      "capitalize",
                                                                  }}
                                                                  className="mb-0"
                                                                >
                                                                  {saa?.title}
                                                                </p>
                                                              );
                                                            }) ||
                                                            listAdmin
                                                              ?.filter(
                                                                (sa) =>
                                                                  sa?._id ===
                                                                  e?.pageAccess
                                                              )
                                                              .map((saa) => {
                                                                return (
                                                                  <p
                                                                    style={{
                                                                      textTransform:
                                                                        "capitalize",
                                                                    }}
                                                                    className="mb-0"
                                                                  >
                                                                    {saa?.title}
                                                                  </p>
                                                                );
                                                              })}
                                                        </>
                                                      ) : (
                                                        <p
                                                          className="mb-0"
                                                          style={{
                                                            textTransform:
                                                              "capitalize",
                                                          }}
                                                        >
                                                          {e.role}
                                                        </p>
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div>
                                                    <p className="mb-0">
                                                      Asked{" "}
                                                      {moment(
                                                        e?.createdAt
                                                      ).fromNow()}
                                                    </p>
                                                  </div>
                                                </div>
                                                <p className="mb-0 mt-3 pl-send-pro-bg">
                                                  {e.comments}
                                                </p>
                                              </div>
                                            </>
                                          )
                                        )}
                                        {state?.auth?.auth?.role ===
                                          "subadmin" ||
                                          (serviceReqParticular?.commentsTo
                                            ?.length &&
                                            state?.auth?.auth?.role === "user") ||
                                          state?.auth?.auth?.role ===
                                          "superadmin" ||
                                          state?.auth?.auth?.role === "admin" ? (
                                          <>
                                            <div className="input-group flex-nowrap  my-3">
                                              <input
                                                style={{
                                                  border: "1px solid #c9c8c8",
                                                  backgroundColor:
                                                    "transparent",
                                                  borderRight: "0px",
                                                }}
                                                type="text"
                                                className="form-control"
                                                placeholder="Your Message"
                                                aria-invalid={Boolean(
                                                  commentsValidation?.comments
                                                    ?.status === false
                                                )}
                                                defaultValue={
                                                  leadComment?.comments
                                                }
                                                onChange={(e) => {
                                                  setCommentsValue(
                                                    "comments",
                                                    e.target.value
                                                  );
                                                }}
                                                onBlur={(e) => {
                                                  setCommentsLeadValidationValue(
                                                    "comments",

                                                    NonEmptyValidation(
                                                      e.target.value
                                                    )
                                                  );
                                                }}
                                              />
                                              <span
                                                className="input-group-text"
                                                id="addon-wrapping"
                                                style={{
                                                  border: "1px solid #c9c8c8",
                                                  backgroundColor:
                                                    "transparent",
                                                  borderLeft: "0px",
                                                }}
                                              >
                                                <button
                                                  className="btn py-1  login-btn"
                                                  type="button"
                                                  id="button-addon2"
                                                  onClick={(e) => {
                                                    handleSubmitComment(e);
                                                  }}
                                                >
                                                  Send
                                                  <i className="fa-solid fa-paper-plane ms-2"></i>
                                                </button>
                                              </span>
                                            </div>
                                            <small className="text-danger">
                                              {commentsValidation?.comments
                                                ?.message
                                                ? `comments ${commentsValidation?.comments?.message}`
                                                : ""}
                                            </small>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {serviceReqParticular?.twowheeler_id ? (
                          <div>
                            <div className=" my-4">
                              <div className="bg-historytablehead rounded-3 py-3 px-3">
                                <h6 className="mb-0">
                                  More Info
                                </h6>
                              </div>
                              <div className="container">
                                <div className="row justify-content-center py-5">
                                  <div className="col-lg-11">
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <div
                                          className="p-3"
                                          style={{ border: "1px solid black" }}
                                        >
                                          <div className="mt-3">
                                            <h6
                                              className="color-maroon fw-bold"
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              <b>Type:</b>{" "}
                                              {
                                                serviceReqParticular
                                                  ?.twowheeler_id?.wheelerType
                                              }
                                            </h6>
                                            <h6
                                              className="color-maroon fw-bold"
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              <b>Reference No:</b>{" "}
                                              {
                                                serviceReqParticular
                                                  ?.twowheeler_id?.referenceNo
                                              }
                                            </h6>
                                            <div className="row">
                                              <div className="col-6">
                                                <p className="small">
                                                  <b>Brand Name:</b>{" "}
                                                  {
                                                    serviceReqParticular
                                                      ?.twowheeler_id?.brandName
                                                  }
                                                </p>
                                              </div>
                                              <div className="col-6">
                                                <p className="small">
                                                  <b>Model Name:</b>{" "}
                                                  {
                                                    serviceReqParticular
                                                      ?.twowheeler_id?.modelName
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                            <div className="row">
                                              <div className="col-6">
                                                <p className="small">
                                                  <b>Model Year :</b>{" "}
                                                  {
                                                    serviceReqParticular
                                                      ?.twowheeler_id?.modelYear
                                                  }
                                                </p>
                                              </div>
                                              <div className="col-6">
                                                <p className="small">
                                                  <b>Color :</b>{" "}
                                                  {
                                                    serviceReqParticular
                                                      ?.twowheeler_id?.color
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                            <div className="row">
                                              <div className="col-6">
                                                <p className="small">
                                                  <b>Price :</b>{" "}
                                                  {
                                                    serviceReqParticular
                                                      ?.twowheeler_id
                                                      ?.bidingPrice
                                                  }
                                                </p>
                                              </div>
                                              <div className="col-6">
                                                <p className="small">
                                                  <b>End Date :</b>{" "}
                                                  {moment(
                                                    serviceReqParticular
                                                      ?.twowheeler_id?.endDate
                                                  ).format("DD/MM/YYYY")}
                                                </p>
                                              </div>
                                            </div>
                                            <b>Description :</b>{" "}
                                            <p
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  serviceReqParticular
                                                    ?.twowheeler_id
                                                    ?.description,
                                              }}
                                            >
                                              {/* <b>Description :</b>{" "} */}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      {/* <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Name
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            placeholder="Enter the Name"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.name?.status === false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.name
                                            }
                                            onChange={(e) => {
                                              setServiceReqParticularValue(
                                                "",
                                                "name",
                                                e.target.value,
                                                ""
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setServiceReqParticularValidationValue(
                                                "",
                                                "name",
                                                StringValidation(
                                                  e.target.value
                                                ),
                                                ""
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.name?.message
                                              ? `Name ${serviceReqParticularValidation?.name?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Mobile No
                                          </label>
                                          <input
                                            type="tel"
                                            className="form-control form-control-lg"
                                            maxLength={10}
                                            placeholder="Enter the Mobile number"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.mobileNumber?.status === false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.mobileNumber
                                            }
                                            onChange={(e) => {
                                              setServiceReqParticularValue(
                                                "",
                                                "mobileNumber",
                                                e.target.value,
                                                ""
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setServiceReqParticularValidationValue(
                                                "",
                                                "mobileNumber",
                                                PhoneNumberValidation(
                                                  e.target.value
                                                ),
                                                ""
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.mobileNumber?.message
                                              ? `Mobile Number ${serviceReqParticularValidation?.mobileNumber?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Email Id
                                          </label>
                                          <input
                                            type="email"
                                            className="form-control form-control-lg"
                                            placeholder="Enter Email-Id"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.email?.status === false
                                            )}
                                            defaultValue={serviceReqParticular?.email?.toLowerCase()}
                                            onChange={(e) => {
                                              setServiceReqParticularValue(
                                                "",
                                                "email",
                                                e.target.value,
                                                ""
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setServiceReqParticularValidationValue(
                                                "",
                                                "email",
                                                EmailValidation(
                                                  e.target.value?.toLowerCase()
                                                ),
                                                ""
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.email?.message
                                              ? `Email Id ${serviceReqParticularValidation?.email?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Message
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            placeholder="Enter Your Question"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.question?.status === false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.question
                                            }
                                            onChange={(e) => {
                                              setServiceReqParticularValue(
                                                "",
                                                "question",
                                                e.target.value,
                                                ""
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setServiceReqParticularValidationValue(
                                                "",
                                                "question",
                                                NonEmptyValidation(
                                                  e.target.value
                                                ),
                                                ""
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.message?.message
                                              ? `Message ${serviceReqParticularValidation?.message?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>

                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Subject
                                          </label>
                                          <textarea
                                            rows={6}
                                            className="form-control form-control-lg"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.subject?.status === false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.subject
                                            }
                                            onChange={(e) => {
                                              setServiceReqParticularValue(
                                                "",
                                                "subject",
                                                e.target.value,
                                                ""
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setServiceReqParticularValidationValue(
                                                "",
                                                "subject",
                                                NonEmptyValidation(
                                                  e.target.value
                                                ),
                                                ""
                                              );
                                            }}
                                            autoComplete="off"
                                          ></textarea>
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.subject?.message
                                              ? `Subject ${serviceReqParticularValidation?.subject?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label ">
                                            Assigned To
                                          </label>
                                          {serviceReqParticular?.status ===
                                            "unassigned" ? (
                                            <select
                                              className="form-control form-control-lg"
                                              defaultValue={
                                                serviceReqParticular?.assigned_to
                                              }
                                              onChange={(e) => {
                                                setAssign(true);
                                                let name = allSubadmin?.filter(
                                                  (s) =>
                                                    s.name === e.target.value
                                                );
                                                let nameId = name?.map((n) => {
                                                  return n._id;
                                                });

                                                setServiceReqParticularValue(
                                                  "",
                                                  "assigned_to",
                                                  nameId.toString(),
                                                  ""
                                                );
                                              }}
                                              onBlur={(e) => {
                                                let name = allSubadmin?.filter(
                                                  (s) =>
                                                    s.name === e.target.value
                                                );
                                                let nameId = name?.map((n) => {
                                                  return n._id;
                                                });
                                                setServiceReqParticularValidationValue(
                                                  "assigned_to",
                                                  "name",
                                                  NonEmptyValidation(
                                                    nameId.toString()
                                                  ),
                                                  "object"
                                                );
                                              }}
                                            >
                                              {!serviceReqParticular
                                                ?.assigned_to?.name ? (
                                                <option value="">
                                                  ---Select Member---
                                                </option>
                                              ) : (
                                                ""
                                              )}
                                              {allSubadmin?.map((e) => (
                                                // <option value={e._id}>{e.name}</option>
                                                <option value={e.name}>
                                                  {e.name}
                                                </option>
                                              ))}
                                            </select>
                                          ) : (
                                            <>
                                              {reassign === true ? (
                                                <select
                                                  className="form-control form-control-lg"
                                                  value={
                                                    serviceReqParticular
                                                      ?.assigned_to?.name
                                                  }
                                                  onChange={(e) => {
                                                    setAssign(true);
                                                    let name =
                                                      allSubadmin?.filter(
                                                        (s) =>
                                                          s.name ===
                                                          e.target.value
                                                      );
                                                    let nameId = name?.map(
                                                      (n) => {
                                                        return n._id;
                                                      }
                                                    );

                                                    setServiceReqParticularValue(
                                                      "assigned_to",
                                                      "name",
                                                      nameId.toString(),
                                                      "object"
                                                    );
                                                  }}
                                                  onBlur={(e) => {
                                                    let name =
                                                      allSubadmin?.filter(
                                                        (s) =>
                                                          s.name ===
                                                          e.target.value
                                                      );
                                                    let nameId = name?.map(
                                                      (n) => {
                                                        return n._id;
                                                      }
                                                    );
                                                    setServiceReqParticularValidationValue(
                                                      "assigned_to",
                                                      "name",
                                                      NonEmptyValidation(
                                                        nameId.toString()
                                                      ),
                                                      "object"
                                                    );
                                                  }}
                                                  disabled={reassign === true}
                                                >
                                                  {!serviceReqParticular
                                                    ?.assigned_to?.name ? (
                                                    <option value="">
                                                      ---Select Member---
                                                    </option>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {allSubadmin?.map((e, i) => (
                                                    // <option value={e._id}>{e.name}</option>
                                                    <option value={e.name}>
                                                      {e.name}
                                                    </option>
                                                  ))}
                                                </select>
                                              ) : (
                                                <select
                                                  className="form-control form-control-lg"
                                                  defaultValue={
                                                    serviceReqParticular
                                                      ?.assigned_to?.name
                                                  }
                                                  onChange={(e) => {
                                                    setAssign(true);
                                                    let name =
                                                      allSubadmin?.filter(
                                                        (s) =>
                                                          s.name ===
                                                          e.target.value
                                                      );
                                                    let nameId = name?.map(
                                                      (n) => {
                                                        return n._id;
                                                      }
                                                    );

                                                    setServiceReqParticularValue(
                                                      "assigned_to",
                                                      "name",
                                                      nameId.toString(),
                                                      "object"
                                                    );
                                                  }}
                                                  onBlur={(e) => {
                                                    let name =
                                                      allSubadmin?.filter(
                                                        (s) =>
                                                          s.name ===
                                                          e.target.value
                                                      );
                                                    let nameId = name?.map(
                                                      (n) => {
                                                        return n._id;
                                                      }
                                                    );
                                                    setServiceReqParticularValidationValue(
                                                      "assigned_to",
                                                      "name",
                                                      NonEmptyValidation(
                                                        nameId.toString()
                                                      ),
                                                      "object"
                                                    );
                                                  }}
                                                  disabled={reassign === true}
                                                >
                                                  {!serviceReqParticular
                                                    ?.assigned_to?.name ? (
                                                    <option value="">
                                                      ---Select Member---
                                                    </option>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {allSubadmin?.map((e, i) => (
                                                    // <option value={e._id}>{e.name}</option>
                                                    <option value={e.name}>
                                                      {e.name}
                                                    </option>
                                                  ))}
                                                </select>
                                              )}
                                            </>
                                          )}

                                          {serviceReqParticularValidation?.assigned_to ? (
                                            <small className="text-danger">
                                              {serviceReqParticularValidation
                                                ?.assigned_to?.message
                                                ? `Assigned to ${serviceReqParticularValidation?.assigned_to?.message}`
                                                : ""}
                                            </small>
                                          ) : (
                                            ""
                                          )}
                                          {serviceReqParticularValidation
                                            ?.assigned_to?.name ? (
                                            <small className="text-danger">
                                              {serviceReqParticularValidation
                                                ?.assigned_to?.name?.message
                                                ? `Assigned to ${serviceReqParticularValidation?.assigned_to?.name?.message}`
                                                : ""}
                                            </small>
                                          ) : (
                                            ""
                                          )}
                                          {serviceReqParticular?.assigned_to
                                            ?.name ? (
                                            <button
                                              className="btn btn-sm btn-warning mt-2"
                                              onClick={() => {
                                                setReassign(false);
                                              }}
                                            >
                                              Reassign
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-lg-6 my">
                                        <div className="mb-3">
                                          <label
                                            htmlFor="fileUpload"
                                            className="form-label"
                                          >
                                            Select File
                                          </label>

                                          <input
                                            type="file"
                                            className="form-control"
                                            id="file"
                                            ref={CeoRef}
                                            onChange={(e) => {
                                              uploadCeoPic(e);
                                            }}
                                          />
                                        </div>
                                        {serviceReqParticular?.fileUploadTo?.map(
                                          (img, index) => (
                                            <>
                                              {index + 1}.{" "}
                                              <a href={img} target="_blank">
                                                {img}
                                              </a>
                                            </>
                                          )
                                        )}
                                      </div>
                                      {serviceReqParticular?.status ===
                                        "unassigned" ? (
                                        ""
                                      ) : (
                                        <div className="col-lg-6">
                                          <div className="mb-3">
                                            <label
                                              for="services"
                                              className="form-label"
                                            >
                                              Status
                                            </label>
                                            <select
                                              className="form-control"
                                              value={
                                                serviceReqParticular?.status
                                              }
                                              onChange={(e) => {
                                                setServiceReqParticularValue(
                                                  "",
                                                  "status",
                                                  e.target.value,
                                                  ""
                                                );
                                              }}
                                            >
                                              <option value="ToDo">
                                                To Do
                                              </option>
                                              <option value="assigned">
                                                Assigned
                                              </option>
                                              <option value="unassigned">
                                                Unassigned
                                              </option>
                                              <option value="inprogress">
                                                In Progress
                                              </option>
                                              <option value="completed">
                                                Completed
                                              </option>
                                            </select>
                                            <small className="text-danger">
                                              {serviceReqParticularValidation
                                                ?.status?.message
                                                ? `Status ${serviceReqParticularValidation?.status?.message}`
                                                : ""}
                                            </small>
                                          </div>
                                        </div>
                                      )}
                                      <div className="col-lg-12">
                                        <button
                                          type="button"
                                          className="btn login-btn ms-2 "
                                          onClick={(e) => {
                                            handleSubmit(e);
                                          }}
                                        >
                                          Save
                                        </button>
                                      </div> */}
                                      <>
                                        {/* {serviceReqParticular?.length && state?.auth?.auth?.role==="user" ? (<> */}
                                        {/* {serviceReqParticular?.commentsTo?.map(
                                          (e) => (
                                            <>
                                              <div
                                                className="send-pro-bg mt-4"
                                                style={{
                                                  border: "1px solid black",
                                                  borderRadius: "8px",
                                                  marginBottom: "8px",
                                                }}
                                              >
                                                <div className="d-flex align-items-center justify-content-between">
                                                  <div className="d-flex   align-items-center">
                                                    <div className="flex-shrink-0 ">
                                                      {e?.profileImage ? (
                                                        <img
                                                          className="admin-pro-img "
                                                          style={{
                                                            width: "50px",
                                                            height: "50px",
                                                          }}
                                                          src={e?.profileImage}
                                                          alt="..."
                                                        />
                                                      ) : (
                                                        <span className="material-symbols-rounded filled-icon dash-icon">
                                                          person
                                                        </span>
                                                      )}
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                      <h6 className="fw-bold">
                                                        {e?.name}
                                                      </h6>
                                                      {e?.pageAccess ? (
                                                        <>
                                                          {listEmployee
                                                            ?.filter(
                                                              (sa) =>
                                                                sa?._id ===
                                                                e?.pageAccess
                                                            )
                                                            .map((saa) => {
                                                              return (
                                                                <p
                                                                  style={{
                                                                    textTransform:
                                                                      "capitalize",
                                                                  }}
                                                                  className="mb-0"
                                                                >
                                                                  {saa?.title}
                                                                </p>
                                                              );
                                                            }) ||
                                                            listAdmin
                                                              ?.filter(
                                                                (sa) =>
                                                                  sa?._id ===
                                                                  e?.pageAccess
                                                              )
                                                              .map((saa) => {
                                                                return (
                                                                  <p
                                                                    style={{
                                                                      textTransform:
                                                                        "capitalize",
                                                                    }}
                                                                    className="mb-0"
                                                                  >
                                                                    {saa?.title}
                                                                  </p>
                                                                );
                                                              })}
                                                        </>
                                                      ) : (
                                                        <p
                                                          className="mb-0"
                                                          style={{
                                                            textTransform:
                                                              "capitalize",
                                                          }}
                                                        >
                                                          {e.role}
                                                        </p>
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div>
                                                    <p className="mb-0">
                                                      Asked{" "}
                                                      {moment(
                                                        e?.createdAt
                                                      ).fromNow()}
                                                    </p>
                                                  </div>
                                                </div>
                                                <p className="mb-0 mt-3 pl-send-pro-bg">
                                                  {e.comments}
                                                </p>
                                              </div>
                                            </>
                                          )
                                        )} */}
                                        {/* {state?.auth?.auth?.role ===
                                          "subadmin" ||
                                          (serviceReqParticular?.commentsTo
                                            ?.length &&
                                            state?.auth?.auth?.role === "user") ||
                                          state?.auth?.auth?.role ===
                                          "superadmin" ||
                                          state?.auth?.auth?.role === "admin" ? (
                                          <>
                                            <div className="input-group flex-nowrap  my-3">
                                              <input
                                                style={{
                                                  border: "1px solid #c9c8c8",
                                                  backgroundColor:
                                                    "transparent",
                                                  borderRight: "0px",
                                                }}
                                                type="text"
                                                className="form-control"
                                                placeholder="Your Message"
                                                aria-invalid={Boolean(
                                                  commentsValidation?.comments
                                                    ?.status === false
                                                )}
                                                defaultValue={
                                                  leadComment?.comments
                                                }
                                                onChange={(e) => {
                                                  setCommentsValue(
                                                    "comments",
                                                    e.target.value
                                                  );
                                                }}
                                                onBlur={(e) => {
                                                  setCommentsLeadValidationValue(
                                                    "comments",

                                                    NonEmptyValidation(
                                                      e.target.value
                                                    )
                                                  );
                                                }}
                                              />
                                              <span
                                                className="input-group-text"
                                                id="addon-wrapping"
                                                style={{
                                                  border: "1px solid #c9c8c8",
                                                  backgroundColor:
                                                    "transparent",
                                                  borderLeft: "0px",
                                                }}
                                              >
                                                <button
                                                  className="btn py-1  login-btn"
                                                  type="button"
                                                  id="button-addon2"
                                                  onClick={(e) => {
                                                    handleSubmitComment(e);
                                                  }}
                                                >
                                                  Send
                                                  <i className="fa-solid fa-paper-plane ms-2"></i>
                                                </button>
                                              </span>
                                            </div>
                                            <small className="text-danger">
                                              {commentsValidation?.comments
                                                ?.message
                                                ? `comments ${commentsValidation?.comments?.message}`
                                                : ""}
                                            </small>
                                          </>
                                        ) : (
                                          ""
                                        )} */}
                                      </>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <>
                        {serviceReqParticular?.servicesCategory ? (
                          <div>
                            <div className=" my-4">
                              <div className="container">
                                <div className="row justify-content-center py-5">
                                  <div className="col-lg-11">
                                    <div className="row">
                                      <div className="col-12">
                                        {serviceReqParticular?.getPurchaseInfo
                                          ?.status === "Paid" ? (
                                          <div className="mb-4 fs-5 fw-bold color-maroon mr-2">
                                            <button className="btn btn-admin">
                                              Paid User
                                            </button>
                                          </div>
                                        ) : (
                                          <div className="mb-4 fs-5 fw-bold color-maroon mr-2">
                                            <button className="btn btn-admin">
                                              Non Paid User
                                            </button>
                                          </div>
                                        )}
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Name
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            placeholder="Enter the Name"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.name?.status === false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.name
                                            }
                                            disabled
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.name?.message
                                              ? `Name ${serviceReqParticularValidation?.name?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Mobile No
                                          </label>
                                          <input
                                            type="tel"
                                            className="form-control form-control-lg"
                                            maxLength={10}
                                            placeholder="Enter the Mobile number"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.mobileNumber?.status === false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.mobileNumber
                                            }
                                            disabled
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.mobileNumber?.message
                                              ? `Mobile Number ${serviceReqParticularValidation?.mobileNumber?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Email Id
                                          </label>
                                          <input
                                            type="email"
                                            className="form-control form-control-lg"
                                            placeholder="Enter Email-Id"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.email?.status === false
                                            )}
                                            defaultValue={serviceReqParticular?.email?.toLowerCase()}
                                            disabled
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.email?.message
                                              ? `Email Id ${serviceReqParticularValidation?.email?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Question
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            placeholder="Enter Your Question"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.question?.status === false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.question
                                            }
                                            disabled
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.question?.message
                                              ? `Question ${serviceReqParticularValidation?.question?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>

                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Service Category
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            placeholder="Enter the Service Category"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.servicesCategory?.status ===
                                              false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.servicesCategory
                                            }
                                            disabled
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.servicesCategory?.message
                                              ? `Category ${serviceReqParticularValidation?.servicesCategory?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Service Subcategory
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            placeholder="enter the Service Subcategory"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.servicesSubcategory
                                                ?.status === false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.servicesSubcategory
                                            }
                                            disabled
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.servicesSubcategory?.message
                                              ? `Subcategory ${serviceReqParticularValidation?.servicesSubcategory?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Service Title
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            placeholder="Enter the Service Title"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.servicesTitle?.status ===
                                              false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.servicesTitle
                                            }
                                            disabled
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.servicesTitle?.message
                                              ? `Title ${serviceReqParticularValidation?.servicesTitle?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>

                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label ">
                                            Assigned To
                                          </label>
                                          {
                                            state?.auth?.auth?.role != "admin" ?
                                              <input type="text"
                                                className="form-control form-control-lg"
                                                value={serviceReqParticular
                                                  ?.assigned_to?.name} disabled />
                                              :
                                              <>
                                                {serviceReqParticular?.status ===
                                                  "unassigned" ? (
                                                  <select
                                                    className="form-control form-control-lg"
                                                    defaultValue={
                                                      serviceReqParticular
                                                        ?.assigned_to?.name
                                                    }
                                                    disabled
                                                  >
                                                    {!serviceReqParticular
                                                      ?.assigned_to?.name ? (
                                                      <option value="">
                                                        ---Select Member---
                                                      </option>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {allSubadmin?.map((e) => (
                                                      // <option value={e._id}>{e.name}</option>
                                                      <option value={e.name}>
                                                        {e.name}
                                                      </option>
                                                    ))}
                                                  </select>
                                                ) : (
                                                  <select
                                                    className="form-control form-control-lg"
                                                    defaultValue={
                                                      serviceReqParticular
                                                        ?.assigned_to?.name
                                                    }
                                                    disabled={reassign === true}
                                                  >
                                                    {!serviceReqParticular
                                                      ?.assigned_to?.name ? (
                                                      <option value="">
                                                        ---Select Member---
                                                      </option>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {allSubadmin?.map((e, i) => (
                                                      // <option value={e._id}>{e.name}</option>
                                                      <option value={e.name}>
                                                        {e.name}
                                                      </option>
                                                    ))}
                                                  </select>
                                                )}
                                              </>
                                          }
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.assigned_to?.name?.message
                                              ? `Assigned to ${serviceReqParticularValidation?.assigned_to?.name?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 my">
                                        {state?.auth?.auth?.role === "user" ? (
                                          <div className="mb-3">
                                            <label
                                              htmlFor="fileUpload"
                                              className="form-label"
                                            >
                                              Select File
                                            </label>

                                            <input
                                              type="file"
                                              className="form-control"
                                              id="file"
                                              ref={CeoRef}
                                              onChange={(e) => {
                                                uploadCeoPic(e);
                                              }}
                                              disabled
                                            />
                                            {serviceReqParticular?.fileUploadTo?.map(
                                              (img, index) => (
                                                <>
                                                  <a
                                                    key={index}
                                                    style={{ color: "black" }}
                                                    href={img}
                                                    target="_blank"
                                                  >
                                                    {index + 1} {img}
                                                  </a>
                                                  <br />
                                                </>
                                              )
                                            )}
                                          </div>
                                        ) : (
                                          <div className="mb-3">
                                            <label
                                              htmlFor="fileUpload"
                                              className="form-label"
                                            >
                                              Select File
                                            </label>

                                            <input
                                              type="file"
                                              className="form-control"
                                              id="file"
                                              ref={CeoRef}
                                              onChange={(e) => {
                                                uploadCeoPic(e);
                                              }}
                                            />
                                            {serviceReqParticular?.fileUploadTo?.map(
                                              (img, index) => (
                                                <>
                                                  <a
                                                    key={index}
                                                    style={{ color: "black" }}
                                                    href={img}
                                                    target="_blank"
                                                  >
                                                    {index + 1} {img}
                                                  </a>
                                                  <br />
                                                </>
                                              )
                                            )}
                                          </div>
                                        )}
                                      </div>

                                      <div className="col-lg-6">
                                        {state?.auth?.auth?.role === "user" ? (
                                          <>
                                            {serviceReqParticular?.status ===
                                              "unassigned" ? (
                                              ""
                                            ) : (
                                              <>
                                                <div className="mb-3">
                                                  <label
                                                    for="services"
                                                    className="form-label"
                                                  >
                                                    Status
                                                  </label>
                                                  <select
                                                    className="form-control"
                                                    value={
                                                      serviceReqParticular?.status
                                                    }
                                                    onChange={(e) => {
                                                      setServiceReqParticularValue(
                                                        "",
                                                        "status",
                                                        e.target.value,
                                                        ""
                                                      );
                                                    }}
                                                    disabled
                                                  >
                                                    <option value="ToDo">
                                                      To Do
                                                    </option>
                                                    <option value="assigned">
                                                      Assigned
                                                    </option>
                                                    <option value="unassigned">
                                                      Unassigned
                                                    </option>
                                                    <option value="inprogress">
                                                      In Progress
                                                    </option>
                                                    <option value="completed">
                                                      Completed
                                                    </option>
                                                  </select>
                                                  <small className="text-danger">
                                                    {serviceReqParticularValidation
                                                      ?.status?.message
                                                      ? `Status ${serviceReqParticularValidation?.status?.message}`
                                                      : ""}
                                                  </small>
                                                </div>
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {serviceReqParticular?.status ===
                                              "unassigned" ? (
                                              ""
                                            ) : (
                                              <div className="mb-3">
                                                <label
                                                  for="services"
                                                  className="form-label"
                                                >
                                                  Status
                                                </label>
                                                <select
                                                  className="form-control"
                                                  defaultValue={
                                                    serviceReqParticular?.status
                                                  }
                                                  onChange={(e) => {
                                                    setOnChangeStatus(!onChangeStatus)
                                                    setServiceReqParticularValue(
                                                      "",
                                                      "status",
                                                      e.target.value,
                                                      ""
                                                    );
                                                  }}
                                                >
                                                  <option value="ToDo">
                                                    To Do
                                                  </option>
                                                  <option value="assigned">
                                                    Assigned
                                                  </option>
                                                  <option value="unassigned">
                                                    Unassigned
                                                  </option>
                                                  <option value="inprogress">
                                                    In Progress
                                                  </option>
                                                  <option value="completed">
                                                    Completed
                                                  </option>
                                                </select>
                                                <small className="text-danger">
                                                  {serviceReqParticularValidation
                                                    ?.status?.message
                                                    ? `Status ${serviceReqParticularValidation?.status?.message}`
                                                    : ""}
                                                </small>
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Subject
                                          </label>
                                          <textarea
                                            rows={6}
                                            className="form-control form-control-lg"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.subject?.status === false
                                            )}
                                            defaultValuealue={
                                              serviceReqParticular?.subject
                                            }
                                            disabled
                                            autoComplete="off"
                                          ></textarea>
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.subject?.message
                                              ? `Subject ${serviceReqParticularValidation?.subject?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          {(state?.auth?.auth?.role ===
                                            "subadmin" &&
                                            serviceReqParticular?.getPackageInfo) ||
                                            (state?.auth?.auth?.role ===
                                              "admin" &&
                                              serviceReqParticular?.getPackageInfo) ? (
                                            <>
                                              <div className="col-lg-6">
                                                <div className="mb-4">
                                                  <label className="form-label ">
                                                    Package Details
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    value={
                                                      `Title: ${serviceReqParticular?.getPurchaseInfo
                                                        ?.PackageInfo?.title}`
                                                    }
                                                    disabled
                                                  />
                                                  <p
                                                    className="form-control mt-2"
                                                    disabled
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        `Description: ${serviceReqParticular?.getPurchaseInfo
                                                          ?.PackageInfo
                                                          ?.description}`,
                                                    }}
                                                  ></p>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    value={`Price: ₹${serviceReqParticular?.getPurchaseInfo
                                                      ?.price}`
                                                    }
                                                    disabled
                                                  />
                                                  <button className="btn-admin mt-2">
                                                    Status :{" "}
                                                    {
                                                      serviceReqParticular
                                                        ?.getPurchaseInfo
                                                        ?.status
                                                    }
                                                  </button>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {state?.auth?.auth?.role === "user" &&
                                            serviceReqParticular?.getPackageInfo ? (
                                            <>
                                              <>
                                                <div className="col-lg-6">
                                                  <div className="mb-4">
                                                    <label className="form-label ">
                                                      Package Details
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      value={
                                                        `Title: ${serviceReqParticular?.getPurchaseInfo
                                                          ?.PackageInfo?.title}`
                                                      }
                                                      disabled
                                                    />
                                                    <p
                                                      className="form-control mt-2"
                                                      disabled
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          `Description: ${serviceReqParticular?.getPurchaseInfo
                                                            ?.PackageInfo
                                                            ?.description}`,
                                                      }}
                                                    ></p>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      value={`Price: ₹${serviceReqParticular?.getPurchaseInfo
                                                        ?.price}`
                                                      }
                                                      disabled
                                                    />
                                                    <button className="btn-admin">
                                                      Status :{" "}
                                                      {
                                                        serviceReqParticular
                                                          ?.getPurchaseInfo
                                                          ?.status
                                                      }
                                                    </button>
                                                  </div>
                                                </div>
                                              </>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {state?.auth?.auth?.role === "user" &&
                                            serviceReqParticular?.getPurchaseInfo
                                              ?.status === "Not Paid" ? (
                                            <button
                                              className="login-btn"
                                              onClick={(e) => {
                                                HandleCCAvenuePaymentCustSrv(
                                                  serviceReqParticular
                                                    ?.getPackageInfo?.orderId,
                                                  serviceReqParticular
                                                    ?.getPurchaseInfo?._id,
                                                  state?.auth?.auth?.token
                                                );
                                              }}
                                            >
                                              Make Payment
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                          {console.log(serviceReqParticular)}

                                          {(state?.auth?.auth?.role ===
                                            "subadmin" &&
                                            serviceReqParticular?.getPurchaseInfo) ||
                                            (state?.auth?.auth?.role ===
                                              "admin" &&
                                              serviceReqParticular?.getPurchaseInfo) ? (
                                            <>
                                              <div className="col-lg-6">
                                                <div className="mb-4">
                                                  <label className="form-label ">
                                                    Package Details
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    value={
                                                      `Title: ${serviceReqParticular?.getPurchaseInfo
                                                        ?.PackageInfo?.title}`
                                                    }
                                                    disabled
                                                  />
                                                  <p
                                                    className="form-control mt-2"
                                                    disabled
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        `Description: ${serviceReqParticular?.getPurchaseInfo
                                                          ?.PackageInfo
                                                          ?.description}`,
                                                    }}
                                                  ></p>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    value={`Price: ₹${serviceReqParticular?.getPurchaseInfo
                                                      ?.price}`
                                                    }
                                                    disabled
                                                  />
                                                  <button className="btn-admin mt-2">
                                                    Status :{" "}
                                                    {
                                                      serviceReqParticular
                                                        ?.getPurchaseInfo
                                                        ?.status
                                                    }
                                                  </button>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            ""
                                            // <button
                                            //   className="login-btn"
                                            //   onClick={(e) => {
                                            //     setPackageModal(true);
                                            //   }}
                                            // >
                                            //   Request Payment
                                            // </button>
                                          )}
                                          {(state?.auth?.auth?.role ===
                                            "subadmin" &&
                                            !serviceReqParticular?.getPurchaseInfo) ||
                                            (state?.auth?.auth?.role ===
                                              "admin" &&
                                              !serviceReqParticular?.getPurchaseInfo) ? (
                                            <button
                                              className="login-btn"
                                              onClick={(e) => {
                                                setPackageModal(true);
                                              }}
                                            >
                                              Request Payment
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-lg-12">
                                      <label className="form-label">Resolution Comment</label>
                                      <div
                                          className="form-control form-control-lg"
                                          style={{
                                            height: "250px",
                                            border: "4px solid maroon",
                                          }}
                                          dangerouslySetInnerHTML={{
                                            __html: serviceReqParticular?.resolutionComment
                                          }}
                                        >
                                          </div>
                                        {/* <button
                                          type="button"
                                          className="btn login-btn ms-2 "
                                          onClick={(e) => {
                                            handleSubmit(e);
                                          }}
                                        >
                                          Save
                                        </button> */}
                                      </div>

                                      {serviceReqParticular?.commentsTo?.map(
                                        (e, i) => (
                                          <>
                                            <div
                                              key={i}
                                              className="send-pro-bg mt-3"
                                              style={{
                                                border: "1px solid black",
                                                borderRadius: "8px",
                                                marginBottom: "8px",
                                              }}
                                            >
                                              <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex   align-items-center">
                                                  <div className="flex-shrink-0 ">
                                                    {e.profileImage ? (
                                                      <img
                                                        className="admin-pro-img "
                                                        style={{
                                                          width: "50px",
                                                          height: "50px",
                                                        }}
                                                        src={e.profileImage}
                                                        alt="..."
                                                      />
                                                    ) : (
                                                      <span className="material-symbols-rounded filled-icon dash-icon">
                                                        person
                                                      </span>
                                                    )}
                                                  </div>
                                                  <div className="flex-grow-1 ms-3">
                                                    <h6 className="fw-bold">
                                                      {e.name}
                                                    </h6>

                                                    {e.Designation ? (
                                                      <p
                                                        style={{
                                                          textTransform:
                                                            "capitalize",
                                                        }}
                                                        className="mb-0"
                                                      >
                                                        {e.Designation}
                                                      </p>
                                                    ) : (
                                                      <p
                                                        style={{
                                                          textTransform:
                                                            "capitalize",
                                                        }}
                                                        className="mb-0"
                                                      >
                                                        {e.role}
                                                      </p>
                                                    )}
                                                  </div>
                                                </div>
                                                <div>
                                                  <p className="mb-0">
                                                    Asked{" "}
                                                    {moment(
                                                      e.createdAt
                                                    ).fromNow()}
                                                  </p>
                                                </div>
                                              </div>
                                              <p
                                                className="mb-0 mt-3 pl-send-pro-bg"
                                                dangerouslySetInnerHTML={{
                                                  __html: e.comments,
                                                }}
                                              >
                                                {/* {e.comments} */}
                                              </p>
                                            </div>
                                          </>
                                        )
                                      )}

                                      <div className="input-group flex-nowrap  my-3">
                                        <div
                                          className="form-control form-control-lg"
                                          style={{ height: "250px" }}
                                        >
                                          <Editor
                                            wrapperClassName="wrapper-class"
                                            editorClassName="editor-class"
                                            toolbarClassName="toolbar-class"
                                            editorState={editorState1}
                                            onEditorStateChange={(e) =>
                                              handleEditorChange1(e)
                                            }
                                          />
                                        </div>
                                        {/* <input
                                              style={{
                                                border: "1px solid #c9c8c8",
                                                backgroundColor: "transparent",
                                                borderRight: "0px",
                                              }}
                                              type="text"
                                              className="form-control"
                                              placeholder="Your Message"
                                              aria-invalid={Boolean(
                                                commentsValidation?.comments
                                                  ?.status === false
                                              )}
                                              defaultValue={
                                                leadComment?.comments
                                              }
                                              onChange={(e) => {
                                                setCommentsValue(
                                                  "comments",
                                                  e.target.value
                                                );
                                              }}
                                              onBlur={(e) => {
                                                setCommentsLeadValidationValue(
                                                  "comments",
                                                  NonEmptyValidation(
                                                    e.target.value
                                                  )
                                                );
                                              }}
                                              aria-describedby="addon-wrapping"
                                            /> */}
                                        <span
                                          className="input-group-text"
                                          id="addon-wrapping"
                                          style={{
                                            border: "1px solid #c9c8c8",
                                            backgroundColor: "transparent",
                                            borderLeft: "0px",
                                          }}
                                        >
                                          <button
                                            className="btn py-1  login-btn"
                                            type="button"
                                            id="button-addon2"
                                            onClick={(e) => {
                                              handleSubmitComment(e);
                                            }}
                                          >
                                            Send
                                            <i className="fa-solid fa-paper-plane ms-2"></i>
                                          </button>
                                        </span>
                                      </div>
                                      <small className="text-danger">
                                        {commentsValidation?.comments?.message
                                          ? `comments ${commentsValidation?.comments?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {serviceReqParticular?.property_id ? (
                          <div>
                            <div className=" my-4">
                              <div className="bg-historytablehead rounded-3 py-3 px-3">
                                <h6 className="mb-0">Update Property</h6>
                              </div>
                              <div className="container">
                                <div className="row justify-content-center py-5">
                                  <div className="col-lg-11">
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <div
                                          className="p-3"
                                          style={{ border: "1px solid black" }}
                                        >
                                          <div className="small-banner-img-head">
                                            <img
                                              style={{
                                                width: "100%",
                                                height: "200px",
                                              }}
                                              // className="w-100 h-auto"
                                              src={
                                                serviceReqParticular
                                                  ?.property_id?.cover_image
                                              }
                                              alt=""
                                            />
                                          </div>
                                          <div className="mt-3">
                                            <h6
                                              className="color-maroon fw-bold"
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              {
                                                serviceReqParticular
                                                  ?.property_id?.property_name
                                              }
                                            </h6>
                                            <p className="small">
                                              <span className="material-symbols-rounded align-middle me-1 filled-icon">
                                                location_on
                                              </span>
                                              {
                                                serviceReqParticular
                                                  ?.property_id
                                                  ?.property_information.address
                                              }
                                            </p>

                                            <p className="small">
                                              <b>Room:</b>{" "}
                                              {
                                                serviceReqParticular
                                                  ?.property_id
                                                  ?.property_information
                                                  .room_size
                                              }
                                              BHK
                                            </p>
                                            <p className="small">
                                              <b>SqFt :</b>{" "}
                                              {
                                                serviceReqParticular
                                                  ?.property_id
                                                  ?.property_information.sq_ft
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Name
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            placeholder="Enter the Name"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.name?.status === false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.name
                                            }
                                            disabled
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.name?.message
                                              ? `Name ${serviceReqParticularValidation?.name?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Mobile No
                                          </label>
                                          <input
                                            type="tel"
                                            className="form-control form-control-lg"
                                            maxLength={10}
                                            placeholder="Enter the Mobile number"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.mobileNumber?.status === false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.mobileNumber
                                            }
                                            disabled
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.mobileNumber?.message
                                              ? `Mobile Number ${serviceReqParticularValidation?.mobileNumber?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Email Id
                                          </label>
                                          <input
                                            type="email"
                                            className="form-control form-control-lg"
                                            placeholder="Enter Email-Id"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.email?.status === false
                                            )}
                                            defaultValue={serviceReqParticular?.email?.toLowerCase()}
                                            disabled
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.email?.message
                                              ? `Email Id ${serviceReqParticularValidation?.email?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Message
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            placeholder="Enter Your Question"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.question?.status === false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.question
                                            }
                                            disabled
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.message?.message
                                              ? `Message ${serviceReqParticularValidation?.message?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>

                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Subject
                                          </label>
                                          <textarea
                                            rows={6}
                                            className="form-control form-control-lg"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.subject?.status === false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.subject
                                            }
                                            disabled
                                            autoComplete="off"
                                          ></textarea>
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.subject?.message
                                              ? `Subject ${serviceReqParticularValidation?.subject?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label ">
                                            Assigned To6
                                          </label>
                                          {serviceReqParticular?.status ===
                                            "unassigned" ? (
                                            <select
                                              className="form-control form-control-lg"
                                              defaultValue={
                                                serviceReqParticular?.assigned_to
                                              }
                                              disabled
                                            >
                                              {!serviceReqParticular
                                                ?.assigned_to?.name ? (
                                                <option value="">
                                                  ---Select Member---
                                                </option>
                                              ) : (
                                                ""
                                              )}
                                              {allSubadmin?.map((e) => (
                                                // <option value={e._id}>{e.name}</option>
                                                <option value={e.name}>
                                                  {e.name}
                                                </option>
                                              ))}
                                            </select>
                                          ) : (
                                            <>
                                              {reassign === true ? (
                                                <select
                                                  className="form-control form-control-lg"
                                                  value={
                                                    serviceReqParticular
                                                      ?.assigned_to?.name
                                                  }
                                                  disabled
                                                >
                                                  {!serviceReqParticular
                                                    ?.assigned_to?.name ? (
                                                    <option value="">
                                                      ---Select Member---
                                                    </option>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {allSubadmin?.map((e, i) => (
                                                    // <option value={e._id}>{e.name}</option>
                                                    <option value={e.name}>
                                                      {e.name}
                                                    </option>
                                                  ))}
                                                </select>
                                              ) : (
                                                <select
                                                  className="form-control form-control-lg"
                                                  defaultValue={
                                                    serviceReqParticular
                                                      ?.assigned_to?.name
                                                  }
                                                  disabled
                                                >
                                                  {!serviceReqParticular
                                                    ?.assigned_to?.name ? (
                                                    <option value="">
                                                      ---Select Member---
                                                    </option>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {allSubadmin?.map((e, i) => (
                                                    // <option value={e._id}>{e.name}</option>
                                                    <option value={e.name}>
                                                      {e.name}
                                                    </option>
                                                  ))}
                                                </select>
                                              )}
                                            </>
                                          )}

                                          {serviceReqParticularValidation?.assigned_to ? (
                                            <small className="text-danger">
                                              {serviceReqParticularValidation
                                                ?.assigned_to?.message
                                                ? `Assigned to ${serviceReqParticularValidation?.assigned_to?.message}`
                                                : ""}
                                            </small>
                                          ) : (
                                            ""
                                          )}
                                          {serviceReqParticularValidation
                                            ?.assigned_to?.name ? (
                                            <small className="text-danger">
                                              {serviceReqParticularValidation
                                                ?.assigned_to?.name?.message
                                                ? `Assigned to ${serviceReqParticularValidation?.assigned_to?.name?.message}`
                                                : ""}
                                            </small>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-lg-6 my">
                                        <div className="mb-3">
                                          <label
                                            htmlFor="fileUpload"
                                            className="form-label"
                                          >
                                            Select File
                                          </label>

                                          <input
                                            type="file"
                                            className="form-control"
                                            id="file"
                                            ref={CeoRef}
                                            onChange={(e) => {
                                              uploadCeoPic(e);
                                            }}
                                          />
                                        </div>
                                        {serviceReqParticular?.fileUploadTo?.map(
                                          (img, index) => (
                                            <>
                                              {index + 1}.{" "}
                                              <a href={img} target="_blank">
                                                {img}
                                              </a>
                                            </>
                                          )
                                        )}
                                      </div>
                                      {serviceReqParticular?.status ===
                                        "unassigned" ? (
                                        ""
                                      ) : (
                                        <div className="col-lg-6">
                                          <div className="mb-3">
                                            <label
                                              for="services"
                                              className="form-label"
                                            >
                                              Status
                                            </label>
                                            <select
                                              className="form-control"
                                              value={
                                                serviceReqParticular?.status
                                              }
                                              onChange={(e) => {
                                                setServiceReqParticularValue(
                                                  "",
                                                  "status",
                                                  e.target.value,
                                                  ""
                                                );
                                              }}
                                            >
                                              <option value="ToDo">
                                                To Do
                                              </option>
                                              <option value="assigned">
                                                Assigned
                                              </option>
                                              <option value="unassigned">
                                                Unassigned
                                              </option>
                                              <option value="inprogress">
                                                In Progress
                                              </option>
                                              <option value="completed">
                                                Completed
                                              </option>
                                            </select>
                                            <small className="text-danger">
                                              {serviceReqParticularValidation
                                                ?.status?.message
                                                ? `Status ${serviceReqParticularValidation?.status?.message}`
                                                : ""}
                                            </small>
                                          </div>
                                        </div>
                                      )}
                                      <div className="col-lg-12">
                                        <button
                                          type="button"
                                          className="btn login-btn ms-2 "
                                          onClick={(e) => {
                                            handleSubmit(e);
                                          }}
                                        >
                                          Save
                                        </button>
                                      </div>
                                      <>
                                        {/* {serviceReqParticular?.length && state?.auth?.auth?.role==="user" ? (<> */}
                                        {serviceReqParticular?.commentsTo?.map(
                                          (e) => (
                                            <>
                                              <div
                                                className="send-pro-bg mt-4"
                                                style={{
                                                  border: "1px solid black",
                                                  borderRadius: "8px",
                                                  marginBottom: "8px",
                                                }}
                                              >
                                                <div className="d-flex align-items-center justify-content-between">
                                                  <div className="d-flex   align-items-center">
                                                    <div className="flex-shrink-0 ">
                                                      {e?.profileImage ? (
                                                        <img
                                                          className="admin-pro-img "
                                                          style={{
                                                            width: "50px",
                                                            height: "50px",
                                                          }}
                                                          src={e?.profileImage}
                                                          alt="..."
                                                        />
                                                      ) : (
                                                        <span className="material-symbols-rounded filled-icon dash-icon">
                                                          person
                                                        </span>
                                                      )}
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                      <h6 className="fw-bold">
                                                        {e?.name}
                                                      </h6>
                                                      {e?.pageAccess ? (
                                                        <>
                                                          {listEmployee
                                                            ?.filter(
                                                              (sa) =>
                                                                sa?._id ===
                                                                e?.pageAccess
                                                            )
                                                            .map((saa) => {
                                                              return (
                                                                <p
                                                                  style={{
                                                                    textTransform:
                                                                      "capitalize",
                                                                  }}
                                                                  className="mb-0"
                                                                >
                                                                  {saa?.title}
                                                                </p>
                                                              );
                                                            }) ||
                                                            listAdmin
                                                              ?.filter(
                                                                (sa) =>
                                                                  sa?._id ===
                                                                  e?.pageAccess
                                                              )
                                                              .map((saa) => {
                                                                return (
                                                                  <p
                                                                    style={{
                                                                      textTransform:
                                                                        "capitalize",
                                                                    }}
                                                                    className="mb-0"
                                                                  >
                                                                    {saa?.title}
                                                                  </p>
                                                                );
                                                              })}
                                                        </>
                                                      ) : (
                                                        <p
                                                          className="mb-0"
                                                          style={{
                                                            textTransform:
                                                              "capitalize",
                                                          }}
                                                        >
                                                          {e.role}
                                                        </p>
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div>
                                                    <p className="mb-0">
                                                      Asked{" "}
                                                      {moment(
                                                        e?.createdAt
                                                      ).fromNow()}
                                                    </p>
                                                  </div>
                                                </div>
                                                <p className="mb-0 mt-3 pl-send-pro-bg">
                                                  {e.comments}
                                                </p>
                                              </div>
                                            </>
                                          )
                                        )}
                                        {state?.auth?.auth?.role ===
                                          "subadmin" ||
                                          (serviceReqParticular?.commentsTo
                                            ?.length &&
                                            state?.auth?.auth?.role === "user") ||
                                          state?.auth?.auth?.role ===
                                          "superadmin" ||
                                          state?.auth?.auth?.role === "admin" ? (
                                          <>
                                            <div className="input-group flex-nowrap  my-3">
                                              <input
                                                style={{
                                                  border: "1px solid #c9c8c8",
                                                  backgroundColor:
                                                    "transparent",
                                                  borderRight: "0px",
                                                }}
                                                type="text"
                                                className="form-control"
                                                placeholder="Your Message"
                                                aria-invalid={Boolean(
                                                  commentsValidation?.comments
                                                    ?.status === false
                                                )}
                                                defaultValue={
                                                  leadComment?.comments
                                                }
                                                onChange={(e) => {
                                                  setCommentsValue(
                                                    "comments",
                                                    e.target.value
                                                  );
                                                }}
                                                onBlur={(e) => {
                                                  setCommentsLeadValidationValue(
                                                    "comments",

                                                    NonEmptyValidation(
                                                      e.target.value
                                                    )
                                                  );
                                                }}
                                              />
                                              <span
                                                className="input-group-text"
                                                id="addon-wrapping"
                                                style={{
                                                  border: "1px solid #c9c8c8",
                                                  backgroundColor:
                                                    "transparent",
                                                  borderLeft: "0px",
                                                }}
                                              >
                                                <button
                                                  className="btn py-1  login-btn"
                                                  type="button"
                                                  id="button-addon2"
                                                  onClick={(e) => {
                                                    handleSubmitComment(e);
                                                  }}
                                                >
                                                  Send
                                                  <i className="fa-solid fa-paper-plane ms-2"></i>
                                                </button>
                                              </span>
                                            </div>
                                            <small className="text-danger">
                                              {commentsValidation?.comments
                                                ?.message
                                                ? `comments ${commentsValidation?.comments?.message}`
                                                : ""}
                                            </small>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {serviceReqParticular?.goldauction_id ? (
                          <div>
                            <div className=" my-4">
                              <div className="bg-historytablehead rounded-3 py-3 px-3">
                                <h6 className="mb-0">Update Property</h6>
                              </div>
                              <div className="container">
                                <div className="row justify-content-center py-5">
                                  <div className="col-lg-11">
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <div
                                          className="p-3"
                                          style={{ border: "1px solid black" }}
                                        >
                                          {/* <div className="small-banner-img-head">
                                    <img
                                      style={{ width: "100%", height: "200px" }}
                                      // className="w-100 h-auto"
                                      src={
                                        serviceReqParticular?.goldauction_id
                                          ?.cover_image
                                      }
                                      alt=""
                                    />
                                  </div> */}
                                          <div className="mt-3">
                                            <h6
                                              className="color-maroon fw-bold"
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              <b>Type:</b>{" "}
                                              {
                                                serviceReqParticular
                                                  ?.goldauction_id?.goldType
                                              }
                                            </h6>
                                            <p className="small">
                                              {/* <span className="material-symbols-rounded align-middle me-1 filled-icon">
                                        location_on
                                      </span> */}
                                              <b>Carat:</b>{" "}
                                              {
                                                serviceReqParticular
                                                  ?.goldauction_id?.carat
                                              }
                                            </p>
                                            <p className="small">
                                              <b>Gram:</b>{" "}
                                              {
                                                serviceReqParticular
                                                  ?.goldauction_id?.gram
                                              }
                                            </p>
                                            <p className="small">
                                              <b>Price :</b>{" "}
                                              {
                                                serviceReqParticular
                                                  ?.goldauction_id?.bidingPrice
                                              }
                                            </p>
                                            <b>Description :</b>{" "}
                                            <p
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  serviceReqParticular
                                                    ?.goldauction_id
                                                    ?.description,
                                              }}
                                            >
                                              {/* <b>Description :</b>{" "} */}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Name
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            placeholder="Enter the Name"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.name?.status === false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.name
                                            }
                                            disabled
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.name?.message
                                              ? `Name ${serviceReqParticularValidation?.name?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Mobile No
                                          </label>
                                          <input
                                            type="tel"
                                            className="form-control form-control-lg"
                                            maxLength={10}
                                            placeholder="Enter the Mobile number"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.mobileNumber?.status === false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.mobileNumber
                                            }
                                            disabled
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.mobileNumber?.message
                                              ? `Mobile Number ${serviceReqParticularValidation?.mobileNumber?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Email Id
                                          </label>
                                          <input
                                            type="email"
                                            className="form-control form-control-lg"
                                            placeholder="Enter Email-Id"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.email?.status === false
                                            )}
                                            defaultValue={serviceReqParticular?.email?.toLowerCase()}
                                            disabled
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.email?.message
                                              ? `Email Id ${serviceReqParticularValidation?.email?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Message
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            placeholder="Enter Your Question"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.question?.status === false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.question
                                            }
                                            disabled
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.message?.message
                                              ? `Message ${serviceReqParticularValidation?.message?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>

                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Subject
                                          </label>
                                          <textarea
                                            rows={6}
                                            className="form-control form-control-lg"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.subject?.status === false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.subject
                                            }
                                            disabled
                                            autoComplete="off"
                                          ></textarea>
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.subject?.message
                                              ? `Subject ${serviceReqParticularValidation?.subject?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label ">
                                            Assigned To7
                                          </label>
                                          {serviceReqParticular?.status ===
                                            "unassigned" ? (
                                            <select
                                              className="form-control form-control-lg"
                                              defaultValue={
                                                serviceReqParticular?.assigned_to
                                              }
                                              disabled
                                            >
                                              {!serviceReqParticular
                                                ?.assigned_to?.name ? (
                                                <option value="">
                                                  ---Select Member---
                                                </option>
                                              ) : (
                                                ""
                                              )}
                                              {allSubadmin?.map((e) => (
                                                // <option value={e._id}>{e.name}</option>
                                                <option value={e.name}>
                                                  {e.name}
                                                </option>
                                              ))}
                                            </select>
                                          ) : (
                                            <>
                                              {reassign === true ? (
                                                <select
                                                  className="form-control form-control-lg"
                                                  value={
                                                    serviceReqParticular
                                                      ?.assigned_to?.name
                                                  }
                                                  disabled
                                                >
                                                  {!serviceReqParticular
                                                    ?.assigned_to?.name ? (
                                                    <option value="">
                                                      ---Select Member---
                                                    </option>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {allSubadmin?.map((e, i) => (
                                                    // <option value={e._id}>{e.name}</option>
                                                    <option value={e.name}>
                                                      {e.name}
                                                    </option>
                                                  ))}
                                                </select>
                                              ) : (
                                                <select
                                                  className="form-control form-control-lg"
                                                  defaultValue={
                                                    serviceReqParticular
                                                      ?.assigned_to?.name
                                                  }
                                                  disabled
                                                >
                                                  {!serviceReqParticular
                                                    ?.assigned_to?.name ? (
                                                    <option value="">
                                                      ---Select Member---
                                                    </option>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {allSubadmin?.map((e, i) => (
                                                    // <option value={e._id}>{e.name}</option>
                                                    <option value={e.name}>
                                                      {e.name}
                                                    </option>
                                                  ))}
                                                </select>
                                              )}
                                            </>
                                          )}

                                          {serviceReqParticularValidation?.assigned_to ? (
                                            <small className="text-danger">
                                              {serviceReqParticularValidation
                                                ?.assigned_to?.message
                                                ? `Assigned to ${serviceReqParticularValidation?.assigned_to?.message}`
                                                : ""}
                                            </small>
                                          ) : (
                                            ""
                                          )}
                                          {serviceReqParticularValidation
                                            ?.assigned_to?.name ? (
                                            <small className="text-danger">
                                              {serviceReqParticularValidation
                                                ?.assigned_to?.name?.message
                                                ? `Assigned to ${serviceReqParticularValidation?.assigned_to?.name?.message}`
                                                : ""}
                                            </small>
                                          ) : (
                                            ""
                                          )}
                                          {serviceReqParticular?.assigned_to
                                            ?.name ? (
                                            <button
                                              className="btn btn-sm btn-warning mt-2"
                                              onClick={() => {
                                                setReassign(false);
                                              }}
                                            >
                                              Reassign
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-lg-6 my">
                                        <div className="mb-3">
                                          <label
                                            htmlFor="fileUpload"
                                            className="form-label"
                                          >
                                            Select File
                                          </label>

                                          <input
                                            type="file"
                                            className="form-control"
                                            id="file"
                                            ref={CeoRef}
                                            onChange={(e) => {
                                              uploadCeoPic(e);
                                            }}
                                          />
                                        </div>
                                        {serviceReqParticular?.fileUploadTo?.map(
                                          (img, index) => (
                                            <>
                                              {index + 1}.{" "}
                                              <a href={img} target="_blank">
                                                {img}
                                              </a>
                                            </>
                                          )
                                        )}
                                      </div>
                                      {serviceReqParticular?.status ===
                                        "unassigned" ? (
                                        ""
                                      ) : (
                                        <div className="col-lg-6">
                                          <div className="mb-3">
                                            <label
                                              for="services"
                                              className="form-label"
                                            >
                                              Status
                                            </label>
                                            <select
                                              className="form-control"
                                              value={
                                                serviceReqParticular?.status
                                              }
                                              onChange={(e) => {
                                                setServiceReqParticularValue(
                                                  "",
                                                  "status",
                                                  e.target.value,
                                                  ""
                                                );
                                              }}
                                            >
                                              <option value="ToDo">
                                                To Do
                                              </option>
                                              <option value="assigned">
                                                Assigned
                                              </option>
                                              <option value="unassigned">
                                                Unassigned
                                              </option>
                                              <option value="inprogress">
                                                In Progress
                                              </option>
                                              <option value="completed">
                                                Completed
                                              </option>
                                            </select>
                                            <small className="text-danger">
                                              {serviceReqParticularValidation
                                                ?.status?.message
                                                ? `Status ${serviceReqParticularValidation?.status?.message}`
                                                : ""}
                                            </small>
                                          </div>
                                        </div>
                                      )}
                                      <div className="col-lg-12">
                                        <button
                                          type="button"
                                          className="btn login-btn ms-2 "
                                          onClick={(e) => {
                                            handleSubmit(e);
                                          }}
                                        >
                                          Save
                                        </button>
                                      </div>
                                      <>
                                        {/* {serviceReqParticular?.length && state?.auth?.auth?.role==="user" ? (<> */}
                                        {serviceReqParticular?.commentsTo?.map(
                                          (e) => (
                                            <>
                                              <div
                                                className="send-pro-bg mt-4"
                                                style={{
                                                  border: "1px solid black",
                                                  borderRadius: "8px",
                                                  marginBottom: "8px",
                                                }}
                                              >
                                                <div className="d-flex align-items-center justify-content-between">
                                                  <div className="d-flex   align-items-center">
                                                    <div className="flex-shrink-0 ">
                                                      {e?.profileImage ? (
                                                        <img
                                                          className="admin-pro-img "
                                                          style={{
                                                            width: "50px",
                                                            height: "50px",
                                                          }}
                                                          src={e?.profileImage}
                                                          alt="..."
                                                        />
                                                      ) : (
                                                        <span className="material-symbols-rounded filled-icon dash-icon">
                                                          person
                                                        </span>
                                                      )}
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                      <h6 className="fw-bold">
                                                        {e?.name}
                                                      </h6>
                                                      {e?.pageAccess ? (
                                                        <>
                                                          {listEmployee
                                                            ?.filter(
                                                              (sa) =>
                                                                sa?._id ===
                                                                e?.pageAccess
                                                            )
                                                            .map((saa) => {
                                                              return (
                                                                <p
                                                                  style={{
                                                                    textTransform:
                                                                      "capitalize",
                                                                  }}
                                                                  className="mb-0"
                                                                >
                                                                  {saa?.title}
                                                                </p>
                                                              );
                                                            }) ||
                                                            listAdmin
                                                              ?.filter(
                                                                (sa) =>
                                                                  sa?._id ===
                                                                  e?.pageAccess
                                                              )
                                                              .map((saa) => {
                                                                return (
                                                                  <p
                                                                    style={{
                                                                      textTransform:
                                                                        "capitalize",
                                                                    }}
                                                                    className="mb-0"
                                                                  >
                                                                    {saa?.title}
                                                                  </p>
                                                                );
                                                              })}
                                                        </>
                                                      ) : (
                                                        <p
                                                          className="mb-0"
                                                          style={{
                                                            textTransform:
                                                              "capitalize",
                                                          }}
                                                        >
                                                          {e.role}
                                                        </p>
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div>
                                                    <p className="mb-0">
                                                      Asked{" "}
                                                      {moment(
                                                        e?.createdAt
                                                      ).fromNow()}
                                                    </p>
                                                  </div>
                                                </div>
                                                <p className="mb-0 mt-3 pl-send-pro-bg">
                                                  {e.comments}
                                                </p>
                                              </div>
                                            </>
                                          )
                                        )}
                                        {state?.auth?.auth?.role ===
                                          "subadmin" ||
                                          (serviceReqParticular?.commentsTo
                                            ?.length &&
                                            state?.auth?.auth?.role === "user") ||
                                          state?.auth?.auth?.role ===
                                          "superadmin" ||
                                          state?.auth?.auth?.role === "admin" ? (
                                          <>
                                            <div className="input-group flex-nowrap  my-3">
                                              <input
                                                style={{
                                                  border: "1px solid #c9c8c8",
                                                  backgroundColor:
                                                    "transparent",
                                                  borderRight: "0px",
                                                }}
                                                type="text"
                                                className="form-control"
                                                placeholder="Your Message"
                                                aria-invalid={Boolean(
                                                  commentsValidation?.comments
                                                    ?.status === false
                                                )}
                                                defaultValue={
                                                  leadComment?.comments
                                                }
                                                onChange={(e) => {
                                                  setCommentsValue(
                                                    "comments",
                                                    e.target.value
                                                  );
                                                }}
                                                onBlur={(e) => {
                                                  setCommentsLeadValidationValue(
                                                    "comments",

                                                    NonEmptyValidation(
                                                      e.target.value
                                                    )
                                                  );
                                                }}
                                              />
                                              <span
                                                className="input-group-text"
                                                id="addon-wrapping"
                                                style={{
                                                  border: "1px solid #c9c8c8",
                                                  backgroundColor:
                                                    "transparent",
                                                  borderLeft: "0px",
                                                }}
                                              >
                                                <button
                                                  className="btn py-1  login-btn"
                                                  type="button"
                                                  id="button-addon2"
                                                  onClick={(e) => {
                                                    handleSubmitComment(e);
                                                  }}
                                                >
                                                  Send
                                                  <i className="fa-solid fa-paper-plane ms-2"></i>
                                                </button>
                                              </span>
                                            </div>
                                            <small className="text-danger">
                                              {commentsValidation?.comments
                                                ?.message
                                                ? `comments ${commentsValidation?.comments?.message}`
                                                : ""}
                                            </small>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {serviceReqParticular?.twowheeler_id ? (
                          <div>
                            <div className=" my-4">
                              <div className="bg-historytablehead rounded-3 py-3 px-3">
                                <h6 className="mb-0">
                                  More Info
                                </h6>
                              </div>
                              <div className="container">
                                <div className="row justify-content-center py-5">
                                  <div className="col-lg-11">
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <div
                                          className="p-3"
                                          style={{ border: "1px solid black" }}
                                        >
                                          {/* <div className="small-banner-img-head">
                                    <img
                                      style={{ width: "100%", height: "200px" }}
                                      // className="w-100 h-auto"
                                      src={
                                        serviceReqParticular?.goldauction_id
                                          ?.cover_image
                                      }
                                      alt=""
                                    />
                                  </div> */}
                                          <div className="mt-3">
                                            <h6
                                              className="color-maroon fw-bold"
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              <b>Type:</b>{" "}
                                              {
                                                serviceReqParticular
                                                  ?.twowheeler_id?.wheelerType
                                              }
                                            </h6>
                                            <h6
                                              className="color-maroon fw-bold"
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              <b>Reference No:</b>{" "}
                                              {
                                                serviceReqParticular
                                                  ?.twowheeler_id?.referenceNo
                                              }
                                            </h6>
                                            <div className="row">
                                              <div className="col-6">
                                                <p className="small">
                                                  {/* <span className="material-symbols-rounded align-middle me-1 filled-icon">
                                        location_on
                                      </span> */}
                                                  <b>Brand Name:</b>{" "}
                                                  {
                                                    serviceReqParticular
                                                      ?.twowheeler_id?.brandName
                                                  }
                                                </p>
                                              </div>
                                              <div className="col-6">
                                                <p className="small">
                                                  <b>Model Name:</b>{" "}
                                                  {
                                                    serviceReqParticular
                                                      ?.twowheeler_id?.modelName
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                            <div className="row">
                                              <div className="col-6">
                                                <p className="small">
                                                  <b>Model Year :</b>{" "}
                                                  {
                                                    serviceReqParticular
                                                      ?.twowheeler_id?.modelYear
                                                  }
                                                </p>
                                              </div>
                                              <div className="col-6">
                                                <p className="small">
                                                  <b>Color :</b>{" "}
                                                  {
                                                    serviceReqParticular
                                                      ?.twowheeler_id?.color
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                            <div className="row">
                                              <div className="col-6">
                                                <p className="small">
                                                  <b>Price :</b>{" "}
                                                  {
                                                    serviceReqParticular
                                                      ?.twowheeler_id
                                                      ?.bidingPrice
                                                  }
                                                </p>
                                              </div>
                                              <div className="col-6">
                                                <p className="small">
                                                  <b>End Date :</b>{" "}
                                                  {moment(
                                                    serviceReqParticular
                                                      ?.twowheeler_id?.endDate
                                                  ).format("DD/MM/YYYY")}
                                                </p>
                                              </div>
                                            </div>
                                            <b>Description :</b>{" "}
                                            <p
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  serviceReqParticular
                                                    ?.twowheeler_id
                                                    ?.description,
                                              }}
                                            >
                                              {/* <b>Description :</b>{" "} */}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      {/* <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Name
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            placeholder="Enter the Name"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.name?.status === false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.name
                                            }
                                            disabled
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.name?.message
                                              ? `Name ${serviceReqParticularValidation?.name?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Mobile No
                                          </label>
                                          <input
                                            type="tel"
                                            className="form-control form-control-lg"
                                            maxLength={10}
                                            placeholder="Enter the Mobile number"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.mobileNumber?.status === false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.mobileNumber
                                            }
                                            disabled
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.mobileNumber?.message
                                              ? `Mobile Number ${serviceReqParticularValidation?.mobileNumber?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Email Id
                                          </label>
                                          <input
                                            type="email"
                                            className="form-control form-control-lg"
                                            placeholder="Enter Email-Id"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.email?.status === false
                                            )}
                                            defaultValue={serviceReqParticular?.email?.toLowerCase()}
                                            disabled
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.email?.message
                                              ? `Email Id ${serviceReqParticularValidation?.email?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Message
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            placeholder="Enter Your Question"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.question?.status === false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.question
                                            }
                                            disabled
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.message?.message
                                              ? `Message ${serviceReqParticularValidation?.message?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>

                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Subject
                                          </label>
                                          <textarea
                                            rows={6}
                                            className="form-control form-control-lg"
                                            aria-invalid={Boolean(
                                              serviceReqParticularValidation
                                                ?.subject?.status === false
                                            )}
                                            defaultValue={
                                              serviceReqParticular?.subject
                                            }
                                            disabled
                                            autoComplete="off"
                                          ></textarea>
                                          <small className="text-danger">
                                            {serviceReqParticularValidation
                                              ?.subject?.message
                                              ? `Subject ${serviceReqParticularValidation?.subject?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label ">
                                            Assigned To8
                                          </label>
                                          {serviceReqParticular?.status ===
                                            "unassigned" ? (
                                            <select
                                              className="form-control form-control-lg"
                                              defaultValue={
                                                serviceReqParticular?.assigned_to
                                              }
                                              disabled
                                            >
                                              {!serviceReqParticular
                                                ?.assigned_to?.name ? (
                                                <option value="">
                                                  ---Select Member---
                                                </option>
                                              ) : (
                                                ""
                                              )}
                                              {allSubadmin?.map((e) => (
                                                // <option value={e._id}>{e.name}</option>
                                                <option value={e.name}>
                                                  {e.name}
                                                </option>
                                              ))}
                                            </select>
                                          ) : (
                                            <>
                                              {reassign === true ? (
                                                <select
                                                  className="form-control form-control-lg"
                                                  value={
                                                    serviceReqParticular
                                                      ?.assigned_to?.name
                                                  }
                                                  disabled
                                                >
                                                  {!serviceReqParticular
                                                    ?.assigned_to?.name ? (
                                                    <option value="">
                                                      ---Select Member---
                                                    </option>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {allSubadmin?.map((e, i) => (
                                                    // <option value={e._id}>{e.name}</option>
                                                    <option value={e.name}>
                                                      {e.name}
                                                    </option>
                                                  ))}
                                                </select>
                                              ) : (
                                                <select
                                                  className="form-control form-control-lg"
                                                  defaultValue={
                                                    serviceReqParticular
                                                      ?.assigned_to?.name
                                                  }
                                                  disabled
                                                >
                                                  {!serviceReqParticular
                                                    ?.assigned_to?.name ? (
                                                    <option value="">
                                                      ---Select Member---
                                                    </option>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {allSubadmin?.map((e, i) => (
                                                    // <option value={e._id}>{e.name}</option>
                                                    <option value={e.name}>
                                                      {e.name}
                                                    </option>
                                                  ))}
                                                </select>
                                              )}
                                            </>
                                          )}

                                          {serviceReqParticularValidation?.assigned_to ? (
                                            <small className="text-danger">
                                              {serviceReqParticularValidation
                                                ?.assigned_to?.message
                                                ? `Assigned to ${serviceReqParticularValidation?.assigned_to?.message}`
                                                : ""}
                                            </small>
                                          ) : (
                                            ""
                                          )}
                                          {serviceReqParticularValidation
                                            ?.assigned_to?.name ? (
                                            <small className="text-danger">
                                              {serviceReqParticularValidation
                                                ?.assigned_to?.name?.message
                                                ? `Assigned to ${serviceReqParticularValidation?.assigned_to?.name?.message}`
                                                : ""}
                                            </small>
                                          ) : (
                                            ""
                                          )}
                                          {serviceReqParticular?.assigned_to
                                            ?.name ? (
                                            <button
                                              className="btn btn-sm btn-warning mt-2"
                                              onClick={() => {
                                                setReassign(false);
                                              }}
                                            >
                                              Reassign
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-lg-6 my">
                                        <div className="mb-3">
                                          <label
                                            htmlFor="fileUpload"
                                            className="form-label"
                                          >
                                            Select File
                                          </label>

                                          <input
                                            type="file"
                                            className="form-control"
                                            id="file"
                                            ref={CeoRef}
                                            onChange={(e) => {
                                              uploadCeoPic(e);
                                            }}
                                          />
                                        </div>
                                        {serviceReqParticular?.fileUploadTo?.map(
                                          (img, index) => (
                                            <>
                                              {index + 1}.{" "}
                                              <a
                                                href={img}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {img}
                                              </a>
                                            </>
                                          )
                                        )}
                                      </div>
                                      {serviceReqParticular?.status ===
                                        "unassigned" ? (
                                        ""
                                      ) : (
                                        <div className="col-lg-6">
                                          <div className="mb-3">
                                            <label
                                              for="services"
                                              className="form-label"
                                            >
                                              Status
                                            </label>
                                            <select
                                              className="form-control"
                                              value={
                                                serviceReqParticular?.status
                                              }
                                              onChange={(e) => {
                                                setServiceReqParticularValue(
                                                  "",
                                                  "status",
                                                  e.target.value,
                                                  ""
                                                );
                                              }}
                                            >
                                              <option value="ToDo">
                                                To Do
                                              </option>
                                              <option value="assigned">
                                                Assigned
                                              </option>
                                              <option value="unassigned">
                                                Unassigned
                                              </option>
                                              <option value="inprogress">
                                                In Progress
                                              </option>
                                              <option value="completed">
                                                Completed
                                              </option>
                                            </select>
                                            <small className="text-danger">
                                              {serviceReqParticularValidation
                                                ?.status?.message
                                                ? `Status ${serviceReqParticularValidation?.status?.message}`
                                                : ""}
                                            </small>
                                          </div>
                                        </div>
                                      )}
                                      <div className="col-lg-12">
                                        <button
                                          type="button"
                                          className="btn login-btn ms-2 "
                                          onClick={(e) => {
                                            handleSubmit(e);
                                          }}
                                        >
                                          Save
                                        </button>
                                      </div> */}
                                      <>
                                        {/* {serviceReqParticular?.length && state?.auth?.auth?.role==="user" ? (<> */}
                                        {/* {serviceReqParticular?.commentsTo?.map(
                                          (e) => (
                                            <>
                                              <div
                                                className="send-pro-bg mt-4"
                                                style={{
                                                  border: "1px solid black",
                                                  borderRadius: "8px",
                                                  marginBottom: "8px",
                                                }}
                                              >
                                                <div className="d-flex align-items-center justify-content-between">
                                                  <div className="d-flex   align-items-center">
                                                    <div className="flex-shrink-0 ">
                                                      {e?.profileImage ? (
                                                        <img
                                                          className="admin-pro-img "
                                                          style={{
                                                            width: "50px",
                                                            height: "50px",
                                                          }}
                                                          src={e?.profileImage}
                                                          alt="..."
                                                        />
                                                      ) : (
                                                        <span className="material-symbols-rounded filled-icon dash-icon">
                                                          person
                                                        </span>
                                                      )}
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                      <h6 className="fw-bold">
                                                        {e?.name}
                                                      </h6>
                                                      {e?.pageAccess ? (
                                                        <>
                                                          {listEmployee
                                                            ?.filter(
                                                              (sa) =>
                                                                sa?._id ===
                                                                e?.pageAccess
                                                            )
                                                            .map((saa) => {
                                                              return (
                                                                <p
                                                                  style={{
                                                                    textTransform:
                                                                      "capitalize",
                                                                  }}
                                                                  className="mb-0"
                                                                >
                                                                  {saa?.title}
                                                                </p>
                                                              );
                                                            }) ||
                                                            listAdmin
                                                              ?.filter(
                                                                (sa) =>
                                                                  sa?._id ===
                                                                  e?.pageAccess
                                                              )
                                                              .map((saa) => {
                                                                return (
                                                                  <p
                                                                    style={{
                                                                      textTransform:
                                                                        "capitalize",
                                                                    }}
                                                                    className="mb-0"
                                                                  >
                                                                    {saa?.title}
                                                                  </p>
                                                                );
                                                              })}
                                                        </>
                                                      ) : (
                                                        <p
                                                          className="mb-0"
                                                          style={{
                                                            textTransform:
                                                              "capitalize",
                                                          }}
                                                        >
                                                          {e.role}
                                                        </p>
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div>
                                                    <p className="mb-0">
                                                      Asked{" "}
                                                      {moment(
                                                        e?.createdAt
                                                      ).fromNow()}
                                                    </p>
                                                  </div>
                                                </div>
                                                <p className="mb-0 mt-3 pl-send-pro-bg">
                                                  {e.comments}
                                                </p>
                                              </div>
                                            </>
                                          )
                                        )} */}
                                        {/* {state?.auth?.auth?.role ===
                                          "subadmin" ||
                                          (serviceReqParticular?.commentsTo
                                            ?.length &&
                                            state?.auth?.auth?.role === "user") ||
                                          state?.auth?.auth?.role ===
                                          "superadmin" ||
                                          state?.auth?.auth?.role === "admin" ? (
                                          <>
                                            <div className="input-group flex-nowrap  my-3">
                                              <input
                                                style={{
                                                  border: "1px solid #c9c8c8",
                                                  backgroundColor:
                                                    "transparent",
                                                  borderRight: "0px",
                                                }}
                                                type="text"
                                                className="form-control"
                                                placeholder="Your Message"
                                                aria-invalid={Boolean(
                                                  commentsValidation?.comments
                                                    ?.status === false
                                                )}
                                                defaultValue={
                                                  leadComment?.comments
                                                }
                                                onChange={(e) => {
                                                  setCommentsValue(
                                                    "comments",
                                                    e.target.value
                                                  );
                                                }}
                                                onBlur={(e) => {
                                                  setCommentsLeadValidationValue(
                                                    "comments",

                                                    NonEmptyValidation(
                                                      e.target.value
                                                    )
                                                  );
                                                }}
                                              />
                                              <span
                                                className="input-group-text"
                                                id="addon-wrapping"
                                                style={{
                                                  border: "1px solid #c9c8c8",
                                                  backgroundColor:
                                                    "transparent",
                                                  borderLeft: "0px",
                                                }}
                                              >
                                                <button
                                                  className="btn py-1  login-btn"
                                                  type="button"
                                                  id="button-addon2"
                                                  onClick={(e) => {
                                                    handleSubmitComment(e);
                                                  }}
                                                >
                                                  Send
                                                  <i className="fa-solid fa-paper-plane ms-2"></i>
                                                </button>
                                              </span>
                                            </div>
                                            <small className="text-danger">
                                              {commentsValidation?.comments
                                                ?.message
                                                ? `comments ${commentsValidation?.comments?.message}`
                                                : ""}
                                            </small>
                                          </>
                                        ) : (
                                          ""
                                        )} */}
                                      </>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CustomModal
            open={PackageModal}
            onClickOutside={() => {
              setPackageModal(false);
            }}
          >
            <div className="modal-content bg-white  m-auto">
              <div className="modalHeader text-center py-2">
                <h5 className="font-bold mt-2">Pricing Info</h5>
              </div>
              <div className="modal-body p-0 ">
                <div className="container">
                  <div className="row d-flex align-items-center justify-content-center  mt-0">
                    <div className="container ">
                      <div className="row justify-content-center">
                        <div className="col-lg-10">
                          <div className="my-4 px-3 create-package-scroll">
                            <div className="mb-3">
                              <label for="" className="form-label">
                                Mobile Number
                              </label>
                              <input
                                type="number"
                                placeholder="Enter mobile number"
                                className="form-control"
                                maxLength={10}
                                defaultValue={
                                  serviceReqParticular?.mobileNumber
                                }
                                disabled
                                autoComplete="off"
                              />
                            </div>
                            <div className="mb-3">
                              <label for="services" className="form-label">
                                Title
                              </label>

                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Title"
                                defaultValue={serviceReqParticular?.servicesTitle}

                                disabled
                                autoComplete="off"
                              />
                            </div>
                            <div className="mb-3">
                              <label for="" className="form-label">
                                Name
                              </label>
                              <input
                                type="text"
                                placeholder="Enter your name"
                                className="form-control"
                                defaultValue={serviceReqParticular?.name}
                                disabled
                                autoComplete="off"
                              />
                            </div>
                            <div className="mb-3">
                              <label for="" className="form-label">
                                Email
                              </label>
                              <input
                                type="text"
                                placeholder="Enter your email"
                                className="form-control"
                                defaultValue={serviceReqParticular?.email}
                                disabled
                                autoComplete="off"
                              />
                            </div>

                            <div className="mb-3">
                              <label for="" className="form-label">
                                Price
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Price"
                                defaultValue={price}
                                onChange={(e) => {
                                  setPrice(e.target.value);
                                }}
                                autoComplete="off"
                              />
                            </div>

                            <div className="mb-3">
                              <label for="" className="form-label">
                                Description
                              </label>
                              <div
                                className="form-control"
                                style={{
                                  overflowY: "scroll",
                                  height: "400px",
                                }}
                              >
                                <Editor
                                  wrapperClassName="wrapper-class"
                                  editorClassName="editor-class"
                                  toolbarClassName="toolbar-class"
                                  editorState={editorState}
                                  onEditorStateChange={(e) =>
                                    handleEditorChange(e)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-center border-0">
              <div>
                <button
                  type="button"
                  className="btn mx-2 white-btn"
                  onClick={() => {
                    setPackageModal(false);

                    // setInputFieldValidation({})
                  }}
                >
                  {" "}
                  Cancel
                </button>
              </div>
              <div>
                <button
                  type="button"
                  className="btn mx-2  login-btn"
                  onClick={(e) => {
                    handleSubmitCustom(
                      e,
                      serviceReqParticular?.serviceRequestId
                    );
                  }}
                >
                  {" "}
                  Save
                </button>
              </div>
            </div>
          </CustomModal>
        </div>
      </div>
    </div>
  );
}
