import React, { useEffect, useState } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";


import { GetUser,  GetNewsLetter, CreateNewsLetter } from "../../../Api/Api";
import { useSelector } from "react-redux";
import moment from "moment";


import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
 
  NonEmptyValidation,
  
} from "../../../Store/validate";

import Spinner from "../../../Spinner/Spinner";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";

export default function NewsLetter() {
  
  const [selectedNewsletterTab, setSelectedNewletterTab] = React.useState("All");
  const AllTabs = ["All",
  
  ];

  const navigate = useNavigate();
  const [newsLetterTask, setNewsLetterTask] = useState(true);

  const [loading, setLoading] = useState(true);

  const [getNewsLetterTask, setGetNewsLetterTask] = useState([]);

  const state = useSelector((state) => state);

  //getlead
  const GetNewsLetters = async (value) => {
    setLoading(true);
    const token = state.auth.auth.token;
    // const creator_id = state.auth.auth._id;
    let data = await GetNewsLetter(token);
    if (data && data.statusCode === 200) {
      let data1 = data?.data.filter((e) => e.customPackage === value);
      let temp = [];
      data1.map((data, i) => {
        let dummy = {
          _id: data?._id,
          fullName: data?.fullName,
          emailId: data?.emailId,

          createdAt: moment(data?.createdAt).format("DD/MM/YYYY"),
          
        };
        temp.push(dummy);
        return null;
      });
      setGetNewsLetterTask(temp);
      setLoading(false);
    } else {
      setGetNewsLetterTask([]);
      setLoading(false);
      //console.log("Error while getting my task");
    }
  };

  const [profileNews, setProfileNewsDetails] = useState([]);
  async function fetchNewsLetterProfile() {
    setLoading(true);
    const id = state?.auth?.auth?._id;
    const token = state?.auth?.auth?.token;
    const getNewsletterDetails = await GetUser(token, id);
    if (getNewsletterDetails && getNewsletterDetails?.statusCode === 200) {
      setProfileNewsDetails(getNewsletterDetails.data);
      setLoading(false);
    } else {
      setProfileNewsDetails([]);
      setLoading(false);
      //console.log("error while getting user profile info");
    }
  }

  useEffect(() => {
    window.scroll(0, 0);

    GetNewsLetters();
    fetchNewsLetterProfile();
  }, []);

  const [NewsTask, setNewsTask] = useState({});
  const [myTaskNewsValidation, setMyTaskNewsValidation] = useState({});

  const setMyTaskNewsValue = (key, value) => {
    setNewsTask({ ...NewsTask, [key]: value });
    if (myTaskNewsValidation[key]) delete myTaskNewsValidation[key];
  };

  const setMyTaskValidationValue = (key, value) => {
    setMyTaskNewsValidation({ ...myTaskNewsValidation, [key]: value });
  };

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.fullName = NonEmptyValidation(NewsTask?.fullName);
    validate.emailId = NonEmptyValidation(NewsTask?.emailId);

    setMyTaskNewsValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        fullName: NewsTask.fullName,
        emailId: NewsTask.emailId,
        
      };

      const token = state?.auth?.auth?.token;
      const response = await CreateNewsLetter(Payload, token);
      if (response && response.statusCode === 200) {
        setNewsLetterTask(true);
        GetNewsLetters();
        showToastSuccessMsg(response.message);
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } else {
      //console.log("Error while update user details");
    }
  };


  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "fullName",
      label: "Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <div
                className=""
                onClick={(val) => {
                  // navigate(`/NewsLetterEdit/${tableMeta.rowData[0]}`);
                }}
              >
                {value}
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "emailId",
      label: "Email Id",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "createdAt",
      label: "Created Date",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    setRowProps: (row) => {
      if (row[5] === false) {
        return {
          style: { background: "#DBDFEA", cursor: "pointer" },
        };
      } else {
        return {
          style: { background: "white", cursor: "pointer" },
        };
      }
    },
  };

  return (
    <div>
      <ToastContainer />
      <AdminNavbar />
      <div className="main">
        {loading ? (
          <Spinner />
        ) : (
          <>
            {newsLetterTask ? (
              <div>
                <div className="d-flex justify-content-between">
                  <h5 className="mb-0 fw-bold color-maroon">News Letter</h5>
                  <div>
                    {/* <button
                      type="button"
                      className="btn py-1 login-btn"
                      onClick={() => {
                        setNewsLetterTask(false);
                      }}
                    >
                      Create News Letter
                    </button> */}
                  </div>
                </div>
                <div className="my-4">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-7 px-0">
                        <div className="tab-section">
                          <ul className="ps-0">
                            {AllTabs.map((tab, index) => {
                              return (
                                <div
                                  key={index}
                                  className={
                                    selectedNewsletterTab === tab
                                      ? "serviceTab active"
                                      : "serviceTab inactive"
                                  }
                                  onClick={() => {
                                    // if (tab === "Active") {
                                    //   GetNewsLetters("rejected");
                                    // } else if (tab === "Converted") {
                                    //   GetNewsLetters("converted");
                                    // }

                                    setSelectedNewletterTab(tab);
                                  }}
                                >
                                  <li>
                                    <span className="align-middle">{tab}</span>
                                  </li>
                                </div>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <MUIDataTable
                    title={"Dalmaf - News Letter"}
                    data={getNewsLetterTask}
                    columns={columns}
                    options={options}
                  />
                  {/* <table className="table">
                    <thead className="bg-historytablehead">
                      <tr>
                        <th scope="col" style={{ borderTopLeftRadius: "0" }}>
                          S. No
                        </th>
                        <th scope="col">client name</th>
                        <th scope="col">service request</th>
                        <th scope="col">Created Date</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>

                    {getNewsLetterTask?.map((e, index) => (
                      <tbody className="historytablebody">
                        <tr
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigate(`/LeadEdit/${e._id}`);
                          }}
                        >
                         
                          <td>
                            <div className="">
                              <h6 className="fw-bold">{index + 1}</h6>
                            </div>
                          </td>
                          <td scope="row">
                            <div className="d-flex align-items-center ">
                              <div className=" ms-3">
                                <h6 className="fw-bold">{e?.clientName}</h6>
                              </div>
                            </div>
                          </td>

                          <td>
                            <div className=" ms-3">
                              <h6 className="fw-bold">{e?.serviceRequest}</h6>
                            </div>
                          </td>

                          <td>
                            <h6 className="fw-bold">
                              {moment(e.createdAt).format("DD/MM/YYYY")}
                            </h6>
                          </td>

                          <td>
                            <h6 className="fw-bold color-red">{e.status}</h6>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table> */}
                </div>
              </div>
            ) : (
              <div>
                <div className="">
                  <button
                    type="button"
                    className="btn login-btn ms-2 "
                    onClick={() => {
                      setNewsLetterTask(true);
                      setMyTaskNewsValidation({});
                      setNewsTask({});
                    }}
                  >
                    Back
                  </button>
                </div>
                <div className=" my-4">
                  <div className="bg-historytablehead rounded-3 py-3 px-3">
                    <h6 className="mb-0">Create News Letter</h6>
                  </div>
                  <div className="container">
                    <div className="row justify-content-center py-5">
                      <div className="col-lg-11">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label">Full Name</label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter page name"
                                aria-invalid={Boolean(
                                  myTaskNewsValidation?.fullName?.status === false
                                )}
                                defaultValue={NewsTask?.fullName}
                                onChange={(e) => {
                                  setMyTaskNewsValue("fullName", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setMyTaskValidationValue(
                                    "fullName",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {myTaskNewsValidation?.fullName?.message
                                  ? `Full Name ${myTaskNewsValidation?.fullName?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label">Email ID</label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter page name"
                                aria-invalid={Boolean(
                                  myTaskNewsValidation?.emailId?.status === false
                                )}
                                defaultValue={NewsTask?.emailId}
                                onChange={(e) => {
                                  setMyTaskNewsValue("emailId", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setMyTaskValidationValue(
                                    "emailId",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {myTaskNewsValidation?.emailId?.message
                                  ? `Email ID ${myTaskNewsValidation?.emailId?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>

                          <div className="col-lg-12 mt-4">
                            <button
                              type="button"
                              className="btn white-btn me-2 "
                              onClick={() => {
                                setMyTaskNewsValidation({});
                                setNewsTask({});
                                navigate("/NewsLetter");
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="btn login-btn ms-2 "
                              onClick={(e) => {
                                handleSubmit(e);
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
