import React, { useEffect } from 'react';
import Footer from "../Components/Footer/Footer";
import Navigation from "../Components/Navbar/Navigation";
import BSApartmentBanner from "../Components/Buy_Sell/BSApartmentBanner";
import BSApartmentContent from "../Components/Buy_Sell/BSApartmentContent";
export default function BSApartment() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="innerpages-nav">
      <div  id="container" >
      <Navigation />
      <BSApartmentBanner />
      <BSApartmentContent />
      <Footer />
      </div>

      
    </div>
  );
}
