import React, { useEffect } from 'react';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactStars from "react-rating-stars-component";

export default function AboutUs() {


    return (
        <>
        <div class="space" id="about-sec">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-xl-6 mb-30 mb-xl-0">
                        <div class="img-box3">
                            <div class="img1"><img src="img/normal/about_2_1.jpg" alt="About"/></div>
                            <div class="img2"><img src="img/normal/about_2_2.jpg" alt="About"/></div>
                            <div class="img3"><img src="img/normal/about_2_3.jpg" alt="About"/></div>
                            <div class="shape1"><img src="img/normal/about_2_shape.png" alt="About"/></div>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="title-area mb-35">
                            <span class="sub-title">
                                <div class="icon-masking me-2">
                                    <span class="mask-icon" data-mask-src="img/theme-img/title_shape_2.svg"></span> 
                                    <img src="img/theme-img/title_shape_2.svg" alt="shape"/>
                                </div>
                                About Dalmaf
                            </span>
                            <h2 class="sec-title">Preparing Your Business with the Best <span
                                    class="text-theme fw-normal">Audit and Legal Solutions</span></h2>
                        </div>
                        <p class="mt-n2 mb-25">We are committed to offering outstanding customer service with absolute professionalism and integrity at Dalmaf Company.Our team of professionals tackles every project with comprehensive expertise in the sector, decades of experience, and a client-focused mindset. We acknowledge that each organisation has unique demands, so we tailor our services to meet your specific requirements and provide bespoke solutions for guaranteeing your business's achievement.</p>
                        <div class="checklist style3 mb-40">
                            <ul>
                                <li><i class="fa-solid fa-square-check"></i> Dramatically re-engineer value-added legal and audit systems through our mission-driven approach.</li>
                                <li><i class="fa-solid fa-square-check"></i> Expert legal consulting and comprehensive audit services.</li>
                                <li><i class="fa-solid fa-square-check"></i> Professional legal research and in-depth audit analysis.</li>
                            </ul>
                        </div>
                        {/* <a href="about.html" class="th-btn">DISCOVER MORE<i
                                class="fa-regular fa-arrow-right ms-2"></i></a> */}
                    </div>
                </div>
            </div>
            <div class="shape-mockup d-none d-lg-block" data-bottom="0%" data-right="0%">
                <div class="particle-2 small" id="particle-1"></div>
            </div>
        </div>
        </>
    );
}
