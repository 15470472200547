import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'; // Make sure to import the styles for react-tabs
import LeadInfoComponent from './LeadInfoComponent';
import ListLeadContactComponent from './ListLeadContactComponent';
import ListNotesComponent from './ListNotesComponent';
import styles from './css/ViewTaskComponent.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import ViewTaskComponent from './ViewTaskComponent';

export default function ListTaskComponent({showFullNav,routeProps,lableProps,userTypeProps,isClientProps}) {
    const navigate = useNavigate();
    const labels = [
        { label: "To do", value: "To do" },
        { label: "In Progress", value: "Inprogress" },
        { label: "Hold", value: "Hold" },
        { label: "Done", value: "Done" }
    ];
    const { id } = useParams();
    const handleTabSelect = (index) => {
        setSelectedTab(index);
        localStorage.setItem(`selectedTaskTab-${routeProps}`, index);
    }; 

    const [selectedTab, setSelectedTab] = useState(0);

    useEffect(() => {
        const savedTab = localStorage.getItem(`selectedTaskTab-${routeProps}`);
        if (savedTab !== null) {
        setSelectedTab(Number(savedTab));
        }
    }, [routeProps]);

    return (
        <>
            <ToastContainer />
            <div>
                <h3>Task List</h3>
                {
                    routeProps != "task" && <button className="btn btn-primary mb-2" style={{ float: 'right' }} onClick={() => navigate(`/${userTypeProps}/${lableProps}/create-new-task/${id}`)}>
                        Create Task 
                    </button>
                }
                
                <Tabs selectedIndex={selectedTab} onSelect={handleTabSelect}>
                    <TabList>
                        {labels.map((data) => (
                            <Tab key={data.value}>{data.label}</Tab>
                        ))}
                    </TabList>

                    {labels.map((data) => (
                        <TabPanel key={data.value}>
                            <ViewTaskComponent lableProps={lableProps} userTypeProps={userTypeProps} isClientProps={isClientProps} routeProps={routeProps} filterProps={data.value} />
                        </TabPanel>
                    ))}
                </Tabs>
            </div>
        </>
    );
}
