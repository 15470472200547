import React, { useState, useEffect } from 'react';
import './Typewriter.css';

const Typewriter = ({ texts, typingSpeed = 100, erasingSpeed = 50, delay = 1500, onTextChange }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    let timeoutId;

    if (isTyping) {
      if (displayedText.length < texts[currentTextIndex].length) {
        timeoutId = setTimeout(() => {
          setDisplayedText(texts[currentTextIndex].substring(0, displayedText.length + 1));
        }, typingSpeed);
      } else {
        timeoutId = setTimeout(() => {
          setIsTyping(false);
        }, delay);
      }
    } else {
      if (displayedText.length > 0) {
        timeoutId = setTimeout(() => {
          setDisplayedText(texts[currentTextIndex].substring(0, displayedText.length - 1));
        }, erasingSpeed);
      } else {
        setCurrentTextIndex((prev) => (prev + 1) % texts.length);
        setIsTyping(true);
      }
    }

    return () => clearTimeout(timeoutId);
  }, [displayedText, isTyping, texts, typingSpeed, erasingSpeed, delay, currentTextIndex]);

  useEffect(() => {
    onTextChange(texts[currentTextIndex]); // Notify parent component when new text starts
  }, [currentTextIndex, texts, onTextChange]);

  return <span className="typewriter" style={{ color: "#dc3545" }}>{displayedText}<span className="cursor"></span></span>;
};

export default Typewriter;
