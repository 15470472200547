import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { format, startOfToday, endOfToday, startOfWeek, endOfWeek, startOfMonth, endOfMonth, subDays, subMonths } from 'date-fns';
import DatePicker from 'react-datepicker'; // Import DatePicker component
import 'react-datepicker/dist/react-datepicker.css'; // DatePicker CSS
import { fetchWithQueryAPI } from "../../../Api/v1/Util/Api";
import { dashboard_revenue_history_admin_url } from "../../../Api/APIUrl";
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell, LabelList, PieChart, Pie
} from 'recharts';
import { utils, writeFileXLSX } from 'xlsx'; // Import XLSX utilities

const RevenueHistoryDashboard = () => {
  const state = useSelector((state) => state);
  const [revenueData, setRevenueData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [revenueType, setRevenueType] = useState('package');
  const [error, setError] = useState(null);
  const [fromDate, setFromDate] = useState(startOfToday());
  const [toDate, setToDate] = useState(endOfToday());

  const filterOptions = [
    { label: "Today", calculateDates: () => [startOfToday(), endOfToday()] },
    { label: "Yesterday", calculateDates: () => [subDays(startOfToday(), 1), subDays(endOfToday(), 1)] },
    { label: "This Week", calculateDates: () => [startOfWeek(new Date()), endOfWeek(new Date())] },
    { label: "Last Week", calculateDates: () => [startOfWeek(subDays(new Date(), 7)), endOfWeek(subDays(new Date(), 7))] },
    { label: "This Month", calculateDates: () => [startOfMonth(new Date()), endOfMonth(new Date())] },
    { label: "Last Month", calculateDates: () => [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))] },
    { label: "Last 3 Months", calculateDates: () => [startOfMonth(subMonths(new Date(), 3)), endOfMonth(new Date())] },
    { label: "Last 6 Months", calculateDates: () => [startOfMonth(subMonths(new Date(), 6)), endOfMonth(new Date())] },
    { label: "Custom", custom: true }
  ];

  const revenueTypeData = [
    { show: "Package", value: "package" },
    { show: "Custom Package", value: "custom-package" },
    { show: "Service", value: "service" },
    { show: "Auction", value: "auction" },
    { show: "All", value: "all" }
  ];

  const handleFetchHistoryDashboard = async () => {
    if (!fromDate || !toDate) return;

    const formattedFromDate = format(fromDate, 'yyyy-MM-dd');
    const formattedToDate = format(toDate, 'yyyy-MM-dd');

    try {
      const response = await fetchWithQueryAPI(
        state.auth.auth.token,
        `${dashboard_revenue_history_admin_url}&from_date=${formattedFromDate}&to_date=${formattedToDate}&revenue_type=${revenueType}`
      );
      if (response.statusCode === 200) {
        setRevenueData(response.data);
      } else {
        setError(response.message || "Failed to fetch data");
      }
    } catch (error) {
      setError("Error fetching revenue data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetchHistoryDashboard();
  }, [fromDate, toDate, revenueType]);

  const handleFilterChange = (filterOption) => {
    const [from, to] = filterOption.calculateDates();
    setFromDate(from);
    setToDate(to);
  };

  const formatRupee = (value) => `₹${value.toLocaleString('en-IN')}`;
  const formatDate = (date) => format(new Date(date), 'dd-MM-yyyy');

  const exportToExcel = () => {
    const ws = utils.json_to_sheet(revenueData.map(item => ({
      Name: item.name,
      Email: item.email,
      Phone: item.phoneNumber,
      "Revenue Type": revenueTypeData.find(type => type.value === item.revenue_type)?.show || item.revenue_type,
      Title: item.title,
      Price: formatRupee(item.price),
      Date: formatDate(item.createdAt),
      // Status: item.status
    })));

    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Revenue Data");
    writeFileXLSX(wb, "RevenueData.xlsx");
  };

  return (
    <>
      <div className="container mt-4">
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p className="text-danger">{error}</p>
        ) : (
          <>
            <div className="row">
              <div className="col-md-10">
                <table className="table table-striped mt-4">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Revenue Type</th>
                      <th>Package/Auction Title</th>
                      <th>Price</th>
                      <th>Date</th>
                      {/* <th>Status</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {revenueData.map((item) => (
                      <tr key={item._id}>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td>{item.phoneNumber}</td>
                        <td>{revenueTypeData.find(type => type.value === item.revenue_type)?.show || item.revenue_type}</td>
                        <td>{item.title}</td>
                        <td>{formatRupee(item.price)}</td>
                        <td>{formatDate(item.createdAt)}</td>
                        {/* <td>{item.status}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button className="btn btn-primary mt-4" onClick={exportToExcel}>Download Excel</button>
              </div>
              <div className="col-md-2">
                <label>Filter By</label>
                <select
                  className="form-select"
                  onChange={(e) => handleFilterChange(filterOptions[e.target.value])}
                >
                  {filterOptions.map((option, index) => (
                    <option key={index} value={index}>{option.label}</option>
                  ))}
                </select>
                <br />
                <label>Revenue Type</label>
                <select
                  id="revenueType"
                  className="form-select"
                  value={revenueType}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    setRevenueType(selectedValue);
                    handleFetchHistoryDashboard();
                  }}
                >
                  {revenueTypeData.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.show}
                    </option>
                  ))}
                </select>
                <br />
                <label>From Date</label>
                <DatePicker
                  selected={fromDate}
                  onChange={(date) => setFromDate(date)}
                  maxDate={new Date()}
                  className="form-control"
                  dateFormat="dd-MM-yyyy"
                />
                <br /><br />
                <label>To Date</label>
                <DatePicker
                  selected={toDate}
                  onChange={(date) => setToDate(date)}
                  maxDate={new Date()}
                  className="form-control"
                  dateFormat="dd-MM-yyyy"
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default RevenueHistoryDashboard;
