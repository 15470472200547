import React, { useEffect } from 'react';
import Footer from '../Components/Footer/Footer'
import LegalBanner from '../Components/Legal/LegalBanner'
import LegalContent from '../Components/Legal/LegalContent'
import Navigation from '../Components/Navbar/Navigation'

export default function Legal() {
    useEffect(() =>{
        window.scroll(0,0)
      },[])
      
  return (
    <div className="innerpages-nav">
       <div id="container">
      <Navigation />
      <LegalBanner/>
      <LegalContent/>
      <Footer/>
      </div>
    </div>
  )
}
