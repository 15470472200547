import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import UserNavigation from '../../../Components/v1/NavBar/Navigaton';
import {
  GetSpecificProperty,
  auctionCreateLog,
} from "../../../Api/Api";
import Navigation from "../../../Components/Navbar/Navigation";
import Footer from "../../../Components/Footer/Footer";
import ScheduleMeetBanner from "../../../Components/v1/Home/ScheduleMeetBanner";
import Testimonial from "../../../Components/v1/Home/Testimonial";
import AppDownload from "../../../Components/v1/Home/AppDownload";
import FAQ from "../../../Components/v1/Home/FAQ";
import Newsletter from "../../../Components/v1/Home/Newletter";
let htmlToDraft = null;
if (typeof window === "object") {
  htmlToDraft = require("html-to-draftjs").default;
}

const PropertyAuctionDetail = () => {
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    window.scroll(0, 0);
    getSpecificAuctionList();
    createAuctionView();
  }, []);

  const createAuctionView = async () =>{
    const token = state?.auth?.auth?.token;
    const response = await auctionCreateLog(token,id);
  }
  const [property, setProperty] = useState({
    floor_information: [{ floor_no: "", floor_image: "", floor_desc: "" }],
  });

  const getSpecificAuctionList = async () => {
    // const token = state?.auth?.auth?.token;
    const response = await GetSpecificProperty(id);
    if (response && response.statusCode === 200) {
      let dummy = response.data;
      let cmsDescription = EditorState.createEmpty();
      if (dummy.property_desc) {
        let blocksFromHtml = htmlToDraft(dummy.property_desc);
        let { contentBlocks, entityMap } = blocksFromHtml;
        let contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        cmsDescription = EditorState.createWithContent(contentState);
      }
      dummy.property_desc = cmsDescription;
      setProperty(dummy);
    } else {
      setProperty({});
      errorMsg(response.message);
    }
  };
  const [validation, setValidation] = useState({});
  const setData = (e, key) => {
    setProperty({ ...property, [key]: e });
    if (validation[key]) delete validation[key];
  };

  const errorMsg = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  return (
    <>
      {/* <Navigation /> */}
      <UserNavigation />

      <div className="container my-4">
        <div className="d-flex justify-content-end mb-4">
          <button
            type="button"
            className="btn btn-primary btn-lg shadow-sm rounded-pill px-4"
            onClick={(e) => {
              navigate(
                "/Service?tab=Other%20Services&subtab=Auctions%20|%20Property%20Auction&id=6650d90bb06b79232c3c49b2"
              );
            }}
          >
            Back
          </button>
        </div>

        <div className="card shadow-sm border-0 bg-light rounded-3 mb-4 py-3 px-3">
          <h6 className="mb-0 text-dark">Property Details</h6>
        </div>

        <div className="container">
          <div className="row justify-content-center py-4">
            <div className="col-lg-11">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card p-4 shadow-sm">
                    <div className="row mb-3">
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="form-label text-muted">Property Id</label>
                          <div className="form-control bg-white border-0 shadow-sm">
                            {property.property_id}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="form-label text-muted">Property Type</label>
                          <div className="form-control bg-white border-0 shadow-sm">
                            {property.property_type}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Repeated sections for other properties */}
                    {/* ... */}

                    <div className="row my-4">
                      <div className="col-lg-12">
                        <label className="form-label text-muted">Property Description</label>
                        <div className="form-control bg-white border-0 shadow-sm" style={{ maxHeight: "150px", overflowY: "auto" }}>
                          <div dangerouslySetInnerHTML={{ __html: property?.description }}></div>
                          {validation.description && <p className="text-danger">{validation.description}</p>}
                        </div>
                      </div>
                    </div>

                    <div className="row my-4">
                      <div className="col-lg-6">
                        <label className="form-label text-muted">Sale Notice URL</label>
                        <div className="form-control bg-white border-0 shadow-sm">
                          {property.sale_notice_url?.length ? (
                            property.sale_notice_url.map((data) => (
                              <img src={data} alt={property.property_id} className="img-fluid rounded shadow-sm mb-2" />
                            ))
                          ) : (
                            "No data found"
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <label className="form-label text-muted">Property Documents</label>
                        <div className="form-control bg-white border-0 shadow-sm">
                          {property.property_documents_url?.length ? (
                            property.property_documents_url.map((data) => (
                              <img src={data} alt={property.property_id} className="img-fluid rounded shadow-sm mb-2" />
                            ))
                          ) : (
                            "No data found"
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <ScheduleMeetBanner/><br></br><br></br>
      <Testimonial/>
      <AppDownload/>
      <Newsletter/>
      <FAQ/>
      <Footer />
      

    </>
  );
};

export default PropertyAuctionDetail;
