import React, { useEffect, useState } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";

import { useSelector } from "react-redux";
import { GetSpecificNewsLetter, UpdateNewsLetter } from "../../../Api/Api";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import { NonEmptyValidation } from "../../../Store/validate";

export default function NewsLetterEdit() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const { id } = useParams();
  useEffect(() => {
    window.scroll(0, 0);

    GetSpecificTsksLetter();
  }, []);

  const [newsLetterTask, setNewsTask] = useState({});
  const [newsTaskValidation, setNewsTaskValidation] = useState({});

  const setNewsTaskValue = (key, value) => {
    setNewsTask({ ...newsLetterTask, [key]: value });
    if (newsTaskValidation[key]) delete newsTaskValidation[key];
  };

  const setnewsTaskValidationValue = (key, value) => {
    setNewsTaskValidation({ ...newsTaskValidation, [key]: value });
  };

  const GetSpecificTsksLetter = async () => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await GetSpecificNewsLetter(token, id);
    if (data && data.statusCode === 200) {
      setNewsTask(data.data);
      setLoading(false);
    } else {
      setNewsTask([]);
      setLoading(false);
      //console.log("Error while getting my specific task");
    }
  };

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.fullName = NonEmptyValidation(newsLetterTask?.fullName);
    validate.emailId = NonEmptyValidation(newsLetterTask?.emailId);
    setNewsTaskValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        fullName: newsLetterTask.fullName,
        emailId: newsLetterTask.emailId,
        _id: newsLetterTask._id,
      };
      const token = state?.auth?.auth?.token;
      const response = await UpdateNewsLetter(Payload, token);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        window.scrollTo(0, 0);
        GetSpecificTsksLetter();
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } else {
      //console.log("Error while update user details");
    }
  };

  return (
    <div>
      <AdminNavbar />
      <ToastContainer />
      <div className="main">
        <div>
          <div className="">
            <button
              type="button"
              className="btn login-btn ms-2 "
              onClick={(e) => {
                navigate("/NewsLetter");
              }}
            >
              Back
            </button>
          </div>
          <div className=" my-4">
            <div className="bg-historytablehead rounded-3 py-3 px-3">
              <h6 className="mb-0">Update NewsLetter</h6>
            </div>
            <div className="container">
              <div className="row justify-content-center py-5">
                <div className="col-lg-11">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label">Full Name</label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Enter page name"
                          aria-invalid={Boolean(
                            newsTaskValidation?.fullName?.status === false
                          )}
                          defaultValue={newsLetterTask?.fullName}
                          onChange={(e) => {
                            setNewsTaskValue("fullName", e.target.value);
                          }}
                          onBlur={(e) => {
                            setnewsTaskValidationValue(
                              "fullName",
                              NonEmptyValidation(e.target.value)
                            );
                          }}
                          autoComplete="off"
                        />
                        <small className="text-danger">
                          {newsTaskValidation?.fullName?.message
                            ? `Full Name ${newsTaskValidation?.fullName?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label">Email ID</label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Enter page name"
                          aria-invalid={Boolean(
                            newsTaskValidation?.emailId?.status === false
                          )}
                          defaultValue={newsLetterTask?.emailId}
                          onChange={(e) => {
                            setNewsTaskValue("emailId", e.target.value);
                          }}
                          onBlur={(e) => {
                            setnewsTaskValidationValue(
                              "emailId",
                              NonEmptyValidation(e.target.value)
                            );
                          }}
                          autoComplete="off"
                        />
                        <small className="text-danger">
                          {newsTaskValidation?.emailId?.message
                            ? `Email ID ${newsTaskValidation?.emailId?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-12 mt-4">
                      <button
                        type="button"
                        className="btn white-btn me-2 "
                        onClick={() => {
                          setNewsTaskValidation({});
                          setNewsTask({});
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn login-btn ms-2 "
                        onClick={(e) => {
                          handleSubmit(e);
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
