import React, { useEffect } from 'react';
import Footer from "../Components/Footer/Footer";
import Navigation from "../Components/Navbar/Navigation";
import BSHouseBanner from "../Components/Buy_Sell/BSHouseBanner";
import BSHouseSpecific from "../Components/Buy_Sell/BSHouseSpecific";
export default function BSHouseDetails() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="innerpages-nav">
      <div id="container">
      <Navigation />
      <BSHouseBanner />
      <BSHouseSpecific />
      <Footer />
      </div>
     
    </div>
  );
}
