import React, { useEffect, useState } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";

import { useSelector } from "react-redux";
import { GetSpecificJobApplicant } from "../../../Api/Api";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useNavigate, useParams } from "react-router-dom";

import moment from "moment";

export default function JobApplicationEdit() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const { id } = useParams();
  useEffect(() => {
    window.scroll(0, 0);

    GetMyJobSpecificTsks();
  }, [id]);
 
  const [myJobTask, setMyJobTask] = useState({});

  const GetMyJobSpecificTsks = async () => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await GetSpecificJobApplicant(token,id);
    if (data && data.statusCode === 200) {
      setMyJobTask(data.data);
      setLoading(false);
    } else {
      setMyJobTask([]);
      setLoading(false);
      //console.log("Error while getting my specific task");
    }
  };

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  return (
    <div>
      <AdminNavbar />
      <ToastContainer />
      <div className="main">
        <div>
          <div className="">
            <button
              type="button"
              className="btn login-btn ms-2 "
              onClick={(e) => {
                navigate("/JobManagement");
              }}
            >
              Back
            </button>
          </div>
          <div className=" my-4">
            <div className="bg-historytablehead rounded-3 py-3 px-3">
              <h6 className="mb-0">Applicant Details</h6>
            </div>
            <div className="container mt-5">
              <div className="row">
                <div className="col-lg-12 col-sm-12 mb-5">
                  <div
                    style={{
                      maxHeight: "540px",
                      width: "100%",
                      background: "white",
                      overflow: "auto",
                    }}
                  >
                    <div className="bg-resumepdf">
                      <div className="container py-5">
                        <div className="row justify-content-center">
                          <div className="col-lg-10">
                            <div className="row">
                              <div className="col-6">

                              <div className="resumepdf">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th colspan="3" scope="col">
                                      <h1 className="mb-0 text-center fw-bold fs-1">
                                        {myJobTask?.firstName}{" "}
                                        {myJobTask?.lastName}
                                      </h1>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td colspan="3">
                                      <p className="mb-0 text-primary fs-6 fw-bold text-center">
                                        {myJobTask?.jobId?.jobTitle}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colspan="3" className="border-bottom-0">
                                      <p className="mb-0 small fw-bold text-center">
                                        {myJobTask?.phoneNumber} |{" "}
                                        {myJobTask?.emailId} 
                                      </p>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td colspan="3">
                                      <p className="mb-0 text-primary fs-6 fw-bold">
                                        Details
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colspan="3" className="border-bottom-0">
                                      <p className="mb-0 small">
                                        <b>Year Of Experience</b> :{" "}
                                        {myJobTask?.yearOfExperience}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colspan="3" className="border-bottom-0">
                                      <p className="mb-0 small">
                                        <b>Current CTC</b> :{" "}
                                        {myJobTask?.currentCtc}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colspan="3" className="border-bottom-0">
                                      <p className="mb-0 small">
                                        <b>Expected CTC</b> :{" "}
                                        {myJobTask?.expectingCtc}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colspan="3" className="border-bottom-0">
                                      <p className="mb-0 small">
                                        <b>Interested Role</b> :{" "}
                                        {myJobTask?.interestRole}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colspan="3" className="border-bottom-0">
                                      <p className="mb-0 small">
                                        <b>Applied Date</b> :{" "}
                                        {moment(myJobTask?.createdAt).format("DD/MM/YYYY")}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colspan="3" className="border-bottom-0">
                                      <p className="mb-0 small">
                                        <b>Cover Letter</b> :{" "}
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: myJobTask?.coverLetter,
                                          }}
                                        ></p>
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                              </div>
                              <div className="col-6">
                              <iframe
                          style={{ width: "100%", height: "100vh" }}
                          src={myJobTask?.resume}
                          title="title"
                        ></iframe>
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
