import React, { useEffect } from "react";
import Footer from "../Components/Footer/Footer";

import Navigation from "../Components/Navbar/Navigation";
import UserNavigation from '../Components/v1/NavBar/Navigaton';
import {Helmet} from "react-helmet";
export default function PrivacyandPolicy() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="home-nav">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy Policy | DALMaf Private Limited</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="The privacy policy of the Company is being issued in
                      compliance to the Information Technology Act and
                      Applicable Rules. DALMAF.com takes your privacy seriously.
                      We have made our policy as clear as possible so that you
                      can rest assured that you are in good hands. If you have
                      comments, concerns or any questions about this Privacy
                      Policy, please contact us at contact@dalmaf.com" />
        <meta name="keywords" content="The privacy policy of the Company is being issued in
                      compliance to the Information Technology Act and
                      Applicable Rules. DALMAF." />
        <meta name="author" content="DALMaf" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>
      <UserNavigation />
      <div>
        <div className="privacy-policy">
          <div className="container">
            <div className="row justify-content-center pt-3">
              <div className="col-lg-12 py-5">
                <h4 className="fw-bold color-maroon mb-4">PRIVACY POLICY</h4>
                <h6 className="fw-bold ">
                  IF YOU DO NOT AGREE TO TERMS OF OUR PRIVACY POLICY WE KINDLY
                  REQUEST YOU TO NOT USE THIS SITE, OR ASSOCIATED MOBILE APPS.
                </h6>
                <div>
                  <ol>
                    <li>
                      The privacy policy of the Company is being issued in
                      compliance to the Information Technology Act and
                      Applicable Rules. DALMAF.com takes your privacy seriously.
                      We have made our policy as clear as possible so that you
                      can rest assured that you are in good hands. If you have
                      comments, concerns or any questions about this Privacy
                      Policy, please contact us at contact@dalmaf.com
                    </li>
                    <li>
                      Our Website is not directed at children, and we do not
                      seek to collect any information from children including
                      those under the age of 18. If we learn that we have
                      received any information from an individual under the age
                      of 18, we will use that information only to respond
                      directly to that child (or a parent or legal guardian) to
                      inform him or her that he or she cannot use the Services
                      and subsequently we will delete that information from our
                      own servers.
                    </li>
                    <li>
                      If you have comments, concerns or any questions about this
                      Privacy Policy, please contact us at{" "}
                      <a href="mailto:contact@dalmaf.com" target="_blank">
                        contact@dalmaf.com
                      </a>
                    </li>
                    <p>
                      If you do not agree to terms of our Privacy Policy we
                      kindly request you to not use this site. Last Updated on
                      May 29th, 2023
                    </p>
                    <li>
                      We may change, modify, amend, terminate or replace this
                      Privacy Policy at any time at our discretion. If we
                      change, modify, amend or terminate this Privacy Policy,
                      such modifications will become effective immediately upon
                      posting to the Website and/or App. We will notify you of
                      material changes in advance by email or by notice when you
                      log on to the Website or the App.
                    </li>
                    <li>
                      We will not use or share your information with anyone
                      except as described in this Privacy Policy. We use your
                      information for providing and improving the Services. By
                      using the Services, you agree to the collection and use of
                      information in accordance with this Privacy Policy.
                    </li>
                    <li>
                      You agree to periodically review the current version of
                      the Privacy Policy, as posted on the Website or the App.
                      If you do not agree with the terms of this Privacy Policy,
                      please do not use the Services.
                    </li>
                    <li>
                      This Privacy Policy is to be read with, and is an integral
                      part of, our Terms unless otherwise defined in this
                      Privacy Policy, terms used in this Privacy Policy have the
                      same meanings as in our Terms of Use.
                    </li>
                    <li>
                      Our Services require you to make payments on the Platform.
                      When you are making any payments, we collect billing
                      details from you such as your name, name of company,
                      billing address, contact number, payment method and IP
                      address, and then redirect you to a secure payment service
                      provider platform operated by our payments partner(s)
                      Razorpay and other payment partners which may be added
                      time to time. We do not store any payment related
                      information such as credit/debit card details and/or bank
                      details.
                    </li>
                    <li>
                      All data storage of your PII or other information
                      collected from you may be stored and processed on
                      computers located at the cloud servers provided by the
                      Amazon Web Service. Through your use of the Services, you
                      unequivocally consent to the processing and storage of
                      your information.
                    </li>
                    <li>
                      We store some of your information on your device for
                      subsequent retrieval and utilisation. We take commercially
                      reasonable care to encrypt this information on your device
                      with passwords or other secrets supplied by you. Such
                      information stored on your personal device is subject to
                      the security offered by your device, browser and other
                      components. You understand and agree that we cannot be
                      held responsible for any compromise of access to your
                      personal device, for loss of information, theft or for any
                      exploits or logic attacks on your device through malware
                      or other software or hardware that is outside the control
                      of our Services
                    </li>
                    <li>
                      Although the security of your PII and other information is
                      important to us, do remember that no method of electronic
                      storage or method of transmission over the internet is
                      100% secure. While we strive to use commercially
                      acceptable and standard means to protect your information,
                      including internal reviews of our data collection, storage
                      and processing practices and security measures, we cannot
                      guarantee absolute security in this regard. You provide
                      your information and PII to us at your own risk.
                    </li>
                
                  </ol>
                </div>
                <div>
                  <h6 className="fw-bold ">12. GENERAL</h6>
                  <ol style={{ listStyleType: "upper-alpha" }}>
                    <li>
                      This document is an electronic record in terms of
                      Information Technology Act, 2000 and rules there under as
                      applicable and the amended provisions pertaining to
                      electronic records in various statutes as amended by the
                      Information Technology Act, 2000. This electronic record
                      is generated by a computer system and does not require any
                      physical or digital signatures.
                    </li>
                    <li>
                      This document is published in accordance with the
                      provisions of Rule 3 (1) of the Information Technology
                      (Intermediaries guidelines) Rules, 2011 that require
                      publishing the rules and regulations, privacy policy and
                      Terms of Use for access or usage of DALMAF.com
                    </li>
                    <li>
                      The domain name www.DALMAF.com ("Website"), is owned and
                      operated by DALMAF Private Limited (“Company”) a Private
                      Company limited by shares, incorporated under the
                      provisions of the Companies Act, 2013, and having its
                      Registered Office at No.56/44, Thirumalai Pillai Road,
                      Block – A2, T.Nagar, Chennai - 600017, where such
                      expression shall, unless repugnant to the context thereof,
                      be deemed to include its respective representatives,
                      administrators, employees, directors, officers, agents and
                      their successors and assigns.
                    </li>
                    <li>
                      For the purpose of this Privacy Policy (“Policy”),
                      wherever the context so requires,
                    </li>
                    <ol style={{ listStyleType: "upper-roman" }}>
                      <li>
                        The term ‘You’ &‘User’ shall mean any legal person or
                        entity accessing or using the services provided on this
                        Website, who is competent to enter into binding
                        contracts, as per the provisions of the Indian Contract
                        Act, 1872;
                      </li>
                      <li>
                        The terms ‘We’, ‘Us’& ‘Our’ shall mean the Website
                        and/or the Company, as the context so requires.The terms
                        ‘We’, ‘Us’& ‘Our’ shall mean the Website and/or the
                        Company, as the context so requires.The terms ‘We’,
                        ‘Us’& ‘Our’ shall mean the Website and/or the Company,
                        as the context so requires.
                      </li>
                      <li>
                        The terms ‘Party’ & ‘Parties’ shall respectively be used
                        to refer to the User and the Company individually and
                        collectively, as the context so requires.
                      </li>
                    </ol>
                    <li>
                      The headings of each section in this Policy are only for
                      the purpose of organizing the various provisions under
                      this Policy in an orderly manner, and shall not be used by
                      either Party to interpret the provisions contained herein
                      in any manner. Further, it is specifically agreed to by
                      the Parties that the headings shall have no legal or
                      contractual value.
                    </li>
                    <li>
                      The use of the Website by the User is solely governed by
                      this Policy as well as the Terms of Use of the Website
                      (“Terms”, available at www.DALMAF.com), and any
                      modifications or amendments made thereto by the Company
                      from time to time, at its sole discretion. Visiting the
                      home page of the Website and/or using any of the services
                      provided on the Website shall be deemed to signify the
                      User’s unequivocal acceptance of this Policy and the
                      aforementioned Terms, and the User expressly agrees to be
                      bound by the same. The User expressly agrees and
                      acknowledges that the Terms and Policy are co-terminus,
                      and that expiry / termination of either one will lead to
                      the termination of the other.
                    </li>
                    <li>
                      The User unequivocally agrees that this Policy and the
                      aforementioned Terms constitute a legally binding
                      agreement between the User and the Company, and that the
                      User shall be subject to the rules, guidelines, policies,
                      terms, and conditions applicable to any service that is
                      provided by the Website, and that the same shall be deemed
                      to be incorporated into the Terms, and shall be treated as
                      part and parcel of the same. The User acknowledges and
                      agrees that no signature or express act is required to
                      make these Terms and the Policy binding on the User, and
                      that the User’s act of visiting any part of the Website
                      constitutes the User’s full and final acceptance of the
                      Policy and the aforementioned Terms.
                    </li>
                    <li>
                      The Parties expressly agree that the Company retains the
                      sole and exclusive right to amend or modify the Policy and
                      the aforementioned Terms without any prior permission or
                      intimation to the User, and the User expressly agrees that
                      any such amendments or modifications shall come into
                      effect immediately. The User has a duty to periodically
                      check the Policy and Terms, and stay updated on their
                      provisions and requirements. If the User continues to use
                      the Website following such a change, the User will be
                      deemed to have consented to any and all amendments /
                      modifications made to the Policy and Terms. In so far as
                      the User complies with the Policy and Terms, he/she is
                      granted a personal, non-exclusive, non-transferable,
                      revocable, limited privilege to enter access and use the
                      Website.
                    </li>
                  </ol>
                </div>
                <div>
                  <h6 className="fw-bold ">13. COOKIES</h6>
                  <ol style={{ listStyleType: "upper-alpha" }}>
                    <li>
                      The User is aware that a ‘Cookie’ is a small piece of
                      information stored by a web server on a web browser so it
                      can later be traced back from that particular browser, and
                      that cookies are useful for enabling the browser to
                      remember information specific to a given user, including
                      but not limited to a User’s login identification,
                      password, etc. The User is aware that the Website places
                      both permanent and temporary cookies in the User’s
                      computer's hard drive and web browser, and does hereby
                      expressly consent to the same.
                    </li>
                    <li>
                      The User is further aware that the Website uses data
                      collection devices such as cookies on certain pages of the
                      Website to help analyze web page flow, measure promotional
                      effectiveness, and promotes trust and safety, and that
                      certain features of the Website are only available through
                      the use of such cookies. While the User is free to decline
                      the Website’s cookies if the User’s browser permits, the
                      User may consequently be unable to use certain features on
                      the Website
                    </li>
                    <li>
                      Additionally, the User is aware that he/she might
                      encounter ‘cookies’ or other similar devices on certain
                      pages of the Website that are placed by third parties or
                      affiliates of the Company/Website. The User expressly
                      agrees and acknowledges that the Company/Website does not
                      control the use of such cookies/other devices by third
                      parties, that the Company/Website is in no way responsible
                      for the same, and that the User assumes any and all risks
                      in this regard.
                    </li>
                  </ol>
                </div>
                <div>
                  <h6 className="fw-bold ">14. SECURITY</h6>
                  <ol style={{ listStyleType: "upper-alpha" }}>
                    <p>
                      Transactions on the Website are secure and protected. Any
                      information entered by the User when transacting on the
                      Website is encrypted to protect the User against
                      unintentional disclosure to third parties. The User’s
                      credit and debit card information is not received, stored
                      by or retained by the Company / Website in any manner.
                      This information is supplied by the User directly to the
                      relevant payment gateway which is authorized to handle the
                      information provided, and is compliant with the
                      regulations and requirements of various banks and
                      institutions and payment franchisees that it is associated
                      with.
                    </p>
                  </ol>
                </div>
                <div>
                  <h6 className="fw-bold ">
                    15. THIRD PARTY ADVERTISEMENTS / PROMOTIONS
                  </h6>
                  <ol style={{ listStyleType: "upper-alpha" }}>
                    <p>
                      The User is aware that the Company/Website uses
                      third-party advertising companies to serve ads to the
                      users of the Website. The User is aware that these
                      companies may use information relating to the User’s
                      visits to the Website and other websites in order to
                      provide customized advertisements to the User.
                      Furthermore, the Website may contain links to other
                      websites that may collect personally identifiable
                      information about the User. The Company/Website is not
                      responsible for the privacy practices or the content of
                      any of the aforementioned linked websites, and the User
                      expressly acknowledges the same and agrees that any and
                      all risks associated will be borne entirely by the User.
                    </p>
                  </ol>
                </div>
                <div>
                  <h6 className="fw-bold ">16. USER’S CONSENT</h6>
                  <ol style={{ listStyleType: "upper-alpha" }}>
                    <p>
                      By using the Website and/ or by providing information to
                      the Company through the Website, the User consents to the
                      collection and use of the information disclosed by the
                      User on the Website in accordance with this Policy,
                      including but not limited to the User’s consent the
                      Company/Website sharing/divulging the User’s information,
                      as per the terms contained hereinabove in Section 4 of the
                      Policy.
                    </p>
                  </ol>
                </div>
                {/* <div>
                  <h6 className="fw-bold color-maroon">
                    19. GRIEVANCE OFFICER
                  </h6>
                  <ol style={{ listStyleType: "upper-alpha" }}>
                    <p>
                      In accordance with Information Technology Act 2000 and
                      rules made there under, the name and contact details of
                      the Grievance Officer are provided below:
                      Name_______(E-mail: _________@dalmaf.com)
                    </p>
                  </ol>
                </div> */}
                <div>
                  <h6 className="fw-bold ">
                    17. DISPUTE RESOLUTION AND JURISDICTION
                  </h6>

                  <p>
                    It is expressly agreed to by the Parties hereto that the
                    formation, interpretation and performance of this Policy and
                    any disputes arising here from will be resolved through a
                    two-step Alternate Dispute Resolution (“ADR”) mechanism. It
                    is further agreed to by the Parties that the contents of
                    this Section shall survive even after the termination or
                    expiry of the Policy and/or Terms.
                  </p>
                  <ol style={{ listStyleType: "upper-alpha" }}>
                    <li>
                      {" "}
                      Mediation: In case of any dispute between the parties, the
                      Parties will attempt to resolve the same amicably amongst
                      themselves, to the mutual satisfaction of both Parties. In
                      the event that the Parties are unable to reach such an
                      amicable solution within thirty (30) days of one Party
                      communicating the existence of a dispute to the other
                      Party, the dispute will be resolved by arbitration, as
                      detailed herein below.
                    </li>
                    <li>
                      {" "}
                      Arbitration: In the event that the Parties are unable to
                      amicably resolve a dispute by mediation, said dispute will
                      be referred to arbitration by a sole arbitrator to be
                      appointed by the Company, and the award passed by such
                      sole arbitrator will be valid and binding on both Parties.
                      The Parties shall bear their own costs for the
                      proceedings, although the sole arbitrator may, in his/her
                      sole discretion, direct either Party to bear the entire
                      cost of the proceedings. The arbitration shall be
                      conducted in English, and the seat of Arbitration shall be
                      the city of Chennai, in the State of Tamil Nadu, India.
                    </li>
                    <li>
                      The Parties expressly agree that the Terms, Policy and any
                      other agreements entered into between the Parties are
                      governed by the laws, rules and regulations of India, and
                      that the Courts at Chennai, Tamil Nadu, shall have
                      exclusive jurisdiction over any disputes arising between
                      the Parties.
                    </li>
                  </ol>
                </div>
                <div>
                  <h6 className="fw-bold ">
                    18. LINK TO OTHER SITES
                  </h6>
                  <ol style={{ listStyleType: "upper-alpha" }}>
                    <p>
                      Our platform provides link to other Sites. Such Sites may
                      collect user information. We recommend user to review
                      privacy policy of such Site as we are not responsible for
                      their privacy practices.
                    </p>
                  </ol>
                </div>
                <div>
                  <h6 className="fw-bold ">19. GOOGLE ADSENSE</h6>
                  <ol style={{ listStyleType: "upper-alpha" }}>
                    <p>
                      Some of the ads may be served by Google. Google's use of
                      the DART cookie enables it to serve ads to users based on
                      their visit to our Site and other Sites on the Internet.
                      DART uses "non personally identifiable information" and
                      does NOT track personal information about you, such as
                      your name, email address, physical address, etc.
                    </p>
                  </ol>
                </div>
                {/* <div>
                  <h6 className="fw-bold color-maroon">22. GOOGLE ADSENSE</h6>
                  <ol style={{ listStyleType: "upper-alpha" }}>
                    <p>
                      Some of the ads may be served by Google. Google's use of
                      the DART cookie enables it to serve ads to users based on
                      their visit to our Site and other Sites on the Internet.
                      DART uses "non personally identifiable information" and
                      does NOT track personal information about you, such as
                      your name, email address, physical address, etc.
                    </p>
                  </ol>
                </div> */}
                <div>
                  <h6 className="fw-bold ">
                    20. CHANGES TO THIS PRIVACY POLICY
                  </h6>
                  <ol style={{ listStyleType: "upper-alpha" }}>
                    <p>
                      Dalmaf have the discretion to update this privacy policy
                      at any time. When we do, we will send you an email. We
                      encourage users to frequently check this page for any
                      changes to stay informed about how we are helping to
                      protect the personal information collected. You
                      acknowledge and agree that it is your responsibility to
                      review this privacy policy periodically and become aware
                      of modifications. The continued use of our platform and
                      our services will denote your acceptance of our modified
                      policy and will be adequate proof that you have agreed to
                      the terms of this policy which shall apply from the first
                      day of your use of the platform. If changes are
                      significant, we will provide more prominent notice.
                    </p>
                  </ol>
                </div>

                <div>
                  {/* <h6 className="fw-bold color-maroon"> */}
                  <h6 className="fw-bold">
                    21. RETURN POLICY
                  </h6>
                  <ol style={{ listStyleType: "upper-alpha" }}>
                    <p>
                      <b>All the services listed in the DALMAF.com under a particular category may not have a return policy since it’s a service oriented not physical product to be returned.</b>
                    </p>
                  </ol>
                </div>

                
                <h6 className="fw-bold mt-4 ">
                  IF YOU DO NOT AGREE TO TERMS OF OUR PRIVACY POLICY WE KINDLY
                  REQUEST YOU TO NOT USE THIS SITE, OR ASSOCIATED MOBILE APPS.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
