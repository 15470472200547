import React, { useEffect, useState } from 'react';
import './ServicesCount.css'; // Import CSS file for styling
import CountUp from "react-countup";
import { fetchServicesCount } from '../../../Api/v1/service/Api';
import ServiceBody from './ServiceBody';
import { useLocation } from 'react-router-dom';
import { GetContent } from '../../../Api/Api';
import ServiceBanner from './ServiceBanner';
import { useSelector } from 'react-redux';

export default function SpecificServicesComponent() {

    const locationSearch = useLocation();

    const [content, setContent] = useState([]);
    

    

    const fetchServiceContent = async () => {
        let id = localStorage.getItem("level2Id");
        // const token = state.auth.auth.token;
        let data;
        const search = locationSearch.search;
        const _id = new URLSearchParams(search).get("id");
        if (_id.length > 1) {
            data = await GetContent("active", _id);
        } else {
            data = await GetContent("active", id);
        }

        if (data && data.statusCode === 200) {
            setContent(data.data);
        } else {
            setContent([]);
            //console.log("Error while getting my task");
        }
    };

    const state = useSelector((state) => state);


    useEffect(() => {
        window.scroll(0, 0);
        fetchServiceContent();
        return () => { };
    }, [locationSearch]);


    return (
        <div>
            <ServiceBanner contentProps={content} stateProps={state} />
            <ServiceBody contentProps={content} stateProps={state}  />
        </div>
    );
}
