import React, { useState, useRef, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CareerImg from "../../Assets/Images/career-img.webp";
import Norecord from "../../Assets/Images/MicrosoftTeams-image.webp";
import Ser1 from "../../Assets/Images/ser1.webp";
import Ser2 from "../../Assets/Images/ser2.webp";
import Ser3 from "../../Assets/Images/ser3.webp";
import Si1 from "../../Assets/Images/si-1.webp";
import Si2 from "../../Assets/Images/si-2.webp";
import Si3 from "../../Assets/Images/si-3.webp";
import {
  EmailValidation,
  NonEmptyValidation,
  PhoneNumberValidation,
  StringValidation,
  lastNameValidation,
} from "../../Store/validate";
import {
  ApplyJob,
  GetJobs,
  GetMain,
  GetSpecificJob,
  GetUser,
  UploadDocument,
  Usercareer,
} from "../../Api/Api";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw, EditorState, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useSelector } from "react-redux";
import moment from "moment";
import CustomModal from "../CustomModal";
import ServicesAllpages from "../ServicesAllpages";

// It's not clear to me how to trigger updates to the UI
const useForceUpdate = () => useState()[1];
export default function CareerContent() {
  const fileInput = useRef(null);
  const forceUpdate = useForceUpdate();

  useEffect((e) => {
    window.addEventListener("keyup", clickFileInput);
    return () => window.removeEventListener("keyup", clickFileInput);
  });

  function clickFileInput(e) {
    if (fileInput.current.nextSibling.contains(document.activeElement)) {
      // Bind space to trigger clicking of the button when focused
      if (e.keyCode === 32) {
        fileInput.current.click();
      }
    }
  }

  function onSubmit(e) {
    e.preventDefault();
    const data = new FormData(fileInput.current.files);
  }

  function fileNames() {
    const { current } = fileInput;

    if (current && current.files.length > 0) {
      let messages = [];
      for (let file of current.files) {
        messages = messages.concat(
          <p className="mb-0 ms-2" key={file.name}>
            {file.name}
          </p>
        );
      }
      return messages;
    }
    return null;
  }

  useEffect(() => {
    window.scroll(0, 0);

    AOS.init();
    window.scroll(0, 0);
    GetMyTsks();
  }, []);

  const [loginMsg, setLoginMsg] = useState(false);
  const [careerDetail, setCareerDetails] = useState({});

  const [careerDetailValidation, setCareerDetailValidation] = useState({});

  const setCareerDetailValue = (key, value) => {
    setCareerDetails({ ...careerDetail, [key]: value });
    if (careerDetailValidation[key]) delete careerDetailValidation[key];
  };

  const setCareerDetailValidationValue = (key, value) => {
    setCareerDetailValidation({ ...careerDetailValidation, [key]: value });
  };

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleEditorChange = (state) => {
    setEditorState(state);
    let isNotEmpty = state && state.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(convertToRaw(state.getCurrentContent()));
      setApplyValue("coverLetter", html);
      setApplyValidationValue("coverLetter", NonEmptyValidation(html));
    }
  };

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  const [getcareerDetail, setGetcareerDetail] = useState([]);

  const state = useSelector((state) => state);

  const GetMyTsks = async () => {
    // const token = state.auth.auth.token;
    // const creator_id = state.auth.auth._id;
    let data = await GetJobs("active");
    if (data && data.statusCode === 200) {
      setGetcareerDetail(data.data);
    } else {
      //console.log("Error while getting my task");
    }
  };

  const capitalizeWords = (str) => {
    return str
      ?.toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  useEffect(() => {
    if (state?.auth?.isLoggedIn === true) {
      getUserProfile();
    }
    GetMyTsks1();
  }, []);

  const [main, setMain] = useState([]);
  const GetMyTsks1 = async () => {
    //  const token = state.auth.auth.token;
    let data = await GetMain("active");
    if (data && data.statusCode === 200) {
      const numAscending = [...data.data];//.sort((a, b) => b.title < a.title);
      setMain(numAscending);
    } else {
      setMain([]);
      //console.log("Error while getting my task");
    }
  };
  const [userProfile, setUserProfile] = useState([]);
  const getUserProfile = async () => {
    const id = state?.auth?.auth?._id;
    const token = state.auth.auth.token;
    const response = await GetUser(token, id);
    if (response.statusCode === 200) {
      setUserProfile(response.data);
    } else {
      //console.log("error while getting user profile info");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //   if(state?.auth?.isLoggedIn){

    //     const Payload = {
    //       emailId: userProfile.email,
    //       name: userProfile.name,
    //       subject: " ",
    //       question: " ",
    //       mobileNumber: userProfile.phoneNumber,
    //     };
    //     const response = await Usercareer(Payload);
    //     if (response && response.statusCode === 200) {
    //       showToastSuccessMsg(response.message);

    //     } else {
    //       showToastErrorMsg(
    //         response.message || response.error.response.data.message
    //       );
    //     }

    // }else{
    let validate = {};
    validate.name = StringValidation(careerDetail?.name);
    validate.emailId = EmailValidation(careerDetail?.emailId);
    validate.subject = NonEmptyValidation(careerDetail?.subject);
    validate.question = NonEmptyValidation(careerDetail?.question);
    validate.mobileNumber = PhoneNumberValidation(careerDetail?.mobileNumber);
    validate.servicesType = NonEmptyValidation(careerDetail?.servicesType);

    // //console.log(validate, "--195");
    setCareerDetailValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        emailId: careerDetail.emailId,
        name: careerDetail.name,
        subject: careerDetail.subject,
        question: careerDetail.question,
        mobileNumber: careerDetail.mobileNumber,
        servicesType: careerDetail.servicesType,
      };
      const response = await Usercareer(Payload);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        window.location.reload();
        careerDetail({});
        setCareerDetails({});
      } else {
        console.error("error");
      }

      if (response && response.statusCode === 400) {
        showToastErrorMsg(response.error.response.data.message);
      } else {
        //console.log("Error while update user details");
      }
    }
    // }
  };
  const [mycareerDetail, setMyCareerDetail] = useState({});
  const GetMySpecificcareerDetail = async (id) => {
    // const token = state.auth.auth.token;
    let data = await GetSpecificJob(id);
    if (data && data.statusCode === 200) {
      setMyCareerDetail(data.data);
    } else {
      //console.log("Error while getting my specific task");
    }
  };

  const [currentOpenings, setCurrentOpenings] = useState(true);
  const [applyPosition, setApplyPosition] = useState(true);

  const [apply, setApply] = useState({});
  const [applyValidation, setApplyValidation] = useState({});

  const setApplyValue = (key, value) => {
    setApply({ ...apply, [key]: value });
    if (applyValidation[key]) delete applyValidation[key];
  };

  const setApplyValidationValue = (key, value) => {
    setApplyValidation({ ...applyValidation, [key]: value });
  };

  const uploadCeoPic = async (e) => {
    const file = e.target.files[0];
    if (file) {
      let str = e.target.files[0].name;
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append(
        "fileName",
        moment().format("YYYYMMDDHHmmss") + "_" + str
      );
      formData.append("path", "Image/");

      let response = await UploadDocument(formData);
      if (response && response.statusCode === 200) {
        // setPhotoURL(response.data);

        showToastSuccessMsg(response.message);
        setApplyValue("resume", response.data);
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    }
  };

  const handleApply = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.firstName = StringValidation(apply?.firstName);
    validate.lastName = lastNameValidation(apply?.lastName);
    validate.emailId = EmailValidation(apply?.emailId);
    validate.phoneNumber = PhoneNumberValidation(apply?.phoneNumber);
    validate.coverLetter = NonEmptyValidation(apply?.coverLetter);
    // validate.currentCtcANDyouExpecting = NonEmptyValidation(
    //   apply?.currentCtcANDyouExpecting
    // );
    validate.currentCtc = NonEmptyValidation(apply?.currentCtc);
    validate.expectingCtc = NonEmptyValidation(apply?.expectingCtc);

    validate.yearOfExperience = NonEmptyValidation(apply?.yearOfExperience);
    validate.interestRole = NonEmptyValidation(apply?.interestRole);
    validate.resume = NonEmptyValidation(apply?.resume);
    //console.log(validate, "--validate");
    setApplyValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      let Payload = {
        firstName: apply.firstName,
        lastName: apply.lastName,
        emailId: apply.emailId,
        phoneNumber: apply.phoneNumber,
        coverLetter: apply.coverLetter,
        currentCtc: apply.currentCtc,
        expectingCtc: apply.expectingCtc,

        yearOfExperience: apply.yearOfExperience,
        interestRole: apply.interestRole,
        resume: apply.resume,
        jobId: mycareerDetail?._id,
      };

      try {
        const token = state?.auth?.auth?.token;
        const response = await ApplyJob(token, Payload);
        if (response.statusCode === 200) {
          showToastSuccessMsg(response.message);
          setApply({});
          setCurrentOpenings(true);
          window.scrollTo(0, 0);
        } else {
          showToastErrorMsg(
            response.message || response.error?.response?.data?.message
          );
        }
      } catch (error) {
        showToastErrorMsg(error.message);
      }
    } else {
      //console.log("valid error");
    }
  };
  //console.log(apply);
  return (
    <>
      {" "}
      <ToastContainer />
      <div className="overflow-pages">
        {currentOpenings ? (
          <div>
            <div className="about-banner py-5">
              <div className="container">
                <h2 className="banner-heading after-line text-center mb-5">
                  Current Openings
                </h2>
                <div className="row">
                  <div
                    className="col-lg-6 col-md-6 m-auto"
                    data-aos="fade-left"
                  >
                    <div className="about-img">
                      <img className="w-100 h-auto" src={CareerImg} alt="" />
                    </div>
                  </div>
                 
                  <div
                    className="col-lg-6 col-md-6 m-auto"
                    data-aos="fade-right"
                  >
                     {getcareerDetail?.length ? (<>
                    <div className="legalpage-content">
                    
                        {getcareerDetail?.map((e, i) => (
                        <div
                          key={i}
                          className="legaltab-color   border-bottom"
                          onClick={() => {
                            GetMySpecificcareerDetail(e._id);
                            setCurrentOpenings(false);
                          }}
                        >
                          <div>
                            <h5 className=" fw-bold">
                              {/* {capitalizeWords(e.jobTitle)} */}
                              {e.jobTitle}
                            </h5>
                            <p className="mb-0 small ">
                              <span className="material-symbols-rounded filled-icon align-middle">
                                location_on
                              </span>
                              <span className="">
                                {capitalizeWords(e.location)}
                              </span>
                            </p>
                          </div>
                          <div>
                            <span className="material-symbols-rounded align-middle ">
                              arrow_forward_ios
                            </span>
                          </div>
                        </div>
                      ))}
                      
                      
                     
                     

                      {/* <div className="legaltab-color border-bottom ">
                        <div>
                          <h5 className=" fw-bold">Junior Lawyer</h5>
                          <p className="mb-0   small">
                            <span className="material-symbols-rounded filled-icon align-middle">
                              location_on
                            </span>
                            <span className="">Guindy, Chennai</span>
                          </p>
                        </div>
                        <div>
                          <span className="material-symbols-rounded  align-middle">
                            arrow_forward_ios
                          </span>
                        </div>
                      </div>
                      <div className="legaltab-color  border-bottom">
                        <div>
                          <h5 className=" fw-bold">Senior Lawyer</h5>
                          <p className="mb-0   small">
                            <span className="material-symbols-rounded filled-icon align-middle">
                              location_on
                            </span>
                            <span className=" ">Guindy, Chennai</span>
                          </p>
                        </div>
                        <div>
                          <span className="material-symbols-rounded align-middle ">
                            arrow_forward_ios
                          </span>
                        </div>
                      </div>
                      <div className="legaltab-color rounded-bottom  border-bottom ">
                        <div>
                          <h5 className=" fw-bold">Office Assistants</h5>
                          <p className="mb-0  small">
                            <span className="material-symbols-rounded filled-icon align-middle">
                              location_on
                            </span>
                            <span className="  ">Guindy, Chennai</span>
                          </p>
                        </div>
                        <div>
                          <span className="material-symbols-rounded align-middle ">
                            arrow_forward_ios
                          </span>
                        </div>
                      </div> */}
                    </div>
                     </>) : (<b className="job-center">No Jobs Founds</b>)}
                  </div>
                 
                </div>
              </div>
            </div>
            <div className="client-left-section py-5">
              <div className="container my-4">
                <h2 className="banner-heading after-line mb-5">
                  FEEL FREE TO ASK
                </h2>
                <div className="row">
                  <div className="col-lg-6" data-aos="fade-right">
                    <div>
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.823571029211!2d80.23700327559305!3d13.046899587275508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267c25ec3b519%3A0xb10841584ccc73c4!2sDALMAF%20Private%20Limited!5e0!3m2!1sen!2sin!4v1688118977225!5m2!1sen!2sin"
                        width="100%"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12" data-aos="fade-right">
                    {/* {state?.auth?.isLoggedIn ? (<button type="button" className="btn login-btn w-100"
                  onClick={(e)=>{
                    handleSubmit(e)
                  }}
                  >
                  Request Callback
                </button>) : ( */}
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="mb-4">
                          <label className="form-label">
                            Name
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Your Name"
                            aria-invalid={Boolean(
                              careerDetailValidation?.name?.status === false
                            )}
                            defaultValue={careerDetail?.name || ""}
                            onChange={(e) => {
                              setCareerDetailValue("name", e.target.value);
                            }}
                            onBlur={(e) => {
                              setCareerDetailValidationValue(
                                "name",
                                StringValidation(e.target.value)
                              );
                            }}
                            autoComplete="off"
                          />
                          <small className="text-danger">
                            {careerDetailValidation?.name?.message
                              ? `Name ${careerDetailValidation?.name?.message}`
                              : ""}
                          </small>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="mb-4">
                          <label className="form-label">
                            Mobile No <span className="text-danger">*</span>
                          </label>
                          <input
                            type="tel"
                            maxLength={10}
                            className="form-control"
                            placeholder="Enter Your Mobile No"
                            aria-invalid={Boolean(
                              careerDetailValidation?.mobileNumber?.status ===
                                false
                            )}
                            defaultValue={careerDetail?.mobileNumber || ""}
                            onChange={(e) => {
                              setCareerDetailValue(
                                "mobileNumber",
                                e.target.value
                              );
                            }}
                            onBlur={(e) => {
                              setCareerDetailValidationValue(
                                "mobileNumber",
                                PhoneNumberValidation(e.target.value)
                              );
                            }}
                            autoComplete="off"
                          />
                          <small className="text-danger">
                            {careerDetailValidation?.mobileNumber?.message
                              ? `Mobile Number ${careerDetailValidation?.mobileNumber?.message}`
                              : ""}
                          </small>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="mb-4">
                          <label className="form-label">
                            E- Mail ID <span className="text-danger">*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Enter Your E- Mail ID"
                            aria-invalid={Boolean(
                              careerDetailValidation?.emailId?.status === false
                            )}
                            defaultValue={careerDetail?.emailId || ""}
                            onChange={(e) => {
                              setCareerDetailValue("emailId", e.target.value);
                            }}
                            onBlur={(e) => {
                              setCareerDetailValidationValue(
                                "emailId",
                                EmailValidation(e.target.value)
                              );
                            }}
                            autoComplete="off"
                          />
                          <small className="text-danger">
                            {careerDetailValidation?.emailId?.message
                              ? `Email id ${careerDetailValidation?.emailId?.message}`
                              : ""}
                          </small>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="mb-4">
                          <label className="form-label">
                            Subject <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Your Subject "
                            aria-invalid={Boolean(
                              careerDetailValidation?.subject?.status === false
                            )}
                            defaultValue={careerDetail?.subject || ""}
                            onChange={(e) => {
                              setCareerDetailValue("subject", e.target.value);
                            }}
                            onBlur={(e) => {
                              setCareerDetailValidationValue(
                                "subject",
                                NonEmptyValidation(e.target.value)
                              );
                            }}
                            autoComplete="off"
                          />
                          <small className="text-danger">
                            {careerDetailValidation?.subject?.message
                              ? `Subject ${careerDetailValidation?.subject?.message}`
                              : ""}
                          </small>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="mb-4">
                          <label className="form-label">
                            Service Type
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          <select
                            className="form-control form-control-lg"
                            defaultValue={careerDetail?.servicesType || ""}
                            onChange={(e) => {
                              setCareerDetailValue(
                                "servicesType",
                                e.target.value
                              );
                            }}
                            onBlur={(e) => {
                              setCareerDetailValidationValue(
                                "servicesType",
                                NonEmptyValidation(e.target.value)
                              );
                            }}
                          >
                            <option value="">---Select Service Type---</option>
                            <option value="Legal">Legal</option>
                            <option value="Audit">Audit</option>
                            <option value="Manpower">Manpower</option>
                            <option value="Auction">Auction</option>
                            <option value="Buy/Sell">Buy/Sell</option>
                          </select>
                          {/* <small className="text-danger">
                            {careerDetailValidation?.servicesType?.message
                              ? `Service Type ${careerDetailValidation?.servicesType?.message}`
                              : ""}
                          </small> */}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="mb-4">
                          <label className="form-label">
                            Questions <span className="text-danger">*</span>
                          </label>
                          <textarea
                            rows="6"
                            className="form-control"
                            placeholder="Enter Questions"
                            aria-invalid={Boolean(
                              careerDetailValidation?.question?.status === false
                            )}
                            defaultValue={careerDetail?.question || ""}
                            onChange={(e) => {
                              setCareerDetailValue("question", e.target.value);
                            }}
                            onBlur={(e) => {
                              setCareerDetailValidationValue(
                                "question",
                                NonEmptyValidation(e.target.value)
                              );
                            }}
                            autoComplete="off"
                          />
                          <small className="text-danger">
                            {careerDetailValidation?.question?.message
                              ? `Question ${careerDetailValidation?.question?.message}`
                              : ""}
                          </small>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <p className="small">
                          You will be emailed all responses on this email id.
                          Your query and lawyer responses will be published on
                          our website, without your details. We Do not publish
                          your name or contact details.Lawyers who answer your
                          query may contact you to discuss your query in detail.
                        </p>

                        <button
                          type="button"
                          className="btn login-btn w-100"
                          onClick={(e) => {
                            handleSubmit(e);
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                    {/* )} */}
                  </div>
                </div>
              </div>
            </div>
            <div className="number-counter py-5">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-md-6" data-aos="fade-in">
                    <div className="counter-content text-start">
                      <div className="d-flex align-items-center">
                        <div className="me-3">
                          <h1 className="mb-0">
                            <span className="material-symbols-rounded align-middle about-call filled-icon">
                              call
                            </span>
                          </h1>
                        </div>
                        <div className="">
                          <h5 className="fs-6 mb-0">Toll Free</h5>
                          <h5 className="mb-0 fs-4">1800-309-8445</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6" data-aos="fade-in">
                    <div className="counter-content text-start">
                      <div className="d-flex align-items-center">
                        <div className="me-3">
                          <h1 className="mb-0">
                            <span className="material-symbols-rounded align-middle about-call filled-icon">
                              mail
                            </span>
                          </h1>
                        </div>
                        <div className="">
                          <h5 className="fs-6 mb-0">Email ID</h5>
                          <h5 className="mb-0 fs-4">info@dalmaf.com</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6" data-aos="fade-in">
                    <div className="counter-content text-start">
                      <div className="d-flex align-items-center">
                        <div className="me-3">
                          <h1 className="mb-0">
                            <i className="fa-brands fa-whatsapp about-whatsapp-icon"></i>
                          </h1>
                        </div>
                        <div className="">
                          <h5 className="fs-6 mb-0">WhatsApp Us</h5>
                          <h5 className="mb-0 fs-4">+91 96000 38059</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <ServicesAllpages />
            </div>
          </div>
        ) : (
          <div>
            <div className="about-banner2 my-5 py-5">
              <div className="container">
                <h2
                  className="banner-heading after-line text-center mb-5"
                  data-aos="fade-in"
                >
                  Job Position
                </h2>
                <div className="row">
                  <div className="col-lg-10 m-auto">
                    {applyPosition ? (
                      <div
                        className="jobposition-section"
                        data-aos="fade-right"
                      >
                        <div className="bg-des-content py-3">
                          <h3 className=" fw-bold ">
                          {mycareerDetail?.jobTitle}
                            {/* {mycareerDetail?.jobTitle
                              ?.toLowerCase()
                              ?.split(" ")
                              ?.map(
                                (word) =>
                                  word?.charAt(0)?.toUpperCase() +
                                  word?.slice(1)
                              )
                              ?.join(" ")} */}
                          </h3>
                          <p className="mb-0 small ">
                            <span className="material-symbols-rounded fw-bold  align-middle">
                              location_on
                            </span>
                            <span className="fw-bold">
                              {mycareerDetail?.location
                                ?.toLowerCase()
                                ?.split(" ")
                                ?.map(
                                  (word) =>
                                    word?.charAt(0)?.toUpperCase() +
                                    word?.slice(1)
                                )
                                ?.join(" ")}
                            </span>
                          </p>
                        </div>
                        <div className="bg-description">
                          <h5 className="py-3 fw-bold color-maroon">
                            DESCRIPTION
                          </h5>
                        </div>
                        <div className="bg-des-content py-3">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: mycareerDetail?.description,
                            }}
                          ></p>
                        </div>
                        <div className="bg-description">
                          <h5 className="py-3 fw-bold color-maroon">
                            REQUIREMENTS
                          </h5>
                        </div>
                        <div className="bg-des-content py-3">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: mycareerDetail?.requirement,
                            }}
                          ></p>
                        </div>
                        <div className="d-flex justify-content-between py-3 bg-description">
                          <button
                            type="button"
                            className="btn white-btn"
                            onClick={() => setCurrentOpenings(true)}
                          >
                            Back
                          </button>
                          <button
                            type="button"
                            className="btn login-btn"
                            // onClick={() => setApplyPosition(false)}

                            onClick={() => {
                              if (state?.auth?.isLoggedIn === true) {
                                setApplyPosition(false);
                                window.scrollTo(0, 0);
                              } else {
                                setLoginMsg(true);
                              }
                            }}
                          >
                            Apply For this Job
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="jobposition-section" data-aos="fade-left">
                        <div className="bg-des-content py-3">
                          <h3 className=" fw-bold ">
                          {mycareerDetail?.jobTitle}
                            {/* {mycareerDetail?.jobTitle
                              ?.toLowerCase()
                              ?.split(" ")
                              ?.map(
                                (word) =>
                                  word?.charAt(0)?.toUpperCase() +
                                  word?.slice(1)
                              )
                              ?.join(" ")} */}
                          </h3>
                          <p className="mb-0 small ">
                            <span className="material-symbols-rounded fw-bold  align-middle">
                              location_on
                            </span>
                            <span className="fw-bold">
                              {mycareerDetail?.location
                                ?.toLowerCase()
                                ?.split(" ")
                                ?.map(
                                  (word) =>
                                    word?.charAt(0)?.toUpperCase() +
                                    word?.slice(1)
                                )
                                ?.join(" ")}
                            </span>
                          </p>
                        </div>
                        <div className="bg-description">
                          <h5 className="py-3 fw-bold color-maroon">
                            PERSONAL
                          </h5>
                        </div>
                        <div className="bg-des-content py-3">
                          <div className="container">
                            <div className="row justify-content-between">
                              <div className="col-lg-5 col-md-6">
                                <div className="mb-4">
                                  <label className="form-label">
                                    First Name{" "}
                                    <span className="text-danger">*</span>{" "}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter first name"
                                    aria-invalid={Boolean(
                                      applyValidation?.name?.status === false
                                    )}
                                    value={apply?.firstName}
                                    onChange={(e) => {
                                      setApplyValue(
                                        "firstName",
                                        e.target.value
                                      );
                                    }}
                                    onBlur={(e) => {
                                      setApplyValidationValue(
                                        "firstName",
                                        StringValidation(e.target.value)
                                      );
                                    }}
                                    autoComplete="off"
                                  />
                                  <small className="text-danger">
                                    {applyValidation?.firstName?.message
                                      ? `First Name ${applyValidation?.firstName?.message}`
                                      : ""}
                                  </small>
                                </div>
                                <div className="mb-4">
                                  <label className="form-label">
                                    E-mail Address{" "}
                                    <span className="text-danger">*</span>{" "}
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter email id"
                                    aria-invalid={Boolean(
                                      applyValidation?.emailId?.status === false
                                    )}
                                    value={apply?.emailId}
                                    onChange={(e) => {
                                      setApplyValue("emailId", e.target.value);
                                    }}
                                    onBlur={(e) => {
                                      setApplyValidationValue(
                                        "emailId",
                                        EmailValidation(e.target.value)
                                      );
                                    }}
                                    autoComplete="off"
                                  />
                                  <small className="text-danger">
                                    {applyValidation?.emailId?.message
                                      ? `Email id ${applyValidation?.emailId?.message}`
                                      : ""}
                                  </small>
                                </div>
                              </div>
                              <div className="col-lg-5 col-md-6">
                                <div className="mb-4">
                                  <label className="form-label">
                                    Last Name{" "}
                                    <span className="text-danger">*</span>{" "}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter last name"
                                    aria-invalid={Boolean(
                                      applyValidation?.lastName?.status ===
                                        false
                                    )}
                                    value={apply?.lastName}
                                    onChange={(e) => {
                                      setApplyValue("lastName", e.target.value);
                                    }}
                                    onBlur={(e) => {
                                      setApplyValidationValue(
                                        "lastName",
                                        lastNameValidation(e.target.value)
                                      );
                                    }}
                                    autoComplete="off"
                                  />
                                  <small className="text-danger">
                                    {applyValidation?.lastName?.message
                                      ? `Last name ${applyValidation?.lastName?.message}`
                                      : ""}
                                  </small>
                                </div>
                                <div className="mb-4">
                                  <label className="form-label">
                                    Phone Number{" "}
                                    <span className="text-danger">*</span>{" "}
                                  </label>
                                  <input
                                    type="tel"
                                    maxLength={10}
                                    className="form-control"
                                    placeholder="Enter Your Mobile No"
                                    aria-invalid={Boolean(
                                      applyValidation?.phoneNumber?.status ===
                                        false
                                    )}
                                    value={apply?.phoneNumber}
                                    onChange={(e) => {
                                      setApplyValue(
                                        "phoneNumber",
                                        e.target.value
                                      );
                                    }}
                                    onBlur={(e) => {
                                      setApplyValidationValue(
                                        "phoneNumber",
                                        PhoneNumberValidation(e.target.value)
                                      );
                                    }}
                                    autoComplete="off"
                                  />
                                  <small className="text-danger">
                                    {applyValidation?.phoneNumber?.message
                                      ? `Mobile Number ${applyValidation?.phoneNumber?.message}`
                                      : ""}
                                  </small>
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="mb-4">
                                  <label className="form-label">
                                    Cover Letter{" "}
                                    <span className="text-danger">*</span>{" "}
                                  </label>
                                  <div
                                    className="form-control form-control-lg"
                                    style={{ height: "auto" }}
                                  >
                                    <Editor
                                      wrapperClassName="wrapper-class"
                                      editorClassName="editor-class"
                                      toolbarClassName="toolbar-class"
                                      editorState={editorState}
                                      onEditorStateChange={(e) =>
                                        handleEditorChange(e)
                                      }
                                    />
                                  </div>
                                  {/* <textarea
                                    className="form-control"
                                    row="8"
                                    placeholder="Describe cover letter"
                                    aria-invalid={Boolean(
                                      applyValidation?.coverLetter?.status ===
                                        false
                                    )}
                                    value={apply?.coverLetter}
                                    onChange={(e) => {
                                      setApplyValue(
                                        "coverLetter",
                                        e.target.value
                                      );
                                    }}
                                    onBlur={(e) => {
                                      setApplyValidationValue(
                                        "coverLetter",
                                        NonEmptyValidation(e.target.value)
                                      );
                                    }}
                                    autoComplete="off"
                                  /> */}
                                  <small className="text-danger">
                                    {applyValidation?.coverLetter?.message
                                      ? `Cover letter ${applyValidation?.coverLetter?.message}`
                                      : ""}
                                  </small>
                                </div>
                                <div className="mb-4">
                                  <label className="form-label">
                                    Resume{" "}
                                    <span className="text-danger">*</span>{" "}
                                  </label>
                                  <div className="custom-btn d-flex align-items-center">
                                    <input
                                      id="file"
                                      type="file"
                                      className="form-control"
                                      ref={fileInput}
                                      onChange={(e) => {
                                        uploadCeoPic(e);
                                      }}
                                      autoComplete="off"
                                    />
                                    <small className="text-danger">
                                      {applyValidation?.resume?.message
                                        ? `Resume ${applyValidation?.resume?.message}`
                                        : ""}
                                    </small>
                                    <label htmlFor="file">
                                      <span
                                        tabIndex="0"
                                        role="button"
                                        className="form-control"
                                        aria-controls="filename"
                                      >
                                        Choose File{" "}
                                      </span>
                                    </label>
                                    {fileNames()}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="bg-description">
                          <h5 className="py-3 fw-bold color-maroon">
                            QUESTIONS
                          </h5>
                        </div>
                        <div className="bg-des-content py-3">
                          <div className="container">
                            <div className="row justify-content-between">
                              <div className="col-lg-5 col-md-12">
                                <div className="mb-4">
                                  <label className="form-label">
                                    Interested Role
                                    <span className="text-danger">*</span>{" "}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter role"
                                    aria-invalid={Boolean(
                                      applyValidation?.interestRole?.status ===
                                        false
                                    )}
                                    value={apply?.interestRole}
                                    onChange={(e) => {
                                      setApplyValue(
                                        "interestRole",
                                        e.target.value
                                      );
                                    }}
                                    onBlur={(e) => {
                                      setApplyValidationValue(
                                        "interestRole",
                                        NonEmptyValidation(e.target.value)
                                      );
                                    }}
                                    autoComplete="off"
                                  />
                                  <small className="text-danger">
                                    {applyValidation?.interestRole?.message
                                      ? `Role ${applyValidation?.interestRole?.message}`
                                      : ""}
                                  </small>
                                </div>
                                <div className="mb-4">
                                  <label className="form-label">
                                    Current CTC ?
                                    <span className="text-danger">*</span>{" "}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter current CTC "
                                    aria-invalid={Boolean(
                                      applyValidation?.currentCtc?.status ===
                                        false
                                    )}
                                    value={apply?.currentCtc}
                                    onChange={(e) => {
                                      setApplyValue(
                                        "currentCtc",
                                        e.target.value
                                      );
                                    }}
                                    onBlur={(e) => {
                                      setApplyValidationValue(
                                        "currentCtc",
                                        NonEmptyValidation(e.target.value)
                                      );
                                    }}
                                    autoComplete="off"
                                  />
                                  <small className="text-danger">
                                    {applyValidation?.currentCtc?.message
                                      ? `CTC ${applyValidation?.currentCtc?.message}`
                                      : ""}
                                  </small>
                                </div>
                              </div>

                              <div className="col-lg-5 col-md-12">
                                <div className="mb-4">
                                  <label className="form-label">
                                    Year Of Experience
                                    <span className="text-danger">*</span>{" "}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter your experience"
                                    aria-invalid={Boolean(
                                      applyValidation?.yearOfExperience
                                        ?.status === false
                                    )}
                                    value={apply?.yearOfExperience}
                                    onChange={(e) => {
                                      setApplyValue(
                                        "yearOfExperience",
                                        e.target.value
                                      );
                                    }}
                                    onBlur={(e) => {
                                      setApplyValidationValue(
                                        "yearOfExperience",
                                        NonEmptyValidation(e.target.value)
                                      );
                                    }}
                                    autoComplete="off"
                                  />
                                  <small className="text-danger">
                                    {applyValidation?.yearOfExperience?.message
                                      ? `Experience ${applyValidation?.yearOfExperience?.message}`
                                      : ""}
                                  </small>
                                </div>
                                <div className="mb-4">
                                  <label className="form-label">
                                    Expecting CTC ?
                                    <span className="text-danger">*</span>{" "}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter expecting ctc"
                                    aria-invalid={Boolean(
                                      applyValidation?.expectingCtc?.status ===
                                        false
                                    )}
                                    value={apply?.expectingCtc}
                                    onChange={(e) => {
                                      setApplyValue(
                                        "expectingCtc",
                                        e.target.value
                                      );
                                    }}
                                    onBlur={(e) => {
                                      setApplyValidationValue(
                                        "expectingCtc",
                                        NonEmptyValidation(e.target.value)
                                      );
                                    }}
                                    autoComplete="off"
                                  />
                                  <small className="text-danger">
                                    {applyValidation?.expectingCtc?.message
                                      ? `CTC ${applyValidation?.expectingCtc?.message}`
                                      : ""}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between py-3 bg-description">
                          <button
                            type="button"
                            className="btn white-btn"
                            onClick={() => setApplyPosition(true)}
                          >
                            Back
                          </button>
                          <button
                            type="button"
                            className="btn login-btn"
                            onClick={(e) => handleApply(e)}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <CustomModal
          open={loginMsg}
          onClickOutside={() => {
            setLoginMsg(false);
          }}
        >
          <div className="modal-content w-50 m-auto" style={{minHeight:"100px"}}>
            <div className="modal-body p-0 ">
              <div className="container ">
                <span
                  className="material-symbols-rounded filled-icon close-icon"
                  style={{
                    marginRight: "30px",
                    marginTop: "30px",
                    textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                  }}
                  onClick={() => {
                    setLoginMsg(false);
                  }}
                >
                  close
                </span>
                <div className="row d-flex align-items-center justify-content-center  my-5">
                  <div className="container ">
                    <div className="row justify-content-center">
                      <div className="col-lg-12">
                      <h4 className="text-center">
                                  Please login to apply this job
                                </h4>
                                </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CustomModal>
      </div>
    </>
  );
}
