import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { ToastContainer, toast } from "react-toastify";
import Typography from "@mui/material/Typography";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { fetchWithQueryAPI } from "../../../Api/v1/Util/Api";
import { schemes_request_find_all_url } from "../../../Api/APIUrl";
import UserListComponent from "./UserListComponent";
import AdminSideListComponent from "./AdminSideListComponent";
import EmpSideListComponent from "./EmpSideListComponent";

// Modal styles
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: '900px',
  height: '90vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '10px',
  overflow: 'auto',
  p: 4,
};

export default function SchemesRequestListComponent({ statusTypeProps, labelProps }) {
  const [data, setData] = useState([]);
  const state = useSelector((state) => state);
  
  const token = state.auth.auth.token;
  const fetchData = async () => {
    const response = await fetchWithQueryAPI(token, schemes_request_find_all_url + "?status=" + statusTypeProps);
    if (response && response.statusCode === 200) {
      if (response.data) {
        setData(response.data.scheme_request);
      }
    }
  };

  useEffect(() => {
    setData([]);
    fetchData();
  }, [statusTypeProps]); // Add statusTypeProps as dependency for fetching new data when status changes
  

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
          styleOverrides: {
            head: {
              whiteSpace: 'nowrap',
              textAlign: 'center',
              fontWeight: 'bold',
              backgroundColor: '#f5f5f5',
              fontSize: '14px',
            },
            body: {
              textAlign: 'center',
              fontSize: '14px',
            },
          },
        },
      },
    });

  return (
    <div>
      <div style={{ padding: "20px" }}>
        <ThemeProvider theme={getMuiTheme()}>

        {
          state.auth.auth.role === "user"?
            <UserListComponent statusTypeProps={statusTypeProps} labelProps={labelProps}/>
        : (state.auth.auth.role === "superadmin" || state.auth.auth.role === "admin") ?
            <AdminSideListComponent statusTypeProps={statusTypeProps} labelProps={labelProps} /> :<EmpSideListComponent statusTypeProps={statusTypeProps} labelProps={labelProps} /> 
        }

        </ThemeProvider>
        <ToastContainer />
      </div>
    </div>
  );
}
