import React from 'react'
import Ser1 from "../../Assets/Images/ser1.webp";
import Ser2 from "../../Assets/Images/ser2.webp";
import Ser3 from "../../Assets/Images/ser3.webp";
import Si1 from "../../Assets/Images/si-1.webp";
import Si2 from "../../Assets/Images/si-2.webp";
import Si3 from "../../Assets/Images/si-3.webp";
export default function ServicesContent() {
  return (
    <div className='overflow-pages'>
      
      <div className="service-banner py-5">
        <div className="container">
          <h2 className="banner-heading  after-line mb-5">
            Similar Services
          </h2>
          <div className="row">
            <div className="col-lg-4 col-md-6 ps-rel m-auto"  data-aos="zoom-in">
              <div className="about-img">
                <img className="w-100 h-auto rounded-top" src={Ser1} alt="" />
              </div>
              <div className="ser-section">
                <div className="d-flex mb-3 align-items-center">
                  <img className="si-icon" src={Si1} alt="" />
                  <h5 className="ms-2 banner-heading ">Manpower</h5>
                </div>
                <p className="mb-0">
                  Free Legal Services/Advice Online, Expert Legal Advice with
                  100% Confidentiality. Give us a call and make us your & your
                  family's lawyer for all legal issues.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 ps-rel m-auto"  data-aos="zoom-in">
              <div className="about-img">
                <img className="w-100 h-auto rounded-top" src={Ser2} alt="" />
              </div>
              <div className="ser-section">
                <div className="d-flex mb-3 align-items-center">
                  <img className="si-icon" src={Si2} alt="" />
                  <h5 className="ms-2 banner-heading ">Auctions</h5>
                </div>
                <p className="mb-0">
                  Free Legal Services/Advice Online, Expert Legal Advice with
                  100% Confidentiality. Give us a call and make us your & your
                  family's lawyer for all legal issues.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 ps-rel m-auto"  data-aos="zoom-in">
              <div className="about-img">
                <img className="w-100 h-auto rounded-top" src={Ser3} alt="" />
              </div>
              <div className="ser-section">
                <div className="d-flex mb-3 align-items-center">
                  <img className="si-icon" src={Si3} alt="" />
                  <h5 className="ms-2 banner-heading ">Legal Services</h5>
                </div>
                <p className="mb-0">
                  Free Legal Services/Advice Online, Expert Legal Advice with
                  100% Confidentiality. Give us a call and make us your & your
                  family's lawyer for all legal issues.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 ps-rel m-auto"  data-aos="zoom-in">
              <div className="about-img">
                <img className="w-100 h-auto rounded-top" src={Ser1} alt="" />
              </div>
              <div className="ser-section">
                <div className="d-flex mb-3 align-items-center">
                  <img className="si-icon" src={Si1} alt="" />
                  <h5 className="ms-2 banner-heading ">Manpower</h5>
                </div>
                <p className="mb-0">
                  Free Legal Services/Advice Online, Expert Legal Advice with
                  100% Confidentiality. Give us a call and make us your & your
                  family's lawyer for all legal issues.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 ps-rel m-auto"  data-aos="zoom-in">
              <div className="about-img">
                <img className="w-100 h-auto rounded-top" src={Ser2} alt="" />
              </div>
              <div className="ser-section">
                <div className="d-flex mb-3 align-items-center">
                  <img className="si-icon" src={Si2} alt="" />
                  <h5 className="ms-2 banner-heading ">Auctions</h5>
                </div>
                <p className="mb-0">
                  Free Legal Services/Advice Online, Expert Legal Advice with
                  100% Confidentiality. Give us a call and make us your & your
                  family's lawyer for all legal issues.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 ps-rel m-auto"  data-aos="zoom-in">
              <div className="about-img">
                <img className="w-100 h-auto rounded-top" src={Ser3} alt="" />
              </div>
              <div className="ser-section">
                <div className="d-flex mb-3 align-items-center">
                  <img className="si-icon" src={Si3} alt="" />
                  <h5 className="ms-2 banner-heading ">Legal Services</h5>
                </div>
                <p className="mb-0">
                  Free Legal Services/Advice Online, Expert Legal Advice with
                  100% Confidentiality. Give us a call and make us your & your
                  family's lawyer for all legal issues.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
