import { useEffect, useState } from "react";
import ListLeadComponent from "../../../../Components/v1/FreelancerPartner/Leads/ListLeadComponent";
import FreelancerPartnerNav from "../../../../Components/v1/NavBar/FreelancerPartnerNav";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'; // Ensure you have the CSS for react-tabs
import { useNavigate } from "react-router-dom";
import styles from '../css/List.module.css';
import LeadClientDashboard from "../../../../Components/v1/FreelancerPartner/Leads/LeadClientDashboard";

export default function ListLeads({ userTypeProps, lableProps, isClientProps, routeProps }) {
  const navigate = useNavigate();
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [show, setShow] = useState(true);
  const handleToggle = () => { setShow(!show); };

  const leadStatus = [
    { label: "Dashboard", value: "Dashboard" },
    { label: "New", value: "New" },
    { label: "Qualified", value: "Qualified" },
    { label: "Discussion", value: "Discussion" },
    { label: "Negotiation", value: "Negotiation" },
    { label: "Converted", value: "Converted" },
    { label: "Not Converted", value: "NotConverted" }
  ];

  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    const savedTab = localStorage.getItem(`selectedTab-${routeProps}`);
    if (savedTab !== null) {
      setSelectedTab(Number(savedTab));
    }
  }, [routeProps]);

  const handleTabSelect = (index) => {
    setSelectedTab(index);
    localStorage.setItem(`selectedTab-${routeProps}`, index);
  };

  return (
    <>
      <FreelancerPartnerNav showFullNav={show} onToggle={handleToggle} userTypeProps={userTypeProps} routeProps={routeProps} />
      <div className={show ? styles.container : styles.containerSmallNav}>
        {!isClientProps && (
          <div className="row">
            <div className="col-md-12">
              <button
                className="btn btn-primary mb-2"
                onClick={() => navigate(`/${userTypeProps}/${routeProps}/create-new-${routeProps}`)}
                style={{float:'right'}}
              >
                Create {lableProps}
              </button>
            </div>
          </div>
        )}
            {lableProps === 'Lead' ? (
              <Tabs selectedIndex={selectedTab} onSelect={handleTabSelect}>
                <TabList>
                  {leadStatus.map((status, index) => (
                    <Tab key={index}>{status.label}</Tab>
                  ))}
                </TabList>
                {leadStatus.map((status, index) => (
                  <TabPanel key={index}>
                    {status.value === "Dashboard" ? (
                      <LeadClientDashboard
                        lableProps={lableProps}
                        statusFilterProps={status.value}
                        showFullNav={show}
                        isClientProps={isClientProps}
                        routeProps={routeProps}
                        userTypeProps={userTypeProps}
                      />
                    ) : (
                      <ListLeadComponent
                        userTypeProps={userTypeProps}
                        lableProps={lableProps}
                        statusFilterProps={status.value}
                        showFullNav={show}
                        isClientProps={isClientProps}
                        routeProps={routeProps}
                      />
                    )}
                  </TabPanel>
                ))}
              </Tabs>
            ) : (
              <ListLeadComponent
                lableProps={lableProps}
                userTypeProps={userTypeProps}
                showFullNav={show}
                isClientProps={isClientProps}
                routeProps={routeProps}
              />
            )}
          </div>
    </>
      );
}
