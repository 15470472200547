import React, { useEffect, useState } from "react";
import {
  GetPaymentServices,
  GetSpecificPaymentService,
  GetUser,
} from "../../../Api/Api";
import AdminNavbar from "../Navbar/AdminNavbar";

import moment from "moment";
import { useSelector } from "react-redux";
import Spinner from "../../../Spinner/Spinner";
import MUIDataTable from "mui-datatables";
import CustomModal from "../../../Components/CustomModal";

export default function PaymentServices() {
  useEffect(() => {
    window.scroll(0, 0);

    getListPaymentServices();
  }, []);
  const [paymentmodal, setPaymentModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [listPayServices, setListPayServices] = useState([]);
  const state = useSelector((state) => state);
  const getListPaymentServices = async () => {
    setLoading(true);
    const token = state?.auth?.auth?.token;

    const data = await GetPaymentServices(token);
    if (data && data.statusCode === 200) {
  let temp = [];
  data.data.map((data, i) => {
    let dummy = {
      _id: data?._id,
      category: data?.category,
      title: data?.title,

      createdAt: moment(data?.createdAt).format("DD/MM/YYYY"),
      paymentType: data?.paymentType,
      paymentStatus: data?.paymentStatus,
      price: data?.price,
      paymentId: data?.paymentId,
      status: data?.status,
    };
    temp.push(dummy);
    return null;
  });

      setListPayServices(temp);
      setLoading(false);
    } else {
      setListPayServices([]);
      setLoading(false);
      //console.log(data);
    }
  };

  const [getpayMyServices1, setGetPayMyServices1] = useState({});

  const GetPayServicesMyTsks1 = async (id) => {
    setLoading(true);
    const token = state.auth.auth.token;
    // const creator_id = state.auth.auth._id;
    let data = await GetSpecificPaymentService(token, id);
    if (data && data.statusCode === 200) {
      fetchData(data.data.profileId);
      setGetPayMyServices1(data.data);
      setLoading(false);
    } else {
      setGetPayMyServices1([]);
      setLoading(false);
      //console.log("Error while getting my task");
    }
  };

  const [paymentDetail, setPaymentDetails] = useState({});
  async function fetchData(id) {
    const token = state?.auth?.auth?.token;
    const getPayServicesBasicDetails = await GetUser(token, id);
    if (getPayServicesBasicDetails && getPayServicesBasicDetails?.statusCode === 200) {
      setPaymentDetails(getPayServicesBasicDetails.data);
    } else {
      setPaymentDetails([]);
      //console.log("error while getting user profile info");
    }
  }
  //console.log(getpayMyServices1);

  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "category",
      label: "Category",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div
              onClick={(val) => {
                GetPayServicesMyTsks1(tableMeta.rowData[0]);

                setPaymentModal(true);
              }}
            >
              {value}
            </div>
          );
        },
      },
    },
    {
      name: "title",
      label: "Title",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "paymentType",
      label: "Payment Type",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "paymentStatus",
      label: "Payment Status",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "price",
      label: "Price",
      options: {
        customBodyRender: (value) => {
          return <div>₹{value}</div>;
        },
      },
    },
    {
      name: "createdAt",
      label: "Created At",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "paymentId",
      label: "Payment Id",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    setRowProps: (row) => {
      if (row[5] === false) {
        return {
          style: { background: "#DBDFEA", cursor: "pointer" },
        };
      } else {
        return {
          style: { background: "white", cursor: "pointer" },
        };
      }
    },
  };

  return (
    <div>
      <AdminNavbar />
      <div className="main">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div>
              <div className="">
                <h5 className="mb-0 fw-bold color-maroon">Payment Services</h5>
              </div>
              <div className="my-4">
                <MUIDataTable
                  title={"Dalmaf - Payment Services "}
                  data={listPayServices}
                  columns={columns}
                  options={options}
                />
              </div>
            </div>
          </>
        )}
        <CustomModal
          open={paymentmodal}
          onClickOutside={() => {
            setPaymentModal(false);
          }}
        >
          <div className="paymentmodal-content w-50 m-auto">
            <div className="paymentmodal-body p-0 ">
              <div className="container ">
                <span
                  className="material-symbols-rounded filled-icon close-icon"
                  style={{
                    marginRight: "30px",
                    marginTop: "50px",
                    textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                  }}
                  onClick={() => {
                    setPaymentModal(false);
                  }}
                >
                  close
                </span>
                <div className="row d-flex align-items-center justify-content-center  mt-0">
                  <div className="container ">
                    <div className="row justify-content-center">
                      <div className="col-lg-12">
                        <div className=" my-5 px-3">
                          <div className="row justify-content-center mt-2">
                            <div className="col-lg-10">
                              <div className="mb-5">
                                <h4 className="fw-bold text-center  mt-2">
                                  Payment Details
                                </h4>
                              </div>
                              <div>
                                <div className="mb-3">
                                  <div className="row">
                                    <div className="col-6">
                                      <img
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                        }}
                                        src={paymentDetail?.profileImage}
                                        alt=""
                                      />
                                      <h6 for="" className="form-label mb-3">
                                        Name : {paymentDetail?.name}
                                      </h6>
                                      <h6 for="" className="form-label mb-3">
                                        Email ID : {paymentDetail?.email}
                                      </h6>
                                      <h6 for="" className="form-label mb-3">
                                        Mobile Number :{" "}
                                        {paymentDetail?.phoneNumber}
                                      </h6>
                                    </div>
                                    <div className="col-6">
                                      <div className="col-lg-12 mb-3">
                                        <div className="admin-service2 p-4">
                                          <div className="mt-3">
                                            <h6
                                              className="color-maroon fw-bold"
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              {/* {allPackage?.packageType?.packageName} */}
                                            </h6>
                                            <div className="d-flex mb-2 align-items-center">
                                              <b>Category:</b>

                                              <h6 className="banner-heading mb-0">
                                                {getpayMyServices1?.category}
                                              </h6>
                                            </div>
                                            <p className="mb-0 ">
                                              <b>
                                                Subcategory:{" "}
                                                <span className="banner-heading">
                                                  {getpayMyServices1?.subcategory}
                                                </span>
                                              </b>
                                            </p>
                                            <p className="mb-0 ">
                                              <b>
                                                Title:{" "}
                                                <span className="banner-heading">
                                                  {getpayMyServices1?.title}
                                                </span>
                                              </b>
                                            </p>
                                            <p className="mb-0 ">
                                              <b>
                                                Price:{" "}
                                                <span className="banner-heading">
                                                  {getpayMyServices1?.price}
                                                </span>
                                              </b>
                                            </p>
                                            <p className="mb-0 ">
                                              <b>
                                                Payment Type:{" "}
                                                <span className="banner-heading">
                                                  {getpayMyServices1?.paymentType}
                                                </span>
                                              </b>
                                            </p>
                                            <p className="mb-0 ">
                                              <b>
                                                Payment Status:{" "}
                                                <span
                                                  className=""
                                                  style={{ color: "green" }}
                                                >
                                                  {getpayMyServices1?.paymentStatus?.toUpperCase()}
                                                </span>
                                              </b>
                                            </p>
                                            <p className="mb-0 ">
                                              <b>
                                                Payment Date:{" "}
                                                <span className="banner-heading">
                                                  {moment(
                                                    getpayMyServices1?.createdAt
                                                  ).format("DD/MM/YYYY")}
                                                </span>
                                              </b>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CustomModal>
      </div>
    </div>
  );
}
