import axios from "axios";
import { url } from "./BaseUrl";

const APIBaseUrl = url;



// SignUp
export const SignUp = async (Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/emailIdVerification`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// Verify Otp
export const VerifyOtp = async (Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/accountOtpVerify`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
//RESEND
export const ReSendOtp = async (Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/resendOtp`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//CHANGE PASSWORD
export const ChangePassword = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/newPassWord`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const UserLogin = async (Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/userLogIn`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//CHANGE PASSWORD
export const ForgotPassword = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/forGetpassWord`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//RESEND
export const ForgotOtp = async (Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/forGetpassWord_OtpVerify`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//PROFILE UPDATE
export const UserProfileUpdate = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/updateUserDetails?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get  using id

export const GetUser = async (token, id) => {
  const response = await axios
    .get(`${APIBaseUrl}/findUserDetails?token=${token}&_id=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
//

//EmployeeProfileDetailsSpecific

export const EmployeeProfileDetailsSpecific = async (token, id) => {
  const response = await axios
    .get(`${APIBaseUrl}/findUserDetails?token=${token}&_id=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Document/Image Upload
export const UploadDocument = async (Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/upload`, Payload, {
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//PROFILE UPDATE
export const UserPasswordUpdate = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/updatePassword?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get my services - user

export const GetMyServiceUser = async (token, userId) => {
  const response = await axios
    .get(`${APIBaseUrl}/findServicesDetails?token=${token}&userId=${userId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get my specific services - user

export const GetMySpecificServiceUser = async (token, id) => {
  const response = await axios
    .get(`${APIBaseUrl}/findOneServicesDetails?token=${token}&_id=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Create Contact Us
export const UserContactUs = async (Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/createContactUsDetails`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//service package

export const adminservicepakage = async (token, Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/createPackageDetails?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//create career
export const Usercareer = async (Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/createContactUsDetails`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      //console.log(response, "correct");
      return response.data;
    })
    .catch((error) => {
      //console.log(error, "error");
    });
  return response;
};
//aboutcontent

export const UseraboutContent = async (Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/createContactUsDetails`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      //console.log(response, "correct");
      return response.data;
    })
    .catch((error) => {
      //console.log(error, "error");
    });
  return response;
};
//create  DMContent

export const UserDmContent = async (Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/createContactUsDetails`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      //console.log(response, "correct");
      return response.data;
    })
    .catch((error) => {
      //console.log(error, "error");
    });
  return response;
};

//Create my task
export const UserCreateMyTask = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/createMyTaskDetail?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get my tasks - subadmin

export const GetMyTaskSubAdmin = async (token, status, id) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findMyTaskDetails?token=${token}&status=${status}&assignedTo=${id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get my tasks - superadmin

export const GetMyTaskSuperAdmin = async (token, status) => {
  const response = await axios
    .get(`${APIBaseUrl}/findMyTaskDetails?token=${token}&status=${status}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetMyTaskSuperAdminAll = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/findMyTaskDetails?token=${token}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get my tasks - superadmin

export const GetMyTaskSuperAdminOverdue = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/findMyTaskDetails?token=${token}&overdue=true`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetMyTaskSuperAdminOverdueEmp = async (token, id) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findMyTaskDetails?token=${token}&overdue=true&userId=${id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get all subadmin

export const GetAllSubAdmin = async (token, role, status) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findAllUserDetails?token=${token}&role=${role}&status=${status}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get all subadmin

export const GetAllSubAdminAndAdmin = async (token) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findAllUserDetails?token=${token}&masterdata=all&status=active`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//const id = req.params
export const ListingBasedOnRole = async (token, role) => {
  const response = await axios
    .get(`${APIBaseUrl}/findAllUserDetails?token=${token}&role=${role}`, {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));

  return response;
};

//const id = req.params
export const ListingBasedOnRoleWithStatus = async (token, role, status) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findAllUserDetails?token=${token}&role=${role}&status=${status}`,
      {
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));

  return response;
};

export const ListinglogOnRole = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/findAlllogManagementDetails?token=${token} `, {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));

  return response;
};

export const ListingEmployeeOnrole = async (token, status) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findAllUserDetails?token=${token}&role=subadmin&status=${status} `,
      {
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));

  return response;
};

export const ListEmployeerolemanagment = async (role, status) => {
  const response = await axios
    .get(`${APIBaseUrl}/findAllUserDetails?role=subadmin&status=${status} `, {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));

  return response;
};

export const ListAdminEmployeemanagment = async (token, status) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findAllemployeeManagementDetails?token=${token}&status=${status}`,
      {
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));

  return response;
};

export const ListAdminManagment = async (token, status) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findAdminManagementDetails?token=${token}&status=${status}`,
      {
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));

  return response;
};

//user dashboard
export const GetUserDashboard = async (token, role) => {
  const response = await axios
    .get(`${APIBaseUrl}/dashboard?token=${token}&role=${role} `, {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));

  return response;
};

//Create service request
export const UserServiceRequest = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/v1/user/service_request/submit`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Create property request
export const UserPropertyRequest = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/createPropertyRequestDetails`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get service request by role

export const GetServiceRequestByRole = async (token, role, status) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findServicesRequestDetails?token=${token}&role=${role}&status=${status}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
//get service request by role

export const GetServiceRequestByRoleAll = async (token, role) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findServicesRequestDetails?token=${token}&role=${role}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const GetServiceRequestSubadmin = async (token, id, status) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findServicesRequestDetails?token=${token}&status=${status}&userId=${id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const GetServiceRequestSubadminEM = async (token, role) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findServicesRequestDetails?token=${token}&role=${role}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetServicemyRole = async (token, role) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findServicesRequestDetails?token=${token}&role=${role}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get service request by role

export const GetServiceRequestByAdmin = async (token, role, status) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findServicesRequestDetails?token=${token}&role=${role}&status=${status}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get service request by role

export const GetServiceRequestByAdminAll = async (token, role) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findServicesRequestDetails?token=${token}&role=${role}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get particular service request

export const GetParticularServiceRequest = async (token, id) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findOneServicesRequestDetails?token=${token}&_id=${id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get particular property request

export const GetParticularPropertyRequest = async (token, id) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findOnePropertyRequestDetails?token=${token}&_id=${id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get service req from super admin

export const GetParticularServiceRequestUser = async (token, id, status) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findServicesRequestDetails?token=${token}&role=adminservice&userId=${id}&status=${status}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get service req from super admin

export const GetParticularServiceRequestUserr = async (token, id) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findServicesRequestDetails?token=${token}&role=adminservice&userId=${id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetParticularTaskAdmin = async (token, id) => {
  const response = await axios
    .get(`${APIBaseUrl}/findMyTaskDetails?token=${token}&assignedTo=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get package

export const GetPackage = async (token, status, id) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findPackageDetails?token=${token}&status=${status}&userId=${id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
//get package auto

export const GetPackageAuto = async (token, status) => {
  const response = await axios
    .get(`${APIBaseUrl}/findPackageDetails?token=${token}&status=${status}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetPackageAutoHome = async (status) => {
  const response = await axios
    .get(`${APIBaseUrl}/findPackageDetails?status=${status}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get specific package

export const getSpecificPackagee = async ( id ,userId) => {
  console.log(`${APIBaseUrl}/findOnePackageDetails?_id=${id}&userId=${userId}`)
  const response = await axios
    .get(`${APIBaseUrl}/findOnePackageDetails?_id=${id}&userId=${userId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get history

export const getUserHistory = async (token, type) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findPaymentPackagesDetails?token=${token}&purchaseType=${type}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get history

export const getUserHistorySpecific = async (token, id, type) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findPaymentPackagesDetails?token=${token}&_id=${id}&type=admin&purchaseType=${type}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Update service request

export const UpdateServiceRequest = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/updateServicesRequestDetails?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Update property request

export const UpdatePropertyRequest = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/updatePropertyRequestDetails?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//update my task

export const UpdateMyTaskRequest = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/updateMyTaskDetails?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get all packages - admin

export const getAllPackagesAdmin = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/findPackageDetails?token=${token}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const getAllPackagesUser = async (token) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findPackageDetails?token=${token}&customPackage=Custom`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const GetALLPACKAGE = async (userId,token) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findPackageDetails?customPackage=Custom&token=${token}&userId=${userId}`,
      
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const getAllPackagesUserAuto = async () => {
  const response = await axios
    .get(`${APIBaseUrl}/findPackageDetails?customPackage=Auto`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const getAllPackagesAuto = async (userId) => {
  const response = await axios
    .get(`${APIBaseUrl}/findPackageDetails?customPackage=Auto&userId=${userId}`, {
     
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
//get specific task

export const GetSpecificTask = async (token, id) => {
  const response = await axios
    .get(`${APIBaseUrl}/findOneMyTaskDetails?token=${token}&_id=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get specific task

export const GetSpecificHistory = async (token, id) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findOnePaymentPackagesDetails?token=${token}&_id=${id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get specific history user

export const GetSpecificHistoryUser = async (token, id) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findpurchaseHistoryDetails?token=${token}&userId=${id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Create employee role
export const CreateEmployeeRole = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/createRoleDetail?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get employee role

export const GetAllEmployeeRole = async (token, status) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findRoleDetails?token=${token}&status=${status}&role=subadmin `,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get employee role

export const GetAllEmployeeRoleSpecific = async (token, id) => {
  const response = await axios
    .get(`${APIBaseUrl}/findOneRoleDetails?token=${token}&_id=${id} `, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get admin role

export const GetAllAdminRole = async (token, status) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findRoleDetails?token=${token}&status=${status}&role=admin`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get notification

export const GetNotification = async (token, role) => {
  const response = await axios
    .get(`${APIBaseUrl}/getnotification?token=${token}&role=${role}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Update notification

export const UpdateNotification = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/updatenotification?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Create job
export const AdminCreateJob = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/createJobPositionDetail?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get jobs

export const GetJobs = async (status) => {
  const response = await axios
    .get(`${APIBaseUrl}/findJobPositionDetails?status=${status}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get specific job

export const GetSpecificJob = async (id) => {
  const response = await axios
    .get(`${APIBaseUrl}/findOneJobPositionDetails?_id=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get specific lead

export const GetSpecificLead = async (token, id) => {
  const response = await axios
    .get(`${APIBaseUrl}/findOneLeadDetails?token=${token}&_id=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//update job

export const UpdateJob = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/updateJobPositionDetails?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//delete job

export const DeleteJob = async (id, token) => {
  const response = await axios
    .delete(
      `${APIBaseUrl}/deleteJobPositionDetails?token=${token}`,
      {
        data: {
          _id: id,
        },
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//update lead

export const UpdateLead = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/updateLeadDetails?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//apply job
export const ApplyJob = async (token, Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/createJobApplicationDetail?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get Property

export const GetProperty = async (status) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findAllPropertyDetails?masterDataType=admin&status=${status}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get Property subadmin

export const GetPropertySubadmin = async (userId, status) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findSubAdminPropertyDetails?userId=${userId}&status=${status}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// export const GetPropertyUser = async (start,limit) => {
  export const GetPropertyUser = async () => {
  const response = await axios
    .get(
      // `${APIBaseUrl}/findAllPropertyDetails?start=`+start+`&limit=`+limit,
      `${APIBaseUrl}/findAllPropertyDetails`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const GetGoldListUser = async () => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findGoldAuctionDetails`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const GetVechileListUser = async () => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findTwoWheelerDetails`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
//Create Property
export const CreateProperty = async (token, Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/createProperty?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
// Create Auction View Log
export const auctionCreateLog = async (token, Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/auctionViewLog/create?token=${token}`, {
      auction_id:Payload
    }, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get specific Property

export const GetSpecificProperty = async (id) => {
  const response = await axios
    .get(`${APIBaseUrl}/findProperty?_id=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const GetSpecificGold = async (id) => {
  const response = await axios
    .get(`${APIBaseUrl}/findGoldAuctionDetails?_id=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const GetSpecificVechile = async (id) => {
  const response = await axios
    .get(`${APIBaseUrl}/findOneTwoWheelerDetails?_id=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//update property
export const UpdateProperty = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/updateProperty?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//delete property
export const DeleteProperty = async (Payload, token) => {
  const response = await axios
    .delete(`${APIBaseUrl}/deleteProperty?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// Create employee
export const CreateEmployee = async (Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/emailIdVerification`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// create admin
export const Createadmin = async (Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/emailIdVerification`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//getlead

export const GetLead = async (token, status, type) => {
  const response = await axios
    .get(`${APIBaseUrl}/findLeadDetails`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      params: {
        token,
        status,
        ...(type && { type: type }),
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//getlead

export const GetLeadAll = async (token, type) => {
  const response = await axios
    .get(`${APIBaseUrl}/findLeadDetails`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      params: {
        token,
        ...(type && { type: type }),
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get  department

export const GetDepartment = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/findDepartmentDetails?token=${token}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// Create department
export const CreateDepartment = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/createDepartmentDetail?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Update task

export const UpdateTaskAdmin = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/updateMyTaskDetails?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Update role

export const UpdateRole = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/updateRoleDetails?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get  timeslot

export const GetTimeslot = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/findTimeSlotDetails?token=${token}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// Create timeslot
export const CreateTimeslot = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/createTimeSlotDetail?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get  department

export const GetPackageService = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/findPackageServicesDetails?token=${token}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// Create department
export const CreatePackageService = async (Payload, token) => {
  const response = await axios
    .post(
      `${APIBaseUrl}/createPackageServicesDetails?token=${token}`,
      Payload,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get  department

export const GetPackageType = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/findPackageTypeDetails?token=${token}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// Create department
export const CreatePackageType = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/createPackageTypeDetails?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
//Create pricing
export const CreatePricing = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/createPricingDetail?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get pricing

export const GetPricing = async () => {
  const response = await axios
    .get(`${APIBaseUrl}/findPricingDetails`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get specific pricing

export const GetSpecificPricing = async (id) => {
  const response = await axios
    .get(`${APIBaseUrl}/findOnePricingDetails?pricingId=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//update pricing

export const UpdatePricing = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/updatePricingDetails?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get applied job

export const GetAppliedJob = async (token, id, status) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findJobApplicationDetails?token=${token}&jobId=${id}&status=${status}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//update job apllication

export const UpdateJobApplication = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/updateJobApplicationDetails?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get specific job applicant

export const GetSpecificJobApplicant = async (token, id) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findOneJobApplicationDetails?_id=${id}&token=${token}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get pricing

export const GetPricingWithName = async (name) => {
  const response = await axios
    .get(`${APIBaseUrl}/findPricingDetails?pageName=${name}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Update service package

export const UpdateServicePackage = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/updatePackageDetails?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get contact list

export const GetContactList = async (status) => {
  const response = await axios
    .get(`${APIBaseUrl}/findAllContactUsDetails?status=${status}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get specific contact list

export const GetSpecificContactList = async (id) => {
  const response = await axios
    .get(`${APIBaseUrl}/findContactUsDetails?_id=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//update contact us

export const UpdateContactUs = async (Payload) => {
  const response = await axios
    .put(`${APIBaseUrl}/updateContactUsDetails`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get news letter

export const GetNewsLetter = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/findNewsLetterDetails?token=${token}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Create news letter
export const CreateNewsLetter = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/createNewsLetterDetails`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get specific newsLetter

export const GetSpecificNewsLetter = async (token, id) => {
  const response = await axios
    .get(`${APIBaseUrl}/findOneNewsLetterDetails?token=${token}&_id=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//update news letter

export const UpdateNewsLetter = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/updateNewsLetterDetails?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//create payment service
export const CreatePaymentService = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/makePayment?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const CreatePaymentCustPack = async (Payload, token) => {
  console.log(Payload);
  const formData = new FormData();
  formData.append("purchaseType", Payload.purchaseType);
  formData.append("orderId", Payload.orderId);
  formData.append("purchasePackageId", Payload.purchasePackageId);
  formData.append("price", Payload.price);
  formData.append("paymentInfo", Payload.paymentInfo);
  formData.append("status", Payload.status);
  formData.append("serviceRequestId", Payload.serviceRequestId);
  formData.append("is_logedin", Payload.is_logedin);

  const response = await axios
    .post(`${APIBaseUrl}/makePayment?token=${token}`, formData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const UpdatePaymentCustom = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/updatePayment?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const serviceViewLogLoggedIn = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/serviceViewLog/Create?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// http://localhost:8004/dalmaf/serviceViewLog/find?start=0&limit=10
export const serviceViewLogNotLoggedIn = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/serviceViewLog/Create`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const serviceViewLog = async (start,limit, token) => {
  const response = await axios
    .get(`${APIBaseUrl}/serviceViewLog/find?token=${token}&start=${start}&limit=${limit}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const auctionViewLog = async (start,limit, token,auction_type) => {
  const response = await axios
    .get(`${APIBaseUrl}/auctionViewLog/find?token=${token}&start=${start}&limit=${limit}&auction_type=${auction_type}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const serviceViewAuctionLog = async (start,limit, token,auction_type) => {
  const response = await axios
    .get(`${APIBaseUrl}/auctionViewLog/find?token=${token}&start=${start}&limit=${limit}&auction_type=${auction_type}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Create Payment Using CCAvenue For package
export const CreatePaymentServiceCC = async (Payload, token) => {
  const formData = new FormData();
  formData.append("orderId", Payload.orderId);
  formData.append("purchaseType", Payload.purchaseType);
  formData.append("packagePurchaseId", Payload.packagePurchaseId);
  formData.append("price", Payload.price);
  formData.append("paymentInfo", Payload.paymentInfo);
  formData.append("status", Payload.status);
  formData.append("is_logedin", Payload.is_logedin);

  const response = await axios
    .post(`${APIBaseUrl}/makePayment?token=${token}`, formData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const CreatePaymentServiceCCNL = async (Payload) => {
  const formData = new FormData();
  formData.append("orderId", Payload.orderId);
  formData.append("purchaseType", Payload.purchaseType);
  formData.append("packagePurchaseId", Payload.packagePurchaseId);
  formData.append("price", Payload.price);
  formData.append("paymentInfo", Payload.paymentInfo);
  formData.append("status", Payload.status);
  formData.append("is_logedin", Payload.is_logedin);
  formData.append("name", Payload.name);
  formData.append("phoneNumber", Payload.mobileNumber);
  formData.append("email", Payload.email);

  const response = await axios
    .post(`${APIBaseUrl}/makePayment`, formData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Create Payment Using CCAvenue For SR
export const CreatePaymentSRCC = async (Payload, token) => {
  const formData = new FormData();
  formData.append("orderId", Payload.orderId);
  formData.append("purchaseType", Payload.purchaseType);
  formData.append("contentId", Payload.contentId);
  formData.append("price", Payload.price);
  formData.append("paymentInfo", Payload.paymentInfo);
  formData.append("status", Payload.status);
  formData.append("is_logedin", Payload.is_logedin);

  const response = await axios
    .post(`${APIBaseUrl}/makePayment?token=${token}`, formData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const CreatePaymentSRCCNL = async (Payload) => {
  const formData = new FormData();
  formData.append("orderId", Payload.orderId);
  formData.append("purchaseType", Payload.purchaseType);
  formData.append("contentId", Payload.contentId);
  formData.append("price", Payload.price);
  formData.append("paymentInfo", Payload.paymentInfo);
  formData.append("status", Payload.status);
  formData.append("is_logedin", Payload.is_logedin);

  formData.append("name", Payload.name);
  formData.append("phoneNumber", Payload.mobileNumber);
  formData.append("email", Payload.email);

  const response = await axios
    .post(`${APIBaseUrl}/makePayment`, formData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const CreatePaymentCustomPkg = async (Payload, token) => {
  const formData = new FormData();
  formData.append("orderId", Payload.orderId);
  formData.append("payment_id", Payload.payment_id);
  console.log(Payload);
  const response = await axios
    .put(`${APIBaseUrl}/updatePayment?token=${token}`, formData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const orderStatus = async (token, orderId) => {
  const response = await axios
    .get(`${APIBaseUrl}/orderStatus?token=${token}&orderId=${orderId}`, {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const orderStatusWithoutLogin = async (orderId) => {
  const response = await axios
    .get(`${APIBaseUrl}/orderStatus?orderId=${orderId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const CreatePaymentServiceWithoutToken = async (Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/makePayment`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//create payment package
export const CreatePaymentPackage = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/makePayment?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//create order
export const CreateOrder = async (Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/ccAvenuePayment`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// get paymnet services

export const GetPaymentServices = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/findPaymentServicesDetails?token=${token} `, {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));

  return response;
};

//get specific payment service

export const GetSpecificPaymentService = async (token, id) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findOnePaymentServicesDetails?token=${token}&_id=${id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// get paymnet package

export const GetPaymentPackage = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/findPaymentPackagesDetails?token=${token} `, {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));

  return response;
};

//get specific payment package

export const GetSpecificPaymentPackage = async (token, id) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findOnePaymentPackagesDetails?token=${token}&_id=${id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get property request

export const GetPackageRequestByAdmin = async (token, role, status) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findPropertyRequestDetails?token=${token}&role=${role}&status=${status}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get package request by role

export const GetPackageRequestByRole = async (token, role, status, id) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findPropertyRequestDetails?token=${token}&role=${role}&status=${status}&userId=${id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const createLeavemoduleapi = async (token, Payload) => {
  const response = await axios

    .post(`${APIBaseUrl}/createLeaveDetail?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",

        "Content-Type": "application/json",
      },
    })

    .then((response) => {
      return response.data;
    })

    .catch((error) => ({ error: error }));

  return response;
};

export const leaveManagement = async (token,status) => {
  const response = await axios

    .get(`${APIBaseUrl}/findLeaveDetails?token=${token}&status=${status}`, {
      headers: {
        Accept: "application/json",

        "Content-Type": "application/json",
      },
    })

    .then((response) => {
      return response.data;
    })

    .catch((error) => ({ error: error }));

  return response;
};

export const leaveManagementAll = async (token) => {
  const response = await axios

    .get(`${APIBaseUrl}/findLeaveDetails?token=${token}`, {
      headers: {
        Accept: "application/json",

        "Content-Type": "application/json",
      },
    })

    .then((response) => {
      return response.data;
    })

    .catch((error) => ({ error: error }));

  return response;
};

export const leaveManagementEmp = async (token, id,status) => {
  const response = await axios

    .get(`${APIBaseUrl}/findLeaveDetails?token=${token}&userId=${id}&status=${status}`, {
      headers: {
        Accept: "application/json",

        "Content-Type": "application/json",
      },
    })

    .then((response) => {
      return response.data;
    })

    .catch((error) => ({ error: error }));

  return response;
};
export const leaveManagementEmpAll = async (token, id) => {
  const response = await axios

    .get(`${APIBaseUrl}/findLeaveDetails?token=${token}&userId=${id}`, {
      headers: {
        Accept: "application/json",

        "Content-Type": "application/json",
      },
    })

    .then((response) => {
      return response.data;
    })

    .catch((error) => ({ error: error }));

  return response;
};

export const leaveSpecific = async (token, id) => {
  const response = await axios

    .get(`${APIBaseUrl}/findOneLeaveDetails?token=${token}&_id=${id}`, {
      headers: {
        Accept: "application/json",

        "Content-Type": "application/json",
      },
    })

    .then((response) => {
      return response.data;
    })

    .catch((error) => ({ error: error }));

  return response;
};

//get dashboard
export const GetDashboard = async (token, role) => {
  const response = await axios
    .get(`${APIBaseUrl}/dashboard?token=${token}&role=${role}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get dashboard
export const GetDashboardTask = async (token, status) => {
  const response = await axios
    .get(`${APIBaseUrl}/myDashboard?token=${token}&status=${status}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get dashboard
export const GetDashboardTeam = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/teamDashboard?token=${token}&filterBy=week`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
//get dashboard
export const GetDashboardLeadDash = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/leadDashboard?token=${token}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
//get dashboard
export const GetDashboardProjectStatus = async (token) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/projectStatusDashboard?token=${token}&filterBy=lastMonth`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get dashboard
export const GetDashboardLeadStatus = async (token, Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/leadStatusDashboard?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const DeleteLead = async (id, token) => {
  const response = await axios
    .delete(
      `${APIBaseUrl}/deleteLeadDetails?token=${token}`,
      {
        data: {
          _id: id,
        },
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const DeleteTasklist = async (id, token) => {
  const response = await axios
    .delete(
      `${APIBaseUrl}/deleteMyTaskDetails?token=${token}`,
      {
        data: {
          _id: id,
        },
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const DeleteServicereq = async (id, token) => {
  const response = await axios
    .delete(
      `${APIBaseUrl}/deleteServicesRequestDetails?token=${token}`,
      {
        data: {
          _id: id,
        },
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const Deleterole = async (id, token) => {
  const response = await axios
    .delete(
      `${APIBaseUrl}/deleteRoleDetails?token=${token}`,
      {
        data: {
          _id: id,
        },
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//leave UPDATE
export const UpdateLeave = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/updateLeaveDetails?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const DeletenewsLetter = async (id, token) => {
  const response = await axios
    .delete(
      `${APIBaseUrl}/deleteNewsLetterDetails?token=${token}`,
      {
        data: {
          _id: id,
        },
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const UpdateserviceRequest = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/updateServicesRequestDetails?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const UpdatePropertyReq = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/updatePropertyRequestDetails?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Create job
export const AdminLeadJob = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/createLeadDetail?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetGold = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/findGoldAuctionDetails?token=${token}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetGoldWithStatus = async (token, status) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findGoldAuctionDetails?token=${token}&status=${status}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const AdmingoldPost = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/createGoldAuctionDetail?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GoldSpecific = async (token, id) => {
  const response = await axios

    .get(`${APIBaseUrl}/findOneGoldAuctionDetails?token=${token}&_id=${id}`, {
      headers: {
        Accept: "application/json",

        "Content-Type": "application/json",
      },
    })

    .then((response) => {
      return response.data;
    })

    .catch((error) => ({ error: error }));

  return response;
};

export const UpdateGold = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/updateGoldAuctionDetails?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetWheeler = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/findTwoWheelerDetails?token=${token}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const GetWheelerWithToken = async (token,status) => {
  const response = await axios
    .get(`${APIBaseUrl}/findTwoWheelerDetails?token=${token}&status=${status}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const AdminwheelerPost = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/createTwoWheelerDetail?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const WheelerSpecific = async (token, id) => {
  const response = await axios

    .get(`${APIBaseUrl}/findOneTwoWheelerDetails?token=${token}&_id=${id}`, {
      headers: {
        Accept: "application/json",

        "Content-Type": "application/json",
      },
    })

    .then((response) => {
      return response.data;
    })

    .catch((error) => ({ error: error }));

  return response;
};

export const UpdateWheeler = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/updateTwoWheelerDetails?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const DeletePackage = async (id, token) => {
  const response = await axios
    .delete(
      `${APIBaseUrl}/deletePackageDetails?token=${token}`,
      {
        data: {
          _id: id,
        },
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Create gold request
export const GoldRequest = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/createGoldAuctionRequestDetails`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Main services

export const GetMain = async (status) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findServicesDetails?status=${status}&masterType=mainService`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//update main

export const UpdateMain = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/updateServicesDetails?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get specific main

export const GetSpecificMain = async (id) => {
  const response = await axios
    .get(`${APIBaseUrl}/findOneServicesDetails?_id=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Create main
export const CreateMain = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/createServicesDetail?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//level1 services

export const GetLevel1 = async (status, id) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findServicesDetails?status=${status}&masterType=subServiceL1&mainServiceId=${id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const GetLevel1All = async (status) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findServicesDetails?status=${status}&masterType=subServiceL1`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//level2 services

export const GetLevel2 = async (status, id, id1) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findServicesDetails?status=${status}&masterType=subServiceL2&mainServiceId=${id}&subServiceL1Id=${id1}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetLevel2All = async (status) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findServicesDetails?status=${status}&masterType=subServiceL2`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetContent = async (status, id) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findContentDetails?status=${status}&subServiceL2Id=${id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetContentAll = async (status) => {
  const response = await axios
    .get(`${APIBaseUrl}/findContentDetails?status=${status}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get specific main

export const GetSpecificContent = async (id) => {
  const response = await axios
    .get(`${APIBaseUrl}/findOneContentDetails?_id=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Create main
export const CreateContent = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/createContentDetails?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//update job

export const UpdateContent = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/updateContentDetails?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const Adminspecificlist = async (token, id, status) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findMyTaskDetails?token=${token}&userId=${id}&status=${status}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const AdminspecificlistCreator = async (token, id) => {
  const response = await axios
    .get(`${APIBaseUrl}/findMyTaskDetails?token=${token}&creatorId=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const AdminspecificlistScroll = async (token, id) => {
  const response = await axios
    .get(`${APIBaseUrl}/findMyTaskDetails?token=${token}&userId=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetSpecificListing = async (token, role) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findServicesRequestDetails?token=${token}&role=${role}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const AdminspecificlistSR = async (token, id, status) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findServicesRequestDetails?token=${token}&subadminId=${id}&status=${status}&role=superadmin`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const AdminspecificlistSRScroll = async (token, id) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findServicesRequestDetails?token=${token}&subadminId=${id}&role=superadmin`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const GetDashBoardUser = async (token, filterBy) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/userServicesRequest?token=${token}&filterBy=${filterBy}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const GetDashBoardUserDate = async (
  token,
  filterBy,
  fromDate,
  toDate
) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/userServicesRequest?token=${token}&filterBy=${filterBy}&fromDate=${fromDate}&toDate=${toDate}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const GetDashUserServicesReq = async (token, filterBy) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/userpurchaseServices?token=${token}&filterBy=${filterBy}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const GetDashUserServicesReqDate = async (
  token,
  filterBy,
  fromDate,
  toDate
) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/userpurchaseServices?token=${token}&filterBy=${filterBy}&fromDate=${fromDate}&toDate=${toDate}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetDashBoardpackage = async (token, filterBy) => {
  const response = await axios
    .get(`${APIBaseUrl}/packageExpiry?token=${token}&filterBy=${filterBy}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const GetDashBoardpackageDate = async (
  token,
  filterBy,
  fromDate,
  toDate
) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/packageExpiry?token=${token}&filterBy=${filterBy}&fromDate=${fromDate}&toDate=${toDate}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const GetEmployeeDashBoardSR = async (token, filterBy) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/employeeServicesRequest?token=${token}&filterBy=${filterBy}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const GetEmployeeDashBoardSRDate = async (
  token,
  filterBy,
  fromDate,
  toDate
) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/employeeServicesRequest?token=${token}&filterBy=${filterBy}&fromDate=${fromDate}&toDate=${toDate}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetEmployeeDashBoardLead = async (token, filterBy) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/employeeLeadDashboard?token=${token}&filterBy=${filterBy}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const GetEmployeeDashBoardLeadDate = async (
  token,
  filterBy,
  fromDate,
  toDate
) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/employeeLeadDashboard?token=${token}&filterBy=${filterBy}&fromDate=${fromDate}&toDate=${toDate}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetEmployeeDashBoardMyTask = async (token, filterBy) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/employeeMytaskDashboard?token=${token}&filterBy=${filterBy}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const GetEmployeeDashBoardMyTaskDate = async (
  token,
  filterBy,
  fromDate,
  toDate
) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/employeeMytaskDashboard?token=${token}&filterBy=${filterBy}&fromDate=${fromDate}&toDate=${toDate}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetEmployeeDashBoardTotal = async (token, filterBy) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/employeeCountDashboard?token=${token}&filterBy=${filterBy}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const GetEmployeeDashBoardTotalDate = async (
  token,
  filterBy,
  fromDate,
  toDate
) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/employeeCountDashboard?token=${token}&filterBy=${filterBy}&fromDate=${fromDate}&toDate=${toDate}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const GetAdminDashBoardTotal = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/adminDashboardCount?token=${token}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const GetAdminDashBoardTotalDate = async (
  token,
  filterBy,
  fromDate,
  toDate
) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/adminDashboardCount?token=${token}&filterBy=${filterBy}&fromDate=${fromDate}&toDate=${toDate}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetTeamMember = async (token, filterBy) => {
  const response = await axios
    .get(`${APIBaseUrl}/teammember?token=${token}&filterBy=${filterBy}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const GetTeamMemberDate = async (token, filterBy, fromDate, toDate) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/teammember?token=${token}&filterBy=${filterBy}&fromDate=${fromDate}&toDate=${toDate}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const GetAdminServicesRequest = async (
  token,
  filterBy,
  fromDate,
  toDate
) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/adminServicesRequest?token=${token}&filterBy=${filterBy}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const GetAdminServicesRequestDate = async (
  token,
  filterBy,
  fromDate,
  toDate
) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/adminServicesRequest?token=${token}&filterBy=${filterBy}&fromDate=${fromDate}&toDate=${toDate}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetPackageBuyer = async (token, filterBy) => {
  const response = await axios
    .get(`${APIBaseUrl}/packageBuyerUser?token=${token}&filterBy=${filterBy}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const GetPackageBuyerDate = async (
  token,
  filterBy,
  fromDate,
  toDate
) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/packageBuyerUser?token=${token}&filterBy=${filterBy}&fromDate=${fromDate}&toDate=${toDate}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const getMyTaskDashboard = async (token, filterBy) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/adminMyTaskDashboard?token=${token}&filterBy=${filterBy}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const getMyTaskDashboardDate = async (
  token,
  filterBy,
  fromDate,
  toDate
) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/adminMyTaskDashboard?token=${token}&filterBy=${filterBy}&fromDate=${fromDate}&toDate=${toDate}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const getCustomPackageBuyerDate = async (
  token,
  filterBy,
  fromDate,
  toDate
) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/custompackageBuyerUser?token=${token}&filterBy=${filterBy}&fromDate=${fromDate}&toDate=${toDate}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const getCustomPackageBuyer = async (token, filterBy) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/custompackageBuyerUser?token=${token}&filterBy=${filterBy}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const getUserPackageExpiryDate = async (
  token,
  filterBy,
  fromDate,
  toDate
) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/userExpiryPackage?token=${token}&filterBy=${filterBy}&fromDate=${fromDate}&toDate=${toDate}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const getUserPackageExpiry = async (token, filterBy) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/userExpiryPackage?token=${token}&filterBy=${filterBy}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const getUserExpiryDatePcakageDate = async (
  token,
  filterBy,
  fromDate,
  toDate
) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/userExpiryDatePackage?token=${token}&filterBy=${filterBy}&fromDate=${fromDate}&toDate=${toDate}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const getUserExpiryDatePcakage = async (token, filterBy) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/userExpiryDatePackage?token=${token}&filterBy=${filterBy}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const FetchtotalTaskDashboards = async (token, filterBy) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/adminTotalTaskDashboard?token=${token}&filterBy=${filterBy}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const FetchtotalTaskDashboardDate = async (
  token,
  filterBy,
  fromDate,
  toDate
) => {
  const response = await axios

    .get(
      `${APIBaseUrl}/adminTotalTaskDashboard?token=${token}&filterBy=${filterBy}&fromDate=${fromDate}&toDate=${toDate}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const OverDueTask = async (token, filterBy) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/adminOverdueTaskDashboard?token=${token}&filterBy=${filterBy}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const OverDueTaskDate = async (token, filterBy, fromDate, toDate) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/adminOverdueTaskDashboard?token=${token}&filterBy=${filterBy}&fromDate=${fromDate}&toDate=${toDate}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const ServiceBuyerUser = async (token, filterBy) => {
  const response = await axios
    .get(`${APIBaseUrl}/serviceBuyerUser?token=${token}&filterBy=${filterBy}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const ServiceBuyerUserDate = async (
  token,
  filterBy,
  fromDate,
  toDate
) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/serviceBuyerUser?token=${token}&filterBy=${filterBy}&fromDate=${fromDate}&toDate=${toDate}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const createCCAveune = async (name, amount) => {
  const formData = new FormData();
  formData.append("name", name);
  formData.append("amount", amount);
  const response = await axios
    .post(`${APIBaseUrl}/ccAvenuePayment`, formData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const renderCCAveune = async (config) => {
  var result = await axios(config);
  // const response = await axios
  //   .post(`${APIBaseUrl}/ccAvenuePayment`, formData)
  //   .then((response) => {
  //     return response.data;
  //   })
  //   .catch((error) => ({ error: error }));
  return result;
};
export const GetRevenueDash = async (token, filterBy, fromDate, toDate) => {
  const response = await axios
    .get(`${APIBaseUrl}/findRevenueDetais?token=${token}&filterBy=${filterBy}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const GetRevenueDashDate = async (token, filterBy, fromDate, toDate) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findRevenueDetais?token=${token}&filterBy=${filterBy}&fromDate=${fromDate}&toDate=${toDate}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Mark As Read
export const Markread = async (Payload,token) => {
  const response = await axios
    .put(`${APIBaseUrl}/updatenotification?token=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};