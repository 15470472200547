import React, { useEffect } from 'react';
import Footer from "../Components/Footer/Footer";
import Navigation from "../Components/Navbar/Navigation";
import BSApartmentBanner from "../Components/Buy_Sell/BSApartmentBanner";
import BSApartmentSpecific from "../Components/Buy_Sell/BSApartmentSpecific";
export default function BSApartmentDetails() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="innerpages-nav">
      <div id="container">
      <Navigation />
      <BSApartmentBanner />
      <BSApartmentSpecific />
      <Footer />
      </div>
    </div>
  );
}
