import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { serviceViewLogLoggedIn, serviceViewLogNotLoggedIn } from '../../Api/Api';
import { showToastErrorMsg, showToastSuccessMsg } from '../../Store/toastMsg';

const ConsultingModal = () => {
    const [showModal, setShowModal] = useState(false);
    const [initialDelay, setInitialDelay] = useState(true);
    const state = useSelector((state) => state);

    useEffect(() => {
        const checkAuthAndLogService = async () => {
            if (state?.auth?.isLoggedIn) {
                if (state?.auth?.auth?.role === 'user') {
                    let data = {
                        "service_name": `${localStorage.getItem('category')} - ${localStorage.getItem('subcategory')} - ${localStorage.getItem('title')}`,
                        "service_id": localStorage.getItem('level2Id')
                    };
                    const token = state?.auth?.auth?.token;
                    const response = await serviceViewLogLoggedIn(data, token);
                    if (response && response.statusCode === 200) {
                        // redirectURL(response.data.url);
                    } else {
                        // showToastErrorMsg(response.message || response.error.response.data.message);
                    }
                }
            } else {
                let timer;
                if (initialDelay) {
                    timer = setTimeout(() => {
                        setShowModal(true);
                    }, 10000); // Opens after 5 seconds initially
                } else {
                    timer = setTimeout(() => {
                        setShowModal(true);
                    }, 20000); // Reopens after 7 seconds if closed
                }
                return () => clearTimeout(timer);
            }
        };
        if(!localStorage.getItem("name"))
            checkAuthAndLogService();
        else
            hitWithoutLogin();
    }, [showModal, state?.auth?.isLoggedIn]);

    useEffect(() => {
   
        if (showModal) {
          document.documentElement.classList.add("modal-open");
        } else {
          document.documentElement.classList.remove("modal-open");
        }
      }, [showModal]);

    const [formView, setFormView] = useState({
        name: '',
        email: '',
        mobile: ''
    });
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let isValid = true;
        let errors = { name: '', mobile: '' };

        if (!formView.name.trim()) {
            errors.name = 'Name is required';
            isValid = false;
        }

        if (!/^\d{10}$/.test(formView.mobile.trim())) {
            errors.mobile = 'Mobile number must be 10 digits';
            isValid = false;
        }

        setErrors(errors);
        return isValid;
    };

    const handleLogInputChange = (e) => {
        const { name, value } = e.target;
        setFormView({
            ...formView,
            [name]: value
        });
    };

    const handleLogSubmit = async () => {
        if (validateForm()) {
            // Create JSON object from form data
            const jsonData = {
                name: formView.name,
                email_id: formView.email || null,
                phone_number: formView.mobile,
                service_name: `${localStorage.getItem('category')} - ${localStorage.getItem('subcategory')} - ${localStorage.getItem('title')}`,
                service_id: localStorage.getItem('level2Id')
            };
            const response = await serviceViewLogNotLoggedIn(jsonData);
            if (response && response.statusCode === 200) {
                localStorage.setItem("name",formView.name);
                localStorage.setItem("email",formView.email || null);
                localStorage.setItem("mobile",formView.mobile);
                showToastSuccessMsg(response.message || response.error.response.data.message);
                setFormView({
                    name: '',
                    email: '',
                    mobile: ''
                });
                setShowModal(false);
            } else {
                showToastErrorMsg(response.message || response.error.response.data.message);
            }
        }
    };

    const hitWithoutLogin = async () => {
        const jsonData = {
            name: localStorage.getItem("name"),
            email_id: localStorage.getItem("email"),
            phone_number: localStorage.getItem("name"),
            service_name: `${localStorage.getItem('category')} - ${localStorage.getItem('subcategory')} - ${localStorage.getItem('title')}`,
            service_id: localStorage.getItem('level2Id')
        };
        const response = await serviceViewLogNotLoggedIn(jsonData);
        if (response && response.statusCode === 200) {
            
        } else {
        }
    };

    
    const handleClose = () => {
        setShowModal(false);
        setInitialDelay(false);
    };

    return (
        <>
            <div
                className={`modal fade ${showModal ? 'show' : ''} m-auto`}
                style={{ display: showModal ? 'block' : 'none' }}
                tabIndex="-1"
                aria-labelledby="consultingModalLabel"
                aria-hidden={!showModal}
                role="dialog"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="consultingModalLabel">
                                To Know More & Get Free Consulting with our experts
                            </h5>
                            {initialDelay && (
                                <button type="button" className="close" onClick={handleClose} aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            )}
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label htmlFor="formName">Name</label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                        id="formName"
                                        placeholder="Name"
                                        name="name"
                                        value={formView.name}
                                        onChange={handleLogInputChange}
                                    />
                                    <div className="invalid-feedback">{errors.name}</div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formEmail">Email Id (Optional)</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="formEmail"
                                        placeholder="Email Id"
                                        name="email"
                                        value={formView.email}
                                        onChange={handleLogInputChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formMobile">Mobile Number</label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.mobile ? 'is-invalid' : ''}`}
                                        id="formMobile"
                                        placeholder="Mobile Number"
                                        name="mobile"
                                        value={formView.mobile}
                                        onChange={handleLogInputChange}
                                    />
                                    <div className="invalid-feedback">{errors.mobile}</div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={handleLogSubmit}>
                                Yes, I am in!
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <style jsx>{`
                .modal-content {
                    background-color: #fff;
                    border-radius: 10px;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
                    z-index: 1050; /* Bootstrap default z-index for modals */
                }
                .modal-header {
                    border-bottom: 1px solid #dee2e6;
                    padding: 1rem 1rem;
                }
                .modal-body {
                    padding: 1rem 1rem;
                }
                .modal-footer {
                    border-top: 1px solid #dee2e6;
                    padding: 1rem;
                }
                .form-control {
                    margin-bottom: 1rem;
                    padding: 0.75rem;
                    border: 1px solid #ced4da;
                    border-radius: 5px;
                    box-shadow: none;
                    transition: border-color 0.2s;
                }
                .form-control:focus {
                    border-color: #80bdff;
                    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
                }
                .form-label {
                    margin-bottom: 0.5rem;
                }
                .close {
                    background: none;
                    border: none;
                    font-size: 1.5rem;
                    position: absolute;
                    right: 1rem;
                    top: 1rem;
                }
                .modal-open {
                    overflow: hidden;
                    height: 100%;
                }
                .fade.show {
                    display: block !important;
                }
            `}</style>
        </>
    );
};

export default ConsultingModal;
