import React, { useEffect, useState } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";
import MUIDataTable from "mui-datatables";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  FetchtotalTaskDashboardDate,
  FetchtotalTaskDashboards,
  GetAdminDashBoardTotal,
  GetAdminServicesRequest,
  GetAdminServicesRequestDate,
  GetAllSubAdminAndAdmin,
  GetDashBoardUser,
  GetDashBoardUserDate,
  GetDashBoardpackage,
  GetDashBoardpackageDate,
  GetDashUserServicesReq,
  GetDashUserServicesReqDate,
  GetEmployeeDashBoardLead,
  GetEmployeeDashBoardLeadDate,
  GetEmployeeDashBoardMyTask,
  GetEmployeeDashBoardMyTaskDate,
  GetEmployeeDashBoardSR,
  GetEmployeeDashBoardSRDate,
  GetEmployeeDashBoardTotal,
  GetEmployeeDashBoardTotalDate,
  GetPackageBuyer,
  GetPackageBuyerDate,
  GetRevenueDash,
  GetRevenueDashDate,
  GetTeamMember,
  GetTeamMemberDate,
  ListingBasedOnRole,
  OverDueTask,
  OverDueTaskDate,
  ServiceBuyerUser,
  ServiceBuyerUserDate,
  getCustomPackageBuyer,
  getCustomPackageBuyerDate,
  getMyTaskDashboard,
  getMyTaskDashboardDate,
  getUserExpiryDatePcakage,
  getUserExpiryDatePcakageDate,
  getUserPackageExpiry,
  getUserPackageExpiryDate,
} from "../../../Api/Api";
import Chart from "react-google-charts";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import UserManagementComponent from "../UserManagement/UserMangementComponent";

export const piechartoptions = {
  title: "Total Leads",
  pieHole: 0.4,
  is3D: false,
  // colors: ["#11D1B7", "#695EEF", "#FFC061", "#FF7F41"],
  colors: ["#11D1B7", "#695EEF", "#FFC061", "#FF7F41", "red"],
};
export const piechartoptionreq = {
  title: "Service Request",
  pieHole: 0.4,
  is3D: false,
  // colors: ["#11D1B7", "#695EEF", "#FFC061", "#FF7F41"],
  colors: ["#11D1B7", "#695EEF", "#FFC061", "#FF7F41", "red"],
};
export const piechartoptions1 = {
  title: "Total My Task",
  pieHole: 0.4,
  is3D: false,
  // colors: ["#11D1B7", "#695EEF", "#FFC061", "#FF7F41"],
  colors: ["#11D1B7", "#695EEF", "#FFC061", "#FF7F41"],
};
// export const piechartrevenue = {
//   title: "Dalmaf Revenue",
//   pieHole: 0.4,
//   is3D: false,
//   // colors: ["#11D1B7", "#695EEF", "#FFC061", "#FF7F41"],
//   colors: ["#11D1B7", "#695EEF", "#FFC061", "#FF7F41"],
// };
export const piechartoptions2 = {
  title: "Total My Task",
  pieHole: 0.4,
  is3D: false,
  // colors: ["#11D1B7", "#695EEF", "#FFC061", "#FF7F41"],
  colors: ["#11D1B7", "#695EEF", "#FFC061", "#FF7F41", "orange"],
};
export const totaltaskpie = {
  title: "Total Task",
  pieHole: 0.4,
  is3D: false,
  colors: ["#11D1B7", "#695EEF", "#FFC061", "#FF7F41"],
  // colors: ["#11D1B7", "#695EEF", "#FFC061", "red"],
};
const Dashboard = () => {
  const state = useSelector((state) => state);
  const navigate = useNavigate();

  const [userDashBoard, setUserDashBoard] = useState({});

  //  const handleTimePeriodChange = (e) => {
  //    const selectedValue = e.target.value;
  //    setFilterBy(selectedValue);

  const defaultSelect = 'lastThreeMonth'
  useEffect(() => {
    window.scroll(0, 0);
    fetchServiceReq(defaultSelect);
    getAllUser();
    fetchuserDashboardData(defaultSelect);
    fetchPackage(defaultSelect);
    fetchEmployeeDashboardSR(defaultSelect);
    fetchEmployeeDashboardLead(defaultSelect);
    fetchEmployeeDashboardMyTask(defaultSelect);
    fetchEmployeeDashboardTotal(defaultSelect);
    fetchAdminDashcount();
    fetchTeamMembers(defaultSelect);
    fetchServiceRequest(defaultSelect);
    fetchPackageBuyer(defaultSelect);
    fetchMytask(defaultSelect);
    getCustomPackage(defaultSelect);
    getPackageUserExpiry(defaultSelect);
    GetExpiryDatePackage(defaultSelect);
    fetchuserDashboardData(defaultSelect);
    getOverDueTask(defaultSelect);
    getServiceBuyerUser(defaultSelect);
    fetchTasktotalcount(defaultSelect);
    fetchRevenue(defaultSelect);
    GetAllSubadmins();
  }, []);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [dateModal, setDateModal] = useState(false);

  const [revenue, setRevenue] = useState([]);
  const [revenue1, setRevenue1] = useState([]);

  async function fetchRevenue(value, fDate, Edate) {
    try {
      const token = state?.auth?.auth?.token;
      let data;
      if (value === "custom") {
        data = await GetRevenueDashDate(token, value, fDate, Edate);
      } else {
        data = await GetRevenueDash(token, value);
      }

      if (data && data?.statusCode === 200) {
        // console.log(data?.data,'155')
        setRevenue1(data.data);
        const temp = data?.data?.map((request) => ({
          _id: request._id,
          status: request.status,
          userId:request?.userId,
          purchaseType: request.purchaseType,
          gstPercentage: request?.userId.gstPercentage,
          package_periods: request?.userId?.package_periods,
          price: request.price,
          name: request?.userDetailsName,
          // email: request?.userId.email,
          Designation: request?.refferedByDesigination,
          updatedAt: moment(request?.userId.updatedAt).format("DD/MM/YYYY"),
          createdAt: moment(request?.userId.createdAt).format("DD/MM/YYYY"),
        }));

        setRevenue(temp);
        ;
      } else {
        setRevenue([]);
        ;
        //console.log("Error while getting user profile info");
      }
    } catch (error) {
      //console.log("Error:", error);
      ;
    }
  }

  //console.log(revenue1);

  const columnrevenue = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options:{
        customBodyRender: (value, meta)=>{
          const filterUserId = revenue?.find(item=> item?._id === meta?.rowData[0])?.userId
          // console.log(filterUserId,'filterUserId')
          return <p className="fw-bold" onClick={()=>navigate(`/UserProfileDetails/${filterUserId}`)} style={{
            cursor:"pointer"
          }}>{value}</p>
        }
      }
    },
    {
      name: "Designation",
      label: "Designation",
    },

    {
      name: "status",
      label: "Status",
    },
    {
      name: "price",
      label: "Price",
    },

    {
      name: "createdAt",
      label: "Created At",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
  
  ];

  async function fetchuserDashboardData(value, fDate, Edate) {
    try {
      
      const token = state?.auth?.auth?.token;

      let data;
      if (value === "custom") {
        data = await GetDashBoardUserDate(token, value, fDate, Edate);
      } else {
        data = await GetDashBoardUser(token, value);
      }

      if (data && data?.statusCode === 200) {
        setUserDashBoard(data.data);
        ;
      } else {
        setUserDashBoard({});
        ;
        //console.log("error while getting user profile info");
      }
    } catch (error) {
      //console.log("Error:", error);
      ;
    }
  }
  const [serviceRequest, setServiceRequest] = useState([]);
  const [serviceRequest1, setServiceRequest1] = useState([]);
  async function fetchServiceRequest(value, fDate, Edate) {
    try {
      
      const token = state?.auth?.auth?.token;
      let data;
      if (value === "custom") {
        data = await GetAdminServicesRequestDate(token, value, fDate, Edate);
      } else {
        data = await GetAdminServicesRequest(token, value);
      }

      if (data && data?.statusCode === 200) {
        setServiceRequest1(data.data);
        // console.log(data.data, "280");
        const temp = data.data.totalServicesRequestCount.map((request) => ({
          _id: request._id,
          name: request.name,
          email: request.email,
          mobileNumber: request.mobileNumber,

          subject: request.subject,
          type: request.type,
          servicesCategory: request.servicesCategory,
          servicesSubcategory: request.servicesSubcategory,
          servicesTitle: request.servicesTitle,
          serviceRequestId: request.serviceRequestId,
          updatedAt: moment(request.updatedAt).format("DD/MM/YYYY"),
          createdAt: moment(request.createdAt).format("DD/MM/YYYY"),
        }));

        setServiceRequest(temp);
        ;
      } else {
        setServiceRequest([]);
        ;
        //console.log("error while getting user profile info");
      }
    } catch (error) {
      //console.log("Error:", error);
      ;
    }
  }
  //console.log(serviceRequest1);
  const [empoyeeSR, setEmployeeSR] = useState({});
  const [empoyeeSRTable, setEmployeeSRTable] = useState([]);
  async function fetchEmployeeDashboardSR(value, fDate, Edate) {
    try {
      
      const token = state?.auth?.auth?.token;
      let data;
      if (value === "custom") {
        data = await GetEmployeeDashBoardSRDate(token, value, fDate, Edate);
      } else {
        data = await GetEmployeeDashBoardSR(token, value);
      }

      if (data && data?.statusCode === 200) {
        setEmployeeSR(data.data);
        // console.log(data.data);
        setEmployeeSRTable(data.data?.totalServicesRequestCount);
        ;
      } else {
        setEmployeeSRTable([]);
        setEmployeeSR({});
        ;
        //console.log("error while getting user profile info");
      }
    } catch (error) {
      //console.log("Error:", error);
      ;
    }
  }

  const [empoyeeLead, setEmployeeLead] = useState({});
  const [empoyeeLeadTable, setEmployeeLeadTable] = useState([]);
  async function fetchEmployeeDashboardLead(value, fDate, Edate) {
    try {
      
      const token = state?.auth?.auth?.token;
      let data;
      if (value === "custom") {
        data = await GetEmployeeDashBoardLeadDate(token, value, fDate, Edate);
      } else {
        data = await GetEmployeeDashBoardLead(token, value);
      }

      if (data && data?.statusCode === 200) {
        setEmployeeLead(data.data);
        setEmployeeLeadTable(data.data?.totalLeadCount);
        ;
      } else {
        setEmployeeLead({});
        setEmployeeLeadTable([]);
        ;
        //console.log("error while getting user profile info");
      }
    } catch (error) {
      //console.log("Error:", error);
      ;
    }
  }

  const [empoyeeMyTask, setEmployeeMyTask] = useState({});
  const [empoyeeMyTaskTable, setEmployeeMyTaskTable] = useState([]);
  async function fetchEmployeeDashboardMyTask(value, fDate, Edate) {
    try {
      
      const token = state?.auth?.auth?.token;

      let data;
      if (value === "custom") {
        data = await GetEmployeeDashBoardMyTaskDate(token, value, fDate, Edate);
      } else {
        data = await GetEmployeeDashBoardMyTask(token, value);
      }

      if (data && data?.statusCode === 200) {
        setEmployeeMyTask(data.data);
        setEmployeeMyTaskTable(data.data?.totalTaskCount);
        ;
      } else {
        setEmployeeMyTask({});
        setEmployeeMyTaskTable([]);
        ;
        //console.log("error while getting user profile info");
      }
    } catch (error) {
      //console.log("Error:", error);
      ;
    }
  }

  const [empoyeeTotal, setEmployeeTotal] = useState({});
  async function fetchEmployeeDashboardTotal(value, fDate, Edate) {
    try {
      
      const token = state?.auth?.auth?.token;

      let data;
      if (value === "custom") {
        data = await GetEmployeeDashBoardTotalDate(token, value, fDate, Edate);
      } else {
        data = await GetEmployeeDashBoardTotal(token, value);
      }

      if (data && data?.statusCode === 200) {
        setEmployeeTotal(data.data);
        ;
      } else {
        setEmployeeTotal({});
        ;
        //console.log("error while getting user profile info");
      }
    } catch (error) {
      //console.log("Error:", error);
      ;
    }
  }

  const [serviceReqDash, setServiceReqDash] = useState([]);

  async function fetchServiceReq(value, fDate, Edate) {
    try {
      
      const token = state?.auth?.auth?.token;
      let data;
      if (value === "custom") {
        data = await GetDashUserServicesReqDate(token, value, fDate, Edate);
      } else {
        data = await GetDashUserServicesReq(token, value);
      }

      if (data && data?.statusCode === 200) {
        setServiceReqDash(data.data);
        ;
      } else {
        setServiceReqDash({});
        ;
        //console.log("error while getting user profile info");
      }
    } catch (error) {
      //console.log("Error:", error);
      ;
    }
  }

  const [packageExpiry, setPackageExpiry] = useState([]);

  async function fetchPackage(value, fDate, Edate) {
    try {
      
      const token = state?.auth?.auth?.token;

      let data;
      if (value === "custom") {
        data = await GetDashBoardpackageDate(token, value, fDate, Edate);
      } else {
        data = await GetDashBoardpackage(token, value);
      }

      if (data && data?.statusCode === 200) {
        let data1 = data?.data.filter((e) => e.customPackage === value);
        let temp = [];
        data1?.map((data, i) => {
          let dummy = {
            _id: data?._id,
            userId: data?.userId,
            packagePurchaseId: data?.packagePurchaseId,
            price: data?.price,

            purchaseType: data?.purchaseType,
            status: data?.status,

            expiry_date: moment(data?.expiry_date).format("DD/MM/YYYY"),
            createdAt: moment(data?.createdAt).format("DD/MM/YYYY"),
            updatedAt: moment(data?.updatedAt).format("DD/MM/YYYY"),

            orderId: data?.orderId,
          };
          temp.push(dummy);
          return null;
        });
        setPackageExpiry(temp);
        ;
      } else {
        setPackageExpiry([]);
        ;
        //console.log("error while getting user profile info");
      }
    } catch (error) {
      //console.log("Error:", error);
      ;
    }
  }
  const [alluser, setAllUser] = useState([]);

  const getAllUser = async () => {
    const token = state?.auth?.auth?.token;

    const data = await ListingBasedOnRole(token, "user");
    if (data && data.statusCode === 200) {
      setAllUser(data?.data);
    } else {
      setAllUser([]);
      //console.log("Error while getting user details");
    }
  };

  const [myTask, setMytask] = useState([]);

  async function fetchMytask(value, fDate, Edate) {
    try {
      
      const token = state?.auth?.auth?.token;

      let data;
      if (value === "custom") {
        data = await getMyTaskDashboardDate(token, value, fDate, Edate);
      } else {
        data = await getMyTaskDashboard(token, value);
      }

      if (data && data?.statusCode === 200) {
        let temp = [];
        data.data.myTask.map((task) => {
          let dummy = {
            _id: task._id,
            taskType: task.taskType,
            taskName: task.taskName,
            taskDescription: task.taskDescription,
            resolutionComment: task.resolutionComment,
            status: task.status,
            startDate: moment(task.startDate).format("DD/MM/YYYY"),
            deadlineDate: moment(task.deadlineDate).format("DD/MM/YYYY"),
          };
          temp.push(dummy);
          return null;
        });

        setMytask(temp);
        ;
      } else {
        setMytask([]);
        ;
        //console.log("error while getting user profile info");
      }
    } catch (error) {
      //console.log("Error:", error);
      ;
    }
  }

  const columnpackage = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "userId",
      label: "User Name",
      options: {
        customBodyRender: (value) => {
          return (
            <div>
              {alluser
                ?.filter((e) => e._id === value)
                .map((i) => (
                  <div>{i.name}</div>
                ))}
            </div>
          );
        },
      },
    },
    {
      name: "packagePurchaseId",
      label: "Package Purchase-Id",
    },
    {
      name: "price",
      label: "Price",
    },
    {
      name: "purchaseType",
      label: "Purchase-Type",
    },
    {
      name: "orderId",
      label: "Order-Id",
    },

    {
      name: "status",
      label: "status",
    },
    {
      name: "expiry_date",
      label: "expiry-date",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "createdAt",
      label: "Created At",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "updatedAt",
      label: "Updated At",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
  };

  function handleRowClick(rowData, rowMeta) {
    console.log(serviceRequest);
    const id = serviceRequest[rowMeta?.dataIndex]?.serviceRequestId;
    navigate(`/ServiceRequestEdit/${id}`);
  }
  function handleRowClick1(rowData, rowMeta) {
    const id = empoyeeMyTaskTable[rowMeta?.dataIndex]?._id;
    console.log(empoyeeMyTaskTable);
    navigate(`/MyTaskEdit/${id}`);
  }
  function handleRowClick2(rowData, rowMeta) {
    const id = myTask[rowMeta?.dataIndex]?._id;
    console.log(myTask);
    navigate(`/MyTaskEdit/${id}`);
  }

  const options_cus = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    onRowClick: handleRowClick,
    setRowProps: () => {
      return {
        style: { cursor: "pointer" },
      };
    },
  };
  const options_cus1 = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    onRowClick: handleRowClick1,
    setRowProps: () => {
      return {
        style: { cursor: "pointer" },
      };
    },
  };
  const options_cus2 = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    onRowClick: handleRowClick2,
    setRowProps: () => {
      return {
        style: { cursor: "pointer" },
      };
    },
  };

  const [totalTaskDashboard, settotalTaskDashboard] = useState([]);

  async function fetchTasktotalcount(value, fDate, Edate) {
    try {
      
      const token = state?.auth?.auth?.token;

      let data;
      if (value === "custom") {
        data = await FetchtotalTaskDashboardDate(token, value, fDate, Edate);
      } else {
        data = await FetchtotalTaskDashboards(token, value);
      }

      if (data && data?.statusCode === 200) {
        settotalTaskDashboard(data.data);
        ;
      } else {
        settotalTaskDashboard({});
        ;
        //console.log("error while getting user profile info");
      }
    } catch (error) {
      //console.log("Error:", error);
      ;
    }
  }
  let piechartdata = [
    ["Task", "Hours per Day"],
    ["Converted", empoyeeLead?.convertedPercentage],
    ["Open", empoyeeLead?.openPercentage],
    ["In Progress", empoyeeLead?.inprogressPercentage],
    ["Hold", empoyeeLead?.holdPercentage],
    ["Rejected", empoyeeLead?.rejectedPercentage],
  ];
  let piechartdata1 = [
    ["Task", "Hours per Day"],
    ["To Do", empoyeeMyTask?.TodoPercentage],
    ["In Progress", empoyeeMyTask?.InprogressPercentage],
    ["Hold", empoyeeMyTask?.HoldPercentage],
    ["Completed", empoyeeMyTask?.CompletedPercentage],
  ];
  let piechartdata2 = [
    ["Task", "Hours per Day"],
    ["Un Assigned", empoyeeSR?.unassignedPercentage],
    ["Assigned", empoyeeSR?.assignedPercentage],
    ["To Do", empoyeeSR?.ToDoPercentage],
    ["In Progress", empoyeeSR?.inprogressPercentage],
    ["Completed", empoyeeSR?.completedPercentage],
  ];

  const columnLead = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "clientName",
      label: "Client Name",
    },
    {
      name: "serviceRequest",
      label: "Service Request",
    },
    {
      name: "createdAt",
      label: "Created At",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return <div>{moment(value).format("DD/MM/YYYY")}</div>;
        },
      },
    },
    { name: "status", label: "Status" },
  ];
  let piechartdata3 = [
    ["Task", "Hours per Day"],
    ["Un Assigned", serviceRequest1?.unassignedPercentage],
    ["Assigned", serviceRequest1?.assignedPercentage],
    ["ToDo", serviceRequest1?.ToDoPercentage],

    ["completed", serviceRequest1?.completedPercentage],
    ["inprogress", serviceRequest1?.inprogressPercentage],
  ];
  let piechartdata4 = [
    ["Task", "Hours per Day"],
    ["User Purchase Services", revenue1?.userpurchaseservicespercentage],
    ["User Package", revenue1?.userPackagepercentage],
    ["User Custom Package", revenue1?.userCustompackagepercentage],
  ];
  let piechartTotalTask = [
    ["Task", "Hours per Day"],

    ["ToDo", totalTaskDashboard?.TodoPercentage],
    ["inprogress", totalTaskDashboard?.InprogressPercentage],
    ["completed", totalTaskDashboard?.CompletedPercentage],
    ["Hold", totalTaskDashboard?.HoldPercentage],
  ];

  const columnMyTask = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "taskName",
      label: "Task Name",
    },
    // {
    //   name: "assignedTo",
    //   label: "Assigned To",
    // },
    // {
    //   name: "assignedBy",
    //   label: "Assigned By",
    // },
    {
      name: "startDate",
      label: "Start Date",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return <div>{moment(value).format("DD/MM/YYYY")}</div>;
        },
      },
    },
    {
      name: "deadlineDate",
      label: "Dead Line",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return <div>{moment(value).format("DD/MM/YYYY")}</div>;
        },
      },
    },
    { name: "status", label: "Status" },
  ];

  const columnSR = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "name",
      label: "Name",
    },
    {
      name: "servicesCategory",
      label: "Services Category",
    },

    {
      name: "createdAt",
      label: "Created At",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return <div>{moment(value).format("DD/MM/YYYY")}</div>;
        },
      },
    },
    { name: "status", label: "Status" },
  ];
  const columnmembers = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    { name: "name", label: "Name" },
    { name: "email", label: "E-mail" },
    { name: "phoneNumber", label: "Phone Number" },
    // { name: "employeeAddress", label: "Address" },
    // { name: "gender", label: "Gender" },
    // { name: "Designation", label: "Designation" },
    { name: "role", label: "Role" },
    {
      name: "lastLogin",
      label: "Last Login ",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return <div>{value}</div>;
        },
      },
    },
  ];

  const columnServiceRequest = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "name",
      label: "Name",
      // options: {
      //   customBodyRender: (value, tableMeta) => {
      //     return (
      //       <div
      //         style={{ textTransform: "capitalize", cursor: "pointer" }}
      //         onClick={(val) => {
      //           // getServiceReqParticular(tableMeta.rowData[0]);
      //           // if (tableMeta.rowData[7] === false) {
      //           //   handleSubmitUpdate(val, tableMeta.rowData[0], "admin");
      //           // } else if (tableMeta.rowData[8] === false) {
      //           //   handleSubmitUpdate(val, tableMeta.rowData[0], "subadmin");
      //           // }
      //           // setViewDetails(false);
      //           console.log(tableMeta);
      //           // navigate(`/ServiceRequestEdit/${tableMeta.rowData[0]}`);
      //         }}
      //       >
      //         {value}
      //       </div>
      //     );
      //   },
      // },
    },
    { name: "email", label: "E-mail" },
    { name: "mobileNumber", label: "mobileNumber" },
    { name: "subject", label: "Subject" },
    { name: "type", label: "Type" },
    { name: "servicesCategory", label: "Services Category" },
    { name: "servicesSubcategory", label: "Services Subcategory" },
    { name: "servicesTitle", label: "Services Title" },

    {
      name: "updatedAt",
      label: "Updated At ",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "createdAt",
      label: "Created At ",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return <div>{value}</div>;
        },
      },
    },
  ];

  const columnsMytaskDashbord = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    { name: "taskType", label: "Task Type" },
    { name: "status", label: "status" },

    { name: "taskName", label: "Task Name" },
    { name: "taskDescription", label: "Task Description", options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return <div dangerouslySetInnerHTML={{__html:value}}></div>;
        },
      } },
    { name: "resolutionComment", label: "Resolution Comment" , options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return <div dangerouslySetInnerHTML={{__html:value}}></div>;
        },
      }},
    {
      name: "startDate",
      label: " Start Date ",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "deadlineDate",
      label: " deadline Date ",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return <div>{value}</div>;
        },
      },
    },
  ];

  const columnsbuyer = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "name",
      label: "Name",
    },
    {
      name: "customPackage",
      label: "Custom Package",
    },
    {
      name: "package_periods",
      label: "Package-Periods",
    },
    {
      name: "toWhom",
      label: "Services",
    },
    {
      name: "gstPercentage",
      label: "GST Percentage",
    },
    {
      name: "status",
      label: "Status",
    },

    {
      name: "purchaseType",
      label: "Purchase Type",
    },
    {
      name: "price",
      label: "Price",
    },

    {
      name: "createdAt",
      label: "Created At",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
  ];

  const columnsPackageUser = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "purchaseType",
      label: "Purchase-Type",
    },
    {
      name: "name",
      label: "Name",
    },
    {
      name: "Designation",
      label: "Designation",
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "phoneNumber",
      label: "Phone-Number",
    },
    {
      name: "razorpay_payment_id",
      label: "Razorpay-Payment-Id",
    },
    {
      name: "price",
      label: "Price",
    },
    {
      name: "lastLogin",
      label: "Last-Login",
    },
  ];
  const columnsUserPackExpiry = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "name",
      label: "Name",
    },
    {
      name: "email",
      label: "E-mail",
    },
    {
      name: "Designation",
      label: "Designation",
    },

    {
      name: "price",
      label: "Price",
    },

    {
      name: "status",
      label: "status",
    },
    {
      name: "phoneNumber",
      label: "Phone-Number",
    },
  ];
  const [adminDashCount, setAdminDashCount] = useState({});
  async function fetchAdminDashcount() {
    try {
      

      const token = state?.auth?.auth?.token;
      let data = await GetAdminDashBoardTotal(token);
      // if (value === "custom") {
      //   data = await GetAdminDashBoardTotalDate(token, value, fDate, Edate);
      // } else {
      //   data = await GetAdminDashBoardTotal(token, value);
      // }

      if (data && data?.statusCode === 200) {
        setAdminDashCount(data.data);
        ;
      } else {
        setAdminDashCount({});
        ;
        //console.log("error while getting user profile info");
      }
    } catch (error) {
      //console.log("Error:", error);
      ;
    }
  }

  const [TeamMemberData, SetTeamMemberData] = useState([]);

  async function fetchTeamMembers(value, fDate, Edate) {
    try {
      
      const token = state?.auth?.auth?.token;
      let data;
      if (value === "custom") {
        data = await GetTeamMemberDate(token, value, fDate, Edate);
      } else {
        data = await GetTeamMember(token, value);
      }

      if (data && data?.statusCode === 200) {
        // console.log(data);
        let temp = [];
        data.data.map((data) => {
          let dummy = {
            _id: data?._id,
            name: data?.name,
            email: data?.email,
            phoneNumber: data?.phoneNumber,
            employeeAddress: data?.employeeAddress,

            // purchaseType: data?.purchaseType,
            gender: data?.gender,
            Designation: data?.Designation,
            role: data?.role,

            lastLogin: moment(data?.lastLogin).format("DD/MM/YYYY"),
          };
          temp.push(dummy);
          return null;
        });
        SetTeamMemberData(temp);
        ;
      } else {
        SetTeamMemberData([]);
        ;
        //console.log("error while getting user profile info");
      }
    } catch (error) {
      //console.log("Error:", error);
      ;
    }
  }

  const [packageBuyer, setPackageBuyer] = useState([]);

  async function fetchPackageBuyer(value, fDate, Edate) {
    try {
      
      const token = state?.auth?.auth?.token;

      let data;
      if (value === "custom") {
        data = await GetPackageBuyerDate(token, value, fDate, Edate);
      } else {
        data = await GetPackageBuyer(token, value);
      }

      if (data && data?.statusCode === 200) {
        let temp = [];
        data.data.map((data) => {
          let dummy = {
            _id: data?._id,
            status: data?.status,
            purchaseType: data?.purchaseType,
            price: data?.price,
            customPackage: data?.packagePurchaseId?.customPackage,
            package_periods: data?.packagePurchaseId?.package_periods,
            toWhom: data?.packagePurchaseId?.toWhom,
            gstPercentage: data?.packagePurchaseId?.gstPercentage,
            name: data?.userId?.name,

            createdAt: moment(data?.createdAt).format("DD/MM/YYYY"),
          };
          temp.push(dummy);
          return null;
        });
        setPackageBuyer(temp);
        ;
      } else {
        setPackageBuyer([]);
        ;
        //console.log("error while getting user profile info");
      }
    } catch (error) {
      //console.log("Error:", error);
      ;
    }
  }
  const [customPackageUser, setCustomPackage] = useState([]);

  const getCustomPackage = async (value, fDate, Edate) => {
    const token = state?.auth?.auth?.token;

    let data;
    if (value === "custom") {
      data = await getCustomPackageBuyerDate(token, value, fDate, Edate);
    } else {
      data = await getCustomPackageBuyer(token, value);
    }

    if (data && data.statusCode === 200) {
      let temp = [];
      data.data.map((data) => {
        let dummy = {
          _id: data?._id,
          purchaseType: data?.purchaseType,
          name: data?.userId?.name,
          Designation: data?.userId?.Designation,
          email: data?.userId?.email,

          phoneNumber: data?.userId?.phoneNumber,
          razorpay_payment_id: data?.paymentInfo?.razorpay_payment_id,
          price: data?.price,

          lastLogin: moment(data?.userId?.lastLogin).format("DD/MM/YYYY"),
        };
        temp.push(dummy);
        return null;
      });
      setCustomPackage(temp);
    } else {
      setCustomPackage([]);
      //console.log("Error while getting user details");
    }
  };

  const [packageUserExpiry, setPackageUserExpiry] = useState([]);

  const getPackageUserExpiry = async (value, fDate, Edate) => {
    const token = state?.auth?.auth?.token;

    let data;
    if (value === "custom") {
      data = await getUserPackageExpiryDate(token, value, fDate, Edate);
    } else {
      data = await getUserPackageExpiry(token, value);
    }

    if (data && data.statusCode === 200) {
      let temp = [];
      data.data.map((data) => {
        let dummy = {
          _id: data?._id,
          status: data?.status,

          gstPercentage: data?.PackageInfo?.gstPercentage,
          price: data?.price,

          name: data?.userId?.name,
          email: data?.userId?.email,
          Designation: data?.userId?.Designation,
          phoneNumber: data?.userId?.phoneNumber,
        };

        temp.push(dummy);
        return null;
      });
      setPackageUserExpiry(temp);
    } else {
      setPackageUserExpiry([]);
      //console.log("Error while getting user details");
    }
  };

  const [userExpiryDatePackage, setUserExpiryDatePackage] = useState([]);

  const GetExpiryDatePackage = async (value, fDate, Edate) => {
    const token = state?.auth?.auth?.token;

    let data;
    if (value === "custom") {
      data = await getUserExpiryDatePcakageDate(token, value, fDate, Edate);
    } else {
      data = await getUserExpiryDatePcakage(token, value);
    }

    if (data && data.statusCode === 200) {
      let temp = [];
      data.data.map((data) => {
        let dummy = {
          _id: data?._id,
          status: data?.status,

          purchaseType: data?.purchaseType,

          customPackage: data?.PackageInfo?.customPackage,
          package_periods: data?.PackageInfo?.package_periods,

          gstPercentage: data?.PackageInfo?.gstPercentage,
          price: data?.price,

          name: data?.userId?.name,
          email: data?.userId?.email,
          Designation: data?.userId?.Designation,

          expiry_date: moment(data?.expiry_date).format("DD/MM/YYYY"),
        };
        temp.push(dummy);
        return null;
      });
      setUserExpiryDatePackage(temp);
    } else {
      setUserExpiryDatePackage([]);
      //console.log("Error while getting user details");
    }
  };

  const [allSubadmin, setAllSubadmin] = useState([]);
  const GetAllSubadmins = async () => {
    
    const token = state?.auth?.auth?.token;
    let data = await GetAllSubAdminAndAdmin(token);
    if (data && data.statusCode === 200) {
      setAllSubadmin(data.data);
      ;
    } else {
      setAllSubadmin([]);
      ;
      //console.log("Error while getting all subadmins");
    }
  };

  const [overdueTask, setOverdueTask] = useState([]);

  const getOverDueTask = async (value, fDate, Edate) => {
    const token = state?.auth?.auth?.token;

    let data;
    if (value === "custom") {
      data = await OverDueTaskDate(token, value, fDate, Edate);
    } else {
      data = await OverDueTask(token, value);
    }

    if (data && data.statusCode === 200) {
      let temp = [];
      data.data.map((data) => {
        let dummy = {
          _id: data?._id,
          taskName: data?.taskName,

          taskType: data?.taskType,
          status: data?.status,

          taskDescription: data?.taskDescription,
          assignedTo: allSubadmin
            ?.filter((e) => e._id === data?.assignedTo)
            .map((i) => {
              return i.name;
            })
            .toString(),
          // assignedTo: data?.assignedTo,
          deadlineDate: moment(data?.deadlineDate).format("DD/MM/YYYY"),

          createdAt: moment(data?.createdAt).format("DD/MM/YYYY"),
        };
        temp.push(dummy);
        return null;
      });
      setOverdueTask(temp);
    } else {
      setOverdueTask([]);
      //console.log("Error while getting user details");
    }
  };

  const columnsServiceBuyer = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "name",
      label: "Name",
    },
    {
      name: "email",
      label: "E-Mail",
    },
    {
      name: "status",
      label: "Status",
    },
    {
      name: "Designation",
      label: "Designation",
    },
    {
      name: "title",
      label: "Title",
    },
    {
      name: "role",
      label: "Role",
    },

    {
      name: "price",
      label: "Price",
    },
    {
      name: "customPackage",
      label: "Custom Package",
    },
    {
      name: "razorpay_payment_id",
      label: "Razorpay-Payment-Id",
    },
    {
      name: "razorpay_order_id",
      label: "Razorpay-order-Id",
    },
    {
      name: "createdAt",
      label: "Created-At",
    },
  ];

  const [serviceBuyerUser, setServiceBuyerUser] = useState([]);

  const getServiceBuyerUser = async (value, fDate, Edate) => {
    const token = state?.auth?.auth?.token;

    let data;
    if (value === "custom") {
      data = await ServiceBuyerUserDate(token, value, fDate, Edate);
    } else {
      data = await ServiceBuyerUser(token, value);
    }

    if (data && data.statusCode === 200) {
      let temp = [];
      data.data.map((data) => {
        let dummy = {
          _id: data?._id,
          purchaseType: data?.purchaseType,

          title: data?.packagePurchaseId?.title,
          status: data?.status,

          price: data?.price,
          customPackage: data?.packagePurchaseId?.customPackage,
          name: data?.userId?.name,
          razorpay_payment_id: data?.paymentInfo?.razorpay_payment_id,
          razorpay_order_id: data?.paymentInfo?.razorpay_order_id,

          email: data?.userId?.email,
          Designation: data?.userId?.Designation,
          role: data?.userId?.role,
          createdAt: moment(data?.createdAt).format("DD/MM/YYYY"),
        };
        temp.push(dummy);
        return null;
      });
      setServiceBuyerUser(temp);
    } else {
      setServiceBuyerUser([]);
      //console.log("Error while getting user details");
    }
  };
  const columnsOverDue = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "taskName",
      label: "Task-Name",
    },
    {
      name: "taskType",
      label: "task-Type",
    },
    {
      name: "status",
      label: "Status",
    },

    {
      name: "assignedTo",
      label: "Assigned To",
    },

    {
      name: "deadlineDate",
      label: "Deadline-Date",
    },
    {
      name: "createdAt",
      label: "Created-At",
    },
  ];

  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [buyerTab, setBuyerTab] = useState("1");

  const handleBuyerTabChange = (event, newValue) => {
    setBuyerTab(newValue);
  };

  return (
    <>
      <AdminNavbar title={'Dashboard'} />
      <div className="main">
        {state.auth.auth.role === "user" ? (
          <>
            <div className=" container mt-3">
              <div className="displayflexstyle">
                <h5 className="mb-0 mb-4 fw-bold color-maro">Dashboard</h5>
                <select
                  className="selectoptioncss"
                  onChange={(e) => {
                    fetchuserDashboardData(e.target.value, "", "");
                    fetchServiceReq(e.target.value, "", "");
                    fetchPackage(e.target.value, "", "");
                    if (e.target.value === "custom") {
                      setDateModal(true);
                    } else {
                      setDateModal(false);
                    }
                  }}
                >
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="week">This Week</option>
                  <option value="lastWeek">Last Week</option>
                  <option value="currentMonth">Current Month</option>
                  <option value="lastMonth">Last Month</option>
                  <option value="lastThreeMonth">Last Three Months</option>
                  <option value="lastSixMonth">Last Six Months</option>
                  <option value="lastOneYear">Last One Year</option>
                  <option value="custom">Custom</option>
                </select>
                {dateModal && (
                  <div className="custom-date-range">
                    <div className="row">
                      <div className="col-6">
                        <label className="form-label">From</label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="All"
                          required
                          id="fromDate"
                          value={fromDate}
                          onChange={(e) => {
                            setFromDate(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-3">
                        <label className="form-label">To</label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="All"
                          required
                          value={toDate}
                          // id="toDate"
                          onChange={(e) => {
                            setToDate(e.target.value);
                            fetchuserDashboardData(
                              "custom",
                              fromDate,
                              e.target.value
                            );
                            fetchServiceReq("custom", fromDate, e.target.value);
                            fetchPackage("custom", fromDate, e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="cardtable">
              <>
                <h6 className="form-bold w-100 mb-3">Service Request</h6>
                <div className="row">
                  <div className="col-lg-3 col-md-3">
                    <div className="ordercard">
                      <div className="row">
                        <div className="col-lg-10 col-10">
                          <div className="d-flex">
                            <span className="material-symbols-rounded iconcolor">
                              group
                            </span>
                            <h6 className="cardsubtitle">Total</h6>
                          </div>
                        </div>
                        <div className="col-lg-2 col-2">
                          <h6 className="numbercolor">
                            {" "}
                            {userDashBoard?.servicesRequestTotal}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3">
                    <div className="ordercard">
                      <div className="row">
                        <div className="col-lg-10 col-10">
                          <div className="d-flex">
                            <span className="material-symbols-rounded iconcolor">
                              group
                            </span>
                            <h6 className="cardsubtitle"> Unassigned</h6>
                          </div>
                        </div>
                        <div className="col-lg-2 col-2">
                          <h6 className="numbercolor">
                            {userDashBoard?.status_unassigned}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3">
                    <div className="ordercard">
                      <div className="row">
                        <div className="col-lg-10 col-10">
                          <div className="d-flex">
                            <span className="material-symbols-rounded iconcolor">
                              group
                            </span>
                            <h6 className="cardsubtitle">Inprogress</h6>
                          </div>
                        </div>
                        <div className="col-lg-2 col-2">
                          <h6 className="numbercolor">
                            {userDashBoard?.status_inprogress}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3">
                    <div className="ordercard">
                      <div className="row">
                        <div className="col-lg-10 col-10">
                          <div className="d-flex">
                            <span className="material-symbols-rounded iconcolor">
                              group
                            </span>
                            <h6 className="cardsubtitle">Assigned</h6>
                          </div>
                        </div>
                        <div className="col-lg-2 col-2">
                          <h6 className="numbercolor">
                            {userDashBoard?.status_assigned}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-lg-3 col-md-3">
                    <div className="ordercard">
                      <div className="row">
                        <div className="col-lg-10 col-10">
                          <div className="d-flex">
                            <span className="material-symbols-rounded iconcolor">
                              group
                            </span>
                            <h6 className="cardsubtitle">ToDo</h6>
                          </div>
                        </div>
                        <div className="col-lg-2 col-2">
                          <h6 className="numbercolor">
                            {userDashBoard?.status_ToDo}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3">
                    <div className="ordercard">
                      <div className="row">
                        <div className="col-lg-10 col-10">
                          <div className="d-flex">
                            <span className="material-symbols-rounded iconcolor">
                              group
                            </span>
                            <h6 className="cardsubtitle">Completed</h6>
                          </div>
                        </div>
                        <div className="col-lg-2 col-2">
                          <h6 className="numbercolor">
                            {userDashBoard?.status_completed}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dashboardborderline"></div>
                <h6 className="form-bold w-100 mb-3">Purchase Services</h6>
                <div className="row">
                  <div className="col-lg-3 col-md-3">
                    <div className="ordercard">
                      <div className="row">
                        <div className="col-lg-10 col-10">
                          <div className="d-flex">
                            <span className="material-symbols-rounded iconcolor">
                              group
                            </span>
                            <h6 className="cardsubtitle">Purchase Service</h6>
                          </div>
                        </div>
                        <div className="col-lg-2 col-2">
                          <h6 className="numbercolor">
                            {serviceReqDash?.purchaseServices}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3">
                    <div className="ordercard">
                      <div className="row">
                        <div className="col-lg-10 col-10">
                          <div className="d-flex">
                            <span className="material-symbols-rounded iconcolor">
                              group
                            </span>
                            <h6 className="cardsubtitle"> Custom Package</h6>
                          </div>
                        </div>
                        <div className="col-lg-2 col-2">
                          <h6 className="numbercolor">
                            {serviceReqDash?.purchaseCustomPackage}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3">
                    <div className="ordercard">
                      <div className="row">
                        <div className="col-lg-10 col-10">
                          <div className="d-flex">
                            <span className="material-symbols-rounded iconcolor">
                              group
                            </span>
                            <h6 className="cardsubtitle">Dalmaf Package</h6>
                          </div>
                        </div>
                        <div className="col-lg-2 col-2">
                          <h6 className="numbercolor">
                            {serviceReqDash?.purchaseAutoPackage}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </div>
            <div className="cardtable">
              <div className="scroll-table">
                <MUIDataTable
                  title={"Dalmaf - Package Expiry"}
                  data={packageExpiry}
                  columns={columnpackage}
                  options={options}
                />
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {state.auth.auth.role === "subadmin" ||
        state.auth.auth.role === "admin" ? (
          <>
            <div className=" container mt-3">
              <div className="displayflexstyle">
                <h5 className="mb-0 mb-4 fw-bold color-maro">DashBoard</h5>
                <select
                  className="selectoptioncss"
                  onChange={(e) => {
                    fetchEmployeeDashboardSR(e.target.value, "", "");
                    fetchEmployeeDashboardLead(e.target.value, "", "");
                    fetchEmployeeDashboardMyTask(e.target.value, "", "");
                    fetchEmployeeDashboardTotal(e.target.value, "", "");
                    if (e.target.value === "custom") {
                      setDateModal(true);
                    } else {
                      setDateModal(false);
                    }
                  }}
                >
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="week">This Week</option>
                  <option value="lastWeek">Last Week</option>
                  <option value="currentMonth">Current Month</option>
                  <option value="lastMonth">Last Month</option>
                  <option value="lastThreeMonth">Last Three Months</option>
                  <option value="lastSixMonth">Last Six Months</option>
                  <option value="lastOneYear">Last One Year</option>
                  <option value="custom">Custom</option>
                </select>
              </div>
              {dateModal && (
                <div className="custom-date-range">
                  <div className="row ">
                    <div className="col-lg-4">
                      <label className="form-label">From</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="All"
                        required
                        id="fromDate"
                        value={fromDate}
                        onChange={(e) => {
                          setFromDate(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-lg-4">
                      <div className="mb-3">
                        <label className="form-label">To</label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="All"
                          required
                          value={toDate}
                          // id="toDate"
                          onChange={(e) => {
                            setToDate(e.target.value);
                            fetchEmployeeDashboardSR(
                              "custom",
                              fromDate,
                              e.target.value
                            );
                            fetchEmployeeDashboardLead(
                              "custom",
                              fromDate,
                              e.target.value
                            );
                            fetchEmployeeDashboardMyTask(
                              "custom",
                              fromDate,
                              e.target.value
                            );
                            fetchEmployeeDashboardTotal(
                              "custom",
                              fromDate,
                              e.target.value
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="cardtable">
              <>
                <h6 className="form-bold w-100 mb-3">Service Request</h6>
                <div className="row">
                  <div className="col-lg-3 col-md-3">
                    <div className="ordercard">
                      <div className="row">
                        <div className="col-lg-10 col-10">
                          <div className="d-flex">
                            <span className="material-symbols-rounded iconcolor">
                              group
                            </span>
                            <h6 className="cardsubtitle">Total</h6>
                          </div>
                        </div>
                        <div className="col-lg-2 col-2">
                          <h6 className="numbercolor">
                            {" "}
                            {empoyeeTotal?.servicesRequestTotal}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3">
                    <div className="ordercard">
                      <div className="row">
                        <div className="col-lg-10 col-10">
                          <div className="d-flex">
                            <span className="material-symbols-rounded iconcolor">
                              group
                            </span>
                            <h6 className="cardsubtitle"> Unassigned</h6>
                          </div>
                        </div>
                        <div className="col-lg-2 col-2">
                          <h6 className="numbercolor">
                            {empoyeeTotal?.servicesstatus_unassigned}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3">
                    <div className="ordercard">
                      <div className="row">
                        <div className="col-lg-10 col-10">
                          <div className="d-flex">
                            <span className="material-symbols-rounded iconcolor">
                              group
                            </span>
                            <h6 className="cardsubtitle">Inprogress</h6>
                          </div>
                        </div>
                        <div className="col-lg-2 col-2">
                          <h6 className="numbercolor">
                            {empoyeeTotal?.servicesstatus_inprogress}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3">
                    <div className="ordercard">
                      <div className="row">
                        <div className="col-lg-10 col-10">
                          <div className="d-flex">
                            <span className="material-symbols-rounded iconcolor">
                              group
                            </span>
                            <h6 className="cardsubtitle">Assigned</h6>
                          </div>
                        </div>
                        <div className="col-lg-2 col-2">
                          <h6 className="numbercolor">
                            {empoyeeTotal?.servicesstatus_assigned}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-lg-3 col-md-3">
                    <div className="ordercard">
                      <div className="row">
                        <div className="col-lg-10 col-10">
                          <div className="d-flex">
                            <span className="material-symbols-rounded iconcolor">
                              group
                            </span>
                            <h6 className="cardsubtitle">ToDo</h6>
                          </div>
                        </div>
                        <div className="col-lg-2 col-2">
                          <h6 className="numbercolor">
                            {empoyeeTotal?.servicesstatus_ToDo}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3">
                    <div className="ordercard">
                      <div className="row">
                        <div className="col-lg-10 col-10">
                          <div className="d-flex">
                            <span className="material-symbols-rounded iconcolor">
                              group
                            </span>
                            <h6 className="cardsubtitle">Completed</h6>
                          </div>
                        </div>
                        <div className="col-lg-2 col-2">
                          <h6 className="numbercolor">
                            {empoyeeTotal?.completedPercentage}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dashboardborderline"></div>
              </>
            </div>
            <div className="cardtable">
              <>
                <h6 className="form-bold w-100 mb-3">Lead</h6>
                <div className="row">
                  <div className="col-lg-3 col-md-3">
                    <div className="ordercard">
                      <div className="row">
                        <div className="col-lg-10 col-10">
                          <div className="d-flex">
                            <span className="material-symbols-rounded iconcolor">
                              group
                            </span>
                            <h6 className="cardsubtitle">Total</h6>
                          </div>
                        </div>
                        <div className="col-lg-2 col-2">
                          <h6 className="numbercolor">
                            {" "}
                            {empoyeeTotal?.leadTotal}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3">
                    <div className="ordercard">
                      <div className="row">
                        <div className="col-lg-10 col-10">
                          <div className="d-flex">
                            <span className="material-symbols-rounded iconcolor">
                              group
                            </span>
                            <h6 className="cardsubtitle"> Open</h6>
                          </div>
                        </div>
                        <div className="col-lg-2 col-2">
                          <h6 className="numbercolor">
                            {empoyeeTotal?.leadstatus_open}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3">
                    <div className="ordercard">
                      <div className="row">
                        <div className="col-lg-10 col-10">
                          <div className="d-flex">
                            <span className="material-symbols-rounded iconcolor">
                              group
                            </span>
                            <h6 className="cardsubtitle">Inprogress</h6>
                          </div>
                        </div>
                        <div className="col-lg-2 col-2">
                          <h6 className="numbercolor">
                            {empoyeeTotal?.leadstatus_inprogress}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3">
                    <div className="ordercard">
                      <div className="row">
                        <div className="col-lg-10 col-10">
                          <div className="d-flex">
                            <span className="material-symbols-rounded iconcolor">
                              group
                            </span>
                            <h6 className="cardsubtitle">Converted</h6>
                          </div>
                        </div>
                        <div className="col-lg-2 col-2">
                          <h6 className="numbercolor">
                            {empoyeeTotal?.leadstatus_converted}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-lg-3 col-md-3">
                    <div className="ordercard">
                      <div className="row">
                        <div className="col-lg-10 col-10">
                          <div className="d-flex">
                            <span className="material-symbols-rounded iconcolor">
                              group
                            </span>
                            <h6 className="cardsubtitle">Hold</h6>
                          </div>
                        </div>
                        <div className="col-lg-2 col-2">
                          <h6 className="numbercolor">
                            {empoyeeTotal?.leadstatus_hold}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3">
                    <div className="ordercard">
                      <div className="row">
                        <div className="col-lg-10 col-10">
                          <div className="d-flex">
                            <span className="material-symbols-rounded iconcolor">
                              group
                            </span>
                            <h6 className="cardsubtitle">Rejected</h6>
                          </div>
                        </div>
                        <div className="col-lg-2 col-2">
                          <h6 className="numbercolor">
                            {empoyeeTotal?.leadstatus_rejected}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dashboardborderline"></div>
              </>
            </div>
            <div className="cardtable">
              <>
                <h6 className="form-bold w-100 mb-3">My Task</h6>
                <div className="row">
                  <div className="col-lg-3 col-md-3">
                    <div className="ordercard">
                      <div className="row">
                        <div className="col-lg-10 col-10">
                          <div className="d-flex">
                            <span className="material-symbols-rounded iconcolor">
                              group
                            </span>
                            <h6 className="cardsubtitle">Total</h6>
                          </div>
                        </div>
                        <div className="col-lg-2 col-2">
                          <h6 className="numbercolor">
                            {" "}
                            {empoyeeTotal?.myTaskTotal}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3">
                    <div className="ordercard">
                      <div className="row">
                        <div className="col-lg-10 col-10">
                          <div className="d-flex">
                            <span className="material-symbols-rounded iconcolor">
                              group
                            </span>
                            <h6 className="cardsubtitle"> To Do</h6>
                          </div>
                        </div>
                        <div className="col-lg-2 col-2">
                          <h6 className="numbercolor">
                            {empoyeeTotal?.taskstatus_Todo}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3">
                    <div className="ordercard">
                      <div className="row">
                        <div className="col-lg-10 col-10">
                          <div className="d-flex">
                            <span className="material-symbols-rounded iconcolor">
                              group
                            </span>
                            <h6 className="cardsubtitle">Inprogress</h6>
                          </div>
                        </div>
                        <div className="col-lg-2 col-2">
                          <h6 className="numbercolor">
                            {empoyeeTotal?.taskstatus_Inprogress}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3">
                    <div className="ordercard">
                      <div className="row">
                        <div className="col-lg-10 col-10">
                          <div className="d-flex">
                            <span className="material-symbols-rounded iconcolor">
                              group
                            </span>
                            <h6 className="cardsubtitle">Hold</h6>
                          </div>
                        </div>
                        <div className="col-lg-2 col-2">
                          <h6 className="numbercolor">
                            {empoyeeTotal?.taskstatus_Hold}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-lg-3 col-md-3">
                    <div className="ordercard">
                      <div className="row">
                        <div className="col-lg-10 col-10">
                          <div className="d-flex">
                            <span className="material-symbols-rounded iconcolor">
                              group
                            </span>
                            <h6 className="cardsubtitle">Completed</h6>
                          </div>
                        </div>
                        <div className="col-lg-2 col-2">
                          <h6 className="numbercolor">
                            {empoyeeTotal?.taskstatus_Completed}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dashboardborderline"></div>
              </>
            </div>
            <div className="cardtable">
              <>
                <h6 className="form-bold w-100 mb-3">Leave</h6>
                <div className="row">
                  <div className="col-lg-3 col-md-3">
                    <div className="ordercard">
                      <div className="row">
                        <div className="col-lg-10 col-10">
                          <div className="d-flex">
                            <span className="material-symbols-rounded iconcolor">
                              group
                            </span>
                            <h6 className="cardsubtitle">Total</h6>
                          </div>
                        </div>
                        <div className="col-lg-2 col-2">
                          <h6 className="numbercolor">
                            {" "}
                            {empoyeeTotal?.leaveCount}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="dashboardborderline"></div>
              </>
            </div>
            <div className="row">
              <div className="col-6">
                {/* <div className="cardtable"> */}
                <div className="scroll-table">
                  <MUIDataTable
                    title={"Dalmaf - Service Request"}
                    data={empoyeeSRTable}
                    columns={columnSR}
                    options={options}
                  />
                </div>
                {/* </div> */}
              </div>
              <div className="col-lg-6">
                {/* <div className="cardtable"> */}
                <table
                  className="table "
                  style={{
                    boxShadow:
                      "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
                  }}
                >
                  <thead className="bg-historytablehead">
                    <tr>
                      <th scope="col" colspan="3" style={{ borderRadius: "0" }}>
                        Service Request Status
                      </th>
                    </tr>
                  </thead>
                  <tbody className="historytablebody">
                    <tr>
                      <td colspan="3">
                        <Chart
                          chartType="PieChart"
                          width="100%"
                          height="300px"
                          data={piechartdata2}
                          options={piechartoptions2}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <p className="mb-0 violetbox-bg"> Un Assigned</p>
                      </th>
                      <td>
                        <p className="mb-0 fw-bold text-secondary">
                          {empoyeeSR?.unassignedPercentage?.toFixed()}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <p className="mb-0 yellowbox-bg"> Assigned </p>
                      </th>
                      <td>
                        <p className="mb-0 fw-bold text-secondary">
                          {empoyeeSR?.assignedPercentage?.toFixed()}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <p className="mb-0 violetbox-bg"> In Progress </p>
                      </th>
                      <td>
                        <p className="mb-0 fw-bold text-secondary">
                          {empoyeeSR?.inprogressPercentage?.toFixed()}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <p className="mb-0 redbox-bg"> To Do</p>
                      </th>
                      <td>
                        <p className="mb-0 fw-bold text-secondary">
                          {empoyeeSR?.ToDoPercentage}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <p className="mb-0 greenbox-bg"> Completed</p>
                      </th>
                      <td>
                        <p className="mb-0 fw-bold text-secondary">
                          {empoyeeSR?.completedPercentage?.toFixed()}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {/* </div> */}
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="cardtable">
                  <div className="scroll-table">
                    <MUIDataTable
                      title={"Dalmaf - Lead"}
                      data={empoyeeLeadTable}
                      columns={columnLead}
                      options={options}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="cardtable">
                  <table
                    className="table "
                    style={{
                      boxShadow:
                        "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
                    }}
                  >
                    <thead className="bg-historytablehead">
                      <tr>
                        <th
                          scope="col"
                          colspan="3"
                          style={{ borderRadius: "0" }}
                        >
                          Lead Status
                        </th>
                      </tr>
                    </thead>
                    <tbody className="historytablebody">
                      <tr>
                        <td colspan="3">
                          <Chart
                            chartType="PieChart"
                            width="100%"
                            height="300px"
                            data={piechartdata}
                            options={piechartoptions}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <p className="mb-0 greenbox-bg"> Converted</p>
                        </th>
                        <td>
                          <p className="mb-0 fw-bold text-secondary">
                            {empoyeeLead?.convertedPercentage?.toFixed()}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <p className="mb-0 violetbox-bg"> Open </p>
                        </th>
                        <td>
                          <p className="mb-0 fw-bold text-secondary">
                            {empoyeeLead?.openPercentage?.toFixed()}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <p className="mb-0 yellowbox-bg"> In Progress </p>
                        </th>
                        <td>
                          <p className="mb-0 fw-bold text-secondary">
                            {empoyeeLead?.inprogressPercentage?.toFixed()}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <p className="mb-0 violetbox-bg"> Hold</p>
                        </th>
                        <td>
                          <p className="mb-0 fw-bold text-secondary">
                            {empoyeeLead?.holdPercentage}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <p className="mb-0 redbox-bg"> Rejected</p>
                        </th>
                        <td>
                          <p className="mb-0 fw-bold text-secondary">
                            {empoyeeLead?.rejectedPercentage?.toFixed()}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="cardtable">
                  <div className="scroll-table">
                    <MUIDataTable
                      title={"Dalmaf - My Task"}
                      data={empoyeeMyTaskTable}
                      columns={columnMyTask}
                      options={options_cus1}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="cardtable">
                  <table
                    className="table "
                    style={{
                      boxShadow:
                        "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
                    }}
                  >
                    <thead className="bg-historytablehead">
                      <tr>
                        <th
                          scope="col"
                          colspan="3"
                          style={{ borderRadius: "0" }}
                        >
                          My Task Status
                        </th>
                      </tr>
                    </thead>
                    <tbody className="historytablebody">
                      <tr>
                        <td colspan="3">
                          <Chart
                            chartType="PieChart"
                            width="100%"
                            height="300px"
                            data={piechartdata1}
                            options={piechartoptions1}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <p className="mb-0 violetbox-bg"> To Do</p>
                        </th>
                        <td>
                          <p className="mb-0 fw-bold text-secondary">
                            {empoyeeMyTask?.TodoPercentage?.toFixed()}
                          </p>
                        </td>
                      </tr>

                      <tr>
                        <th>
                          <p className="mb-0 yellowbox-bg"> In Progress </p>
                        </th>
                        <td>
                          <p className="mb-0 fw-bold text-secondary">
                            {empoyeeMyTask?.InprogressPercentage?.toFixed()}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <p className="mb-0 redbox-bg"> Hold</p>
                        </th>
                        <td>
                          <p className="mb-0 fw-bold text-secondary">
                            {empoyeeMyTask?.HoldPercentage}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <p className="mb-0 greenbox-bg"> Completed</p>
                        </th>
                        <td>
                          <p className="mb-0 fw-bold text-secondary">
                            {empoyeeMyTask?.CompletedPercentage?.toFixed()}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {state?.auth?.auth?.role === "superadmin" ? (
          <>
           
            {dateModal && (
              <div className="custom-date-range">
                <div className="row">
                  <div className="col-lg-4 ">
                    <div className="mb-3">
                      <label className="form-label">From</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="All"
                        required
                        id="fromDate"
                        value={fromDate}
                        onChange={(e) => {
                          setFromDate(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="mb-3">
                      <label className="form-label">To</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="All"
                        required
                        value={toDate}
                        // id="toDate"
                        onChange={(e) => {
                          setToDate(e.target.value);
                          // fetchAdminDashcount("custom", fromDate, e.target.value);
                          fetchTeamMembers("custom", fromDate, e.target.value);
                          fetchServiceRequest(
                            "custom",
                            fromDate,
                            e.target.value
                          );
                          fetchPackageBuyer("custom", fromDate, e.target.value);
                          fetchMytask("custom", fromDate, e.target.value);
                          getCustomPackage("custom", fromDate, e.target.value);
                          getPackageUserExpiry(
                            "custom",
                            fromDate,
                            e.target.value
                          );
                          GetExpiryDatePackage(
                            "custom",
                            fromDate,
                            e.target.value
                          );
                          fetchuserDashboardData(
                            "custom",
                            fromDate,
                            e.target.value
                          );
                          getOverDueTask("custom", fromDate, e.target.value);
                          getServiceBuyerUser(
                            "custom",
                            fromDate,
                            e.target.value
                          );
                          fetchuserDashboardData(
                            "custom",
                            fromDate,
                            e.target.value
                          );
                          fetchRevenue("custom", fromDate, e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="cardtable" style={{
                marginTop:"1%"
            }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider",display:"flex", alignItems:"center",justifyContent:"space-between" ,gap:8}}>
                  <TabList
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    aria-label="scrollable force tabs example"
                    textColor="secondary"
                    indicatorColor="secondary"
                  >
                    <Tab label="Revenue" value="1" />
                    <Tab label="Service Request" value="2" />
                    <Tab label="Task" value="3" />
                    <Tab label="Buyer" value="4" />
                    <Tab label="Employee & Admin" value="5" />
                    <Tab label="User" value="6" />
                  </TabList>
                  <select className="selectoptioncss mb-2"   
                  defaultValue={defaultSelect}
                  onChange={(e) => {
                // fetchAdminDashcount(e.target.value, "", "");
                fetchTeamMembers(e.target.value, "", "");
                fetchServiceRequest(e.target.value, "", "");
                fetchPackageBuyer(e.target.value, "", "");
                fetchMytask(e.target.value, "", "");
                getCustomPackage(e.target.value, "", "");
                getPackageUserExpiry(e.target.value, "", "");
                GetExpiryDatePackage(e.target.value, "", "");
                fetchuserDashboardData(e.target.value, "", "");
                getOverDueTask(e.target.value, "", "");
                fetchTasktotalcount(e.target.value, "", "");
                getServiceBuyerUser(e.target.value, "", "");
                fetchRevenue(e.target.value, "", "");
                  setDateModal(Boolean(e.target.value === "custom"));
              }}>
                <option value="today">Today</option>
                <option value="yesterday">Yesterday</option>
                <option value="week">This Week</option>
                <option value="lastWeek">Last Week</option>
                <option value="currentMonth">Current Month</option>
                <option value="lastMonth">Last Month</option>
                <option value="lastThreeMonth">Last Three Months</option>
                <option value="lastSixMonth">Last Six Months</option>
                <option value="lastOneYear">Last One Year</option>
                <option value="custom">Custom</option>
              </select>
                </Box>
                <TabPanel value="1">
                  <div className="row">
                  {/* <div className="col-12">
                      <div
                        className="d-flex gap-5 mt-4 align-items-center"
                        style={{
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            flex: 1,
                          }}
                        >
                          <Chart
                            chartType="PieChart"
                            width="100%"
                            height="300px"
                            data={piechartdata4}
                            options={piechartrevenue}
                          />
                        </div>

                        <div
                          style={{
                            flex: 1,
                          }}
                        >
                          <ul
                            style={{
                              maxWidth: "300px",
                              lineHeight: "2rem",
                            }}
                          >
                            <li className="d-flex align-items-center justify-content-between">
                              <p className="mb-0 greenbox-bg">
                                User Purchase Services
                              </p>
                              <p className="mb-0 fw-bold text-secondary">
                                {revenue1?.userpurchaseservicespercentage?.toFixed()}
                              </p>
                            </li>
                            <li className="d-flex align-items-center justify-content-between">
                              <p className="mb-0 violetbox-bg">
                                {" "}
                                User Package{" "}
                              </p>
                              <p className="mb-0 fw-bold text-secondary">
                                {revenue1?.userPackagepercentage?.toFixed()}
                              </p>
                            </li>
                            <li className="d-flex align-items-center justify-content-between">
                              <p className="mb-0 redbox-bg">
                                {" "}
                                User Custom Package
                              </p>
                              <p className="mb-0 fw-bold text-secondary">
                                {revenue1?.userCustompackagepercentage?.toFixed()}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div> */}

                    <div className="col-12">
                      <div className="scroll-table">
                        <MUIDataTable
                          title={"Dalmaf - Revenue"}
                          data={revenue}
                          columns={columnrevenue}
                          options={options}
                        />
                      </div>
                    </div>
                   
                  </div>
                </TabPanel>
                <TabPanel value="2">
                <div className="w-full">
                      <div
                        className="d-flex gap-5 mt-4 align-items-center"
                        style={{
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            flex: 1,
                          }}
                        >
                          <Chart
                            chartType="PieChart"
                            width="100%"
                            height="300px"
                            data={piechartdata3}
                            options={piechartoptionreq}
                          />
                        </div>

                        <div
                          style={{
                            flex: 1,
                          }}
                        >
                          <ul
                            style={{
                              maxWidth: "300px",
                              lineHeight: "2rem",
                            }}
                          >
                            <li className="d-flex align-items-center justify-content-between">
                              <p className="mb-0 greenbox-bg">Unassigned</p>
                              <p className="mb-0 fw-bold text-secondary">
                                {serviceRequest1?.unassignedPercentage?.toFixed()}
                              </p>
                            </li>
                            <li className="d-flex align-items-center justify-content-between">
                              <p className="mb-0 violetbox-bg">Assigned</p>
                              <p className="mb-0 fw-bold text-secondary">
                                {serviceRequest1?.assignedPercentage?.toFixed()}
                              </p>
                            </li>
                            <li className="d-flex align-items-center justify-content-between">
                              <p className="mb-0 yellowbox-bg">ToDo</p>
                              <p className="mb-0 fw-bold text-secondary">
                                {serviceRequest1?.ToDoPercentage?.toFixed()}
                              </p>
                            </li>
                            <li className="d-flex align-items-center justify-content-between">
                              <p className="mb-0 violetbox-bg">completed</p>
                              <p className="mb-0 fw-bold text-secondary">
                                {serviceRequest1?.completedPercentage?.toFixed()}
                              </p>
                            </li>
                            <li className="d-flex align-items-center justify-content-between">
                              <p className="mb-0 redbox-bg">Inprogress</p>
                              <p className="mb-0 fw-bold text-secondary">
                                {serviceRequest1?.inprogressPercentage?.toFixed()}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>

                    </div>
                 
                  <div className="row mt-4">
                  <div className="row col-12 mb-4">
                    <h6 className="form-bold w-100 mb-3">Services</h6>
                    <div className="col-lg-6 col-md-6">
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="ordercard">
                            <div className="row">
                              <div className="col-lg-10 col-10">
                                <div className="d-flex">
                                  <span className="material-symbols-rounded iconcolor">
                                    group
                                  </span>
                                  <h6 className="cardsubtitle">New Request</h6>
                                </div>
                              </div>
                              <div className="col-lg-2 col-2">
                                <h6 className="numbercolor">
                                  {adminDashCount?.total_newRequest}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="ordercard">
                            <div className="row">
                              <div className="col-lg-10 col-10">
                                <div className="d-flex">
                                  <span className="material-symbols-rounded iconcolor">
                                    group
                                  </span>
                                  <h6 className="cardsubtitle"> Assigned</h6>
                                </div>
                              </div>
                              <div className="col-lg-2 col-2">
                                <h6 className="numbercolor">
                                  {adminDashCount?.total_requestAssigned}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-lg-6 col-md-6">
                          <div className="ordercard">
                            <div className="row">
                              <div className="col-lg-10 col-10">
                                <div className="d-flex">
                                  <span className="material-symbols-rounded iconcolor">
                                    group
                                  </span>
                                  <h6 className="cardsubtitle">Todo</h6>
                                </div>
                              </div>
                              <div className="col-lg-2 col-2">
                                <h6 className="numbercolor">
                                  {adminDashCount?.total_requestToDo}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      {/* <h6 className="form-bold w-100 mb-3">Total Employee</h6> */}
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="ordercard">
                            <div className="row">
                              <div className="col-lg-10 col-10">
                                <div className="d-flex">
                                  <span className="material-symbols-rounded iconcolor">
                                    group
                                  </span>
                                  <h6 className="cardsubtitle">In-Progress</h6>
                                </div>
                              </div>
                              <div className="col-lg-2 col-2">
                                <h6 className="numbercolor">
                                  {adminDashCount?.total_requestinprogress}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="ordercard">
                            <div className="row">
                              <div className="col-lg-10 col-10">
                                <div className="d-flex">
                                  <span className="material-symbols-rounded iconcolor">
                                    group
                                  </span>
                                  <h6 className="cardsubtitle"> Completed</h6>
                                </div>
                              </div>
                              <div className="col-lg-2 col-2">
                                <h6 className="numbercolor">
                                  {adminDashCount?.total_requestcompleted}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                    <div className="col-12">
                      <div className="scroll-table">
                        <MUIDataTable
                          title={"Dalmaf - Service Request"}
                          data={serviceRequest}
                          columns={columnServiceRequest}
                          options={options_cus}
                        />
                      </div>
                    </div>

                  
                  </div>
                </TabPanel>
                <TabPanel value="3">
                <div className="w-full">
                      {/* <div className="cardtable"> */}
                      <div
                        className="d-flex gap-5 mt-4 align-items-center"
                        style={{
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            flex: 1,
                          }}
                        >
                          <Chart
                            chartType="PieChart"
                            width="100%"
                            height="300px"
                            data={piechartTotalTask}
                            options={totaltaskpie}
                          />
                        </div>

                        <div
                          style={{
                            flex: 1,
                          }}
                        >
                          <ul
                            style={{
                              maxWidth: "300px",
                              lineHeight: "2rem",
                            }}
                          >
                            <li className="d-flex align-items-center justify-content-between">
                              <p className="mb-0 greenbox-bg">Inprogress</p>
                              <p className="mb-0 fw-bold text-secondary">
                                {totalTaskDashboard?.InprogressPercentage?.toFixed()}
                              </p>
                            </li>
                            <li className="d-flex align-items-center justify-content-between">
                              <p className="mb-0 yellowbox-bg"> ToDo </p>
                              <p className="mb-0 fw-bold text-secondary">
                                {totalTaskDashboard?.TodoPercentage?.toFixed()}
                              </p>
                            </li>
                            <li className="d-flex align-items-center justify-content-between">
                              <p className="mb-0 violetbox-bg"> completed</p>
                              <p className="mb-0 fw-bold text-secondary">
                                {totalTaskDashboard?.CompletedPercentage?.toFixed()}
                              </p>
                            </li>
                            <li className="d-flex align-items-center justify-content-between">
                              <p className="mb-0 redbox-bg"> Hold</p>
                              <p className="mb-0 fw-bold text-secondary">
                                {totalTaskDashboard?.HoldPercentage?.toFixed()}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  <div className="row">
                    <h6 className="form-bold w-100 mb-3">Task Details</h6>
                    <div className="col-lg-6 col-md-6">
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="ordercard">
                            <div className="row">
                              <div className="col-lg-10 col-10">
                                <div className="d-flex">
                                  <span className="material-symbols-rounded iconcolor">
                                    group
                                  </span>
                                  <h6 className="cardsubtitle">Total Task</h6>
                                </div>
                              </div>
                              <div className="col-lg-2 col-2">
                                <h6 className="numbercolor">
                                  {adminDashCount?.total_task}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="ordercard">
                            <div className="row">
                              <div className="col-lg-10 col-10">
                                <div className="d-flex">
                                  <span className="material-symbols-rounded iconcolor">
                                    group
                                  </span>
                                  <h6 className="cardsubtitle"> Todo</h6>
                                </div>
                              </div>
                              <div className="col-lg-2 col-2">
                                <h6 className="numbercolor">
                                  {adminDashCount?.total_taskTodo}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-lg-6 col-md-6">
                          <div className="ordercard">
                            <div className="row">
                              <div className="col-lg-10 col-10">
                                <div className="d-flex">
                                  <span className="material-symbols-rounded iconcolor">
                                    group
                                  </span>
                                  <h6 className="cardsubtitle">Completed</h6>
                                </div>
                              </div>
                              <div className="col-lg-2 col-2">
                                <h6 className="numbercolor">
                                  {adminDashCount?.total_taskCompleted}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      {/* <h6 className="form-bold w-100 mb-3">Total Employee</h6> */}
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="ordercard">
                            <div className="row">
                              <div className="col-lg-10 col-10">
                                <div className="d-flex">
                                  <span className="material-symbols-rounded iconcolor">
                                    group
                                  </span>
                                  <h6 className="cardsubtitle">In-Progress</h6>
                                </div>
                              </div>
                              <div className="col-lg-2 col-2">
                                <h6 className="numbercolor">
                                  {adminDashCount?.total_taskInprogress}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="ordercard">
                            <div className="row">
                              <div className="col-lg-10 col-10">
                                <div className="d-flex">
                                  <span className="material-symbols-rounded iconcolor">
                                    group
                                  </span>
                                  <h6 className="cardsubtitle"> Hold</h6>
                                </div>
                              </div>
                              <div className="col-lg-2 col-2">
                                <h6 className="numbercolor">
                                  {adminDashCount?.total_taskHold}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    {/* <div className="cardtable"> */}
                    <div className="scroll-table">
                      <MUIDataTable
                        title={"Dalmaf - Over Due Task"}
                        data={overdueTask}
                        columns={columnsOverDue}
                        options={options}
                      />
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="row">
                    
                    <div className="col-12">
                      {/* <div className="cardtable"> */}
                      <div className="scroll-table">
                        <MUIDataTable
                          title={"Dalmaf - My Task"}
                          data={myTask}
                          columns={columnsMytaskDashbord}
                          options={options_cus2}
                        />
                      </div>
                      {/* </div> */}
                    </div>
                    
                  </div>
                </TabPanel>
                <TabPanel value="4">
                  <TabContext value={buyerTab}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleBuyerTabChange}
                        aria-label="lab API tabs example"
                        textColor="secondary"
                        indicatorColor="secondary"
                      >
                        <Tab label="Service Request" value="1" />
                        <Tab label="Packages" value="2" />
                        <Tab label="Custom Package" value="3" />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <div className="">
                        <div className="scroll-table">
                          <MUIDataTable
                            title={"Dalmaf - Service Buyer User"}
                            data={serviceBuyerUser}
                            columns={columnsServiceBuyer}
                            options={options}
                          />
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="2">
                      <div className="">
                        <div className="scroll-table">
                          <MUIDataTable
                            title={"Dalmaf - Package Subscriber"}
                            data={packageBuyer}
                            columns={columnsbuyer}
                            options={options}
                          />
                        </div>
                      </div>

                      <div className="">
                        <div className="scroll-table">
                          <MUIDataTable
                            title={"Dalmaf - User Package Subscriber"}
                            data={packageUserExpiry}
                            columns={columnsUserPackExpiry}
                            options={options}
                          />
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="3">
                      <div className="">
                        <div className="scroll-table">
                          <MUIDataTable
                            title={"Dalmaf - Custom Package Subscriber"}
                            data={customPackageUser}
                            columns={columnsPackageUser}
                            options={options}
                          />
                        </div>
                      </div>
                    </TabPanel>
                  </TabContext>
                </TabPanel>
                <TabPanel value="5">
                  <div className="">
                    <div className="scroll-table">
                      <MUIDataTable
                        title={"Dalmaf - Team Member"}
                        data={TeamMemberData}
                        columns={columnmembers}
                        options={options}
                      />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="6">
                    <div>
                    <UserManagementComponent/>
                    </div>
                </TabPanel>
              </TabContext>
           
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Dashboard;
