
import React, { useEffect } from "react";
import Footer from "../Components/Footer/Footer";
import UserNavigation from '../Components/v1/NavBar/Navigaton';
import Navigation from "../Components/Navbar/Navigation";
import {Helmet} from "react-helmet";
export default function CancellationAndRefundPolicy() {
    useEffect(() => {
        window.scroll(0, 0);
    }, []);
    return (
        <div className="home-nav">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Cancellation and Refund Policy | DALMaf Private Limited</title>
                <link rel="canonical" href={window.location.href} />
                <meta name="description" content="We strive to ensure that the services you avail through our website are to your full satisfaction, and are the best in the Industry at extremely reasonable and affordable rates. However, in case you are not satisfied with our services, please contact us immediately and we will correct the situation, provide a refund or offer credit that can be used for future dalmaf.com orders." />
                <meta name="keywords" content="We strive to ensure that the services you avail through our website are to your full satisfaction, and are the best in the Industry at extremely reasonable and affordable rates. However, in case you are not satisfied with our services, please contact us immediately and we will correct the situation, provide a refund or offer credit that can be used for future dalmaf.com orders." />
                <meta name="author" content="DALMaf" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            </Helmet>
            <UserNavigation />
            <div>
                <div className="term-cond ">
                    <div className="container"><br></br><br></br>
                        <p><h4 className="fw-bold color-maroon">CANCELLATION AND REFUND POLICY</h4>
                            <ul>
                                <li>We strive to ensure that the services you avail through our website are to your full satisfaction, and are the best in the Industry at extremely reasonable and affordable rates. However, in case you are not satisfied with our services, please contact us immediately and we will correct the situation, provide a refund or offer credit that can be used for future dalmaf.com orders.</li>
                                <li>If you are Not Satisfied with our services ? Kindly Let us know!</li>
                                <li>If you're not satisfied with the service, please login to the dalmaf.com Dashboard using the email address from which payment was made. In case you forgot the password, use the forgot password option to reset the password. Situations may arise when you desire a refund. Three instances user wish not to proceed with the DALMAF Orders.</li>
                                <li>when you pay for the services but later on decide that you do not wish to avail them</li>
                                <li>when there is a delay in the services offered from our side, beyond the time frame we have intimated to you, due to human error i.e., factors for which we are solely responsible.</li>
                                <li>You might find our services unsatisfactory<br></br>
                                In such events, kindly write a mail to refund@dalmaf.com in your name and mention about the order details. We would like to clarify that only refunds of the professional fees component of the charges paid by you shall be considered for a refund or offer credit that can be used for future dalmaf.com orders</li>
                                <li>Upon receiving your mail, the Senior Management at dalmaf.com shall decide on whether your request for a refund should be processed, contingent on the reasons for such a request. Please note that we reserve the right to take the final and binding decision with regard to requests for refund.</li>
                                <li>If we confirm your request for refund, subject to the terms and conditions mentioned herein or elsewhere, we will send you an e-mail seeking the details required to refund the amount which may include your Bank Account details such as the account number and the IFS code of the branch in question. Kindly note that it will take us a minimum of about 48-72 working hours from the receipt of all such information to process the refund and initiate the transfer.</li>
                                <li>Note: All the services listed in the dalmaf.com under a particular category may not have a return policy since it’s a service oriented not physical product to be returned.</li>
                            </ul>
                        </p>

                        <p><h4 className="fw-bold color-maroon">30 Day Refund Policy</h4>
                            <ul>
                                <li>If a service opted by you is not progressing as per your expectations, you can request for a refund within 30 days of payment. After 30 days, you can request for the refund to be processed as a service credit - which can be used for any other future service.</li>
                                <li>Cancellation Fee Deduction</li>
                                <li>Before processing any refund, we reserve the right to make a best effort to complete the service. In case you are not satisfied with the service, a cancellation fee of 20% + fee paid to the government would be applicable. In case of change of service, the cancellation fee would not be applicable.</li>
                                <li>Hence, all refunds will be subject to a minimum of 20% cancellation fee.</li>
                            </ul>
                        </p>

                        <p><h4 className="fw-bold color-maroon">Change of Service </h4>
                            <ul>
                                <li>If you want to change the service you ordered for a different one, you can request this change of service within 20 days of purchase. The purchase price of the original service, less any and money paid to government entities, such as filing fees or taxes, or to other third parties with deducted and the next service will be launched.</li>
                                <li>Penalty or Tax Payment or charges levied by Government</li>
                                <li>dalmaf.com and its employees play the role of a corporate service provider. While, it is our responsibility to prepare the necessary filings, it is the clients responsibility to ensure accuracy and correctness. Hence, dalmaf.com will not pay any penalties or taxes on behalf of its clients. Further, dalmaf.com is not responsible or liable for any other cost incurred by the client related to the completion of the service that is out of scope.</li>
                            </ul>
                        </p>

                        <p><h4 className="fw-bold color-maroon">Factors Outside our Control</h4>
                            <ul>
                                <li>We cannot guarantee the results or outcome of your particular procedure. For instance, the government may reject a trademark application for legal reasons beyond the scope of dalmaf.com services. In some cases, a government backlog or problems with the government platforms (e.g. MCA website, Income Tax website, FSSAI website, E-Courts & other government portals) can lead to long delays before your process is complete. Problems like these are beyond our control and are not covered by this guarantee or eligible for refund. Hence, delay in processing of your file by the Government cannot be a reason for refund.</li>
                                <li>Force Majeure dalmaf.com shall not be considered in breach of its Satisfaction Guarantee policy or default under any terms of service, and shall not be liable to the Client for any cessation, interruption, or delay in the performance of its obligations by reason of earthquake, flood, fire, storm, lightning, drought, landslide, hurricane, cyclone, typhoon, tornado, natural disaster, act of God or the public enemy, epidemic, famine or plague, action of a court or public authority, change in law, explosion, war, terrorism, armed conflict, labour strike, lockout, boycott or similar event beyond our reasonable control, whether foreseen or unforeseen (each a "Force Majeure Event").</li>
                            </ul>
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
