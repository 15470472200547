import React, { useEffect, useState } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";

import { useSelector } from "react-redux";
import {
  GetAllSubAdminAndAdmin,
  GetSpecificLead,
  UpdateLead,
} from "../../../Api/Api";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useNavigate, useParams } from "react-router-dom";
import {
  NonEmptyValidation,
  StringValidation,
  PincodeValidation,
  PhoneNumberValidation,
  EmailValidation,
  DateValidationn,
  NonEmptyValidationForDate,
} from "../../../Store/validate";
import moment from "moment";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CustomModal from "../../../Components/CustomModal";
let htmlToDraft = null;
if (typeof window === "object") {
  htmlToDraft = require("html-to-draftjs").default;
}

export default function LeadEdit() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const { id } = useParams();
  useEffect(() => {

    window.scroll(0, 0);
    GetAllLeadSubadmins();
    GetMySpecificTsksRC();
  }, []);
  useEffect(() => {
    GetMySpecificLeadTsks();
  }, [id])
  const [open, setOpen] = useState(false);
  const [myLeadTask, setMyLeadTask] = useState({});
  const [myTaskLeadValidation, setMyTaskLeadValidation] = useState({});

  const setMyTaskLeadValue = (key, value) => {
    setMyLeadTask({ ...myLeadTask, [key]: value });
    if (myTaskLeadValidation[key]) delete myTaskLeadValidation[key];
  };

  const setMyTaskLeadValidationValue = (key, value) => {
    setMyTaskLeadValidation({ ...myTaskLeadValidation, [key]: value });
  };
  const GetMySpecificLeadTsks = async () => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await GetSpecificLead(token, id);
    if (data && data.statusCode === 200) {
      let dummy = data.data;
      let cmsDescription = EditorState.createEmpty();
      if (dummy.notes) {
        let blocksFromHtml = htmlToDraft(dummy.notes);
        let { contentBlocks, entityMap } = blocksFromHtml;
        let contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        cmsDescription = EditorState.createWithContent(contentState);
      }
      dummy.notes = cmsDescription;

      setMyLeadTask(dummy);
      // setMyLeadTask(data.data);
      setLoading(false);
    } else {
      setMyLeadTask({});
      setLoading(false);
      //console.log("Error while getting my specific task");
    }
  };

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };
  const [allLeadSubadmin, setAllLeadSubadmin] = useState([]);
  const GetAllLeadSubadmins = async () => {
    setLoading(true);
    const token = state?.auth?.auth?.token;
    let data = await GetAllSubAdminAndAdmin(token);
    if (data && data.statusCode === 200) {
      setAllLeadSubadmin(data.data);
      setLoading(false);
    } else {
      setAllLeadSubadmin([]);
      setLoading(false);
      //console.log("Error while getting all subadmins");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.typeOfEntity = NonEmptyValidation(myLeadTask?.typeOfEntity);
    validate.name = NonEmptyValidation(myLeadTask?.name);
    validate.date = NonEmptyValidationForDate(myLeadTask?.date);
    validate.clientName = StringValidation(myLeadTask?.clientName);
    validate.typeOfEntity = NonEmptyValidation(myLeadTask?.typeOfEntity);
    validate.mobileNumber = PhoneNumberValidation(myLeadTask?.mobileNumber);
    validate.email = EmailValidation(myLeadTask?.email);
    validate.professional = NonEmptyValidation(myLeadTask?.professional);
    validate.occupation = NonEmptyValidation(myLeadTask?.occupation);
    validate.address = NonEmptyValidation(myLeadTask?.address);
    validate.pinCode = PincodeValidation(myLeadTask?.pinCode);
    validate.city = StringValidation(myLeadTask?.city);
    validate.state = StringValidation(myLeadTask?.state);
    setMyTaskLeadValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      let html;
      let Payload;
      let isNotEmpty =
        myLeadTask.notes &&
        myLeadTask.notes.getCurrentContent().hasText();
      if (isNotEmpty) {
        html = draftToHtml(
          convertToRaw(myLeadTask.notes.getCurrentContent())
        );
        Payload = {
          _id: myLeadTask._id,
          name: myLeadTask.name,
          date: myLeadTask.date,
          clientName: myLeadTask.clientName,
          typeOfEntity: myLeadTask.typeOfEntity,
          mobileNumber: myLeadTask.mobileNumber,
          email: myLeadTask.email?.toLowerCase(),
          pinCode: myLeadTask.pinCode,
          professional: myLeadTask.professional,
          occupation: myLeadTask.occupation,
          address: myLeadTask.address,
          state: myLeadTask.state,
          city: myLeadTask.city,
          // serviceRequest: myLeadTask.serviceRequest,
          serviceRequest: myLeadTask.serviceRequest=== "Others" ?  myLeadTask?.otherServiceRequest: myLeadTask.serviceRequest ,
          status: myLeadTask?.status,
          notes: html,
        };
      }
      const token = state?.auth?.auth?.token;
      const response = await UpdateLead(Payload, token);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        if (myLeadTask?.status === "rejected") {
          setOpen(true);
        }
        // window.scrollTo(0, 0);
        // GetMySpecificTsks();
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } else {
      //console.log("Error while update user details");
    }
  };

  // const [leadComment, setLeadComment] = useState("");
  // const handleSubmitComment = async (e) => {
  //   e.preventDefault();
  //   let Payload = {
  //     _id: myLeadTask._id,
  //     comments: leadComment,
  //   };

  //   const token = state.auth.auth.token;

  //   const response = await UpdateLead(Payload, token);
  //   if (response && response.statusCode === 200) {
  //     showToastSuccessMsg(response.message);
  //     GetMySpecificLeadTsks();
  //     window.location.reload();
  //     setLeadComment("");
  //     e.target.reset();
  //   } else {
  //     showToastErrorMsg(
  //       response.message || response.error.response.data.message
  //     );

  //   }
  // };

  const [leadComment, setLeadComment] = useState("");
  const [commentsValidation, setCommentsValidation] = useState({});

  const setCommentsValue = (key, value) => {
    setLeadComment({ ...leadComment, [key]: value });

    if (commentsValidation[key]) delete commentsValidation[key];
  };

  const setCommentsLeadValidationValue = (key, value) => {
    setCommentsValidation({
      ...commentsValidation,

      [key]: value,
    });
  };

  const handleSubmitComment = async (e) => {
    e.preventDefault();

    let validate = {};
    validate.comments = NonEmptyValidation(leadComment?.comments);
    setCommentsValidation(validate)
    if (Object.values(validate).every((v) => v.status === true)) {
      let Payload = {
        _id: myLeadTask._id,

        comments: leadComment?.comments,
      };

      // 6441de0da3fe015898ab275f

      const token = state.auth.auth.token;

      const response = await UpdateLead(Payload, token);

      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);

        GetMySpecificLeadTsks();

        window.location.reload();

        setLeadComment("");

        e.target.reset();
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );

        //setfailureModal(true);
      }
    } else {
      //console.log("Error while update user details");
    }
  };

  const [editorState1, setEditorState1] = useState(() =>
    EditorState.createEmpty()
  );
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleEditorChange1 = (state) => {
    setEditorState1(state);
    let isNotEmpty = state && state.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(convertToRaw(state.getCurrentContent()));
      setMyTaskLeadValue("resolutionComment", html);
    }
  };

  const handleEditorChange = (state) => {
    setEditorState(state);
    let isNotEmpty = state && state.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(convertToRaw(state.getCurrentContent()));
      setCommentsValue("comments", html);
    }
  };

  const handleSubmitUpdateComplete = async (e, value, id) => {
    e.preventDefault();
    const Payload = {
      _id: id,
      resolutionComment: value,
    };
    const token = state?.auth?.auth?.token;

    const response = await UpdateLead(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      setOpen(false);
      window.location.reload()
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setfailureModal(true);
    }
  };

  const [rc, setRC] = useState({});

  const GetMySpecificTsksRC = async () => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await GetSpecificLead(token, id);
    if (data && data.statusCode === 200) {
      let dummy = data.data;
      let cmsDescription = EditorState.createEmpty();
      if (dummy.notes) {
        let blocksFromHtml = htmlToDraft(dummy.notes);
        let { contentBlocks, entityMap } = blocksFromHtml;
        let contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        cmsDescription = EditorState.createWithContent(contentState);
      }
      dummy.notes = cmsDescription;
      // let cmsDescription1 = EditorState.createEmpty();
      // if (dummy.resolutionComment) {
      //   let blocksFromHtml = htmlToDraft(dummy.resolutionComment);
      //   let { contentBlocks, entityMap } = blocksFromHtml;
      //   let contentState = ContentState.createFromBlockArray(
      //     contentBlocks,
      //     entityMap
      //   );
      //   cmsDescription1 = EditorState.createWithContent(contentState);
      // }
      // dummy.resolutionComment = cmsDescription1;
      //console.log(dummy);
      setRC(dummy);
      setLoading(false);
    } else {
      setRC([]);
      setLoading(false);
      //console.log("Error while getting my specific task");
    }
  };

  return (
    <div>
      <AdminNavbar />
      <ToastContainer />
      <div className="main">
        <div>
          <div className="">
            <button
              type="button"
              className="btn login-btn ms-2 "
              onClick={(e) => {
                navigate("/Lead");
              }}
            >
              Back
            </button>
          </div>
          <div className=" my-4">
            <div className="bg-historytablehead rounded-3 py-3 px-3">
              <h6 className="mb-0">Update Lead</h6>
            </div>
            <div className="container">
              <div className="row justify-content-center py-5">
                <div className="col-lg-11">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label">Employee Name</label>
                        {/* {console.log("myLeadTask",myLeadTask.name)} */}
                        <select
                          className="form-control form-control-lg"
                          value={myLeadTask?.name}
                          onChange={(e) => {
                            let name = allLeadSubadmin?.filter(
                              (s) => s.name === e.target.value
                            );
                            let nameId = name?.map((n) => {
                              return n._id;
                            });

                            setMyTaskLeadValue("name", nameId?.toString());
                          }}
                          disabled={myLeadTask?.status === "rejected"}
                          onBlur={(e) => {
                            let name = allLeadSubadmin?.filter(
                              (s) => s.name === e.target.value
                            );
                            let nameId = name?.map((n) => {
                              return n._id;
                            });
                            setMyTaskLeadValidationValue(
                              "name",
                              NonEmptyValidation(nameId?.toString())
                            );
                          }}
                        >
                          {allLeadSubadmin?.map((e, i) => (
                            <option key={i} value={e._id}>
                              {e.name}
                            </option>
                          ))}
                        </select>
                        <small className="text-danger">
                          {myTaskLeadValidation?.name?.message
                            ? `Name ${myTaskLeadValidation?.name?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label">Client Name</label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Enter the Client Name"
                          aria-invalid={Boolean(
                            myTaskLeadValidation?.clientName?.status === false
                          )}
                          value={myLeadTask?.clientName}
                          onChange={(e) => {
                            setMyTaskLeadValue("clientName", e.target.value);
                          }}
                          onBlur={(e) => {
                            setMyTaskLeadValidationValue(
                              "clientName",
                              StringValidation(e.target.value)
                            );
                          }}
                          disabled={myLeadTask?.status === "rejected"}
                          autoComplete="off"
                        />
                        <small className="text-danger">
                          {myTaskLeadValidation?.clientName?.message
                            ? `Client Name ${myTaskLeadValidation?.clientName?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>

                    {/* <div className="col-lg-6">
                        
                            <div className="mb-4">
                              <label className="form-label"> Type Of Entity</label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Type"
                                aria-invalid={Boolean(
                                  myTaskLeadValidation?.typeOfEntity?.status === false
                                )}
                                value={myLeadTask?.typeOfEntity}
                                onChange={(e) => {
                                  setMyTaskLeadValue("typeOfEntity", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setMyTaskLeadValidationValue(
                                    "typeOfEntity",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {myTaskLeadValidation?.typeOfEntity?.message
                                  ? `type Of Entity ${myTaskLeadValidation?.typeOfEntity?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>  */}

                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label"> Mobile Number </label>
                        <input
                          type="tel"
                          className="form-control form-control-lg"
                          placeholder="Enter Your Phone Number"
                          aria-invalid={Boolean(
                            myTaskLeadValidation?.mobileNumber?.status === false
                          )}
                          value={myLeadTask?.mobileNumber}
                          onChange={(e) => {
                            setMyTaskLeadValue("mobileNumber", e.target.value);
                          }}
                          onBlur={(e) => {
                            setMyTaskLeadValidationValue(
                              "mobileNumber",
                              PhoneNumberValidation(e.target.value)
                            );
                          }}
                          disabled={myLeadTask?.status === "rejected"}
                          autoComplete="off"
                        />
                        <small className="text-danger">
                          {myTaskLeadValidation?.mobileNumber?.message
                            ? `Mobile Number ${myTaskLeadValidation?.mobileNumber?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label">Email</label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Enter the E -mail"
                          aria-invalid={Boolean(
                            myTaskLeadValidation?.email?.status === false
                          )}
                          value={myLeadTask?.email?.toLowerCase()}
                          onChange={(e) => {
                            setMyTaskLeadValue(
                              "email",
                              e.target.value?.toLowerCase()
                            );
                          }}
                          onBlur={(e) => {
                            setMyTaskLeadValidationValue(
                              "email",
                              EmailValidation(e.target.value?.toLowerCase())
                            );
                          }}
                          disabled={myLeadTask?.status === "rejected"}
                          autoComplete="off"
                        />
                        <small className="text-danger">
                          {myTaskLeadValidation?.email?.message
                            ? `Email ${myTaskLeadValidation?.email?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label"> Professional</label>
                        {/* <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Enter the Professional"
                          aria-invalid={Boolean(
                            myTaskLeadValidation?.professional?.status === false
                          )}
                          value={myLeadTask?.professional}
                          onChange={(e) => {
                            setMyTaskLeadValue("professional", e.target.value);
                          }}
                          onBlur={(e) => {
                            setMyTaskLeadValidationValue(
                              "professional",
                              NonEmptyValidation(e.target.value)
                            );
                          }}
                          autoComplete="off"
                        /> */}

                        <select
                          className="form-control form-control-lg"
                          value={myLeadTask?.professional}
                          onChange={(e) => {
                            setMyTaskLeadValue("professional", e.target.value);
                          }}
                          onBlur={(e) => {
                            setMyTaskLeadValidationValue(
                              "professional",
                              NonEmptyValidation(e.target.value)
                            );
                          }}
                          disabled={myLeadTask?.status === "rejected"}
                        >
                          <option value="Doctor">Doctor</option>
                          <option value="Lawyer">Lawyer</option>
                          <option value="CA">CA</option>
                          <option value="Engineer">Engineer</option>
                          <option value="Not Applicable">Not Applicable</option>
                        </select>
                        <small className="text-danger">
                          {myTaskLeadValidation?.professional?.message
                            ? `Professional ${myTaskLeadValidation?.professional?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label"> Occupation</label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Enter your Occupation "
                          aria-invalid={Boolean(
                            myTaskLeadValidation?.occupation?.status === false
                          )}
                          value={myLeadTask?.occupation}
                          onChange={(e) => {
                            setMyTaskLeadValue("occupation", e.target.value);
                          }}
                          onBlur={(e) => {
                            setMyTaskLeadValidationValue(
                              "occupation",
                              NonEmptyValidation(e.target.value)
                            );
                          }}
                          disabled={myLeadTask?.status === "rejected"}
                          autoComplete="off"
                        />
                        <small className="text-danger">
                          {myTaskLeadValidation?.occupation?.message
                            ? `Occupation ${myTaskLeadValidation?.occupation?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label">Address</label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Enter the addresss"
                          aria-invalid={Boolean(
                            myTaskLeadValidation?.address?.status === false
                          )}
                          value={myLeadTask?.address}
                          onChange={(e) => {
                            setMyTaskLeadValue("address", e.target.value);
                          }}
                          onBlur={(e) => {
                            setMyTaskLeadValidationValue(
                              "address",
                              NonEmptyValidation(e.target.value)
                            );
                          }}
                          disabled={myLeadTask?.status === "rejected"}
                          autoComplete="off"
                        />
                        <small className="text-danger">
                          {myTaskLeadValidation?.address?.message
                            ? `Address ${myTaskLeadValidation?.address?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label"> Pincode</label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Enter the pincode"
                          aria-invalid={Boolean(
                            myTaskLeadValidation?.pinCode?.status === false
                          )}
                          value={myLeadTask?.pinCode}
                          onChange={(e) => {
                            setMyTaskLeadValue("pinCode", e.target.value);
                          }}
                          onBlur={(e) => {
                            setMyTaskLeadValidationValue(
                              "pinCode",
                              PincodeValidation(e.target.value)
                            );
                          }}
                          disabled={myLeadTask?.status === "rejected"}
                          autoComplete="off"
                        />
                      </div>
                      <small className="text-danger">
                        {myTaskLeadValidation?.pinCode?.message
                          ? `Pincode ${myTaskLeadValidation?.pinCode?.message}`
                          : ""}
                      </small>
                    </div>

                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label">City</label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Enter the city"
                          aria-invalid={Boolean(
                            myTaskLeadValidation?.city?.status === false
                          )}
                          value={myLeadTask?.city}
                          onChange={(e) => {
                            setMyTaskLeadValue("city", e.target.value);
                          }}
                          disabled={myLeadTask?.status === "rejected"}
                          onBlur={(e) => {
                            setMyTaskLeadValidationValue(
                              "city",
                              StringValidation(e.target.value)
                            );
                          }}
                          autoComplete="off"
                        />
                        <small className="text-danger">
                          {myTaskLeadValidation?.city?.message
                            ? `City ${myTaskLeadValidation?.city?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label">State</label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Enter the state"
                          aria-invalid={Boolean(
                            myTaskLeadValidation?.state?.status === false
                          )}
                          value={myLeadTask?.state}
                          onChange={(e) => {
                            setMyTaskLeadValue("state", e.target.value);
                          }}
                          onBlur={(e) => {
                            setMyTaskLeadValidationValue(
                              "state",
                              StringValidation(e.target.value)
                            );
                          }}
                          disabled={myLeadTask?.status === "rejected"}
                          autoComplete="off"
                        />
                        <small className="text-danger">
                          {myTaskLeadValidation?.state?.message
                            ? `State ${myTaskLeadValidation?.state?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label">Date</label>
                        <input
                          type="date"
                          className="form-control form-control-lg"
                          aria-invalid={Boolean(
                            myTaskLeadValidation?.date?.status === false
                          )}
                          // defaultValue={moment(myLeadTask?.date).format("YYYY-MM-DD")}
                          //  value={myLeadTask?.date}
                          value={moment(myLeadTask?.date).format(
                            "YYYY-MM-DD"
                          )}
                          onChange={(e) => {
                            setMyTaskLeadValue("date", e.target.value);
                          }}
                          onBlur={(e) => {
                            setMyTaskLeadValidationValue(
                              "date",
                              DateValidationn(e.target.value)
                            );
                          }}
                          disabled={myLeadTask?.status === "rejected"}
                          autoComplete="off"
                        />
                        <small className="text-danger">
                          {myTaskLeadValidation?.date?.message
                            ? `Date ${myTaskLeadValidation?.date?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                    {/* {console.log(myLeadTask)} */}
                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label">Type of Entity</label>

                        <select
                          className="form-control"
                          value={myLeadTask?.typeOfEntity}
                          onChange={(e) => {
                            setMyTaskLeadValue("typeOfEntity", e.target.value);
                          }}
                          onBlur={(e) => {
                            setMyTaskLeadValidationValue(
                              "typeOfEntity",
                              NonEmptyValidation(e.target.value)
                            );
                          }}
                          disabled={myLeadTask?.status === "rejected"}
                        >
                          <option value="Individual">Individual</option>
                          <option value="Solo">Solo</option>
                          <option value="Partnership">Partnership</option>
                          <option value="Company">Company</option>
                          <option value="Hosptial Institution">Hosptial Institution</option>
                          <option value="Educational Institution">
                            Educational Institution
                          </option>
                        </select>
                        <small className="text-danger">
                          {myTaskLeadValidation?.typeOfEntity?.message
                            ? `TypeOfEntity ${myTaskLeadValidation?.typeOfEntity?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label">Service Request</label>
                        <select
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Enter A Service Request"
                          aria-invalid={Boolean(
                            myTaskLeadValidation?.serviceRequest?.status ===
                            false
                          )}
                          value={myLeadTask?.serviceRequest}
                          onChange={(e) => {
                            setMyTaskLeadValue(
                              "serviceRequest",
                              e.target.value
                            );
                          }}
                          onBlur={(e) => {
                            setMyTaskLeadValidationValue(
                              "serviceRequest",
                              NonEmptyValidation(e.target.value)
                            );
                          }}
                          disabled={myLeadTask?.status === "rejected"}
                          autoComplete="off"
                        >
                          <option value={myLeadTask.serviceRequest}>{myLeadTask.serviceRequest}</option>
                          <option value="Legal">Legal</option>
                          <option value="Manpower">Manpower</option>
                          <option value="Buy/Sell">Buy/Sell</option>
                          <option value="Audit">Audit</option>
                          <option value="Finance">Finance</option>
                          <option value="Auction">Auction</option>
                          <option value="Others">Others</option>
                        </select>
                        {myLeadTask.serviceRequest === "Others" && (
                          <input
                            type="text"
                            className="form-control form-control-lg mt-2"
                            placeholder="Enter Custom Service Request"
                            value={myLeadTask.otherServiceRequest}
                            onBlur={(e) =>
                              setMyTaskLeadValue("otherServiceRequest", e.target.value)
                            }
                          />
                        )}
                        <small className="text-danger">
                          {myTaskLeadValidation?.serviceRequest?.message
                            ? `Service ${myTaskLeadValidation?.serviceRequest?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label">Note</label>
                        <div
                          className="form-control"
                          style={{ overflowY: "scroll", height: "400px" }}
                        >
                          <Editor
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            editorState={myLeadTask?.notes}
                            onEditorStateChange={(e) =>
                              setMyTaskLeadValue(
                                "notes",
                                e
                              )
                            }
                          />
                          {/* {myLeadTaskValidation?.question && (
                                <p className="text-danger">
                                    {myLeadTaskValidation?.question}
                                </p>
                              )} */}
                          {/* <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter the Name"
                                aria-invalid={Boolean(
                                  myLeadTaskValidation?.question?.status === false
                                )}
                                value={myLeadTask?.question}
                                onChange={(e) => {
                                  setMyLeadTaskValue("question", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setMyTaskLeadValidationValue(
                                    "question",
                                    StringValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              /> */}

                        </div>
                        <small className="text-danger">
                          {myTaskLeadValidation?.notes?.message
                            ? `Note ${myTaskLeadValidation?.notes?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label">Status</label>
                        <select
                          className="form-control form-control-lg"
                          value={myLeadTask?.status}
                          onChange={(e) => {
                            setMyTaskLeadValue("status", e.target.value);
                          }}
                          disabled={myLeadTask?.status === "rejected"}
                        >
                          <option value="inprogress">In Progress</option>
                          <option value="rejected">Rejected</option>
                          <option value="hold">Hold</option>
                          <option value="open">Open</option>
                          <option value="converted">Converted</option>
                        </select>
                        <small className="text-danger">
                          {myTaskLeadValidation?.status?.message
                            ? `Status ${myTaskLeadValidation?.status?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                    {rc?.resolutionComment &&
                      rc?.status === "rejected" ? (
                      <div className="col-lg-12">
                        <div className="mb-4">
                          <label className="form-label">Rejection Reason</label>
                          <div
                            className="form-control form-control-lg"
                            style={{
                              height: "250px",
                              border: "4px solid maroon",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: rc?.resolutionComment
                            }}
                          >
                            {/* <Editor
                              wrapperClassName="wrapper-class"
                              editorClassName="editor-class"
                              toolbarClassName="toolbar-class"
                              editorState={rc?.resolutionComment}
                              keyBindingFn={() => "not-handled-command"}
                            /> */}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="col-lg-12 mt-4">
                      {/* <button
                        type="button"
                        className="btn white-btn me-2 "
                        onClick={() => {
                          setMyLeadTask({});
                        }}
                      >
                        Cancel
                      </button> */}
                      <button
                        type="button"
                        className="btn login-btn ms-2 mb-4"
                        onClick={(e) => {
                          handleSubmit(e);
                        }}
                      >
                        Save
                      </button>
                    </div>

                    <>
                      {/* {serviceReqParticular?.length && state?.auth?.auth?.role==="user" ? (<> */}
                      {myLeadTask?.commentsTo?.map((e, i) => (
                        <>
                          <div
                            key={i}
                            className="send-pro-bg"
                            style={{
                              border: "1px solid #c1c1c1",
                              borderRadius: "8px",
                              marginBottom: "8px",
                            }}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="d-flex   align-items-center">
                                <div className="flex-shrink-0 ">
                                  {e.profileImage ? (
                                    <img
                                      className="admin-pro-img "
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                      }}
                                      src={e.profileImage}
                                      alt="..."
                                    />
                                  ) : (
                                    <span className="material-symbols-rounded filled-icon dash-icon">
                                      person
                                    </span>
                                  )}
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <h6 className="fw-bold">{e.name}</h6>
                                  {e.Designation ? (<p style={{
                                    textTransform:
                                      "capitalize",
                                  }} className="mb-0">
                                    {e.Designation}
                                  </p>) : (<p style={{
                                    textTransform:
                                      "capitalize",
                                  }} className="mb-0">
                                    {e.role}
                                  </p>)}
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                  Asked {moment(e.createdAt).fromNow()}
                                </p>
                              </div>
                            </div>
                            <p className="mb-0 mt-3 pl-send-pro-bg"
                              dangerouslySetInnerHTML={{
                                __html: e.comments,
                              }}>
                              {/* {e.comments} */}
                            </p>
                          </div>
                        </>
                      ))}
                      {state?.auth?.auth?.role === "superadmin" ||
                        state?.auth?.auth?.role === "subadmin" ||
                        state?.auth?.auth?.role === "admin" ? (
                        <>
                          <div className="input-group flex-nowrap  my-3">
                            <div
                              className="form-control form-control-lg"
                              style={{ height: "250px" }}
                            >
                              <Editor
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbarClassName="toolbar-class"
                                editorState={editorState}
                                onEditorStateChange={(e) =>
                                  handleEditorChange(e)
                                }

                              />
                            </div>
                            {/* <input
                              style={{
                                border: "1px solid #c9c8c8",

                                backgroundColor: "transparent",

                                borderRight: "0px",
                              }}
                              type="text"
                              className="form-control"
                              placeholder="Your Message"
                              aria-invalid={Boolean(
                                commentsValidation?.comments?.status === false
                              )}
                              defaultValue={leadComment?.comments}
                              onChange={(e) => {
                                setCommentsValue("comments", e.target.value);
                              }}
                              onBlur={(e) => {
                                setCommentsLeadValidationValue(
                                  "comments",

                                  NonEmptyValidation(e.target.value)
                                );
                              }}
                            /> */}
                            <span
                              className="input-group-text"
                              id="addon-wrapping"
                              style={{
                                border: "1px solid #c9c8c8",
                                backgroundColor: "transparent",
                                borderLeft: "0px",
                              }}
                            >
                              <button
                                className="btn py-1  login-btn"
                                type="button"
                                id="button-addon2"
                                onClick={(e) => {
                                  handleSubmitComment(e);
                                }}
                              >
                                Send
                                <i className="fa-solid fa-paper-plane ms-2"></i>
                              </button>
                            </span>
                          </div>
                          <small className="text-danger">
                            {commentsValidation?.comments?.message
                              ? `comments ${commentsValidation?.comments?.message}`
                              : ""}
                          </small>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          open={open}
          onClickOutside={() => {
            setOpen(false);
          }}
        >
          <div className="modal-content w-50 m-auto">
            <div className="modal-body p-0 ">
              <div className="container">
                <span
                  className="material-symbols-rounded filled-icon close-icon"
                  style={{ textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px" }}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  close
                </span>
                <div className="row d-flex align-items-center justify-content-center  mt-0">
                  <div className="container ">
                    <div className="row justify-content-center">
                      <div className="col-lg-12">
                        <div className=" my-5 px-3">
                          <div className="row justify-content-center mt-2">
                            <div className="" style={{}}>
                              Rejection Reason
                            </div>
                            <div
                              className="form-control form-control-lg"
                              style={{ height: "250px" }}
                            >
                              <Editor
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbarClassName="toolbar-class"
                                editorState={editorState1}
                                onEditorStateChange={(e) =>
                                  handleEditorChange1(e)
                                }
                              />
                            </div>
                            <div className="col-6 text-center">
                              <button
                                className="btn btn-admin mt-3"
                                onClick={(e) =>
                                  handleSubmitUpdateComplete(
                                    e,
                                    myLeadTask?.resolutionComment,
                                    myLeadTask._id
                                  )
                                }
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CustomModal>
      </div>
    </div>
  );
}
