import { useEffect, useState } from "react";
import ListLeadComponent from "../../../../Components/v1/FreelancerPartner/Leads/ListLeadComponent";
import FreelancerPartnerNav from "../../../../Components/v1/NavBar/FreelancerPartnerNav";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'; // Ensure you have the CSS for react-tabs
import { useNavigate } from "react-router-dom";
import ListTaskComponent from "../../../../Components/v1/FreelancerPartner/Leads/ListTaskComponent";
import styles from "../../../../Components/v1/FreelancerPartner/Leads/css/ViewTaskComponent.module.css"
export default function ListTask({userTypeProps,routeProps,lableProps,isClientProps}) {
  const navigate = useNavigate();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
 
  const [show, setShow] = useState(true);
  const handleToggle = () => { setShow(!show); };


  return (
    <>
      <FreelancerPartnerNav showFullNav={show} onToggle={handleToggle} userTypeProps={userTypeProps} routeProps={routeProps} />
      <div style={{ marginLeft: show ? '220px' : '50px',padding:'20px'}}>
      <ListTaskComponent routeProps={routeProps} lableProps={lableProps} userTypeProps={userTypeProps} showFullNav={show} isClientProps={isClientProps} />
      </div>
      
    </>
  );
}
