import { useEffect, useState } from "react";
import FreelancerPartnerNav from "../../../../Components/v1/NavBar/FreelancerPartnerNav";
import CreateEstimateComponent from "../../../../Components/v1/FreelancerPartner/Leads/CreateEstimateComponent";


export default function CreateEstimate({routeProps,lableProps,userTypeProps,isClient}) {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  const [show, setShow] = useState(true);
  const handleToggle = () => {setShow(!show);};


  return (
    <>
      <FreelancerPartnerNav showFullNav={show} onToggle={handleToggle} userTypeProps={userTypeProps} routeProps={routeProps} />
      <CreateEstimateComponent showFullNav={show} lableProps={lableProps} userTypeProps={userTypeProps} isClient={isClient} routeProps={routeProps} />
    </>
  );
}
