import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import MUIDataTable from 'mui-datatables';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { fetchWithQueryAPI, updateAPI } from '../../../../Api/v1/Util/Api';
import { notes_fetch_by_find_particular, notes_fetch_by_reference, notes_update } from '../../../../Api/APIUrl';
import { format } from 'date-fns';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function ListNotesComponent({showFullNav,routeProps,lableProps,userTypeProps,isClientProps}) {
    const [leads, setLeads] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [viewMode, setViewMode] = useState(false);
    const [currentNote, setCurrentNote] = useState(null);
    const state = useSelector((state) => state);
    const navigate = useNavigate();
    const { id } = useParams();

    const fetchNotes = async () => {
        try {
            const response = await fetchWithQueryAPI(state.auth.auth.token, notes_fetch_by_reference+id);
            if (response && response.statusCode === 200) {
                setLeads(response.data);
            } else {
                toast.error("Failed to fetch Notes");
            }
        } catch (error) {
            toast.error("An error occurred while fetching Notes");
        }
    };

    const fetchNotesById = async (notesId, action) => {
        try {
            const response = await fetchWithQueryAPI(state.auth.auth.token, notes_fetch_by_find_particular+notesId);
            if (response && response.statusCode === 200) {
                setCurrentNote({
                    "_id":response.data._id,
                    "title":response.data.title,
                    "description":response.data.description,
                });
                if (action === 'Edit') {
                    setEditMode(true);
                } else {
                    setViewMode(true);
                }
            } else {
                toast.error("Failed to fetch Notes");
            }
        } catch (error) {
            toast.error("An error occurred while fetching Notes");
        }
    };

    useEffect(() => {
        fetchNotes();
    }, []);

    const handleView = (note) => {
        fetchNotesById(note, "View");
    };

    const handleEdit = (note) => {
        fetchNotesById(note, "Edit");
    };

    const handleCloseEdit = () => {
        setEditMode(false);
        setViewMode(false);
        setCurrentNote(null);
    };

    const handleSaveEdit = async () => {
        try {
            const response = await updateAPI(state.auth.auth.token, notes_update, currentNote);
            if (response && response.statusCode === 200) {
                toast.success("Note updated successfully");
                fetchNotes();
                handleCloseEdit();
            } else {
                toast.error("Failed to update note");
            }
        } catch (error) {
            toast.error("An error occurred while updating note");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCurrentNote(prevNote => ({
            ...prevNote,
            [name]: value
        }));
    };

    const handleDescriptionChange = (value) => {
        setCurrentNote(prevNote => ({
            ...prevNote,
            description: value
        }));
    };

    const columns = [
        {
            name: 'title',
            label: 'Title',
            options: {
                customBodyRender: (value, tableMeta) => (
                    <span onClick={() => handleView(tableMeta.rowData[5])} style={{ cursor: 'pointer' }}>
                        {value}
                    </span>
                ),
            },
        },
        { name: 'created_by', label: 'Created By' },
        { name: 'updated_by', label: 'Updated By' },
        {
            name: 'createdAt',
            label: 'Created At',
            options: {
                customBodyRender: (value) => format(new Date(value), 'dd/MM/yyyy HH:mm:ss'),
            },
        },
        {
            name: 'updatedAt',
            label: 'Updated At',
            options: {
                customBodyRender: (value) => format(new Date(value), 'dd/MM/yyyy HH:mm:ss'),
            },
        },
        {
            name: '_id',
            label: 'Actions',
            options: {
                customBodyRender: (value, tableMeta) => (
                    <>
                        <IconButton onClick={() => handleView(tableMeta.rowData[5])} color="primary">
                            <VisibilityIcon />
                        </IconButton>
                        <IconButton onClick={() => handleEdit(tableMeta.rowData[5])} color="secondary">
                            <EditIcon />
                        </IconButton>
                    </>
                ),
            },
        },
    ];

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
    };

    const customTheme = createTheme({
        components: {
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#dc3545', // Change this to your desired color
                        color: 'white',
                    },
                },
            },
        },
    });

    return (
        <>
            <ToastContainer />
            <div>
                <div className='row'>
                    <div className='col-md-12'>
                        <button className='btn btn-primary mb-2' onClick={() => { navigate(`/${userTypeProps}/${routeProps}/add-new-notes/${id}`) }} style={{float:'right'}}>Create Notes</button>
                    </div>
                </div>
                <ThemeProvider theme={customTheme}>
                    <MUIDataTable
                        title={`Notes List (${leads?.total_count ?? 0})`}
                        data={leads.notes}
                        columns={columns}
                        options={options}
                    />
                </ThemeProvider>
            </div>
            {editMode && (
                <Dialog
                    open={editMode}
                    onClose={handleCloseEdit}
                    maxWidth="md"
                    fullWidth
                >
                    <DialogTitle>Edit Note</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            name="title"
                            label="Title"
                            type="text"
                            fullWidth
                            value={currentNote?.title || ''}
                            onChange={handleChange}
                        />
                        <ReactQuill
                            placeholder="Description"
                            style={{ height: "200px" }}
                            value={currentNote?.description || ''}
                            onChange={handleDescriptionChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseEdit} color="primary">
                            Cancel
                        </Button>
                        <button onClick={handleSaveEdit} className='btn btn-primary'>
                            Save
                        </button>
                    </DialogActions>
                </Dialog>
            )}

            {viewMode && (
                <Dialog open={viewMode} onClose={handleCloseEdit} maxWidth="md" fullWidth>
                    <DialogTitle>View Note</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            name="title"
                            label="Title"
                            type="text"
                            fullWidth
                            value={currentNote?.title || ''}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <ReactQuill
                            // theme="snow"
                            style={{ height: '200px' }}
                            value={currentNote?.description || ''}
                            readOnly={true}
                            modules={{ toolbar: false }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseEdit} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
