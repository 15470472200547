//React Default packageImport
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
//Reusable Component Import
import CustomModal from "../CustomModal";
import { EmailValidation, PhoneNumberValidation, StringValidation } from "../../Store/validate";
import {HandleCCAvenuePaymentPkg,HandleCCAvenuePaymentPkgNL} from "../../Store/util";

//API Include
import { getAllPackagesUserAuto} from "../../Api/Api"


//Assets Import
import SplImg from "../../Assets/Images/special-offer-img.webp";
import Norecord from "../../Assets/Images/MicrosoftTeams-image.webp";

export function MoreOffers(props) {


    const navigate = useNavigate();
    //---------------------------- Array Starts 
    const [packageList, setPackageList] = useState([]);
    //---------------------------- Boolean Starts 
    const [moreOfferInfo, setMoreOffersInfo] = useState(false);
    const [requestForm, setRequestForm] = useState(false);
    const [form, setForm] = useState(false);
    //---------------------------- Boolean Ends 
    //---------------------------- Object Starts
    const [serviceReqValidation1, setServiceReqValidation1] = useState({});
    const [serviceReq1, setServiceReq1] = useState({});
    //Props
    const { onClickOutside, open, children } = props;
    const state = useSelector((state) => state);

    useEffect(() => {
        getPackageList()
    }, []);

    useEffect(() => {
        setMoreOffersInfo(open);
        if (open) {
            document.documentElement.classList.add("modal-open");
        } else {
            document.documentElement.classList.remove("modal-open");
        }
    }, [open]);
    const getPackageList = async () => {
        const response = await getAllPackagesUserAuto();
        if (response.statusCode === 200) {
            setPackageList(response.data.filter((e) => e.customPackage === "Auto"));
        } else {
            setPackageList([]);
        }
    };
    

    
    
    const setServiceReqValue1 = (key, value) => {
        setServiceReq1({ ...serviceReq1, [key]: value });
        if (serviceReqValidation1[key]) delete serviceReqValidation1[key];
    };
    const setServiceReqValidationValue1 = (key, value) => {
        setServiceReqValidation1({ ...serviceReqValidation1, [key]: value });
    };
    const handleOrder = async (e, amount, type, id, Logintype) => {
        HandleCCAvenuePaymentPkgNL(localStorage?.getItem("LPrice"), localStorage?.getItem("LId"), false, serviceReq1.name, serviceReq1.mobileNumber, serviceReq1.email);
    };
    return (
        <>
            {/* Payment Model */}
            <div className="book-now-modal-width">
            <CustomModal
                open={open}
                onClickOutside={() => {
                    setMoreOffersInfo(false);
                }}
            >
                <div
                    className="modal-content web-height-fix w-100 m-auto h-100">
                    <div className="modal-body p-0 ">
                        <div className="container ">
                            <span
                                className="material-symbols-rounded filled-icon close-icon"
                                style={{
                                    marginRight: "30px",
                                    marginTop: "50px",
                                    textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                                }}
                                onClick={() => {
                                    onClickOutside()
                                }}
                            >
                                close
                            </span>
                            <div className="row d-flex align-items-center justify-content-center  mt-0">
                                <div className="container">
                                    <div className="mt-4 mb-4">
                                        <h4 className="fw-bold mt-2">
                                            Retainer Packages with Lots of benefits
                                        </h4>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-lg-12 ">
                                            <div className=" my-3 px-3">
                                                <div className="row justify-content-center mt-2">
                                                    <div className="col-lg-12">
                                                        <div className="row ">
                                                            <>
                                                                {packageList?.length ? (
                                                                    <>
                                                                        {packageList?.map((e, i) => (
                                                                            <div
                                                                                className="col-lg-3"
                                                                                style={{ position: "relative" }}
                                                                                key={i}
                                                                            >
                                                                                <img
                                                                                    className="spl-offer-img"
                                                                                    src={SplImg}
                                                                                    alt=""
                                                                                />
                                                                                <div className="admin-service-wheeler-buy-now">
                                                                                    <div className="px-2 py-2 offer-head-sec">
                                                                                        {e.title?.toUpperCase()}
                                                                                    </div>
                                                                                    <div className="scroll-offer-modal">
                                                                                        <div className="m-3">
                                                                                            {e.services?.map((s) => (
                                                                                                <>
                                                                                                    <p className="text-white mx-1 my-1">
                                                                                                        <span className="material-symbols-rounded align-middle pe-1 filled-icon">
                                                                                                            check_circle
                                                                                                        </span>
                                                                                                        {s.servicesName}{" "}
                                                                                                    </p>
                                                                                                </>
                                                                                            ))}
                                                                                        </div>
                                                                                        <div
                                                                                            className="btn-holder-tc"
                                                                                            dangerouslySetInnerHTML={{
                                                                                                __html: e?.description,
                                                                                            }}
                                                                                        ></div>
                                                                                    </div>
                                                                                    <div className="btn-holder">
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn  buy-now-offer-btn"
                                                                                            onClick={(val) => {
                                                                                                if (state?.auth?.isLoggedIn) {
                                                                                                    HandleCCAvenuePaymentPkg(
                                                                                                        Number(e.price) +
                                                                                                        Number(e.gstPercentage),
                                                                                                        e._id,
                                                                                                        true,
                                                                                                        state?.auth?.auth?.token,
                                                                                                    );
                                                                                                } else {
                                                                                                    setRequestForm(true);
                                                                                                    localStorage?.setItem(
                                                                                                        "LPrice",
                                                                                                        Number(e.price) +
                                                                                                        Number(e.gstPercentage)
                                                                                                    );
                                                                                                    localStorage?.setItem(
                                                                                                        "LType",
                                                                                                        "package"
                                                                                                    );
                                                                                                    localStorage?.setItem(
                                                                                                        "LId",
                                                                                                        e._id
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                        >
                                                                                            Buy Now at ₹{" "}
                                                                                            {Number(e.price) +
                                                                                                Number(e.gstPercentage)}
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </>
                                                                ) : (
                                                                    <div className="text-center mt-5">
                                                                        <img
                                                                            src={Norecord}
                                                                            alt=""
                                                                            style={{
                                                                                width: "200px",
                                                                                height: "200px",
                                                                                // marginLeft: "500px",
                                                                            }}
                                                                            className="img-fluid"
                                                                        />
                                                                    </div>
                                                                )}
                                                            </>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CustomModal>
            </div>
            {/* If user have not login Model */}
            <CustomModal
                open={requestForm}
                onClickOutside={() => {
                    setRequestForm(false);
                }}
            >
                <div className="modal-content w-50 m-auto">
                    <div className="modal-body p-0 ">
                        <div className="container ">
                            <span
                                className="material-symbols-rounded filled-icon close-icon"
                                style={{
                                    marginRight: "30px",
                                    marginTop: "50px",
                                    textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                                }}
                                onClick={() => {
                                    setRequestForm(false);
                                }}
                            >
                                close
                            </span>
                            <div className="row d-flex align-items-center justify-content-center  mt-0">
                                <div className="container ">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-12">
                                            <div className=" my-5 px-3">
                                                <div className="row justify-content-center mt-2">
                                                    <div className="mb-2">
                                                        <b>
                                                            Please fill the details
                                                        </b>
                                                    </div>
                                                    <hr />
                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="mb-4">
                                                            <label className="form-label">
                                                                Name <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter Your Name"
                                                                aria-invalid={Boolean(
                                                                    serviceReqValidation1?.name?.status ===
                                                                    false
                                                                )}
                                                                defaultValue={serviceReq1?.name}
                                                                onChange={(e) => {
                                                                    setServiceReqValue1("name", e.target.value);
                                                                }}
                                                                onBlur={(e) => {
                                                                    setServiceReqValidationValue1(
                                                                        "name",
                                                                        StringValidation(e.target.value)
                                                                    );
                                                                }}
                                                            />
                                                            <small className="text-danger">
                                                                {serviceReqValidation1?.name?.message
                                                                    ? `Name ${serviceReqValidation1?.name?.message}`
                                                                    : ""}
                                                            </small>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="mb-4">
                                                            <label className="form-label">
                                                                Mobile No{" "}
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                type="tel"
                                                                maxLength={10}
                                                                className="form-control"
                                                                defaultValue={serviceReq1?.mobileNumber}
                                                                placeholder="Enter Your Mobile No"
                                                                aria-invalid={Boolean(
                                                                    serviceReqValidation1?.mobileNumber
                                                                        ?.status === false
                                                                )}
                                                                onChange={(e) => {
                                                                    setServiceReqValue1(
                                                                        "mobileNumber",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                onBlur={(e) => {
                                                                    setServiceReqValidationValue1(
                                                                        "mobileNumber",
                                                                        PhoneNumberValidation(e.target.value)
                                                                    );
                                                                }}
                                                            />
                                                            <small className="text-danger">
                                                                {serviceReqValidation1?.mobileNumber?.message
                                                                    ? `Mobile no ${serviceReqValidation1?.mobileNumber?.message}`
                                                                    : ""}
                                                            </small>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="mb-4">
                                                            <label className="form-label">
                                                                E- Mail ID{" "}
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                type="email"
                                                                className="form-control"
                                                                placeholder="Enter Your E- Mail ID"
                                                                aria-invalid={Boolean(
                                                                    serviceReqValidation1?.email?.status ===
                                                                    false
                                                                )}
                                                                defaultValue={
                                                                    serviceReq1?.email?.toLowerCase() || ""
                                                                }
                                                                onChange={(e) => {
                                                                    setServiceReqValue1(
                                                                        "email",
                                                                        e.target.value?.toLowerCase()
                                                                    );
                                                                }}
                                                                onBlur={(e) => {
                                                                    setServiceReqValidationValue1(
                                                                        "emailId",
                                                                        EmailValidation(
                                                                            e.target.value
                                                                        )?.toLowerCase()
                                                                    );
                                                                }}
                                                            />
                                                            <small className="text-danger">
                                                                {serviceReqValidation1?.email?.message
                                                                    ? `Email ${serviceReqValidation1?.email?.message}`
                                                                    : ""}
                                                            </small>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <button
                                                            type="button"
                                                            className="btn login-btn w-100"
                                                            onClick={(e) => {
                                                                HandleCCAvenuePaymentPkgNL(
                                                                    localStorage?.getItem("LPrice"),
                                                                    localStorage?.getItem("LId"),
                                                                    false,
                                                                    serviceReq1?.name,
                                                                    serviceReq1?.mobileNumber,
                                                                    serviceReq1?.email,
                                                                );
                                                            }}
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CustomModal>
        </>
    )
}