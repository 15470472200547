import React, { useEffect } from 'react';
import Navigation from '../Components/Navbar/Navigation'
import Footer from '../Components/Footer/Footer'
import DMBanner from '../Components/DigitalMarketing/DMBanner'
import DMContent from '../Components/DigitalMarketing/DMContent'
import {Helmet} from "react-helmet";
export default function Marketing() {
  useEffect(() =>{
    window.scroll(0,0)
  },[])
  return (
    <div  className="innerpages-nav">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Marketing | DALMaf Private Limited</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="Marketing Information" />
        <meta name="keywords" content="Marketing Information" />
        <meta name="author" content="DALMaf" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>
        <div id="container">
      <Navigation />
      <DMBanner />
      <DMContent />
      <Footer />
      </div>
    </div>
  )
}
