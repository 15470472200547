import React, { useEffect, useRef, useState } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";

import { useSelector } from "react-redux";
import {
  GetContent,
  GetContentAll,
  GetLevel1,
  GetLevel1All,
  GetLevel2,
  GetLevel2All,
  GetMain,
  GetSpecificContent,
  GetSpecificTask,
  GetUser,
  UpdateContent,
  UpdateTaskAdmin,
  UploadDocument,
} from "../../../Api/Api";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import SunEditor from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';
import { useNavigate, useParams } from "react-router-dom";
import {
  NonEmptyValidation,
  NonEmptyValidationForDate,
  ExpireDateValidation,
  NumberValidation,
} from "../../../Store/validate";
import { GetAllSubAdminAndAdmin } from "../../../Api/Api";
// let htmlToDraft = null;
// if (typeof window === "object") {
//   htmlToDraft = require("html-to-draftjs").default;
// }

export default function ContentEdit() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const { id } = useParams();
  
  useEffect(() => {
    GetMySpecificTsks();
    GetMyTsksMain();
    GetMyTsksAll("active");
    GetMyTsksLevel1All("active");
    GetMyTsksLevel2All("active");
  }, []);
 
  
  const [getMyTask, setGetMyTask] = useState([]);
  const [getMyTaskMain, setGetMyTaskMain] = useState([]);
  const [getMyTasklevel1, setGetMyTasklevel1] = useState([]);
  const [getMyTasklevel2, setGetMyTasklevel2] = useState([]);
  const GetMyTsksMain = async () => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await GetMain("active");
    if (data && data.statusCode === 200) {
      setGetMyTaskMain(data.data);
      setLoading(false);
    } else {
      setGetMyTaskMain([]);
      setLoading(false);
      //console.log("Error while getting my task");
    }
  };

  const GetMyTsksLevel1 = async (status, id) => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await GetLevel1(status, id);
    if (data && data.statusCode === 200) {
      setGetMyTasklevel1(data.data);
      setLoading(false);
    } else {
      setGetMyTasklevel1([]);
      setLoading(false);
      //console.log("Error while getting my task");
    }
  };
  const GetMyTsksLevel1All = async (status) => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await GetLevel1All(status);
    if (data && data.statusCode === 200) {
      setGetMyTasklevel1(data.data);
      setLoading(false);
    } else {
      setGetMyTasklevel1([]);
      setLoading(false);
      //console.log("Error while getting my task");
    }
  };

  const GetMyTsksLevel2 = async (status, id, id1) => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await GetLevel2(status, id, id1);
    if (data && data.statusCode === 200) {
      setGetMyTasklevel2(data.data);
      setLoading(false);
    } else {
      setGetMyTasklevel2([]);
      setLoading(false);
      //console.log("Error while getting my task");
    }
  };

  const GetMyTsksLevel2All = async (status) => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await GetLevel2All(status);
    if (data && data.statusCode === 200) {
      setGetMyTasklevel2(data.data);
      setLoading(false);
    } else {
      setGetMyTasklevel2([]);
      setLoading(false);
      //console.log("Error while getting my task");
    }
  };

  const GetMyTsks = async (status, id) => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await GetContent(status, id);
    if (data && data.statusCode === 200) {
      setGetMyTask(data.data);
      setLoading(false);
    } else {
      setGetMyTask([]);
      setLoading(false);
      //console.log("Error while getting my task");
    }
  };

  const GetMyTsksAll = async (status) => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await GetContentAll(status);
    if (data && data.statusCode === 200) {
      setGetMyTask(data.data);
      setLoading(false);
    } else {
      setGetMyTask([]);
      setLoading(false);
      //console.log("Error while getting my task");
    }
  };

  const CeoRef = useRef();

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  const [myTask1, setMyTask1] = useState({});
  const [content, setContent] = useState("");
  const [content1, setContent1] = useState("");
  const [price, setPrice] = useState(false);
  const [price1, setPrice1] = useState(false);
  const [myTaskValidation1, setMyTaskValidation1] = useState({});

  const setMyTaskValue1 = (parentKey, childKey, value, type) => {
    if (type === "object") {
      setMyTask1({
        ...myTask1,
        ...{ [parentKey]: { ...myTask1[parentKey], [childKey]: value } },
      });
      if (myTaskValidation1[parentKey]) delete myTaskValidation1[parentKey];
      if (myTaskValidation1[childKey]) delete myTaskValidation1[childKey];
    } else {
      // console.log(parentKey, childKey, value, myTask1)
      setMyTask1({
        ...myTask1,
        [childKey]: value,
      });
      if (myTaskValidation1[parentKey]) delete myTaskValidation1[parentKey];
      if (myTaskValidation1[childKey]) delete myTaskValidation1[childKey];
    }
  };

  const setMyTaskValidationValue1 = (parentKey, childKey, value, type) => {
    if (type === "object") {
      setMyTaskValidation1({
        ...myTaskValidation1,
        ...{
          [parentKey]: {
            ...myTaskValidation1[parentKey],
            [childKey]: value,
          },
        },
      });
    } else {
      setMyTaskValidation1({
        ...myTaskValidation1,
        [childKey]: value,
      });
    }
  };
  const GetMySpecificTsks = async () => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await GetSpecificContent(id);
    if (data && data.statusCode === 200) {
      // let dummy = data.data;
      // let cmsDescription = EditorState.createEmpty();
      // if (dummy.content) {
      //   let blocksFromHtml = htmlToDraft(dummy.content);
      //   let { contentBlocks, entityMap } = blocksFromHtml;
      //   let contentState = ContentState.createFromBlockArray(
      //     contentBlocks,
      //     entityMap
      //   );
      //   cmsDescription = EditorState.createWithContent(contentState);
      // }
      // dummy.content = cmsDescription;
      // let cmsDescription1 = EditorState.createEmpty();
      // if (dummy.serviceIncludedIn) {
      //   let blocksFromHtml = htmlToDraft(dummy.serviceIncludedIn);
      //   let { contentBlocks, entityMap } = blocksFromHtml;
      //   let contentState = ContentState.createFromBlockArray(
      //     contentBlocks,
      //     entityMap
      //   );
      //   cmsDescription1 = EditorState.createWithContent(contentState);
      // }
      // dummy.serviceIncludedIn = cmsDescription1;
      //console.log(dummy);
      setMyTask1(data?.data);
      let con = data?.data?.content
      let con1 = data?.data?.serviceIncludedIn
      setContent(con);
      setContent1(con1);
      setLoading(false);
    } else {
      setMyTask1([]);
      setContent({})
      setContent1({})
      setLoading(false);
      //console.log("Error while getting my specific task");
    }
  };

  const [change, setChange] = useState(false)
  const [change1, setChange1] = useState(false)

  const handleUpdateSubmit = async (e) => {
    // console.log("myTask1", myTask1);
    e.preventDefault();
   
    let Payload = {
        _id: myTask1?._id,
        status: myTask1?.status,
        content: change ? content : myTask1?.content,
        mainServiceId: myTask1?.mainServiceId?._id ? myTask1?.mainServiceId?._id : myTask1?.mainServiceId,
        subServiceL1Id: myTask1?.subServiceL1Id?._id ? myTask1?.subServiceL1Id?._id : myTask1?.subServiceL1Id,
        subServiceL2Id: myTask1?.subServiceL2Id?._id ? myTask1?.subServiceL2Id?._id : myTask1?.subServiceL2Id,
        dynamicPricingFlag: myTask1?.dynamicPricingFlag,
        dynamicServiceFlag: myTask1?.dynamicServiceFlag,
        serviceIncludedIn:  change1 ? content1 :myTask1?.serviceIncludedIn,
        addBy: myTask1?.addBy?._id ? myTask1?.addBy?._id : state?.auth?.auth?._id,
        pricing: {
          marketPrice: myTask1?.pricing?.marketPrice  ,
          companyPrice: myTask1?.pricing?.companyPrice,
          gst: myTask1?.pricing?.gst,
        },
      };
   
    // console.log(Payload);
    const token = state?.auth?.auth?.token;
    // console.log(Payload)
    const response = await UpdateContent(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      window.scrollTo(0, 0);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
 
  };

  return (
    <div>
      <AdminNavbar />
      <ToastContainer />
      <div className="main">
        <div>
          <div className="">
            <button
              type="button"
              className="btn login-btn ms-2 "
              onClick={(e) => {
                navigate("/Content");
              }}
            >
              Back
            </button>
          </div>
          <div className=" my-4">
            <div className="bg-historytablehead rounded-3 py-3 px-3">
              <h6 className="mb-0">Update Content</h6>
            </div>
            <div className="container">
              <div className="row justify-content-center mt-2">
                <div className="col-lg-12">
                  <div className="">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="mb-4">
                          <label className="form-label">Main Service</label>
                          <select
                            className="form-control"
                            style={{
                              textTransform: "capitalize",
                            }}
                            aria-invalid={Boolean(
                              myTaskValidation1?.mainServiceId?.status === false
                            )}
                            // disabled
                            value={myTask1?.mainServiceId?.title}
                            onChange={(e) => {
                              let name = getMyTaskMain?.filter(
                                (s) => s.title === e.target.value
                              );
                              let nameId = name?.map((n) => {
                                return n._id;
                              });
                              setMyTaskValue1(
                                "",
                                "mainServiceId",
                                nameId.toString(),
                                ""
                              );
                              GetMyTsksLevel1("active", nameId.toString());
                            }}
                            // onBlur={(e) => {
                            //   let name = getMyTaskMain?.filter(
                            //     (s) => s.title === e.target.value
                            //   );
                            //   let nameId = name?.map((n) => {
                            //     return n._id;
                            //   });
                            //   setMyTaskValidationValue1(
                            //     "",
                            //     "mainServiceId",
                            //     NonEmptyValidation(
                            //       nameId.toString()
                            //     ),
                            //     ""
                            //   );
                            // }}
                          >
                            <option
                              value="
                                            "
                            >
                              ---Select Service---
                            </option>
                            {getMyTaskMain?.map((e) => (
                              <option value={e.title}>{e.title}</option>
                            ))}
                          </select>
                          {/* <small className="text-danger">
                                            {myTaskValidation1?.mainServiceId
                                              ?.message
                                              ? `Main Service ${myTaskValidation1?.mainServiceId?.message}`
                                              : ""}
                                          </small> */}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="mb-4">
                          <label className="form-label">Level 1</label>
                          <select
                            className="form-control"
                            style={{
                              textTransform: "capitalize",
                            }}
                            aria-invalid={Boolean(
                              myTaskValidation1?.subServiceL1Id?.status ===
                                false
                            )}
                            // disabled
                            value={myTask1?.subServiceL1Id?.title}
                            onChange={(e) => {
                              let name = getMyTasklevel1?.filter(
                                (s) => s.title === e.target.value
                              );
                              let nameId = name?.map((n) => {
                                return n._id;
                              });
                              setMyTaskValue1(
                                "",
                                "subServiceL1Id",
                                nameId.toString(),
                                ""
                              );
                              GetMyTsksLevel2(
                                "active",
                                myTask1?.mainServiceId,
                                nameId.toString()
                              );
                            }}
                            // onBlur={(e) => {
                            //   let name = getMyTasklevel1?.filter(
                            //     (s) => s.title === e.target.value
                            //   );
                            //   let nameId = name?.map((n) => {
                            //     return n._id;
                            //   });
                            //   setMyTaskValidationValue1(
                            //     "",
                            //     "subServiceL1Id",
                            //     NonEmptyValidation(
                            //       nameId.toString()
                            //     ),""
                            //   );
                            // }}
                          >
                            <option
                              value=" "
                            >
                              ---Select Service---
                            </option>
                            {getMyTasklevel1?.map((e) => (
                              <option value={e.title}>{e.title}</option>
                            ))}
                          </select>
                          {/* <small className="text-danger">
                                            {myTaskValidation1?.subServiceL1Id
                                              ?.message
                                              ? `Level 1 Service ${myTaskValidation1?.subServiceL1Id?.message}`
                                              : ""}
                                          </small> */}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="mb-4">
                          <label className="form-label">Level 2</label>
                          <select
                            className="form-control"
                            style={{
                              textTransform: "capitalize",
                            }}
                            aria-invalid={Boolean(
                              myTaskValidation1?.subServiceL2Id?.status ===
                                false
                            )}
                            // disabled
                            value={myTask1?.subServiceL2Id?.title}
                            onChange={(e) => {
                              let name = getMyTasklevel2?.filter(
                                (s) => s.title === e.target.value
                              );
                              let nameId = name?.map((n) => {
                                return n._id;
                              });
                              setMyTaskValue1(
                                "",
                                "subServiceL2Id",
                                nameId.toString(),
                                ""
                              );
                            }}
                            // onBlur={(e) => {
                            //   let name = getMyTasklevel2?.filter(
                            //     (s) => s.title === e.target.value
                            //   );
                            //   let nameId = name?.map((n) => {
                            //     return n._id;
                            //   });
                            //   setMyTaskValidationValue1(
                            //     "",
                            //     "subServiceL2Id",
                            //     NonEmptyValidation(
                            //       nameId.toString()
                            //     ),""
                            //   );
                            // }}
                          >
                            <option
                              value="
                                            "
                            >
                              ---Select Service---
                            </option>
                            {getMyTasklevel2?.map((e) => (
                              <option value={e.title}>{e.title}</option>
                            ))}
                          </select>
                          {/* <small className="text-danger">
                                            {myTaskValidation1?.subServiceL2Id
                                              ?.message
                                              ? `Level 2 Service ${myTaskValidation1?.subServiceL2Id?.message}`
                                              : ""}
                                          </small> */}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="mb-4">
                          <label className="form-label">Content</label>
                          <div
                            className="form-control form-control-lg"
                            // style={{ height: "400px", overflowY: "scroll" }}
                          >
                            {/* <Editor
                              wrapperClassName="wrapper-class"
                              editorClassName="editor-class"
                              toolbarClassName="toolbar-class"
                              editorState={myTask1?.content}
                              onEditorStateChange={(e) =>
                                setMyTaskValue1("", "content", e, "")
                              }
                            /> */}
                               <SunEditor
                                  setOptions={{
                                    height: 400,
                                    buttonList: [
                                      ["undo", "redo"],
                                      ["font", "fontSize", "formatBlock"],
                                      [
                                        "bold",
                                        "underline",
                                        "italic",
                                        "strike",
                                        "subscript",
                                        "superscript",
                                      ],
                                      ["fontColor", "hiliteColor", "textStyle"],
                                      ["removeFormat"],
                                      "/", // Line break
                                      [
                                        "align",
                                        "horizontalRule",
                                        "list",
                                        "lineHeight",
                                      ],
                                      ["table", "link", "image", "video"],
                                      ["fullScreen", "showBlocks", "codeView"],
                                      ["preview", "print"],
                                      ["save", "template"],
                                    ],
                                  }}
                                  setContents={content}
                                  onChange={(e) => {
                                    setChange(true)
                                    setContent(e);
                                  }}
                                />
                          </div>

                          {/* <small className="text-danger">
                                            {myTaskValidation1?.content?.message
                                              ? `Content ${myTaskValidation1?.content?.message}`
                                              : ""}
                                          </small> */}
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="form-label ">Status</label>

                          <select
                            className="form-control form-control-lg"
                            defaultValue={myTask1?.status}
                            onChange={(e) => {
                              setMyTaskValue1("", "status", e.target.value, "");
                            }}
                            onBlur={(e) => {
                              setMyTaskValidationValue1(
                                "",
                                "status",
                                NonEmptyValidation(e.target.value),
                                ""
                              );
                            }}
                          >
                            <option value="active">Active</option>

                            <option value="inactive">Inactive</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="mb-4">
                          <input
                            type="checkbox"
                            defaultChecked={myTask1?.dynamicPricingFlag}
                            defaultValue={myTask1?.dynamicPricingFlag}
                            onChange={(e) => {
                              setMyTaskValue1(
                                "",
                                "dynamicPricingFlag",
                                e.target.checked,
                                ""
                              );
                              if (e.target.checked === true) {
                                setPrice(true);
                              } else {
                                setPrice(false);
                              }
                            }}
                          />{" "}
                          <label className="form-label">
                            Dynamic Pricing Flag
                          </label>
                        </div>
                      </div>
                      {myTask1?.pricing || price ? (
                        <>
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label">Market Price</label>
                              <input
                                type="text"
                                className="form-control"
                                required
                                defaultValue={myTask1?.pricing?.marketPrice}
                                onChange={(e) => {
                                  setMyTaskValue1(
                                    "pricing",
                                    "marketPrice",
                                    e.target.value,
                                    "object"
                                  );
                                }}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label">
                                Company Price
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={myTask1?.pricing?.companyPrice}
                                onChange={(e) => {
                                  setMyTaskValue1(
                                    "pricing",
                                    "companyPrice",
                                    e.target.value,
                                    "object"
                                  );
                                }}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label">GST</label>
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={myTask1?.pricing?.gst}
                                onChange={(e) => {
                                  setMyTaskValue1(
                                    "pricing",
                                    "gst",
                                    e.target.value,
                                    "object"
                                  );
                                }}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      <div className="col-lg-12">
                        <div className="mb-4">
                          <input
                            type="checkbox"
                            defaultValue={myTask1?.dynamicServiceFlag}
                            defaultChecked={
                              myTask1?.dynamicServiceFlag
                            }
                            onChange={(e) => {
                              setMyTaskValue1(
                                "",
                                "dynamicServiceFlag",
                                e.target.checked,
                                ""
                              );

                              if (e.target.checked === true) {
                                setPrice1(true);
                              } else {
                                setPrice1(false);
                              }
                            }}
                          />{" "}
                          <label className="form-label">
                            Dynamic Service Flag
                          </label>
                        </div>
                      </div>
                      {price1 || myTask1?.dynamicServiceFlag ? (
                        <>
                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">
                                Service Included In
                              </label>
                              <div
                                className="form-control form-control-lg"
                                // style={{ height: "400px", overflowY: "scroll" }}
                              >
                                {/* <Editor
                                  wrapperClassName="wrapper-class"
                                  editorClassName="editor-class"
                                  toolbarClassName="toolbar-class"
                                  editorState={myTask1?.serviceIncludedIn}
                                  onEditorStateChange={(e) =>
                                    setMyTaskValue1(
                                      "",
                                      "serviceIncludedIn",
                                      e,
                                      ""
                                    )
                                  }
                                /> */}

<SunEditor
                                  setOptions={{
                                    height: 400,
                                    buttonList: [
                                      ["undo", "redo"],
                                      ["font", "fontSize", "formatBlock"],
                                      [
                                        "bold",
                                        "underline",
                                        "italic",
                                        "strike",
                                        "subscript",
                                        "superscript",
                                      ],
                                      ["fontColor", "hiliteColor", "textStyle"],
                                      ["removeFormat"],
                                      "/", // Line break
                                      [
                                        "align",
                                        "horizontalRule",
                                        "list",
                                        "lineHeight",
                                      ],
                                      ["table", "link", "image", "video"],
                                      ["fullScreen", "showBlocks", "codeView"],
                                      ["preview", "print"],
                                      ["save", "template"],
                                    ],
                                  }}
                                  setContents={content1}
                                  onChange={(e) => {
                                    setChange1(true)
                                    setContent1(e);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      <div className="col-lg-12">
                        <button
                          type="button"
                          className="btn login-btn ms-2 "
                          onClick={(e) => {
                            handleUpdateSubmit(e);
                          }}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
