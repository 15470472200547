import React, { Fragment, useState, useEffect } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";

import AdminPro from "../../../Assets/Images/admin-pro.webp";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreatableSelect from "react-select/creatable";

import { alpha, styled } from "@mui/material/styles";
import { pink } from "@mui/material/colors";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { format } from "date-fns";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  CreateDepartment,
  CreateTimeslot,
  Createadmin,
  GetAllEmployeeRole,
  GetDepartment,
  GetTimeslot,
  ListingEmployeeOnrole,
  UploadDocument,
  UserProfileUpdate,
} from "../../../Api/Api";
import {
  DateValidation,
  DateValidationn,
  EmailValidation,
  NonEmptyObjectValidation,
  NonEmptyValidation,
  NonEmptyValidationForDate,
  NonEmptyValidationn,
  PhoneNumberValidation,
  StringValidation,
} from "../../../Store/validate";
import { useRef } from "react";
import MUIDataTable from "mui-datatables";

export default function EmployeeManagement() {
  const navigate = useNavigate();
  //  useEffect(() => {
  //    getListEmployee("active");
  //  }, []);

  const [selectedTab, setSelectedTab] = React.useState("Active Members");
  const AllTabs = ["Active Members", "Inactive Members"];

  const [addEmployeeManagement, setAddEmployeeManagement] = useState(true);

  const [showGeneralInfo, setShowGeneralInfo] = useState(true);
  const [showJobInfo, setShowJobInfo] = useState(false);
  const [showAccountSetting, setShowAccountSetting] = useState(false);
  const [active, setActive] = useState("GeneralInfo");
 
  const [listEmployee, setListEmployee] = useState([]);
 

  useEffect(() => {
    getListsubadmin("active");
  }, []);

  const getListsubadmin = async (status) => {
    const token = state?.auth?.auth?.token;
    const response = await ListingEmployeeOnrole(
      token,
      status
      // selectedTab === "Active Members" ? "active" : "inactive"
    );
    if (response.statusCode === 200) {
      setListEmployee(response.data);
    } else {
      
      setListEmployee([]);
    }
  };

  useEffect(() => {
    window.scroll(0, 0);

    // getListEmployee("active");
    getDepartmentlist();
    getTimeslotlist()
  }, []);
  // const [listEmployeeRole, setListEmployeeRole] = useState([]);
  // const getListEmployee = async (status) => {
  //   const token = state?.auth?.auth?.token;
  //   const response = await GetAllEmployeeRole(token, status);
  //   if (response.statusCode === 200) {
  //     setListEmployeeRole(response.data.map((e) => e.role));
  //   } else {
  //     
  //     setListEmployee([]);
  //   }
  // };

  const [schedule, setSchedule] = useState([]);
  async function getDepartmentlist() {
    const token = state?.auth?.auth?.token;
    const getEmployeeSkills = await GetDepartment(token);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.departmentName,
          label: e.departmentName,
          _id: e._id,
        };
      });
      setSchedule(mappingData);
    }
  }

  const handleCreateDepartmentName = async (string) => {
    const valid = NonEmptyValidation(string);
    setRegisterValidationValue("departmentName", valid);
    if (valid.status) {
      const token = state?.auth?.auth?.token;
      const Payload = {
        departmentName: string,
      };
      const response = await CreateDepartment(Payload, token);
      if (response && response.statusCode === 200) {
        getDepartmentlist();

        const data = {
          value: response.data.departmentName,
          label: response.data.departmentName,
          _id: response.data._id,
        };
        if (registerDetail?.departmentName?.length) {
          let scheduleData = registerDetail?.departmentName;

          scheduleData.push(data);
          setRegisterDetailsValue("departmentName", scheduleData);
        } else {
          setRegisterDetailsValue("departmentName", [data]);
        }
      }
    }
  };

  const [timeslot, setTimeslot] = useState([]);
  async function getTimeslotlist() {
    const token = state?.auth?.auth?.token;
    const getEmployeeSkills = await GetTimeslot(token);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.timeSlot,
          label: e.timeSlot,
          _id: e._id,
        };
      });
      setTimeslot(mappingData);
    }
  }

  const handleCreateTimeslot = async (string) => {
    const valid = NonEmptyValidation(string);
    setRegisterValidationValue("timeSlot", valid);
    if (valid.status) {
      const token = state?.auth?.auth?.token;
      const Payload = {
        timeSlot: string,
      };
      const response = await CreateTimeslot(Payload, token);
      if (response && response.statusCode === 200) {
        getTimeslotlist();

        const data = {
          value: response.data.timeSlot,
          label: response.data.timeSlot,
          _id: response.data._id,
        };
        if (registerDetail?.timeSlot?.length) {
          let scheduleData = registerDetail?.timeSlot;

          scheduleData.push(data);
          setRegisterDetailsValue("timeSlot", scheduleData);
        } else {
          setRegisterDetailsValue("timeSlot", [data]);
        }
      }
    }
  };

  const optionList = [
    { value: "Monday", label: "Monday" },
    { value: "Tuesday", label: "Tuesday" },
    { value: "Wednesday", label: "Wednesday" },
    { value: "Thursday", label: "Thursday" },
    { value: "Friday", label: "Friday" },
    { value: "Saturday", label: "Saturday" },
    { value: "Sunday", label: "Sunday" },
  ];

 
  const state = useSelector((state) => state);
  // const getListEmployee = async (status) => {
  //   const token = state?.auth?.auth?.token
  //   const response = await ListAdminEmployeemanagment(token,
  //     status
  //     // selectedTab === "Inactive Members" ? "inactive" : "active"
  //     );
  //   if (response.statusCode === 200) {
  //     setListEmployee(response.data);
  //    
  //   } else {
  //     setListEmployee([]);
  //   }
  // };

  const [registerDetail, setRegisterDetails] = useState({});

  const [registerValidation, setRegisterValidation] = useState({});

  const setRegisterDetailsValue = (key, value) => {
    setRegisterDetails({ ...registerDetail, [key]: value });
    if (registerValidation[key]) delete registerValidation[key];
  };

  const setRegisterValidationValue = (key, value) => {
    setRegisterValidation({ ...registerValidation, [key]: value });
  };

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };
  const CeoRef = useRef();
  const naviGation = useNavigate();

  const [photoURL, setPhotoURL] = useState("");

  const generalInformation = () => {
    let validate = {};
    validate.name = StringValidation(registerDetail?.name);
    validate.email = EmailValidation(registerDetail?.email?.toLowerCase());
    validate.phoneNumber = NonEmptyValidation(registerDetail?.phoneNumber);
    validate.profileImage = NonEmptyValidationn(registerDetail?.profileImage);
    validate.employeeAddress = NonEmptyValidation(
      registerDetail?.employeeAddress
    );
    validate.gender = NonEmptyValidation(registerDetail?.gender);
    validate.DOB = NonEmptyValidation(registerDetail?.DOB);
    //console.log(validate)
    setRegisterValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      setShowGeneralInfo(false);
      setShowJobInfo(true);
    } else {
      //console.log("general info error");
    }
  };

  const jobInformation = (value) => {
    if (value === "back") {
      let validate = {};
      validate.departmentName = NonEmptyObjectValidation(
        registerDetail?.departmentName
      );
      validate.Designation = NonEmptyValidation(registerDetail?.Designation);
      validate.dateOfAppointment = NonEmptyValidation(
        registerDetail?.dateOfAppointment
      );
      // validate.password = NonEmptyValidation(registerDetail?.password);
      //console.log(validate)
      setRegisterValidation(validate);
      if (Object.values(validate).every((v) => v.status === true)) {
        setShowJobInfo(false);
        setShowGeneralInfo(true);
      } else {
        //console.log("job info error");
      }
    } else {
      let validate = {};
      validate.departmentName = NonEmptyObjectValidation(
        registerDetail?.departmentName
      );
      validate.Designation = NonEmptyValidation(registerDetail?.Designation);
      validate.dateOfAppointment = NonEmptyValidation(
        registerDetail?.dateOfAppointment
      );
      // validate.password = NonEmptyValidation(registerDetail?.password);
      //console.log(validate)
      setRegisterValidation(validate);
      if (Object.values(validate).every((v) => v.status === true)) {
        setShowJobInfo(false);
        setShowAccountSetting(true);
      } else {
        //console.log("job info error");
      }
    }
  };
 

  const accountInformation = (value) => {
    if (value === "back") {
      let validate = {};
      validate.workingDays = NonEmptyValidation(registerDetail?.workingDays);
      validate.timeSlot = NonEmptyValidation(registerDetail?.timeSlot);
      validate.ctc = NonEmptyValidation(registerDetail?.ctc);
      validate.salaryTerm = NonEmptyValidation(registerDetail?.salaryTerm);
      validate.pageAccess = NonEmptyValidation(registerDetail?.pageAccess);
      validate.employeeESI = NonEmptyValidation(registerDetail?.employeeESI);
      validate.PFId = NonEmptyValidation(registerDetail?.PFId);
      //console.log(validate)
      setRegisterValidation(validate);
      if (Object.values(validate).every((v) => v.status === true)) {
        setShowAccountSetting(false);
        setShowJobInfo(true);
      } else {
        //console.log("account info error");
      }
    } else {
      let validate = {};
      validate.workingDays = NonEmptyValidation(registerDetail?.workingDays);
      validate.timeSlot = NonEmptyValidation(registerDetail?.timeSlot);
      validate.ctc = NonEmptyValidation(registerDetail?.ctc);
      validate.salaryTerm = NonEmptyValidation(registerDetail?.salaryTerm);
      validate.pageAccess = NonEmptyValidation(registerDetail?.pageAccess);
      validate.employeeESI = NonEmptyValidation(registerDetail?.employeeESI);
      validate.PFId = NonEmptyValidation(registerDetail?.PFId);
      //console.log(validate)
      setRegisterValidation(validate);
      if (Object.values(validate).every((v) => v.status === true)) {
        handleSubmit();
      } else {
        //console.log("account info error");
      }
    }
  };

  //User Registration api call

  const handleSubmit = async (e) => {
   
    const Payload = {
      profileImage: registerDetail.profileImage,

      name: registerDetail.name,
      email: registerDetail.email?.toLowerCase(),
      phoneNumber: registerDetail.phoneNumber,
      employeeAddress: registerDetail.employeeAddress,
      gender: registerDetail.gender,
      DOB: registerDetail.DOB,

      departmentName:
        registerDetail?.departmentName?._id ||
        registerDetail?.departmentName?.map((e) => e._id.toString()),
      Designation: registerDetail.Designation,
      dateOfAppointment: registerDetail.dateOfAppointment,
      password: "Qwerty@123",

      
      workingDays: registerDetail?.workingDays?.map((v) => v.label),
      timeSlot: registerDetail?.timeSlot?._id ||
      registerDetail?.timeSlot?.map((e) => e._id.toString()),
      ctc: registerDetail.ctc,
      salaryTerm: registerDetail.salaryTerm,
      employeeESI: registerDetail.employeeESI,
      PFId: registerDetail.PFId,
      role:"subadmin",
      pageAccess: registerDetail.pageAccess,
      emailVerification: true,
      status: "active",
    };
    
  
    const response = await Createadmin(Payload);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      setRegisterDetails({});
      setAddEmployeeManagement(true);
      getListsubadmin("active");
      localStorage?.removeItem("account");
      localStorage?.removeItem("job");
      localStorage?.removeItem("general");
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
    
  };

  const uploadCeoPic = async (e) => {
    const file = e.target.files[0];
    if (file) {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("fileName", e.target.files[0].name);
      formData.append("path", "Image/");

      let response = await UploadDocument(formData);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        setPhotoURL(response.data);
        setRegisterDetailsValue("profileImage", response.data);
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    }
  };

  const PinkSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: pink[600],
      "&:hover": {
        backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: pink[600],
    },
  }));

  const label = { inputProps: { "aria-label": "Color switch demo" } };

  const handleSubmitUpdate = async (e, value, id) => {
    e.preventDefault();
    const Payload = {
      _id: id,
      status: value,
    };
    const token = state?.auth?.auth?.token;
    const response = await UserProfileUpdate(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      if (value !== "active") {
        setSelectedTab("Active Members");
        getListsubadmin("active");
      } else {
        setSelectedTab("Inactive Members");
        getListsubadmin("inactive");
      }
      // getListsubadmin("active");
      //  window.location.reload()
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setfailureModal(true);
    }
  };

  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <div
                style={{ cursor: "pointer" }}
                onClick={(val) => {
                  navigate(`/EmployeeProfileDetails/${tableMeta.rowData[0]}`);
                }}
              >
                {value}
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "email",
      label: "E- Mail ID",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "role",
      label: "Role",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "createdAt",
      label: "Joining Date",
      options: {
        customBodyRender: (value) => {
          return (
            <div>
              <div>{format(new Date(value), "dd/MM/yyyy")}</div>
              <p className="mb-0">
                At {format(new Date(value), "  h:mm:ss a ")}
              </p>
            </div>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              {value === "inactive" ? (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <PinkSwitch
                        {...label}
                        onChange={(e) => {
                          handleSubmitUpdate(e, "active", tableMeta.rowData[0]);
                        }}
                      />
                    }
                  />
                </FormGroup>
              ) : (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <PinkSwitch
                        {...label}
                        defaultChecked
                        onChange={(e) => {
                          handleSubmitUpdate(
                            e,
                            "inactive",
                            tableMeta.rowData[0]
                          );
                        }}
                      />
                    }
                  />
                </FormGroup>
              )}
            </>
          );
        },
      },
    },
    {
      name: "admin_read",
      label: "Status",
      options: {
        display: false,
      },
    },
    {
      name: "subadmin_read",
      label: "Status",
      options: {
        display: false,
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    setRowProps: (row) => {
      if (row[5] === false) {
        return {
          style: { background: "#DBDFEA", cursor: "pointer" },
        };
      } else {
        return {
          style: { background: "white", cursor: "pointer" },
        };
      }
    },
  };

  useEffect(() => {
    getListEmployeeRole("active");
  }, []);
  const [roleList, setRoleList] = useState([]);
  const getListEmployeeRole = async (status) => {
    const token = state?.auth?.auth?.token;
    const response = await GetAllEmployeeRole(token, status);
    if (response.statusCode === 200) {
      setRoleList(response.data);
    } else {
      
      setListEmployee([]);
    }
  };

  const [password, setPassword] = useState('')
  const [passwordLength, setPasswordLength] = useState(9)
  const [uppercase, setUppercase] = useState(true)
  const [lowercase, setLowercase] = useState(true)
  const [numbers, setNumbers] = useState(true)
  const [symbols, setSymbols] = useState(true)
  const [errors, setErrors] = useState({})

  const generatePassword = () => {
    setErrors({})
    if (!uppercase && !lowercase && !numbers && !symbols) {
      return setErrors('At least one character type must be selected')
    } else if (passwordLength === '0') {
      return setErrors('Password length cannot be 0')
    } else if (passwordLength === '') {
      return setErrors('Invalid password length')
    } else if (passwordLength > 80) {
      return setErrors('Password length cannot exceed 80 characters')
    }

    let password = ''
    for (let i = 0; i < passwordLength; i++) {
      let choice = random(0, 3)
      if (lowercase && choice === 0) {
        password += randomLower()
      } else if (uppercase && choice === 1) {
        password += randomUpper()
      } else if (symbols && choice === 2) {
        password += randomSymbol()
      } else if (numbers && choice === 3) {
        password += random(0, 9)
      } else {
        i--
      }
    }
    setPassword(password)
  }

  const random = (min = 0, max = 1) => {
    return Math.floor(Math.random() * (max + 1 - min) + min)
  }

  const randomLower = () => {
    return String.fromCharCode(random(97, 122))
  }

  const randomUpper = () => {
    return String.fromCharCode(random(65, 90))
  }

  const randomSymbol = () => {
    const symbols = "~*$%@#^&!?*'-=/,.{}()[]<>"
    return symbols[random(0, symbols.length - 1)]
  }

  useEffect(() => {
    generatePassword()
  }, [])


  return (
    <div>
      <AdminNavbar />
      <ToastContainer />
      <div className="main">
        {addEmployeeManagement ? (
          <div>
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="mb-0 fw-bold color-maroon">Employee Management</h5>
              <div>
                <button
                  type="button"
                  className="btn py-1 login-btn me-1"
                  onClick={() => setAddEmployeeManagement(false)}
                >
                  Add Employee
                </button>
              </div>
            </div>
            <div className="my-4">
              <div className="container">
                <div className="row">
                  <div className="col-lg-7 px-0">
                    <div className="tab-section">
                      <ul className="ps-0">
                        {AllTabs.map((tab, index) => {
                          return (
                            <div
                              key={index}
                              className={
                                selectedTab === tab
                                  ? "serviceTab active"
                                  : "serviceTab inactive"
                              }
                              onClick={() => {
                                getListsubadmin(
                                  tab === "Inactive Members"
                                    ? "inactive"
                                    : "active"
                                );
                                setSelectedTab(tab);
                              }}
                            >
                              <li>
                                <span className="align-middle">{tab}</span>
                              </li>
                            </div>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <MUIDataTable
                title={"Employee Management"}
                data={listEmployee}
                columns={columns}
                options={options}
              />
              {/* <table className="table">
                <thead className="bg-historytablehead">
                  <tr>
                    <th scope="col" style={{ borderTopLeftRadius: "0" }}>
                      S. No
                    </th>
                    <th scope="col">Name</th>
                    <th scope="col">E- Mail ID </th>
                    <th scope="col">Role </th>
                    <th scope="col">Joining Date</th>
                  </tr>
                </thead>
                <tbody className="historytablebody">
                  {listEmployee &&
                    listEmployee.length > 0 &&
                    listEmployee?.map((list, index) => {
                      return (
                        <tr
                          key={index}
                          className="cursor"
                          onClick={() =>
                            navigate(`/EmployeeProfileDetails/${list._id}`)
                          }
                        >
                          <td>
                            <div className="">
                              <h6 className="fw-bold">{index + 1}</h6>
                            </div>
                          </td>
                          <td scope="row">
                            <div className="d-flex align-items-center ">
                              <div className="flex-shrink-0">
                                {list.profileImage ? (
                                  <img
                                    style={{ width: "60px", height: "60px" }}
                                    src={list.profileImage}
                                    alt="..."
                                  />
                                ) : (
                                  <span className="material-symbols-rounded filled-icon dash-icon">
                                    person
                                  </span>
                                )}
                              </div>
                              <div className=" ms-3">
                                <h6 className="fw-bold">{list?.name}</h6>
                                <p className="mb-0 small">
                                  {list?.phoneNumber}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className=" ">
                              <h6 className="fw-bold">{list?.email}</h6>
                            </div>
                          </td>
                          <td>
                            <h6 className="fw-bold">{list?.role}</h6>
                          </td>
                          <td>
                            <h6 className="fw-bold">
                              {format(new Date(list?.createdAt), "dd/MM/yyyy")}
                            </h6>
                            <p className="mb-0">
                              At{" "}
                              {format(
                                new Date(list?.createdAt),
                                "  h:mm:ss a "
                              )}
                            </p>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table> */}
            </div>
          </div>
        ) : (
          <div>
            {/* <div className="d-flex justify-content-between"> */}
            <div className="row">
              <div className="col-6 text-start">
                <h5 className="mb-0 fw-bold color-maroon">Add New Member</h5>
              </div>
              <div className="col-6 text-end">
                <button
                  className="btn py-1  login-btn"
                  type="button"
                  onClick={(e) => {
                    setAddEmployeeManagement(true);
                    getListsubadmin("active");
                    localStorage?.removeItem("general")
                    localStorage?.removeItem("job")
                    localStorage?.removeItem("account")
                  }}
                >
                  Back
                </button>
              </div>
            </div>
            {/* </div> */}
            <div className="">
              <div className="container py-4">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="">
                      <div className="profile-head">
                        <div className="profile-head1"></div>
                        <div className="d-flex profile-head2  align-items-center">
                          <div className="flex-shrink-0 ">
                          {registerDetail.profileImage ? (<img
                              style={{ width: "100px", height: "100px" }}
                              className="admin-pro-img "
                              src={
                                registerDetail.profileImage
                                  ? registerDetail.profileImage
                                  : AdminPro
                              }
                              alt="..."
                            />):(
                              <div className="admin-pro-img" style={{ width: "100px", height: "100px" }}>
                            <span className=" material-symbols-rounded filled-icon dash-icon" >
                            person
                          </span>
                          </div>
                          )}  

                            <div className="admin-edit-icon">
                              <span
                                className="material-symbols-rounded filled-icon"
                                onClick={() => CeoRef.current.click()}
                              >
                                border_color
                                <input
                                  id="file"
                                  className="form-control"
                                  type="file"
                                  ref={CeoRef}
                                  onChange={(e) => {
                                    uploadCeoPic(e);
                                  }}
                                  hidden
                                />
                              </span>
                            </div>
                          </div>
                       <small className="text-danger ms-3">
                            {registerValidation?.profileImage?.message
                              ? `Profile image ${registerValidation?.profileImage?.message}`
                              : ""}
                          </small>
                        </div>
                      </div>

                      <div className="pro-body">
                        <div className="container">
                          <div className="row justify-content-center py-5">
                            <div className="col-lg-10">
                              <div className="white-wrapper">
                                <div className="steps">
                                  <Fragment>
                                    <ul>
                                      <li
                                        className={
                                          localStorage?.getItem("general")
                                            ? "active"
                                            : ""
                                        }
                                      >
                                        <label>1</label>
                                        <span>General Info</span>
                                      </li>
                                      <li
                                        className={
                                          localStorage?.getItem("job")
                                            ? "active"
                                            : ""
                                        }
                                      >
                                        <label>2</label>
                                        <span>Job Info</span>
                                      </li>
                                      <li
                                        className={
                                          localStorage?.getItem("account")
                                            ? "active"
                                            : ""
                                        }
                                      >
                                        <label>3</label>
                                        <span>Account Setting</span>
                                      </li>
                                    </ul>
                                  </Fragment>
                                </div>
                                {showGeneralInfo && (
                                  <div>
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Name{" "}
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            placeholder="Enter Your First Name"
                                            aria-invalid={Boolean(
                                              registerValidation?.name
                                                ?.status === false
                                            )}
                                            defaultValue={
                                              registerDetail?.name || ""
                                            }
                                            onChange={(e) => {
                                              setRegisterDetailsValue(
                                                "name",
                                                e.target.value
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setRegisterValidationValue(
                                                "name",
                                                StringValidation(e.target.value)
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {registerValidation?.name?.message
                                              ? `Name ${registerValidation?.name?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>

                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            E- Mail ID{" "}
                                          </label>
                                          <input
                                            type="email"
                                            className="form-control form-control-lg"
                                            placeholder="Enter Your E- Mail ID"
                                            aria-invalid={Boolean(
                                              registerValidation?.email
                                                ?.status === false
                                            )}
                                            defaultValue={
                                              registerDetail?.email?.toLowerCase() ||
                                              ""
                                            }
                                            onChange={(e) => {
                                              setRegisterDetailsValue(
                                                "email",
                                                e.target.value?.toLowerCase()
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setRegisterValidationValue(
                                                "email",
                                                EmailValidation(
                                                  e.target.value?.toLowerCase()
                                                )
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {registerValidation?.email?.message
                                              ? `Email ${registerValidation?.email?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Phone Number{" "}
                                          </label>
                                          <input
                                            type="text"
                                            maxLength={10}
                                            className="form-control form-control-lg"
                                            placeholder="Enter Your Phone Number"
                                            aria-invalid={Boolean(
                                              registerValidation?.phoneNumber
                                                ?.status === false
                                            )}
                                            defaultValue={
                                              registerDetail?.phoneNumber || ""
                                            }
                                            onChange={(e) => {
                                              setRegisterDetailsValue(
                                                "phoneNumber",
                                                e.target.value
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setRegisterValidationValue(
                                                "phoneNumber",
                                                PhoneNumberValidation(
                                                  e.target.value
                                                )
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {registerValidation?.phoneNumber
                                              ?.message
                                              ? `Phone no ${registerValidation?.phoneNumber?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">DOB </label>
                                          <input
                                            type="date"
                                            className="form-control form-control-lg"
                                            placeholder="Enter Your DOB"
                                            aria-invalid={Boolean(
                                              registerValidation?.DOB
                                                ?.status === false
                                            )}
                                            defaultValue={
                                              registerDetail?.DOB || ""
                                            }
                                            onChange={(e) => {
                                              setRegisterDetailsValue(
                                                "DOB",
                                                e.target.value
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setRegisterValidationValue(
                                                "DOB",
                                                DateValidation(e.target.value)
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {registerValidation?.DOB?.message
                                              ? `DOB ${registerValidation?.DOB?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label ">
                                            Gender{" "}
                                          </label>
                                          <div>
                                            <div className="form-check form-check-inline">
                                            {/* <input type="radio" value="male" name="gender" onChange={(e) => {
                                                  setRegisterDetailsValue(
                                                    "gender",
                                                    e.target.value
                                                  );
                                                }}/> <span className="p-2">Male</span>
                                              <input className="ms-2" type="radio" value="female" name="gender" onChange={(e) => {
                                                  setRegisterDetailsValue(
                                                    "gender",
                                                    e.target.value
                                                  );
                                                }}/> <span className="p-2">Female</span> */}
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="gender"
                                                id="inlineRadio1"
                                                value="male"
                                                // defaultValue={
                                                //   registerDetail?.gender || ""
                                                // }
                                                onChange={(e) => {
                                                  setRegisterDetailsValue(
                                                    "gender",
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                              <label
                                                className="form-check-label"
                                                for="inlineRadio1"
                                              >
                                                Male
                                              </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="gender"
                                                id="inlineRadio2"
                                                value="female"
                                                onChange={(e) => {
                                                  setRegisterDetailsValue(
                                                    "gender",
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                              <label
                                                className="form-check-label"
                                                for="inlineRadio2"
                                              >
                                                Female
                                              </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="gender"
                                                id="inlineRadio3"
                                                value="others"
                                                onChange={(e) => {
                                                  setRegisterDetailsValue(
                                                    "gender",
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                              <label
                                                className="form-check-label"
                                                for="inlineRadio3"
                                              >
                                                Others
                                              </label>
                                            </div>
                                          </div>
                                          <small className="text-danger">
                                            {registerValidation?.gender?.message
                                              ? `Gender ${registerValidation?.gender?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Address{" "}
                                          </label>
                                          <textarea
                                            type="text"
                                            className="form-control form-control-lg"
                                            placeholder="Enter Your Phone Address"
                                            aria-invalid={Boolean(
                                              registerValidation
                                                ?.employeeAddress?.status ===
                                                false
                                            )}
                                            defaultValue={
                                              registerDetail?.employeeAddress ||
                                              ""
                                            }
                                            onChange={(e) => {
                                              setRegisterDetailsValue(
                                                "employeeAddress",
                                                e.target.value
                                              );
                                              localStorage?.setItem(
                                                "general",
                                                true
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setRegisterValidationValue(
                                                "employeeAddress",
                                                NonEmptyValidation(
                                                  e.target.value
                                                )
                                              );
                                            }}
                                            autoComplete="off"
                                          ></textarea>
                                          <small className="text-danger">
                                            {registerValidation?.employeeAddress
                                              ?.message
                                              ? `Address ${registerValidation?.employeeAddress?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>

                                      <div className="col-lg-12 mt-4">
                                        {/* <button
                                          type="button"
                                          className="btn white-btn me-2 "
                                        >
                                          Cancel
                                        </button> */}
                                        <button
                                          type="button"
                                          className="btn login-btn ms-2 "
                                          onClick={() => {
                                            generalInformation();
                                          }}
                                        >
                                          Next
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {showJobInfo && (
                                  <div>
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Department Name
                                          </label>
                                          <CreatableSelect
                                            options={schedule}
                                            theme={(theme) => ({
                                              ...theme,
                                              borderRadius: "6px",
                                              zIndex: 3,
                                              colors: {
                                                ...theme.colors,
                                                primary25: "#DFF1F1", // selected option background color
                                                neutral20:
                                                  registerValidation
                                                    ?.departmentName?.status ===
                                                  false
                                                    ? "red"
                                                    : "#540326", // border color
                                                primary:
                                                  registerValidation
                                                    ?.departmentName?.status ===
                                                  false
                                                    ? "red"
                                                    : "#540326", // border color when focus
                                              },
                                            })}
                                            styles={{
                                              menu: (provided, state) => ({
                                                ...provided,
                                                zIndex: "5 !important",
                                              }),
                                            }}
                                            placeholder="Choose Department Name"
                                            isClearable
                                            isMulti
                                            value={
                                              registerDetail?.departmentName
                                                ? registerDetail?.departmentName?.map(
                                                    (v) => {
                                                      return {
                                                        value: v.departmentName
                                                          ? v.departmentName
                                                          : v.label,
                                                        label: v.departmentName
                                                          ? v.departmentName
                                                          : v.label,
                                                        _id: v._id
                                                          ? v._id
                                                          : v._id,
                                                      };
                                                    }
                                                  )
                                                : []
                                            }
                                            onInputChange={() => {
                                              if (
                                                registerValidation[
                                                  "departmentName"
                                                ]
                                              )
                                                delete registerValidation[
                                                  "departmentName"
                                                ];
                                            }}
                                            onChange={(newValue) => {
                                              setRegisterDetailsValue(
                                                "departmentName",
                                                newValue
                                              );
                                            }}
                                            onCreateOption={
                                              handleCreateDepartmentName
                                            }
                                          />
                                          {/* <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            placeholder="Enter department name"
                                            aria-invalid={Boolean(
                                              registerValidation?.departmentName
                                                ?.status === false
                                            )}
                                            defaultValue={
                                              registerDetail?.departmentName || ""
                                            }
                                            onChange={(e) => {
                                              setRegisterDetailsValue(
                                                "departmentName",
                                                e.target.value
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setRegisterValidationValue(
                                                "departmentName",
                                                NonEmptyValidation(
                                                  e.target.value
                                                )
                                              );
                                            }}
                                            autoComplete="off"
                                          /> */}
                                          <small className="text-danger">
                                            {registerValidation?.departmentName
                                              ?.message
                                              ? `Department name ${registerValidation?.departmentName?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Designation
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            placeholder="Enter Designation"
                                            aria-invalid={Boolean(
                                              registerValidation?.Designation
                                                ?.status === false
                                            )}
                                            defaultValue={
                                              registerDetail?.Designation || ""
                                            }
                                            onChange={(e) => {
                                              setRegisterDetailsValue(
                                                "Designation",
                                                e.target.value
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setRegisterValidationValue(
                                                "Designation",
                                                NonEmptyValidation(
                                                  e.target.value
                                                )
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {registerValidation?.Designation
                                              ?.message
                                              ? `Designation ${registerValidation?.Designation?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Date Of Appointment
                                          </label>
                                          <input
                                            type="date"
                                            className="form-control form-control-lg"
                                            placeholder="Enter dateOfAppointment"
                                            aria-invalid={Boolean(
                                              registerValidation
                                                ?.dateOfAppointment?.status ===
                                                false
                                            )}
                                            defaultValue={
                                              registerDetail?.dateOfAppointment ||
                                              ""
                                            }
                                            onChange={(e) => {
                                              setRegisterDetailsValue(
                                                "dateOfAppointment",
                                                e.target.value
                                              );
                                              localStorage?.setItem(
                                                "job",
                                                true
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setRegisterValidationValue(
                                                "dateOfAppointment",
                                                NonEmptyValidationForDate(
                                                  e.target.value
                                                )
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {registerValidation
                                              ?.dateOfAppointment?.message
                                              ? `Date Of Appointment ${registerValidation?.dateOfAppointment?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      {/* <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Password
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            placeholder="Enter password"
                                            aria-invalid={Boolean(
                                              registerValidation?.password
                                                ?.status === false
                                            )}
                                            value="Qwerty@123"
                                            onChange={(e) => {
                                              setRegisterDetailsValue(
                                                "password",
                                                e.target.value
                                              );
                                              
                                            }}
                                            onBlur={(e) => {
                                              setRegisterValidationValue(
                                                "password",
                                                NonEmptyValidation(
                                                  e.target.value
                                                )
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {registerValidation?.password
                                              ?.message
                                              ? `Password ${registerValidation?.password?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div> */}

                                      <div className="col-lg-12 mt-4">
                                        <button
                                          type="button"
                                          className="btn white-btn me-2 "
                                          onClick={() => {
                                            jobInformation("back");
                                          }}
                                        >
                                          Back
                                        </button>
                                        <button
                                          type="button"
                                          className="btn login-btn ms-2 "
                                          onClick={() => {
                                            jobInformation("next");
                                          }}
                                        >
                                          Next
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {showAccountSetting && (
                                  <div>
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label ">
                                            Working Days
                                          </label>
                                          <Select
                                            options={optionList}
                                            theme={(theme) => ({
                                              ...theme,
                                              borderRadius: "6px",
                                              zIndex: 3,
                                              colors: {
                                                ...theme.colors,
                                                primary25: "#DFF1F1", // selected option background color
                                                neutral20:
                                                  registerValidation
                                                    ?.workingDays?.status ===
                                                  false
                                                    ? "red"
                                                    : "#540326", // border color
                                                primary:
                                                  registerValidation
                                                    ?.workingDays?.status ===
                                                  false
                                                    ? "red"
                                                    : "#540326", // border color when focus
                                              },
                                            })}
                                            styles={{
                                              menu: (provided, state) => ({
                                                ...provided,
                                                zIndex: "5 !important",
                                              }),
                                            }}
                                            placeholder="Choose working days"
                                            isClearable
                                            value={
                                              registerDetail?.workingDays
                                                ? registerDetail?.workingDays?.map(
                                                    (v) => {
                                                      return {
                                                        value: v.label,
                                                        label: v.label,
                                                      };
                                                    }
                                                  )
                                                : []
                                            }
                                            l
                                            onChange={(e) => {
                                              //console.log(e);
                                              setRegisterDetailsValue(
                                                "workingDays",
                                                e
                                              );
                                            }}
                                            onInputChange={() => {
                                              if (
                                                registerValidation[
                                                  "workingDays"
                                                ]
                                              )
                                                delete registerValidation[
                                                  "workingDays"
                                                ];
                                            }}
                                            isMulti
                                          />
                                          <small className="text-danger">
                                            {registerValidation?.workingDays
                                              ?.message
                                              ? `Working Days ${registerValidation?.workingDays?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                        {/* <div className="mb-4">
                                          <label className="form-label">
                                          Working Days
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            placeholder="Enter workingDays"
                                            aria-invalid={Boolean(
                                              registerValidation?.workingDays
                                                ?.status === false
                                            )}
                                            defaultValue={
                                              registerDetail?.workingDays || ""
                                            }
                                            onChange={(e) => {
                                              setRegisterDetailsValue(
                                                "workingDays",
                                                e.target.value
                                              );
                                              localStorage?.setItem("12",true)
                                            }}
                                            onBlur={(e) => {
                                              setRegisterValidationValue(
                                                "workingDays",
                                                NonEmptyValidation(
                                                  e.target.value
                                                )
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {registerValidation?.workingDays
                                              ?.message
                                              ? `WorkingDays ${registerValidation?.workingDays?.message}`
                                              : ""}
                                          </small>
                                        </div> */}
                                      </div>
                                       <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Time Slot
                                          </label>
                                          <CreatableSelect
                                            options={timeslot}
                                            theme={(theme) => ({
                                              ...theme,
                                              borderRadius: "6px",
                                              zIndex: 3,
                                              colors: {
                                                ...theme.colors,
                                                primary25: "#DFF1F1", // selected option background color
                                                neutral20:
                                                  registerValidation
                                                    ?.timeSlot?.status ===
                                                  false
                                                    ? "red"
                                                    : "#540326", // border color
                                                primary:
                                                  registerValidation
                                                    ?.timeSlot?.status ===
                                                  false
                                                    ? "red"
                                                    : "#540326", // border color when focus
                                              },
                                            })}
                                            styles={{
                                              menu: (provided, state) => ({
                                                ...provided,
                                                zIndex: "5 !important",
                                              }),
                                            }}
                                            placeholder="Choose time slot"
                                            isClearable
                                            isMulti
                                            value={
                                              registerDetail?.timeSlot
                                                ? registerDetail?.timeSlot?.map(
                                                    (v) => {
                                                      return {
                                                        value: v.timeSlot
                                                          ? v.timeSlot
                                                          : v.label,
                                                        label: v.timeSlot
                                                          ? v.timeSlot
                                                          : v.label,
                                                        _id: v._id
                                                          ? v._id
                                                          : v._id,
                                                      };
                                                    }
                                                  )
                                                : []
                                            }
                                            onInputChange={() => {
                                              if (
                                                registerValidation[
                                                  "timeSlot"
                                                ]
                                              )
                                                delete registerValidation[
                                                  "timeSlot"
                                                ];
                                            }}
                                            onChange={(newValue) => {
                                              setRegisterDetailsValue(
                                                "timeSlot",
                                                newValue
                                              );
                                            }}
                                            onCreateOption={
                                              handleCreateTimeslot
                                            }
                                          />
                                        
                                          <small className="text-danger">
                                            {registerValidation?.timeSlot
                                              ?.message
                                              ? `Time slot ${registerValidation?.timeSlot?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      {/* <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label ">
                                            Time Slot
                                          </label>

                                          <input
                                            className="form-control form-control-lg"
                                            placeholder="Enter time slot"
                                            defaultValue={
                                              registerDetail?.timeSlot
                                            }
                                            onChange={(e) => {
                                              setRegisterDetailsValue(
                                                "timeSlot",
                                                e.target.value
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setRegisterValidationValue(
                                                "timeSlot",
                                                NonEmptyValidation(
                                                  e.target.value
                                                )
                                              );
                                            }}
                                          />

                                          <small className="text-danger">
                                            {registerValidation?.timeSlot
                                              ?.message
                                              ? `Time Slot ${registerValidation?.timeSlot?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div> */}
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label ">
                                          Role Restriction
                                          </label>

                                          <select
                                            className="form-control form-control-lg"
                                            onChange={(e) => {
                                              setRegisterDetailsValue(
                                                "pageAccess",
                                                e.target.value
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setRegisterValidationValue(
                                                "pageAccess",
                                                NonEmptyValidation(
                                                  e.target.value
                                                )
                                              );
                                            }}
                                          >
                                            <option value="selectmembername">
                                              ---Select Role Restriction---
                                            </option>
                                            {roleList?.map((e) => (
                                              <option value={e._id}>
                                                {e.title}
                                              </option>
                                            ))}
                                          </select>
                                          <small className="text-danger">
                                            {registerValidation?.pageAccess?.message
                                              ? `Page Access ${registerValidation?.pageAccess?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">CTC</label>
                                          <input
                                            type="text"
                                            placeholder="Enter CTC"
                                            className="form-control form-control-lg"
                                            aria-invalid={Boolean(
                                              registerValidation?.ctc
                                                ?.status === false
                                            )}
                                            defaultValue={
                                              registerDetail?.ctc || ""
                                            }
                                            onChange={(e) => {
                                              setRegisterDetailsValue(
                                                "ctc",
                                                e.target.value
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setRegisterValidationValue(
                                                "ctc",
                                                NonEmptyValidation(
                                                  e.target.value
                                                )
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {registerValidation?.ctc?.message
                                              ? `CTC ${registerValidation?.ctc?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>

                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Salary Term
                                          </label>
                                          <select
                                            className="form-control form-control-lg"
                                            aria-invalid={Boolean(
                                              registerValidation?.salaryTerm
                                                ?.status === false
                                            )}
                                            defaultValue={
                                              registerDetail?.salaryTerm || ""
                                            }
                                            onChange={(e) => {
                                              setRegisterDetailsValue(
                                                "salaryTerm",
                                                e.target.value
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setRegisterValidationValue(
                                                "salaryTerm",
                                                NonEmptyValidation(
                                                  e.target.value
                                                )
                                              );
                                            }}
                                          >
                                            <option value="">
                                              ---Select Salary Term---
                                            </option>

                                            <option value="monthly">
                                              Monthly
                                            </option>
                                            <option value="annual">
                                              Annual
                                            </option>
                                          </select>
                                          {/* <input
                                            type="text"
                                            placeholder="Enter salary term"
                                            className="form-control form-control-lg"
                                            aria-invalid={Boolean(
                                              registerValidation?.salaryTerm
                                                ?.status === false
                                            )}
                                            defaultValue={
                                              registerDetail?.salaryTerm || ""
                                            }
                                            onChange={(e) => {
                                              setRegisterDetailsValue(
                                                "salaryTerm",
                                                e.target.value
                                              );
                                            
                                            }}
                                            onBlur={(e) => {
                                              setRegisterValidationValue(
                                                "salaryTerm",
                                                NonEmptyValidation(e.target.value)
                                              );
                                            }}
                                            autoComplete="off"
                                          /> */}
                                          <small className="text-danger">
                                            {registerValidation?.salaryTerm
                                              ?.message
                                              ? `Salary Term ${registerValidation?.salaryTerm?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>

                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Employee ESI
                                          </label>
                                          <input
                                            type="text"
                                            placeholder="Enter ESI"
                                            className="form-control form-control-lg"
                                            aria-invalid={Boolean(
                                              registerValidation?.employeeESI
                                                ?.status === false
                                            )}
                                            defaultValue={
                                              registerDetail?.employeeESI || ""
                                            }
                                            onChange={(e) => {
                                              setRegisterDetailsValue(
                                                "employeeESI",
                                                e.target.value
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setRegisterValidationValue(
                                                "employeeESI",
                                                NonEmptyValidation(
                                                  e.target.value
                                                )
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {registerValidation?.employeeESI
                                              ?.message
                                              ? `ESI ${registerValidation?.employeeESI?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>

                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            PF Number
                                          </label>
                                          <input
                                            type="text"
                                            placeholder="Enter PF number"
                                            className="form-control form-control-lg"
                                            aria-invalid={Boolean(
                                              registerValidation?.PFId
                                                ?.status === false
                                            )}
                                            defaultValue={
                                              registerDetail?.PFId || ""
                                            }
                                            onChange={(e) => {
                                              localStorage?.setItem(
                                                "account",
                                                true
                                              );
                                              setRegisterDetailsValue(
                                                "PFId",
                                                e.target.value
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setRegisterValidationValue(
                                                "PFId",
                                                NonEmptyValidation(
                                                  e.target.value
                                                )
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {registerValidation?.PFId?.message
                                              ? `PF number ${registerValidation?.PFId?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>

                                      <div className="col-lg-12 mt-4">
                                        <button
                                          type="button"
                                          className="btn white-btn me-2 "
                                          onClick={() => {
                                            accountInformation("back");
                                          }}
                                        >
                                          Back
                                        </button>
                                        <button
                                          type="button"
                                          className="btn login-btn ms-2 "
                                          onClick={(e) => {
                                            accountInformation("next");
                                          }}
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
