import React, { useEffect } from 'react';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactStars from "react-rating-stars-component";

export default function Testimonial() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const testimonialValue = [
        {
            clientName: "Gayathri Narasi",
            message: "Very professional Lawyer. For all kinds of legal issues we can reach out to them without any doubt. They are professional and also soft natured and hear all our concerns very patiently and give all possible solutions briefly. Most importantly they are not money minded. So kindly consult👆",
            imageURL: "https://thumbs.dreamstime.com/b/печать-woman-avatar-profile-female-face-icon-vector-illustration-226594813.jpg",
            rating: 5
        },
        {
            clientName: "Yobu Daniel",
            message: "I had a Credit Card bill payment issue with a bank they gave me a detailed consultation and quick relief for my debts. They are best for all your legal issues I will definitely rate them 5 ⭐",
            imageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbNOpai32_rwcRrMxmpF4sNJG3CIR7yTPv7MD9qK4Ft5OdltMU6DymiRqxXRb0qtgGJoE&usqp=CAU",
            rating: 5
        },
        {
            clientName: "Kotte Eswaran",
            message: "One of the best places for legal solutions. The advocates here are very professional and knowledgeable they provide appropriate solutions for any issues. Best place to approach for good results.",
            imageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbNOpai32_rwcRrMxmpF4sNJG3CIR7yTPv7MD9qK4Ft5OdltMU6DymiRqxXRb0qtgGJoE&usqp=CAU",
            rating: 5
        },
        {
            clientName: "Rishi Ruban",
            message: "Best place to approach for any legal issues. They are experts in Mortgage loan disputes under SARFAESI Act, 2002. They are very friendly and polite towards their clients.",
            imageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbNOpai32_rwcRrMxmpF4sNJG3CIR7yTPv7MD9qK4Ft5OdltMU6DymiRqxXRb0qtgGJoE&usqp=CAU",
            rating: 5
        },
        {
            clientName: "Chandru G",
            message: "I had a credit card issue, they gave me an opinion on how to close the debts and even gave me complete legal protection until I cleared all the dues. Excellent service, thank you.",
            imageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbNOpai32_rwcRrMxmpF4sNJG3CIR7yTPv7MD9qK4Ft5OdltMU6DymiRqxXRb0qtgGJoE&usqp=CAU",
            rating: 4
        },
        {
            clientName: "Samson Christudoss",
            message: "Dalmaf, I am very grateful for the help. I always receive from the lawyers Clinton Sir and Paul Sir. For the past 2 years I have needed their services for my home loan (mortgage loan) and as always, the service exceeds my expectations. At any time, I need them, they are always available to help quickly and have a high level of professionalism. They explained everything in detail and made the process easy. If anyone needs any legal service for any issues, I can recommend them.",
            imageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbNOpai32_rwcRrMxmpF4sNJG3CIR7yTPv7MD9qK4Ft5OdltMU6DymiRqxXRb0qtgGJoE&usqp=CAU",
            rating: 5
        },
        {
            clientName: "ClickOn Studio",
            message: "They are specialized in Loan matters. To be honest they give us the best solution legally. Trust me they are the best in the city.",
            imageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbNOpai32_rwcRrMxmpF4sNJG3CIR7yTPv7MD9qK4Ft5OdltMU6DymiRqxXRb0qtgGJoE&usqp=CAU",
            rating: 5
        },
        {
            clientName: "Nirmal Koothan",
            message: "Best communication skills and fast solving on loan issues. Impressed by their knowledgeable faculties.",
            imageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbNOpai32_rwcRrMxmpF4sNJG3CIR7yTPv7MD9qK4Ft5OdltMU6DymiRqxXRb0qtgGJoE&usqp=CAU",
            rating: 5
        },
        {
            clientName: "Pavithra N",
            message: "I'm really surprised by their knowledge and quick relief strategy, best of best in loan problem, thank you DALMAF",
            imageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbNOpai32_rwcRrMxmpF4sNJG3CIR7yTPv7MD9qK4Ft5OdltMU6DymiRqxXRb0qtgGJoE&usqp=CAU",
            rating: 5
        },
        {
            clientName: "MCOG eng and Fab chennai",
            message: "Nice service, kindly staff, supportive people. For supporting legal activities like mortgage loan",
            imageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbNOpai32_rwcRrMxmpF4sNJG3CIR7yTPv7MD9qK4Ft5OdltMU6DymiRqxXRb0qtgGJoE&usqp=CAU",
            rating: 5
        },
        {
            clientName: "Renga Narthagi",
            message: "Very professional Lawyer. For all kinds of legal issues we can reach out to them without any doubt. They hear all our concerns very patiently and give all possible solutions briefly. Most importantly they are not money minded. The lawyer helped to solve our issue very quickly... So kindly consult👆",
            imageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbNOpai32_rwcRrMxmpF4sNJG3CIR7yTPv7MD9qK4Ft5OdltMU6DymiRqxXRb0qtgGJoE&usqp=CAU",
            rating: 5
        },
        {
            clientName: "Aravind K",
            message: "Professional way of handling things. Adv. Paul guides things clearly and explains everything. Would recommend to my friends & family.",
            imageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbNOpai32_rwcRrMxmpF4sNJG3CIR7yTPv7MD9qK4Ft5OdltMU6DymiRqxXRb0qtgGJoE&usqp=CAU",
            rating: 5
        },
        {
            clientName: "Abita Kuppuswamy",
            message: "They give the best solution for all your problems!!!",
            imageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbNOpai32_rwcRrMxmpF4sNJG3CIR7yTPv7MD9qK4Ft5OdltMU6DymiRqxXRb0qtgGJoE&usqp=CAU",
            rating: 5
        },
        {
            clientName: "Raja Sekaran",
            message: "Better service and good respect, thanks for your support and service.🙏",
            imageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbNOpai32_rwcRrMxmpF4sNJG3CIR7yTPv7MD9qK4Ft5OdltMU6DymiRqxXRb0qtgGJoE&usqp=CAU",
            rating: 5
        },
        {
            clientName: "Mayil Vaganan Thanikavelu",
            message: "There are experts in bank litigation issues",
            imageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbNOpai32_rwcRrMxmpF4sNJG3CIR7yTPv7MD9qK4Ft5OdltMU6DymiRqxXRb0qtgGJoE&usqp=CAU",
            rating: 5
        },
        {
            clientName: "Sakthivelkumar M",
            message: "Good service. Thanks for your support",
            imageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbNOpai32_rwcRrMxmpF4sNJG3CIR7yTPv7MD9qK4Ft5OdltMU6DymiRqxXRb0qtgGJoE&usqp=CAU",
            rating: 5
        },
        {
            clientName: "T. Saravanan",
            message: "Appropriate Solution",
            imageURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbNOpai32_rwcRrMxmpF4sNJG3CIR7yTPv7MD9qK4Ft5OdltMU6DymiRqxXRb0qtgGJoE&usqp=CAU",
            rating: 5
        },
    ];

    return (
        <section class="space" id="service-sec"
            style={{
                backgroundImage: `url('https://dalmaf.com/img/bg/team_bg_2.png')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            <div class="container">
                <div class="row justify-content-lg-between justify-content-center align-items-center">
                    <div class="col-lg-12 col-sm-12 pe-xl-12">
                        <div class="title-area text-center text-lg-start"><span class="sub-title">
                            <div class="icon-masking me-2"><span class="mask-icon"
                                data-mask-src="https://dalmaf.com/img/theme-img/title_shape_2.svg"></span> <img
                                    src="https://dalmaf.com/img/theme-img/title_shape_2.svg" alt="shape" /></div>WHAT WE DO
                        </span>
                            <h2 class="sec-title">What Happy Clients Says<br /><span class="text-theme fw-normal">About Us?</span></h2>
                        </div>
                    </div>
                </div>
                <div class="slider-area">
                    <div class="swiper-wrapper">
                        <OwlCarousel
                            className="owl-theme owl-scroll"
                            loop
                            margin={10}
                            nav
                            dots={false}
                            autoplay={true}
                            autoplayTimeout={4000}
                            autoplayHoverPause={true}
                            responsive={{
                                0: {
                                    items: 1
                                },
                                600: {
                                    items: 2
                                },
                            }}
                        >
                            {testimonialValue.map((testimonial, index) => (
                                <div key={index} className="item text-center p-4" style={{ height: '300px', overflowY: 'auto', cursor: 'pointer' }}>
                                    <div className="shadow-sm rounded p-4 position-relative service-card ">
                                        <div className="position-absolute top-0 end-0 p-2">
                                            <ReactStars
                                                count={5}
                                                value={testimonial.rating}
                                                size={20}
                                                edit={false}
                                                activeColor="#ffd700"
                                            />
                                        </div>
                                        <div className="testimonial-img">
                                            <img
                                                src={testimonial.imageURL}
                                                alt={testimonial.clientName}
                                                className="img-fluid rounded-circle mb-4"
                                                style={{ width: '80px', height: '80px', objectFit: 'cover' }}
                                            />
                                        </div>
                                        <h5 className="mb-1">{testimonial.clientName}</h5>
                                        <p className="mb-2"><em>{testimonial.message}</em></p>
                                    </div>
                                </div>

                            ))}
                        </OwlCarousel>
                    </div>
                </div>
            </div>
            <div class="shape-mockup moving d-none d-xl-block" data-bottom="0%" data-left="10%"><img
                src="https://dalmaf.com/img/shape/line_1.png" alt="shape"/></div>
            <div class="shape-mockup jump d-none d-xl-block" data-top="20%" data-right="2%"><img
                src="https://dalmaf.com/img/shape/line_2.png" alt="shape"/></div>
        </section>
        // <section class="bg-auto space" data-bg-src="img/bg/testi_bg_2.png">
        //     <div class="container">
        //         <div class="title-area text-center"><span class="sub-title">
        //             <div class="icon-masking me-2"><span class="mask-icon"
        //                 data-mask-src="imgtheme-img/title_shape_2.svg"></span> <img
        //                     src="img/theme-img/title_shape_2.svg" alt="shape"/></div>CUSTOMER FEEDBACK
        //         </span>
        //             <h2 class="sec-title">What Happy Clients Says<br/><span class="text-theme fw-normal">About Us?</span>
        //             </h2>
        //         </div>
        //         <OwlCarousel
        //                 className="owl-theme owl-scroll"
        //                 loop
        //                 margin={10}
        //                 nav
        //                 dots={false}
        //                 autoplay={true}
        //                 autoplayTimeout={4000}
        //                 autoplayHoverPause={true}
        //                 responsive={{
        //                     0: {
        //                         items: 1
        //                     },
        //                     600: {
        //                         items: 2
        //                     },
        //                 }}
        //             >
        //                 {testimonialValue.map((testimonial, index) => (
        //                     <div key={index} className="item text-center p-4" style={{ height: '300px', overflowY: 'auto',cursor:'pointer' }}>
        //                         <div className="testimonial-box shadow-sm rounded p-4 position-relative">
        //                             <div className="position-absolute top-0 end-0 p-2">
        //                                 <ReactStars
        //                                     count={5}
        //                                     value={testimonial.rating}
        //                                     size={20}
        //                                     edit={false}
        //                                     activeColor="#ffd700"
        //                                 />
        //                             </div>
        //                             <div className="testimonial-img">
        //                                 <img
        //                                     src={testimonial.imageURL}
        //                                     alt={testimonial.clientName}
        //                                     className="img-fluid rounded-circle mb-4"
        //                                     style={{ width: '80px', height: '80px', objectFit: 'cover' }}
        //                                 />
        //                             </div>
        //                             <h5 className="mb-1">{testimonial.clientName}</h5>
        //                             <p className="mb-2"><em>{testimonial.message}</em></p>
        //                         </div>
        //                     </div>

        //                 ))}
        //             </OwlCarousel>
        //     </div>
        //     <div class="shape-mockup moving d-none d-xl-block" data-bottom="0%" data-left="10%"><img
        //         src="img/shape/line_1.png" alt="shape"/></div>
        //     <div class="shape-mockup jump d-none d-xl-block" data-top="20%" data-right="2%"><img
        //         src="img/shape/line_2.png" alt="shape"/></div>
        // </section>
    );
}
