import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import AdminNavbar from "../Navbar/AdminNavbar";
import Spinner from "../../../Spinner/Spinner";
import { serviceViewLog } from "../../../Api/Api";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export default function WebsiteAllLeads() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [noofRecords, setNoOfRecords] = useState(0);
  const state = useSelector((state) => state);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);

  const fetchData = async (page, reset = false) => {
    setLoading(true);
    const token = state.auth.auth.token;
    const response = await serviceViewLog(page, 10, token);
    if (response && response.statusCode === 200) {
      setNoOfRecords(response.data.total_count);
      if (response.data.service_view_log.length > 0) {
        setData(prevData => reset ? response.data.service_view_log : [...prevData, ...response.data.service_view_log]);
      } else {
        setHasMore(false);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    setData([]);
    setPage(0);
    setHasMore(true);
    fetchData(0, true);
  }, []);

  useEffect(() => {
    if (page > 0) fetchData(page);
  }, [page]);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    if (
      currentScrollPos > prevScrollPos &&
      window.innerHeight + currentScrollPos >= document.documentElement.offsetHeight &&
      !loading &&
      hasMore
    ) {
      setPage(prevPage => prevPage + 1);
    }
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading, hasMore, prevScrollPos]);

  const columns = [
    { name: "Name", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    { name: "User LoggedIn", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    { name: "Email", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    { name: "Phone", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    { name: "Service", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    { name: "Visiting Count", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    { name: "First Visit", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    { name: "Last Visit", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
  ];

  const options = {
    selectableRows: "none",
    responsive: "standard",
    filter: true,
    search: true,
    pagination: false,
    tableBodyHeight: "430px",
    download: true,
    viewColumns: true,
    print: true,
    elevation: 1,
    rowsPerPage: 10,
    rowsPerPageOptions: [10],
    customBodyRender: value => <div style={{ textAlign: "center" }}>{value}</div>,
    customBodyRow: () => ({
      style: {
        borderBottom: "1px solid #ddd",
      },
    }),
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
          styleOverrides: {
            head: {
              whiteSpace: 'nowrap',
              textAlign: 'center',
            },
            body: {
              textAlign: 'center',
            },
          },
        },
      },
    });

  return (
    <div>
      <div style={{ padding: "20px" }}>
        {loading && <Spinner />}
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={`All Website View data (${noofRecords})`}
            data={data.map(item => [
              item.name,
              item.login ? "Yes" : "No",
              item.email_id,
              item.phone_number,
              item.service_name,
              item.visiting_count,
              new Date(item.createdAt).toLocaleString(),
              new Date(item.last_visiting_time).toLocaleString(),
            ])}
            columns={columns}
            options={options}
            style={{
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
              border: "1px solid #ddd",
            }}
          />
        </ThemeProvider>
      </div>
    </div>
  );
}
