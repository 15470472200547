import React, { useEffect } from 'react';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactStars from "react-rating-stars-component";

export default function AboutExperince() {


    return (
        <>
        <div class="overflow-hidden bg-smoke space">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-xl-6">
                        <div class="title-area mb-35 text-center text-xl-start"><span class="sub-title">
                                <div class="icon-masking me-2"><span class="mask-icon"
                                        data-mask-src="img/theme-img/title_shape_2.svg"></span> <img
                                        src="img/theme-img/title_shape_2.svg" alt="shape"/></div>GREAT INDUSTRY SKILLS
                            </span>
                            <h2 class="sec-title">More Than 30+ Years Experience We Provide <span
                                    class="text-theme fw-normal">INDUSTRY Services</span></h2>
                        </div>
                        <p class="mt-n2 mb-25 text-center text-xl-start">Collaboratively envision user-friendly supply chains and cross-unit imperatives. Authoritatively create competitive resources and holistic solutions. Holistically restore real-time resources while standardizing networks for legal audits, manpower management, and property auctions.</p>
                        <div class="pe-xxl-4">
                            <div class="skill-feature">
                                <h3 class="skill-feature_title">Legal Compliance</h3>
                                <div class="progress">
                                    <div class="progress-bar" style={{width: '100%'}}>
                                        <div class="progress-value">100%</div>
                                    </div>
                                </div>
                            </div>
                            <div class="skill-feature">
                                <h3 class="skill-feature_title">Audit Services</h3>
                                <div class="progress">
                                    <div class="progress-bar" style={{width: '100%'}}>
                                        <div class="progress-value">100%</div>
                                    </div>
                                </div>
                            </div>
                            <div class="skill-feature">
                                <h3 class="skill-feature_title">Human Resource</h3>
                                <div class="progress">
                                    <div class="progress-bar" style={{width: '100%'}}>
                                        <div class="progress-value">100%</div>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="skill-feature">
                                <h3 class="skill-feature_title">Property Auction Solutions</h3>
                                <div class="progress">
                                    <div class="progress-bar" style={{width: '99%'}}>
                                        <div class="progress-value">99%</div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div class="col-xl-6 mt-40 mt-xl-0">
                        <div class="video-box1">
                            <img class="tilt-active" src="img/normal/video_shape_1.png"
                                alt="Video"/> 
                                {/* <a href="https://www.youtube.com/watch?v=_sI_Ps7JSEk"
                                class="play-btn popup-video"><i class="fas fa-play"></i></a> */}
                        </div>
                    </div> 
                </div>
            </div>
            {/* <div class="shape-mockup" data-top="0%" data-left="0%"><img src="img/shape/tech_shape_3.png" alt="shape"/>
            </div>
            <div class="shape-mockup" data-bottom="0%" data-right="0%"><img src="img/shape/tech_shape_4.png"
                    alt="shape"/></div> */}
        </div>
        </>
    );
}
