import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { fetchScheduleMeeting, fetchSpecificScheduleMeeting, updateScheduleMeetingStatus } from "../../../../Api/v1/ScheduleMeeting/Api";
import Spinner from "../../../../Spinner/Spinner";
import { ToastContainer, toast } from "react-toastify";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

// Modal styles
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: '900px',
  height: '90vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '10px',
  overflow: 'auto',
  p: 4,
};

// Component for the tab content
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function ScheduleMeetingDetails({ statusTypeProps }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [noofRecords, setNoOfRecords] = useState(0);
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const state = useSelector((state) => state);
  const [tabValue, setTabValue] = useState(0);

  const token = state.auth.auth.token;

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const fetchData = async (page, reset = false) => {
    setLoading(true);
    const response = await fetchScheduleMeeting(statusTypeProps, token);
    if (response && response.statusCode === 200) {
      setNoOfRecords(response.data.total_count);
      if (response.data.schedule_meeting_details.length > 0) {
        setData(prevData => reset ? response.data.schedule_meeting_details : [...prevData, ...response.data.schedule_meeting_details]);
      } else {
        setHasMore(false);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    setData([]);
    setPage(0);
    setHasMore(true);
    fetchData(0, true);
  }, [statusTypeProps]);

  useEffect(() => {
    if (page > 0) fetchData(page);
  }, [page]);

  const handleScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight && !loading && hasMore) {
      setPage(prevPage => prevPage + 1);
    }
  };

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, [loading, hasMore]);

  const handleStatusChange = (e, _id) => {
    const newStatus = e.target.value;
    handleUpdateStatus(_id, newStatus);
  };

  const handleUpdateStatus = async (_id, newStatus) => {
    setLoading(true);
    let payload = {
      "_id": _id,
      "status": newStatus
    };
    const response = await updateScheduleMeetingStatus(payload, token);
    if (response && response.statusCode === 200) {
      toast.success("Status updated successfully!");
      setData([]);
      fetchData(0, true);
    } else {
      toast.error("Failed to update status.");
    }
    setLoading(false);
  };

  const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

  const handleOpenModal = async (rowData) => {
    const response = await fetchSpecificScheduleMeeting(rowData._id, token);
    if (response && response.statusCode === 200) {
      setModalData(response.data);
    } else {
      toast.error("Failed to fetch the details.");
    }

    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setModalData(null);
  };

  const columns = [
    {
      name: "Name",
      options: {
        setCellProps: () => ({ style: { textAlign: 'center' } }),
        customBodyRender: (value, tableMeta) => (
          <span style={{ cursor: "pointer", color: "blue" }} onClick={() => handleOpenModal(data[tableMeta.rowIndex])}>
            {value}
          </span>
        )
      }
    },
    { name: "Email", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    { name: "Phone", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    { name: "Company Name", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    { name: "Submitted On", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    { name: "Updated On", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    {
      name: "Update Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const partnerId = data[tableMeta.rowIndex]._id;
          const currentStatus = data[tableMeta.rowIndex].status;
          return (
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                value={currentStatus}
                onChange={(e) => handleStatusChange(e, partnerId)}
                label="Status"
              >
                <MenuItem value="Draft">Draft</MenuItem>
                <MenuItem value="New">New</MenuItem>
                <MenuItem value="Review">Review</MenuItem>
                <MenuItem value="MeetingScheduled">Meeting Scheduled</MenuItem>
                <MenuItem value="Rescheduled">Rescheduled</MenuItem>
                <MenuItem value="Completed">Completed</MenuItem>
              </Select>
            </FormControl>
          );
        }
      }
    }
  ];

  const options = {
    selectableRows: "none",
    responsive: "standard",
    filter: true,
    search: true,
    pagination: false,
    tableBodyHeight: "500px",
    download: true,
    viewColumns: true,
    print: true,
    elevation: 1,
    rowsPerPage: 10,
    rowsPerPageOptions: [10],
    customBodyRender: value => <div style={{ textAlign: "center" }}>{value}</div>,
    customBodyRow: () => ({
      style: {
        borderBottom: "1px solid #ddd",
      },
    }),
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
          styleOverrides: {
            head: {
              whiteSpace: 'nowrap',
              textAlign: 'center',
              fontWeight: 'bold',
              backgroundColor: '#f5f5f5',
              fontSize: '14px',
            },
            body: {
              textAlign: 'center',
              fontSize: '14px',
            },
          },
        },
      },
    });

  return (
    <div>
      <div style={{ padding: "20px" }}>
        {loading && <Spinner />}
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={`All ${capitalizeFirstLetter(statusTypeProps)} Schedule Meeting Request (${noofRecords})`}
            data={data.map(item => [
              item.step_1.name,
              item.step_1.email_id,
              item.step_1.phone_number,
              item.step_1.company_name,
              new Date(item.createdAt).toLocaleString(),
              new Date(item.updatedAt).toLocaleString(),
            ])}
            columns={columns}
            options={options}
            style={{
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
              border: "1px solid #ddd",
            }}
          />
        </ThemeProvider>
        <ToastContainer />
      </div>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <h2 id="modal-modal-title" style={{ textAlign: 'center', marginBottom: '20px' }}>Schedule Meeting Details</h2>
          {modalData && (
            <>
              <Tabs value={tabValue} onChange={handleChange} aria-label="Schedule Meeting Stages">
                <Tab label="Stage 1" />
                <Tab label="Stage 2" />
                <Tab label="Stage 3" />
              </Tabs>
              <TabPanel value={tabValue} index={0}>
                <Typography variant="body1"><strong>Name:</strong> {modalData?.step_1?.name}</Typography>
                <Typography variant="body1"><strong>Email Id:</strong> {modalData?.step_1?.email_id}</Typography>
                <Typography variant="body1"><strong>Phone Number:</strong> {modalData?.step_1?.phone_number}</Typography>
                <Typography variant="body1"><strong>Company Name:</strong> {modalData?.step_1?.company_name}</Typography>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <Typography variant="body1"><strong>Employee Count:</strong> {modalData?.step_2?.turn_over}</Typography>
                <Typography variant="body1"><strong>Turnover:</strong> {modalData?.step_2?.email_id}</Typography>
                <Typography variant="body1"><strong>Company Website/Reference Link:</strong> {modalData?.step_2?.company_website}</Typography>
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                {modalData?.service_details?.map((data, index) => (
                  <Typography key={index} variant="body1"><strong>Service {index + 1}:</strong> {data?.title}</Typography>
                ))}
              </TabPanel>
              {/* <div style={{ marginTop: '20px', textAlign: 'center' }}>
                <select
                  value={modalData?.status}
                  onChange={(e) => handleStatusChange(e, modalData?._id)}
                  className="form-select"
                >
                  <option value="Draft">Draft</option>
                  <option value="New">New</option>
                  <option value="Review">Review</option>
                  <option value="MeetingScheduled">Meeting Scheduled</option>
                  <option value="Rescheduled">Rescheduled</option>
                  <option value="Completed">Completed</option>
                </select>
              </div> */}
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
}
