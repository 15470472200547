import React, { useEffect } from 'react';
import Footer from "../Components/Footer/Footer";
import Navigation from "../Components/Navbar/Navigation";
import ManPowerBanner from "../Components/ManPower/ManPowerBanner";
import ManPowerContent from "../Components/ManPower/ManPowerContent";
import {Helmet} from "react-helmet";
export default function ManPower() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="innerpages-nav">
      <Helmet>
        <meta charSet="utf-8" />
        <title>ManPower | DALMaf Private Limited</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="Man Power Agency" />
        <meta name="keywords" content="Best Man Power Agency" />
        <meta name="author" content="DALMaf" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>
        <div id="container">
      <Navigation />
      <ManPowerBanner />
      <ManPowerContent />
      <Footer />
      </div>
    </div>
  );
}
