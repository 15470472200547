import React, { useEffect, useState } from "react";
import {createCCAveune,renderCCAveune} from "./../Api/Api";
export const CCAvenue = () => {

  const [htmlResponse, setHtmlResponse] = useState(true);
  
  useEffect(() => {
    fetEncValue();
    
  }, []);

  const fetEncValue = async () =>{
    
    const response = await createCCAveune("Mike","123");
    setHtmlResponse(response.data)
  }
  
  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: htmlResponse }} />
    </div>
  );
};
