import React, { useState, useEffect } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import CustomModal from "../../../Components/CustomModal";
import {
  ListingBasedOnRole,
  Createadmin,
  UserProfileUpdate,
  GetAllAdminRole,
  ListingBasedOnRoleWithStatus,
} from "../../../Api/Api";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { alpha, styled } from "@mui/material/styles";
import { pink } from "@mui/material/colors";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  NonEmptyValidation,
  PhoneNumberValidation,
  EmailCompanyValidation,
  PasswordValidation,
  StringValidation,
  EmailValidation,
} from "../../../Store/validate";
import MUIDataTable from "mui-datatables";
import moment from "moment";

export default function AdminManagement() {
  const navigate = useNavigate();
  const [addAdminModal, setAddAdminModal] = useState(false);

  const [listAdmin, setListAdmin] = useState([]);
  const [selectedTab, setSelectedTab] = React.useState("Active Members");
  const AllTabs = ["Active Members", "Inactive Members"];
  const [loading, setLoading] = useState(true);

  const state = useSelector((state) => state);
  useEffect(() => {
    window.scroll(0, 0);
    getListadmin("active");
    getListAdmin()
  }, []);

  const getListadmin = async (status) => {
    const token = state?.auth?.auth?.token;
    const response = await ListingBasedOnRoleWithStatus(token, "admin", status);
    if (response.statusCode === 200) {
      let temp = [];
      response.data.map((data, i) => {
        let dummy = {
          _id: data?._id,
          name: data?.name,
          email: data?.email,
          status: data?.status,
          phoneNumber: data?.phoneNumber,
          role: data?.role,
          createdAt:  moment(data?.createdAt).format("DD/MM/YYYY"),
        };
        temp.push(dummy);
        return null;
      });
      setListAdmin(temp);
      // setListAdmin(response.data);
      setLoading(false);
      //console.log(response.data);
    } else {
      setListAdmin([]);
      setLoading(false);
    }
  };

  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  const [adminRole, setAdminRole] = useState([])

  const getListAdmin = async () => {
    setLoading(true);
    const token = state?.auth?.auth?.token;
    const response = await GetAllAdminRole(token, "active");
    if (response.statusCode === 200) {
      setAdminRole(response.data);
      setLoading(false);
    } else {
      setAdminRole([]);
      setLoading(false);
    }
  };

  //validation
  const [registerAdminDetail, setRegisterAdminDetails] = useState({});

  const [registerAdminValidation, setRegisterAdminValidation] = useState({});

  const setRegisterDetailsValue = (key, value) => {
    setRegisterAdminDetails({ ...registerAdminDetail, [key]: value });
    if (registerAdminValidation[key]) delete registerAdminValidation[key];
  };

  const setRegisterValidationValue = (key, value) => {
    setRegisterAdminValidation({ ...registerAdminValidation, [key]: value });
  };

  const handleAdminSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.name = StringValidation(registerAdminDetail?.name);
    validate.email = EmailValidation(
      registerAdminDetail?.email?.toLowerCase()
    );
    validate.phoneNumber = NonEmptyValidation(registerAdminDetail?.phoneNumber);
    validate.password = NonEmptyValidation(registerAdminDetail?.password);
    validate.pageAccess = NonEmptyValidation(registerAdminDetail?.pageAccess);

    setRegisterAdminValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        name: registerAdminDetail.name,
        role: "admin",
        email: registerAdminDetail.email?.toLowerCase(),
        phoneNumber: registerAdminDetail.phoneNumber,
        pageAccess: registerAdminDetail.pageAccess,
        password: registerAdminDetail.password,
        Designation: registerAdminDetail.Designation,
        emailVerification: true,
        status: "active",
      };

      //const token = state?.auth?.auth?.token;
      const response =await Createadmin(Payload);
      //console.log(response)
      if (response && response.statusCode === 200) {
        //console.log("test")
        setAddAdminModal(false);
        setRegisterAdminDetails({});
        showToastSuccessMsg(response.message);
        getListadmin("active");
                                window.location.reload();

      } else {
        showToastErrorMsg(
          response.message || response.error?.response?.data?.message
        );
      }
    } else {
      //console.log("Error while create an admin");
    }
  };

  const handleAdminSubmitUpdate = async (e, value, id) => {
    e.preventDefault();
    const Payload = {
      _id: id,
      status: value,
    };
    const token = state?.auth?.auth?.token;
    const response = await UserProfileUpdate(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      if (value !== "active") {
        setSelectedTab("Active Members");
        getListadmin("active");
      } else {
        setSelectedTab("Inactive Members");
        getListadmin("inactive");
      }
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };

  const PinkSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: pink[600],
      "&:hover": {
        backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: pink[600],
    },
  }));

  const label = { inputProps: { "aria-label": "Color switch demo" } };

  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <div
                style={{ cursor: "pointer" }}
                onClick={(val) => {
                  navigate(`/AdminProfileDetails/${tableMeta.rowData[0]}`);
                }}
              >
                {value}
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "email",
      label: "E- Mail ID",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "phoneNumber",
      label: "Phone No",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "role",
      label: "Role",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "createdAt",
      label: "Joining Date",
      options: {
        customBodyRender: (value) => {
          return (
            <div>
              {value}
              {/* <div>{format(new Date(value), "dd/MM/yyyy")}</div>
              <p className="mb-0">At {format(new Date(value), "h:mm:ss a")}</p> */}
            </div>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              {value === "inactive" ? (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <PinkSwitch
                        {...label}
                        onChange={(e) => {
                          handleAdminSubmitUpdate(e, "active", tableMeta.rowData[0]);
                        }}
                      />
                    }
                  />
                </FormGroup>
              ) : (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <PinkSwitch
                        {...label}
                        defaultChecked
                        onChange={(e) => {
                          handleAdminSubmitUpdate(
                            e,
                            "inactive",
                            tableMeta.rowData[0]
                          );
                        }}
                      />
                    }
                  />
                </FormGroup>
              )}
            </>
          );
        },
      },
    },
   
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    setRowProps: (row) => {
      if (row[5] === false) {
        return {
          style: { background: "#DBDFEA", cursor: "pointer" },
        };
      } else {
        return {
          style: { background: "white", cursor: "pointer" },
        };
      }
    },
  };

  return (
    <div>
      <AdminNavbar />
      <ToastContainer />
      <div className="main">
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="mb-0 fw-bold color-maroon">Admin Management</h5>
            <div>
              <button
                type="button"
                className="btn py-1 login-btn me-1"
                onClick={() => setAddAdminModal(true)}
              >
                Add Admin
              </button>
            </div>
          </div>
          <div className="my-4">
            <div className="container ">
              <div className="row">
                <div className="col-lg-7 px-0">
                  <div className="tab-section">
                    <ul className="ps-0">
                      {AllTabs.map((tab, index) => {
                        return (
                          <div
                            key={index}
                            className={
                              selectedTab === tab
                                ? "serviceTab active"
                                : "serviceTab inactive"
                            }
                            onClick={() => {
                              if(tab==="Inactive Members"){
                                getListadmin(
                                 "inactive"
                                );
                              }else{
                                getListadmin(
                                  "active"
                                 );
                              }
                             
                              setSelectedTab(tab);
                            }}
                          >
                            <li>
                              <span className="align-middle">{tab}</span>
                            </li>
                          </div>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <MUIDataTable
              title={"Dalmaf - Admin Management"}
              data={listAdmin}
              columns={columns}
              options={options}
            />

            {/* <table className="table">
              <thead className="bg-historytablehead">
                <tr>
                  <th scope="col" style={{ borderTopLeftRadius: "0" }}>
                    S. No
                  </th>
                  <th scope="col">Name</th>
                  <th scope="col">E- Mail ID </th>
                  <th scope="col">Role </th>
                  <th scope="col">Joining Date</th>
                </tr>
              </thead>
              <tbody className="historytablebody">
                {listAdmin &&
                  listAdmin.length > 0 &&
                  listAdmin?.map((data, index) => {
                    return (
                      <tr
                        className="cursor"
                        onClick={() =>
                          navigate("/AdminProfileDetails/" + data?._id)
                        }
                      >
                        <td>
                          <div className="">
                            <h6 className="fw-bold">{index + 1}</h6>
                          </div>
                        </td>
                        <td scope="row">
                          <div className="d-flex align-items-center ">
                            <div className="flex-shrink-0">
                              {data.profileImage ? (
                                <img
                                  style={{ width: "60px", height: "60px" }}
                                  src={data.profileImage}
                                  alt="..."
                                />
                              ) : (
                                <span className="material-symbols-rounded filled-icon dash-icon">
                                  person
                                </span>
                              )}
                            </div>
                            <div className=" ms-3">
                              <h6 className="fw-bold">{data.name}</h6>
                              <p className="mb-0 small">{data.phoneNumber}</p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className=" ">
                            <h6 className="fw-bold">{data.email}</h6>
                          </div>
                        </td>
                        <td>
                          <h6 className="fw-bold">{data.role}</h6>
                  </td>
                        <td>
                          <h6 className="fw-bold">
                            {format(new Date(data?.createdAt), "dd/MM/yyyy")}
                          </h6>
                          <p className="mb-0">
                            At {format(new Date(data?.createdAt), "h:mm:ss a")}
                          </p>
                        </td>
                      </tr>
                    );
                  })}
                  
              </tbody>
            </table> */}
          </div>
        </div>
      </div>
      <CustomModal
        open={addAdminModal}
        onClickOutside={() => {
          setAddAdminModal(false);
        }}
      >
        <div className="modal-content bg-white  m-auto w-50">
          <div className="modalHeader text-center py-2">
            <h5 className="font-bold mt-2">Add Admin</h5>
          </div>
          <div className="modal-body p-0 ">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container reg-page-scroll ">
                  <div className="row justify-content-center">
                    <div className="col-lg-10">
                      <div className="my-5 px-3">
                        <div className="mb-3">
                          <label for="" className="form-label">
                            Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Your Name"
                            aria-invalid={Boolean(
                              registerAdminValidation?.name?.status === false
                            )}
                            defaultValue={registerAdminDetail?.name || ""}
                            onChange={(e) => {
                              setRegisterDetailsValue("name", e.target.value);
                            }}
                            onBlur={(e) => {
                              setRegisterValidationValue(
                                "name",
                                StringValidation(e.target.value)
                              );
                            }}
                            autoComplete="off"
                          />
                          <small className="text-danger">
                            {registerAdminValidation?.name?.message
                              ? ` Name ${registerAdminValidation?.name?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="mb-3">
                          <label for="" className="form-label">
                            Email ID
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Enter Your E- Mail ID"
                            aria-invalid={Boolean(
                              registerAdminValidation?.email?.status === false
                            )}
                            defaultValue={
                              registerAdminDetail?.email?.toLowerCase() || ""
                            }
                            onChange={(e) => {
                              setRegisterDetailsValue(
                                "email",
                                e.target.value?.toLowerCase()
                              );
                            }}
                            onBlur={(e) => {
                              setRegisterValidationValue(
                                "email",
                                EmailValidation(e.target.value?.toLowerCase())
                              );
                            }}
                            autoComplete="off"
                          />
                          <small className="text-danger">
                            {registerAdminValidation?.email?.message
                              ? `Email ${registerAdminValidation?.email?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="mb-3">
                          <label for="" className="form-label">
                            Phone Number{" "}
                          </label>
                          <input
                            type="tel"
                            maxLength={10}
                            className="form-control"
                            placeholder="Enter Your Phone Number"
                            aria-invalid={Boolean(
                              registerAdminValidation?.phoneNumber?.status ===
                                false
                            )}
                            defaultValue={
                              registerAdminDetail?.phoneNumber || ""
                            }
                            onChange={(e) => {
                              setRegisterDetailsValue(
                                "phoneNumber",
                                e.target.value
                              );
                            }}
                            onBlur={(e) => {
                              setRegisterValidationValue(
                                "phoneNumber",
                                PhoneNumberValidation(e.target.value)
                              );
                            }}
                            autoComplete="off"
                          />
                          <small className="text-danger">
                            {registerAdminValidation?.phoneNumber?.message
                              ? `Phone no ${registerAdminValidation?.phoneNumber?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="mb-3">
                          <label for="" className="form-label">
                            Page Access
                          </label>
                          <select
                            className="form-control form-control-lg"
                            defaultValue={registerAdminDetail?.pageAccess || ""}
                            onChange={(e) => {
                              setRegisterDetailsValue(
                                "pageAccess",
                                e.target.value
                              );
                            }}
                          >
                            <option value="">---Select Page Access---</option>
                            {adminRole?.map((e, i) => (
                              <option  value={e._id}>
                                {e.title}
                              </option>
                            ))}
                          </select>

                          <small className="text-danger">
                            {registerAdminValidation?.pageAccess?.message
                              ? `Page Access ${registerAdminValidation?.pageAccess?.message}`
                              : ""}
                          </small>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-4">
                            <label className="form-label">Password</label>
                            <input
                              type="text"
                              className="form-control form-control-lg"
                              placeholder="Enter  the passowrd"
                              aria-invalid={Boolean(
                                registerAdminValidation?.password?.status ===
                                  false
                              )}
                              defaultValue={registerAdminDetail?.password || ""}
                              onChange={(e) => {
                                setRegisterDetailsValue(
                                  "password",
                                  e.target.value
                                );
                              }}
                              onBlur={(e) => {
                                setRegisterValidationValue(
                                  "password",
                                  PasswordValidation(e.target.value)
                                );
                              }}
                              autoComplete="off"
                            />
                            <small className="text-danger">
                              {registerAdminValidation?.password?.message
                                ? `Password ${registerAdminValidation?.password?.message}`
                                : ""}
                            </small>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-4">
                            <label className="form-label">Designation</label>
                            <input
                              type="text"
                              className="form-control form-control-lg"
                              placeholder="Enter  the Designation"
                              aria-invalid={Boolean(
                                registerAdminValidation?.Designation?.status ===
                                  false
                              )}
                              defaultValue={registerAdminDetail?.Designation || ""}
                              onChange={(e) => {
                                setRegisterDetailsValue(
                                  "Designation",
                                  e.target.value
                                );
                              }}
                              onBlur={(e) => {
                                setRegisterValidationValue(
                                  "Designation",
                                  NonEmptyValidation(e.target.value)
                                );
                              }}
                              autoComplete="off"
                            />
                            <small className="text-danger">
                              {registerAdminValidation?.Designation?.message
                                ? `Designation ${registerAdminValidation?.Designation?.message}`
                                : ""}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer justify-content-center border-0">
            <div>
              <button
                type="button"
                className="btn mx-2 white-btn"
                onClick={() => {
                  setAddAdminModal(false);
                  setRegisterAdminDetails({});
                }}
              >
                {" "}
                Cancel
              </button>
            </div>
            <div>
              <button
                type="button"
                className="btn mx-2  login-btn"
                onClick={(e) => {
                  handleAdminSubmit(e);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}
