import { useEffect, useState } from "react";
import FreelancerPartnerNav from "../../../Components/v1/NavBar/FreelancerPartnerNav";
import MainDashboardComponent from "../../../Components/v1/FreelancerPartner/Leads/MainDashboardComponent";


export default function Dashboard({ userTypeProps, lableProps, isClientProps, routeProps }) {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  const [show, setShow] = useState(true);
  const handleToggle = () => { setShow(!show); };

  return (
    <>
      <FreelancerPartnerNav showFullNav={show} userTypeProps={userTypeProps} routeProps={routeProps} onToggle={handleToggle} />
      <div style={{ marginLeft: show ? '220px' : '60px',padding:"20px" }}>
        <MainDashboardComponent
          lableProps={lableProps}
          userTypeProps={userTypeProps}
          showFullNav={show}
          isClientProps={isClientProps}
          routeProps={routeProps} />
      </div>

    </>
  );
}
