import React, { useEffect, useState } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchWithQueryAPI } from "../../../Api/v1/Util/Api";
import { dashboard_revenue_count_admin_url } from "../../../Api/APIUrl";
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell, LabelList, PieChart, Pie
} from 'recharts';
import { format, startOfToday, endOfToday, startOfWeek, endOfWeek, startOfMonth, endOfMonth, subDays, subMonths } from 'date-fns';
import DatePicker from 'react-datepicker'; // Import DatePicker component
import 'react-datepicker/dist/react-datepicker.css'; // DatePicker CSS

const RevenueCountDashboard = () => {
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const [revenueData, setRevenueData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fromDate, setFromDate] = useState(startOfToday()); // Default to today
  const [toDate, setToDate] = useState(endOfToday()); // Default to today
  const [isCustom, setIsCustom] = useState(false); // Track if custom date is selected

  // Filter options
  const filterOptions = [
    { label: "Today", calculateDates: () => [startOfToday(), endOfToday()] },
    { label: "Yesterday", calculateDates: () => [subDays(startOfToday(), 1), subDays(endOfToday(), 1)] },
    { label: "This Week", calculateDates: () => [startOfWeek(new Date()), endOfWeek(new Date())] },
    { label: "Last Week", calculateDates: () => [startOfWeek(subDays(new Date(), 7)), endOfWeek(subDays(new Date(), 7))] },
    { label: "This Month", calculateDates: () => [startOfMonth(new Date()), endOfMonth(new Date())] },
    { label: "Last Month", calculateDates: () => [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))] },
    { label: "Last 3 Months", calculateDates: () => [startOfMonth(subMonths(new Date(), 3)), endOfMonth(subMonths(new Date(), 1))] },
    { label: "Last 6 Months", calculateDates: () => [startOfMonth(subMonths(new Date(), 6)), endOfMonth(new Date())] },
    { label: "Custom", custom: true } // Custom date range
  ];

  const handleFetchCountDashboard = async () => {
    if (!fromDate || !toDate) return; // Exit if dates are not set

    const formattedFromDate = format(fromDate, 'yyyy-MM-dd');
    const formattedToDate = format(toDate, 'yyyy-MM-dd');

    try {
      const response = await fetchWithQueryAPI(
        state.auth.auth.token,
        `${dashboard_revenue_count_admin_url}&from_date=${formattedFromDate}&to_date=${formattedToDate}`
      );
      if (response.statusCode === 200) {
        setRevenueData(response.data);
      } else {
        setError(response.message || "Failed to fetch data");
      }
    } catch (error) {
      setError("Error fetching revenue data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetchCountDashboard();
  }, [fromDate, toDate]);

  // Handle filter selection
  const handleFilterChange = (filterOption) => {
    if (filterOption.custom) {
      setIsCustom(true); // Activate custom date picker
    } else {
      const [from, to] = filterOption.calculateDates();
      setFromDate(from);
      setToDate(to);
      setIsCustom(false); // Deactivate custom date picker
    }
  };

  // Prepare data for the chart
  const chartData = [
    { name: "Package Revenue", value: revenueData?.package_revenue || 0 },
    { name: "Custom Package Revenue", value: revenueData?.custom_package_revenue || 0 },
    { name: "Service Request Revenue", value: revenueData?.service_revenue || 0 },
    { name: "Auction Revenue", value: revenueData?.auction_revenue || 0 },
  ];

  // Colors for each bar (to simulate a more "3D" look)
  const barColors = ["#8884d8", "#82ca9d", "#ffc658", "#ff6b6b"];

  // Format the number with rupee symbol and commas
  const formatRupee = (value) => `₹${value.toLocaleString('en-IN')}`;

  return (
    <>
      <div className="container mt-4">
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p className="text-danger">{error}</p>
        ) : (
          <div className="row">
            <div className="col-md-10">
              <ResponsiveContainer width="100%" height={500}>
                <BarChart
                  data={chartData}
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip formatter={(value) => formatRupee(value)} />
                  <Legend />
                  <Bar dataKey="value" fill="#8884d8" radius={[10, 10, 0, 0]}>
                    {chartData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={barColors[index % barColors.length]} />
                    ))}
                    <LabelList
                      dataKey="value"
                      position="top"
                      content={({ x, y, value }) => (
                        <text
                          x={x}
                          y={y}
                          dy={-6}
                          fontSize={14}
                          fontWeight="bold"
                          fill="#000"
                          textAnchor="middle"
                        >
                          {formatRupee(value)}
                        </text>
                      )}
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
              <h3 className="text-center mb-4">Revenue Overview</h3>
            </div>
            <div className="col-md-2">
              <label>Filter By</label>
              <select
                className="form-select"
                onChange={(e) => handleFilterChange(filterOptions[e.target.value])}
              >
                {filterOptions.map((option, index) => (
                  <option key={index} value={index}>{option.label}</option>
                ))}
              </select>
              <br></br>
              <label>From Date</label>
              <DatePicker
                selected={fromDate}
                onChange={(date) => setFromDate(date)}
                maxDate={new Date()} // Prevent future dates
                className="form-control"
                dateFormat="dd-MM-yyyy"
              />
              <br></br><br></br>
              <label>To Date</label>
              <DatePicker
                selected={toDate}
                onChange={(date) => setToDate(date)}
                maxDate={new Date()} // Prevent future dates
                className="form-control"
                dateFormat="dd-MM-yyyy"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RevenueCountDashboard;
