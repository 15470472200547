import React, { useState, useEffect } from "react";
import CustomModal from "../CustomModal";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../Assets/Images/logo.webp";
import NewLog from "../../Assets/dalmaf-vertical-logo.png";
import { useDispatch, useSelector } from "react-redux";
import OtpInput from "react-otp-input";
import DropImg from "../../Assets/Images/drop-img.webp";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Profile from "../../Assets/Images/profile.webp";
 
import DropImgModal from "../../Assets/Images/navbar-img/drop-img-modal.webp";
import DropImgModal1 from "../../Assets/Images/navbar-img/navheadimg1.webp";
import DropImgModal2 from "../../Assets/Images/navbar-img/navheadimg2.webp";
import DropImgModal3 from "../../Assets/Images/navbar-img/navheadimg3.webp";
import DropImgModal4 from "../../Assets/Images/navbar-img/navheadimg4.webp";
import DropImgModal5 from "../../Assets/Images/navbar-img/navheadimg5.webp";
import instaicon from "../../Assets/Images/instagram-icon.png";
import DMLeft from "../../Assets/Images/dm-left.webp";
import NavFam from "../../Assets/Images/navbar-img/fam.webp";
import F1 from "../../Assets/Images/navbar-img/f1.webp";
import F2 from "../../Assets/Images/navbar-img/f2.webp";
import F3 from "../../Assets/Images/navbar-img/f3.webp";
import F4 from "../../Assets/Images/navbar-img/f4.webp";
import F5 from "../../Assets/Images/navbar-img/f5.webp";
import A1 from "../../Assets/Images/navbar-img/a1.webp";
import A2 from "../../Assets/Images/navbar-img/a2.webp";
import A3 from "../../Assets/Images/navbar-img/a3.webp";
import A4 from "../../Assets/Images/navbar-img/a4.webp";
import A5 from "../../Assets/Images/navbar-img/a5.webp";
import DM from "../../Assets/Images/marketing-icon.webp";
import ManPowerNav from "../../Assets/Images/manpower-nav.webp";
import OsNav from "../../Assets/Images/os-nav.webp";
import BuysellNav from "../../Assets/Images/buy-sell-nav.webp";
import AuctionNav from "../../Assets/Images/auction-nav.webp";
import LegalNav from "../../Assets/Images/legal-modal-nav.webp";
import LegalIcon from "../../Assets/Images/si-3.webp";
import MarketingIcon from "../../Assets/Images/marketing-icon-nav.webp";
import AuctionIcon from "../../Assets/Images/si-2.webp";
import ManPowerIcon from "../../Assets/Images/si-1.webp";
import BuySellIcon from "../../Assets/Images/buysell-icon.webp";

import {
  EmailValidation,
  NonEmptyKeyValidation,
  NonEmptyValidation,
  PasswordValidation,
  PhoneNumberValidation,
  RePasswordValidation,
  StringValidation,
} from "../../Store/validate";
import {
  ChangePassword,
  CreateNewsLetter,
  ForgotOtp,
  ForgotPassword,
  GetContent,
  GetLevel1,
  GetLevel2,
  GetMain,
  GetUser,
  SignUp,
  UserLogin,
  VerifyOtp,
} from "../../Api/Api";
import { loginSuccess, registerDetails } from "../../Store/auth/action";

export default function Navigation() {
  let host = window.location.href;
  let component = host?.split("/");
  //  //console.log(component)

  const navigate = useNavigate();

  const [stickyClass, setStickyClass] = useState("");
  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight > 150 ? setStickyClass("sticky-nav") : setStickyClass("");
    }
  };

  const [manpowerNavbarModal, setmanpowerNavbarModal] = useState(false);
  const [auctionContent, setAuctionContent] = useState(false);
  const [legalNavbarModal, setlegalNavbarModal] = useState(false);
  const [otherserviceNavbarModal, setotherserviceNavbarModal] = useState(false);
  const [otherserviceNavbarModal1, setotherserviceNavbarModal1] =
    useState(false);
  const [auditNavbarModal, setauditNavbarModal] = useState(false);
  const [selectedTab, setSelectedTab] = React.useState("Find An Advocate");
  const AllTabs = ["Find An Advocate", "Legal Services"];

  useEffect(() => {
    if (manpowerNavbarModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [manpowerNavbarModal]);
  useEffect(() => {
    if (legalNavbarModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [legalNavbarModal]);
  useEffect(() => {
    if (otherserviceNavbarModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [otherserviceNavbarModal]);
  useEffect(() => {
    if (auditNavbarModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [auditNavbarModal]);

  const [selectedManPower, setSelectedManPower] = React.useState("Man Power");
  const AllManPowerTabs = [{ heading: "Man Power", icon: ManPowerIcon }];

  const [selectedLegal, setSelectedLegal] = React.useState("Civil Cases");



  const [selectedLegal2, setSelectedLegal2] = React.useState(
    "Family & Matrimonial"
  );


  const DataLegal2 = [
    {
      name: "Mutual Divorce",
      route: "/Legal",
      icon: F1,
      type: "Family & Matrimonial",
    },
    {
      name: "Marriage Registration",
      route: "/Legal",
      icon: F1,
      type: "Civil Law / Property",
    },
    {
      name: "Court Marriage",
      route: "/Legal",
      icon: F1,
      type: "Civil Law / Property",
    },
    {
      name: "Divorce Notice",
      route: "/Legal",
      icon: F1,
      type: "Civil Law / Property",
    },
    {
      name: "Marriage Counselling",
      route: "/Legal",
      icon: F1,
      type: "Civil Law / Property",
    },
    {
      name: "Will Drafting",
      route: "/Legal",
      icon: F1,
      type: "Civil Law / Property",
    },
    {
      name: "Succession Certificate",
      route: "/Legal",
      icon: F1,
      type: "Civil Law / Property",
    },
    {
      name: "Property Verification",
      route: "/Legal",
      icon: F1,
      type: "Civil Law / Property",
    },
    {
      name: "Gift Deed Preparation",
      route: "/Legal",
      icon: F1,
      type: "Civil Law / Property",
    },
    {
      name: "Lease Agreement Preparation",
      route: "/Legal",
      icon: F1,
      type: "Civil Law / Property",
    },
    {
      name: "Lease Agreement Registration",
      route: "/Legal",
      icon: F1,
      type: "Civil Law / Property",
    },
    {
      name: "Power of Attorney",
      route: "/Legal",
      icon: F1,
      type: "Civil Law / Property",
    },
    {
      name: "Mou Preparation",
      route: "/Legal",
      icon: F1,
      type: "Civil Law / Property",
    },
    {
      name: "Consumer Notice",
      route: "/Legal",
      icon: F1,
      type: "Legal Notice",
    },
    {
      name: "Consumer Notice",
      route: "/Legal",
      icon: F1,
      type: "Legal Notice",
    },
    {
      name: "Fault Property Notice",
      route: "/Legal",
      icon: F1,
      type: "Legal Notice",
    },
    {
      name: "Property Legal Opinion",
      route: "/Legal",
      icon: F1,
      type: "Legal Notice",
    },
    {
      name: "Cheque Balance Notice",
      route: "/Legal",
      icon: F1,
      type: "Legal Notice",
    },
    {
      name: "Recovery Notice",
      route: "/Legal",
      icon: F1,
      type: "Legal Notice",
    },
    {
      name: "Refund & Security Notice",
      route: "/Legal",
      icon: F1,
      type: "Legal Notice",
    },
    {
      name: "Provident Fund Issues",
      route: "/Legal",
      icon: F1,
      type: "Employee and Employer Cases",
    },
    {
      name: "ESI Issues",
      route: "/Legal",
      icon: F1,
      type: "Employee and Employer Cases",
    },
    {
      name: "Professional Tax Issues",
      route: "/Legal",
      icon: F1,
      type: "Employee and Employer Cases",
    },
    {
      name: "Employer Salary Issues",
      route: "/Legal",
      icon: F1,
      type: "Employee and Employer Cases",
    },
    {
      name: "Remedy for Salary Issues with Employer",
      route: "/Legal",
      icon: F1,
      type: "Employee and Employer Cases",
    },
    {
      name: "Remedy for Delay in Salary by Employer",
      route: "/Legal",
      icon: F1,
      type: "Employee and Employer Cases",
    },
    {
      name: "Mental Harassment in Workplace",
      route: "/Legal",
      icon: F1,
      type: "Employee and Employer Cases",
    },
    {
      name: "Sexual Harassment in Workplace",
      route: "/Legal",
      icon: F1,
      type: "Employee and Employer Cases",
    },
    {
      name: "Remedy for Sexual Harassment in Workplace",
      route: "/Legal",
      icon: F1,
      type: "Employee and Employer Cases",
    },
    {
      name: "Employee Notice Period",
      route: "/Legal",
      icon: F1,
      type: "Employee and Employer Cases",
    },
    {
      name: "Refusal to Give Original Certificates by the Employer",
      route: "/Legal",
      icon: F1,
      type: "Employee and Employer Cases",
    },
    {
      name: "Gratuity Issues",
      route: "/Legal",
      icon: F1,
      type: "Employee and Employer Cases",
    },
    {
      name: "Refusal of Accepting the Resignation by Employer",
      route: "/Legal",
      icon: F1,
      type: "Employee and Employer Cases",
    },
    {
      name: "Workplace safety issues",
      route: "/Legal",
      icon: F1,
      type: "Employee and Employer Cases",
    },
    {
      name: "Wrongful Termination",
      route: "/Legal",
      icon: F1,
      type: "Employee and Employer Cases",
    },
    {
      name: "Remedy for Wrongful Termination",
      route: "/Legal",
      icon: F1,
      type: "Employee and Employer Cases",
    },
    {
      name: "Violation of Privacy things",
      route: "/Legal",
      icon: F1,
      type: "Employee and Employer Cases",
    },
    {
      name: "Industrial Disputes Act, 1974",
      route: "/Legal",
      icon: F1,
      type: "Employee and Employer Cases",
    },
    {
      name: "Minimum Wages Act, 1948",
      route: "/Legal",
      icon: F1,
      type: "Employee and Employer Cases",
    },
    {
      name: "Payment of Wages Act, 1936",
      route: "/Legal",
      icon: F1,
      type: "Employee and Employer Cases",
    },
    {
      name: "The Employer Provident Fund and Miscellaneous Act, 1952",
      route: "/Legal",
      icon: F1,
      type: "Employee and Employer Cases",
    },
    {
      name: "Maternity Benefit Act, 1961",
      route: "/Legal",
      icon: F1,
      type: "Employee and Employer Cases",
    },
    {
      name: "Prevention, Prohibition and Redressal Act, 2013",
      route: "/Legal",
      icon: F1,
      type: "Employee and Employer Cases",
    },
    {
      name: "Factories Act, 1948",
      route: "/Legal",
      icon: F1,
      type: "Employee and Employer Cases",
    },
    {
      name: "The Mines Act, 1952",
      route: "/Legal",
      icon: F1,
      type: "Employee and Employer Cases",
    },
    {
      name: "The Building and other Construction Workers Act, 1996 ",
      route: "/Legal",
      icon: F1,
      type: "Employee and Employer Cases",
    },
    {
      name: "ESI",
      route: "/Legal",
      icon: F1,
      type: "Others",
    },
    {
      name: "PF",
      route: "/Legal",
      icon: F1,
      type: "Others",
    },
    {
      name: "Medical Insurance",
      route: "/Legal",
      icon: F1,
      type: "Others",
    },
    {
      name: "Car Insurance",
      route: "/Legal",
      icon: F1,
      type: "Others",
    },
    {
      name: "Labour Court",
      route: "/Legal",
      icon: F1,
      type: "Others",
    },
  ];

  const [selectedAudit, setSelectedAudit] = React.useState("Startup");
  const AllAuditTabs = [
    { heading: "Startup", icon: A1 },
    { heading: "Trademark", icon: A2 },
    { heading: "GST", icon: A3 },
    { heading: "IncomeTax", icon: A4 },
    { heading: "Compliance", icon: A5 },
  ];


  const [selectedOS, setSelectedOS] = React.useState("Marketing");

  const [manPowerContent, setManPowerContent] = useState(false);
  const [auditContent, setAuditContent] = useState(false);
  const [BuySellContent, setBuySellContent] = useState(false);
  const [dropdownContent, setDropdownContent] = useState(false);
  const [dropdownContent2, setDropdownContent2] = useState(false);
  const [OSContent, setOSContent] = useState(false);
  const [OSContent1, setOSContent1] = useState(false);

  useEffect(() => {
    if (manPowerContent) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [manPowerContent]);
  useEffect(() => {
    if (auditContent) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [auditContent]);
  useEffect(() => {
    if (dropdownContent) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [dropdownContent]);
  useEffect(() => {
    if (dropdownContent2) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [dropdownContent2]);
  useEffect(() => {
    if (OSContent) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [OSContent]);

  const [loginModal, setloginModal] = useState(false);
  const [createModal, setcreateModal] = useState(false);
  const [fpwModal, setfpwModal] = useState(false);
  const [otpModal, setotpModal] = useState(false);
  const [otpFgtModal, setotpFgtModal] = useState(false);
  const [changepswModal, setchangepswModal] = useState(false);

  const [password, setPassword] = useState();
  const viewPassword = () => {
    setPassword(!password);
  };
  const [password2, setPassword2] = useState();
  const viewPassword2 = () => {
    setPassword2(!password2);
  };

  // state values
  const state = useSelector((state) => state);
  const [passwordStrength, setPasswordStrength] = useState("");
  const [registerDetail, setRegisterDetails] = useState({});

  const [registerValidation, setRegisterValidation] = useState({});

  const setRegisterDetailsValue = (key, value) => {
    setRegisterDetails({ ...registerDetail, [key]: value });
    if (registerValidation[key]) delete registerValidation[key];
  };

  const setRegisterValidationValue = (key, value) => {
    setRegisterValidation({ ...registerValidation, [key]: value });
  };

  const dispatch = useDispatch();
  // const location = useLocation();
  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 3000,
    });
  };

  let data = [
    {
      name: "Apartment",
      route: "/OtherService&tab=Apartment",
      icon: MarketingIcon,
      type: "Auction",
    },
    {
      name: "Independent Villa",
      route: "/OtherService&tab=Villa",
      icon: MarketingIcon,
      type: "Auction",
    },
    {
      name: "Individual House",
      route: "/OtherService&tab=IndividualHouse",
      icon: MarketingIcon,
      type: "Auction",
    },
    {
      name: "Plot",
      route: "/OtherService&tab=Plot",
      icon: MarketingIcon,
      type: "Auction",
    },
    {
      name: "Gold",
      route: "/OtherService&tab=Gold",
      icon: MarketingIcon,
      type: "Auction",
    },
    {
      name: "Two Wheeler",
      route: "/OtherService&tab=TwoWheeler",
      icon: MarketingIcon,
      type: "Auction",
    },
    {
      name: "Four Wheeler",
      route: "/OtherService&tab=FourWheeler",
      icon: MarketingIcon,
      type: "Auction",
    },
  ];

  let data1 = [
    {
      name: "Apartment",
      route: "/OtherService&tab=BSApartment",
      icon: BuySellIcon,
      type: "Buy/Sell",
    },
    {
      name: "Independent Villa",
      route: "/OtherService&tab=BSVilla",
      icon: BuySellIcon,
      type: "Buy/Sell",
    },
    {
      name: "Individual House",
      route: "/OtherService&tab=BSIndividualHouse",
      icon: BuySellIcon,
      type: "Buy/Sell",
    },
    {
      name: "Plot",
      route: "/OtherService&tab=BSPlot",
      icon: BuySellIcon,
      type: "Buy/Sell",
    },
    {
      name: "Gold",
      route: "/OtherService&tab=BSGold",
      icon: BuySellIcon,
      type: "Buy/Sell",
    },
    {
      name: "Two Wheeler",
      route: "/OtherService&tab=BSTwoWheeler",
      icon: BuySellIcon,
      type: "Buy/Sell",
    },
    {
      name: "Four Wheeler",
      route: "/OtherService&tab=BSFourWheeler",
      icon: BuySellIcon,
      type: "Buy/Sell",
    },
  ];

  const naviGation = useNavigate();

  //User Registration api call

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.name = StringValidation(registerDetail?.name);
    validate.email = EmailValidation(registerDetail?.email?.toLowerCase());
    validate.password = PasswordValidation(registerDetail?.password);
    validate.phoneNumber = PhoneNumberValidation(registerDetail?.phoneNumber);
    setRegisterValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        name: registerDetail.name,
        email: registerDetail.email?.toLowerCase(),
        password: registerDetail.password,
        phoneNumber: registerDetail.phoneNumber,
        role: "user",
      };

      const response = await SignUp(Payload);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        setcreateModal(false);
        setRegisterDetails({});
        setRegisterValidationValue({});
        setotpModal(true);
        dispatch(registerDetails(registerDetail));
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        //setfailureModal(true);
      }
    } else {
      //console.log("Error while register as user");
    }
  };

  const handleSubmitResend = async (e) => {
    e.preventDefault();
    const Payload = {
      name: state.auth.registerData.name,
      email: state.auth.registerData.email,
      password: state.auth.registerData.password,
      phoneNumber: state.auth.registerData.phoneNumber,
      role: "user",
    };

    const response = await SignUp(Payload);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      setMinutes(0);
      setSeconds(59);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setfailureModal(true);
    }
  };

  const handleSubmitResend1 = async (e) => {
    e.preventDefault();
    const Payload = {
      email: state.auth.registerData.email,
    };

    const response = await ForgotPassword(Payload);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      setMinutes(0);
      setSeconds(59);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setfailureModal(true);
    }
  };
  const [code, setOtp] = useState("");
  const [validation, setValidation] = useState({});
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });
  //Otp Submit
  const handleChange = (code) => {
    setOtp(code);
    setValidation({});
  };

  const handleSubmitNewsLetter = async (e) => {
    e.preventDefault();

    const Payload = {
      fullName: state.auth.registerData.name,
      emailId: state.auth.registerData.email,
    };
    const token = state?.auth?.auth?.token;
    const response = await CreateNewsLetter(Payload, token);
    if (response && response.statusCode === 200) {
      // showToastSuccessMsg(response.message);
    } else { 
      // showToastErrorMsg(
      //   response.message || response.error.response.data.message
      // );
    }
  };

  // otp
  const handleSubmitOtp = async (data, value) => {
    data.preventDefault();
    let validate = {};
    validate.code = NonEmptyValidation(code);
    setValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        email: state.auth.registerData.email,
        otp: parseInt(code),
      };
      let response = await VerifyOtp(Payload);
      if (response && response.statusCode === 200) {
        setotpModal(false);
        if (state?.auth?.registerData?.acceptNewsLetter === true) {
          handleSubmitNewsLetter(data);
        }

        setOtp("");
        setloginModal(true);
        showToastSuccessMsg(response.message);
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        // setFailureModal(true);
      }
    } else {
      //console.log("Error while verify otp");
    }
  };

  const handleSubmitOtpFgt = async (data) => {
    data.preventDefault();
    let validate = {};
    validate.code = NonEmptyValidation(code);
    setValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        email: state.auth.registerData.email,
        otp: parseInt(code),
      };
      let response = await ForgotOtp(Payload);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);

        setotpFgtModal(false);
        setchangepswModal(true);
        setOtp("");
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        // setFailureModal(true);
      }
    }
  };
  const [registerDetail1, setRegisterDetails1] = useState({});

  const [registerValidation1, setRegisterValidation1] = useState({});

  const setRegisterDetailsValue1 = (key, value) => {
    setRegisterDetails1({ ...registerDetail1, [key]: value });
    if (registerValidation1[key]) delete registerValidation1[key];
  };

  const setRegisterValidationValue1 = (key, value) => {
    setRegisterValidation1({ ...registerValidation1, [key]: value });
  };

  //login api call

  const handleSubmit1 = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.email = EmailValidation(registerDetail1?.email?.toLowerCase());
    validate.password = PasswordValidation(registerDetail1?.password);
    setRegisterValidation1(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        email: registerDetail1.email?.toLowerCase(),
        password: registerDetail1.password,
      };

      const response = await UserLogin(Payload);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        dispatch(loginSuccess(response.data, response?.data?.token));
        setloginModal(false);
        setRegisterDetails1({});
        navigate("/");
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } else {
      //console.log("Error while login as user");
    }
  };

  const [registerDetail2, setRegisterDetails2] = useState({});

  const [registerValidation2, setRegisterValidation2] = useState({});

  const setRegisterDetailsValue2 = (key, value) => {
    setRegisterDetails2({ ...registerDetail2, [key]: value });
    if (registerValidation2[key]) delete registerValidation2[key];
  };

  const setRegisterValidationValue2 = (key, value) => {
    setRegisterValidation2({ ...registerValidation2, [key]: value });
  };

  //forgot password api call

  const handleSubmitForgotPassword = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.email = EmailValidation(registerDetail2?.email?.toLowerCase());
    setRegisterValidation2(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        email: registerDetail2.email?.toLowerCase(),
      };

      const response = await ForgotPassword(Payload);
      if (response && response.statusCode === 200) {
        dispatch(registerDetails(registerDetail2));
        showToastSuccessMsg(response.message);
        setfpwModal(false);
        setotpFgtModal(true);
        setRegisterDetails2({});
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        //setfailureModal(true);
      }
    } else {
      //console.log("Error while reset password");
    }
  };

  const [registerDetail3, setRegisterDetails3] = useState({});

  const [registerValidation3, setRegisterValidation3] = useState({});

  const setRegisterDetailsValue3 = (key, value) => {
    setRegisterDetails3({ ...registerDetail3, [key]: value });
    if (registerValidation3[key]) delete registerValidation3[key];
  };

  const setRegisterValidationValue3 = (key, value) => {
    setRegisterValidation3({ ...registerValidation3, [key]: value });
  };

  //forgot password api call

  const handleSubmitChangePassword = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.password = PasswordValidation(registerDetail3?.password);
    validate.re_password = RePasswordValidation(
      registerDetail3?.re_password,
      registerDetail3?.password
    );
    setRegisterValidation3(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        email: state.auth.registerData.email,
        password: registerDetail3?.password,
      };

      const response = await ChangePassword(Payload);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        setchangepswModal(false);
        setloginModal(true);
        setRegisterDetails3({});
        getUserProfile();
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        //setfailureModal(true);
      }
    } else {
      //console.log("Error while chenge password");
    }
  };

  const [userProfile, setUserProfile] = useState([]);
  useEffect(() => {
    if (state?.auth?.isLoggedIn === true) {
      getUserProfile();
    }
  }, []);
  const getUserProfile = async () => {
    const id = state?.auth?.auth?._id;
    const token = state.auth.auth.token;
    const response = await GetUser(token, id);
    if (response.statusCode === 200) {
      setUserProfile(response.data);
    } else {
      //console.log("error while getting user profile info");
    }
  };

  const [getMyTaskMain, setGetMyTaskMain] = useState([]);
  const [getMyTasklevel1, setGetMyTasklevel1] = useState([]);
  const [getMyTasklevel2, setGetMyTasklevel2] = useState([]);
  const [isSelected, setIsSelected] = useState(null);
  const [isMainId, setIsMainid] = useState(null);
  useEffect(() => {
    setTimeout(() => {
      GetMyTsksMain();
    }, 1000);
    // console.log("test");
  }, ["active", getMyTaskMain?.[0]?._id]);

  const GetMyTsksMain = async () => {
    let data = await GetMain("active");

    setGetMyTasklevel2([])
    if (data && data.statusCode === 200) {
      const numAscending = data.data;//[...data.data];
      setGetMyTaskMain(numAscending);
      setIsMainid(numAscending?.[0]?._id);
    } else {
      setGetMyTaskMain([]);
    }
  };
  const GetMyTsksLevel1 = async (status, id) => {
    let data = await GetLevel1(status, id);
    if (data && data.statusCode === 200) {
      const numAscending = data.data;//[...data.data];
      setGetMyTasklevel1(numAscending);
      setIsSelected(numAscending?.[0]?._id); // Set the first item as active by default
      GetMyTsksLevel2("active", id, numAscending?.[0]?._id);
    } else {
      setGetMyTasklevel1([]);
    }
  };

  const GetMyTsksLevel2 = async (status, id, id1) => {
    let data = await GetLevel2(status, id, id1);
    setAuditContent(true);
    if (data && data.statusCode === 200) {
      const numAscending = data.data;//[...data.data];
      setGetMyTasklevel2(numAscending);
    } else {
      setGetMyTasklevel2([]);
    }
  };

  return (
    <div>
      <div
        className=""
        open={auditContent}
        onClickOutside={() => {
          setAuditContent(false);
        }}
      >
        <div className=" topbar-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="">
                  <li className="list-unstyled">
                    <span className="material-symbols-rounded align-middle me-1">
                      phone_in_talk
                    </span>
                    Toll Free:{" "}
                    <a
                      className=" "
                      href="tel:1800-309-8445"
                      target="_blank"
                      alt=""
                    >
                      <span>1800-309-8445</span>
                    </a>
                  </li>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="">
                  <li className="list-unstyled">
                    <span className="material-symbols-rounded align-middle me-1">
                      phone_iphone
                    </span>
                    {/* Toll Free:{" "} */}
                    <a
                      className=" "
                      href="tel:+91 9884211885"
                      target="_blank"
                      alt=""
                    >
                      <span>+91 9884211885</span>
                    </a>
                  </li>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="">
                  <li className="list-unstyled">
                    <span className="material-symbols-rounded align-middle filled-icon me-1">
                      mail
                    </span>
                    
                    <a
                      className=" "
                      href="mailto:info@dalmaf.com"
                      target="_blank"
                      alt=""
                    >
                      <span>info@dalmaf.com</span>
                    </a>
                  </li>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="">
                  <li className="list-unstyled">
                    Follow us on
                    <a
                      style={{ marginLeft: "5px" }}
                      href="https://www.instagram.com/dalmaf_dpl?igsh=M2R0bGI0MjNvZ2E3"
                      target="_blank"
                      alt=""
                    >
                      <img src={instaicon} width="20px" />
                    </a> 
                    <a
                      className=" "
                      style={{marginLeft:"10px"}}
                      href="https://play.google.com/store/apps/details?id=com.dalmaf"
                      target="_blank"
                      alt=""
                    >
                      <img src="https://static.vecteezy.com/system/resources/previews/022/613/026/original/google-play-store-icon-logo-symbol-free-png.png" width="20px" />
                    </a>
                    <a
                      className=" "
                      href="https://apps.apple.com/in/app/dalmaf/id6467872452"
                      target="_blank"
                      style={{marginLeft:"10px"}}
                      alt=""
                    >
                      <img src="https://www.freepnglogos.com/uploads/app-store-logo-png/app-store-vector-logos-download-2.png" width="30px" />
                    </a>
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <nav
            className={`navbar  navbar-expand-lg navbar-light  ${stickyClass}`}
          >
            <div className="container-fluid">
              <a className="navbar-brand" href="#">
                <img
                  className="logo-img"
                  style={{ marginLeft: "50px" }}
                  onClick={() => navigate("/")}
                  src={NewLog}
                  alt=""
                />
                {/* <h2 className="banner-heading mb-0">Dalmaf</h2> */}
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li className="nav-item ">
                    <NavLink className="nav-link " aria-current="page" to="/">
                      Home
                    </NavLink>
                  </li>
                  {/* <li className="nav-item">
                    <NavLink
                      className="nav-link "
                      aria-current="page"
                      to="/About"
                    >
                      About US
                    </NavLink>
                  </li> */}
                  {/* {getMyTaskMain?.map((nav, i) => (
                    <li className="nav-item dropdown has-megamenu" key={i}>
                      
                      <a
                        style={{ textTransform: "capitalize" }}
                        className={(window.location.href.includes('Audit') && nav?.title?.toLowerCase() === "audit") 
                          | (window.location.href.includes('Legal') && nav?.title?.toLowerCase() === "legal")
                          | (window.location.href.includes('OtherService') && nav?.title?.toLowerCase() === "other services")
                          | (window.location.href.includes('manpower') && nav?.title?.toLowerCase() === "man power" )
                          ?"nav-link dropdown-toggle active":"nav-link dropdown-toggle"}
                        href="#"
                        data-bs-toggle="dropdown"
                        onClick={() => {
                          if (nav?.title?.toLowerCase() === "audit") {
                            GetMyTsksLevel1("active", nav._id);
                            localStorage?.setItem("mainContent", nav._id);
                            setauditNavbarModal(true);
                          } else if (nav?.title?.toLowerCase() === "legal") {
                            GetMyTsksLevel1("active", nav._id);
                            localStorage?.setItem("mainContent", nav._id);
                            setlegalNavbarModal(true);
                          } else if (
                            nav?.title?.toLowerCase() === "man power"
                          ) {
                            GetMyTsksLevel1("active", nav._id);
                            localStorage?.setItem("mainContent", nav._id);
                            setmanpowerNavbarModal(true);
                            // setManPowerContent(true)
                          } else if (
                            nav?.title?.toLowerCase() === "other services"
                          ) {
                            GetMyTsksLevel1("active", nav._id);
                            localStorage?.setItem("mainContent", nav._id);
                            setotherserviceNavbarModal(true);
                          } else {
                            GetMyTsksLevel1("active", nav._id);
                            localStorage?.setItem("mainContent", nav._id);
                            setotherserviceNavbarModal1(true);
                          }
                        }}
                      >
                        {" "}
                        {nav?.title}
                       
                      </a>

                    </li>
                  ))} */}
                  {getMyTaskMain?.map((nav, i) => (
                    <li
                      class="nav-item dropdown dropdown-mega position-static"
                      key={i}
                    >
                      <a
                        style={{ textTransform: "capitalize" }}
                        className={
                          (window.location.href.includes("Audit") &&
                            nav?.title?.toLowerCase() === "audit") |
                            (window.location.href.includes("Legal") &&
                              nav?.title?.toLowerCase() === "legal") |
                            (window.location.href.includes("OtherService") &&
                              nav?.title?.toLowerCase() === "other services") |
                            (window.location.href.includes("manpower") &&
                              nav?.title?.toLowerCase() === "man power")
                            ? "nav-link dropdown-toggle active"
                            : "nav-link dropdown-toggle"
                        }
                        href="#"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        onClick={() => {



                          if (nav?.title?.toLowerCase() === "audit") {

                            GetMyTsksLevel1("active", nav._id);
                            localStorage?.setItem("mainContent", nav._id);
                            setauditNavbarModal(true);
                          } else if (nav?.title?.toLowerCase() === "legal") {
                            GetMyTsksLevel1("active", nav._id);
                            localStorage?.setItem("mainContent", nav._id);
                            setlegalNavbarModal(true);
                          } else if (nav?.title?.toLowerCase() === "man power") {
                            GetMyTsksLevel1("active", nav._id);
                            localStorage?.setItem("mainContent", nav._id);
                            // setmanpowerNavbarModal(true);
                          } else if (
                            nav?.title?.toLowerCase() === "other services"
                          ) {

                            GetMyTsksLevel1("active", nav._id);
                            localStorage?.setItem("mainContent", nav._id);
                            setotherserviceNavbarModal(true);
                          } else {

                            GetMyTsksLevel1("active", nav._id);
                            localStorage?.setItem("mainContent", nav._id);
                            setotherserviceNavbarModal1(true);
                          }
                        }}
                      >
                        {" "}
                        {nav?.title}
                      </a>
                      <div class="dropdown-menu shadow">
                        <div class="mega-content px-4">
                          <div class="container-fluid">
                            <div class="row">
                              <div class="col-12 col-sm-4 col-md-3 col-lg-3 py-4">
                                <h5 className="fw-bold mb-3"> {nav?.title}</h5>
                                <div className="modal-drop-box-3">
                                  <div className="p-2">
                                    {manpowerNavbarModal ?
                                      <>
                                        {getMyTasklevel1?.map((item, index) => (
                                          <div
                                            className={`my-2 selected-dropdownbox ${isSelected === item._id
                                              ? "active"
                                              : ""
                                              }`}
                                            key={index}
                                            onClick={() => {
                                              localStorage.setItem(
                                                "category",
                                                item.mainServiceId.title
                                              );
                                              localStorage.setItem(
                                                "subcategory",
                                                item.title
                                              );
                                              localStorage.setItem(
                                                "title",
                                                " "
                                              );
                                              localStorage.setItem(
                                                "level2Id",
                                                item._id
                                              );

                                              navigate({
                                                pathname: "/Service",

                                                search: `tab=manpower&subtab=${item.title}&id=${item._id}`,
                                              });

                                              setmanpowerNavbarModal(false);
                                              window.location.reload();
                                            }}
                                          >
                                            <div className="dropdown-box-2">
                                              <img
                                                className="drop-box-img-2"
                                                src={item.icon}
                                                alt={item.icon}
                                              />
                                              <div>
                                                <p className="mb-0 fw-bold">
                                                  {item.title}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </>
                                      :
                                      <>{getMyTasklevel1?.map(
                                        (tabAudit, index) => (
                                          <div
                                            className={`my-2 selected-dropdownbox ${isSelected === tabAudit._id
                                              ? "active"
                                              : ""
                                              }`}
                                            key={index}
                                            onClick={() => {
                                              GetMyTsksLevel2(
                                                "active",
                                                localStorage?.getItem(
                                                  "mainContent"
                                                ),
                                                tabAudit._id
                                              );

                                              setSelectedAudit(
                                                tabAudit.title
                                              );

                                              setAuditContent(true);
                                              setIsSelected(tabAudit._id);
                                            }}
                                          >
                                            <div className="dropdown-box-2">
                                              <img
                                                className="drop-box-img-2"
                                                src={tabAudit.icon}
                                                alt={tabAudit.icon}
                                              />
                                              <div>
                                                <p className="mb-0 fw-bold">
                                                  {tabAudit.title}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                      </>
                                    }


                                    {/* {legalNavbarModal && (
                                      <>
                                        {getMyTasklevel1?.map(
                                          (tabLegal, index) => (
                                            <div
                                              className={`my-2 selected-dropdownbox ${isSelected === tabLegal._id
                                                  ? "active"
                                                  : ""
                                                }`}
                                              key={index}
                                              onClick={() => {
                                                GetMyTsksLevel2(
                                                  "active",
                                                  localStorage?.getItem(
                                                    "mainContent"
                                                  ),
                                                  tabLegal._id
                                                );

                                                setSelectedAudit(
                                                  tabLegal.title
                                                );
                                                setSelectedLegal(
                                                  tabLegal.title
                                                );
                                                setlegalNavbarModal(false);
                                                setDropdownContent(true);
                                                setIsSelected(tabLegal._id);
                                              }}
                                            >
                                              <div className="dropdown-box-2">
                                                <img
                                                  className="drop-box-img-2"
                                                  src={tabLegal.icon}
                                                  alt={tabLegal.icon}
                                                />
                                                <div>
                                                  <p className="mb-0 fw-bold">
                                                    {tabLegal.title}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </>
                                    )}
                                    {manpowerNavbarModal && (
                                      <>
                                        {getMyTasklevel1?.map((item, index) => (
                                          <div
                                            className={`my-2 selected-dropdownbox ${isSelected === item._id
                                                ? "active"
                                                : ""
                                              }`}
                                            key={index}
                                            onClick={() => {
                                              localStorage.setItem(
                                                "category",
                                                item.mainServiceId.title
                                              );
                                              localStorage.setItem(
                                                "subcategory",
                                                item.title
                                              );
                                              localStorage.setItem(
                                                "title",
                                                " "
                                              );
                                              localStorage.setItem(
                                                "level2Id",
                                                item._id
                                              );

                                              navigate({
                                                pathname: "/Service",

                                                search: `tab=manpower&subtab=${item.title}&id=${item._id}`,
                                              });

                                              setmanpowerNavbarModal(false);
                                              window.location.reload();
                                            }}
                                          >
                                            <div className="dropdown-box-2">
                                              <img
                                                className="drop-box-img-2"
                                                src={item.icon}
                                                alt={item.icon}
                                              />
                                              <div>
                                                <p className="mb-0 fw-bold">
                                                  {item.title}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </>
                                    )}
                                    {otherserviceNavbarModal && (
                                      <>
                                        {getMyTasklevel1?.map(
                                          (tabOS, index) => (
                                            <div
                                              className={`my-2 selected-dropdownbox ${isSelected === tabOS._id
                                                  ? "active"
                                                  : ""
                                                }`}
                                              key={index}
                                              onClick={() => {
                                                GetMyTsksLevel2(
                                                  "active",
                                                  localStorage?.getItem(
                                                    "mainContent"
                                                  ),
                                                  tabOS._id
                                                );

                                                setSelectedOS(tabOS.title);
                                                if (
                                                  tabOS.title === "Auctions"
                                                ) {
                                                  setotherserviceNavbarModal(
                                                    false
                                                  );
                                                  setAuctionContent(true);
                                                } else if (
                                                  tabOS.title ===
                                                  "Buying and Selling"
                                                ) {
                                                  setotherserviceNavbarModal(
                                                    false
                                                  );
                                                  setBuySellContent(true);
                                                } else {
                                                  setotherserviceNavbarModal(
                                                    false
                                                  );
                                                  setOSContent(true);
                                                }
                                                setIsSelected(tabOS._id);
                                              }}
                                            >
                                              <div className="dropdown-box-2">
                                                <img
                                                  className="drop-box-img-2"
                                                  src={tabOS.icon}
                                                  alt={tabOS.icon}
                                                />
                                                <div>
                                                  <p className="mb-0 fw-bold">
                                                    {tabOS.title}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </>
                                    )}
                                    {otherserviceNavbarModal1 && (
                                      <>
                                        {getMyTasklevel1?.map(
                                          (tabOS, index) => (
                                            <div
                                              className={`my-2 selected-dropdownbox ${isSelected === tabOS._id
                                                  ? "active"
                                                  : ""
                                                }`}
                                              key={index}
                                              onClick={() => {
                                                GetMyTsksLevel2(
                                                  "active",
                                                  localStorage?.getItem(
                                                    "mainContent"
                                                  ),
                                                  tabOS._id
                                                );

                                                setSelectedOS(tabOS.title);
                                                setotherserviceNavbarModal1(
                                                  false
                                                );
                                                setOSContent1(true);
                                                setIsSelected(tabOS._id);
                                              }}
                                            >
                                              <div className="dropdown-box-2">
                                                <img
                                                  className="drop-box-img-2"
                                                  src={tabOS.icon}
                                                  alt={tabOS.icon}
                                                />
                                                <div>
                                                  <p className="mb-0 fw-bold">
                                                    {tabOS.title}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </>
                                    )} */}
                                  </div>
                                </div>
                              </div>
                              <div class="col-12 col-sm-4 col-md-9 col-lg-9 py-4">
                                <div className="modal-drop-box-2">
                                  <div class="row">
                                    {auditContent && (
                                      <>
                                        {getMyTasklevel2?.map((item, index) => (
                                          <div
                                            className="col-lg-3 col-md-3 col-6 my-2"
                                            key={index}
                                          >
                                            <div
                                              className="dropdown-box"
                                              onClick={() => {
                                                let route =
                                                  item.mainServiceId.title
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                  item.mainServiceId.title.slice(
                                                    1
                                                  );
                                                localStorage.setItem(
                                                  "category",
                                                  item.mainServiceId.title
                                                );
                                                localStorage.setItem(
                                                  "subcategory",
                                                  item.subServiceL1Id.title
                                                );
                                                localStorage.setItem(
                                                  "title",
                                                  item.title
                                                );

                                                localStorage.setItem(
                                                  "level2Id",
                                                  item._id
                                                );

                                                navigate({
                                                  pathname: "/Service",
                                                  search: `tab=${item.mainServiceId.title}&subtab=${item.subServiceL1Id.title} | ${item.title}&id=${item._id}`,
                                                });
                                                // navigate(`/Service`);
                                                setAuditContent(false);
                                                window.location.reload();
                                              }}
                                            >
                                              {/* <img
                                                className="drop-box-img mb-3"
                                                // src={item.icon}
                                                src={item.icon.replace(
                                                  "https://dalmaf-production.s3.amazonaws.com/",
                                                  "https://dxwew9m8iv6fp.cloudfront.net/"
                                                )}
                                                alt={item.title}
                                              /> */}
                                              <div>
                                                <p className="mb-0 fw-bold">
                                                  {item.title}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </>
                                    )}
                                    {/* {dropdownContent && (
                                      <>
                                        {getMyTasklevel2?.map((item, index) => (
                                          <div
                                            className="col-lg-3 col-md-3 col-6 my-2"
                                            key={index}
                                          >
                                            <div
                                              className="dropdown-box"
                                              onClick={() => {
                                                let route =
                                                  item.mainServiceId.title
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                  item.mainServiceId.title.slice(
                                                    1
                                                  );
                                                localStorage.setItem(
                                                  "category",
                                                  item.mainServiceId.title
                                                );
                                                localStorage.setItem(
                                                  "subcategory",
                                                  item.subServiceL1Id.title
                                                );
                                                localStorage.setItem(
                                                  "title",
                                                  item.title
                                                );

                                                localStorage.setItem(
                                                  "level2Id",
                                                  item._id
                                                );
                                                
                                                navigate({
                                                  pathname: "/Service",
                                                  search: `tab=${item.mainServiceId.title}&subtab=${item.subServiceL1Id.title} | ${item.title}&id=${item._id}`,
                                                });
                                               
                                                setDropdownContent(false);
                                                window.location.reload();
                                              }}
                                            >
                                              <img
                                                className="drop-box-img mb-3"
                                                
                                                src={item.icon.replace(
                                                  "https://dalmaf-production.s3.amazonaws.com/",
                                                  "https://dxwew9m8iv6fp.cloudfront.net/"
                                                )}
                                                alt={item.title}
                                              />
                                              <div>
                                                <p className="mb-0 fw-bold">
                                                  {item.title}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </>
                                    )} */}
                                    {auctionContent && (
                                      <>
                                        {data?.map((item, index) => (
                                          <div
                                            className="col-lg-3 col-md-3 col-6 my-2"
                                            key={index}
                                          >
                                            <div
                                              className="dropdown-box"
                                              onClick={() => {
                                                if (
                                                  item?.name === "Apartment"
                                                ) {
                                                  navigate(
                                                    `/OtherService&tab=Apartment`
                                                  );
                                                } else if (
                                                  item?.name ===
                                                  "Independent Villa"
                                                ) {
                                                  navigate(
                                                    `/OtherService&tab=Villa`
                                                  );
                                                } else if (
                                                  item?.name ===
                                                  "Individual House"
                                                ) {
                                                  navigate(
                                                    `/OtherService&tab=IndividualHouse`
                                                  );
                                                } else if (
                                                  item?.name === "Plot"
                                                ) {
                                                  navigate(
                                                    `/OtherService&tab=BSPlot`
                                                  );
                                                } else if (
                                                  item?.name === "Gold"
                                                ) {
                                                  navigate(
                                                    `/OtherService&tab=Gold`
                                                  );
                                                } else if (
                                                  item?.name === "Two Wheeler"
                                                ) {
                                                  navigate(
                                                    `/OtherService&tab=TwoWheeler`
                                                  );
                                                } else if (
                                                  item?.name === "Four Wheeler"
                                                ) {
                                                  navigate(
                                                    `/OtherService&tab=FourWheeler`
                                                  );
                                                }
                                                setAuctionContent(false);
                                              }}
                                            >
                                              <img
                                                className="drop-box-img mb-3"
                                                src={item.icon.replace(
                                                  "https://dalmaf-production.s3.amazonaws.com/",
                                                  "https://dxwew9m8iv6fp.cloudfront.net/"
                                                )}
                                                alt={item.title}
                                              />
                                              <div>
                                                <p className="mb-0 fw-bold">
                                                  {item.title}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </>
                                    )}
                                    {BuySellContent && (
                                      <>
                                        {data1?.map((item, index) => (
                                          <div
                                            className="col-lg-3 col-md-3 col-6 my-2"
                                            key={index}
                                          >
                                            <div
                                              className="dropdown-box"
                                              onClick={() => {
                                                if (
                                                  item?.name === "Apartment"
                                                ) {
                                                  navigate(
                                                    `/OtherService&tab=BSApartment`
                                                  );
                                                } else if (
                                                  item?.name ===
                                                  "Independent Villa"
                                                ) {
                                                  navigate(
                                                    `/OtherService&tab=BSVilla`
                                                  );
                                                } else if (
                                                  item?.name ===
                                                  "Individual House"
                                                ) {
                                                  navigate(
                                                    `/OtherService&tab=BSIndividualHouse`
                                                  );
                                                } else if (
                                                  item?.name === "Plot"
                                                ) {
                                                  navigate(
                                                    `/OtherService&tab=BSPlot`
                                                  );
                                                } else if (
                                                  item?.name === "Gold"
                                                ) {
                                                  navigate(
                                                    `/OtherService&tab=BSGold`
                                                  );
                                                } else if (
                                                  item?.name === "Two Wheeler"
                                                ) {
                                                  navigate(
                                                    `/OtherService&tab=BSTwoWheeler`
                                                  );
                                                } else if (
                                                  item?.name === "Four Wheeler"
                                                ) {
                                                  navigate(
                                                    `/OtherService&tab=BSFourWheeler`
                                                  );
                                                }

                                                setBuySellContent(false);
                                              }}
                                            >
                                              <img
                                                className="drop-box-img mb-3"
                                                src={item.icon.replace(
                                                  "https://dalmaf-production.s3.amazonaws.com/",
                                                  "https://dxwew9m8iv6fp.cloudfront.net/"
                                                )}
                                                alt={item.title}
                                              />
                                              <div>
                                                <p className="mb-0 fw-bold">
                                                  {item.title}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </>
                                    )}
                                    {OSContent && (
                                      <>
                                        {getMyTasklevel2?.map((item, index) => (
                                          <div
                                            className="col-lg-3 col-md-3 col-6 my-2"
                                            key={index}
                                          >
                                            <div
                                              className="dropdown-box"
                                              onClick={() => {
                                                let route =
                                                  item.mainServiceId.title
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                  item.mainServiceId.title.slice(
                                                    1
                                                  );
                                                localStorage.setItem(
                                                  "category",
                                                  item.mainServiceId.title
                                                );
                                                localStorage.setItem(
                                                  "subcategory",
                                                  item.subServiceL1Id.title
                                                );
                                                localStorage.setItem(
                                                  "title",
                                                  item.title
                                                );
                                                localStorage.setItem(
                                                  "level2Id",
                                                  item._id
                                                );
                                                navigate({
                                                  pathname: "/Service",

                                                  search: `tab=${item.mainServiceId.title}&subtab=${item.subServiceL1Id.title} | ${item.title}&id=${item._id}`,
                                                });
                                                setOSContent(false);
                                                window.location.reload();
                                              }}
                                            >
                                              <img
                                                className="drop-box-img mb-3"
                                                // src={item.icon}
                                                src={item.icon.replace(
                                                  "https://dalmaf-production.s3.amazonaws.com/",
                                                  "https://dxwew9m8iv6fp.cloudfront.net/"
                                                )}
                                                alt={item.title}
                                              />
                                              <div>
                                                <p className="mb-0 fw-bold">
                                                  {item.title}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </>
                                    )}
                                    {OSContent1 && (
                                      <>
                                        {getMyTasklevel2?.map((item, index) => (
                                          <div
                                            className="col-lg-3 col-md-3 col-6 my-2"
                                            key={index}
                                          >
                                            <div
                                              className="dropdown-box"
                                              onClick={() => {
                                                let route =
                                                  item.mainServiceId.title
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                  item.mainServiceId.title.slice(
                                                    1
                                                  );
                                                localStorage.setItem(
                                                  "category",
                                                  item.mainServiceId.title
                                                );
                                                localStorage.setItem(
                                                  "subcategory",
                                                  item.subServiceL1Id.title
                                                );
                                                localStorage.setItem(
                                                  "title",
                                                  item.title
                                                );
                                                localStorage.setItem(
                                                  "level2Id",
                                                  item._id
                                                );

                                                navigate({
                                                  pathname: "/Service",
                                                  // search: `tab=${item.mainServiceId.title}&subtab=${item.subServiceL1Id.title}&${item.title}`,
                                                  search: `tab=${item.mainServiceId.title}&subtab=${item.subServiceL1Id.title} | ${item.title}&id=${item._id}`,
                                                });
                                                setOSContent1(false);
                                                window.location.reload();
                                              }}
                                            >
                                              <img
                                                className="drop-box-img mb-3"
                                                // src={item.icon}
                                                src={item.icon.replace(
                                                  "https://dalmaf-production.s3.amazonaws.com/",
                                                  "https://dxwew9m8iv6fp.cloudfront.net/"
                                                )}
                                                alt={item.title}
                                              />
                                              <div>
                                                <p className="mb-0 fw-bold">
                                                  {item.title}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                  <li className="nav-item">
                    <NavLink
                      className="nav-link banner-heading"
                      aria-current="page"
                      to="/partner-with-dalmaf"
                    >
                      Partner With Us
                    </NavLink>
                  </li>

                  {/* <li className="nav-item">
                    <NavLink
                      className="nav-link "
                      aria-current="page"
                      to="/Career"
                    >
                      Career
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link "
                      aria-current="page"
                      to="/Contact"
                    >
                      Contact US
                    </NavLink>
                  </li> */}
                  <li className="nav-item ">
                    {state?.auth?.isLoggedIn === true ? (
                      <>
                        <span
                          className="nav-link"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigate("/Dashboard");
                          }}
                        >
                          {" "}
                          <span className="material-symbols-rounded align-middle icon-bg">
                            person{" "}
                          </span>
                          {state?.auth?.auth?.name}
                        </span>
                      </>
                    ) : (
                      <button
                        type="button"
                        className="btn login-btn-home"
                        onClick={() => {
                          setloginModal(true);
                        }}
                      >
                        LOG IN
                      </button>
                    )}
                  </li>
                  <li className="nav-item ">
                    {state?.auth?.isLoggedIn === true ? (
                      ""
                    ) : (
                      <button
                        type="button"
                        className="btn login-btn-home ms-2"
                        onClick={() => {
                          setcreateModal(true);
                        }}
                      >
                        SIGN UP
                      </button>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>

      {/* <CustomModal
        open={auditContent}
        onClickOutside={() => {
          setAuditContent(false);
          
          setGetMyTasklevel2([]);
        }}
      >
        <div className="modal-content  m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className="row justify-content-center ">
                        <div className="col-lg-4  d-none d-lg-block  px-0">
                          <div className="">
                            <img
                              className="w-100 h-auto modal-left-img"
                              src={
                                selectedAudit === "Startup"
                                  ? DropImgModal1
                                  : selectedAudit === "Trademark"
                                  ? DropImgModal2
                                  : selectedAudit === "GST"
                                  ? DropImgModal3
                                  : selectedAudit === "IncomeTax"
                                  ? DropImgModal4
                                  : DropImgModal5
                              }
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-12">
                          <div
                            className="pt-3 pb-2 pe-5 d-flex justify-content-end align-items-center"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setGetMyTasklevel2([]);
                              setauditNavbarModal(true);
                              setAuditContent(false);
                            }}
                          >
                            <span className="material-symbols-rounded align-middle color-maroon">
                              arrow_back
                            </span>
                            <h6
                              type="button"
                              className="color-maroon fw-bold mb-0"
                            >
                              Back
                            </h6>
                          </div>
                          <div className="modal-drop-box pt-0 nav-modal-overflow">
                            <div className="row">
                              {getMyTasklevel2?.map((item, index) => (
                                <div
                                  className="col-lg-4 col-md-4 col-6 my-2"
                                  key={index}
                                >
                                  <div
                                    className="dropdown-box"
                                    onClick={() => {
                                      let route =
                                        item.mainServiceId.title
                                          .charAt(0)
                                          .toUpperCase() +
                                        item.mainServiceId.title.slice(1);
                                      localStorage.setItem(
                                        "category",
                                        item.mainServiceId.title
                                      );
                                      localStorage.setItem(
                                        "subcategory",
                                        item.subServiceL1Id.title
                                      );
                                      localStorage.setItem("title", item.title);

                                      localStorage.setItem(
                                        "level2Id",
                                        item._id
                                      );
                                      
                                      navigate({
                                        pathname: "/Service",
                                        search: `tab=${item.mainServiceId.title}&subtab=${item.subServiceL1Id.title} | ${item.title}&id=${item._id}`,
                                      });
                                    
                                      setAuditContent(false);
                                      window.location.reload();
                                    }}
                                  >
                                    <img
                                      className="drop-box-img mb-3"
                                     
                                      src={item.icon.replace(
                                        "https://dalmaf-production.s3.amazonaws.com/",
                                        "https://dxwew9m8iv6fp.cloudfront.net/"
                                      )}
                                      alt={item.title}
                                    />
                                    <div>
                                      <p className="mb-0 fw-bold">
                                        {item.title}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal> */}
      {/* <CustomModal
        open={OSContent}
        onClickOutside={() => {
          setOSContent(false);
          
          setGetMyTasklevel2([]);
        }}
      >
        <div className="modal-content  m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className="row justify-content-center ">
                        <div className="col-lg-4  d-none d-lg-block  px-0">
                          <div className="">
                            <img
                              className="w-100 h-auto modal-left-img"
                              src={
                                selectedOS === "Marketing"
                                  ? DMLeft
                                  : selectedOS === "Auction"
                                  ? AuctionNav
                                  : selectedOS === "Buy/Sell"
                                  ? BuysellNav
                                  : BuysellNav
                              }
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-12">
                          <div
                            className="pt-3 pb-2 pe-5 d-flex justify-content-end align-items-center"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setGetMyTasklevel2([]);
                              setOSContent(false);
                              setotherserviceNavbarModal(true);
                            }}
                          >
                            <span className="material-symbols-rounded align-middle color-maroon">
                              arrow_back
                            </span>
                            <h6
                              type="button"
                              className="color-maroon fw-bold mb-0"
                            >
                              Back
                            </h6>
                          </div>
                          <div className="modal-drop-box nav-modal-overflow">
                            <div className="row">
                              {getMyTasklevel2?.map((item, index) => (
                                <div
                                  className="col-lg-4 col-md-4 col-6 my-2"
                                  key={index}
                                >
                                  <div
                                    className="dropdown-box"
                                    onClick={() => {
                                      let route =
                                        item.mainServiceId.title
                                          .charAt(0)
                                          .toUpperCase() +
                                        item.mainServiceId.title.slice(1);
                                      localStorage.setItem(
                                        "category",
                                        item.mainServiceId.title
                                      );
                                      localStorage.setItem(
                                        "subcategory",
                                        item.subServiceL1Id.title
                                      );
                                      localStorage.setItem("title", item.title);
                                      localStorage.setItem(
                                        "level2Id",
                                        item._id
                                      );
                                      navigate({
                                        pathname: "/Service",
                                       
                                        search: `tab=${item.mainServiceId.title}&subtab=${item.subServiceL1Id.title} | ${item.title}&id=${item._id}`,
                                      });
                                      setOSContent(false);
                                      window.location.reload();
                                    }}
                                  >
                                    <img
                                      className="drop-box-img mb-3"
                                    
                                      src={item.icon.replace(
                                        "https://dalmaf-production.s3.amazonaws.com/",
                                        "https://dxwew9m8iv6fp.cloudfront.net/"
                                      )}
                                      alt={item.title}
                                    />
                                    <div>
                                      <p className="mb-0 fw-bold">
                                        {item.title}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal> */}
      {/* <CustomModal
        open={OSContent1}
        onClickOutside={() => {
          
          setGetMyTasklevel2([]);
          setOSContent1(false);
        }}
      >
        <div className="modal-content  m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className="row justify-content-center ">
                        <div className="col-lg-4  d-none d-lg-block  px-0">
                          <div className="">
                            <img
                              className="w-100 h-auto modal-left-img"
                              src={
                                selectedOS === "Marketing"
                                  ? DMLeft
                                  : selectedOS === "Auction"
                                  ? AuctionNav
                                  : selectedOS === "Buy/Sell"
                                  ? BuysellNav
                                  : BuysellNav
                              }
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-12">
                          <div
                            className="pt-3 pb-2 pe-5 d-flex justify-content-end align-items-center"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setGetMyTasklevel2([]);
                              setOSContent1(false);
                              setotherserviceNavbarModal1(true);
                            }}
                          >
                            <span className="material-symbols-rounded align-middle color-maroon">
                              arrow_back
                            </span>
                            <h6
                              type="button"
                              className="color-maroon fw-bold mb-0"
                            >
                              Back
                            </h6>
                          </div>
                          <div className="modal-drop-box nav-modal-overflow">
                            <div className="row">
                              {getMyTasklevel2?.map((item, index) => (
                                <div
                                  className="col-lg-4 col-md-4 col-6 my-2"
                                  key={index}
                                >
                                  <div
                                    className="dropdown-box"
                                    onClick={() => {
                                      let route =
                                        item.mainServiceId.title
                                          .charAt(0)
                                          .toUpperCase() +
                                        item.mainServiceId.title.slice(1);
                                      localStorage.setItem(
                                        "category",
                                        item.mainServiceId.title
                                      );

                                      localStorage.setItem(
                                        "subcategory",
                                        item.subServiceL1Id.title
                                      );

                                      localStorage.setItem("title", item.title);

                                      localStorage.setItem(
                                        "level2Id",
                                        item._id
                                      );
                                      
                                      navigate({
                                        pathname: "/Service",
                                       
                                        search: `tab=${item.mainServiceId.title}&subtab=${item.subServiceL1Id.title} | ${item.title}&id=${item._id}`,
                                      });
                                      setOSContent1(false);
                                      window.location.reload();
                                    }}
                                  >
                                    <img
                                      className="drop-box-img mb-3"
                                      src={item.icon.replace(
                                        "https://dalmaf-production.s3.amazonaws.com/",
                                        "https://dxwew9m8iv6fp.cloudfront.net/"
                                      )}
                                      alt={item.title}
                                    />
                                    <div>
                                      <p className="mb-0 fw-bold">
                                        {item.title}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal> */}
      {/* <CustomModal
        open={dropdownContent}
        onClickOutside={() => {
          
          setGetMyTasklevel2([]);
          setDropdownContent(false);
        }}
      >
        <div className="modal-content  m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className="row justify-content-center ">
                        <div className="col-lg-4 col-md-4 d-none d-lg-block  px-0">
                          <div className="">
                            <img className="w-100 h-auto" src={NavFam} alt="" />
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-8">
                          <div
                            className="pt-3 pb-2 pe-5 d-flex justify-content-end align-items-center"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setGetMyTasklevel2([]);
                              setDropdownContent(false);
                              setlegalNavbarModal(true);
                            }}
                          >
                            <span className="material-symbols-rounded align-middle color-maroon">
                              arrow_back
                            </span>
                            <h6
                              type="button"
                              className="color-maroon fw-bold mb-0"
                            >
                              Back
                            </h6>
                          </div>
                          <div className="modal-drop-box pt-0">
                            <div className="row">
                              {getMyTasklevel2?.map((item, index) => (
                                <div
                                  className="col-lg-4 col-md-6 col-6 my-2"
                                  key={index}
                                >
                                  <div
                                    className="dropdown-box"
                                    onClick={() => {
                                      let route =
                                        item.mainServiceId.title
                                          .charAt(0)
                                          .toUpperCase() +
                                        item.mainServiceId.title.slice(1);
                                      localStorage.setItem(
                                        "category",
                                        item.mainServiceId.title
                                      );
                                      localStorage.setItem(
                                        "subcategory",
                                        item.subServiceL1Id.title
                                      );
                                      localStorage.setItem("title", item.title);
                                      localStorage.setItem(
                                        "level2Id",
                                        item._id
                                      );
                                      
                                      navigate({
                                        pathname: "/Service",
                                      
                                        search: `tab=${item.mainServiceId.title}&subtab=${item.subServiceL1Id.title} | ${item.title}&id=${item._id}`,
                                      });
                                      setDropdownContent(false);
                                      window.location.reload();
                                   
                                    }}
                                  >
                                    <img
                                      className="drop-box-img mb-3"
                                     
                                      src={item.icon.replace(
                                        "https://dalmaf-production.s3.amazonaws.com/",
                                        "https://dxwew9m8iv6fp.cloudfront.net/"
                                      )}
                                      alt={item.title}
                                    />
                                    <div>
                                      <p className="mb-0 fw-bold">
                                        {item.title}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal> */}
      <CustomModal
        open={dropdownContent2}
        onClickOutside={() => {

          setGetMyTasklevel2([]);
          setDropdownContent2(false);
        }}
      >
        <div className="modal-content  m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className="row justify-content-center ">
                        <div className="col-lg-4 col-md-4 d-none d-lg-block  px-0">
                          <div className="">
                            <img className="w-100 h-auto" src={NavFam} alt="" />
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-8">
                          <div className="modal-drop-box">
                            <div className="row">
                              {DataLegal2.filter(
                                (item) => item.type === selectedLegal2
                              ).map((item, index) => {
                                return (
                                  <div
                                    className="col-lg-4 col-md-6 col-6 my-2"
                                    key={index}
                                  >
                                    <div
                                      className="dropdown-box"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "category",
                                          item.route
                                        );
                                        localStorage.setItem(
                                          "subcategory",
                                          item.type
                                        );
                                        localStorage.setItem(
                                          "title",
                                          item.name
                                        );
                                        navigate(item.route);
                                      }}
                                    >
                                      <img
                                        className="drop-box-img mb-3"
                                        // src={item.icon}
                                        src={item.icon.replace(
                                          "https://dalmaf-production.s3.amazonaws.com/",
                                          "https://dxwew9m8iv6fp.cloudfront.net/"
                                        )}
                                        alt={item.title}
                                      />
                                      <div>
                                        <p className="mb-0 fw-bold">
                                          {item.name}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <ToastContainer />
      {/* Login */}
      <CustomModal
        open={loginModal}
        onClickOutside={() => {
          setloginModal(false);
          setRegisterValidation1({});
          setcreateModal(false);
          setfpwModal(false);
          setotpModal(false);
          setchangepswModal(false);
        }}
      >
        <div className="modal-content w-50 m-auto">
          <div className="modal-body p-0 ">
            <div className="container ">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{
                  marginRight: "30px",
                  marginTop: "50px",
                  textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                }}
                onClick={() => {
                  setloginModal(false);
                }}
              >
                close
              </span>
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className=" my-5 px-3">
                        <div className="row justify-content-center mt-2">
                          <div className="col-lg-10">
                            <div className="mb-5">
                              <h4 className="fw-bold mt-2">Login</h4>
                            </div>
                            <div className="mb-3">
                              <label for="" className="form-label">
                                Email
                              </label>

                              <input
                                type="email"
                                className="form-control"
                                placeholder="example@mail.com"
                                required
                                aria-invalid={Boolean(
                                  registerValidation1?.email?.status === false
                                )}
                                defaultValue={
                                  registerDetail1?.email?.toLowerCase() || ""
                                }
                                onChange={(e) => {
                                  setRegisterDetailsValue1(
                                    "email",
                                    e.target.value?.toLowerCase()
                                  );
                                }}
                                onBlur={(e) => {
                                  setRegisterValidationValue1(
                                    "email",
                                    EmailValidation(
                                      e.target.value?.toLowerCase()
                                    )
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {registerValidation1?.email?.message
                                  ? `Email ${registerValidation1?.email?.message}`
                                  : ""}
                              </small>
                            </div>
                            <div className="mb-3 ">
                              <div className="psw-icon-section">
                                <label for="" className="form-label">
                                  Password
                                </label>
                                <input
                                  type={password ? "text" : "password"}
                                  className="form-control"
                                  required
                                  placeholder="******"
                                  aria-invalid={Boolean(
                                    registerValidation1?.password?.status ===
                                    false
                                  )}
                                  defaultValue={registerDetail1?.password || ""}
                                  onChange={(e) => {
                                    setRegisterDetailsValue1(
                                      "password",
                                      e.target.value
                                    );
                                  }}
                                  onBlur={(e) => {
                                    setRegisterValidationValue1(
                                      "password",
                                      PasswordValidation(e.target.value)
                                    );
                                  }}
                                  autoComplete="off"
                                />
                                <div className="psw-icon">
                                  {password ? (
                                    <span
                                      className="material-symbols-rounded cursor align-middle"
                                      onClick={() => viewPassword()}
                                    >
                                      visibility
                                    </span>
                                  ) : (
                                    <span
                                      className="material-symbols-rounded cursor align-middle"
                                      onClick={() => viewPassword()}
                                    >
                                      visibility_off
                                    </span>
                                  )}
                                </div>
                              </div>

                              <small className="text-danger">
                                {registerValidation1?.password?.message
                                  ? `Password ${registerValidation1?.password?.message}`
                                  : ""}
                              </small>
                            </div>
                            <div className="mb-3">
                              <button
                                type="button"
                                className="btn  w-100 my-2  login-btn"
                                onClick={(e) => handleSubmit1(e)}
                              >
                                Login
                              </button>
                            </div>
                            <div className="mb-3 ">
                              <p className="text-center mb-1">
                                <span className="text-secondary">
                                  Don’t have account? ?{" "}
                                </span>
                                <span
                                  className="mx-2 cursor text-decoration-underline"
                                  onClick={() => {
                                    setloginModal(false);
                                    setcreateModal(true);
                                    setRegisterDetails1({});
                                    setRegisterValidation1({});
                                  }}
                                >
                                  Sign Up
                                </span>{" "}
                              </p>
                              <p className="text-center">
                                <span className="text-secondary">
                                  Forgot your password?
                                </span>
                                <span
                                  className="mx-2 cursor text-decoration-underline"
                                  onClick={() => {
                                    setloginModal(false);
                                    setfpwModal(true);
                                    setRegisterDetails1({});
                                    setRegisterValidation1({});
                                  }}
                                >
                                  Reset
                                </span>{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      {/* Signup */}
      <CustomModal
        open={createModal}
        onClickOutside={() => {
          setcreateModal(false);
          setRegisterValidation({});
          setfpwModal(false);
          setotpModal(false);
          setloginModal(false);
          setchangepswModal(false);
        }}
      >
        <div className="modal-content w-50 h-50  m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{
                  marginRight: "30px",
                  marginTop: "50px",
                  textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                }}
                onClick={() => {
                  setcreateModal(false);
                }}
              >
                close
              </span>
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className=" my-5 px-3">
                        <div className="row justify-content-center mt-2">
                          <div className="col-lg-10 reg-page-scroll">
                            <div className="mb-5">
                              <h4 className="fw-bold mt-2">
                                Create an account
                              </h4>
                            </div>
                            <div className="mb-3">
                              <label for="" className="form-label">
                                Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Name"
                                required
                                aria-invalid={Boolean(
                                  registerValidation?.name?.status === false
                                )}
                                defaultValue={registerDetail?.name || ""}
                                onChange={(e) => {
                                  setRegisterDetailsValue(
                                    "name",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setRegisterValidationValue(
                                    "name",
                                    StringValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {registerValidation?.name?.message
                                  ? `Name ${registerValidation?.name?.message}`
                                  : ""}
                              </small>
                            </div>
                            <div className="mb-3">
                              <label for="" className="form-label">
                                Email
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                placeholder="example@mail.com"
                                required
                                aria-invalid={Boolean(
                                  registerValidation?.email?.status === false
                                )}
                                defaultValue={
                                  registerDetail?.email?.toLowerCase() || ""
                                }
                                onChange={(e) => {
                                  setRegisterDetailsValue(
                                    "email",
                                    e.target.value?.toLowerCase()
                                  );
                                }}
                                onBlur={(e) => {
                                  setRegisterValidationValue(
                                    "email",
                                    EmailValidation(
                                      e.target.value?.toLowerCase()
                                    )
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {registerValidation?.email?.message
                                  ? `Email ${registerValidation?.email?.message}`
                                  : ""}
                              </small>
                            </div>
                            <div className="mb-3">
                              <label for="" className="form-label">
                                Phone No
                              </label>
                              <input
                                type="tel"
                                className="form-control"
                                maxLength={10}
                                placeholder="Phone Number"
                                required
                                aria-invalid={Boolean(
                                  registerValidation?.phoneNumber?.status ===
                                  false
                                )}
                                defaultValue={registerDetail?.phoneNumber || ""}
                                onChange={(e) => {
                                  setRegisterDetailsValue(
                                    "phoneNumber",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setRegisterValidationValue(
                                    "phoneNumber",
                                    PhoneNumberValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {registerValidation?.phoneNumber?.message
                                  ? `Phone no ${registerValidation?.phoneNumber?.message}`
                                  : ""}
                              </small>
                            </div>
                            <div className="mb-3 ">
                              <div className="psw-icon-section">
                                <label for="" className="form-label">
                                  Password
                                </label>
                                <input
                                  type={password ? "text" : "password"}
                                  className="form-control"
                                  required
                                  placeholder="*********"
                                  aria-invalid={Boolean(
                                    registerValidation?.password?.status ===
                                    false
                                  )}
                                  defaultValue={registerDetail?.password || ""}
                                  onChange={(e) => {
                                    setRegisterDetailsValue(
                                      "password",
                                      e.target.value
                                    );
                                    setPasswordStrength(e.target.value);
                                  }}
                                  onBlur={(e) => {
                                    setRegisterValidationValue(
                                      "password",
                                      PasswordValidation(e.target.value)
                                    );
                                  }}
                                  autoComplete="off"
                                />
                                <div className="psw-icon">
                                  {password ? (
                                    <span
                                      className="material-symbols-rounded cursor align-middle"
                                      onClick={() => viewPassword()}
                                    >
                                      visibility
                                    </span>
                                  ) : (
                                    <span
                                      className="material-symbols-rounded cursor align-middle"
                                      onClick={() => viewPassword()}
                                    >
                                      visibility_off
                                    </span>
                                  )}
                                </div>
                              </div>

                              <small className="text-danger">
                                {registerValidation?.password?.message
                                  ? `Password ${registerValidation?.password?.message}`
                                  : ""}
                              </small>
                            </div>
                            {passwordStrength ? (
                              <div className="mt-3">
                                <b>Create a password with the following</b>
                                <br />
                                {
                                  <p
                                    className={`${passwordStrength.trim().length < 8
                                      ? "text-danger"
                                      : "text-success"
                                      } d-flex`}
                                  >
                                    {passwordStrength.trim().length < 8 ? (
                                      <span className="material-symbols-rounded filled-icon close-icon">
                                        close
                                      </span>
                                    ) : (
                                      <span className="material-symbols-rounded filled-icon close-icon">
                                        check
                                      </span>
                                    )}
                                    At least 8 Characters
                                  </p>
                                }
                                {
                                  <p
                                    className={`${!/^(?=.*[A-Z])(?=.*[a-z]).*$/.test(
                                      passwordStrength
                                    )
                                      ? "text-danger"
                                      : "text-success"
                                      } d-flex`}
                                  >
                                    {!/^(?=.*[A-Z])(?=.*[a-z]).*$/.test(
                                      passwordStrength
                                    ) ? (
                                      <span className="material-symbols-rounded filled-icon close-icon">
                                        close
                                      </span>
                                    ) : (
                                      <span className="material-symbols-rounded filled-icon close-icon">
                                        check
                                      </span>
                                    )}
                                    An Uppercase &amp; Lowercase character
                                  </p>
                                }
                                {
                                  <p
                                    className={`${!/^(?=.*[.,:;\'!@#$%^&*_+=|(){}[?\-\]\/\\]).*$/.test(
                                      passwordStrength
                                    )
                                      ? "text-danger"
                                      : "text-success"
                                      } d-flex`}
                                  >
                                    {!/^(?=.*[.,:;\'!@#$%^&*_+=|(){}[?\-\]\/\\]).*$/.test(
                                      passwordStrength
                                    ) ? (
                                      <span className="material-symbols-rounded filled-icon close-icon">
                                        close
                                      </span>
                                    ) : (
                                      <span className="material-symbols-rounded filled-icon close-icon">
                                        check
                                      </span>
                                    )}
                                    A special character
                                  </p>
                                }
                                {
                                  <p
                                    className={`${!/[0-9]/.test(passwordStrength)
                                      ? "text-danger"
                                      : "text-success"
                                      } d-flex`}
                                  >
                                    {!/[0-9]/.test(passwordStrength) ? (
                                      <span className="material-symbols-rounded filled-icon close-icon">
                                        close
                                      </span>
                                    ) : (
                                      <span className="material-symbols-rounded filled-icon close-icon">
                                        check
                                      </span>
                                    )}
                                    A number
                                  </p>
                                }
                              </div>
                            ) : null}
                            <div className="mb-3">
                              <input
                                // id="email"
                                type="checkbox"
                                // className="form-control"
                                required
                                aria-invalid={Boolean(
                                  registerValidation?.acceptNewsLetter
                                    ?.status === false
                                )}
                                defaultValue={registerDetail?.acceptNewsLetter}
                                onChange={(e) => {
                                  setRegisterDetailsValue(
                                    "acceptNewsLetter",
                                    e.target.checked
                                  );
                                }}
                                onBlur={(e) => {
                                  setRegisterValidationValue(
                                    "acceptNewsLetter",
                                    NonEmptyKeyValidation(e.target.checked)
                                  );
                                }}
                                autoComplete="off"
                              />{" "}
                              <label for="" className="form-label">
                                {" "}
                                I would like to receive news letters
                              </label>
                              <small className="text-danger">
                                {registerValidation?.acceptNewsLetter?.message
                                  ? `Accept News Letter ${registerValidation?.acceptNewsLetter?.message}`
                                  : ""}
                              </small>
                            </div>
                            <div className="mb-3">
                              <button
                                // type="button"
                                className="btn  w-100 my-2  login-btn"
                                onClick={(e) => handleSubmit(e)}
                              >
                                Create account
                              </button>
                            </div>
                            <div className="mb-3 ">
                              <p className="text-center mb-1">
                                <span className="text-secondary">
                                  Already have an account ?
                                </span>
                                <span
                                  className="mx-2 cursor text-decoration-underline"
                                  onClick={() => {
                                    setcreateModal(false);
                                    setloginModal(true);
                                    setRegisterDetails({});
                                    setRegisterValidation({});
                                  }}
                                >
                                  Log in
                                </span>{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      {/* Forgot Password */}
      <CustomModal
        open={fpwModal}
        onClickOutside={() => {
          setfpwModal(false);
          setRegisterValidation2({});
          setcreateModal(false);
          setotpModal(false);
          setloginModal(false);
          setchangepswModal(false);
        }}
      >
        <div className="modal-content w-50 m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{
                  marginRight: "30px",
                  marginTop: "50px",
                  textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                }}
                onClick={() => {
                  setfpwModal(false);
                }}
              >
                close
              </span>
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className=" my-5 px-3">
                        <div className="row justify-content-center mt-2">
                          <div className="col-lg-10">
                            <div className="mb-5">
                              <h4 className="fw-bold mt-2">Forgot Password</h4>
                            </div>
                            <div className="mb-3">
                              <label for="email" className="form-label">
                                Email
                              </label>
                              <input
                                id="email"
                                type="email"
                                className="form-control"
                                placeholder="example@mail.com"
                                required
                                aria-invalid={Boolean(
                                  registerValidation2?.email?.status === false
                                )}
                                defaultValue={
                                  registerDetail2?.email?.toLowerCase() || ""
                                }
                                onChange={(e) => {
                                  setRegisterDetailsValue2(
                                    "email",
                                    e.target.value?.toLowerCase()
                                  );
                                }}
                                onBlur={(e) => {
                                  setRegisterValidationValue2(
                                    "email",
                                    EmailValidation(
                                      e.target.value?.toLowerCase()
                                    )
                                  );
                                }}
                              />
                              <small className="text-danger">
                                {registerValidation2?.email?.message
                                  ? `Email ${registerValidation2?.email?.message}`
                                  : ""}
                              </small>
                            </div>
                            <div className="mb-3">
                              <button
                                type="button"
                                className="btn  w-100 my-2  login-btn"
                                onClick={(e) => {
                                  handleSubmitForgotPassword(e);
                                }}
                              >
                                Submit
                              </button>
                            </div>
                            <div className="mb-3 ">
                              <p className="text-center mb-1">
                                <span className="text-secondary">
                                  Already have an account ?
                                </span>
                                <span
                                  className="mx-2 cursor text-decoration-underline"
                                  onClick={() => {
                                    setfpwModal(false);
                                    setloginModal(true);
                                    setRegisterDetails2({});
                                    setRegisterValidation2({});
                                  }}
                                >
                                  Log in
                                </span>{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      {/* Verify your email ID */}
      <CustomModal
        open={otpModal}
        onClickOutside={() => {
          setotpModal(false);
          setValidation({});
          setcreateModal(false);
          setfpwModal(false);
          setloginModal(false);
          setchangepswModal(false);
        }}
      >
        <div className="modal-content w-50 m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{
                  marginRight: "30px",
                  marginTop: "50px",
                  textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                }}
                onClick={() => {
                  setotpModal(false);
                }}
              >
                close
              </span>
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className=" my-5 px-3">
                        <div className="row justify-content-center mt-2">
                          <div className="col-lg-10">
                            <div className="mb-5">
                              <h4 className="fw-bold mt-3">
                                Verify your email ID
                              </h4>
                              <small>Enter the code we just sent to</small>
                            </div>
                            <div className="mb-4 psw-icon-section">
                              <OtpInput
                                className="otpInput"
                                value={code}
                                onChange={handleChange}
                                numInputs={6}
                                separator={
                                  <span style={{ width: "8px" }}></span>
                                }
                                isInputNum={true}
                                shouldAutoFocus={true}
                                inputStyle={{
                                  border: "1px solid #CFD3DB",
                                  borderRadius: "8px",
                                  width: "45px",
                                  height: "45px",
                                  fontSize: "12px",
                                  color: "#000",
                                  fontWeight: "400",
                                  caretColor: "blue",
                                }}
                                focusStyle={{
                                  border: "1px solid black ",
                                  outline: "none",
                                }}
                              />
                            </div>
                            <small className="text-danger">
                              {validation?.code?.message
                                ? `OTP ${validation?.code?.message}`
                                : ""}
                            </small>
                            {seconds > 0 || minutes > 0 ? (
                              <div className="top-12">
                                Resend OTP in&nbsp;
                                <span className="text-green">
                                  {minutes < 10 ? `0${minutes}` : minutes}:
                                  {seconds < 10 ? `0${seconds}` : seconds}
                                </span>
                              </div>
                            ) : (
                              <div className="top-12">
                                Didn't receive code?&nbsp;
                                <span
                                  className="fgtpwd"
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => handleSubmitResend(e)}
                                >
                                  Resend
                                </span>
                              </div>
                            )}

                            <div className="mb-3">
                              <button
                                type="button"
                                className="btn  w-100 my-2  login-btn"
                                onClick={(e) => {
                                  if (
                                    localStorage.getItem(
                                      "accountcreation" === true
                                    )
                                  ) {
                                    handleSubmitOtp(e, "accountcreation");
                                  } else {
                                    handleSubmitOtp(e, "forgot");
                                  }

                                  // setchangepswModal(true);
                                }}
                              >
                                Verify
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      {/* Verify your email ID */}
      <CustomModal
        open={otpFgtModal}
        onClickOutside={() => {
          setotpFgtModal(false);
          setValidation({});
          setcreateModal(false);
          setfpwModal(false);
          setloginModal(false);
          setchangepswModal(false);
        }}
      >
        <div className="modal-content w-50 m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{
                  marginRight: "30px",
                  marginTop: "50px",
                  textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                }}
                onClick={() => {
                  setotpFgtModal(false);
                }}
              >
                close
              </span>
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className=" my-5 px-3">
                        <div className="row justify-content-center mt-2">
                          <div className="col-lg-10">
                            <div className="mb-5">
                              <h4 className="fw-bold mt-3">
                                Verify your email ID
                              </h4>
                              <small>Enter the code we just sent to</small>
                            </div>
                            <div className="mb-4 psw-icon-section">
                              <OtpInput
                                className="otpInput"
                                value={code}
                                onChange={handleChange}
                                numInputs={6}
                                separator={
                                  <span style={{ width: "8px" }}></span>
                                }
                                isInputNum={true}
                                shouldAutoFocus={true}
                                inputStyle={{
                                  border: "1px solid #CFD3DB",
                                  borderRadius: "8px",
                                  width: "45px",
                                  height: "45px",
                                  fontSize: "12px",
                                  color: "#000",
                                  fontWeight: "400",
                                  caretColor: "blue",
                                }}
                                focusStyle={{
                                  border: "1px solid black ",
                                  outline: "none",
                                }}
                              />
                            </div>
                            <small className="text-danger">
                              {validation?.code?.message
                                ? `OTP ${validation?.code?.message}`
                                : ""}
                            </small>
                            {seconds > 0 || minutes > 0 ? (
                              <div className="top-12">
                                Resend OTP in&nbsp;
                                <span className="text-green">
                                  {minutes < 10 ? `0${minutes}` : minutes}:
                                  {seconds < 10 ? `0${seconds}` : seconds}
                                </span>
                              </div>
                            ) : (
                              <div className="top-12">
                                Didn't receive code?&nbsp;
                                <span
                                  className="fgtpwd"
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => handleSubmitResend1(e)}
                                >
                                  Resend
                                </span>
                              </div>
                            )}

                            <div className="mb-3">
                              <button
                                type="button"
                                className="btn  w-100 my-2  login-btn"
                                onClick={(e) => {
                                  handleSubmitOtpFgt(e);
                                }}
                              >
                                Verify
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      {/* Change Password */}
      <CustomModal
        open={changepswModal}
        onClickOutside={() => {
          setchangepswModal(false);
          setRegisterValidation3({});
          setcreateModal(false);
          setfpwModal(false);
          setotpModal(false);
          setloginModal(false);
        }}
      >
        <div className="modal-content w-50 m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{
                  marginRight: "30px",
                  marginTop: "50px",
                  textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                }}
                onClick={() => {
                  setchangepswModal(false);
                }}
              >
                close
              </span>
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className=" my-5 px-3">
                        <div className="row justify-content-center mt-2">
                          <div className="col-lg-10 reg-page-scroll">
                            <div className="mb-5">
                              <h4 className="fw-bold mt-3">Change Password</h4>
                            </div>
                            <div className="mb-3 ">
                              <div className="psw-icon-section">
                                <label for="password" className="form-label">
                                  New Password
                                </label>
                                <input
                                  id="password"
                                  type={password ? "text" : "password"}
                                  className="form-control"
                                  placeholder="*********"
                                  required
                                  aria-invalid={Boolean(
                                    registerValidation3?.password?.status ===
                                    false
                                  )}
                                  defaultValue={registerDetail?.password || ""}
                                  onChange={(e) => {
                                    setRegisterDetailsValue3(
                                      "password",
                                      e.target.value
                                    );
                                    setPasswordStrength(e.target.value);
                                  }}
                                  onBlur={(e) => {
                                    setRegisterValidationValue3(
                                      "password",
                                      PasswordValidation(e.target.value)
                                    );
                                  }}
                                  autoComplete="off"
                                />
                                <div className="psw-icon">
                                  {password ? (
                                    <span
                                      className="material-symbols-rounded cursor align-middle"
                                      onClick={() => viewPassword()}
                                    >
                                      visibility
                                    </span>
                                  ) : (
                                    <span
                                      className="material-symbols-rounded cursor align-middle"
                                      onClick={() => viewPassword()}
                                    >
                                      visibility_off
                                    </span>
                                  )}
                                </div>
                              </div>

                              <small className="text-danger">
                                {registerValidation3?.password?.message
                                  ? `Password ${registerValidation3?.password?.message}`
                                  : ""}
                              </small>
                              {passwordStrength ? (
                                <div className="mt-3">
                                  <b>Create a password with the following</b>
                                  <br />
                                  {
                                    <p
                                      className={`${passwordStrength.trim().length < 8
                                        ? "text-danger"
                                        : "text-success"
                                        } d-flex`}
                                    >
                                      {passwordStrength.trim().length < 8 ? (
                                        <span className="material-symbols-rounded filled-icon close-icon">
                                          close
                                        </span>
                                      ) : (
                                        <span className="material-symbols-rounded filled-icon close-icon">
                                          check
                                        </span>
                                      )}
                                      At least 8 Characters
                                    </p>
                                  }
                                  {
                                    <p
                                      className={`${!/^(?=.*[A-Z])(?=.*[a-z]).*$/.test(
                                        passwordStrength
                                      )
                                        ? "text-danger"
                                        : "text-success"
                                        } d-flex`}
                                    >
                                      {!/^(?=.*[A-Z])(?=.*[a-z]).*$/.test(
                                        passwordStrength
                                      ) ? (
                                        <span className="material-symbols-rounded filled-icon close-icon">
                                          close
                                        </span>
                                      ) : (
                                        <span className="material-symbols-rounded filled-icon close-icon">
                                          check
                                        </span>
                                      )}
                                      An Uppercase &amp; Lowercase character
                                    </p>
                                  }
                                  {
                                    <p
                                      className={`${!/^(?=.*[.,:;\'!@#$%^&*_+=|(){}[?\-\]\/\\]).*$/.test(
                                        passwordStrength
                                      )
                                        ? "text-danger"
                                        : "text-success"
                                        } d-flex`}
                                    >
                                      {!/^(?=.*[.,:;\'!@#$%^&*_+=|(){}[?\-\]\/\\]).*$/.test(
                                        passwordStrength
                                      ) ? (
                                        <span className="material-symbols-rounded filled-icon close-icon">
                                          close
                                        </span>
                                      ) : (
                                        <span className="material-symbols-rounded filled-icon close-icon">
                                          check
                                        </span>
                                      )}
                                      A special character
                                    </p>
                                  }
                                  {
                                    <p
                                      className={`${!/[0-9]/.test(passwordStrength)
                                        ? "text-danger"
                                        : "text-success"
                                        } d-flex`}
                                    >
                                      {!/[0-9]/.test(passwordStrength) ? (
                                        <span className="material-symbols-rounded filled-icon close-icon">
                                          close
                                        </span>
                                      ) : (
                                        <span className="material-symbols-rounded filled-icon close-icon">
                                          check
                                        </span>
                                      )}
                                      A number
                                    </p>
                                  }
                                </div>
                              ) : null}
                            </div>
                            <div className="mb-3 ">
                              <div className="psw-icon-section">
                                <label for="" className="form-label">
                                  Re-Enter Password
                                </label>
                                <input
                                  type={password2 ? "text" : "password"}
                                  className="form-control"
                                  placeholder="example@mail.com"
                                  required
                                  aria-invalid={Boolean(
                                    registerValidation3?.re_password?.status ===
                                    false
                                  )}
                                  onChange={(e) => {
                                    setRegisterDetailsValue3(
                                      "re_password",
                                      e.target.value
                                    );
                                  }}
                                  onBlur={(e) => {
                                    setRegisterValidationValue3(
                                      "re_password",
                                      RePasswordValidation(
                                        e.target.value,
                                        registerDetail3?.password
                                      )
                                    );
                                  }}
                                />
                                <div className="psw-icon">
                                  {password2 ? (
                                    <span
                                      className="material-symbols-rounded cursor align-middle"
                                      onClick={() => viewPassword2()}
                                    >
                                      visibility
                                    </span>
                                  ) : (
                                    <span
                                      className="material-symbols-rounded cursor align-middle"
                                      onClick={() => viewPassword2()}
                                    >
                                      visibility_off
                                    </span>
                                  )}
                                </div>
                              </div>

                              <small className="text-danger">
                                {registerValidation3?.re_password?.message
                                  ? `Re-Password ${registerValidation3?.re_password?.message}`
                                  : ""}
                              </small>
                            </div>
                            <div className="mb-3">
                              <button
                                type="button"
                                className="btn  w-100 my-2  login-btn"
                                onClick={(e) => {
                                  handleSubmitChangePassword(e);
                                }}
                              >
                                Change
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      {/* <CustomModal
        open={auditNavbarModal}
        onClickOutside={() => {
          
          setGetMyTasklevel2([]);
          setauditNavbarModal(false);
        }}
        className="auditModalzindex"
      >
        <div className="modal-content m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row ">
                    <div className="col-lg-8 ">
                      <div className="modal-drop-box">
                        <div className="row">
                          <div className="col-lg-12 col-md-12 ">
                            <div className="mb-3 d-flex justify-content-between align-items-center">
                              <h5 className="color-maroon fw-bold">AUDIT</h5>
                              <div
                                className="pt-3 pb-2  d-flex justify-content-end align-items-center"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  
                                  setauditNavbarModal(false);
                                }}
                              >
                                <span className="material-symbols-rounded align-middle color-maroon">
                                  arrow_back
                                </span>
                                <h6
                                  type="button"
                                  className="color-maroon fw-bold mb-0"
                                >
                                  Back
                                </h6>
                              </div>
                            </div>
                            <div className="col-megamenu ">
                              <div className="row">
                                {getMyTasklevel1?.map((tabAudit, index) => (
                                  <div
                                    className="col-lg-4 col-md-4 col-6 my-2"
                                    key={index}
                                    onClick={() => {
                                      GetMyTsksLevel2(
                                        "active",
                                        localStorage?.getItem("mainContent"),
                                        tabAudit._id
                                      );

                                      setSelectedAudit(tabAudit.title);
                                      setauditNavbarModal(false);
                                      setAuditContent(true);
                                    }}
                                  >
                                    <div className="dropdown-box">
                                      <img
                                        className="drop-box-img mb-3"
                                        src={tabAudit.icon}
                                        alt={tabAudit.icon}
                                      />
                                      <div>
                                        <p className="mb-0 fw-bold">
                                          {tabAudit.title}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 px-0 d-md-none d-none d-lg-block">
                      <div className="">
                        <img
                          className="w-100 h-auto"
                          src={DropImgModal}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal> */}
      {/* <CustomModal
        open={otherserviceNavbarModal}
        onClickOutside={() => {
          
          setGetMyTasklevel2([]);
          setotherserviceNavbarModal(false);
        }}
        className="auditModalzindex"
      >
        <div className="modal-content m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row">
                    <div className="col-lg-8 ">
                      <div className="modal-drop-box">
                        <div className="row">
                          <div className="col-lg-12 col-md-12">
                            <div className="mb-3 d-flex justify-content-between align-items-center">
                              <h5 className="fw-bold color-maroon">
                                Other Services
                              </h5>
                              <div
                                className="pt-3 pb-2  d-flex justify-content-end align-items-center"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  
                                  setotherserviceNavbarModal(false);
                                }}
                              >
                                <span className="material-symbols-rounded align-middle color-maroon">
                                  arrow_back
                                </span>
                                <h6
                                  type="button"
                                  className="color-maroon fw-bold mb-0"
                                >
                                  Back
                                </h6>
                              </div>
                            </div>
                            <div className="col-megamenu ">
                              <div className="row">
                                {getMyTasklevel1?.map((tabOS, index) => (
                                  <div
                                    className="col-lg-4  col-md-4 col-6 my-2"
                                    key={index}
                                    onClick={() => {
                                      GetMyTsksLevel2(
                                        "active",
                                        localStorage?.getItem("mainContent"),
                                        tabOS._id
                                      );
                                      setSelectedOS(tabOS.title);
                                     
                                      if (tabOS.title === "Auctions") {
                                        setotherserviceNavbarModal(false);
                                        setAuctionContent(true);
                                      } else if (
                                        tabOS.title === "Buying and Selling"
                                      ) {
                                        setotherserviceNavbarModal(false);
                                        setBuySellContent(true);
                                      } else {
                                        setotherserviceNavbarModal(false);
                                        setOSContent(true);
                                      }
                                    }}
                                  >
                                    <div className="dropdown-box">
                                      <img
                                        className="drop-box-img mb-3"
                                        src={tabOS.icon}
                                        alt={tabOS.icon}
                                      />
                                      <div>
                                        <p className="mb-0 fw-bold">
                                          {tabOS.title}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 px-0 d-md-none d-none d-lg-block ">
                      <div className="">
                        <img
                          className="w-100 h-auto os-nav-right"
                          src={OsNav}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal> */}
      {/* <CustomModal
        open={otherserviceNavbarModal1}
        onClickOutside={() => {
          
          setGetMyTasklevel2([]);
          setotherserviceNavbarModal1(false);
        }}
        className="auditModalzindex"
      >
        <div className="modal-content m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row">
                    <div className="col-lg-8 ">
                      <div className="modal-drop-box">
                        <div className="row">
                          <div className="col-lg-12 col-md-6">
                            <div className="mb-3 d-flex justify-content-between align-items-center">
                              <h5 className="fw-bold color-maroon">
                                Other Services
                              </h5>
                              <div
                                className="pt-3 pb-2  d-flex justify-content-end align-items-center"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  
                                  setotherserviceNavbarModal1(false);
                                }}
                              >
                                <span className="material-symbols-rounded align-middle color-maroon">
                                  arrow_back
                                </span>
                                <h6
                                  type="button"
                                  className="color-maroon fw-bold mb-0"
                                >
                                  Back
                                </h6>
                              </div>
                            </div>
                            <div className="col-megamenu ">
                              <div className="row">
                                {getMyTasklevel1?.map((tabOS, index) => (
                                  <div
                                    className="col-lg-4  col-md-4 col-6 my-2"
                                    key={index}
                                    onClick={() => {
                                      GetMyTsksLevel2(
                                        "active",
                                        localStorage?.getItem("mainContent"),
                                        tabOS._id
                                      );

                                      setSelectedOS(tabOS.title);
                                      setotherserviceNavbarModal1(false);
                                      setOSContent1(true);
                                    }}
                                  >
                                    <div className="dropdown-box">
                                      <img
                                        className="drop-box-img mb-3"
                                        src={tabOS.icon}
                                        alt={tabOS.icon}
                                      />
                                      <div>
                                        <p className="mb-0 fw-bold">
                                          {tabOS.title}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 px-0 d-md-none d-none d-lg-block ">
                      <div className="">
                        <img
                          className="w-100 h-auto os-nav-right"
                          src={OsNav}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal> */}
      {/* <CustomModal
        open={legalNavbarModal}
        onClickOutside={() => {
          
          setGetMyTasklevel2([]);
          setlegalNavbarModal(false);
        }}
        className="auditModalzindex"
      >
        <div className="modal-content m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row">
                    <div className="col-lg-8 ">
                      <div className="modal-drop-box pt-0">
                        <div className="row  ">
                          <div className="col-lg-12 col-md-12">
                            <div className="mb-3 mt-4 d-flex justify-content-between align-items-center">
                              <h5 className="fw-bold color-maroon">LEGAL</h5>
                              <div
                                className="pt-3 pb-2  d-flex justify-content-end align-items-center"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  
                                  setlegalNavbarModal(false);
                                }}
                              >
                                <span className="material-symbols-rounded align-middle color-maroon">
                                  arrow_back
                                </span>
                                <h6
                                  type="button"
                                  className="color-maroon fw-bold mb-0"
                                >
                                  Back
                                </h6>
                              </div>
                            </div>
                            <div className="col-megamenu ">
                              <div className="row">
                                {getMyTasklevel1?.map((tabLegal, index) => (
                                  <div
                                    className="col-lg-4 col-md-4 col-6 my-2"
                                    key={index}
                                    onClick={() => {
                                      GetMyTsksLevel2(
                                        "active",
                                        localStorage?.getItem("mainContent"),
                                        tabLegal._id
                                      );

                                      setSelectedAudit(tabLegal.title);
                                      setSelectedLegal(tabLegal.title);
                                      setlegalNavbarModal(false);
                                      setDropdownContent(true);
                                    }}
                                  >
                                    <div className="dropdown-box">
                                      <img
                                        className="drop-box-img mb-3"
                                        src={tabLegal.icon}
                                        alt={tabLegal.icon}
                                      />
                                      <div>
                                        <p className="mb-0 fw-bold">
                                          {tabLegal.title}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 d-md-none d-none px-0 d-lg-block ">
                      <div className="">
                        <img className="w-100 h-auto" src={LegalNav} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal> */}
      {/* <CustomModal
        open={manpowerNavbarModal}
        onClickOutside={() => {
          
          setGetMyTasklevel2([]);
          setmanpowerNavbarModal(false);
        }}
        className="auditModalzindex"
      >
        <div className="modal-content m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row">
                    <div className="col-lg-8 ">
                      <div className="modal-drop-box">
                        <div className="row  ">
                          <div className="col-lg-12 col-md-12">
                            <div className="mb-3 d-flex justify-content-between align-items-center">
                              <h5 className="fw-bold color-maroon">
                                MAN POWER
                              </h5>
                              <div
                                className="pt-3 pb-2  d-flex justify-content-end align-items-center"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  
                                  setmanpowerNavbarModal(false);
                                }}
                              >
                                <span className="material-symbols-rounded align-middle color-maroon">
                                  arrow_back
                                </span>
                                <h6
                                  type="button"
                                  className="color-maroon fw-bold mb-0"
                                >
                                  Back
                                </h6>
                              </div>
                            </div>
                            <div className="col-megamenu ">
                              <div className="row">
                                {console.log(
                                  "getMyTasklevel1",
                                  getMyTasklevel1
                                )}
                                {getMyTasklevel1?.map((item, index) => (
                                  <div
                                    className="col-lg-4 col-md-4 col-6 my-2"
                                    key={index}
                                  >
                                    <div
                                      className="dropdown-box"
                                      onClick={() => {
                                       
                                        localStorage.setItem(
                                          "category",
                                          item.mainServiceId.title
                                        );
                                        localStorage.setItem(
                                          "subcategory",
                                          item.title
                                        );
                                        localStorage.setItem("title", " ");
                                        localStorage.setItem(
                                          "level2Id",
                                          item._id
                                        );

                                        navigate({
                                          pathname: "/Service",
                                       
                                          search: `tab=manpower&subtab=${item.title}&id=${item._id}`,
                                        });

                                        setmanpowerNavbarModal(false);
                                        window.location.reload();
                                      }}
                                    >
                                      <img
                                        className="drop-box-img mb-3"
                                       
                                        src={item.icon.replace(
                                          "https://dalmaf-production.s3.amazonaws.com/",
                                          "https://dxwew9m8iv6fp.cloudfront.net/"
                                        )}
                                        alt={item.title}
                                      />
                                      <div>
                                        <p className="mb-0 fw-bold">
                                          {item.title}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 d-md-none d-none px-0 d-lg-block ">
                      <div className="">
                        <img
                          className="w-100 h-auto"
                          src={DropImgModal}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal> */}
      <CustomModal
        open={manPowerContent}
        onClickOutside={() => {

          setGetMyTasklevel2([]);
          setManPowerContent(false);
        }}
      >
        <div className="modal-content  m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className="row justify-content-center ">
                        <div className="col-lg-4  d-none d-lg-block  px-0">
                          <div className="">
                            <img
                              className="w-100 h-auto modal-left-img"
                              src={
                                selectedManPower === "Man Power"
                                  ? ManPowerNav
                                  : ManPowerNav
                              }
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-12">
                          <div
                            className="pt-3 pb-2 pe-5 d-flex justify-content-end align-items-center"
                            style={{ cursor: "pointer" }}
                            onClick={() => {

                              // setmanpowerNavbarModal(true);
                              setManPowerContent(false);
                            }}
                          >
                            <span className="material-symbols-rounded align-middle color-maroon">
                              arrow_back
                            </span>
                            <h6
                              type="button"
                              className="color-maroon fw-bold mb-0"
                            >
                              Back
                            </h6>
                          </div>
                          <div className="modal-drop-box pt-0 nav-modal-overflow">
                            <div className="row ">
                              {getMyTasklevel1?.map((item, index) => (
                                <div
                                  className="col-lg-4 col-md-4 col-6 my-2"
                                  key={index}
                                >
                                  <div
                                    className="dropdown-box"
                                    onClick={() => {
                                      localStorage.setItem(
                                        "category",
                                        item.mainServiceId.title
                                      );
                                      localStorage.setItem(
                                        "subcategory",
                                        item.subServiceL1Id.title
                                      );
                                      localStorage.setItem("title", " ");
                                      localStorage.setItem(
                                        "level2Id",
                                        item._id
                                      );

                                      navigate({
                                        pathname: "/Service",
                                        // search: `tab=${item.mainServiceId.title}&subtab=${item.subServiceL1Id.title}&${item.title}`,
                                        search: `tab=${item.mainServiceId.title}&subtab=${item.subServiceL1Id.title} | ${item.title}&id=${item._id}`,
                                      });
                                      window.location.reload();
                                      setManPowerContent(false);
                                    }}
                                  >
                                    <img
                                      className="drop-box-img mb-3"
                                      // src={item.icon}
                                      src={item.icon.replace(
                                        "https://dalmaf-production.s3.amazonaws.com/",
                                        "https://dxwew9m8iv6fp.cloudfront.net/"
                                      )}
                                      alt={item.title}
                                    />
                                    <div>
                                      <p className="mb-0 fw-bold">
                                        {item.title}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      {/* <CustomModal
        open={auctionContent}
        onClickOutside={() => {
          
          setGetMyTasklevel2([]);
          setAuctionContent(false);
        }}
      >
        <div className="modal-content  m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className="row justify-content-center ">
                        <div className="col-lg-4  d-none d-lg-block  px-0">
                          <div className="">
                            <img
                              className="w-100 h-auto modal-left-img"
                              src={
                                selectedManPower === "Man Power"
                                  ? ManPowerNav
                                  : ManPowerNav
                              }
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-12">
                          <div
                            className="pt-3 pb-2 pe-5 d-flex justify-content-end align-items-center"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setAuctionContent(false);
                              setotherserviceNavbarModal(true);
                            }}
                          >
                            <span className="material-symbols-rounded align-middle color-maroon">
                              arrow_back
                            </span>
                            <h6
                              type="button"
                              className="color-maroon fw-bold mb-0"
                            >
                              Back
                            </h6>
                          </div>
                          <div className="modal-drop-box pt-0 nav-modal-overflow">
                            <div className="row ">
                              {data?.map((item, index) => (
                                <div
                                  className="col-lg-4 col-md-4 col-6 my-2"
                                  key={index}
                                >
                                  <div
                                    className="dropdown-box"
                                    onClick={() => {
                                      if (item?.name === "Apartment") {
                                        navigate(`/OtherService&tab=Apartment`);
                                      } else if (
                                        item?.name === "Independent Villa"
                                      ) {
                                        navigate(`/OtherService&tab=Villa`);
                                      } else if (
                                        item?.name === "Individual House"
                                      ) {
                                        navigate(
                                          `/OtherService&tab=IndividualHouse`
                                        );
                                      } else if (item?.name === "Plot") {
                                        navigate(`/OtherService&tab=BSPlot`);
                                      } else if (item?.name === "Gold") {
                                        navigate(`/OtherService&tab=Gold`);
                                      } else if (item?.name === "Two Wheeler") {
                                        navigate(
                                          `/OtherService&tab=TwoWheeler`
                                        );
                                      } else if (
                                        item?.name === "Four Wheeler"
                                      ) {
                                        navigate(
                                          `/OtherService&tab=FourWheeler`
                                        );
                                      }

                                      setAuctionContent(false);
                                    }}
                                  >
                                    <img
                                      className="drop-box-img mb-3"
                                    
                                      src={item.icon.replace(
                                        "https://dalmaf-production.s3.amazonaws.com/",
                                        "https://dxwew9m8iv6fp.cloudfront.net/"
                                      )}
                                      alt={item.title}
                                    />
                                    <div>
                                      <p className="mb-0 fw-bold">
                                        {item.name}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal> */}
      {/* <CustomModal
        open={BuySellContent}
        onClickOutside={() => {
          
          setGetMyTasklevel2([]);
          setBuySellContent(false);
        }}
      >
        <div className="modal-content  m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className="row justify-content-center ">
                        <div className="col-lg-4  d-none d-lg-block  px-0">
                          <div className="">
                            <img
                              className="w-100 h-auto modal-left-img"
                              src={
                                selectedManPower === "Man Power"
                                  ? ManPowerNav
                                  : ManPowerNav
                              }
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-12">
                          <div
                            className="pt-3 pb-2 pe-5 d-flex justify-content-end align-items-center"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setBuySellContent(false);
                              setotherserviceNavbarModal(true);
                            }}
                          >
                            <span className="material-symbols-rounded align-middle color-maroon">
                              arrow_back
                            </span>
                            <h6
                              type="button"
                              className="color-maroon fw-bold mb-0"
                            >
                              Back
                            </h6>
                          </div>
                          <div className="modal-drop-box pt-0 nav-modal-overflow">
                            <div className="row ">
                              {data1?.map((item, index) => (
                                <div
                                  className="col-lg-4 col-md-4 col-6 my-2"
                                  key={index}
                                >
                                  <div
                                    className="dropdown-box"
                                    onClick={() => {
                                      if (item?.name === "Apartment") {
                                        navigate(
                                          `/OtherService&tab=BSApartment`
                                        );
                                      } else if (
                                        item?.name === "Independent Villa"
                                      ) {
                                        navigate(`/OtherService&tab=BSVilla`);
                                      } else if (
                                        item?.name === "Individual House"
                                      ) {
                                        navigate(
                                          `/OtherService&tab=BSIndividualHouse`
                                        );
                                      } else if (item?.name === "Plot") {
                                        navigate(`/OtherService&tab=BSPlot`);
                                      } else if (item?.name === "Gold") {
                                        navigate(`/OtherService&tab=BSGold`);
                                      } else if (item?.name === "Two Wheeler") {
                                        navigate(
                                          `/OtherService&tab=BSTwoWheeler`
                                        );
                                      } else if (
                                        item?.name === "Four Wheeler"
                                      ) {
                                        navigate(
                                          `/OtherService&tab=BSFourWheeler`
                                        );
                                      }

                                      setBuySellContent(false);
                                    }}
                                  >
                                    <img
                                      className="drop-box-img mb-3"
                                    
                                      src={item.icon.replace(
                                        "https://dalmaf-production.s3.amazonaws.com/",
                                        "https://dxwew9m8iv6fp.cloudfront.net/"
                                      )}
                                      alt={item.title}
                                    />
                                    <div>
                                      <p className="mb-0 fw-bold">
                                        {item.name}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal> */}
    </div>
  );
}
