import React, { useEffect, useState } from "react";
import { GetGoldListUser, GetPropertyUser } from "../../Api/Api";
import Spinner from "../../Spinner/Spinner";
import Select from 'react-select';
import { useSelector } from "react-redux";
import CustomModal from "../CustomModal";
import { useNavigate } from "react-router-dom";

export default function GoldAuctionList() {
  const [loading, setLoading] = useState(true);
  const [propertyList, setPropertyList] = useState([]);
  const [loginModal, setLoginModal] = useState(false);
  const [filters, setFilters] = useState({
    property_id: '',
    branch: '',
    bank: '',
  });

  const handleFilterChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  const filteredList = propertyList.filter((property) =>
    Object.entries(filters).every(([key, value]) => !value || property[key].toLowerCase().includes(value.toLowerCase()))
  );

  useEffect(() => {
    getGoldList();
  }, []);

  const getGoldList = async () => {
    setLoading(true);
    const response = await GetGoldListUser();
    if (response && response.statusCode === 200) {
      setPropertyList(response.data.filter((data) => data.status === "Active"));
      setLoading(false);
    } else {
      setPropertyList([]);
      setLoading(false);
      // errorMsg(response.message);
    }
  };

  const formatter = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR'
  });
  const navigate = useNavigate();

  const state = useSelector((state) => state);
  const viewMore = (id) => {
    const userid = state?.auth?.auth?._id;
    const token = state?.auth?.auth?.token;
    if (token) {
      navigate(`/GoldAuctionDetail/${id}`)
    } else {
      setLoginModal(true)
    }
  }

  return (
    <div>
      <div
        className={`modal fade ${loginModal ? 'show d-block' : ''}`}
        tabIndex="-1"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header text-white" style={{backgroundColor:'#dc3545'}}>
              <h5 className="modal-title">Validate Login</h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                onClick={() => setLoginModal(!loginModal)}
              ></button>
            </div>
            <div className="modal-body text-center">
              <h5 className="mb-4">Login to View More</h5>
            </div>
          </div>
        </div>
      </div>
      {/* <CustomModal
        open={loginModal}
        onClickOutside={() => setLoginModal(false)}
      >
        <div className={`modal-content ${styles.modalContent}`}>
          <div className="modal-body text-center py-4">
            <p className="text-center">
              You must be logged in to view property details.
            </p>
          </div>
        </div>
      </CustomModal> */}
      {loading ? (
        <Spinner />
      ) : (
        <div style={{ width: "100%" }}>
          <div className="row">
            <div className="col-md-4 col-sm-12 mb-2">
              <Select
                options={propertyList.map(property => ({ label: property.branch, value: property.branch }))}
                onChange={(selectedOption) => handleFilterChange('branch', selectedOption.value)}
                placeholder="Select Branch"
                isSearchable
              />
            </div>
            {/* <div className="col-md-4 col-sm-12 mb-2">
              <Select
                options={propertyList.map(property => ({ label: property.bank, value: property.bank }))}
                onChange={(selectedOption) => handleFilterChange('property_type', selectedOption.value)}
                placeholder="Select Property Type"
                isSearchable
              />
            </div> */}
            <div className="col-md-4 col-sm-12 mb-2">
              <Select
                options={propertyList.map(property => ({ label: property.bank_institude, value: property.bank_institude }))}
                onChange={(selectedOption) => handleFilterChange('bank_institude', selectedOption.value)}
                placeholder="Select Bank"
                isSearchable
              />
            </div>
            {/* <div className="col-md-4 col-sm-12 mb-2">
              <Select
                options={propertyList.map(property => ({ label: property.city, value: property.city }))}
                onChange={(selectedOption) => handleFilterChange('city', selectedOption.value)}
                placeholder="Select City"
                isSearchable
              />
            </div>
            <div className="col-md-4 col-sm-12 mb-2">
              <Select
                options={propertyList.map(property => ({ label: property.possession_status, value: property.possession_status }))}
                onChange={(selectedOption) => handleFilterChange('possession_status', selectedOption.value)}
                placeholder="Select Possession Status"
                isSearchable
              />
            </div>
            <div className="col-md-4 col-sm-12 mb-2">
              <Select
                options={propertyList.map(property => ({ label: property.bank, value: property.bank }))}
                onChange={(selectedOption) => handleFilterChange('bank', selectedOption.value)}
                placeholder="Select Bank"
                isSearchable
              />
            </div> */}
          </div>
          <div className="table-responsive">
            <table className="table table-hover table-striped">
              <thead className="table-heading">
                <tr>
                  <th>S.No</th>
                  <th>Auction ID</th>
                  <th>Branch</th>
                  {/* <th>Auction Date</th> */}
                  {/* <th>EMD Submission Last Date</th> */}
                  <th>Bank</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredList.map((property, index) => (
                  <tr key={index} style={{justifyContent:'center'}}>
                    <td>{index + 1}</td>
                    <td style={{ cursor: "pointer" }} onClick={() => viewMore(property._id)}><u>{property.id}</u></td>
                    <td>{property.branch}</td>
                    {/* <td>{property.property_type}</td>
                    <td>{property.area}</td>
                    <td>{property.city}</td>
                    <td>{property.possession_status}</td>
                    <td>{formatter.format(property.reserve_price)}</td>
                    <td>{formatter.format(property.emd_amount)}</td> */}
                    {/* <td>{new Date(property.auction_date).toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })}</td> */}
                    {/* <td>{new Date(property.emd_submission_last_date).toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })}</td> */}
                    <td>{property.bank_institude}</td>
                    <td>
                      <button
                        className="btn"
                        style={{backgroundColor:'#dc3545',color:"white"}}
                        // style={{ backgroundImage: "linear-gradient(180deg, #c56a38, #de9338)" }}
                        onClick={() => viewMore(property._id)}
                      >
                        View Details
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}
