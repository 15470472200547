
export const url =
process.env.NODE_ENV === "development" ? "https://dalmaf.com/dalmaf" : "/dalmaf";

export const entrepreneur_service_url =
process.env.NODE_ENV === "development" ? "https://dalmaf.com/entrepreneur-service/" : "/entrepreneur-service/";

export const payment_service_url =
process.env.NODE_ENV === "development" ? "https://dalmaf.com/payment-service/" : "/payment-service/";
  export const entrepreneur_ui_url =
  process.env.NODE_ENV === "development" ? "http://localhost:3001/entrepreneur" : "https://dalmaf.com/entrepreneur";
    // process.env.NODE_ENV === "development" ? "http://localhost:8004/dalmaf" : "/dalmaf";

