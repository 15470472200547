import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { GetContent } from '../../Api/Api';

export default function DMBanner() {
  const [auditContent,setAuditContent] = useState([])
  const state = useSelector((state) => state);
  useEffect(() => {
    GetMyTsks()
  }, []);
  const GetMyTsks = async () => {
    let id = localStorage.getItem("level2Id")
    const token = state.auth.auth.token;
    let data = await GetContent("active",id);
    if (data && data.statusCode === 200) {
      setAuditContent(data.data);
    } else {
      setAuditContent([]);
      //console.log("Error while getting my task");
    }
  };
  return (
    <div className="">
      <div className="servicespage-banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              {auditContent?.map((e, i) => (
                <div className="head-banner-posi" key={i}>
                  <h1 className="banner-heading after-line ">
                    {e?.subServiceL2Id?.title}
                  </h1>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
