import React, { useEffect, useState } from "react";
import { orderStatus, orderStatusWithoutLogin } from "../Api/Api";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { fetchWithQueryAPI } from "../Api/v1/Util/Api";
import { auction_payment_status } from "../Api/APIUrl";
import Countdown from "react-countdown";
import Success from "../Assets/Images/success-img/success.webp";
import Failure from "../Assets/Images/success-img/failure.webp";
import Not from "../Assets/Images/success-img/not.webp";
import UserNavigation from '../Components/v1/NavBar/Navigaton';

function OrderConfirmation() {
    const [unAuth, setUnAuth] = useState(false);
    const [awaitResponse, setAwaitResponse] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(false);
    const state = useSelector((state) => state);


    // Extract URL parameters
    const getUrlParameter = (name) => {
        const params = new URLSearchParams(window.location.search);
        return params.get(name);
    };

    useEffect(() => {
        if (getUrlParameter('auction_type') && getUrlParameter('reference_id')) {
            fetchAuctionOrderInfo();
        } else {
            fetchOrderInfo(window.location.href.substring(window.location.href.indexOf("=") + 1));
        }
    }, []);

    const fetchAuctionOrderInfo = async () => {
        let response;
        if (state?.auth?.isLoggedIn) {
            const token = state?.auth?.auth?.token;
            response = await fetchWithQueryAPI(token, auction_payment_status + getUrlParameter('orderId'));
            handleResponse(response);
        }
    };

    const fetchOrderInfo = async (getOrderId) => {
        let response;
        if (state?.auth?.isLoggedIn) {
            const token = state?.auth?.auth?.token;
            response = await orderStatus(token, getOrderId);
            handleResponse(response);
        } else {
            response = await orderStatusWithoutLogin(getOrderId);
            handleResponse(response);
        }
    };

    const handleResponse = (response) => {
        if (response && response.statusCode === 200) {
            if (response.data.paymentInfo?.order_status === 'Success' || response.data.status === 'paid') {
                setPaymentStatus(true);
            }else if (response.data.payment_response?.order_status === 'Success') {
                setPaymentStatus(true);
            } else {
                setPaymentStatus(false);
            }
            setAwaitResponse(true)
        } else if (response && response.statusCode === 406) {
            // setUnAuth(true);
            setAwaitResponse(true)
            setPaymentStatus(false);
        }
    };

    // Renderer for countdown
    const renderer = ({ seconds, completed }) => {
        const auctionType = getUrlParameter('auction_type');
        const referenceId = getUrlParameter('reference_id');

        if (completed) {
            let url;
            if (auctionType === 'property') {
                url = `https://www.dalmaf.com/PropertyAuctionDetail/${referenceId}`;
            } else if (auctionType === 'gold') {
                url = `https://www.dalmaf.com/GoldAuctionDetail/${referenceId}`;
            } else if (auctionType === 'vehicle') {
                url = `https://www.dalmaf.com/VechileAuctionDetail/${referenceId}`;
            }else{
                url = localStorage?.getItem("url");
            }
            return (
                <h6 className="ms-2 mt-1 text-primary text-white">
                    <a href={url}>Click here</a>
                </h6>
            );
        } else if (seconds === 1) {
            window.location.href = auctionType === 'property'
                ? `https://www.dalmaf.com/PropertyAuctionDetail/${referenceId}`
                : auctionType === 'gold'
                    ? `https://www.dalmaf.com/GoldAuctionDetail/${referenceId}`
                    : auctionType === 'vehicle'
                        ? `https://www.dalmaf.com/VechileAuctionDetail/${referenceId}`
                        : localStorage?.getItem("url");
        } else {
            return <h6 className="ms-2 mt-1 text-primary text-white"><span>{seconds}</span></h6>;
        }
    };

    return (
        <div className="payment-images">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Order Confirmation | DALMaf Private Limited</title>
                <link rel="canonical" href={window.location.href} />
                <meta name="description" content="Order Confirmation" />
                <meta name="keywords" content="Order Confirmation" />
                <meta name="author" content="DALMaf" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <UserNavigation/>
            {
                awaitResponse && (
                    <>
                        <div className="container" style={{marginTop:"-100px"}}>
                            <div className="row justify-content-center">
                                <div className="col-lg-6">
                                    {paymentStatus ? (
                                        <img className="w-100 h-auto" src={Success} alt="Success" />
                                    ) : (
                                        <img className="w-100 h-auto" src={Failure} alt="Failure" />
                                    )}
                                </div>
                                <div className="col-lg-6 m-auto">
                                    <div className="text-center">
                                        <h1 className="fw-bold my-4 text-white">
                                            {paymentStatus ? "Your Payment is Successful" : "Your Purchase Failed"}
                                        </h1>
                                        <div className="ms-5 d-flex justify-content-center text-white">
                                            <span>You will be redirected to the web page in </span>
                                            <Countdown date={Date.now() + 5000} renderer={renderer} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </div>
    );
}

export default OrderConfirmation;
