import React, { useEffect, useState } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";

import Norecord from "../../../Assets/Images/R.webp";

import Si3 from "../../../Assets/Images/as3.webp";
import { useSelector } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  GetAllSubAdminAndAdmin,
  GetPackageRequestByAdmin,
  GetParticularPropertyRequest,
  GetProperty,
  GetServiceRequestByRole,
  UpdatePropertyReq,
  UpdatePropertyRequest,
} from "../../../Api/Api";
import moment from "moment";
import {
  EmailValidation,
  NonEmptyValidation,
  PhoneNumberValidation,
  StringValidation,
} from "../../../Store/validate";
import Spinner from "../../../Spinner/Spinner";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
export default function PropertyRequest() {
  const [ShowTable, setShowTable] = useState(true);
  const [loading, setLoading] = useState(true);

  const [viewDetails, setViewDetails] = useState(true);

  const [selectedTab, setSelectedTab] = React.useState("Assigned");
  const AllTabs = ["Assigned", "Pending", "In Progress", "Reject", "Complete"];

  const state = useSelector((state) => state);
  const [propertyReq, setPropertyReq] = useState([]);
  const [propertyReqParticular, setPropertyReqParticular] = useState({});
  const [propertyReqValidation, setPropertyReqValidation] =
    useState({});

  const setServiceReqParticularValue = (parentKey, childKey, value, type) => {
    if (type === "object") {
      setPropertyReqParticular({
        ...propertyReqParticular,
        ...{
          [parentKey]: {
            ...propertyReqParticular[parentKey],
            [childKey]: value,
          },
        },
      });
      if (propertyReqValidation[parentKey])
        delete propertyReqValidation[parentKey];
      if (propertyReqValidation[childKey])
        delete propertyReqValidation[childKey];
    } else {
      setPropertyReqParticular({ ...propertyReqParticular, [childKey]: value });
      if (propertyReqValidation[parentKey])
        delete propertyReqValidation[parentKey];
      if (propertyReqValidation[childKey])
        delete propertyReqValidation[childKey];
    }
  };

  const handleSubmitUpdateStatus = async (e, value, id, data, value1) => {
    e.preventDefault();
    const Payload = {
      _id: id,
      assigned_to: value,
      comments: data,
    };
    const token = state?.auth?.auth?.token;

    const response = await UpdatePropertyReq(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      getServiceReq(value1);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };
  const setServiceReqParticularValidationValue = (
    parentKey,
    childKey,
    value,
    type
  ) => {
    if (type === "object") {
      setPropertyReqValidation({
        ...propertyReqValidation,
        ...{
          [parentKey]: {
            ...propertyReqValidation[parentKey],
            [childKey]: value,
          },
        },
      });
    } else {
      setPropertyReqValidation({
        ...propertyReqValidation,
        [childKey]: value,
      });
    }
  };

  useEffect(() => {
    if (state?.auth?.auth?.role === "superadmin") {
      getServiceReq("Assigned");
    } else {
      getServiceReq("Assigned");
    }
    GetAllProperty();
    GetAllSubadmins();
  }, []);

  const getServiceReq = async (status) => {
    setLoading(true);
    const role = state?.auth?.auth?.role;
    const token = state.auth.auth.token;
    let response;
    if (state?.auth?.auth?.role === "superadmin") {
      response = await GetPackageRequestByAdmin(token, role, status);
    } else {
      response = await GetServiceRequestByRole(
        token,
        role,
        status,
        state?.auth?.auth?._id
      );
    }

    if (response.statusCode === 200) {
      setPropertyReq(response.data);

      setLoading(false);
    } else {
      setPropertyReq([]);
      setLoading(false);
      //console.log("error while getting user profile info");
    }
  };

  const getServiceReqParticular = async (id) => {
    setLoading(true);
    const token = state.auth.auth.token;
    const response = await GetParticularPropertyRequest(token, id);
    if (response.statusCode === 200) {
      setPropertyReqParticular(response.data);
      setLoading(false);
    } else {
      setPropertyReqParticular({});
      setLoading(false);
      //console.log("error while getting user profile info");
    }
  };

  const [allSubadmin, setAllSubadmin] = useState([]);
  const GetAllSubadmins = async () => {
    setLoading(true);
    const token = state?.auth?.auth?.token;
    let data = await GetAllSubAdminAndAdmin(token);
    if (data && data.statusCode === 200) {
      setAllSubadmin(data.data);
      setLoading(false);
    } else {
      setAllSubadmin({});
      setLoading(false);
      //console.log("Error while getting all subadmins");
    }
  };

  const [property, setProperty] = useState([]);
  const GetAllProperty = async () => {
    setLoading(true);

    let data = await GetProperty("Active");
    if (data && data.statusCode === 200) {
      setProperty(data.data);
      setLoading(false);
    } else {
      setProperty([]);
      //console.log("Error while getting all subadmins");
    }
  };

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.name = StringValidation(propertyReqParticular?.name);

    validate.emailId = NonEmptyValidation(
      propertyReqParticular?.emailId?.toLowerCase()
    );
    validate.message = NonEmptyValidation(propertyReqParticular?.message);

    validate.subject = NonEmptyValidation(propertyReqParticular?.subject);
    validate.assigned_to = NonEmptyValidation(
      propertyReqParticular?.assigned_to?.name
    );

    setPropertyReqValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      let Payload;
      if (propertyReqParticular.status === "Pending") {
        Payload = {
          name: propertyReqParticular.name,
          emailId: propertyReqParticular.emailId?.toLowerCase(),
          message: propertyReqParticular.message,
          mobileNumber: propertyReqParticular.mobileNumber,
          assigned_to: propertyReqParticular.assigned_to?.name,
          subject: propertyReqParticular.subject,
          property_id: propertyReqParticular.property_id?._id,
          _id: propertyReqParticular._id,
          status: "Assigned",
        };
      } else {
        Payload = {
          name: propertyReqParticular.name,
          emailId: propertyReqParticular.emailId?.toLowerCase(),
          message: propertyReqParticular.message,
          mobileNumber: propertyReqParticular.mobileNumber,
          assigned_to: propertyReqParticular.assigned_to?.name,
          subject: propertyReqParticular.subject,
          property_id: propertyReqParticular.property_id?._id,
          _id: propertyReqParticular._id,
          status: "Assigned",
        };
      }

      const token = state.auth.auth.token;
      const response = await UpdatePropertyRequest(Payload, token);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        getServiceReq("Assigned");
        setViewDetails(true);
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } else {
      //console.log("Error while assign service");
    }
  };


   const [leadComment, setLeadComment] = useState("");
   const [commentsValidation, setCommentsValidation] = useState({});

   const setCommentsValue = (key, value) => {
     setLeadComment({ ...leadComment, [key]: value });

     if (commentsValidation[key]) delete commentsValidation[key];
   };

   const setCommentsLeadValidationValue = (key, value) => {
     setCommentsValidation({
       ...commentsValidation,

       [key]: value,
     });
   };
  // const [comment, setComment] = useState("");
  const handleSubmitComment = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.comments = NonEmptyValidation(leadComment?.comments);
    setCommentsValidation(validate)
    if (Object.values(validate).every((v) => v.status === true)) {
      let Payload = {
        _id: propertyReqParticular._id,
        comments: leadComment?.comments,
      };
      const token = state.auth.auth.token;
      const response = await UpdatePropertyRequest(Payload, token);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        getServiceReqParticular();
        window.location.reload();
        setLeadComment("");

        e.target.reset();
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } else {
      //console.log("Error while update user details");
    }
  };

  const handleSubmitUpdate = async (e, userId, value) => {
    e.preventDefault();
    let Payload;
    if (value === "subadmin") {
      Payload = {
        _id: userId,
        subadmin_read: true,
        // admin_read:"",
      };
    } else {
      Payload = {
        _id: userId,
        // subadmin_read:"",
        admin_read: true,
      };
    }
    const token = state.auth.auth.token;
    const response = await UpdatePropertyRequest(Payload, token);
    if (response && response.statusCode === 200) {
      getServiceReq();
    } else {
      //console.log("Error");
    }
  };

  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <div
                style={{ cursor: "pointer" }}
                onClick={(val) => {
                  getServiceReqParticular(tableMeta.rowData[0]);
                  if (tableMeta.rowData[6] === false) {
                    handleSubmitUpdate(val, tableMeta.rowData[0], "admin");
                  } else if (tableMeta.rowData[7] === false) {
                    handleSubmitUpdate(val, tableMeta.rowData[0], "subadmin");
                  }
                  setViewDetails(false);
                }}
              >
                {value}
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "emailId",
      label: "Email Id",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "assigned_to",
      label: "Assigned To",
      options: {
        customBodyRender: (value) => {
          return (
            <div>
              {allSubadmin
                ?.filter((e) => e._id === value)
                .map((i) => (
                  <div>{i.name}</div>
                ))}
            </div>
          );
        },
      },
    },
    {
      name: "createdAt",
      label: "Created On",
      options: {
        customBodyRender: (value) => {
          return <div>{moment(value).format("DD-MM-YYYY")}</div>;
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <select
              className="form-control form-control-lg"
              defaultValue={value}
              onChange={(e) => {
                handleSubmitUpdateStatus(
                  e,
                  e.target.value,
                  tableMeta.rowData[0],
                  value
                );
              }}
            >
              <option value="Assigned">Assigned</option>
              <option value="Pending">To Do</option>
              <option value="Inprogress">In Progress</option>
              <option value="Reject">Reject</option>
              <option value="Completed">Completed</option>
            </select>
          );
        },
      },
    },
   
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    setRowProps: (row) => {
      if (
        (state?.auth?.auth?.role === "superadmin" && row[6] === false) ||
        (state?.auth?.auth?.role === "subadmin" && row[7] === false)
      ) {
        return {
          style: { background: "#DBDFEA", cursor: "pointer" },
        };
      } else {
        return {
          style: { background: "white", cursor: "pointer" },
        };
      }
    },
  };

  return (
    <div>
      <ToastContainer />
      <AdminNavbar />
      <div className="main srpage">
        {loading ? (
          <Spinner />
        ) : (
          <>
            {viewDetails ? (
              <>
                {state?.auth?.auth?.role === "superadmin" ? (
                  <div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="">
                        <h5 className="mb-0 fw-bold color-maroon">
                          Property Request Management
                        </h5>
                      </div>
                      {/* <div className="d-flex">
                        <h6
                          className={`mb-0 mx-2 table-icon-bg ${
                            ShowTable && "active"
                          }`}
                          onClick={() => {
                            setShowTable(true);
                          }}
                        >
                          <span className="material-symbols-rounded shop-icon  cursor align-middle mx-2">
                            menu
                          </span>
                        </h6>
                        <h6
                          className={`mx-2 mb-0 table-icon-bg ${
                            !ShowTable && "active"
                          }`}
                          onClick={() => {
                            setShowTable(false);
                          }}
                        >
                          <span className="material-symbols-rounded shop-icon filled-icon cursor align-middle mx-2">
                            grid_view
                          </span>
                        </h6>
                      </div> */}
                    </div>
                    {ShowTable && (
                      <div className="my-4">
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-7 px-0">
                              <div className="tab-section">
                                <ul className="ps-0">
                                  {AllTabs.map((tab, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className={
                                          selectedTab === tab
                                            ? "serviceTab active"
                                            : "serviceTab inactive"
                                        }
                                        onClick={() => {
                                          if (tab === "Assigned") {
                                            getServiceReq("Assigned");
                                          } else if (tab === "Pending") {
                                            getServiceReq("Pending");
                                          } else if (tab === "In Progress") {
                                            getServiceReq("Inprogress");
                                          } else if (tab === "Reject") {
                                            getServiceReq("Reject");
                                          } else if (tab === "Complete") {
                                            getServiceReq("Complete");
                                          }

                                          setSelectedTab(tab);
                                        }}
                                      >
                                        <li>
                                          <span className="align-middle">
                                            {tab}
                                          </span>
                                        </li>
                                      </div>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <MUIDataTable
                          title={"Dalmaf -  Property Request Management"}
                          data={propertyReq}
                          columns={columns}
                          options={options}
                        />
                      </div>
                    )}
                    {!ShowTable && (
                      <div>
                        <div className="container py-4">
                          <div className="row">
                            {propertyReq?.map((e, index) => (
                              <div
                                className="col-lg-3 mb-3"
                                key={index}
                                onClick={() => {
                                  getServiceReqParticular(e._id);
                                  setViewDetails(false);
                                }}
                              >
                                <div className="admin-service2">
                                  <div className="mt-3">
                                    <div className="d-flex mb-2 align-items-center">
                                      {/* <img className="asi-icon" src={Si3} alt="" /> */}
                                      {property
                                        ?.filter((p) => p._id === e.property_id)
                                        .map((i) => (
                                          <h6 className="ms-2 banner-heading mb-0">
                                            {i.property_name}
                                          </h6>
                                        ))}
                                    </div>
                                    <p className="mt-3 small">
                                      {e?.name?.toUpperCase()}{" "}
                                    </p>
                                    <p className="mt-3 small">{e.emailId} </p>
                                    <h6>
                                      <span className="fw-bold  color-maroon">
                                        Status :
                                      </span>{" "}
                                      <span className="small color-orange">
                                        {e.status}
                                      </span>
                                    </h6>
                                    <h6>
                                      <span className="fw-bold  color-maroon">
                                        Assigned To :
                                      </span>{" "}
                                      {allSubadmin
                                        ?.filter((e) => e._id === e.assigned_to)
                                        .map((i) => (
                                          <span className="small color-orange">
                                            {i.name}
                                          </span>
                                        ))}
                                    </h6>
                                    <h6>
                                      <span className="fw-bold  color-maroon">
                                        Created On :
                                      </span>{" "}
                                      <span className="small ">
                                        {moment(e.createdAt).format(
                                          "DD.MM.YYYY"
                                        )}
                                      </span>
                                    </h6>
                                    <button
                                      type="button"
                                      className="btn btn-admin w-100"
                                      onClick={() => setViewDetails(false)}
                                    >
                                      View Details
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    <div>
                      <div className="d-flex justify-content-between">
                        <div className="">
                          <h5 className="mb-0 fw-bold color-maroon">
                            Property Request Management
                          </h5>
                        </div>
                      </div>{" "}
                      <div>
                        <div className="container py-4">
                          <div className="row">
                            {loading ? (
                              <Spinner />
                            ) : (
                              <>
                                {propertyReq && propertyReq?.length ? (
                                  <>
                                    {propertyReq?.map((e, index) => (
                                      <div
                                        className="col-lg-3 mb-3"
                                        key={index}
                                      >
                                        <div className="admin-service2">
                                          <div className="mt-3">
                                            <div className="d-flex mb-2 align-items-center">
                                              <img
                                                className="asi-icon"
                                                src={Si3}
                                                alt=""
                                              />
                                              <h6 className="ms-2 banner-heading mb-0">
                                                {e.servicesCategory}
                                              </h6>
                                            </div>
                                            <p className="mt-3 small">
                                              Free Legal Services/Advice Online,
                                              Expert Legal Advice with 100%
                                              Confidentiality.{" "}
                                              {/* <a
                                                className="color-maroon fw-bold"
                                                href=""
                                              >
                                                Read More
                                              </a> */}
                                            </p>
                                            <h6>
                                              <span className="fw-bold  color-maroon">
                                                Status :
                                              </span>{" "}
                                              <span className="small color-orange">
                                                {e.status}
                                              </span>
                                            </h6>
                                            <h6>
                                              <span className="fw-bold  color-maroon">
                                                Created On :
                                              </span>{" "}
                                              <span className="small ">
                                                {moment(e.createdAt).format(
                                                  "DD-MM-YYYY"
                                                )}
                                              </span>
                                            </h6>
                                            <button
                                              type="button"
                                              className="btn btn-admin w-100"
                                              onClick={() => {
                                                getServiceReqParticular(e._id);
                                                setViewDetails(false);
                                              }}
                                            >
                                              View Details
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={Norecord}
                                      alt=""
                                      style={{
                                        width: "200px",
                                        height: "200px",
                                        marginLeft: "500px",
                                      }}
                                      className="img-fluid"
                                    />
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* )} */}
                    </div>
                  </>
                )}
              </>
            ) : (
              <div>
                <div className="">
                  <p className="mb-0">
                    {" "}
                    <span
                      style={{ cursor: "pointer" }}
                      className="mb-0 fs-5 fw-bold color-maroon mr-2"
                      onClick={() => {
                        setViewDetails(true);
                        if (state?.auth?.auth?.role === "superadmin") {
                          getServiceReq("Assigned");
                        } else {
                          getServiceReq();
                        }
                      }}
                    >
                      Property Request{" "}
                    </span>{" "}
                    <span className="mb-0 fs-6 color-maroon">
                      {" "}
                      Request Details
                    </span>{" "}
                  </p>
                </div>

                {state?.auth?.auth?.role === "superadmin" ? (
                  <div>
                    <div className=" my-4">
                      <div className="bg-historytablehead rounded-3 py-3 px-3">
                        <h6 className="mb-0">Update Property</h6>
                      </div>
                      <div className="container">
                        <div className="row justify-content-center py-5">
                          <div className="col-lg-11">
                            <div className="row">
                              <div className="col-lg-12">
                                <div
                                  className="p-3"
                                  style={{ border: "1px solid black" }}
                                >
                                  <div className="small-banner-img-head">
                                    <img
                                      style={{ width: "100%", height: "200px" }}
                                      // className="w-100 h-auto"
                                      src={
                                        propertyReqParticular?.property_id
                                          ?.cover_image
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="mt-3">
                                    <h6 className="color-maroon fw-bold">
                                      {
                                        propertyReqParticular?.property_id
                                          ?.property_name
                                      }
                                    </h6>
                                    <p className="small">
                                      <span className="material-symbols-rounded align-middle me-1 filled-icon">
                                        location_on
                                      </span>
                                      {
                                        propertyReqParticular?.property_id
                                          ?.property_information.address
                                      }
                                    </p>

                                    <p className="small">
                                      <b>Room:</b>{" "}
                                      {
                                        propertyReqParticular?.property_id
                                          ?.property_information.room_size
                                      }
                                      BHK
                                    </p>
                                    <p className="small">
                                      <b>SqFt :</b>{" "}
                                      {
                                        propertyReqParticular?.property_id
                                          ?.property_information.sq_ft
                                      }
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-4">
                                  <label className="form-label">Name</label>
                                  <input
                                    type="text"
                                    className="form-control form-control-lg"
                                    placeholder="Enter the Name"
                                    aria-invalid={Boolean(
                                      propertyReqValidation?.name?.status ===
                                        false
                                    )}
                                    value={propertyReqParticular?.name}
                                    onChange={(e) => {
                                      setServiceReqParticularValue(
                                        "",
                                        "name",
                                        e.target.value,
                                        ""
                                      );
                                    }}
                                    onBlur={(e) => {
                                      setServiceReqParticularValidationValue(
                                        "",
                                        "name",
                                        StringValidation(e.target.value),
                                        ""
                                      );
                                    }}
                                    autoComplete="off"
                                  />
                                  <small className="text-danger">
                                    {propertyReqValidation?.name?.message
                                      ? `Name ${propertyReqValidation?.name?.message}`
                                      : ""}
                                  </small>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-4">
                                  <label className="form-label">
                                    Mobile No
                                  </label>
                                  <input
                                    type="tel"
                                    className="form-control form-control-lg"
                                    maxLength={10}
                                    placeholder="Enter the Mobile number"
                                    aria-invalid={Boolean(
                                      propertyReqValidation?.mobileNumber
                                        ?.status === false
                                    )}
                                    defaultValue={
                                      propertyReqParticular?.mobileNumber
                                    }
                                    onChange={(e) => {
                                      setServiceReqParticularValue(
                                        "",
                                        "mobileNumber",
                                        e.target.value,
                                        ""
                                      );
                                    }}
                                    onBlur={(e) => {
                                      setServiceReqParticularValidationValue(
                                        "",
                                        "mobileNumber",
                                        PhoneNumberValidation(e.target.value),
                                        ""
                                      );
                                    }}
                                    autoComplete="off"
                                  />
                                  <small className="text-danger">
                                    {propertyReqValidation?.mobileNumber
                                      ?.message
                                      ? `Mobile Number ${propertyReqValidation?.mobileNumber?.message}`
                                      : ""}
                                  </small>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-4">
                                  <label className="form-label">Email Id</label>
                                  <input
                                    type="email"
                                    className="form-control form-control-lg"
                                    placeholder="Enter Email-Id"
                                    aria-invalid={Boolean(
                                      propertyReqValidation?.emailId?.status ===
                                        false
                                    )}
                                    value={propertyReqParticular?.emailId?.toLowerCase()}
                                    onChange={(e) => {
                                      setServiceReqParticularValue(
                                        "",
                                        "emailId",
                                        e.target.value,
                                        ""
                                      );
                                    }}
                                    onBlur={(e) => {
                                      setServiceReqParticularValidationValue(
                                        "",
                                        "emailId",
                                        EmailValidation(
                                          e.target.value?.toLowerCase()
                                        ),
                                        ""
                                      );
                                    }}
                                    autoComplete="off"
                                  />
                                  <small className="text-danger">
                                    {propertyReqValidation?.emailId?.message
                                      ? `Email Id ${propertyReqValidation?.emailId?.message}`
                                      : ""}
                                  </small>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-4">
                                  <label className="form-label">Message</label>
                                  <input
                                    type="text"
                                    className="form-control form-control-lg"
                                    placeholder="Enter Your Question"
                                    aria-invalid={Boolean(
                                      propertyReqValidation?.message?.status ===
                                        false
                                    )}
                                    value={propertyReqParticular?.message}
                                    onChange={(e) => {
                                      setServiceReqParticularValue(
                                        "",
                                        "message",
                                        e.target.value,
                                        ""
                                      );
                                    }}
                                    onBlur={(e) => {
                                      setServiceReqParticularValidationValue(
                                        "",
                                        "message",
                                        NonEmptyValidation(e.target.value),
                                        ""
                                      );
                                    }}
                                    autoComplete="off"
                                  />
                                  <small className="text-danger">
                                    {propertyReqValidation?.message?.message
                                      ? `Message ${propertyReqValidation?.message?.message}`
                                      : ""}
                                  </small>
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="mb-4">
                                  <label className="form-label">Subject</label>
                                  <textarea
                                    rows={6}
                                    className="form-control form-control-lg"
                                    aria-invalid={Boolean(
                                      propertyReqValidation?.subject?.status ===
                                        false
                                    )}
                                    value={propertyReqParticular?.subject}
                                    onChange={(e) => {
                                      setServiceReqParticularValue(
                                        "",
                                        "subject",
                                        e.target.value,
                                        ""
                                      );
                                    }}
                                    onBlur={(e) => {
                                      setServiceReqParticularValidationValue(
                                        "",
                                        "subject",
                                        NonEmptyValidation(e.target.value),
                                        ""
                                      );
                                    }}
                                    autoComplete="off"
                                  ></textarea>
                                  <small className="text-danger">
                                    {propertyReqValidation?.subject?.message
                                      ? `Subject ${propertyReqValidation?.subject?.message}`
                                      : ""}
                                  </small>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-4">
                                  <label className="form-label ">
                                    Assigned To
                                  </label>

                                  <select
                                    className="form-control form-control-lg"
                                    defaultValue={
                                      propertyReqParticular?.assigned_to?.name
                                    }
                                    onChange={(e) => {
                                      let name = allSubadmin?.filter(
                                        (s) => s.name === e.target.value
                                      );
                                      let nameId = name?.map((n ) => {
                                        return n._id;
                                      });
                                      setServiceReqParticularValue(
                                        "assigned_to",
                                        "name",
                                        nameId.toString(),
                                        "object"
                                      );
                                    }}
                                    onBlur={(e) => {
                                      setServiceReqParticularValidationValue(
                                        "assigned_to",
                                        "name",
                                        NonEmptyValidation(e.target.value),
                                        "object"
                                      );
                                    }}
                                  >
                                    <option value="selectmembername">
                                      ---Select Member Name---
                                    </option>

                                    {allSubadmin?.map((e, i) => (
                                      // <option value={e._id}>{e.name}</option>
                                      <option  value={e.name}>
                                        {e.name}
                                      </option>
                                    ))}
                                  </select>
                                  <small classdeadlineDate="text-danger">
                                    <small className="text-danger">
                                      {propertyReqValidation?.assigned_to?.name
                                        ?.message
                                        ? `Assigned to ${propertyReqValidation?.assigned_to?.name?.message}`
                                        : ""}
                                    </small>
                                  </small>
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <button
                                  type="button"
                                  className="btn login-btn ms-2 "
                                  onClick={(e) => {
                                    handleSubmit(e);
                                  }}
                                >
                                  Save
                                </button>
                              </div>
                              <>
                                {/* {propertyReqParticular?.length && state?.auth?.auth?.role==="user" ? (<> */}
                                {propertyReqParticular?.comments?.map(
                                  (e, i) => (
                                    <>
                                      <div
                                        key={i}
                                        className="send-pro-bg mt-4"
                                        style={{
                                          border: "1px solid black",
                                          borderRadius: "8px",
                                          marginBottom: "8px",
                                        }}
                                      >
                                        <div className="d-flex align-items-center justify-content-between">
                                          <div className="d-flex   align-items-center">
                                            <div className="flex-shrink-0 ">
                                              {e?.profileImage ? (
                                                <img
                                                  className="admin-pro-img "
                                                  style={{
                                                    width: "50px",
                                                    height: "50px",
                                                  }}
                                                  src={e?.profileImage}
                                                  alt="..."
                                                />
                                              ) : (
                                                <span className="material-symbols-rounded filled-icon dash-icon">
                                                  person
                                                </span>
                                              )}
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                              <h6 className="fw-bold">
                                                {e?.name}
                                              </h6>
                                              <p className="mb-0">{e?.role}</p>
                                            </div>
                                          </div>
                                          <div>
                                            <p className="mb-0">
                                              Asked{" "}
                                              {moment(e?.createdAt).fromNow()}
                                            </p>
                                          </div>
                                        </div>
                                        <p className="mb-0 mt-3 pl-send-pro-bg">
                                          {e.comments}
                                        </p>
                                      </div>
                                    </>
                                  )
                                )}
                                {state?.auth?.auth?.role === "subadmin" ||
                                state?.auth?.auth?.role === "superadmin" ||
                                state?.auth?.auth?.role === "admin" ? (
                                  <>
                                    <div className="input-group flex-nowrap  my-3">
                                      <input
                                        style={{
                                          border: "1px solid #c9c8c8",
                                          backgroundColor: "transparent",
                                          borderRight: "0px",
                                        }}
                                        type="text"
                                        className="form-control"
                                        placeholder="Your Message"
                                        aria-invalid={Boolean(
                                          commentsValidation?.comments
                                            ?.status === false
                                        )}
                                        defaultValue={leadComment?.comments}
                                        onChange={(e) => {
                                          setCommentsValue(
                                            "comments",
                                            e.target.value
                                          );
                                        }}
                                        onBlur={(e) => {
                                          setCommentsLeadValidationValue(
                                            "comments",

                                            NonEmptyValidation(e.target.value)
                                          );
                                        }}
                                      />
                                      <span
                                        className="input-group-text"
                                        id="addon-wrapping"
                                        style={{
                                          border: "1px solid #c9c8c8",
                                          backgroundColor: "transparent",
                                          borderLeft: "0px",
                                        }}
                                      >
                                        <button
                                          className="btn py-1  login-btn"
                                          type="button"
                                          id="button-addon2"
                                          onClick={(e) => {
                                            handleSubmitComment(e);
                                          }}
                                        >
                                          Send
                                          <i className="fa-solid fa-paper-plane ms-2"></i>
                                        </button>
                                      </span>
                                    </div>
                                    <small className="text-danger">
                                      {commentsValidation?.comments?.message
                                        ? `comments ${commentsValidation?.comments?.message}`
                                        : ""}
                                    </small>
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="container py-4">
                    <div className="row">
                      <div className="col-lg-12 ps-rel">
                        <div className="col-lg-12">
                          <div
                            className="p-3"
                            style={{ border: "1px solid black" }}
                          >
                            <div className="small-banner-img-head">
                              <img
                                style={{ width: "100%", height: "200px" }}
                                // className="w-100 h-auto"
                                src={
                                  propertyReqParticular?.property_id
                                    ?.cover_image
                                }
                                alt=""
                              />
                            </div>
                            <div className="mt-3">
                              <h6 className="color-maroon fw-bold">
                                {
                                  propertyReqParticular?.property_id
                                    ?.property_name
                                }
                              </h6>
                              <p className="small">
                                <span className="material-symbols-rounded align-middle me-1 filled-icon">
                                  location_on
                                </span>
                                {
                                  propertyReqParticular?.property_id
                                    ?.property_information.address
                                }
                              </p>

                              <p className="small">
                                <b>Room:</b>{" "}
                                {
                                  propertyReqParticular?.property_id
                                    ?.property_information.room_size
                                }
                                BHK
                              </p>
                              <p className="small">
                                <b>SqFt :</b>{" "}
                                {
                                  propertyReqParticular?.property_id
                                    ?.property_information.sq_ft
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                        {propertyReqParticular?.status === "Assigned" ? (
                          <>
                            {/* {propertyReqParticular?.length && state?.auth?.auth?.role==="user" ? (<> */}
                            {propertyReqParticular?.comments?.map((e, i) => (
                              <>
                                <div
                                  key={i}
                                  className="send-pro-bg"
                                  style={{
                                    border: "1px solid black",
                                    borderRadius: "8px",
                                    marginBottom: "8px",
                                  }}
                                >
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex   align-items-center">
                                      <div className="flex-shrink-0 ">
                                        {e.profileImage ? (
                                          <img
                                            className="admin-pro-img "
                                            style={{
                                              width: "50px",
                                              height: "50px",
                                            }}
                                            src={e.profileImage}
                                            alt="..."
                                          />
                                        ) : (
                                          <span className="material-symbols-rounded filled-icon dash-icon">
                                            person
                                          </span>
                                        )}
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h6 className="fw-bold">{e.name}</h6>
                                        <p className="mb-0">{e.role}</p>
                                      </div>
                                    </div>
                                    <div>
                                      <p className="mb-0">
                                        Asked {moment(e.createdAt).fromNow()}
                                      </p>
                                    </div>
                                  </div>
                                  <p className="mb-0 mt-3 pl-send-pro-bg">
                                    {e.comments}
                                  </p>
                                </div>
                              </>
                            ))}
                            {(propertyReqParticular?.comments?.length &&
                              state?.auth?.auth?.role === "user") ||
                            state?.auth?.auth?.role === "subadmin" ||
                            state?.auth?.auth?.role === "superadmin" ||
                            state?.auth?.auth?.role === "admin" ? (
                              <>
                                <div className="input-group flex-nowrap  my-3">
                                  <input
                                    style={{
                                      border: "1px solid #c9c8c8",
                                      backgroundColor: "transparent",
                                      borderRight: "0px",
                                    }}
                                    type="text"
                                    className="form-control"
                                    placeholder="Your Message"
                                    aria-invalid={Boolean(
                                      commentsValidation?.comments?.status ===
                                        false
                                    )}
                                    defaultValue={leadComment?.comments}
                                    onChange={(e) => {
                                      setCommentsValue(
                                        "comments",
                                        e.target.value
                                      );
                                    }}
                                    onBlur={(e) => {
                                      setCommentsLeadValidationValue(
                                        "comments",

                                        NonEmptyValidation(e.target.value)
                                      );
                                    }}
                                  />
                                  <span
                                    className="input-group-text"
                                    id="addon-wrapping"
                                    style={{
                                      border: "1px solid #c9c8c8",
                                      backgroundColor: "transparent",
                                      borderLeft: "0px",
                                    }}
                                  >
                                    <button
                                      className="btn py-1  login-btn"
                                      type="button"
                                      id="button-addon2"
                                      onClick={(e) => {
                                        handleSubmitComment(e);
                                      }}
                                    >
                                      Send
                                      <i className="fa-solid fa-paper-plane ms-2"></i>
                                    </button>
                                  </span>
                                </div>
                                <small className="text-danger">
                                  {commentsValidation?.comments?.message
                                    ? `comments ${commentsValidation?.comments?.message}`
                                    : ""}
                                </small>
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* ))} */}
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
