import React, { useEffect } from "react";
import Footer from "../Components/Footer/Footer";
import Navigation from "../Components/Navbar/Navigation";
import ApartmentBanner from "../Components/Property/ApartmentBanner";
import ApartmentSpecific from "../Components/Property/ApartmentSpecific";
export default function ApartmentDetails() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);


  return (
    <div className="innerpages-nav">
      <div id="container" >
        <Navigation />
        <ApartmentBanner />
        <ApartmentSpecific />
        <Footer />
      </div>
    </div>
  );
}
