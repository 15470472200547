import { useEffect, useState } from "react";
import CreateLeadComponent from "../../../../Components/v1/FreelancerPartner/Leads/CreateLeadComponent";
import FreelancerPartnerNav from "../../../../Components/v1/NavBar/FreelancerPartnerNav";


export default function CreateLead({ userTypeProps, lableProps, isClientProps, routeProps }) {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  const [show, setShow] = useState(true);
  const handleToggle = () => {setShow(!show);};


  return (
    <>
      <FreelancerPartnerNav showFullNav={show} onToggle={handleToggle} userTypeProps={userTypeProps} routeProps={routeProps} />
      <CreateLeadComponent showFullNav={show} lableProps={lableProps} userTypeProps={userTypeProps} isClientProps={isClientProps} routeProps={routeProps}/>
    </>
  );
}
