import React, { Fragment, useEffect, useRef, useState } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";

import { useSelector } from "react-redux";
import {
  GetMain,
  GetSpecificTask,
  GetUser,
  UpdateMyTaskRequest,
  UpdateTaskAdmin,
  UploadDocument,
} from "../../../Api/Api";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { useNavigate, useParams } from "react-router-dom";
import {
  NonEmptyValidation,
  NonEmptyValidationForDate,
  ExpireDateValidation,
} from "../../../Store/validate";
import { GetAllSubAdminAndAdmin } from "../../../Api/Api";
import CustomModal from "../../../Components/CustomModal";
let htmlToDraft = null;
if (typeof window === "object") {
  htmlToDraft = require("html-to-draftjs").default;
}

export default function TaskEdit() {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const { id } = useParams();
  useEffect(() => {
    
    GetAllSubadmins();
    getSpecificUser();
    GetMyTsksMain("active");
    GetMySpecificTsksRC();
  }, []);
   
  useEffect(() => {
    GetTaskEditSpecificTsks();
  }, [id])
  
  const CeoRef = useRef();
  const [taskEdit, setTaskEdit] = useState({});
  const [taskEditValidation, setTaskEditValidation] = useState({});

  const setTaskEditValue = (key, value) => {
    setTaskEdit({ ...taskEdit, [key]: value });
    if (taskEditValidation[key]) delete taskEditValidation[key];
  };

  const setTaskEditValidationValue = (key, value) => {
    setTaskEditValidation({ ...taskEditValidation, [key]: value });
  };
  const GetTaskEditSpecificTsks = async () => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await GetSpecificTask(token, id);
    if (data && data.statusCode === 200) {
      let dummy = data.data;
      let cmsDescription = EditorState.createEmpty();
      if (dummy.taskDescription) {
        let blocksFromHtml = htmlToDraft(dummy.taskDescription);
        let { contentBlocks, entityMap } = blocksFromHtml;
        let contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        cmsDescription = EditorState.createWithContent(contentState);
      }
      dummy.taskDescription = cmsDescription;

      setTaskEdit(dummy);
      setLoading(false);
    } else {
      setTaskEdit([]);
      setLoading(false);
      //console.log("Error while getting my specific task");
    }
  };
  const [taskEditrc, setTaskEditRC] = useState({});

  const GetMySpecificTsksRC = async () => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await GetSpecificTask(token, id);
    if (data && data.statusCode === 200) {
      let dummy = data.data;
      let cmsDescription = EditorState.createEmpty();
      if (dummy.taskDescription) {
        let blocksFromHtml = htmlToDraft(dummy.taskDescription);
        let { contentBlocks, entityMap } = blocksFromHtml;
        let contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        cmsDescription = EditorState.createWithContent(contentState);
      }
      // dummy.taskDescription = cmsDescription;
      // let cmsDescription1 = EditorState.createEmpty();
      // if (dummy.resolutionComment) {
      //   let blocksFromHtml = htmlToDraft(dummy.resolutionComment);
      //   let { contentBlocks, entityMap } = blocksFromHtml;
      //   let contentState = ContentState.createFromBlockArray(
      //     contentBlocks,
      //     entityMap
      //   );
      //   cmsDescription1 = EditorState.createWithContent(contentState);
      // }
      // dummy.resolutionComment = cmsDescription1;
      //console.log(dummy);
      setTaskEditRC(dummy);
      setLoading(false);
    } else {
      setTaskEditRC([]);
      setLoading(false);
      //console.log("Error while getting my specific task");
    }
  };
  //console.log(taskEditrc);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleEditorChange = (state) => {
    setEditorState(state);
    let isNotEmpty = state && state.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(convertToRaw(state.getCurrentContent()));
      setTaskEditValue("comments", html);
    }
  };

  const [editorState1, setEditorState1] = useState(() =>
    EditorState.createEmpty()
  );

  const handleEditorChange1 = (state) => {
    setEditorState1(state);
    let isNotEmpty = state && state.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(convertToRaw(state.getCurrentContent()));
      setTaskEditValue("resolutionComment", html);
    }
  };

  const [getMyTaskMain, setGetMyTaskMain] = useState([]);

  const GetMyTsksMain = async (status) => {
    setLoading(true);
    // const token = state.auth.auth.token;
    let data = await GetMain(status);
    if (data && data.statusCode === 200) {
      const numAscending = [...data.data]; //.sort(
      //(a, b) => b.displayOrder < a.displayOrder
      //);
      setGetMyTaskMain(numAscending);
      setLoading(false);
    } else {
      setGetMyTaskMain([]);
      setLoading(false);
      //console.log("Error while getting my task");
    }
  };

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  const [allSubadmin, setAllSubadmin] = useState([]);
  const GetAllSubadmins = async () => {
    setLoading(true);
    const token = state?.auth?.auth?.token;
    let data = await GetAllSubAdminAndAdmin(token);
    if (data && data.statusCode === 200) {
      setAllSubadmin(data.data);
      setLoading(false);
    } else {
      setAllSubadmin([]);
      setLoading(false);
      //console.log("Error while getting all subadmins");
    }
  };

  const [reassign, setReassign] = useState(true);
  const [assignId, setassignId] = useState(false);
  const [imageBig, setImageBig] = useState(false);

  const uploadCeoPic = async (e) => {
    const file = e.target.files[0];
    if (file) {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("fileName", e.target.files[0].name);
      formData.append("path", "Image/");

      let response = await UploadDocument(formData);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        const data = response.data;

        if (taskEdit?.fileUpload?.length) {
          let scheduleData = taskEdit?.fileUpload;
          scheduleData.push(data);
          setTaskEditValue("fileUpload", scheduleData);
        } else {
          setTaskEditValue("fileUpload", [data]);
        }
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.taskType = NonEmptyValidation(taskEdit?.taskType);
    validate.taskName = NonEmptyValidation(taskEdit?.taskName);
    // validate.taskDescription = NonEmptyValidation(taskEdit?.taskDescription);
    validate.startDate = NonEmptyValidationForDate(taskEdit?.startDate);
    validate.deadlineDate = ExpireDateValidation(
      taskEdit?.startDate,
      taskEdit?.deadlineDate
    );
    // validate.assignedTo = NonEmptyValidation(taskEdit?.assignedTo);

    setTaskEditValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      let html;
      // let html1;
      let Payload;

      html = draftToHtml(
        convertToRaw(taskEdit.taskDescription.getCurrentContent())
      );
      // html1 = draftToHtml(
      //   convertToRaw(taskEdit.resolutionComment.getCurrentContent())
      // );
      Payload = {
        _id: taskEdit._id,
        taskType: taskEdit.taskType,
        taskName: taskEdit.taskName,
        taskDescription: html,
        // resolutionComment: html1,
        startDate: taskEdit.startDate,
        deadlineDate: taskEdit.deadlineDate,
        assignedTo:
          assignId === true ? taskEdit.assignedTo : taskEdit.assignedTo._id,
        fileUpload: taskEdit.fileUpload,
        creator_id: taskEdit?.creator_id?._id,
        status: taskEdit?.status,
      };
      const token = state?.auth?.auth?.token;
      const response = await UpdateTaskAdmin(Payload, token);
      if (response && response.statusCode === 200) {
        if (taskEdit?.status === "Completed") {
        } else {
          showToastSuccessMsg(response.message);
        }

        if (taskEdit?.status === "Completed") {
          setOpen(true);
        }
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } else {
      //console.log("Error while update user details");
    }
  };

  const [specificUser, setSpecificUser] = useState([]);

  const getSpecificUser = async () => {
    const token = state?.auth?.auth?.token;
    // const id = state?.auth?.auth?._id;
    const data = await GetUser(token, taskEdit?.assignedTo?._id);
    if (data && data.statusCode === 200) {
      setSpecificUser(data?.data);
    } else {
      setSpecificUser([]);
      //console.log("Error while getting user details");
    }
  };

  // const [comment,setComment] = useState("")
  const handleSubmitComment = async (e) => {
    e.preventDefault();

    let validate = {};
    validate.comments = NonEmptyValidation(taskEdit?.comments);
    setTaskEditValidation(validate);

    if (Object.values(validate).every((v) => v.status === true)) {
      let Payload = {
        _id: id,
        comments: taskEdit?.comments,
      };

      const token = state.auth.auth.token;

      try {
        const response = await UpdateTaskAdmin(Payload, token);

        if (response.statusCode === 200) {
          showToastSuccessMsg(response.message);
          GetTaskEditSpecificTsks();
          setTaskEditValue("comments", "");
          window.location.reload();
          e.target.reset();
        } else {
          showToastErrorMsg(
            response.message || response.error.response.data.message
          );
          // setfailureModal(true);
        }
      } catch (error) {
        //console.log("Error while updating task details:", error);
      }
    } else {
      //console.log("Error while updating task details");
    }
  };

  const handleSubmitUpdateComplete = async (e, value, id) => {
    e.preventDefault();
    const Payload = {
      _id: id,
      resolutionComment: value,
    };
    const token = state?.auth?.auth?.token;

    const response = await UpdateMyTaskRequest(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      setOpen(false);
      window.location.reload();
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setfailureModal(true);
    }
  };

  return (
    <div>
      <AdminNavbar />
      <ToastContainer />
      <div className="main">
        <div>
          <div className="">
            <button
              type="button"
              className="btn login-btn ms-2 "
              onClick={(e) => {
                navigate("/TaskList");
              }}
            >
              Back
            </button>
          </div>
          <div className=" my-4">
            <div className="bg-historytablehead rounded-3 py-3 px-3">
              <h6 className="mb-0">Update Task</h6>
            </div>
            <div className="container">
              <div className="row justify-content-center py-5">
                <div className="col-lg-11">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="mb-4">
                        <label className="form-label">Task Name</label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Enter the Task Name"
                          aria-invalid={Boolean(
                            taskEditValidation?.taskType?.status === false
                          )}
                          defaultValue={taskEdit?.taskType}
                          onChange={(e) => {
                            setTaskEditValue("taskType", e.target.value);
                          }}
                          onBlur={(e) => {
                            setTaskEditValidationValue(
                              "taskType",
                              NonEmptyValidation(e.target.value)
                            );
                          }}
                          autoComplete="off"
                        />
                        <small className="text-danger">
                          {taskEditValidation?.taskType?.message
                            ? `Task name ${taskEditValidation?.taskType?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-4">
                        <label for="services" className="form-label">
                          Department
                        </label>
                        <select
                          className="form-control"
                          value={taskEdit?.taskName}
                          onChange={(e) => {
                            setTaskEditValue("taskName", e.target.value);
                          }}
                          onBlur={(e) => {
                            setTaskEditValidationValue(
                              "taskName",
                              NonEmptyValidation(e.target.value)
                            );
                          }}
                        >
                          <option value="Audit">Audit</option>
                          <option value="Legal">Legal</option>
                          <option value="Man Power">Man Power</option>
                          <option value="Buy/Selling">Buy/Selling</option>
                          <option value="In House">In House</option>
                          <option value="Other Services">Other Services</option>

                          {/* {getMyTaskMain?.map((e)=>(
                              <option value={e.title}>{e.title}</option>
                             ))}    */}
                        </select>
                        <small className="text-danger">
                          {taskEditValidation?.taskName?.message
                            ? `Service type ${taskEditValidation?.taskName?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-4">
                        <label className="form-label">Task Description</label>
                        <div
                          className="form-control form-control-lg"
                          style={{ height: "250px" }}
                        >
                          <Editor
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            editorState={taskEdit?.taskDescription}
                            onEditorStateChange={(e) =>
                              setTaskEditValue("taskDescription", e)
                            }
                          />
                        </div>
                        {/* <textarea
                                rows={6}
                                className="form-control form-control-lg"
                                aria-invalid={Boolean(
                                  taskEditValidation?.taskDescription?.status ===
                                    false
                                )}
                                value={taskEdit?.taskDescription}
                                onChange={(e) => {
                                  setTaskEditValue(
                                    "taskDescription",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setTaskEditValidationValue(
                                    "taskDescription",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              /> */}
                        <small classtaskDescription="text-danger">
                          {taskEditValidation?.taskDescription?.message
                            ? `Task description ${taskEditValidation?.taskDescription?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label">Start Date</label>
                        <input
                          type="date"
                          className="form-control form-control-lg"
                          placeholder="select the  start date"
                          aria-invalid={Boolean(
                            taskEditValidation?.startDate?.status === false
                          )}
                          value={moment(taskEdit?.startDate).format(
                            "YYYY-MM-DD"
                          )}
                          onChange={(e) => {
                            setTaskEditValue("startDate", e.target.value);
                          }}
                          onBlur={(e) => {
                            setTaskEditValidationValue(
                              "startDate",
                              NonEmptyValidationForDate(e.target.value)
                            );
                          }}
                          disabled
                          autoComplete="off"
                        />
                        <small classstartDate="text-danger">
                          {taskEditValidation?.startDate?.message
                            ? `Start date ${taskEditValidation?.startDate?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label">Deadline Date</label>
                        <input
                          type="date"
                          className="form-control form-control-lg"
                          placeholder="select the Deadline Date"
                          aria-invalid={Boolean(
                            taskEditValidation?.deadlineDate?.status === false
                          )}
                          value={moment(taskEdit?.deadlineDate).format(
                            "YYYY-MM-DD"
                          )}
                          onChange={(e) => {
                            setTaskEditValue("deadlineDate", e.target.value);
                          }}
                          onBlur={(e) => {
                            setTaskEditValidationValue(
                              "deadlineDate",
                              ExpireDateValidation(
                                taskEdit?.startDate,
                                e.target.value
                              )
                            );
                          }}
                          disabled
                          autoComplete="off"
                        />
                        <small classdeadlineDate="text-danger">
                          {taskEditValidation?.deadlineDate?.message
                            ? `Deadline date ${taskEditValidation?.deadlineDate?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label ">Assigned To</label>

                        <select
                          className="form-control form-control-lg"
                          value={taskEdit?.assignedTo?.name}
                          onChange={(e) => {
                            setassignId(true);
                            let name = allSubadmin?.filter(
                              (s) => s.name === e.target.value
                            );
                            let nameId = name?.map((n) => {
                              return n._id;
                            });

                            setTaskEditValue("assignedTo", nameId.toString());
                          }}
                          onBlur={(e) => {
                            let name = allSubadmin?.filter(
                              (s) => s.name === e.target.value
                            );
                            let nameId = name?.map((n) => {
                              return n._id;
                            });
                            setTaskEditValidationValue(
                              "assignedTo",
                              NonEmptyValidation(nameId.toString())
                            );
                          }}
                          disabled={reassign === true}
                        >
                          {allSubadmin?.map((e) => (
                            <option value={e.name}>{e.name}</option>
                          ))}
                        </select>

                        <small classdeadlineDate="text-danger">
                          {taskEditValidation?.assignedTo?.message
                            ? `Member name ${taskEditValidation?.assignedTo?.message}`
                            : ""}
                        </small>
                        <button
                          className="btn btn-sm btn-warning mt-2"
                          onClick={() => {
                            setReassign(false);
                          }}
                        >
                          Reassign
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-6 my">
                      <div className="mb-3">
                        <label htmlFor="fileUpload" className="form-label">
                          Select File
                        </label>

                        <input
                          type="file"
                          className="form-control"
                          id="file"
                          ref={CeoRef}
                          onChange={(e) => {
                            uploadCeoPic(e);
                          }}
                        />
                        {taskEdit?.fileUpload?.map((e, index) => {
                          const url = e;
                          // Decode the URL to get the actual filename
                          const decodedUrl = decodeURIComponent(url);
                          // Split the decoded URL using '/' to get the segments of the URL
                          const urlSegments = decodedUrl.split("/");
                          // The last segment should be the filename
                          const filename = urlSegments[urlSegments.length - 1];

                          return (
                            <Fragment key={e}>
                              <a
                                style={{ color: "black" }}
                                href={e}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {index + 1} {filename}
                              </a>
                              <br />
                            </Fragment>
                          );
                        })}
                        {/* {taskEdit?.fileUpload?.length ? (
                        <>
                        {taskEdit?.fileUpload?.map((img)=>(
                        <a href={img} target="_blank">{img?.substring.substring(img?.lastIndexOf("/") + 1)}</a>
                        ))}
                        </>
                       
                       ):""} */}
                      </div>
                    </div>
                    <div className="col-lg-6 my">
                      <div className="mb-4">
                        <label className="form-label ">Status</label>

                        <select
                          className="form-control form-control-lg"
                          value={taskEdit?.status}
                          onChange={(e) => {
                            setTaskEditValue("status", e.target.value);
                          }}
                          onBlur={(e) => {
                            setTaskEditValidationValue(
                              "status",
                              NonEmptyValidation(e.target.value)
                            );
                          }}
                        >
                          <option value="Todo">To Do</option>
                          <option value="Inprogress">In Progress</option>
                          <option value="Completed">Completed</option>
                          <option value="Hold">Hold</option>
                        </select>
                        <small className="text-danger">
                          {taskEditValidation?.status?.message
                            ? `Status ${taskEditValidation?.status?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                    {taskEditrc?.resolutionComment &&
                    taskEditrc?.status === "Completed" ? (
                      <div className="col-lg-12">
                        <div className="mb-4">
                          <label className="form-label">
                            Resolution Comment
                          </label>
                          <div
                            className="form-control form-control-lg"
                            style={{
                              height: "250px",
                              border: "4px solid maroon",
                            }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: taskEditrc?.resolutionComment,
                              }}
                            ></div>
                          </div>
                          {/* <div
                            className="form-control form-control-lg"
                            style={{
                              height: "250px",
                              border: "4px solid maroon",
                            }}
                          >
                            <Editor
                              wrapperClassName="wrapper-class"
                              editorClassName="editor-class"
                              toolbarClassName="toolbar-class"
                              editorState={taskEditrc?.resolutionComment}
                              disabled
                            />
                          </div> */}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="col-lg-12">
                      {/* <button
                        type="button"
                        className="btn white-btn me-2 "
                        onClick={() => {
                          setTaskEdit({});
                          navigate("/TaskList");
                        }}
                      >
                        Cancel
                      </button> */}
                      <button
                        type="button"
                        className="btn login-btn ms-2 "
                        onClick={(e) => {
                          handleSubmit(e);
                        }}
                      >
                        Save
                      </button>
                    </div>

                    <>
                      {/* {serviceReqParticular?.length && state?.auth?.auth?.role==="user" ? (<> */}
                      {taskEdit?.commentsTo?.map((e, i) => (
                        <>
                          <div
                            key={i}
                            className="send-pro-bg mt-4"
                            style={{
                              border: "1px solid black",
                              borderRadius: "8px",
                              marginBottom: "8px",
                            }}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="d-flex   align-items-center">
                                <div className="flex-shrink-0 ">
                                  {e?.profileImage ? (
                                    <img
                                      className="admin-pro-img "
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                      }}
                                      src={e?.profileImage}
                                      alt="..."
                                    />
                                  ) : (
                                    <span className="material-symbols-rounded filled-icon dash-icon">
                                      person
                                    </span>
                                  )}
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <h6 className="fw-bold">{e?.name}</h6>
                                  <p className="mb-0">{e?.Designation}</p>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                  Asked {moment(e.createdAt).fromNow()}
                                </p>
                              </div>
                            </div>
                            <p
                              className="mb-0 mt-3 pl-send-pro-bg"
                              dangerouslySetInnerHTML={{
                                __html: e?.comments,
                              }}
                            ></p>
                          </div>
                        </>
                      ))}
                      {(taskEdit?.comments?.length &&
                        state?.auth?.auth?.role === "user") ||
                      state?.auth?.auth?.role === "subadmin" ||
                      state?.auth?.auth?.role === "superadmin" ||
                      state?.auth?.auth?.role === "admin" ? (
                        <>
                          <div className="input-group flex-nowrap  my-3">
                            <div
                              className="form-control form-control-lg"
                              style={{ height: "200px" }}
                            >
                              <Editor
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbarClassName="toolbar-class"
                                editorState={editorState}
                                onEditorStateChange={(e) =>
                                  handleEditorChange(e)
                                }
                              />
                            </div>
                            {/* <input
                              style={{
                                border: "1px solid #c9c8c8",
                                backgroundColor: "transparent",
                                borderRight: "0px",
                              }}
                              type="text"
                              className="form-control"
                              placeholder="Your Message"
                              aria-label="comments"
                              defaultValue={taskEdit?.comments}
                              onChange={(e) => {
                                setTaskEditValue("comments", e.target.value);
                              }}
                              aria-describedby="addon-wrapping"
                            /> */}
                            <span
                              className="input-group-text"
                              id="addon-wrapping"
                              style={{
                                border: "1px solid #c9c8c8",
                                backgroundColor: "transparent",
                                borderLeft: "0px",
                              }}
                            >
                              <button
                                className="btn py-1  login-btn"
                                type="button"
                                id="button-addon2"
                                onClick={(e) => {
                                  handleSubmitComment(e);
                                }}
                              >
                                Send
                                <i className="fa-solid fa-paper-plane ms-2"></i>
                              </button>
                            </span>
                          </div>
                          <small className="text-danger">
                            {taskEditValidation?.comments?.message
                              ? `comments ${taskEditValidation?.comments?.message}`
                              : ""}
                          </small>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          open={open}
          onClickOutside={() => {
            setOpen(false);
          }}
        >
          <div className="modal-content w-50 m-auto">
            <div className="modal-body p-0 ">
              <div className="container">
                <span
                  className="material-symbols-rounded filled-icon close-icon"
                  style={{ textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px" }}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  close
                </span>
                <div className="row d-flex align-items-center justify-content-center  mt-0">
                  <div className="container ">
                    <div className="row justify-content-center">
                      <div className="col-lg-12">
                        <div className=" my-5 px-3">
                          <div className="row justify-content-center mt-2">
                            <div
                              className=""
                              style={{
                                marginBottom: 3,
                                fontSize: "1.05rem",
                              }}
                            >
                              Resolution Comment
                            </div>
                            <div
                              className="form-control form-control-lg"
                              style={{ height: "250px" }}
                            >
                              <Editor
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbarClassName="toolbar-class"
                                editorState={editorState1}
                                onEditorStateChange={(e) =>
                                  handleEditorChange1(e)
                                }
                              />
                            </div>
                            <div className="col-6 text-center">
                              <button
                                className="btn btn-admin mt-3"
                                onClick={(e) =>
                                  handleSubmitUpdateComplete(
                                    e,
                                    taskEdit?.resolutionComment,
                                    taskEdit._id
                                  )
                                }
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CustomModal>
      </div>
    </div>
  );
}
