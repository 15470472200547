import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './css/CreateTaskComponent.module.css';  // Adjust the path as necessary
import { useSelector } from 'react-redux';
import { createAPI, createLabelAPI, fetchLabelAPI, UploadDocument } from '../../../../Api/v1/Util/Api';
import { showErrorMsg, showSuccessMsg } from '../../../../Store/util';
import { createNewLeadAPI } from '../../../../Api/v1/FreelancerPartner/leads';
import { useNavigate, useParams } from 'react-router-dom';
import { task_create } from '../../../../Api/APIUrl';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function CreateTaskComponent({showFullNav,routeProps,lableProps,userTypeProps,isClient}) {
    const navigate = useNavigate();
    const state = useSelector((state) => state);
    const { id } = useParams();

    const initialTaskState = {
        reference_id: id,
        assign_to:state.auth.auth._id,
        title: '',
        description: '',
        points: 1,
        collaborators: [],
        status: 'To do',
        start_date:'',
        end_date:'',
        priority: '',
        document: '',
    };

    const [task, setTask] = useState(initialTaskState);
    const [errors, setErrors] = useState({});
    const [statuses] = useState([
        { value: 'To do', label: 'To do' },
        { value: 'Inprogress', label: 'Inprogress' },
        { value: 'Hold', label: 'Hold' },
        { value: 'Done', label: 'Done' }
    ]);
    const [priorities] = useState([
        { value: 'Low', label: 'Low' },
        { value: 'Medium', label: 'Medium' },
        { value: 'Priority', label: 'Priority' }
    ]);
    const [allLabels, setAllLabels] = useState([]);

    useEffect(() => {
        // Fetch labels on component mount
        handleFetchLabels();
    }, []);

    const handleInputChange = (e, field) => {
        setTask({ ...task, [field]: e.target.value });
    };

    const handleSelectChange = (selectedOption, field) => {
        setTask({ ...task, [field]: selectedOption });
    };

    const handleCreateOption = async (inputValue) => {
        try {
            const response = await createLabelAPI(state.auth.auth.token, { title: inputValue, label_type: 'tasks' });
            setAllLabels([...allLabels, { value: response.data.label._id, label: inputValue }]);
        } catch (error) {
            console.error('Error creating label:', error);
        }
    };

    const handleFetchLabels = async () => {
        try {
            const response = await fetchLabelAPI(state.auth.auth.token, 'tasks');
            setAllLabels(response.data.label.map(label => ({ value: label._id, label: label.title })));
        } catch (error) {
            console.error('Error fetching labels:', error);
        }
    };

    const handleDescriptionChange = (value) => {
        setTask({ ...task, description: value });
    };

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                const response = await UploadDocument(state.auth.auth.token, file);
                setTask({ ...task, document: response.url });
            } catch (error) {
                console.error('Error uploading document:', error);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Validate fields
        const newErrors = {};
        if (!task.title) newErrors.title = 'Title is required';
        if (!task.description) newErrors.description = 'Description is required';
        if (!task.start_date) newErrors.start_date = 'Start Date is required';
        if (!task.end_date) newErrors.end_date = 'End Date is required';
        // if (!task.status) newErrors.status = 'Status is required';
        if (!task.priority) newErrors.priority = 'Priority is required';
        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) return;
        if(task.priority.value)
            task.priority = task.priority.value;
        // Submit form data
        const response = await createAPI(state.auth.auth.token,task_create, task);
        if (response && response.statusCode === 200) {
            showSuccessMsg(response.message);
            setTask(initialTaskState);
            navigate(`/${userTypeProps}/${routeProps}/view-${routeProps}/${id}`);
        } else {
            showErrorMsg(response.message || response.error.response.data.message);
        }
    };

    return (
        <form className={styles.taskForm} onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-md-10">
                    <h2>Create Task </h2>
                </div>
                <div className="col-md-2">
                    <button onClick={()=>{navigate(`/${userTypeProps}/${routeProps}/view-${routeProps}/${id}`);}} className='btn btn-primary' style={{float:'right'}}>
                        <ArrowBackIcon />
                    </button>
                </div>
                </div>
            <div className="row">
                <div className="col-md-5">
                    <div className={styles.formGroup}>
                        <div className={styles.inputContainer}>
                            <input
                                type="text"
                                id="title"
                                value={task.title}
                                onChange={(e) => handleInputChange(e, 'title')}
                                className={`${styles.input} ${errors.title ? styles.errorInput : ''}`}
                                placeholder=" "
                            />
                            <label htmlFor="title" className={`${styles.placeholder} ${task.title ? styles.filled : ''}`}>
                                Title
                            </label>
                        </div>
                        {errors.title && <span className={styles.error}>{errors.title}</span>}
                    </div>
                </div>
                {/* <div className="col-md-4">
                    <div className={styles.formGroup}>
                        <label htmlFor="status" className={`${styles.placeholder} ${task.status ? styles.filled : ''}`}>
                            Status
                        </label>
                        <Select
                            id="status"
                            value={statuses.find(status => status.value === task.status)}
                            onChange={(selectedOption) => handleSelectChange(selectedOption, 'status')}
                            options={statuses}
                            placeholder="Select Status"
                        />
                        {errors.status && <span className={styles.error}>{errors.status}</span>}
                    </div>
                </div> */}
                <div className="col-md-5">
                    <div className={styles.formGroup}>
                        <label htmlFor="priority" className={`${styles.placeholder} ${task.priority ? styles.filled : ''}`}>
                            Priority
                        </label>
                        <Select
                            id="priority"
                            value={priorities.find(priority => priority.value === task.priority)}
                            onChange={(selectedOption) => handleSelectChange(selectedOption, 'priority')}
                            options={priorities}
                            placeholder="Select Priority"
                            styles={{zIndex: 1}}
                        />
                        {errors.priority && <span className={styles.error}>{errors.priority}</span>}
                    </div>
                </div>
                <div className="col-md-5">
                    <div className={styles.formGroup}>
                        <div className={styles.inputContainer}>
                            <input
                                type="date"
                                id="start_date"
                                value={task.start_date}
                                onChange={(e) => handleInputChange(e, 'start_date')}
                                className={`${styles.input} ${errors.start_date ? styles.errorInput : ''}`}
                                placeholder=" "
                            />
                            <label htmlFor="start_date" className={`${styles.placeholder} ${task.start_date ? styles.filled : ''}`}>
                                Start Date
                            </label>
                        </div>
                        {errors.start_date && <span className={styles.error}>{errors.start_date}</span>}
                    </div>
                </div>
                <div className="col-md-5">
                    <div className={styles.formGroup}>
                        <div className={styles.inputContainer}>
                            <input
                                type="date"
                                id="end_date"
                                value={task.end_date}
                                onChange={(e) => handleInputChange(e, 'end_date')}
                                className={`${styles.input} ${errors.end_date ? styles.errorInput : ''}`}
                                placeholder=" "
                            />
                            <label htmlFor="end_date" className={`${styles.placeholder} ${task.end_date ? styles.filled : ''}`}>
                                End Date
                            </label>
                        </div>
                        {errors.end_date && <span className={styles.error}>{errors.end_date}</span>}
                    </div>
                </div>
                <div className="col-md-12">
                    <div className={styles.formGroup}>
                        <label htmlFor="description" className={`${styles.placeholder} ${task.description ? styles.filled : ''}`}>
                            Description
                        </label>
                        <ReactQuill
                            id="description"
                            value={task.description}
                            onChange={handleDescriptionChange}
                            style={{height:'200px'}}
                            className={` ${errors.description ? styles.errorInput : ''}`}
                        />
                        {errors.description && <span className={styles.error}>{errors.description}</span>}
                    </div>
                </div>
                <div className="col-md-12">
                    <div className={styles.formGroup} style={{marginTop:"50px"}}>
                        <label htmlFor="document" className={`${styles.placeholder} ${task.document ? styles.filled : ''}`}>
                            Document
                        </label>
                        <input
                            type="file"
                            id="document"
                            onChange={handleFileUpload}
                            className={`${errors.document ? styles.errorInput : ''}`}
                        />
                        {errors.document && <span className={styles.error}>{errors.document}</span>}
                    </div>
                </div>
            </div>
            <div className={styles.formGroup}>
                <button type="submit" className="btn btn-primary">Create Task</button>
                <button type="button" className="btn" onClick={() => { navigate(`/${userTypeProps}/${routeProps}/view-${routeProps}/${id}`); }}>Cancel</button>
            </div>
        </form>
    );
}
