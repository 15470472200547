import React, { useEffect, useRef, useState } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";

import { toast, ToastContainer } from "react-toastify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import {
  GetSpecificTask,
  GetUser,
  UserCreateMyTask,
  UpdateMyTaskRequest,
  GetServicemyRole,
  GetMyTaskSuperAdmin,
  GetAllSubAdminAndAdmin,
  GetMyTaskSuperAdminOverdue,
  UploadDocument,
  GetMain,
  GetMyTaskSuperAdminAll,
} from "../../../Api/Api";
import { useSelector } from "react-redux";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import {
  DateValidationn,
  NonEmptyValidation,
  NonEmptyValidationForDate,
} from "../../../Store/validate";
import { ExpireDateValidation } from "../../../Store/validate";
import CustomModal from "../../../Components/CustomModal";
import Spinner from "../../../Spinner/Spinner";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";

export default function TaskList() {
  const [selectedTaskListTab, setSelectedTaskListTab] = React.useState("All");
  const AllTabs = [
    "All",
    "To Do",
    "In Progress",
    "Completed",
    "Over Due",
    "Hold",
  ];
  const [newTaskList, setNewTaskList] = useState(true);

  const [specificTaskListModal, setSpecificTaskListModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [serviceTaskListReq, setServiceTaskListReq] = useState([]);

  const [getTaskList, setGetTaskList] = useState([]);
  const CeoRef = useRef();
  const state = useSelector((state) => state);

  const GetTsksList = async (status) => {
    setLoading(true);
    const token = state.auth.auth.token;
    // GetMyTaskSuperAdminAll
    if (status === "All") {
      let data = await GetMyTaskSuperAdminAll(token);
      if (data && data.statusCode === 200) {
        let temp = [];
        data.data.map((data, i) => {
          let dummy = {
            _id: data?._id,
            taskType: data?.taskType,
            assignedTo: data?.assignedTo?.name,
            creator_id: data?.creator_id?.name,
            startDate: moment(data?.startDate).format("DD/MM/YYYY"),
            status: data?.status,
            admin_read: data?.admin_read,
            subadmin_read: data?.subadmin_read,
            deadlineDate: moment(data?.deadlineDate).format("DD/MM/YYYY"),
          };
          temp.push(dummy);
          return null;
        });
        // setGetMyTask(temp);
        temp = temp.sort((a, b) => {
          const dateA = moment(a.startDate, "DD/MM/YYYY").toDate();
          const dateB = moment(b.startDate, "DD/MM/YYYY").toDate();

          return dateB - dateA; // Sort in descending order
        });

        setGetTaskList(temp);
        setLoading(false);
      } else {
        setGetTaskList([]);
        setLoading(false);
        //console.log("Error while getting my task");
      }
    } else {
      let data = await GetMyTaskSuperAdmin(token, status);
      if (data && data.statusCode === 200) {
        let temp = [];
        data.data.map((data, i) => {
          let dummy = {
            _id: data?._id,
            taskType: data?.taskType,
            assignedTo: data?.assignedTo?.name,
            creator_id: data?.creator_id?.name,
            startDate: moment(data?.startDate).format("DD/MM/YYYY"),
            status: data?.status,
            admin_read: data?.admin_read,
            subadmin_read: data?.subadmin_read,
            deadlineDate: moment(data?.deadlineDate).format("DD/MM/YYYY"),
          };
          temp.push(dummy);
          return null;
        });
        // setGetMyTask(temp);
        setGetTaskList(temp);
        setLoading(false);
      } else {
        setGetTaskList([]);
        setLoading(false);
        //console.log("Error while getting my task");
      }
    }
  };

  const GetMyTsksOverdue = async () => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await GetMyTaskSuperAdminOverdue(token);
    if (data && data.statusCode === 200) {
      let temp = [];
      data.data.map((data, i) => {
        let dummy = {
          _id: data?._id,
          taskType: data?.taskType,
          assignedTo: data?.assignedDetails,//assignedTo?.name,
          creator_id: data?.creatorDetails,//creator_id?.name,
          startDate: moment(data?.startDate).format("DD/MM/YYYY"),
          status: data?.status,
          admin_read: data?.admin_read,
          subadmin_read: data?.subadmin_read,
          deadlineDate: moment(data?.deadlineDate).format("DD/MM/YYYY"),
        };
        temp.push(dummy);
        return null;
      });
      // setGetMyTask(temp);
      setGetTaskList(temp);
      // setGetTaskList(data.data);
      setLoading(false);
    } else {
      setGetTaskList([]);
      setLoading(false);
      //console.log("Error while getting my task");
    }
  };
  const [specificTask, setSpecificTask] = useState([]);

  const [TaskListprofile, setTaskListProfile] = useState([]);
  async function fetchDataProfile() {
    setLoading(true);
    const id = state?.auth?.auth?._id;
    const token = state?.auth?.auth?.token;
    const getTaskBasicDetails = await GetUser(token, id);
    if (getTaskBasicDetails && getTaskBasicDetails?.statusCode === 200) {
      setTaskListProfile(getTaskBasicDetails.data);
      setLoading(false);
    } else {
      setTaskListProfile([]);
      setLoading(false);
      //console.log("error while getting user TaskListprofile info");
    }
  }

  //  const handleSubmitDelete = async (e, id, value) => {
  //    e.preventDefault();

  //    const token = state?.auth?.auth?.token;
  //    const response = await DeleteTasklist(id, token);
  //    if (response && response.statusCode === 200) {
  //      showToastSuccessMsg(response.message);

  //      GetTsksList(value);

  //      //  window.location.reload()
  //    } else {
  //      showToastErrorMsg(
  //        response.message || response.error.response.data.message
  //      );
  //      //setfailureModal(true);
  //    }
  //  };

  const [allTaskSubadmin, setTaskAllSubadmin] = useState([]);
  const GetAllTaskSubadmins = async () => {
    setLoading(true);
    const token = state?.auth?.auth?.token;
    let data = await GetAllSubAdminAndAdmin(token);
    if (data && data.statusCode === 200) {
      setTaskAllSubadmin(data.data);
      setLoading(false);
    } else {
      setTaskAllSubadmin([]);
      setLoading(false);
      //console.log("Error while getting all subadmins");
    }
  };

  let taskListassignName = allTaskSubadmin?.filter((e) => {
    return e._id === specificTask?.assignedTo;
  });

  useEffect(() => {
    if (state?.auth?.auth?.role === "superadmin") {
      getTaskListReq("assigned");
    } else {
      getTaskListReq();
    }

    GetAllTaskSubadmins();
  }, []);

  const getTaskListReq = async (status) => {
    setLoading(true);
    const role = state?.auth?.auth?.role;
    const token = state.auth.auth.token;
    let response;
    if (state?.auth?.auth?.role === "superadmin") {
      response = await GetServicemyRole(token, role, status);
    } else {
      response = await GetServicemyRole(token, role);
    }

    if (response.statusCode === 200) {
      setServiceTaskListReq(response.data);
      setLoading(false);
    } else {
      setServiceTaskListReq([]);
      setLoading(false);
      //console.log("error while getting user TaskListprofile info");
    }
  };

  useEffect(() => {
    GetTsksList("All");
    GetAllTaskSubadmins();
    fetchDataProfile();
    GetMyTsksListMain("active");
  }, []);

  const [myTaskList, setMyTaskList] = useState({});
  const [myTaskValidation, setMyTaskValidation] = useState({});

  const setMyTaskListValue = (key, value) => {
    setMyTaskList({ ...myTaskList, [key]: value });
    if (myTaskValidation[key]) delete myTaskValidation[key];
  };

  const setMyTaskListValidationValue = (key, value) => {
    setMyTaskValidation({ ...myTaskValidation, [key]: value });
  };

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 3000,
    });
  };

  const [getMyTaskMain, setGetMyTaskMain] = useState([]);

  const GetMyTsksListMain = async (status) => {
    setLoading(true);
    // const token = state.auth.auth.token;
    let data = await GetMain(status);
    if (data && data.statusCode === 200) {
      setGetMyTaskMain(data.data);
      setLoading(false);
    } else {
      setGetMyTaskMain([]);
      setLoading(false);
      //console.log("Error while getting my task");
    }
  };

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleEditorChange = (state) => {
    setEditorState(state);
    let isNotEmpty = state && state.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(convertToRaw(state.getCurrentContent()));
      setMyTaskListValue("taskDescription", html);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.taskType = NonEmptyValidation(myTaskList?.taskType);
    validate.taskName = NonEmptyValidation(myTaskList?.taskName);
    validate.taskDescription = NonEmptyValidation(myTaskList?.taskDescription);
    validate.startDate = NonEmptyValidationForDate(myTaskList?.startDate);
    validate.deadlineDate = ExpireDateValidation(
      myTaskList?.startDate,
      myTaskList?.deadlineDate
    );
    validate.assignedTo = NonEmptyValidation(myTaskList?.assignedTo);

    setMyTaskValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        taskType: myTaskList.taskType,
        taskName: myTaskList.taskName,
        taskDescription: myTaskList.taskDescription,
        startDate: myTaskList.startDate,
        deadlineDate: myTaskList.deadlineDate,
        assignedTo: myTaskList.assignedTo,
        fileUpload: myTaskList.fileUpload,
        creator_id: state?.auth?.auth?._id,
      };
      const token = state?.auth?.auth?.token;
      const response = await UserCreateMyTask(Payload, token);
      if (response && response.statusCode === 200) {
        setMyTaskList({});
        setNewTaskList(true);
        GetTsksList("Todo");
        showToastSuccessMsg(response.message);
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } else {
      //console.log("Error while update user details");
    }
  };

  const handleSubmitUpdate = async (e, userId) => {
    e.preventDefault();
    let Payload;
    if (state?.auth?.auth?.role === "superadmin") {
      Payload = {
        _id: userId,
        // subadmin_read: true,
        admin_read: true,
      };
    }
    const token = state.auth.auth.token;
    const response = await UpdateMyTaskRequest(Payload, token);
    if (response && response.statusCode === 200) {
      // showToastSuccessMsg(response.message);

      GetTsksList("Inprogress");
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };

  const handleSubmitUpdateStatus = async (e, value, id, value1) => {
    e.preventDefault();
    const Payload = {
      _id: id,
      status: value,
    };
    const token = state?.auth?.auth?.token;

    const response = await UpdateMyTaskRequest(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      GetTsksList(value1);
      // if (value1 === "Inprogress") {
      //   setSelectedTaskListTab("In Progress");
      //   GetTsksList("Inprogress");
      // } else if(value1 === "Completed"){
      //   setSelectedTaskListTab("Completed");
      //   GetTsksList("Completed");
      // }else if(value1 === "Overdue"){
      //   setSelectedTaskListTab("Over Due");
      //   GetMyTsksOverdue()
      // }
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setfailureModal(true);
    }
  };

  const navigate = useNavigate();

  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "taskType",
      label: "Task Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <div
                style={{ cursor: "pointer" }}
                onClick={(val) => {
                  console.log(val, tableMeta.rowData);
                  if (tableMeta.rowData[7] === false) {
                    handleSubmitUpdate(val, tableMeta.rowData[0]);
                  }
                  navigate(`/TaskEdit/${tableMeta.rowData[0]}`);
                }}
              >
                {value}
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "assignedTo",
      label: "Assigned To",
      options: {
        customBodyRender: (value) => {
          return (
            <div>{value}</div>
            // <div>{value?.name}</div>
            // <div>
            // {allTaskSubadmin
            // ?.filter((e) => e._id === value)
            // .map((i) => (
            //   <div>{i.name}</div>
            // ))}</div>
          );
        },
      },
    },
    {
      name: "creator_id",
      label: "Assigned By",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
          // return <div>{value?.name}</div>;
          // <div>
          // {allTaskSubadmin
          // ?.filter((e) => e._id === value)
          // .map((i) => (
          //   <div>{i.name}</div>
          // ))}</div>)
        },
      },
    },
    {
      name: "startDate",
      label: "Start Date",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "deadlineDate",
      label: "Deadline",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>{value}</div>
            // <select
            //   className="form-control form-control-lg"
            //   defaultValue={value}
            //   onChange={(e) => {
            //     handleSubmitUpdateStatus(
            //       e,
            //       e.target.value,
            //       tableMeta.rowData[0],
            //       value
            //     );
            //   }}
            // >
            //   <option value="Overdue">Over Due</option>
            //   <option value="Todo">To Do</option>
            //   <option value="Inprogress">In Progress</option>
            //   <option value="Completed">Completed</option>
            // </select>
          );
        },
      },
    },
    {
      name: "admin_read",
      label: "Status",
      options: {
        display: false,
      },
    },
    {
      name: "subadmin_read",
      label: "Status",
      options: {
        display: false,
      },
    },
    // {
    //   name: "overdue",
    //   label: "Overdue",
    //   options: {
    //     display: false,
    //   },
    // },
    // {
    //   name: "Action",
    //   label: "Action",
    //   options: {
    //     customBodyRender: (value, tableMeta) => {
    //       return (
    //         <div className="text-center">
    //           <i
    //             onClick={(e) => {
    //               Swal.fire({
    //                 title: "Are you sure?",
    //                 text: "You want to delete !",
    //                 // icon: "warning",
    //                 showCancelButton: true,
    //                 confirmButtonColor: "#3085d6",
    //                 cancelButtonColor: "#d33",
    //                 confirmButtonText: "Yes, delete",
    //               }).then((result) => {
    //                 if (result.isConfirmed) {
    //                   handleSubmitDelete(
    //                     e,
    //                     tableMeta.rowData[0],
    //                     tableMeta.rowData[5]
    //                   );
    //                 }
    //               });
    //             }}
    //             // onClick={(e) => {
    //             //   handleSubmitDelete(e,tableMeta.rowData[0],value)
    //             // }}
    //             className="fa fa-trash-can"
    //             style={{
    //               color: "#E34242",
    //               fontSize: "16px",
    //               cursor: "pointer",
    //             }}
    //           ></i>
    //         </div>
    //       );
    //     },
    //   },
    // },
  ];

  const handleRowClick = (rowData, rowMeta) => {
    console.log(rowMeta);
    if (getTaskList[rowMeta?.dataIndex]?.admin_read === false) {
      handleSubmitUpdate(null, getTaskList[rowMeta?.dataIndex]?._id);
    }
    navigate(`/TaskEdit/${getTaskList[rowMeta?.dataIndex]?._id}`);
  };

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    onRowClick: handleRowClick,
    setRowProps: (row) => {
      if (state?.auth?.auth?.role==="superadmin" && row[7] === false) {
        return {
          style: { background: "#DBDFEA", cursor: "pointer" },
        };
      }
      else if(state?.auth?.auth?.role==="subadmin" && row[8]===false){
        return {
          style: { background: "#DBDFEA", cursor:"pointer" },
        };
      }
      else {
        return {
          style: { background: "white", cursor: "pointer" },
        };
      }
    },
  };

  var todayDate = new Date();
  var month = todayDate.getMonth() + 1;
  var year = todayDate.getUTCFullYear();
  var tdate = todayDate.getDate();
  if (month < 10) {
    month = "0" + month;
  }
  if (tdate < 10) {
    tdate = "0" + tdate;
  }
  var minDate = year + "-" + month + "-" + tdate;

  const uploadCeoPic = async (e) => {
    const file = e.target.files[0];
    if (file) {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("fileName", e.target.files[0].name);
      formData.append("path", "Image/");

      let response = await UploadDocument(formData);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        setMyTaskListValue("fileUpload", response.data);
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    }
  };

  return (
    <div>
      <ToastContainer />
      <AdminNavbar />
      <div className="main">
        {loading ? (
          <Spinner />
        ) : (
          <>
            {newTaskList ? (
              <div>
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="mb-0 fw-bold color-maroon">Task List</h5>
                  <div>
                    <button
                      type="button"
                      className="btn py-1 login-btn"
                      onClick={() => {
                        setNewTaskList(false);
                      }}
                    >
                      Create Task
                    </button>
                  </div>
                </div>
                <div className="my-4">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-7 px-0">
                        <div className="tab-section">
                          <ul className="ps-0">
                            {AllTabs.map((tab, index) => {
                              return (
                                <div
                                  key={index}
                                  className={
                                    selectedTaskListTab === tab
                                      ? "serviceTab active"
                                      : "serviceTab inactive"
                                  }
                                  onClick={() => {
                                    if (tab === "To Do") {
                                      GetTsksList("Todo");
                                    } else if (tab === "Completed") {
                                      GetTsksList("Completed");
                                    } else if (tab === "Hold") {
                                      GetTsksList("Hold");
                                    } else if (tab === "In Progress") {
                                      GetTsksList("Inprogress");
                                      localStorage?.setItem(
                                        "tabReturn",
                                        "Inprogress"
                                      );
                                    } else if (tab === "Over Due") {
                                      GetMyTsksOverdue();
                                    } else if (tab === "All") {
                                      GetTsksList("All");
                                    }

                                    setSelectedTaskListTab(tab);
                                  }}
                                >
                                  <li>
                                    <span className="align-middle">{tab}</span>
                                  </li>
                                </div>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div style={{height:"200px"}}>  */}
                  <MUIDataTable
                    title={"Dalmaf - Global Task"}
                    data={getTaskList}
                    columns={columns}
                    options={options}
                  />
                  {/* </div> */}
                  {/* <table className="table">
                    <thead className="bg-historytablehead">
                      <tr>
                        <th scope="col" style={{ borderTopLeftRadius: "0" }}>
                          S. No
                        </th>
                        <th scope="col">Name</th>
                        <th scope="col">Task Name</th>
                        <th scope="col">Start Date</th>
                        <th scope="col">Deadline</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>

                    {getTaskList?.map((e, index) => (
                      <tbody className="historytablebody">
                        <tr
                          style={{
                            cursor: "pointer",
                            backgroundColor:
                              e.admin_read ? "" : "#DBDFEA",
                          }}
                          onClick={(val) => {
                            GetMySpecificTsks(e._id);
                            if (!e.admin_read) {
                              handleSubmitUpdate(val, e._id);
                            }
                            setSpecificTaskListModal(true);
                          }}
                        >
                          <td>
                            <div className="">
                              <h6 className="fw-bold">{index + 1}</h6>
                            </div>
                          </td>
                          <td scope="row">
                            <div className="d-flex align-items-center ">
                              <div className="flex-shrink-0">
                                <img
                                  // className="w-100 h-auto"
                                  style={{ width: "50px", height: "50px" }}
                                  src={
                                    TaskListprofile?.profileImage
                                      ? TaskListprofile?.profileImage
                                      : ADclient
                                  }
                                  alt="..."
                                />
                              </div>
                              <div className=" ms-3">
                                <h6 className="fw-bold">{TaskListprofile?.name}</h6>
                                <p className="mb-0 small">
                                  {TaskListprofile?.phoneNumber}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className=" ">
                              <h6 className="fw-bold">{e.taskType}</h6>
                            </div>
                          </td>
                          <td>
                            <h6 className="fw-bold">
                              {moment(e.startDate).format("DD/MM/YYYY")}
                            </h6>
                          </td>
                          <td>
                            <h6 className="fw-bold">
                              {moment(e.deadlineDate).format("DD/MM/YYYY")}
                            </h6>
                          </td>
                          <td>
                            <h6 className="fw-bold color-red">{e.status}</h6>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table> */}
                </div>
              </div>
            ) : (
              <div>
                <div className="">
                  <button
                    className="btn login-btn ms-2"
                    onClick={() => {
                      setNewTaskList(true);
                      GetTsksList("Inprogress");
                    }}
                  >
                    Back
                  </button>
                </div>
                <div className=" my-4">
                  <div className="bg-historytablehead rounded-3 py-3 px-3">
                    <h6 className="mb-0">New Task</h6>
                  </div>
                  <div className="container">
                    <div className="row justify-content-center py-5">
                      <div className="col-lg-11">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">Task Name</label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter the Task Name"
                                aria-invalid={Boolean(
                                  myTaskValidation?.taskType?.status === false
                                )}
                                value={myTaskList?.taskType}
                                onChange={(e) => {
                                  setMyTaskListValue(
                                    "taskType",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setMyTaskListValidationValue(
                                    "taskType",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {myTaskValidation?.taskType?.message
                                  ? `Task name ${myTaskValidation?.taskType?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label for="services" className="form-label">
                                Department
                              </label>
                              <select
                                className="form-control"
                                onChange={(e) => {
                                  setMyTaskListValue(
                                    "taskName",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setMyTaskListValidationValue(
                                    "taskName",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                              >
                                <option value="">---Select---</option>
                                <option value="Audit">Audit</option>
                                <option value="Legal">Legal</option>
                                <option value="Man Power">Man Power</option>
                                <option value="Buy/Selling">Buy/Selling</option>
                                <option value="In House">In House</option>
                                <option value="Other Services">
                                  Other Services
                                </option>
                              </select>
                              <small className="text-danger">
                                {myTaskValidation?.taskName?.message
                                  ? `Service type ${myTaskValidation?.taskName?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">
                                Task Description
                              </label>
                              <div
                                className="form-control form-control-lg"
                                style={{ height: "250px" }}
                              >
                                <Editor
                                  wrapperClassName="wrapper-class"
                                  editorClassName="editor-class"
                                  toolbarClassName="toolbar-class"
                                  editorState={editorState}
                                  onEditorStateChange={(e) =>
                                    handleEditorChange(e)
                                  }
                                />
                              </div>
                              {/* <textarea
                                rows={6}
                                className="form-control form-control-lg"
                                aria-invalid={Boolean(
                                  myTaskValidation?.taskDescription?.status ===
                                    false
                                )}
                                value={myTaskList?.taskDescription}
                                onChange={(e) => {
                                  setMyTaskListValue(
                                    "taskDescription",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setMyTaskListValidationValue(
                                    "taskDescription",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              /> */}
                              <small className="text-danger">
                                {myTaskValidation?.taskDescription?.message
                                  ? `Task description ${myTaskValidation?.taskDescription?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label">Start Date</label>
                              <input
                                type="date"
                                // min={minDate}
                                id="startDate"
                                className="form-control form-control-lg"
                                placeholder="select the  start date"
                                aria-invalid={Boolean(
                                  myTaskValidation?.startDate?.status === false
                                )}
                                value={myTaskList?.startDate}
                                onChange={(e) => {
                                  setMyTaskListValue(
                                    "startDate",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setMyTaskListValidationValue(
                                    "startDate",
                                    DateValidationn(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {myTaskValidation?.startDate?.message
                                  ? `Start date ${myTaskValidation?.startDate?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label">
                                Deadline Date
                              </label>
                              <input
                                type="date"
                                className="form-control form-control-lg"
                                placeholder="select the Deadline Date"
                                aria-invalid={Boolean(
                                  myTaskValidation?.deadlineDate?.status ===
                                    false
                                )}
                                min={myTaskList?.startDate}
                                value={myTaskList?.deadlineDate}
                                onChange={(e) => {
                                  setMyTaskListValue(
                                    "deadlineDate",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setMyTaskListValidationValue(
                                    "deadlineDate",
                                    ExpireDateValidation(
                                      myTaskList?.startDate,
                                      e.target.value
                                    )
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {myTaskValidation?.deadlineDate?.message
                                  ? `Deadline date ${myTaskValidation?.deadlineDate?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-6 my">
                            <div className="mb-4">
                              <label className="form-label ">
                                Team Member Name
                              </label>

                              <select
                                className="form-control form-control-lg"
                                onChange={(e) => {
                                  setMyTaskListValue(
                                    "assignedTo",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setMyTaskListValidationValue(
                                    "assignedTo",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                              >
                                <option value="">
                                  ---Select Member Name---
                                </option>

                                {allTaskSubadmin?.map((e) => (
                                  <option value={e._id}>{e.name}</option>
                                ))}
                              </select>
                              <small className="text-danger">
                                {myTaskValidation?.assignedTo?.message
                                  ? `Member name ${myTaskValidation?.assignedTo?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-6 my">
                            <div className="mb-3">
                              <label
                                htmlFor="fileUpload"
                                className="form-label"
                              >
                                Select File
                              </label>
                              <input
                                type="file"
                                className="form-control"
                                id="file"
                                ref={CeoRef}
                                onChange={(e) => {
                                  uploadCeoPic(e);
                                }}
                              />
                              {/* <button
                              className="btn btn-primary mt-2"
                              onClick={() => CeoRef.current()}
                            >
                              Upload
                            </button> */}
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <button
                              type="button"
                              className="btn white-btn me-2 "
                              onClick={() => {
                                setMyTaskList({});
                                setNewTaskList(true);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="btn login-btn ms-2 "
                              onClick={(e) => {
                                handleSubmit(e);
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <CustomModal
        open={specificTaskListModal}
        onClickOutside={() => {
          setSpecificTaskListModal(false);
        }}
      >
        <div className="modal-content w-50 m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{ textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px" }}
                onClick={() => {
                  setSpecificTaskListModal(false);
                }}
              >
                close
              </span>
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className=" my-5 px-3">
                        <div className="row justify-content-center mt-2">
                          <div className="col-lg-12">
                            <div className="admin-service">
                              <div
                                className="small-banner-img-head"
                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  className=""
                                  style={{ width: "525px", height: "200px" }}
                                  src={
                                    TaskListprofile?.profileImage
                                      ? TaskListprofile?.profileImage
                                      : ""
                                  }
                                  hidden={!TaskListprofile?.profileImage}
                                  alt=""
                                />
                                {/* <p className="mb-0 cost-pack">₹{e.price}</p> */}
                              </div>
                              <div className="mt-3">
                                <h5 className="color-maroon fw-bold">
                                  {specificTask?.taskType}
                                </h5>
                                <p className="small">
                                  <h6 className="color-maroon fw-bold">
                                    Description:
                                  </h6>
                                  {specificTask?.taskDescription}
                                </p>

                                {taskListassignName?.map((i) => (
                                  <p className="small">
                                    <h6 className="color-maroon fw-bold">
                                      Assigned To:
                                    </h6>{" "}
                                    {i.name}
                                  </p>
                                ))}

                                <p className="small">
                                  <h6 className="color-maroon fw-bold">
                                    Status:
                                  </h6>{" "}
                                  {specificTask?.status}
                                </p>
                                <p className="small">
                                  <h6 className="color-maroon fw-bold">
                                    Valid:
                                  </h6>{" "}
                                  {moment(specificTask?.startDate).format(
                                    "DD/MM/YYYY"
                                  )}{" "}
                                  -{" "}
                                  {moment(specificTask?.deadlineDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                </p>
                                <p className="small">
                                  {/* <h6 className="color-maroon fw-bold">Service Title:</h6> {e.serviceTitle} */}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}
