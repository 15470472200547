import React, { useEffect } from 'react';
import ContactBanner from '../Components/Contact/ContactBanner'
import ContactSection from '../Components/Contact/ContactSection'
import Footer from '../Components/Footer/Footer'
import Navigation from '../Components/Navbar/Navigation'
import UserNavigation from '../Components/v1/NavBar/Navigaton';
import {Helmet} from "react-helmet";
import AboutExperince from '../Components/v1/Home/AboutExperince';
import ScheduleMeetBanner from '../Components/v1/Home/ScheduleMeetBanner';
import AppDownload from '../Components/v1/Home/AppDownload';
import Newsletter from '../Components/v1/Home/Newletter';
export default function Contact() {
    useEffect(() =>{
        window.scroll(0,0)
      },[])
  return (
    <div className="innerpages-nav">
       <Helmet>
        <meta charSet="utf-8" />
        <title>Contactus | DALMaf Private Limited</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="Bring to the table win-win survival strategies to ensure proactive domination. At the end of the day, going a new normal that from generation" />
        <meta name="keywords" content="At Dalmaf, We understand the challenges and complexities that businesses experience in today's dynamic and highly competitive marketplace. As a result, we are here to deliver a wide range of Expert Assistance Tailored to your Success. We Provide Comprehensive Services in the areas of Legal, Audit, Human Resources, Bank Auctions, Buying/Selling, and Marketing Assistance resulting from our extensive experience and dedication to excellence." />
        <meta name="author" content="DALMaf" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>
        <div id="container">
      <UserNavigation/>
      <div style={{marginTop:'-30px'}}>
        <AboutExperince/>
      </div>
      {/* <ContactBanner/> */}
      <ContactSection/>
      <ScheduleMeetBanner/>
      <AppDownload/>
      <Newsletter/>
      <Footer/>
      </div>
    </div>
  )
}
