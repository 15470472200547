const data = [
  {
    pagename: "Professional Tax",
  },
  {
    title: "Professional Tax Registration and Compliance",
    desc: [
      "Professional Tax is a tax levied by state governments in India on individuals who are engaged in a profession or trade, such as doctors, lawyers, chartered accountants, and so on. The tax is usually paid annually, and the amount varies depending on the state and the income of the individual.",
      "If you are engaged in a profession or trade in India, you may need to register for professional tax with your state government. The process of registration varies from state to state, but generally, you will need to fill out an application form and provide proof of your profession or trade",
      "Once you are registered for professional tax, you will need to comply with the regulations set out by your state government. This may include paying the tax on time, filing regular tax returns, and maintaining proper records of your income and expenses. Failure to comply with these regulations can result in penalties and fines.",
      "It is important to stay up-to-date with the professional tax regulations in your state to ensure that you are in compliance and avoid any legal issues. You may also want to consult with a tax professional to ensure that you are fulfilling all of your tax obligations and taking advantage of any available deductions or exemptions.",
    ],
  },
  {
    title: "Professional Tax Rate",
    desc: [
      "The Professional Tax rates vary from state to state in India,and the tax is levied on individuals who are engaged in a profession or trade.",
      "For instance, in Maharashtra, the Professional Tax rates range from INR 175 to INR 2,500 per annum, depending on the income of the individual. In Karnataka, the Professional Tax rates range from INR 200 to INR 2,500 per annum, and in Tamil Nadu, the tax rates vary from INR 120 to INR 12,000 per annum.",
      "It is important to note that the rates and rules of Professional Tax differ from state to state, and it is advisable to check with the state government's website or consult with a tax professional for accurate and updated information.",
    ],
  },
  {
    title: "WHO PAYS PROFESSIONAL TAX",
    desc: [
      "Professional Tax in India is typically paid by individuals who are engaged in a profession, trade, or employment. This includes self-employed individuals, salaried employees, and professionals such as doctors, lawyers, accountants, engineers, and consultants, among others.The tax is usually deducted from the salary or income of the individual by the employer, and the employer is responsible for paying the tax to the state government. However, in some states, the individual may be required to pay the tax directly to the state government if the employer does not deduct the tax from their salary or income.",
      "It is important to note that the rules and regulations regarding Professional Tax vary from state to state in India, and individuals engaged in a profession or trade should check with their respective state government or consult with a tax professional to determine whether they are required to pay Professional Tax and how to comply with the regulations.",
    ],
  },

  {
    title: "EXEMPTIONS FOR PAYMENT OF PROFESSIONAL TAX",
    subtitle:
      "There are certain exemptions available for the payment of Professional Tax in India. However, the rules and regulations regarding exemptions vary from state to state. Exemptions for Payment of Professional Tax",
    points: [
      "1. Senior citizens: In many states, individuals above a certain age (typically 65 years) are exempted from paying Professional Tax.",
      "2. Individuals with a disability: In some states, individuals with a disability are exempted from paying Professional Tax.",
      "3. Government employees: In some states, government employees are exempted from paying Professional Tax.",
      "4. Salaried individuals earning below a certain amount: In some states, salaried individuals earning below a certain amount (usually INR 15,000 per month) are exempted from paying Professional Tax.",
      "5. Individuals engaged in certain professions: In some states, individuals engaged in certain professions, such as agriculture, fishing, and weaving, may be exempted from paying Professional Tax.",
    ],
    footer:
      "It is important to note that the rules and regulations regarding exemptions vary from state to state in India, and individuals engaged in a profession or trade should check with their respective state government or consult with a tax professional to determine whether they are eligible for any exemptions.",
  },
  {
    title: "DUE DATES FOR PROFESSIONAL TAX PAYMENT",
    subtitle:
      "The due dates for Professional Tax payment vary from state to state in India. Generally, the tax is paid on a quarterly ,half-yearly or yearly basis, depending on the rules and regulations of the state government .Here are some general guidelines regarding the due dates for Professional Tax payment:",
    points: [
      "1. Quarterly Payment: In many states, individuals and businesses are required to pay Professional Tax on a quarterly basis. The due dates for the payment are usually 15th July, 15th October, 15th January, and 15th April.",
      "2. Half-Yearly Payment: In some states, the Professional Tax is paid on a half-yearly basis. The due dates for the payment are usually 31st March and 30th September.",
      "3. Yearly Payment: In a few states, the Professional Tax is paid on a yearly basis. The due date for the payment is usually 31st March.",
    ],
    footer:
      "It is important to note that the rules and regulations regarding Professional Tax payment, including due dates, may vary from state to state. Therefore, it is advisable to check with the state government's website or consult with a tax professional to determine the accurate and updated information regarding the due dates for Professional Tax payment",
  },
  {
    title: "BENEFITS OF PROFESSIONAL TAX REGISTRATION",
    subtitle: "The benefits of Professional tax Registration are given below:",
    points: [
      "· It is simple to comply with the Professional Tax Compliance, which results in a smooth registration process with minimum restrictions.",
      "· It is compulsory to pay Professional tax as per the law. Hence, the timely payment of Professional tax can help avoid penalties and any punitive action against the Employer or a self-employed person.",
      "· Professional Tax Compliance is simple, which can enable a smooth and hassle-free Registration Process.",
      "· The Professional Tax acts as a revenue source for the state governments that helps the government to implement schemes for the various welfare and development of the region.",
      "· The Employer or the self-employed person can claim a deduction on the previously paid professional tax.",
    ],
  },
  {
    title: "DOCUMENTS REQUIRED FOR PROFESSIONAL TAX REGISTRATION",
    points: [
      "· Certificate of Incorporation / LLP Agreement",
      "· MOA and AOA",
      "· PAN Card of Company/LLP/Proprietor/Owner/Director",
      "· NOC from the landlord, where the business is situated",
      "· Passport size photos of Proprietor/Owner/Director",
      "· Address and identity proof of Proprietor/Owner/Director",
      "· Details of employees and salaries paid",
      "· Additional registrations and licenses",
    ],
  },
];
const dataUdayamRegistration = [
  {
    pagename: "Udyam Registration",
  },
  {
    title: "Documents Required",
    desc: ["PAN", "Aadhaar"],
  },
  {
    title: "Udyam Registration",
    subtitle: [
      "Udyam registration is a process of registering micro, small, and medium enterprises (MSMEs) in India. It was introduced by the Government of India in July 2020, replacing the earlier system of registration known as Udyog Aadhaar.",
    ],
    desc: ["Here are some advantages of Udyam registration:"],
    points: [
      "1. Eligibility for government schemes and benefits: MSMEs registered under Udyam are eligible for various government schemes and benefits, such as subsidies, low-interest loans, credit guarantee schemes, and others. This can help them to grow their businesses and improve their competitiveness",
      "2. Protection against delayed payments: Udyam registration provides MSMEs with protection against delayed payments for goods and services supplied to buyers. In case of delayed payment, the buyer is liable to pay interest on the outstanding amount to the MSME.",
      "3. Access to credit: Udyam registration is recognized as a valid proof of existence and identity of MSMEs, which makes it easier for them to access credit from banks and other financial institutions. This can help them to fund their business operations and expand their businesses.",
      "4. Ease of doing business: Udyam registration is a simple and hassle-free process that can be completed online. It eliminates the need for physical documentation and reduces the time and effort required for MSMEs to register their businesses.",
      "5. Better market access: Udyam registration provides MSMEs with a unique identification number (Udyam Registration Number) that can be used to participate in government tenders and access markets both domestically and globally.",
    ],
    footer:
      "To register for Udyam, an MSME needs to provide basic details such as the name of the business, its PAN card and GSTIN, and other details such as the location and type of business activity. The process can be completed online, and no fee is charged for the registration.",
  },
  {
    title: "Who can apply Udayam",
    desc: [
      "Any micro, small, or medium enterprise (MSME) can apply for Udyam registration. An MSME is defined as a business that falls within certain size and investment criteria.",
      "As per the new definition of MSMEs, which was introduced by the Government of India in July 2020, the following enterprises are eligible for Udyam registration:",
    ],
    points: [
      "1. Micro enterprises: Businesses with a turnover of up to Rs. 5 crore and investment in plant and machinery or equipment up to Rs. 1 crore.",
      "2. Small enterprises: Businesses with a turnover between Rs. 5 crore and Rs. 75 crore and investment in plant and machinery or equipment between Rs. 1 crore and Rs. 10 crore.",
      "3. Medium enterprises: Businesses with a turnover between Rs. 75 crore and Rs. 250 crore and investment in plant and machinery or equipment between Rs. 10 crore and Rs. 50 crore.",
    ],
    footer:
      "The Udyam registration process is simple and can be done online through the official Udyam registration portal. The MSME needs to provide basic details such as the name of the business, its PAN card and GSTIN, and other details such as the location and type of business activity. No fee is charged for the registration, and the registration certificate is issued immediately upon completion of the registration process.",
  },
];
const dataFSSAIRegistration = [
  {
    pagename: "FSSAI Registration",
  },
  {
    title: "Documents Required",
    desc: ["PAN", "Aadhaar"],
  },
  {
    title: "FSSAI License and Registration",
    desc: [
      "FSSAI (Food Safety and Standards Authority of India) license and registration are mandatory requirements for all food businesses operating in India. Here is a brief explanation of each:",
    ],
    points: [
      "1. FSSAI Registration: This is required for small food businesses with an annual turnover of up to Rs. 12 lakh. The registration can be done online through the FSSAI website or offline through the designated food safety officer in the area of operation. The registration is valid for a period of 1 to 5 years, depending on the preference of the business owner",
      "2. FSSAI License: This is required for food businesses with an annual turnover of more than Rs. 12 lakh. There are three categories of FSSAI licenses, namely State License, Central License, and Registration Exemption. The license can be obtained through the FSSAI website or offline through the designated food safety officer in the area of operation. The license is valid for a period of 1 to 5 years, depending on the preference of the business owner.",
    ],
  },
  {
    subtitle: "Advantages of obtaining FSSAI License and Registration:",
    points: [
      "1. Legal compliance: FSSAI registration and license ensure that food businesses are compliant with the food safety regulations set by the government of India.",
      "2. Consumer trust: FSSAI registration and license help build consumer trust by assuring them that the food products they consume are safe and of good quality.",
      "3. Access to new markets: FSSAI registration and license enable food businesses to access new markets, both domestic and international.",
      "4. Government schemes and benefits: FSSAI registered and licensed food businesses are eligible for various government schemes and benefits related to food safety and quality.",
      "5. Avoid penalties and legal issues: Non-compliance with FSSAI regulations can result in penalties and legal issues, which can be avoided by obtaining FSSAI registration and license.",
    ],
    footer:
      "FSSAI registration and license are mandatory requirements for food businesses operating in India. They ensure legal compliance, consumer trust, access to new markets, eligibility for government schemes and benefits, and avoidance of penalties and legal issues.",
  },
  {
    title: "Food Business Operators Who Who Require FSSAI Registration?",
    desc: [
      "The Food Business Operators (FBOs) carrying on the following kinds of the food business must have FSSAI License/Registration:",
    ],
    points: [
      "· Petty retailers, Retail Shops, Snacks shops, Confectionery or Bakery shops, etc",
      "· Temporary stalls, fixed stalls, or food premises are involved in preparing, storing, distributing, and selling food products.",
      "· Hawkers sell packaged or freshly prepared food by traveling from one location to another.",
      "· Dairy Units, including Milk Chilling Units, Petty Milkmen, and Milk Vendors",
      "· Slaughtering house",
      "· Fish Processing, Meat Processing, and unit",
      "· All Food Manufacturing units that include Repacking food",
      "· Vegetable Oil Processing Units",
      "· Proprietary food and Novel food",
      "· Cold/refrigerated storage facility",
      "· Transporter of food products having several specialized vehicles like insulated refrigerated vans/wagons, milk tankers, food wagons, food trucks, etc",
      "· Wholesalers, suppliers, distributors, and marketers of food products",
      "· Hotels, Restaurants, and Bars",
      "· Canteens and Cafeteria, including mid-day meal canteens",
      "· Food Vending Agencies and Caterers",
      "· Dhaba, PG provides food, a Banquet hall with food catering arrangements, Home Based Canteen, and Food stalls at fairs or religious institutions.",
      "· Importers and Exporters of food items and food ingredients",
      "· E-Commerce food suppliers, including cloud kitchens",
      "· Eligibility Criteria for Food Business Registration/License",
      "· The document attached here provides eligibility criteria for Food Business Registration/License.",
    ],
  },
  {
    title: "who require FSSAI Registration",
    subtitle: [
      "All food businesses operating in India require FSSAI registration or license, irrespective of their size or scale of operation. The FSSAI has classified food businesses into three categories based on their size and turnover, and the type of license or registration required varies accordingly.",
    ],
    desc: [
      "Here are the three categories of food businesses and their FSSAI registration or license requirements:",
    ],
    points: [
      "1. FSSAI Registration: This is required for small food businesses with an annual turnover of up to Rs. 12 lakh. Examples of such businesses include small food manufacturers, petty retailers, and small-scale food businesses.",
      "2. FSSAI State License: This is required for food businesses with an annual turnover of more than Rs. 12 lakh and up to Rs. 20 crore. Examples of such businesses include medium-sized food manufacturers, storage units, and transporters.",
      "3. FSSAI Central License: This is required for food businesses with an annual turnover of more than Rs. 20 crore. Examples of such businesses include large-scale food manufacturers, importers, and exporters.",
    ],
    footer: [
      "It is important to note that even food businesses operating from home or online are required to obtain FSSAI registration or license, depending on their annual turnover. Also, any food business involved in the manufacturing, storage, distribution, and sale of food items must obtain FSSAI registration or license to operate legally in India.",
      "FSSAI registration or license is mandatory for all food businesses operating in India, regardless of their size or scale of operation. The type of license or registration required varies based on the annual turnover of the food business.",
    ],
  },
  {
    title: "Procedure to Apply for FSSAI License and Registration",
    subtitle:
      "The procedure to apply for FSSAI license and registration in India is as follows:",
    points: [
      "1. Determine the type of FSSAI license/registration required: The first step is to determine the type of FSSAI license or registration required for the food business, based on its annual turnover and size. This information can be obtained from the FSSAI website or by consulting a food safety consultant.",
      "2. Prepare the required documents: The next step is to prepare the required documents, such as proof of identity and address of the proprietor/partners/directors, food safety management plan, NOC from local municipal authorities, etc. The exact list of required documents may vary depending on the type of FSSAI license/registration required.",
      "3. Submit the application online/offline: The FSSAI license or registration application can be submitted online through the FSSAI website or offline by submitting the application form and required documents to the nearest FSSAI office or designated food safety officer in the area of operation.",
      "4. Pay the application fee: The application fee for FSSAI license or registration varies based on the type of license/registration and the duration of validity. The fee can be paid online through the FSSAI website or offline at the designated bank or through a demand draft.",
      "5. Wait for verification and inspection: After the application is submitted and the fee is paid, the FSSAI will verify the application and conduct an inspection of the food business premises to ensure compliance with food safety regulations. The food safety officer may also request additional documents or information during the inspection.",
      "6. Receive the license/registration: Once the application is approved and the inspection is successful, the FSSAI license or registration will be issued to the food business. The license/registration is valid for a period of 1 to 5 years, depending on the preference of the business owner.",
    ],
    footer:
      "The procedure to apply for FSSAI license and registration in India involves determining the type of license/registration required, preparing the required documents, submitting the application online/offline, paying the application fee, waiting for verification and inspection, and receiving the license/registration.",
  },
  {
    title: "Documents Required For FSSAI Registration",
    points: [
      "Promoters Passport size Photo",
      "Pan card of Food Applicant",
      "Passport",
      "Driving license",
      "Aadhaar Card Food Applicant",
      "Electricity bill",
      "Telephone BILL",
      "Incorporation Certificate",
      "Partnership Deed",
      "Memorandum of Association",
      "Article of Association Copy",
      "PAN of Company/LLP",
      "Rental Agreement",
      "Proof of Premises ( EB bill or Tax Receipt) of Business Place",
    ],
  },
];
const dataTrademarkOposition = [
  {
    pagename: "Trademark Oposition",
  },
  {
    title: "Trademark Opposition",
    desc: [
      "Trademark opposition in India refers to a legal proceeding initiated by a third party to challenge the registration of a trademark. It provides an opportunity for interested parties to oppose the registration of a trademark that they believe may infringe upon their existing rights or is contrary to the provisions of the Trademarks Act, 1999.",
    ],
  },
  {
    title: "Who can File Trademark Opposition in India",
    subtitle:
      "In India, any person or entity with a legitimate interest can file a trademark opposition. The Trademarks Act, 1999 allows the following parties to file a trademark opposition in India:",
    points: [
      "1. Any Person: Any individual, whether a natural person or a legal entity, can file a trademark opposition. This includes individuals, companies, partnerships, associations, and organizations.",
      "2. Registered Trademark Owners: Owners of prior registered trademarks in India can file an opposition against a new trademark application if they believe it infringes upon their existing rights.",
      "3. Prior Applicants: Applicants who have previously filed trademark applications that are pending examination can file an opposition against a subsequent application that they believe conflicts with their pending application.",
      "4. Authorized Users or Licensees: Authorized users or licensees of a registered trademark can also file an opposition if they believe that the proposed trademark registration may infringe upon the rights granted to them under the license or authorization agreement.",
      "5. Legal Representatives: Legal representatives or attorneys acting on behalf of the above-mentioned parties can file a trademark opposition on their client's behalf.",
    ],
  },
  {
    subtitle: "Key Points to Understand about Trademark Opposition in India:",
    points: [
      "1. Filing an Opposition: Any person can file a notice of opposition within a specified time period after the publication of a trademark application in the Trademarks Journal. The opposition must be filed with the appropriate Trademark Registry along with the prescribed fee.",
      "2. Examination and Evidence: Once an opposition is filed, the Trademark Registry examines the opposition and provides an opportunity for both parties to submit evidence, arguments, and counterstatements to support their respective positions. This may involve exchanging evidence of prior use, reputation, distinctiveness, or any other relevant factors.",
      "3. Hearing and Decision: If the opposition is not withdrawn, a hearing is conducted before the Registrar of Trademarks. Both parties present their case and the Registrar evaluates the evidence and arguments presented. Based on the examination and the submissions, the Registrar may decide to allow or refuse the trademark registration. The decision can be appealed before the Intellectual Property Appellate Board (IPAB).",
      "4. Duration and Costs: The opposition process can be time-consuming and may take several months or even years to reach a final decision. The costs associated with opposition proceedings include filing fees, attorney fees, and any additional expenses incurred during the process.",
    ],
  },
  {
    title: "Grounds For Trademark Opposition in India",
    subtitle:
      "The grounds for trademark opposition in India are defined under Section 9 and Section 11 of the Trademarks Act, 1999. Here are the main grounds for trademark opposition:",
    points: [
      "1. Similarity or Identity with Existing Trademark: An opposition can be filed if the proposed trademark is similar or identical to an existing registered trademark or a prior pending application. The opposition can be based on the likelihood of confusion or deception among the public, which may arise due to the similarity of the marks and the similarity of the goods or services covered.",
      "2. Descriptiveness or Lack of Distinctiveness: A trademark can be opposed if it is deemed descriptive or lacking in distinctiveness. A descriptive mark directly describes the goods, services, or their characteristics, while a mark lacking distinctiveness is one that is common, generic, or widely used in the trade.",
      "3. Prohibited Marks: Trademarks that fall under the absolute grounds for refusal can be opposed. These include marks that are contrary to public policy, morality, or religious sentiments, marks that are likely to deceive the public, or marks that consist exclusively of signs or indications that have become customary in the trade",
      "4. Deceptive or Misleading Mark: If the trademark is likely to deceive or mislead the public regarding the nature, quality, or geographical origin of the goods or services, an opposition can be filed.",
      "5. Earlier Existing Rights: Opposition can be filed based on prior existing rights, such as prior use or prior registrations. The opponent may argue that they have a prior right to use the mark in question, and the registration of the proposed mark would infringe upon their rights.",
      "6. Bad Faith: Opposition can be filed if the applicant is believed to have filed the trademark application in bad faith or with the intention to take unfair advantage of the opponent's goodwill.",
      "7. Well-Known Trademarks: If the opponent claims that the proposed trademark is likely to take unfair advantage of, or be detrimental to, the distinctive character or reputation of a well-known trademark, they can file an opposition.",
    ],
  },
  {
    title: "Procedure for Filing of Opposition Notice",
    subtitle:
      "The procedure for trademark opposition in India involves several stages and timelines.",
    desc: [
      "1. Filing Notice of Opposition: The opposition proceedings begin with the filing of a Notice of Opposition. This must be done within the prescribed time period after the publication of the trademark application in the Trademarks Journal. The Notice of Opposition should include the grounds for opposition and the supporting evidence or arguments.",
      "2. Examination and Counter-Statement: Once the Notice of Opposition is filed, the Trademark Registry serves a copy of the opposition on the applicant. The applicant then has the opportunity to file a Counter-Statement within a specified time period, usually within two months from the date of receipt of the opposition.",
      "3. Evidence and Documentation: After the Counter-Statement is filed, both the opponent and the applicant can submit evidence, supporting documents, and affidavits to substantiate their respective positions. This may include evidence of prior use, distinctiveness, reputation, or any other relevant factors. The evidence should be filed within the specified time frame set by the Registry.",
      "4. Examination and Hearing: Once the evidence and documents are submitted, the Registry examines the opposition and the counter-statement along with the supporting evidence. If required, the Registry may issue examination reports, request clarifications, or call for a hearing to allow both parties to present their arguments and evidence.",
      "5. Decision: Based on the examination and the submissions made by both parties, the Registrar of Trademarks evaluates the evidence, arguments, and legal provisions. The Registrar then issues a decision either allowing or refusing the trademark registration. The decision is communicated to both the opponent and the applicant.",
      "6. Appeal: If either party is dissatisfied with the decision of the Registrar, they can file an appeal before the Intellectual Property Appellate Board (IPAB) within the prescribed time period. The IPAB re-examines the case based on the evidence, arguments, and legal provisions, and issues a final decision.",
    ],
    footer:
      "It is important to note that the timelines and specific requirements may vary depending on the particular circumstances of each case.",
  },
];
const dataTrademarkRegistration = [
  {
    title: "TRADEMARK PROCESS",
    desc: [
      "In India, trademarks are defined and protected under the Trademarks Act, 1999. A trademark is a unique symbol, word, phrase, logo, design, or combination thereof that distinguishes the goods or services of one person or company from those of others. It serves as a valuable intellectual property right, allowing businesses to establish brand identity, protect their reputation, and prevent others from using similar marks that may cause confusion among consumers.",
    ],
  },
  {
    title: "",
    subtitle: "Key features and aspects of Trademarks in India:",
    points: [
      "1. Definition: As per the Trademarks Act, 1999, a trademark is defined as any mark capable of being represented graphically and distinguishing the goods or services of one entity from those of others. It can include names, logos, labels, numerals, shapes, sounds, or combinations thereof",
      "2. Registration: Trademark registration is not mandatory in India. However, registering a trademark provides stronger legal protection and exclusive rights to the owner. The registration process involves filing an application with the Trademark Registry, examination, publication, and issuance of the registration certificate",
      "3. Exclusive Rights: A registered trademark owner has the exclusive right to use the mark for the goods or services it represents. They can prevent others from using a similar mark or engaging in activities that may cause confusion or dilute the distinctiveness of the mark.",
      "4. Duration and Renewal: Once registered, a trademark is valid for ten years from the date of filing the application. It can be renewed indefinitely for successive periods of ten years upon payment of the renewal fee.",
      "5. Protection: Trademark infringement occurs when someone uses a mark similar to a registered trademark for identical or similar goods or services, leading to confusion among consumers. Owners of registered trademarks can take legal action against infringers to protect their rights.",
      "6. International Protection: India is a member of the Paris Convention for the Protection of Industrial Property and the World Intellectual Property Organization (WIPO), providing certain rights and benefits to foreign trademark owners. Foreign entities can seek trademark protection in India through the Madrid Protocol or file directly with the Indian Trademark Registry.",
    ],
  },
  {
    title: "Eligibility for Trademark Registration",

    points: [
      "1. Individuals: Any Individual, whether an Indian citizen or a foreign national, who owns a trademark and intends to protect it in India can apply for trademark registration.",
      "2. Companies: Companies registered under the Companies Act, such as private limited companies, public limited companies, and one-person companies (OPCs), can apply for trademark registration. This includes both Indian and foreign companies operating in India.",
      "3. Partnership Firms: Partnership firms, including registered partnerships and unregistered partnerships, are eligible to apply for trademark registration.",
      "4. Limited Liability Partnerships (LLPs): LLPs, which are hybrid entities combining features of both partnerships and companies, can seek trademark registration in India.",
      "5. Trusts and Societies: Trusts and societies, whether charitable, educational, or otherwise, can apply for trademark registration if they use a trademark in connection with their activities or services.",
      "6. Government Departments and Statutory Bodies: Government departments, ministries, and statutory bodies, whether at the central or state level, can register trademarks to protect their brands and services",
      "7. Foreign Entities: Foreign entities, including individuals and companies, can apply for trademark registration in India if they wish to protect their trademarks within the country. It is not necessary to have a physical presence in India to apply for trademark registration.",
    ],
    footer: [
      "It's important to note that the applicant must demonstrate a bona fide intention to use the trademark in India or have already used it before applying for registration. Additionally, the trademark must meet the requirements of distinctiveness, non-descriptiveness, and non-offensiveness as per the Trademarks Act, 1999.",
    ],
  },
  {
    title: "Types of Trademark Registration in India",

    points: [
      "1. Word Marks: These are trademarks that consist of words, letters, numerals, or any combination thereof.",
      "2. Logo Marks: Logo marks are trademarks that consist of a unique design, symbol, or graphic element without any words.",
      "3. Device Marks: Device marks are similar to logo marks and consist of a specific device or emblem that represents a particular brand. These marks can be three-dimensional or non-traditional in nature.",
      "4. Slogan Marks: Slogan marks are trademarks that consist of a catchy phrase or tagline associated with a product or service.",
      "5. Sound Marks: Sound marks represent unique sounds or melodies that are used to identify a particular brand or product. These marks can be registered in India if they meet the distinctiveness criteria.",
      "6. Shape Marks: Shape marks are trademarks that consist of the three-dimensional shape of a product or its packaging, which distinguishes it from others in the market.",
      "7. Color Marks: Color marks are trademarks that consist of a particular color or combination of colors used to identify a brand or product. However, securing registration for color marks can be challenging, as they need to demonstrate acquired distinctiveness and not be considered generic or functional.",
      "8. Collective Marks: Collective marks are used by members of an organization, association, or group to indicate a common origin, quality, or characteristic of their goods or services.",
      "9. Certification Marks: Certification marks are used to certify specific characteristics, quality, origin, or other attributes of goods or services. These marks are granted by the certifying authority to individuals or entities that meet certain predetermined standards",
    ],
    footer:
      "It's important to note that these types of trademarks are not mutually exclusive, and a trademark may fall into multiple categories depending on its characteristics. The choice of the trademark type depends on the nature of the brand, its distinctiveness, and the protection sought by the owner.",
  },
  {
    title: "Required Documents for Trademark Registration",
    subtitle: "1. Incorporation Certificate",
    points: ["If the Trademark is registered under a company or LLP."],
  },
  {
    subtitle: "2. Partnership Deed",
    points: ["If the Trademark is registered under a partnership firm"],
  },
  {
    subtitle: ["3. PAN Card"],
    points: ["Of the Authorised Signatory."],
  },
  {
    subtitle: ["4. Aadhaar Card"],
    points: ["Of the Authorised Signator"],
  },
  {
    subtitle: ["5. Form-48 Signed"],
    points: [
      "Please print document in Color. Authorised Signatory to sign on each of the Pages.",
    ],
  },
  {
    subtitle: ["6. Logo"],
    points: [
      "Please upload the Logo in Black and White to ensure all Colors of the trademark are covered under the registration.",
    ],
  },
  {
    subtitle: "7. MSME Certificate",
  },
];
const dataTrademarkObjection = [
  {
    pagename: "Trademark Objection",
  },
  {
    title: "Trademark Objection",

    desc: [
      "In India, trademark objection refers to a situation where the Trademark Registry raises concerns or objections regarding the registration of a trademark application. When an application is examined by the Trademark Registry, the examiner may identify issues that prevent the straightforward acceptance and registration of the trademark. These issues are communicated to the applicant through an official communication known as a trademark examination report or an objection letter",
    ],
  },
  {
    title: "Common reasons for Trademark Objections in India",
    points: [
      "1. Similarity to Existing Trademarks: If the proposed trademark is deemed similar or identical to an existing registered or pending trademark in the same or similar class of goods or services, the examiner may raise an objection. This objection is based on the potential for confusion among consumers",
      "2. Descriptive or Non-Distinctive Mark: If the proposed trademark is considered to be descriptive of the goods or services it represents, lacking distinctiveness, or being too generic, an objection may be raised. Trademarks that are not inherently distinctive may require evidence of acquired distinctiveness or secondary meaning.",
      "3. Deceptive or Misleading Trademark: If the trademark is likely to deceive or mislead the public, for example, by falsely suggesting a geographical origin or qualities of the goods or services, an objection may be raised.",
      "4. Contrary to Law or Morality: If the trademark is against public policy, religious sentiments, or morality, it may face objection. Marks that are offensive or scandalous in nature may be deemed objectionable.",
      "5.Absolute Grounds for Refusal: Trademarks that fall under the absolute grounds for refusal, such as being devoid of any distinctive character, being a common name, consisting of shapes that result from the nature of the goods, or lacking sufficient graphical representation, may be objected to.",
      "6. Failure to File TM-48: Form TM-48 is used for the authorization of an agent for trademark matters. Failure to file Form TM-48 refers to the situation where an applicant or trademark owner fails to submit this form when appointing an agent to act on their behalf in trademark-related proceedings before the Trademark Registry",
      "7. Incorrect Addresses on Trademark Application: In case a trademark application has not mentioned the principal place of business of the applicant or if the applicant’s address for service in India is not mentioned, then an objection can be raised",
    ],
    footer: [
      "Once a trademark objection is raised, the applicant has the opportunity to respond and provide counter-arguments or evidence to overcome the objection. The response needs to address the specific issues raised by the examiner and provide satisfactory explanations or evidence to support the registrability of the trademark. The applicant may need to file a written reply, attend hearings, or amend the application as necessary.",
    ],
  },
];
const dataLLPRegistration = [
  {
    pagename: "Limited Liability Partnership",
  },
  {
    title: "Documents Required For LLP Registration",
    points: [
      "Passport (For Foreign Nationals)",
      "Voters ID",
      "Ration Card",
      "Driving License",
      "Current Month Electricity Bill",
      "Current month Telephone Bill",
      "Aadhar Card",
      "Bank Statement",
      "Passport size Photo",
      "Recent Utility bill of the Business Place",
    ],
  },
  {
    title: "To register a Limited Liability Partnership (LLP) in India,",
    subtitle: " you will need to prepare and submit the following documents",
    points: [
      "1. Digital Signature Certificate (DSC) - Every designated partner of the LLP must have their own DSC for signing and filing the registration documents online.",
      "2. Director Identification Number (DIN) - Each designated partner must obtain a DIN from the Ministry of Corporate Affairs.",
      "3. Name approval application - The LLP's proposed name must be submitted to the Ministry of Corporate Affairs for approval. You will need to provide at least 6 potential names in order of preference.",
      "4. LLP agreement - This document outlines the LLP's structure, business objectives, and the rights and responsibilities of its partners. It must be signed by all partners and notarized.",
      "5. Address proof - You will need to provide proof of the registered office address of the LLP, such as a rental agreement or utility bill.",
      "6. Identity proof - Each designated partner must provide a copy of their PAN card or passport as identity proof.",
      "7. Address proof - Each designated partner must provide a copy of their Aadhaar card or voter ID card as address proof.",
    ],
    footer:
      "Once you have all these documents, you can file an online application for LLP registration with the Ministry of Corporate Affairs.",
  },
];
const dataPartnershipFirm = [
  {
    pagename: "Partnership",
  },
  {
    title: "Partnership Firm",
    desc: [
      "A partnership firm in India is prescribed in the Indian Partnership Act of 1932. This Act says the duties of the partners between themselves and other legal relations between partners .",
      "A partnership business structure where two or more individuals share ownership and management of a business. In a partnership, the partners contribute capital and expertise to the business and share in the profits and losses.",
      "There are different types of partnerships, including general partnerships, limited partnerships, and limited liability partnerships. In a general partnership, all partners have unlimited liability for the debts and obligations of the partnership. In a limited partnership, there are both general partners who have unlimited liability, and limited partners who have limited liability. In a limited liability partnership, all partners have limited liability for the debts and obligations of the partnership.",
    ],
  },
  {
    title: "Number of membership",
    desc: [
      "For firms running a business the number must not exceed 20. For banks.",
    ],
  },
  {
    title: "Documents Required for Partnership",
    points: [
      "Rental Agreement",
      "Aadhar card of the Partners",
      "Pan card of the Partners",
    ],
  },
];
const dataprivatelimitedcompany = [
  {
    pagename: "Private Limited Company",
  },
  {
    title: "Documents Required For Private Limited Registration",
    subtitle:
      "A formal agreement between two or more parties to manage and operate the business",
    desc: ["Min 2 Partners", "Max 50 Partners"],
    points: [
      "Passport (For Foreign Nationals)",
      "Voters ID",
      "Ration Card",
      "Driving License",
      "Current Month Electricity Bill",
      "Current month Telephone Bill",
      "Aadhar Card",
      "Rental Agreement with Landlord at Company",
      "Name",
      "Name Recent Tax Receipt for the premises",
      "Board Resolution Authorising Investment",
      "Passport size Photo",
      "Recent Utility bill of the Business Place",
    ],
  },
  {
    title: "Private Limited Company",

    desc: ["key features of a private limited company:"],
    subtitle: [
      "A private limited company is a type of business structure in which the company is privately owned and has a limited number of shareholders (typically 2-200). It is a separate legal entity, distinct from its owners, and has limited liability protection for its shareholders.",
    ],
    points: [
      "1. Limited Liability Protection: The liability of the shareholders is limited to the amount of their investment in the company. In other words, the personal assets of the shareholders cannot be used to settle the debts or liabilities of the company.",
      "2. Separate Legal Entity: The company is a separate legal entity from its owners, which means that it can sue and be sued in its own name, own property, and enter into contracts.",
      "3. Shareholders: A private limited company can have between 2 to 200 shareholders. The shares of a private limited company are not available to the general public, and cannot be traded on the stock exchange.",
      "4. Directorship: A private limited company must have at least one director, who is responsible for managing the affairs of the company. The director can also be a shareholder of the company.",
      "5. Statutory Compliance: A private limited company is required to comply with various legal and regulatory requirements under the Companies Act, such as maintaining proper books of accounts, filing annual returns, and conducting board meetings.",
      "6. Capital: A private limited company must have a minimum paid-up capital of Rs. 1 lakh.",

      "Private limited companies are often preferred by small and medium-sized businesses due to their limited liability protection, separate legal entity, and ability to raise funds from private investors. They offer greater flexibility and control to the owners, while also providing credibility and a professional image to the business. However, setting up and running a private limited company can be more complex and costly than other forms of business structures, and may require the assistance of legal and financial professionals.",
      "The minimum capital requirement for a private limited company in India is Rs. 1 lakh. This means that the company must have a minimum paid-up capital of Rs. 1 lakh at the time of incorporation. The paid-up capital is the actual amount of money that has been invested by the shareholders in the company.",
      "It is important to note that the minimum capital requirement for a private limited company does not mean that the company needs to have a net worth of Rs. 1 lakh or more. The capital requirement only refers to the amount of money that has been invested by the shareholders at the time of incorporation.",
      "However, it is important to remember that the capital requirement for a private limited company may vary depending on the business requirements and the industry in which the company operates. In some cases, a higher capital investment may be required to meet the initial expenses and working capital needs of the business",
      "It is also worth noting that the paid-up capital of a private limited company can be increased at any time by issuing additional shares to the shareholders. However, this process may require compliance with certain legal and regulatory requirements, and the approval of the shareholders and the Registrar of Companies.",
    ],
  },
  {
    title: "Company Registration Process",
    subtitle: "Step 1: RUN Name Approval",
    desc: [
      "Name approval form to be submitted to the Ministry of Corporate Affairs to reserve the company name. In the name approval application 3 names with business objectives can be submitted. If a name approval is rejected, 1 or 2 more names to be resubmitted. Normally, the MCA approves all name approval applications in less than 5 working days.",
    ],
  },
  {
    subtitle: ["Step 2: Digital Signature for Directors"],
  },
  {
    subtitle: ["Step 3: Incorporation Application"],
    desc: ["Submission"],
    footer: [
      "Once the digital signatures are obtained, the incorporation application can be filed in SPICe Form to the MCA with all relevant attachments. Along with the incorporation application, the Memorandum of Association (MOA) and Articles of Association (AOA) of the company are filed. MCA accepts, the Incorporation Certificate is granted along with PAN of the company.",
    ],
  },
  {
    subtitle: ["Private Limited Company Compliances"],
    points: [
      "Director DIN KYC",
      "Auditor Appointment",
      "Commencement of Business",

      "Within 180 days of incorporation, the company must open a Bank Current Account and the shareholders must deposit the subscription amount mentioned in the MOA of the company. Hence, if the company was to be incorporated with a paid-up capital of Rs. 1 lakh, then the shareholders must deposit Rs. 1 lakh in the Company’s bank account and file the bank statement with the MCA to obtain a commencement of business certificate.",
    ],
  },
  {
    subtitle: ["MCA Annual Filings"],
  },
];
const dataProprietorship = [
  {
    pagename: "Proprietorship",
  },
  {
    title: "Proprietorship",
    points: [
      "1. Choose a business name: Select a unique name for your business that is not already registered by another business.",
      "2. Obtain a PAN Card: As a proprietor, you will need a Permanent Account Number (PAN) card in your name. You can apply for it online or at the nearest UTI or NSDL office.",
      "3. Open a bank account: Open a current bank account in the name of the business.",
      "4. Register for GST: If your annual turnover is more than Rs. 40 lakhs, you will need to register for Goods and Services Tax (GST) and obtain a GST number.",
      "5. Obtain necessary licenses and permits: Depending on the type of business, you may need to obtain certain licenses and permits from local or state authorities.",
      "6. Register with the Registrar of Firms: If you want to register your proprietorship firm, you can do so with the Registrar of Firms. However, registration is optional and not mandatory.",
    ],
    footer:
      "The exact registration process may vary depending on the state you are in. It is recommended to consult a professional or visit the website of the Ministry of Corporate Affairs for more information.",
  },
  {
    title: "Proprietorship Registrations & Licenses",
    desc: [
      "To run a proprietorship business in India, the proprietor will have to obtain PAN and Aadhar. The proprietor must obtain GST registration, UDYAM registration and open a bank current account. In some states, the proprietor will also have to obtain Shops & Establishment Act registration.",
      "In addition to the basic requirements above, additional licence and permits may be required depending on the industry, state, and local regulations.",
    ],
  },
  {
    title: "Advantages of Proprietorship",
    subtitle: ["Easy registration"],
  },
  {
    subtitle: ["Lower compliance:"],
    desc: [
      "As most proprietorship are only registered with government departments like Income Tax & GST, the compliance burden will be lower",
    ],
  },
  {
    subtitle: ["Easy way:"],
    desc: [
      " proprietor can easily operate his business with minimal documents and low requirements. Proprietorship type of business structure is best suited for very small businesses.",
    ],
  },
  {
    subtitle: ["Decision Making"],
    desc: [
      "In this type of business owner can take his own business decisions. There is no consent or approval required from any other person. Hence, a proprietor can take quick decisions regarding his business .",
    ],
  },
  {
    subtitle: ["Control on Business"],
    desc: [
      "Proprietorship is owned only by the proprietor. He/she has complete control over the assets, revenue, expenses and all business operations.",
    ],
  },
  {
    title: "Registering a Proprietorship through Dalmaf",
    desc: [
      "Easily register a proprietorship through Dalmaf. To register a proprietorship, only the PAN & Aadhaar card of the business owner is required. To Guide you we will make a personal visit to your place and fulfil your requirement on registering the firm. We can help you obtain the following registrations in less than 15 days:",
    ],
    points: ["· GST Registration", "· UDYAM Registration"],
  },
  {
    title: "GST Registration for Proprietorship",
    desc: [
      "GST registration for a proprietorship will be obtained by Dalmaf as a part of the service. The following documents are required for GST registration:",
    ],
    points: [
      "· Permanent Account Number (PAN) of Proprietor",
      "· Digital Signature Certificate of the Authorized Signatory",
      "· Consent by Proprietor for obtaining GST Registration",
      "· Photograph of Proprietor and Authorized Signatory",
      "· Bank Account Details: A scanned copy of a cancelled cheque with the business entity's name, bank account number, MICR, IFSC, and branch information.",
      "· Declaration / Authorization to Authorized Signatory",
      "· For commercial purposes, the rent / lease agreement should be in the name of the proprietor.",
      "· Additional documents such as Aadhaar Card, Driving Licence, Passport, or Voter ID in the name of the Owner with the complete address of the premises should also be provided if the address on the ownership document (Property Tax Receipt or Municipal Khata copy or copy of Electricity Bill) is incomplete.",
    ],
  },
];
const dataDigitalSignature = [
  {
    pagename: "Digital Signature",
  },
  {
    title: "Digital Signature",
    subtitle: "Required Documents",
    points: [
      "Pan card Copy",
      "Passport",
      "Electricity Bill",
      "Gas Bill",
      "Aadhaar Copy",
      "Passport size Photo",
    ],
  },
  {
    title: "A digital signature certificate (DSC)",
    subtitle: [
      "A digital signature certificate (DSC) is a secure electronic key that is used to digitally sign documents and transactions. It is essentially the digital equivalent of a handwritten signature and provides authenticity, integrity, and confidentiality to digital documents and transactions.",
    ],
    desc: ["Here are the steps to obtain a digital signature certificate:"],
    points: [
      "1. Choose a certifying authority: The first step is to choose a certifying authority (CA) that is authorized by the Controller of Certifying Authorities (CCA) under the Ministry of Electronics and Information Technology, Government of India.",
      "2. Fill in the application form: You need to fill in the application form for the digital signature certificate and provide your personal and organizational details as required. You also need to provide the required documents such as proof of identity, proof of address, and a photograph.",
      "3. Submit the application form: You can submit the application form online or in person at the office of the chosen CA along with the required documents and payment.",
      "4. Verify your identity: Once the application is submitted, you will be required to undergo a process of identity verification, which may include a personal visit to the CA's office, submission of additional documents, or verification through video conferencing.",
      "5. Receive the digital signature certificate: After the verification process is completed, the digital signature certificate will be issued to you either on a USB token or a smart card. You can then use the digital signature certificate to sign electronic documents and transactions.",
    ],
    footer:
      "Note that the process and requirements for obtaining a digital signature certificate may vary depending on the certifying authority chosen and the type of digital signature certificate required.",
  },
  {
    title: "Class 3 Digital Signature Certificate",
    subtitle: [
      "Class 3 Digital Signature Certificate (DSC) is the highest level of digital signature certificate issued by the Certifying Authorities (CA) in India. It is used for online transactions that require a high level of security and confidentiality, such as filing e-tenders, e-procurement, e-bidding, e-auctions, and other online transactions related to government organizations and financial institutions.",
    ],
    desc: [
      "Here are some key features and benefits of Class 3 Digital Signature Certificate:",
    ],
    points: [
      "6. High-level security: Class 3 DSC offers the highest level of security for online transactions as it uses the Public Key Infrastructure (PKI) technology to ensure the authenticity, confidentiality, and integrity of electronic documents and transactions.",
      "7. Legal validity: Class 3 DSC is legally valid and recognized under the Information Technology Act, 2000, and various other government regulations.",
      "8. Multiple uses: Class 3 DSC can be used for a variety of online transactions, including filing e-tenders, e-procurement, e-bidding, e-auctions, and other online transactions related to government organizations and financial institutions.",
      "9. Fast processing: The process for obtaining a Class 3 DSC is fast and simple, and can be done online or in person",
      "10. Wide acceptance: Class 3 DSC is widely accepted across various government organizations, banks, and financial institutions.",
    ],
    footer:
      "To obtain a Class 3 DSC, you need to follow the standard procedure for obtaining a digital signature certificate, as mentioned in my previous response, but you need to choose Class 3 DSC instead of other types of digital signature certificates.",
  },
  {
    title: "Class 3 DSC for Individual",
    subtitle: [
      "Yes, Class 3 Digital Signature Certificate (DSC) can be issued to individuals as well. It is mainly used for online transactions that require a high level of security and confidentiality, such as filing income tax returns, e-tendering, e-procurement, and other transactions related to government organizations and financial institutions.",
    ],
    desc: ["Here is the process to obtain a Class 3 DSC for individuals:"],
    points: [
      "11. Fill in the application form: You need to fill in the application form for the Class 3 DSC and provide your personal details as required. You also need to provide the required documents such as proof of identity, proof of address, and a photograph.",
      "12. Verify your identity: After the application is submitted, you need to undergo a process of identity verification, which may include a personal visit to Dalmaf office, submission of additional documents, or verification through video conferencing.",
      "13. Receive the digital signature certificate: After the verification process is completed, the Class 3 DSC will be issued to you either on a USB token or a smart card. You can then use the digital signature certificate to sign electronic documents and transactions.",
    ],
    footer:
      "Note that the process and requirements for obtaining a Class 3 DSC for individuals may vary depending on the certifying authority chosen and the type of Class 3 DSC required.",
  },
  {
    title: "Class 3 DSC for Company/Organization",
    desc: [
      "Yes, Class 3 Digital Signature Certificate (DSC) can also be issued to companies and organizations. It is mainly used for online transactions that require a high level of security and confidentiality, such as filing e-tenders, e-procurement, e-bidding, e-auctions, and other transactions related to government organizations and financial institutions.",
    ],
    points: [
      "Obtain a Class 2 DSC for authorized signatory: The authorized signatory of the company/organization needs to obtain a Class 2 DSC first, which is required for applying for a Class 3 DSC for the organization.",
    ],
  },
  {
    desc: [
      "Fill in the application form: The authorized signatory needs to fill in the application form for the Class 3 DSC for the company/organization and provide the required details and documents such as the company's PAN card, proof of address, and a photograph.",
      "Verify the identity: After the application is submitted, the authorized signatory needs to undergo a process of identity verification, personal visit to the verification through video conferencing.",
      "Receive the digital signature certificate: After the verification process is completed, the Class 3 DSC will be issued to the authorized signatory on a USB token or a smart card. The company/organization can then use the digital signature certificate for online transactions.",
    ],
  },
];
const dataStartupIndia = [
  {
    pagename: "Startup India Registration",
  },
  {
    title: "Documents Required",
    points: ["Proof of Funding", "Documents of Award", "Patent Documents"],
  },
  {
    title: "Startup India Recognition",
    subtitle: "Eligibility",
    desc: [
      "The Startup India Recognition is a program initiated by the Government of India to promote and support startups in the country. To be eligible for the Startup India Recognition, a startup must meet the following criteria:",
    ],
    points: [
      "1. The startup should be registered as a Private Limited Company, Partnership Firm, Limited Liability Partnership, or One Person Company.",
      "2. The startup should be incorporated or registered in India for less than 10 years from the date of its incorporation/registration.",
      "3. The turnover of the startup should not have exceeded INR 100 crores in any of the previous financial years.",
      "4. The startup should be working towards innovation, development, deployment or commercialization of new products, processes or services driven by technology or intellectual property.",
      "5. The startup should aim to create or add value to the society and create employment opportunities.",
    ],
    footer: [
      "If a startup meets the above-mentioned criteria, it can apply for the Startup India Recognition by registering on the Startup India website and submitting the required documents and information. The startup will be evaluated based on its innovation, scalability, potential for job creation, and social impact. Once recognized, the startup will receive various benefits and support from the government to help it grow and succeed.",
      "The DPIIT (Department for Promotion of Industry and Internal Trade) certificate is a recognition given by the Government of India to eligible startups under the Startup India Scheme.",
    ],
  },
  {
    title: "Procedure to get DPIIT Certificate",
    subtitle: "Here is the procedure to obtain the DPIIT certificate:",
    points: [
      "1. Register on the Startup India portal: The first step is to register your startup on the Startup India portal (www.startupindia.gov.in) and provide all the necessary details about your startup.",
      "2. Submit the required documents: Once you have registered on the portal, you need to submit the required documents such as the Certificate of Incorporation, Memorandum of Association, Articles of Association, PAN card, etc. along with a brief write-up about your startup.",
      "3. Evaluation by DPIIT: The DPIIT will evaluate your application based on various criteria such as innovation, scalability, potential for job creation, and social impact. If your startup meets the eligibility criteria and is found to be innovative and capable of creating a positive impact on society, you will be granted the DPIIT certificate.",
      "4. Receive the certificate: If your application is approved, you will receive the DPIIT certificate within 10-15 working days through the Startup India portal.",
    ],
    footer:
      "Note that obtaining the DPIIT certificate can provide various benefits and incentives to your startup, such as tax exemptions, easier access to funding, and other support services from the government.",
  },
  {
    points: [
      "· Entity Details: Nature of Entity, Industry, Sector, Categories and Company Incorporation Number and Registration Date",
      "· Full Address of the Entity",
      "· Details of the Authorized Representative",
      "· Directors or Partner Details",
      "· Details of Intellectual Property Right",
      "· Details of funding",
      "· Recognition received by the entity",
    ],
  },
  {
    title: "Get the Startup Recognition Number",
    desc: [
      "The DPIIT Certificate of Recognition for Startups will be issued after examination of the application and documents submitted.",
      "Once the ministry approves the application and provides the unique startup recognition number, the startup can be registered with tax benefits.",
    ],
  },
];
const dataECodeRegistration = [
  {
    pagename: "Import Export Code",
  },
  {
    title: "Importer Exporter Code (IEC Code) Registration",
    subtitle: [
      "Importer Exporter Code (IEC) is a registration required for businesses in India that import or export goods and services. It is a 10-digit code issued by the Directorate General of Foreign Trade (DGFT) under the Ministry of Commerce and Industry, Government of India.",
    ],
    desc: ["Here is the process for IEC code registration:"],
    points: [
      "1. Obtain a digital signature certificate (DSC): The first step is to obtain a Class 2 or Class 3 digital signature certificate (DSC) from a certifying authority authorized by the Controller of Certifying Authorities (CCA).",
      "2. Fill in the application form: The applicant needs to fill in the IEC application form online and provide details such as the name and address of the business, PAN card details, bank account details, and other relevant information.",
      "3. Submit the application: After filling in the application form, the applicant needs to submit it online along with the required documents such as PAN card copy, address proof, and bank account details.",
      "4. Pay the fee: Once the application is submitted, the applicant needs to pay the IEC code registration fee online through net banking, credit/debit card, or other payment modes.",
      "5. Verification and approval: After the payment is made, the application is verified by the concerned authorities. If the application is complete and all the documents are in order, the IEC code is issued within 2-3 working days.",
    ],
  },
  {
    title: "The documents required for IEC code registration are:",
    points: [
      "1. PAN card copy of the business",
      "2. Passport size photograph of the proprietor/partners/directors",
      "3. Address proof of the business premises",
      "4. Bank account details of the business",
    ],
  },
  {
    title: "Advantages of IEC code registration include:",
    points: [
      "1. Legal recognition: IEC code registration provides legal recognition to businesses engaged in import and export activities.",
      "2. Global market access: IEC code enables businesses to access global markets and expand their business beyond the domestic market.",
      "3. Easy remittance: IEC code is required for businesses to receive payments from foreign buyers and to make payments to foreign suppliers.",
      "4. Avail benefits and schemes: IEC code registration makes businesses eligible for various government schemes and benefits related to import and export activities.",
      "5. Easy customs clearance: IEC code is mandatory for customs clearance of goods and services, making the import-export process easier and smoother for businesses.",
    ],
  },
  {
    title: "Import Export Code (IE Code)",
    subtitle: [
      "Import Export Code (IE Code) is a registration issued by the Directorate General of Foreign Trade (DGFT) to Indian entities engaged in international trade. It is a 10-digit code that is mandatory for businesses that wish to engage in import or export activities.",
    ],
    desc: ["Here are the key features of IE Code:"],
    points: [
      "1. Identification of business: IE Code serves as a unique identification number for businesses engaged in international trade.",
      "2. One-time registration: IE Code registration is a one-time process and does not need to be renewed.",
      "3. Mandatory for import/export: IE Code is mandatory for businesses that wish to engage in import or export activities.",
      "4. No tax-related benefits: Unlike other registrations such as GST registration, IE Code does not provide any tax-related benefits to businesses.",
      "5. No renewal required: IE Code is issued for the lifetime of the business and does not need to be renewed.",
    ],
  },
  {
    title: "Process for IE Code registration:",
    points: [
      "1. Gather required documents: The applicant needs to gather the required documents such as PAN card, address proof, and bank details.",
      "2. Fill in the application form: The applicant needs to fill in the IE Code application form and provide details such as the name and address of the business, PAN card details, bank account details, and other relevant information.",
      "3. Submit the application: After filling in the application form, the applicant needs to submit it online along with the required documents.",
      "4. Pay the fee: Once the application is submitted, the applicant needs to pay the IE Code registration fee online through net banking, credit/debit card, or other payment modes.",
      "5. Verification and approval: After the payment is made, the application is verified by the concerned authorities. If the application is complete and all the documents are in order, the IE Code is issued within 2-3 working days.",
    ],
    footer:
      "In summary, IE Code is a mandatory registration for businesses engaged in international trade. The registration process is simple and can be done online through the official DGFT website. Once registered, businesses can engage in import and export activities without any legal hassles.",
  },
  {
    title: "Importance of Import Export Code",
    desc: [
      "Import Export Code (IEC) is a 10-digit code issued by the Directorate General of Foreign Trade (DGFT) under the Ministry of Commerce and Industry, Government of India. It is a mandatory registration for businesses engaged in international trade, and here are some of the reasons why it is important:",
    ],
    points: [
      "1. Legal recognition: IEC code provides legal recognition to businesses engaged in import and export activities. Without an IEC code, businesses cannot engage in international trade.",
      "2. Global market access: IEC code enables businesses to access global markets and expand their business beyond the domestic market. With an IEC code, businesses can import and export goods and services to and from other countries.",
      "3. Easy remittance: IEC code is required for businesses to receive payments from foreign buyers and to make payments to foreign suppliers. Without an IEC code, businesses cannot engage in international financial transactions.",
      "4. Avail benefits and schemes: IEC code registration makes businesses eligible for various government schemes and benefits related to import and export activities. For example, businesses with an IEC code can avail benefits under the Merchandise Exports from India Scheme (MEIS).",
      "5. Easy customs clearance: IEC code is mandatory for customs clearance of goods and services, making the import-export process easier and smoother for businesses. Without an IEC code, businesses may face delays and legal issues while importing or exporting goods.",
    ],
    footer:
      "IEC code is a crucial registration for businesses engaged in international trade. It provides legal recognition, enables access to global markets, facilitates international financial transactions, makes businesses eligible for government schemes and benefits, and eases customs clearance.",
  },
  {
    title: "Documents required for IEC Code registration",
    subtitle:
      "The list of scanned documents required for IEC Registration is listed as follows:",
    points: [
      "· Proof of establishment/incorporation/registration: The following type of Firm needs to submit the establishment/incorporation/registration certificate:",
      "· Partnership",
      "· Registered Society",
      "· Trust",
      "· HUF",
      "· Other",
      "· Proof of Address: Proof of Address can be any one of the following documents:",
      "· Sale Deed",
      "· Rent agreement",
      "· Lease deed",
      "· Electricity bil",
      "· Telephone landline bill",
      "· Mobile, post-paid bill",
      "· MoU",
      "· Partnership deed",
      "· Other acceptable documents (for proprietorship only):",
      "· Aadhar card",
      "· Passport",
      "· Voter id",
    ],
  },
  {
    title: "Validity of IE Code",
    desc: [
      "IE Code (Import Export Code) is a registration issued by the Directorate General of Foreign Trade (DGFT) under the Ministry of Commerce and Industry, Government of India. The validity of IE Code is for the lifetime of the entity or business that has been issued the code. This means that once an entity or business obtains an IE Code, it does not need to be renewed.",
      "IE Code is a one-time registration, and it remains valid until the entity or business is in operation. It is important to note that IE Code is issued to a specific entity or business and cannot be transferred or used by another entity or business.",
      "However, in certain cases, an entity or business may need to modify its existing IE Code due to changes in its name, address, or constitution. In such cases, the entity or business can apply for a modification of the existing IE Code through the DGFT website. The modification does not affect the validity of the IE Code.",
      "In summary, IE Code is a lifetime registration, and once issued, it remains valid for the entity or business for as long as it is in operation. There is no need to renew the IE Code, but modifications may be required in certain cases.",
    ],
  },
];
const dataOnePersonCompany = [
  {
    pagename: "One person Company",
  },
  {
    title: "One person Company",
    desc: [],
    subtitle: "Documents Required",

    points: [
      "PAN Card",
      " Passport Voters",
      "Voters Identity Card",
      "A One Person Company (OPC) is a type of company structure in which a single individual can form a company and become its sole director and shareholder. It is a relatively new concept in India that was introduced by the Companies Act, 2013, to encourage entrepreneurship and make it easier for individuals to start their own businesses.",
      "Here are some key features of an OPC:",
      "1. One Director and Shareholder: An OPC must have only one director and shareholder, who can be the same person. This means that the owner has complete control over the company's management and operations.",
      "2. Limited Liability: The liability of the owner is limited to the extent of their shareholding in the company. In other words, the personal assets of the owner cannot be used to settle the debts or liabilities of the company.",
      "3. Perpetual Succession: The company has a separate legal entity from the owner and, therefore, can continue to exist even if the owner dies or becomes incapacitated.",
      "4. Minimum Capital Requirement: There is no minimum capital requirement to start an OPC. However, the owner must contribute at least Rs. 1 lakh as the authorized share capital of the company.",
      "5. Annual Compliance: Like any other company, an OPC must comply with the annual filing and compliance requirements of the Companies Act, such as maintaining proper books of accounts and filing annual returns with the Registrar of Companies",
    ],
    footer:
      "OPC is a good option for individuals who want to start a business but do not have a partner or a team. It offers the advantages of limited liability and separate legal entity, while allowing the owner to have complete control over the company's management and operations. However, it is important to consult a legal or financial expert",
  },
];
const dataGSTRegistration = [
 
  {
    title: "GST Registration",
    desc: [
      "GST Registration in India refers to the process of obtaining a unique Goods and Services Tax (GST) identification number from the government. It is the legal requirement for businesses whose turnover exceeds the prescribed threshold. GST registration ensures compliance with the GST Act and enables businesses to collect GST on their supplies, claim input tax credit, and engage in interstate trade. It simplifies tax procedures, enhances credibility, promotes a level playing field, and provides access to various benefits and government schemes. Overall, GST registration is the formal recognition of a business as a taxpayer under the GST regime, facilitating seamless and lawful operations.",
    ],
  },
  {
    subtitle:
      ["Important key points regarding GST registration in India",],

    points: [
    "1. Threshold Limit: Entities engaged in the supply of goods or services with an aggregate turnover exceeding a specified threshold are required to register under GST. The threshold limits vary based on the type of business and the state of operation.",
    "2. Mandatory and Voluntary Registration: GST registration is mandatory for businesses meeting the threshold limit criteria. However, businesses below the threshold limit can also opt for voluntary registration to avail various benefits and to comply with GST regulations.",
    "3. GSTIN (GST Identification Number): Upon successful registration, a unique GSTIN is allocated to each registered taxpayer. This 15-digit alphanumeric number is used for all GST-related transactions and communications with tax authorities.",
    "4. Application Process: The registration process involves submitting an online application through the GST portal (https://www.gst.gov.in/). The application requires details such as PAN (Permanent Account Number), proof of constitution, address proof, bank account details, and authorized signatory information.",
    "5. Verification and Approval: Once the application is submitted, it undergoes verification by the tax authorities. They may seek additional information or clarification during the verification process. Upon approval, a GST registration certificate is issued.",
    "6. Composition Scheme: Small taxpayers with an aggregate turnover below a specified limit can opt for the composition scheme, which provides simplified compliance requirements and allows for payment of GST at a fixed rate based on turnover.",
    "7. Compliance and Filing Returns: Registered entities are required to maintain proper books of accounts, file periodic GST returns, and comply with various provisions under the GST law. The frequency of return filing varies based on the type of taxpayer and turnover.",
    "8. Cancellation of Registration: GST registration can be canceled voluntarily if the business ceases operations or undergoes a change in ownership. It can also be canceled by the tax authorities for non-compliance or other reasons specified under the GST law.",
    "9. Structure: GST is categorized into two main components: Central Goods and Services Tax (CGST) levied by the central government and State Goods and Services Tax (SGST) levied by the state governments. Additionally, Integrated Goods and Services Tax (IGST) is applicable on interstate supplies and imports.",
    "10. Tax Slabs: GST classifies goods and services into different tax slabs, primarily four slabs: 5%, 12%, 18%, and 28%. Certain essential goods and services may be taxed at lower rates, while some specific items may attract higher rates or fall under special categories.",
    "11. Input Tax Credit: GST allows businesses to claim input tax credit, which means they can offset the tax paid on inputs (purchases of goods and services) against the tax collected on their outputs (sales of goods and services). This helps to avoid the cascading effect of taxes and promotes a seamless flow of credit.",

    ],
   
  },
  {
    title: "Eligibility for GST Registration",
   
    subtitle:[
   "In India, businesses and individuals need to meet certain criteria to be eligible for GST registration. The eligibility for GST registration is determined based on the following factors:",
    ],
    points: [
     "1. Aggregate Turnover: GST registration is mandatory for businesses whose aggregate turnover in a financial year exceeds the prescribed threshold limit. The threshold limit varies based on the nature of the business and the state in which it operates. As of September 2021, the threshold limits for GST registration are as follows:",
     "a) For businesses operating in most states: Aggregate turnover exceeding Rs. 40 lakhs.",
     "b) For businesses operating in specified northeastern and hilly states: Aggregate turnover exceeding Rs. 20 lakhs.",
     "2. Mandatory Registration Categories: Certain categories of businesses are required to register for GST regardless of their turnover. These categories include:",
     "a) Inter-State Suppliers: Businesses involved in the supply of goods or services across state borders.",
     "b) E-Commerce Operators: Platforms that facilitate the supply of goods or services through electronic platforms.",
     "c) Input Service Distributors: Entities that receive invoices for input services and distribute credit to other branches.",
     "d) Casual Taxable Persons: Individuals or businesses that occasionally undertake transactions liable to GST.",
     "e) Non-Resident Taxable Persons: Foreign individuals or businesses making taxable supplies in India.",
     "3. Voluntary Registration: Even if a business does not meet the mandatory registration criteria, it can opt for voluntary GST registration. Voluntary registration allows businesses to avail of various benefits, such as claiming input tax credit, participating in interstate trade, and establishing their credibility with customers and suppliers.",
     
    ],
    footer:[
     "It's important to note that separate provisions and thresholds exist for certain specific industries and activities, such as composition scheme taxpayers, restaurants, and small-scale businesses. These provisions have their own eligibility criteria and compliance requirements.",
    ],
  },
  {
    title: "Types of GST Registration",
    subtitle:[
      "In India, there are primarily three types of GST registration, each catering to specific categories of taxpayers. The types of GST registration in India are as follows:",
    ],
    points: [
      "1. Regular GST Registration: Regular GST registration is the most common type of registration for businesses that exceed the prescribed threshold limit for GST registration. It is applicable to businesses engaged in the supply of goods or services within a state or across multiple states.",
    ],
  },
  {
    subtitle:"Key features of Regular GST registration include:",
    points:[
      "· GSTIN (Goods and Services Tax Identification Number) is issued upon successful registration.",
      "· Businesses are required to file regular GST returns and comply with the provisions of the GST law.",
      "· Input tax credit can be claimed on eligible purchases made for business purposes.",
      "· The taxpayer is subject to the regular tax rates applicable to their goods or services.",
    ],
  },
  {
    points:[
      "2. Composition Scheme Registration: The composition scheme is an optional scheme available for eligible small businesses with a lower turnover threshold. Under this scheme, taxpayers can pay tax at a fixed percentage of their turnover instead of the regular tax rates. The composition scheme aims to simplify compliance requirements for small businesses.",
    ],
  },
  {
    subtitle:[
      "Key features of Composition Scheme Registration include:",
     ],
     points:[
      "· Simplified compliance procedures, such as filing quarterly returns.",
      "· Tax is paid at a fixed percentage of turnover (lower rates compared to regular tax rates).",
      "· Input tax credit cannot be claimed under the composition scheme.",
      "· Certain restrictions and conditions apply, such as limitations on interstate supplies and eligibility criteria based on turnover and nature of business.",
     ],
  },
  {
    points:[
      "3. Non-Resident GST Registration: Non-resident GST registration is required for businesses or individuals located outside India that engage in taxable supplies of goods or services within India. Non-resident taxpayers are required to register for GST and comply with the relevant provisions.",
    ],
  },
  {
    subtitle:["Key features of Non-Resident GST Registration include:",
  ],
    points:
    [
      "· GSTIN is issued to non-resident taxpayers upon registration.",
      "· Non-resident taxpayers are required to appoint an authorized representative in India for GST compliance.",
      "· Compliance requirements may differ for non-resident taxpayers compared to regular taxpayers.",
      "· Non-resident taxpayers need to file GST returns and fulfill their tax obligations in India.",
    ],
  },
  {
    title: "Benefits of GST Registration",
    
    points: [
      "1. Legally Compliant: GST registration ensures that businesses comply with the legal requirements of the Goods and Services Tax Act. It helps businesses operate within the framework of the law, avoiding penalties and legal consequences for non-compliance.",
      "2. Input Tax Credit (ITC): Registered businesses can claim input tax credit, allowing them to offset the tax paid on inputs against the tax liability on their outputs. This helps in reducing the overall tax burden and prevents the cascading effect of taxes, ultimately reducing the cost of goods and services.",
      "3. Seamless Interstate Trade: GST registration enables businesses to engage in interstate trade without restrictions. It eliminates barriers and check-posts, promoting the seamless movement of goods and services across state borders. This simplifies logistics and reduces transportation time and costs.",
      "4. Competitive Advantage: GST registration provides a competitive edge in the market. Registered businesses can demonstrate their compliance with GST regulations to clients, customers, and suppliers, enhancing their credibility and reliability. It also makes them eligible to participate in government tenders and contracts that require GST registration.",
      "5. Access to Input Suppliers: Many suppliers and vendors prefer to deal with GST-registered businesses to claim input tax credit. Registering for GST expands the supplier network and facilitates access to reliable and compliant vendors, potentially improving the quality and cost-effectiveness of inputs and supplies.",
      "6. Ability to Collect GST: Registered businesses have the legal authority to collect and charge GST on their supplies of goods and services. This allows them to pass on the tax liability to their customers and prevents disputes or non-compliance issues related to tax collection.",
    ],
  },
  {
    title: "Penalty for Not Obtaining GST Registration",
    desc: [
      "Failure to obtain GST registration in India when required can lead to penalties and legal consequences. The penalties for not obtaining GST registration depend on the nature of the offense and the duration of non-compliance. Here are the key penalty provisions related to non-registration under GST",
    ],
    points: [
      "1. Late Registration Penalty: If a person or entity liable to register under GST fails to do so within the prescribed time, a penalty is imposed. The penalty is calculated as a percentage of the tax liability, subject to a minimum amount. The penalty is 10% of the tax due, with a minimum of Rs. 10,000 for regular taxpayers. For small taxpayers opting for the composition scheme, the penalty is 2.5% of the tax due, with a minimum of Rs. 10,000.",
      "2. Penalty for Tax Evasion: Non-registration or under-reporting of turnover to evade taxes may attract penalties under the anti-evasion provisions of the GST law. The penalty can be up to 100% of the tax amount evaded or not paid.",
      "3. Interest on Late Payment: If a person or entity is liable to pay GST but fails to make timely payment, interest is charged on the outstanding amount. The interest rate is typically 18% per annum, calculated from the due date until the date of payment.",
      "4. Legal Proceedings: Non-registration or non-compliance with GST provisions can lead to legal proceedings, including prosecution and fines. Authorities have the power to initiate investigations, conduct audits, and take necessary actions to ensure compliance.",
     ],
    footer:
      ["It's important to note that penalties can vary based on the specific circumstances, nature of the offense, and the discretion of the tax authorities. The penalty provisions under GST are aimed at encouraging compliance and deterring non-compliance and tax evasion.",
    ],
  },
  {
    title: "Required Documents for GST Registration",
    desc: ["Note: Common Required Documents for All Entities (Rental Agreement, NOC from the Landlord, Utility Bill of the Principal Business Place)"],
    points: [
     "1. Sole Proprietorship / Individuals",
     "a) PAN card of the Applicant",
     "b) Aadhar card of the Applicant",
     "c) Photograph of the Applicant",
     "d) Bank Account Details",
     "e) Address Proof of Principal Business Place",
     "2. LLP and Partnership Firms",
     "a) PAN card of all Partners (including Managing Partner and Authorized Signatory)",
     "b) Copy of Partnership Deed",
     "c) Photograph of all Partners and Authorised Signatories",
     "d) Address Proof of Partners",
     "e) Aadhar card of Authorised Signatories",
     "f) Proof of appointment of Authorised Signatories",
     "g) In the case of LLP, registration certificate / Board resolution of LLP",
     "h) Bank Account details",
     "i) Address proof of Principal Place of Business",
     "3. HUF",
     "a) PAN card of HUF",
     "b) PAN card and Aadhar card of Karta",
     "c) Photograph of the Owner",
     "d) Bank Account details",
     "e) Address proof of Principal Place of Business",
     "4. Company (Private Limited and Public Limited)",
     "a) PAN card of Company",
     "b) Certificate of Incorporation given by Ministry of Corporate Affairs",
     "c) Memorandum of Association / Articles of Association",
     "d) PAN card and Aadhar card of authorized signatory. The authorised signatory must be an Indian even in case of foreign companies/branch registration",
     "e) PAN card and address proof of all directors of the Company",
     "f) Photograph of all directors and authorised signatory",
     "g) Board Resolution Appointing Authorised Signatory / Any other proof of Appointment of Authorised Signatory",
     "h) Bank Account Details",
     "i) Address Proof of Principal Place of Business",
    ],  
  },

];
const dataGSTAdvisoryServices = [
  {
    title:"GST Advisory Services",
    subtitle:[
      "Advisory services for GST in India encompass professional guidance and expertise provided by tax consultants, accounting firms, or GST experts to assist businesses in navigating the complexities of the Goods and Services Tax (GST) regime. These services aim to help businesses understand and comply with GST regulations, optimize their tax positions, and mitigate any potential risks or issues. Advisory services for GST in India may include:",
    ],
    points:[
      "1. GST Registration Assistance: Helping businesses determine their GST registration requirements, assisting with the registration process, and ensuring compliance with registration-related formalities.",
      "2. GST Impact Analysis: Assessing the impact of GST on a business's operations, supply chain, pricing structure, and financials. This analysis helps businesses understand the implications of GST and make informed decisions.",
      "3. GST Compliance Review: Conducting a comprehensive review of a business's GST compliance processes, systems, and documentation to identify any non-compliance areas, gaps, or potential risks.",
      "4. GST Advisory for Specific Transactions: Providing guidance and advice on GST implications for specific transactions, such as mergers and acquisitions, intercompany transfers, cross-border transactions, or complex supply chains.",
      "5. Input Tax Credit Optimization: Assisting businesses in maximizing their eligible Input Tax Credit (ITC) by reviewing and optimizing the ITC claims, ensuring proper documentation, and identifying any potential opportunities for ITC.",
      "6. GST Audit and Investigation Support: Assisting businesses in preparing for GST audits and investigations, providing guidance on record-keeping requirements, and representing them during interactions with tax authorities.",
      "7. Training and Education: Conducting workshops, seminars, or training sessions to educate businesses and their employees about GST laws, compliance requirements, and best practices.",
      "Updates on GST Law and Policy Changes: Keeping businesses informed about the latest developments, amendments, and updates in GST laws, policies, and regulations, and advising on the potential impact on their operations."
    ],
    footer:[
      "Advisory services for GST aim to provide businesses with the necessary expertise and guidance to ensure compliance, optimize tax positions, and make informed decisions in a dynamic GST environment. The scope and nature of advisory services may vary based on the specific needs and requirements of each business. Engaging professional GST advisors can help businesses navigate the complexities of GST and achieve their compliance and strategic objectives.",
    ]
  },
];
const dataGSTReturnFiling = [
  {
    pagename: "GST Return Filing",
  },
  {
    title: "GST Return Filing",
    desc: [
      "GST returns in India refer to the periodic filings that registered taxpayers need to submit to the tax authorities, providing details of their business transactions, tax liabilities, and input tax credits. These returns contain information about sales, purchases, output tax liability, and input tax credit availed. GST returns play a crucial role in the GST system, enabling tax authorities to track and verify the tax liabilities of registered taxpayers, ensuring proper tax compliance and revenue collection.",
    ],
  },
  {
    title: "",
    subtitle: "Important Key Points regarding GST returns in India:",

    points: [
      "· Filing Frequency: GST returns need to be filed based on the prescribed frequency, which varies for different categories of taxpayers. Generally, regular taxpayers file monthly returns, while those under the composition scheme file quarterly returns.",
      "· GSTR Forms: GST returns are filed through various GSTR forms, each serving a specific purpose. Some of the commonly used GSTR forms include GSTR-1 (outward supplies), GSTR-3B (summary return), and GSTR-9 (annual return).",
      "· Information to be Provided: GST returns require taxpayers to furnish details such as invoice-wise outward supplies, tax collected, input tax credit claimed, and details of reverse charge supplies. Other information, such as amendments, adjustments, and corrections, may also be required.",
      "· Input Tax Credit Reconciliation: GST returns facilitate the reconciliation of input tax credit claimed by the recipient with the details furnished by the supplier. This helps in preventing any mismatches or discrepancies in claiming input tax credit.",
      "· Compliance and Payment: Along with filing GST returns, taxpayers are required to pay the tax liability due. The payment is made online through the GSTN portal, and a valid return is a prerequisite for making the payment.",
      "· Late Filing and Penalties: Delayed or non-filing of GST returns attracts penalties and late fees. It is important for businesses to file their returns within the prescribed due dates to avoid such penalties and maintain compliance with GST regulations.",
    ],
  },

  {
    table: (
      <div>
        <h3 className="banner-heading after-line mb-3">
          GST Returns Forms, Description, Frequency, Due Dates
        </h3>
        <div className="table">
          <table className="table table-bordered">
            <thead>
              <tr>
                <td>Return Form</td>
                <td>Description</td>
                <td>Frequency</td>
                <td>Due Date</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan="3">GSTR 1</td>
                <td rowspan="3">
                  Details of outward supplies of taxable goods and/or services
                  affected.
                </td>
                <td>Monthly</td>
                <td>11th of the next month</td>
              </tr>
              <tr>
                <td>Quarterly (If opted under the QRMP scheme)</td>
                <td>13th of the month succeeding the quarte</td>
              </tr>
              <tr></tr>
              <tr>
                <td rowSpan="2">
                  IFF (Optional by taxpayers under the QRMP scheme)
                </td>
                <td rowspan="2">
                  Details of B2B supplies of taxable goods and/or services
                  affected.
                </td>
                <td>Monthly (for the first two months of the quarter)</td>
                <td>13th of the next month.</td>
              </tr>
              <tr></tr>
              <tr>
                <td rowSpan="3">GSTR-3B</td>
                <td rowspan="3">
                  Summary return of outward supplies and input tax credit
                  claimed, along with payment of tax by the taxpayer.
                </td>
                <td>Monthly</td>
                <td>20th of the next month.</td>
              </tr>
              <tr>
                <td>Quarterly (For taxpayers under the QRMP scheme)</td>
                <td>22nd or 24th of the month succeeding the quarter***</td>
              </tr>
              <tr></tr>
              <tr>
                <td>CMP 08</td>
                <td>
                  Statement cum challan to make a tax payment by a taxpayer
                  registered under the composition scheme under Section 10 of
                  the CGST Act.
                </td>
                <td>Quarterly</td>
                <td>18th of the month succeeding the quarter.</td>
              </tr>
              <tr>
                <td>GSTR 4</td>
                <td>
                  Returns to be filed by the taxpayer that is registered under
                  the composition scheme under Section 10 of the CGST Act
                </td>
                <td>Annually</td>
                <td>30th of the month succeeding a financial year.</td>
              </tr>
              <tr>
                <td>GSTR 5</td>
                <td>Returns to be filed by a Non-resident taxable person</td>
                <td>Monthly</td>
                <td>
                  20th of the next month. (Amended to 13th by Budget 2022; yet
                  to be notified by CBIC.)
                </td>
              </tr>
              <tr>
                <td>GSTR-5A</td>
                <td>Return to be filed by non-resident OIDAR service providers.</td>
                <td>Monthly</td>
                <td>
                20th of the next month.
                </td>
              </tr>
              <tr>
                <td>GSTR 6</td>
                <td>
                Return for an input service distributor to distribute the eligible input tax credit to its branches.
                </td>
                <td>Monthly</td>
                <td>13th of the next month.</td>
              </tr>
              <tr>
                <td>GSTR 7</td>
                <td>Return to be filed by registered persons deducting tax at source (TDS).</td>
                <td>Monthly</td>
                <td>10th of the next month.</td>
              </tr>
              <tr>
                <td>GSTR 8</td>
                <td>
                Return to be filed by e-commerce operators containing details of supplies effected and the amount of tax collected at source by them
                </td>
                <td>Monthly</td>
                <td>10th of the next month.</td>
              </tr>
              <tr>
                <td>GSTR 9</td>
                <td>Annual return by a regular taxpayer.</td>
                <td>Annually</td>
                <td>31st December of the next financial year.</td>
              </tr>
              <tr>
                <td>GSTR 9C</td>
                <td>Self-certified reconciliation statement.</td>
                <td>Annually</td>
                <td>31st December of the next financial year.</td>
              </tr>
              <tr>
                <td>GSTR 10</td>
                <td>
                Final return to be filed by a taxpayer whose GST registration is cancelled.
                </td>
                <td>Once, when the GST registration is cancelled or surrendered.</td>
                <td>Within three months of the date of cancellation or date of cancellation order, whichever is later.</td>
              </tr>
              <tr>
                <td>GSTR 11</td>
                <td >
                Details of inward supplies to be furnished by a person having UIN and claiming a refund
                </td>
                <td>Monthly</td>
                <td>28th of the month following the month for which statement is filed.</td>
              </tr>
              <tr>
                <td>ITC-04</td>
                <td>
                Statement to be filed by a principal/job-worker about details of goods sent to/received from a job-worker
                </td>
                <td>Annually
(for AATO up to Rs.5 crore)
Half-yearly
(for AATO - Rs.5 crore)</td>
                <td>25th April where AATO is up to Rs.5 crore.
25th October and 25th April where AATO exceeds Rs.5 crore.

(AATO = Annual aggregate turnover)</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    ),
  },
  {
    title: "Penalties for Not Filing GST Returns",
    subtitle:"Failure to file GST returns in India can lead to penalties and legal consequences. The penalties for non-filing or late filing of GST returns depend on the duration of the delay and the taxpayer's category. Here are the key penalty provisions related to non-filing of GST returns:",
    points: [
     "1. Late Filing Penalty: If a taxpayer fails to file GST returns by the due date, a late filing penalty is imposed. The penalty amount is Rs. 50 per day for CGST and SGST, totalling Rs. 100 per day, subject to a maximum penalty of Rs. 5,000. However, for IGST, the late filing penalty is Rs. 100 per day, totalling Rs. 200 per day, with no maximum limit specified.",
     "2. Tax Liability and Interest: Late filing of GST returns may also result in the payment of interest on the outstanding tax liability. The interest is calculated at a rate of 18% per annum from the due date until the date of filing the return.",
     "3. Disallowance of Input Tax Credit: Non-filing or late filing of GST returns can lead to the disallowance of input tax credit (ITC). If a taxpayer fails to file GSTR-3B for two consecutive tax periods, they are not eligible to claim ITC for subsequent tax periods until the pending returns are filed.",
     "4. Legal Proceedings: Non-filing of GST returns is considered a violation of the GST law. Tax authorities have the power to initiate legal proceedings, audits, and investigations against non-compliant taxpayers. These proceedings can lead to penalties, prosecution, and potential imprisonment under certain circumstances.",
    ],
    footer:"It's crucial for businesses to file GST returns within the prescribed due dates to avoid penalties and maintain compliance with GST regulations. Accurate and timely filing of returns helps businesses claim input tax credit, avoid interest charges, and maintain a good compliance record.",
  },

  {
    title: "Documents Required for Filing GST Returns",
   
    points: [
     "1. Invoices",
     "2. Purchase Invoices",
     "3. Bank Statement",
     "4. Sales Invoice",
     "5. Amended Invoice",
     "6. Credit Notes",
     "7. Debit Notes",
     "8. Accounting Data",
    ],
  },
];
const dataGSTLUTFiling = [
  {
    title: "GST Letter of Undertaking Form",
    desc: [
      'GST LUT stands for "Letter of Undertaking" under the Goods and Services Tax (GST) regime in India. It is a document filed by registered taxpayers who are engaged in the export of goods or services. The purpose of the GST LUT is to enable exporters to supply goods or services without the payment of integrated tax (IGST) at the time of supply.',
    ],
  },
  {
    subtitle: "Key points regarding GST LUT in India:",
    points: [
      "1. Export of Goods or Services: The GST LUT is applicable to taxpayers involved in the export of goods or services. It allows them to make supplies without paying IGST upfront and subsequently claim a refund of accumulated input tax credits or claim zero-rated supplies.",
      "2. Application for GST LUT: Registered taxpayers who wish to avail of the benefits of GST LUT need to submit an application to the jurisdictional GST officer. The application is made in Form GST RFD-11, which includes details such as taxpayer information, export details, and declarations.",
      "3. Validity Period: The GST LUT is valid for a specific period as determined by the tax authorities. Typically, it is valid for one financial year. Taxpayers need to renew the GST LUT before its expiry to continue availing the benefits.",
      "4. Conditions and Compliance: Taxpayers availing of the GST LUT need to comply with certain conditions, including submitting statement-wise details of exports, furnishing the required Bank Realization Certificates (BRCs), and complying with other export-related requirements.",
      "5. Cancellation and Penalties: The tax authorities have the power to cancel the GST LUT if there is any non-compliance or violation of the conditions specified. Non-compliance may attract penalties and impact future export-related transactions.",
      "6. Simplification for Exporters: The introduction of the GST LUT simplifies the process for exporters, as it eliminates the need to pay IGST at the time of supply. This reduces the burden of working capital requirements and promotes ease of doing business for exporters.",
    ],
  },
  {
    title: "Eligibility of Filing LUT in GST",
    subtitle:
      "Eligibility for filing a Letter of Undertaking (LUT) under the Goods and Services Tax (GST) in India is determined by certain criteria. The eligibility criteria for filing an LUT are as follows:",
    points: [
      "1. Registered Exporter: The taxpayer should be a registered entity under the GST Act and possess a valid Goods and Services Tax Identification Number (GSTIN).",
      "2. Export of Goods or Services: The taxpayer should be engaged in the export of goods or services. The export may include both physical goods and services provided to customers located outside India.",
      "3. No Tax Liability: The taxpayer should not have any pending tax liabilities, including tax, interest, or penalty payments, exceeding Rs. 2,00,000 in the current financial year. If any liabilities are outstanding, the taxpayer is required to clear them before filing the LUT.",
      "4. Compliance Record: The taxpayer should have a good compliance record and adhere to all GST compliance requirements, including timely filing of GST returns and payment of taxes.",
      "5. No Prosecution or Conviction: The taxpayer should not be subjected to any prosecution or conviction under the GST Act or any other law for an amount exceeding Rs. 2,00,000.",
    ],
    footer:
      "It's important to note that the eligibility criteria for filing an LUT may be subject to change or additional requirements as per the updated notifications and circulars issued by the tax authorities.",
  },
  {
    title: "Documents Required for LUT Under GST",

    points: [
      "1. LUT cover letter - request for acceptance - duly signed by an authorized person",
      "2. Copy of GST registration",
      "3. PAN card of the entity",
      "4. KYC of the authorized person/signatory",
      "5. GST RFD11 form",
      "6. Copy of the IEC code",
      "7. Cancelled Cheque",
      "8. Authorized letter",
    ],
  },
];
const dataRegistrationCancellation = [
  {
    pagename: "GST Registration Cancellation",
  },
  {
    title: "GST Registration Cancellation",
    subtitle: "",
    desc: [
      "GST cancellation in India refers to the process of de-registering or terminating a Goods and Services Tax (GST) registration. It involves the removal of a taxpayer's GSTIN (Goods and Services Tax Identification Number) from the GST database, indicating the cessation of their liability to collect and pay GST. GST cancellation can occur voluntarily if a business ceases operations or undergoes a change in ownership. It can also be initiated by the tax authorities for non-compliance, failure to file returns, or other specified reasons. The cancellation process involves submitting an application, clearing any pending tax liabilities, and completing the necessary procedures to terminate the GST registration.",
    ],
  },
];
const dataGSTAnnualFiling = [
  {
    title: "GST Annual Returns",
    subtitle: "",
    desc: [
      "GST annual return in India refers to a comprehensive summary of a taxpayer's business transactions, tax liabilities, and input tax credits for a particular financial year. It is a mandatory return that registered taxpayers must file to provide a consolidated view of their GST-related activities. The annual return, known as GSTR-9, includes details of outward and inward supplies, taxes paid, input tax credit availed, and HSN/SAC-wise summary of supplies. It serves as a reconciliation tool, ensuring that the taxpayer's accounting records align with the filed monthly or quarterly returns. The GST annual return provides a holistic view of the taxpayer's GST transactions for the financial year.",
    ],
  },
  {
    title: "Benefits of Filing GST Annual Returns",
    points: [
      "1. Compliance with GST Regulations: Filing GST annual returns ensures compliance with the Goods and Services Tax (GST) laws and regulations. It demonstrates the taxpayer's adherence to the reporting and filing requirements mandated by the tax authorities.",
      "2. Input Tax Credit Reconciliation: Filing the annual return allows taxpayers to reconcile and cross-verify the input tax credit (ITC) claimed during the financial year. This ensures that the ITC claimed aligns with the eligible and permissible credits, thereby preventing any potential discrepancies.",
      "3. Identification of Tax Liabilities: The annual return provides a comprehensive overview of the taxpayer's outward supplies, tax liabilities, and payments made during the financial year. It helps in identifying any outstanding tax liabilities and facilitates their timely settlement, thereby avoiding penalties and interest charges.",
      "4. Transparent Financial Reporting: The GST annual return contributes to transparent financial reporting. It enables tax authorities, stakeholders, and business partners to gain insights into the taxpayer's business operations, turnover, and tax compliance, fostering transparency and building trust.",
      "5. Audit and Assessment Support: Filing the annual return facilitates the audit and assessment processes conducted by tax authorities. It provides a consolidated record of transactions and serves as a reference document during any tax audits or assessments carried out by the authorities.",
      "6. Legal Compliance and Record Maintenance: Filing the GST annual return fulfills the legal obligation of taxpayers, ensuring they maintain accurate and up-to-date records of their business transactions. It helps in building a strong compliance record, which can be beneficial during future interactions with the tax authorities.",
      "7. Data for Business Analysis: The information provided in the annual return can be used for business analysis and strategic decision-making. It provides insights into sales patterns, supplier relationships, and tax-related aspects that can aid in improving business operations and planning.",
      "Filing GST annual returns not only ensures compliance with the GST laws but also offers valuable benefits such as reconciling data, claiming accurate input tax credits, and maintaining transparent financial records.",
    ],
    footer:
      "Note: To know about the GST Annual Return Filing Required Documents, Fill the Request form. Our representatives will guide you the necessary things.",
  },
];
const dataGSTeInvoice = [
  {
    pagename: "GST eInvoice",
  },
  {
    title: "GST eInvoice",
    desc: [
      "GST e-invoice is an electronic version of a tax invoice that is generated and authenticated in the GSTN (Goods and Services Tax Network) portal. It is designed to streamline the invoicing process and ensure the accuracy of the invoice data for GST compliance purposes.",
      "Under the GST e-invoice system, the taxpayer is required to upload the invoice details in a specified format to the GSTN portal, which then generates a unique Invoice Reference Number (IRN) and a digitally signed QR code. This IRN is unique and cannot be regenerated for the same invoice. The QR code contains all the relevant details of the invoice and can be used by the buyer and seller to verify the authenticity of the invoice.",
      "The GST e-invoice system is applicable to all registered taxpayers whose annual aggregate turnover exceeds Rs. 50 crores. The GST e-invoice system is mandatory for taxpayers in specific sectors, including railways, transportation of goods by road, airways, and waterways, and suppliers to the government. The system is being implemented in a phased manner, and more sectors are expected to be brought under its purview in the future.",
      "The GST e-invoice system has several benefits, including reducing errors and discrepancies in invoicing, automating the invoice generation process, facilitating quicker invoice matching, and improving compliance with GST regulations. It is important for taxpayers to familiarize themselves with the e-invoice system and ensure that their invoicing processes are compliant with GST regulations.",
    ],
  },
  {
    table: (
      <div>
        <h3 className="banner-heading after-line mb-3">
          eInvoicing Implementation Date
        </h3>
        <p>
          The proposal for implementing eInvoicing was placed before the 35th
          GST Council Meeting in June, 2019. The GST Council provided an
          in-principle launch of a pilot project on voluntary basis for online
          generation of B2B e-invoices from January 2020.
        </p>
        <div className="table">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Timeline</th>
                <th scope="col">Turnover</th>
                <th scope="col">Supply Type</th>
                <th scope="col">Implementation</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>January, 2021</td>
                <td>
                  GST registered entities having an aggregate turnover of more
                  than INR 100 crores.
                </td>
                <td>B2B</td>
                <td>Voluntary</td>
              </tr>
              <tr>
                <td>April, 2021</td>
                <td>
                  GST registered entities having an aggregate turnover of more
                  than INR 50 crores.
                </td>
                <td>B2B</td>
                <td>Voluntary</td>
              </tr>
              <tr>
                <td>April, 2021 onwards</td>
                <td>
                  GST registered entities having an aggregate turnover of more
                  than INR 20 crores.
                </td>
                <td>B2B</td>
                <td>Mandatory</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>
          The Government has provided exemption to certain classes of persons
          from implementation of eInvoicing through Notification
          No.13/2020-Central Tax dated 21st March, 2020. As per the
          notification, the following entities are exempted from the above
          implementation timeline:
        </p>
        <p>
          An Insurer, Banking Company or Financial Institution including NBFC
        </p>
        <p>Goods Transport Agency</p>
        <p>Passenger Transport Service</p>
        <p>
          Admission to exhibition of Cinematograph Films in Multiplex Screens
        </p>
      </div>
    ),
  },
  {
    title: "Advantages of GST eInvoice",
    desc: [
      "GST eInvoicing will completely transform the business process as currently there are no standards defined for invoices. With the implementation of eInvoicing, invoicing would be standardised across the entire GST eco-system. So an eInvoice generated by one software can be read by another software - eliminating the need for data transfer or re-entry. Finally, with eInvoicing will eliminate the process of compilation of invoices at the end of the month and make input tax credit flow seamless.",
    ],
  },
  {
    title: "Key Concepts of eInvoicing",
    subtitle: "The following are some key concepts of e-invoicing:",
    points: [
      "1. Invoice Registration Portal (IRP): The IRP is a central platform where e-invoices are uploaded by the supplier in a standardized format. The IRP then validates the invoice and generates a unique Invoice Reference Number (IRN) along with a QR code.",
      "2. Invoice Reference Number (IRN): The IRN is a unique number assigned to each e-invoice generated under the e-invoicing system. It is a 64-digit alphanumeric code that is generated by the IRP and serves as a unique identifier for the invoice.",
      "3. Quick Response (QR) Code: The QR code is a machine-readable code that contains all the relevant details of the invoice, such as the supplier's GSTIN, invoice number, invoice date, and amount. The QR code is used by the recipient to verify the authenticity of the invoice.",
      "4. E-way bill: The e-way bill is an electronic document that is required for the movement of goods worth more than Rs. 50,000. The e-way bill is linked to the e-invoice system and is generated automatically when an e-invoice is generated.",
      "5. Input Tax Credit (ITC): The e-invoicing system is designed to ensure the accuracy of the invoice data, which helps in the seamless matching of invoices and reconciliation of ITC.",
      "6. GST compliance: The e-invoicing system is aimed at improving compliance with GST regulations, reducing errors and discrepancies in invoicing, and facilitating quicker invoice matching and processing.",
    ],
  },
  {
    title: "Generating GST eInvoice",
    subtitle:
      "Generating an e-invoice under the GST system involves the following steps:",
    points: [
      "1. Generate the invoice in the prescribed format: The supplier must generate the invoice in the prescribed format, which includes specific details such as the supplier's name, address, GSTIN, invoice number, date, and taxable value.",
      "2. Upload the invoice to the Invoice Registration Portal (IRP): Once the invoice is generated, it must be uploaded to the IRP in the prescribed format. The IRP will then validate the invoice and generate a unique Invoice Reference Number (IRN) along with a QR code.",
      "3. Verify the QR code: The QR code contains all the relevant details of the invoice and can be used by the recipient to verify the authenticity of the invoice. The recipient can scan the QR code using a smartphone app to access the details of the invoice.",
      "4. Share the e-invoice with the recipient: Once the e-invoice is generated, it can be shared with the recipient via email or other electronic means. The recipient can then access the invoice details using the QR code.",
    ],
    footer:
      "By following these steps, businesses can generate e-invoices under the GST system and ensure compliance with GST regulations. The e-invoicing system is designed to simplify the invoicing process and reduce errors and discrepancies in invoicing, which can help businesses save time and reduce costs.",
  },
  {
    title: "Invoice Reference Number (IRN)",
    desc: [
      "Each invoice uploaded by a seller to the Invoice Registration Portal and validated will receive a number called Invoice Reference Number (IRN). IRN is a 64 characters long, unique number in the GST system - irrespective of taxpayer, financial year and document type.",
      "IRN will be issued by the Invoice Registration Portal on uploading of the JSON invoice file. IRN will be generated based on a combination of the supplier GSTIN, document type, document number and year of invoice. Document types are INV for invoice, CRN for credit note, DBN for debit note.",
    ],
  },
  {
    title: "eInvoice QR Code",
    desc: [
      "The IRP will digitally sign a JSON invoice submitted by the seller and also generate a QR code containing the unique IRN along with other invoice data so that it can be readily verified online. This QR code can be scanned by the buyer or any tax offer using an app to check the validity of invoice issued. The eInvoice QR code will contain the following information:",
    ],
    points: [
      "GSTIN of supplier",
      "GSTIN of recipient",
      "Invoice number as given by supplier",
      "Invoice date",
      "Invoice value",
      "Number of line items",
      "HSN code of main item (Item with highest value)",
      "Unique Invoice Reference Number (IRN)",
    ],
  },
  {
    title: "GST eInvoice Format",
    subtitle:
      "The GST e-invoice format is standardized and follows a specific schema. The format of the e-invoice includes the following details:",
    points: [
      "1. Invoice Reference Number (IRN)",
      "2. Date of generation of e-invoice",
      "3. Supplier's GSTIN",
      "4. Supplier's legal name and address",
      "5. Buyer's GSTIN (if registered)",
      "6. Buyer's legal name and address",
      "7. Invoice number",
      "8. Date of the invoice",
      "9. Taxable value and currency",
      "10. Tax amount (breakup of CGST, SGST, IGST, and cess)",
      "11. Discounts (if any)",
      "12. Shipping address and address of delivery (if different)",
      "13. Place of supply",
      "14. HSN/SAC code",
      "15. Description of goods or services",
      "16. Quantity of goods or services",
      "17. Unit of measurement",
      "18. Total value of supply",
      "19. Signature of supplier (digital or physical)",
      "20. QR code containing key details of the invoice",
    ],
  },
  {
    title: "Time Limit for Generating eInvoice",
    desc: [
      "In India, under the Goods and Services Tax (GST) regime, the time limit for generating an e-invoice is 24 hours from the time of the generation of the invoice reference number (IRN).",
    ],
  },
  {
    title: "Cancellation of eInvoice",
    desc: [
      "An eInvoice once generated can be fully cancelled within 24 hours on the Invoice Registration Portal. After 24 hours, cancellation of eInvoice on the Invoice Registration Portal will not be possible and the same will have to be done on the GST Portal before filing of GST return.",
      "Once a GST eInvoice is cancelled, the same invoice number cannot be used again to generate another invoice.",
    ],
  },
  {
    title: "Amendment of eInvoice",
    desc: [
      "Amendments to eInvoice can be done on the GST Portal. All amendments to the eInvoice generated on the Invoice Registration Portal can be done only on the GST Portal. An amended invoice if again sent to IRP as a JSON file will get rejected as the IRN will be duplicate.",
    ],
  },
];
const dataEwaybill = [
  {
    pagename: "eWay Bill",
  },
  {
    title: "eWay Bill",
    subtitle: "What is an eway bill?",
    desc: [
      "An e-way bill is an electronic document that is required for the movement of goods worth more than a certain value in India. It is generated on the GST portal, and it contains details of the goods being transported, such as the invoice number, the quantity of goods, the value of the goods, the GST identification numbers of the parties involved in the transaction, and the vehicle details.",
      "The e-way bill is required to be generated by the person responsible for the movement of goods, such as the supplier, the transporter, or the recipient of the goods. It must be generated before the goods are transported, and it must be presented to the relevant authorities if requested during transit.",
      "The e-way bill is part of the Goods and Services Tax (GST) regime in India, which is a unified tax system that replaced multiple indirect taxes such as excise duty, service tax, and value-added tax. The e-way bill system is intended to simplify the process of moving goods across the country and ensure that goods are transported in a timely and efficient manner.",
    ],
  },
  {
    subtitle: "Who should Generate an eWay Bill?",
    desc: [
      "In India, under the Goods and Services Tax (GST) regime, an e-way bill is required to be generated by any person who causes the movement of goods of value exceeding INR 50,000 (approximately USD 670) in relation to the following transactions:",
    ],
    points: [
      "1. Supply: When the goods are supplied from one registered person to another registered person, either within the same state (intra-state) or across different states (inter-state).",
      "2. Export or Import: When the goods are exported or imported.",
      "3. Job Work: When the goods are sent for job work to a job worker who is registered under GST.",
      "4. SKD/CKD: When the goods are sent in a semi-knocked down or completely knocked down condition for assembly or installation.",
      "5. Exhibition or Fairs: When the goods are sent for display in exhibitions or fairs.",
      "6. Consignment: When the goods are sent on consignment basis.",
    ],
    footer:
      "The e-way bill can be generated by the consignor, consignee, transporter, or any other person who is responsible for the movement of goods. It is important to note that the e-way bill must be generated before the commencement of the movement of goods, and it must accompany the goods until their final destination.",
  },
  {
    title: "Documents required to generate eWay Bills",
    desc: [
      "Invoice/ Bill of supply/ Challan relevant to the consignment of goods",
      "In case of Transport by road- Transporter ID or the vehicle number",
      "Transport by rail, air, or ship- Transporter ID, Transport document number, and date.",
    ],
  },
  {
    subtitle: "What is the eWay bill format?",
    desc: [
      "The E-way bill consists of two parts Part A and B. The Part A of the E-way bill collects the details related to consignment, usually the invoice details. Accordingly, the following information needs to be submitted.",
      "GSTIN of the recipient must be submitted.",
      "The Pin code of the place where goods are delivered needs to be mentioned.",
      "The invoice or the challan number against which the goods are supplied must be submitted.",
      "The value of consignment is to be mentioned.",
      "HSN code of the goods which are transported should be entered. If the turnover is up to INR 5 crores, the HSN code's first two digits should be mentioned. If the turnover is more than INR 5 crores, a four-digit HSN code is required.",
      "The reason for transportation should be predefined, and the most appropriate one needs to be selected.",
      "The transport document number should be indicated. It includes goods receipt number, railway receipt number, airway bill number.",
    ],
  },
  {
    subtitle: "How to Generate eWay Bill?",
    desc: [
      "An E-Way Bill (EWB) is an ‘electronic way’ bill for movement of goods which can be generated on the E-Way Bill Portal. Any supplier or a transporter transporting goods with a value of more than Rs.50,000 (Single Invoice/bill/delivery challan) in a single vehicle should carry a GST e-way bill as per the GST Council regulations. The supplier or the transporter of the goods must register with GST to obtain GST E-Way bill. This bill shall come into effect from 1st April 2018.",
      "After generating the E-Way bill on the portal using required credentials, the portal generates a unique E-Way Bill Number (EBN) and allocates to the registered supplier, recipient, and the transporter. In this article, we look at the steps to generate a e-way bill on the Government website.",
    ],
  },
  {
    title: "Steps to generate e-Way Bill on the e-Way Bill portal",
    subtitle:
      "The following are the steps to generate an e-Way Bill on the e-Way Bill portal in India:",
    points: [
      "1. Visit the e-Way Bill portal at https://ewaybillgst.gov.in/ and log in using your credentials (i.e., username and password).",
      '2. On the dashboard, click on the "Generate New" option under the "e-Waybill" tab.',
      "3. Select the type of transaction for which the e-Way Bill is required (i.e., Outward, Inward, or Sub Supply) and fill in the required details of the consignment, such as the name of the supplier, recipient, and transporter, the place of dispatch and delivery, and the item details.",
      '4. Verify the details entered and click on the "Generate e-Way Bill" button.',
    ],
  },
  {
    table: (
      <div className="">
        <h3 className="banner-heading after-line mb-3">eway bill validity</h3>
        <p className="fw-bold">
          What is the minimum distance required for E-way bill?
        </p>
        <p>
          The significant amendment made effective video notification no.
          12/2018- Central tax dated 7th March 2018 changes in the validity
          period of E-way bill. The new validity period provisions of the E-way
          bill are tabulated here:
        </p>
        <div className="table">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Type of Conveyance</th>
                <th scope="col">Distance</th>
                <th scope="col">E-way bill</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="2">Other than Over dimensional cargo</td>
                <td>Less than 100 km</td>
                <td>1 day</td>
              </tr>
              <tr>
                <td>For every additional 100 km and thereof</td>
                <td>Additional 1 day</td>
              </tr>
              <tr>
                <td rowspan="2">For Over dimensional cargo</td>
                <td>Up to 20 km</td>
                <td>1 day</td>
              </tr>
              <tr>
                <td>For every additional 20 km and thereof</td>
                <td>Additional 1 day</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>
          The relevant date on which the E-way bill has been generated and the
          period of validity would be counted from the time at which the E-way
          bill has been generated, and each day would be counted as the period
          expiring at midnight of the day immediately following the date of
          generation of the E-way bill.
        </p>
        <p>
          'Over Dimensional Cargo' is a cargo carried as a single indivisible
          unit and which exceeds the dimensional limits prescribed in rule 93 of
          the Central Motor Vehicle Rules,1989, made under the Motor Vehicles
          Act,1988 (59 of 1988).
        </p>
      </div>
    ),
  },
  {
    subtitle: "SMS E-Way Bill Generation on Mobile",
  },
  {
    desc: [
      "SMS e-way bill generation is ideal for entities with limited transactions, as it would be prudent to use other methods in case of higher volume.",
      "SMS e-way bill generation facility can also be used by taxpayers in case of emergencies such as during the night or while involved in travelling in a vehicle.",
    ],
  },
  {
    title: "Enabling SMS E-Way Bill Generate Facility",
    desc: [
      "Before starting to transact, the taxpayer must first register his/her mobile number on the GST e-way bill portal. The system only enables and responds to mobile number registered on the portal for a particular GSTIN.",
      "Once user selects option ‘for SMS’ under main option ‘Registration’, following screen is displayed.",
      "The user must enter the mobile number and complete the OTP to register the mobile number.",
      "In the next screen the mobile number registered with the GSTIN is displayed. The user can use this screen for delinking or changing the mobile number, if required.",
    ],
    points: [
      "Step 1: Access to Portal",
      "The taxpayer or the transporter must open the e-way bill portal and log in using his/her credentials.",
      "Step 1: Register Mobile Number",
      "Enable the SMS e-way bill facility by following the below shown above. Once validation is complete and the mobile number is registered, you are ready to generate e-way bill using SMS.",
    ],
  },
  {
    title: "Cases When E-Way Bill is Exempted or Not Required",
    subtitle:
      "In India, there are certain cases when an e-Way Bill is exempted or not required. Some of these cases are:",
    points: [
      "1. Transportation of exempted goods: If the goods being transported are exempt from GST, then an e-Way Bill is not required to be generated.",
      "2. Transportation of goods by non-motorized conveyance: If the goods are transported by non-motorized conveyance, such as a bicycle, then an e-Way Bill is not required to be generated.",
      "3. Transportation of certain specified goods: If the goods being transported fall under certain specified categories, such as live animals, meat, fish, etc., then an e-Way Bill may not be required to be generated.",
      "4. Transportation of goods for personal use: If the goods being transported are for personal use and not for sale or further supply, then an e-Way Bill is not required to be generated.",
      "5. Transportation of goods within a distance of 10 km: If the distance between the supplier's place of business and the recipient's place of business is less than 10 km and the value of the goods is less than INR 50,000, then an e-Way Bill is not required to be generated.",
      "6. Transportation of goods by the government: If the goods are being transported by the government, local authority, or a statutory body, then an e-Way Bill may not be required to be generated",
    ],
  },
  {
    title: "Documents Required For Accountant & GST Software",
    points: [
      "Supply of Goods",
      "Invoice/ Bill of supply/ Challan relevant to the consignment of goods.",
      "Transport ID",
      "Transporter ID or the vehicle number.",
      "Transport Document",
      "Transporter ID, Transport document number, and date.",
    ],
  },
];
const dataInputTaxcredit = [
  {
    title: "GST Input Tax Credit Reconciliation",
    desc: [
      "GST Input Tax Credit (ITC) Reconciliation in India refers to the process of verifying and reconciling the input tax credits claimed by a taxpayer with the corresponding details provided by their suppliers. It involves comparing the ITC claimed by the taxpayer in their GST returns with the invoices and payment details uploaded by their suppliers. The purpose of ITC reconciliation is to ensure that the claimed credits are valid, accurate, and comply with the provisions of the GST law. It helps in identifying any discrepancies, rectifying errors, and maintaining the integrity of the ITC claimed by taxpayers while promoting transparency and compliance in the GST system. ITC reconciliation plays a crucial role in ensuring accurate reporting, reducing tax liabilities, and preventing non-compliance issues. It is important for taxpayers to regularly reconcile their ITC claims with supplier details to avail of these benefits and maintain compliance with the GST law.",
    ],
  },
  {
    title: "Reversal of Input Tax Credit",
    subtitle:
      "In India, the reversal of Input Tax Credit (ITC) refers to the reversal or adjustment of previously claimed ITC due to certain circumstances or conditions specified under the Goods and Services Tax (GST) law. The reversal of ITC can occur in various scenarios, including:",
    points: [
      "1. Non-payment to Suppliers: If a taxpayer fails to make payment to their suppliers within 180 days from the date of invoice, the ITC claimed on those invoices needs to be reversed.",
      "2. Non-receipt of Goods or Services: If the taxpayer does not receive the goods or services for which they claimed ITC, they are required to reverse the credit previously availed.",
      "3. Exempt Supplies: When a taxpayer makes exempt supplies or supplies that are not liable to GST, the ITC claimed on inputs, input services, or capital goods used for such supplies needs to be reversed.",
      "4. Personal Use or Non-business Purpose: If inputs, input services, or capital goods are used for personal purposes or non-business activities, the proportionate ITC needs to be reversed.",
      "5. Blocked Credit: ITC cannot be claimed or utilized for certain expenses specified as blocked credits under the GST law, such as certain motor vehicles, food and beverages, membership of clubs, etc.",
      "The reversal of ITC is done by reducing the credit amount from the taxpayer's electronic credit ledger. The details of the reversal are usually reported in the GST returns filed by the taxpayer. ",
    ],
    footer:
      "It's important for taxpayers to understand the specific provisions for reversal of ITC under GST and comply with the requirements to avoid any non-compliance issues or penalties.",
  },
  {
    title: "Benefits of GST Input Tax Credit Reconciliation",
    subtitle:
      "Accuracy in ITC Claims: Reconciliation ensures the accuracy of ITC claims by verifying them against the details provided by suppliers. It helps identify any discrepancies or errors, allowing taxpayers to rectify them and ensure compliance with the provisions of the GST law.",
    points: [
      "1. Prevention of Reversal and Disallowance: ITC reconciliation helps avoid the reversal or disallowance of claimed credits. By matching the ITC claimed with the supplier invoices and payment details, taxpayers can ensure that all necessary conditions for claiming ITC are met, reducing the risk of ITC reversals or disallowances.",
      "2. Reduction of Tax Liability: Accurate ITC reconciliation can result in the reduction of tax liability. It ensures that eligible credits are properly claimed, thereby reducing the amount of tax payable by the taxpayer.",
      "3. Prevention of Interest and Penalties: Timely and accurate ITC reconciliation helps prevent interest charges and penalties. It ensures that the taxpayer's ITC claims align with the supplier-reported details, reducing the likelihood of non-compliance issues and subsequent penalties.",
      "4. Transparency and Trust: ITC reconciliation fosters transparency in tax reporting and builds trust with tax authorities. By maintaining accurate records and reconciling ITC, taxpayers demonstrate their commitment to compliance, which can result in smoother interactions with tax authorities.",
      "5. Efficient Audit and Assessment Processes: ITC reconciliation simplifies the audit and assessment procedures. By having accurate records and reconciled ITC details readily available, taxpayers can streamline the audit process, saving time and resources.",
      "6. Improved Cash Flow: Effective ITC reconciliation ensures that eligible credits are properly claimed, leading to improved cash flow for the taxpayer. It helps in reducing the burden of tax payments by utilizing valid ITC, ultimately benefiting the financial health of the business.",
      "7. Compliance with GST Regulations: ITC reconciliation is a critical requirement under the GST law. By adhering to the reconciliation process, taxpayers ensure compliance with GST regulations and maintain a good compliance record.",
    ],
  },
  {
    title: "Goods and Services not Eligible for Input Tax Credit",
    subtitle:
      "Under the Goods and Services Tax (GST) system in India, there are certain goods and services for which Input Tax Credit (ITC) is not eligible. These include:",
    points: [
      "1. Motor Vehicles: ITC cannot be claimed for motor vehicles and other conveyances, except when they are used for specific purposes like providing taxable transportation services, renting for self-drive, or for the transportation of goods.",
      "2. Food and Beverages: ITC is not available for food and beverages, except when they are supplied as part of a taxable service or as goods for further resale.",
      "3. Renting of Immovable Property: ITC cannot be claimed for the renting of immovable property, except when it is used for providing taxable services or further leasing.",
      "4. Personal Use or Non-business Purposes: Goods and services used for personal use or non-business purposes are not eligible for ITC. This includes goods and services used for personal travel, personal events, or any activities that are not related to the business.",
      "5. Works Contract for Immovable Property: ITC is not available for works contracts used for the construction of immovable property, except for certain specified cases like buildings intended for sale or leasing, or for the provision of further works contracts.",
      "6. Membership of Clubs, Fitness Centers, and Recreational Facilities: ITC cannot be claimed for membership fees or expenses related to clubs, fitness centers, and recreational facilities.",
      "7. Certain Input Services: ITC is not eligible for certain input services like general insurance, travel benefits extended to employees, and services related to the motor vehicles used for personal purposes.",
    ],
    footer:
      "It's important for businesses to understand the specific restrictions on ITC and ensure compliance with the GST law regarding eligible and ineligible input tax credits.",
  },
  {
    title: "Documents Required For GST Input Tax Credit Reconciliation",
    points: [
      "1. Invoice",
      "Invoice issues by the supplier and recipient of goods and services .",
      "2. Debit notes.",
      "Debit note issued by the supplier of the tax charged is less than the tax payable concerning such supply.",
      "3. Bill Of Entry",
      "A bill of entry or similar documents is required to document on integrated tax on imports.",
      "4. Credit Notes",
      "Credit note that is issued by an input service distributor as per the rules under GST.",
      "5. Supply Bill",
      "Supply bill by a dealer that is opting for a composition scheme or an exporter or a supplier of the exempted goods.",
    ],
  },
];
const dataTrademarkRenewal = [
  {
    pagename: "Trademark Renewal",
  },
  {
    title: "Trademark Renewal",
    desc: [
      "Trademark renewal in India refers to the process of extending the validity of a registered trademark beyond its initial ten-year period. The renewal process allows the trademark owner to maintain their exclusive rights over the trademark and continue enjoying the legal protection provided by the registration.",
    ],
  },
  {
    title: "",
    subtitle:
      "Important key aspects to understand about Trademark Renewal in India:",
    points: [
      "1.Duration of Trademark Registration: A trademark registration in India is initially valid for a period of ten years from the date of filing the trademark application. This ten-year period is divided into successive periods, each lasting ten years.",
      "2. Renewal Application: To keep the trademark registration active beyond the initial ten-year period, the trademark owner needs to file a renewal application with the Trademark Registry. The renewal application can be filed within six months before the expiration of the current registration or within a grace period of six months after the expiration, with the payment of additional fees.",
      "3. Form and Documents: The renewal application is filed using Form TM-R along with the prescribed renewal fee. The trademark owner needs to provide details of the registered trademark, including the registration number and class(es) of goods or services, and submit any supporting documents as required by the Trademark Registry.",
      "4. Validity Period: Once the renewal application is accepted and the renewal fee is paid, the trademark registration is renewed for another ten-year period from the date of expiration of the previous registration. The renewed registration is valid as long as the renewal is timely done within the prescribed periods.",
      "5. Late Renewal and Restoration: If the renewal application is not filed within the six-month grace period after expiration, the trademark registration is deemed expired. However, there is a provision for restoration of the expired trademark by filing a restoration application within one year from the expiration date, along with the prescribed fees and a valid reason for the delay. The restoration is subject to the discretion of the Registrar and additional fees.",
      "6. Continuous Renewal: Trademark renewal can be done indefinitely in successive ten-year periods as long as the renewal application and fees are timely filed within the prescribed periods",
    ],
    footer:
      "It is important for trademark owners to keep track of the renewal dates and ensure timely filing of renewal applications to maintain the validity of their trademark registrations in India.",
  },
];
const datacopyrightResgistraion = [
  {
    pagename: "Copyright Registration",
  },
  {
    title: "Copyright Registration",
    desc: [
      "Copyright registration in India refers to the process of securing legal protection for original creative works under the Copyright Act, 1957. It provides formal recognition and evidence of ownership over literary, artistic, musical, and other creative works.",
    ],
  },
  {
    title: "",
    subtitle:
      "Important key aspects to understand about Copyright Registration in India",

    points: [
      "1. Definition of Copyright: Copyright is a form of intellectual property right that grants exclusive rights to the creators of original works. It covers a wide range of creative expressions, including literary works, artistic works, musical works, dramatic works, cinematographic works, sound recordings, and computer programs.",
      "2. Voluntary Registration: Copyright registration in India is voluntary. Copyright protection automatically exists upon the creation of the work, irrespective of registration. However, obtaining copyright registration offers additional legal benefits and advantages.",
      "3. Copyright Office: Copyright registration is administered by the Copyright Office under the Department of Promotion of Industry and Internal Trade (DPIIT) in India. The Copyright Office handles the application process, examination, and issuance of copyright registration certificates.",
      "4. Application Process: To obtain copyright registration, an application must be filed with the Copyright Office. The application should include the required forms, supporting documents, and the prescribed fee. The applicant should provide details about the work, including its title, description, authorship, and date of creation.",
      "5. Examination and Registration: Upon receiving the application, the Copyright Office examines it for compliance with formalities and substantial requirements. If the application is in order, the Copyright Office registers the copyright and issues a registration certificate. In case of any deficiencies or objections, the applicant may be required to address them through amendments or additional documents.",
      "6. Duration of Copyright Protection: Copyright protection in India generally lasts for the lifetime of the author plus an additional 60 years after their death. For works of joint authorship, the term is calculated based on the lifetime of the last surviving author.",
      "7. Benefits of Copyright Registration: Copyright registration provides several benefits, including legal evidence of ownership, a presumption of validity, the ability to enforce rights through legal actions, and the possibility of statutory damages and attorney fees in infringement cases.",
    ],
    footer:
      "It is important to note that while copyright registration is not mandatory for protection, it is highly recommended. It serves as strong evidence of ownership and facilitates the enforcement of rights in case of infringement.",
  },
  {
    title: "Advantages of Copyright Registration in India:",

    points: [
      "1. Legal Evidence of Ownership: Copyright registration provides strong legal evidence of ownership over the copyrighted work. It serves as a public record and helps establish the creator's rights in case of disputes or infringement claims.",
      "2. Presumption of Validity: Copyright registration creates a presumption of the validity of the copyright and the facts stated in the registration certificate. This can simplify legal proceedings and strengthen the creator's position in litigation.",
      "3. Enhanced Enforcement Options: With copyright registration, the copyright owner can take legal action against infringers. Registration allows the copyright holder to seek statutory damages and attorney fees in infringement cases, providing a stronger deterrent against unauthorized use",
      "4. Global Protection: Copyright registration in India may help in seeking protection and enforcement of copyrights in foreign jurisdictions through international treaties and agreements",
      "5. Licensing and Commercial Opportunities: Copyright registration can facilitate licensing and commercialization of the copyrighted work. It provides a solid foundation for entering into licensing agreements, selling or assigning rights, and attracting potential investors or partners.",
    ],
  },
  {
    title: "Disadvantages of Copyright Registration in India:",

    points: [
      "1. Not Mandatory for Protection: Copyright protection exists automatically upon the creation of an original work, irrespective of registration. Copyright registration is voluntary, and the lack of registration does not invalidate the copyright itself.",
      "2. Time and Cost: The process of copyright registration in India involves filing an application, paying fees, and dealing with administrative procedures. This can be time-consuming and may involve costs, including application fees and professional fees if legal assistance is sought",
      "3. Publication of Sensitive Information: Copyright registration may require disclosing certain information about the copyrighted work, such as its title, description, and authorship. This information becomes a part of the public record, which may not be desirable for some creators who wish to keep their work private.",
      "4. Limited Protection: Copyright registration protects against direct copying or substantial reproduction of the work. However, it may not prevent independent creation of similar works or protect against infringement in jurisdictions where the work is not registered.",
    ],
  },
  {
    title: "Required Documents for Copyright Registration",

    points: [
      "1. Applicant Aadhar Card",
      "2. Applicant Pan Card",
      "3. Copies of Original Work",
      "4. Incorporation Certificate if its for Business",
      "5. Class & Description of the work",
      "6. Tile of the work",
      "7. Language of the work",
      "8. Authorisation Letter",
    ],
  },
];
const dataDesignRegistration = [
  {
    pagename: "Design Registration",
  },
  {
    title: "Design Registration",
    desc: [
      "Design Registration in India refers to the process of legally protecting the aesthetic or visual appearance of a product under the Designs Act, 2000. Design Registration provides exclusive rights to the creator or owner of a design, preventing others from using, manufacturing, or selling similar designs without permission",
    ],
  },
  {
    title: "",
    subtitle:
      "Important key aspects to understand about Design Registration in India",
    points: [
      "1. Definition of Design: A design refers to the features of shape, configuration, pattern, ornament, or composition of lines or colors applied to an article. It relates to the visual appearance of a product and can include elements such as shape, surface design, texture, or a combination of these.",
      "2. Design Registration Office: Design registration is administered by the Design Wing of the Intellectual Property Office under the Controller General of Patents, Designs, and Trademarks in India. The office handles the application process, examination, and issuance of design registration certificates.",
      "3. Eligibility for Design Registration: To be eligible for design registration in India, the design must be new and original, not previously published or disclosed in any form. It should not be contrary to public order or morality. Functional aspects of a product that solely serve a technical function cannot be protected as designs but may be eligible for patent protection.",
      "4. Application Process: To obtain design registration, an application must be filed with the Design Office. The application should include the prescribed form, the representation or drawings of the design, and the prescribed fee. The applicant should provide details about the article to which the design is applied.",
      "5. Examination and Registration: Upon receiving the application, the Design Office examines it for compliance with formalities and substantive requirements. If the application is in order and meets the criteria for design registration, the Design Office registers the design and issues a registration certificate.",
      "6. Duration of Design Protection: Design registration in India initially provides protection for a period of ten years from the date of registration. It can be further extended for an additional five years, totalling a maximum protection period of 15 years.",
      "7. Infringement and Enforcement: Once a design is registered, the owner has the exclusive right to use, reproduce, license, or assign the design. In case of infringement, the owner can take legal action to enforce their rights and seek remedies, such as injunctions, damages, or account of profits.",
    ],
    footer:
      "Design registration in India provides legal protection to the visual appearance of a product, safeguarding the creativity and innovation of the design owner.",
  },
  {
    title: "Advantages of Design Registration in India:",

    points: [
      "1. Exclusive Rights: Design registration provides the owner with exclusive rights over the registered design. It prohibits others from using, manufacturing, selling, or importing products with a similar design without permission. This helps prevent unauthorized copying and imitation of the design",
      "2. Legal Protection: Design registration offers legal protection and evidence of ownership over the registered design. It provides a strong basis for taking legal action against infringers and seeking remedies in case of design infringement.",
      "3. Commercial Advantage: Registered designs can be valuable assets that can be licensed, sold, or used for commercial purposes. Design registration can attract potential investors, licensing opportunities, and business collaborations by demonstrating the uniqueness and legal protection of the design",
      "4. Deterrence against Infringement: The existence of a registered design acts as a deterrent to potential infringers. The exclusive rights and legal consequences associated with design registration discourage others from copying or using the design without permission.",
      "5. Global Protection: Design registration in India can facilitate seeking protection and enforcement of designs in foreign jurisdictions through international treaties and agreements",
    ],
  },
  {
    title: "Disadvantages of Design Registration in India",

    points: [
      "1. Cost and Time: The process of design registration involves filing an application, paying fees, and dealing with administrative procedures. This can be time-consuming and may involve costs, including application fees and professional fees if legal assistance is sought",
      "2. Limited Duration of Protection: Design registration provides protection for a limited period of ten years from the date of registration, which can be extended for an additional five years. After the expiration of the protection period, the design falls into the public domain, and others can freely use it.",
      "3. Limited Scope of Protection: Design registration protects the aesthetic aspects of a design but does not cover functional aspects. Designs that are primarily functional in nature may not be eligible for registration or may be vulnerable to challenges.",
      "4. Publication of Sensitive Information: Design registration may require disclosing certain information about the design, such as its visual representation, description, and details. This information becomes a part of the public record, which may not be desirable for some creators who wish to keep their design confidential.",
    ],
  },
];
const dataPatentRegistration = [
  {
    pagename: "Patent Registration",
  },
  {
    title: "Patent Registration",
    desc: [
      "Patent Registration in India refers to the process of obtaining legal protection for an invention through a patent granted by the Indian Patent Office. A patent is an exclusive right granted to an inventor or assignee for a limited period, which typically lasts for 20 years from the date of filing the patent application.",
    ],
  },
  {
    title: "",
    subtitle: "Patent Registration process in India involves several steps:",

    points: [
      "1. Patentability Search: It is recommended to conduct a patentability search to determine if the invention is novel and non-obvious. This search helps in assessing the chances of obtaining a patent.",
      "2. Drafting the Patent Application: A patent application needs to be drafted, including a detailed description of the invention, claims defining the scope of the invention, and drawings (if applicable).",
      "3. Filing the Application: The patent application can be filed electronically or physically with the Indian Patent Office. The application must include the necessary forms, fees, and supporting documents.",
      "4. Publication: After the filing of the application, it is published in the Indian Patent Office's official journal, which is available to the public. The publication usually occurs after 18 months from the filing or priority date.",
      "5. Examination: A request for examination must be filed within 48 months from the priority date or filing date. The patent application is examined by the Indian Patent Office to assess its novelty, inventive step, and industrial applicability.",
      "6. Response to Examination Report: If any objections or rejections are raised in the examination report, the applicant needs to respond within the prescribed time frame, typically within six months from the date of issuance of the report.",
      "7. Grant of Patent: If the patent application meets the requirements of novelty, inventive step, and industrial applicability, and all objections are resolved, the Indian Patent Office grants the patent. The patent owner then receives exclusive rights over the invention for a period of 20 years.",
    ],
  },
  {
    title: "Advantages of Patent Registration in India:",
    points: [
      "· Exclusive Rights: Patent registration grants the inventor exclusive rights over their invention, preventing others from making, using, selling, or importing the patented invention without permission. This exclusivity allows the inventor to capitalize on their innovation and enjoy a competitive advantage in the market.",
      "· Legal Protection: A registered patent provides legal protection against infringement, enabling the patent holder to take legal action against unauthorized use or copying of their invention. This protection serves as a deterrent to potential infringers and safeguards the inventor's rights.",
      "· Market Advantage: A patent can give the inventor a competitive edge in the market by providing a monopoly over their invention. This monopoly allows them to control the supply and pricing of the patented product or technology, potentially leading to increased market share and profitability.",
      "· Licensing and Commercialization Opportunities: Patents can be licensed or sold to other entities, allowing the patent holder to generate revenue through licensing fees or royalties. Additionally, patents can attract investors or partnerships, facilitating the commercialization of the invention and opening doors to business opportunities.",
      "· Research and Development Incentive: Patent protection encourages innovation by rewarding inventors for their efforts. The prospect of obtaining exclusive rights and the potential for financial gain motivates inventors and businesses to invest in research and development, leading to technological advancements and societal progress.",
    ],
  },
  {
    title: "Disadvantages of Patent Registration in India:",

    points: [
      "· Cost and Time: The process of patent registration can be expensive and time-consuming. Costs include filing fees, attorney fees, and maintenance fees over the life of the patent. The registration process itself can take several years, which may deter inventors with limited resources or those in rapidly evolving industries.",
      "· Disclosure of Invention: To obtain a patent, inventors must disclose their invention in detail, including its technical specifications and operation. This public disclosure may make it easier for competitors to reverse-engineer the invention or develop alternative solutions to address the same problem.",
      "· Limited Duration: Although patents provide exclusive rights, their duration is limited. In India, patents typically last for 20 years from the filing date. After the expiry of the patent term, the invention enters the public domain, and anyone can use or exploit it without permission.",
      "· Patent Thickets and Patent Trolls: In certain industries, a large number of overlapping patents can create patent thickets, making it challenging to navigate patent rights and potentially leading to legal disputes. Additionally, the presence of patent trolls, who acquire patents primarily for the purpose of litigation rather than the development of innovative products, can create legal uncertainties and increase the risk of infringement lawsuits.",
    ],
  },
  {
    title: "Eligibility of Patent Registration",

    points: [
      "· Individual Inventors: Any individual who is the true and first inventor or their legal representative can apply for a patent. The inventor may be a citizen of India or a foreign national.",
      "· Joint Inventors: Two or more individuals who jointly invent an invention may apply for a patent as joint applicants. Each inventor should have contributed to the invention, and their names should be mentioned in the patent application.",
      "· Assignee or Legal Representative: If the inventor has assigned their rights to another person or entity, such as a company or organization, the assignee or legal representative can apply for the patent on behalf of the inventor.",
      "· Startup Entities: Startups recognized by the Department for Promotion of Industry and Internal Trade (DPIIT) can avail of certain benefits, including expedited examination of patent applications, reduced fees, and other incentives under the Startup India initiative.",
      "· Foreign Applicants: Foreign individuals, entities, or their authorized representatives can also file a patent application in India. However, they may need to comply with additional requirements, such as providing an address for service in India.",
      "It's important to note that eligibility for Patent Registration is based on being the inventor or the assignee of the invention. The invention itself must meet the criteria of novelty, inventive step, and industrial applicability as per the Indian Patent Act.",
    ],
    footer: [
      "Note: To know about the Required Documents for Patent Registration, Kindly fill the Request Form. Our Representatives will call you back.",
    ],
  },
];
const dataProvisionalpatent = [
  {
    pagename: "Provisional Patent",
  },
  {
    title: "Provisional Patent",
    desc: [
      "A provisional patent application is a type of patent application that can be filed in India to establish a priority date for an invention. It provides a way for inventors to protect their ideas before the invention is fully developed or ready for commercialization.",
      "A provisional patent application is a simplified version of a regular patent application that includes a description of the invention and any drawings or diagrams. Unlike a regular patent application, a provisional application does not include formal claims or an abstract.",
      "The main advantage of filing a provisional patent application in India is that it establishes a priority date for the invention. This means that if the inventor decides to file a regular patent application within 12 months of filing the provisional application, the priority date for the regular application will be the same as the priority date for the provisional application. This can be important in cases where there is a risk that someone else may file a patent application for a similar invention.",
      'It is important to note that a provisional patent application does not provide any legal protection for the invention. It simply establishes a priority date and allows the inventor to use the term "patent pending" for the invention. To obtain legal protection, the inventor must file a regular patent application within 12 months of filing the provisional application.',
      "In summary, a provisional patent application in India is a useful tool for inventors who want to establish a priority date for their invention while they continue to develop and refine it. It provides a way to protect the invention before it is ready for commercialization and can help to prevent others from filing patent applications for similar inventions.",
    ],
  },
  {
    title: "Types of Provisional Patent in India",
    desc: [
      'There is only one type of provisional patent application in India, which is the "provisional specification" as defined in the Indian Patents Act, 1970.',
      "A provisional specification is a simplified version of a regular patent specification that includes a description of the invention and any drawings or diagrams. It does not include any formal claims or abstract.",
      "The purpose of a provisional specification is to establish a priority date for the invention. If the inventor decides to file a regular patent application later, the priority date for the regular application will be the same as the priority date for the provisional specification.",
      "It is important to note that a provisional specification does not provide any legal protection for the invention. To obtain legal protection, the inventor must file a regular patent application within 12 months of filing the provisional specification, which will include a complete specification with formal claims and abstract. The regular patent application will be examined by the Indian Patent Office to determine whether the invention is novel and non-obvious, and if it meets the other requirements for patentability.",
    ],
    subtitle: "",
    points: [],
    footer: "",
  },
  {
    table: (
      <div className="table">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col" colspan="4">
                Trademark vs Copyright vs Patent
              </th>
            </tr>
            <tr>
              <th scope="col"></th>
              <th scope="col">Trademark</th>
              <th scope="col">Patent</th>
              <th scope="col">Copyright</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>What’s protected</td>
              <td>
                Any word, phrase, symbol or design that recognizes and
                differentiates the source of one party's goods from those of
                another.
              </td>
              <td>
                Inventions, such as procedure, manufacturer, composition,
                machines of matter as well as improvements to these.
              </td>
              <td>
                Books, articles, music, photography, sculptures, dances, sound
                recordings, motion films and other original works of authorship.
              </td>
            </tr>
            <tr>
              <td>Requirements for protection</td>
              <td>
                A mark must be distinguishable, in the sense that it must be
                able to identify the source of a certain good.
              </td>
              <td>A fresh, valuable, and unusual invention is required.</td>
              <td>
                A work must be unique, original, and created in a tangible
                manner.
              </td>
            </tr>
            <tr>
              <td>Term of protection</td>
              <td>As long as the mark is used in commerce.</td>
              <td>20 years</td>
              <td>Author’s life span+ 70 years.</td>
            </tr>
            <tr>
              <td>Rights Granted</td>
              <td>
                Right to use the mark and to prohibit others from using
                identical marks in a way that could create confusion about the
                goods or services' origin.
              </td>
              <td>
                Right to restrict others from manufacturing, selling, or
                importing the patented invention.
              </td>
              <td>
                Copyrighted works have the power to dictate their reproduction,
                creative works, circulation, public performance, and display.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    ),
  },
];
const dataPersonalTaxFiling = [
  {
    pagename: "Personal Tax Return Filing",
  },
  {
    title: "Personal Tax Return Filing",
  },
  {
    table: (
      <div className="">
        <h5 className="banner-heading after-line mb-3">
          (ITR eFiling) Income Tax Filing
        </h5>
        <div className="table">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Return Type</th>
                <th scope="col">
                  Retrieving data. Wait a few seconds and try to cut or copy
                  again.
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>ITR-1</td>
                <td>
                  ITR-1 form can be used by Individuals who have less than Rs.50
                  Lakhs of annual income earned by way of salary or pension and
                  have one house property only.
                </td>
              </tr>
              <tr>
                <td>ITR-2</td>
                <td>
                  ITR-2 form must be filed by individuals who are NRIs,
                  Directors of Companies, shareholders of private companies or
                  having capital gains income, income from foreign sources, two
                  or more house property, income of more than Rs.50 lakhs.
                </td>
              </tr>
              <tr>
                <td>ITR-3</td>
                <td>
                  ITR-3 form must be filed by individuals who are professionals
                  or persons who are operating a proprietorship business in
                  India.
                </td>
              </tr>
              <tr>
                <td>ITR-4</td>
                <td>
                  ITR-4 form can be filed by taxpayers enrolled under the
                  presumptive taxation scheme. To be enrolled for the scheme,
                  the taxpayer must have less than Rs.2 crores of business
                  income or less than Rs.50 lakhs of professional income.
                </td>
              </tr>
              <tr>
                <td>ITR-5</td>
                <td>
                  ITR-5 form must be filed by partnership firms, LLPs,
                  associations and body of individuals to report their income
                  and computation of tax.
                </td>
              </tr>
              <tr>
                <td>ITR-6</td>
                <td>
                  ITR-6 form must be filed by companies registered in India.
                </td>
              </tr>
              <tr>
                <td>ITR-7</td>
                <td>
                  ITR-7 form must be filed by entities claiming exemption as
                  charitable/religions trust, political parties, scientific
                  research insitutions and colleges or universities.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    ),
  },
  {
    title: "Penalty for Late Filing Income Tax Return",
    desc: [
      "The penalty for late filing of income tax returns (ITR) depends on various factors such as the taxpayer's income, the type of income, the date of filing, and the amount of tax due. Here are some general guidelines:",
    ],
    points: [
      "1. For individuals: If an individual files the ITR after the due date (31st July for most taxpayers), but before December 31 of the assessment year (AY), then the penalty is Rs. 5,000. If the return is filed after December 31 of the AY, then the penalty increases to Rs. 10,000.",
      "2. For small taxpayers: If the total income of the taxpayer does not exceed Rs. 5 lakhs, then the maximum penalty for late filing is Rs. 1,000.",
      "3. Interest on late payment: In addition to the above penalties, if the tax liability is not paid on time, then interest at the rate of 1% per month (or part thereof) is charged on the outstanding tax amount.",
      "4. Revised return: If the taxpayer files a revised return after the due date, then the penalty for late filing will still apply, but only if there is tax due on the revised return.",
    ],
  },
  {
    title: "Penalty for Late Filing Income Tax Return",
    desc: [
      "The penalty for late filing of income tax returns (ITR) depends on various factors such as the taxpayer's income, the type of income, the date of filing, and the amount of tax due. Here are some general guidelines:",
    ],
    points: [
      "1. For individuals: If an individual files the ITR after the due date (31st July for most taxpayers), but before December 31 of the assessment year (AY), then the penalty is Rs. 5,000. If the return is filed after December 31 of the AY, then the penalty increases to Rs. 10,000.",
      "2. For small taxpayers: If the total income of the taxpayer does not exceed Rs. 5 lakhs, then the maximum penalty for late filing is Rs. 1,000.",
      "3. Interest on late payment: In addition to the above penalties, if the tax liability is not paid on time, then interest at the rate of 1% per month (or part thereof) is charged on the outstanding tax amount.",
      "4. Revised return: If the taxpayer files a revised return after the due date, then the penalty for late filing will still apply, but only if there is tax due on the revised return.",
    ],
  },
  {
    title: "Income Tax Return Due Date",
    desc: [
      "However, for certain categories of taxpayers, the due date may be different. For example:",
    ],

    subtitle:
      "The due date for filing income tax return (ITR) varies depending on the type of taxpayer and the nature of income. For most individuals, the due date for filing ITR is 31st July of the assessment year (AY). For example, the due date for filing ITR for the financial year (FY) 2021-22 is 31st July 2022.",

    points: [
      "1. Taxpayers who are required to get their accounts audited under section 44AB of the Income Tax Act, the due date for filing ITR is 30th September of the AY.",
      "2. Companies are required to file their ITRs by 30th September of the AY.",
      "3. For taxpayers who are required to furnish a report under section 92E (transfer pricing), the due date for filing ITR is 30th November of the AY.",
    ],
    footer:
      "It is important to note that the due date for filing ITR may be extended by the government in case of any unforeseen circumstances or for specific categories of taxpayers. Therefore, it is always advisable to keep a track of the due date and any extensions announced by the government.",
  },
  {
    title: "Top Income Tax Deductions for year by 2019",
    subtitle: "Section 80C Deduction",
    desc: [
      "Income tax deduction of upto Rs.1.5 lakhs can be claimed on amount paid or deposited in PF, PPF, LIC premium paid, National Savings Certificate, ULIP, principal part of repayment of housing loan, tuition fees paid for children, term deposit in bank, deposit in Senior Citizen savings scheme and more.",
    ],
  },
  {
    subtitle: "Section 80D Deduction",
    desc: [
      "Section 80D deduction can be claimed by individuals and HUF for payments to medical insurance paid by cheque under GI scheme. Also fees of upto Rs.5000 paid for preventive health checkup can be claimed as income tax deduction under Section 80D.",
    ],
  },
  {
    subtitle: "Section 80EE Deduction",
    desc: [
      "Additional deduction under Section 80EE can be claimed on interest on housing loan paid through EMI by the assessee. The maximum deduction allowed under Section 80EE is Rs.1 lakh. The deduction can be availed on the first home loan, the amount of loan does not exceed Rs.35 lakhs and the property value does not exceed Rs.50 lakhs.",
    ],
  },
  {
    subtitle: "Section 80E Deduction",
    desc: [
      "Section 80E deduction can be claimed by individuals for repayment of interest on loan taken in respect of higher deduction. The amount of interest paid can be claimed as a deduction under Section 80E. The maximum period for which this deduction can be availed is 8 years starting from repayment of loan or till the entire loan is repaid, whichever is earlier.",
    ],
  },
  {
    subtitle: "Section 80G Deduction",
    desc: [
      "Section 80G deduction can be claimed on donations to certain funds, charitable institutes within the ceiling amount of 10% of the Gross Taxable Income. The amount of deduction available would depend on the exemption enjoyed by the fund. Section 80G deduction cannot be claimed for cash deductions of more than Rs.2000.",
    ],
  },
  {
    subtitle: "Income Tax Deductions",
    desc: [
      "Various income tax deductions are available for taxpayers to save on taxes payable. You can use the income tax calculator or read the article below to know more about income tax deductions.",
    ],
  },
  {
    title: "Personal Tax Return Filing FAQ's",
    subtitle: "1. When am I supposed to file my income tax returns?",
    desc: [
      "Individuals, NRIs, partnership firms, LLPs, companies and Trusts are supposed to file Income Tax Returns every year.",
    ],
  },
  {
    subtitle: "2. Who is to file ITR-1?",
    desc: [
      "ITR-1 form can be used by Individuals who have less than Rs.50 Lakhs of annual income as salary or pension and have one house property only.",
    ],
  },
  {
    subtitle: "3. Is there a penalty if I fail to file my Income Tax Returns?",
    desc: [
      "Yes, taxpayers who do not file their Income Tax Return on time are subject to penalty and charged an interest on the late payment of income tax. The penalty for late filing has been increased recently.",
    ],
  },
  {
    subtitle: "4. Who is supposed to file ITR-3?",
    desc: [
      "ITR-3 form must be filed by professionals or people who are operating a proprietorship business in India.",
    ],
  },
  {
    subtitle: "5. Why is ITR-5 filed?",
    desc: [
      "ITR-5 form is filed by partnership firms, LLPs, associations and body of individuals to report their income and computation of tax.",
    ],
  },
  {
    subtitle: "6. Who is supposed to file ITR-2?",
    desc: [
      "ITR-2 form must be filed by individuals who are NRIs, Directors of Companies, shareholders of private companies or having capital gains income, income from foreign sources, two or more house property, income of more than Rs. 50 lakh.",
    ],
  },
];
const dataBusinessFiling = [
  {
    pagename: "Business Tax Filing",
  },
  {
    title: "Business Tax Filing",
    desc: ["Here are some general steps involved in business tax filing:"],
    subtitle:
      "Business tax filing is the process of reporting and paying taxes on the income earned by a business entity. The tax filing requirements for businesses depend on the legal structure of the business, such as whether it is a sole proprietorship, partnership, limited liability company (LLC), or corporation.",
    points: [
      "1. Keep accurate records: It is important for businesses to maintain accurate financial records throughout the year, including income, expenses, and any deductions.",
      "2. Determine taxable income: Once the financial records are in place, the business needs to calculate its taxable income. This can be done by subtracting eligible deductions from the total income earned by the business.",
      "3. Choose the correct tax form: Depending on the legal structure of the business and the amount of taxable income, the business needs to choose the appropriate tax form to file. For example, a sole proprietorship may use Schedule C to report business income and deductions, while a corporation may use Form 1120.",
      "4. File taxes on time: The business needs to file its tax return and pay any taxes owed by the due date. For most businesses, the due date for filing taxes is March 15th for calendar year businesses and September 15th for fiscal year businesses. However, there may be extensions available in some cases.",
      "5. Pay estimated taxes: Businesses may also be required to pay estimated taxes throughout the year, based on the amount of income earned. These estimated tax payments are made quarterly and help to avoid penalties for underpayment of taxes.",
    ],
  },
  {
    title: "Proprietorship",
    desc: ["Here are some key features of a proprietorship in India:"],
    subtitle:
      "A proprietorship is a type of business structure in India that is owned and managed by a single individual. It is the simplest and most common form of business entity, suitable for small and medium-sized businesses.",
    points: [
      "1. Ownership: The business is owned and managed by a single individual who is responsible for all aspects of the business.",
      "2. Liability: The proprietor is personally liable for all debts and obligations of the business. There is no legal distinction between the owner and the business entity.",
      "3. Registration: There is no specific registration requirement for a proprietorship in India. However, the proprietor may need to obtain certain licenses and permits depending on the nature of the business.",
      "4. Taxation: The proprietorship is taxed as the personal income of the proprietor. There is no separate tax filing requirement for the business entity.",
      "5. Expansion: A proprietorship cannot raise funds through the issuance of shares and is limited to the personal resources of the proprietor. It may also face limitations in terms of growth and expansion.",
      "6. Compliance: The proprietor is responsible for complying with all legal and regulatory requirements, including filing of income tax returns, maintenance of accounts, and payment of taxes.",
    ],
  },
  {
    title: "Partnership",
    desc: ["Here are some key features of a partnership in India:"],
    subtitle:
      "A partnership is a type of business structure in India in which two or more persons join together to carry on a business for profit. A partnership is governed by the Indian Partnership Act, 1932.",
    points: [
      "1. Ownership: A partnership is owned and managed by two or more partners who share profits and losses of the business.",
      "2. Liability: Each partner is personally liable for the debts and obligations of the partnership. However, in a limited liability partnership (LLP), the liability of partners is limited to their agreed contribution to the partnership.",
      "3. Registration: A partnership may be registered or unregistered. However, registration is recommended as it provides legal recognition to the partnership and enables it to sue and be sued in its own name.",
      "4. Taxation: A partnership is taxed as a separate entity, and partners are taxed on their share of profits. The partnership is required to file a separate income tax return.",
      "5. Expansion: A partnership can be expanded by adding new partners or by reconstituting the partnership through a partnership deed. However, the consent of all partners is required for admission or retirement of a partner.",
      "6. Compliance: A partnership is required to maintain proper books of accounts and comply with various legal and regulatory requirements, including filing of income tax returns and other tax-related compliances.",
    ],
  },
  {
    title: "LLP",
    desc: ["Here are some key features of an LLP in India:"],
    subtitle:
      "LLP stands for Limited Liability Partnership, which is a type of business structure in India that combines the benefits of a partnership and a limited liability company. It was introduced in India in 2009 through the Limited Liability Partnership Act, 2008.",

    points: [
      "1. Ownership: An LLP is owned by its partners, who are not personally liable for the debts and obligations of the LLP. The liability of partners is limited to their agreed contribution to the LLP.",
      "2. Registration: An LLP is required to be registered with the Registrar of Companies (ROC) under the provisions of the LLP Act. The registration process involves obtaining a DPIN (Designated Partner Identification Number) and DIN (Director Identification Number), digital signature certificate, and filing of incorporation documents with the ROC.",
      "3. Taxation: An LLP is taxed as a partnership, and partners are taxed on their share of profits. An LLP is required to file an annual return and an income tax return.",
      "4. Management: The management of an LLP is carried out by designated partners, who are responsible for compliance with various legal and regulatory requirements.",
      "5. Expansion: An LLP can be expanded by adding new partners or by reconstituting the LLP through an agreement. However, the consent of all partners is required for admission or retirement of a partner.",
      "6. Compliance: An LLP is required to comply with various legal and regulatory requirements, including filing of annual returns, maintenance of books of accounts, and other tax-related compliances.",
    ],
  },
  {
    title: "Companies",
    subtitle: "Proprietorship Tax Return Filing",
    desc: [
      "As a proprietor, you are required to file your income tax return annually if your total income exceeds the basic exemption limit, which is currently Rs 2.5 lakhs for individuals (as of 2023). Here are the steps to file your income tax return as a proprietor:",
    ],
    points: [
      "1. Gather all the required documents: You will need to collect all the necessary documents related to your income and expenses for the financial year, including invoices, receipts, bank statements, and other relevant documents.",
      "2. Calculate your income: You will need to calculate your total income for the financial year, including income from your business, any other sources of income, and deductions.",
      "3. Prepare your tax return: You can prepare your tax return using online tax filing portals or through an accountant. You will need to fill in the required information, including your personal details, income details, and tax calculations.",
      "4. Verify your tax return: Once you have prepared your tax return, you will need to verify it. You can do this by signing and sending the ITR-V (Income Tax Return Verification) form to the Income Tax Department or by using digital signature.",
      "5. Submit your tax return: You can submit your tax return online through the Income Tax Department's e-filing portal or through a tax professional.",
    ],
  },
  {
    title: "Requirement for Filing Proprietorship Tax Return",
    desc: [
      "As a proprietor, you are required to file your income tax return in India if your total income during the financial year exceeds the basic exemption limit, which is currently Rs 2.5 lakhs for individuals (as of 2023). Here are some of the requirements for filing a tax return as a proprietor in India:",
    ],
    points: [
      "1. PAN card: You must have a valid Permanent Account Number (PAN) to file your income tax return.",
      "2. Maintain proper books of accounts: As a proprietor, you are required to maintain proper books of accounts for your business, including a cash book, ledger, and other relevant documents.",
      "3. Prepare financial statements: You need to prepare financial statements, including a profit and loss account and balance sheet, to calculate your taxable income.",
      "4. Claim deductions: You can claim deductions for expenses incurred in the course of your business, such as rent, salaries, and depreciation.",
      "5. File the return: Once you have calculated your taxable income, you need to file your income tax return on or before the due date.",
      "6. Pay taxes: If you have any tax liability after claiming deductions and exemptions, you need to pay the taxes due before filing your income tax return.",
    ],
  },
  {
    title: "Income Tax Rate for Proprietorship",
    desc: [
      "The income tax rate for proprietorship is the same as the income tax rate for individuals. Unlike the income tax rate for LLP or Company which are flat rates, proprietorships are taxed on slab rates. The following is the income tax rate applicable for proprietorships for assessment year 2019-20, wherein the age of the proprietor is less than 60.",
    ],
  },
  {
    table: (
      <div className="table">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Taxable Amount</th>
              <th scope="col">Tax Rate</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Rs. 0 - Rs. 2,50,000</td>
              <td>0%</td>
            </tr>
            <tr>
              <td>Rs. 2,50,001 to Rs. 5,00,000</td>
              <td>5%</td>
            </tr>
            <tr>
              <td>Rs. 5,00,001 - Rs.10,00,000</td>
              <td>20%</td>
            </tr>
            <tr>
              <td>Above Rs. 10,00,000</td>
              <td>30%</td>
            </tr>
          </tbody>
        </table>
      </div>
    ),
  },
  {
    title: "Tax Audit/Due Date/Return for Proprietorship",
    subtitle: "Tax Audit:",
    desc: [
      "If the turnover of your proprietorship business exceeds Rs 1 crore in a financial year, you are required to get your accounts audited by a chartered accountant (CA) under Section 44AB of the Income Tax Act. The tax audit report needs to be filed along with the income tax return.",
    ],
  },
  {
    subtitle: "Due Date for Proprietorship Tax Return:",
    desc: [
      "The due date for filing income tax returns for proprietors in India is usually July 31st of the assessment year (for example, for the financial year 2022-23, the due date for filing income tax returns is July 31, 2023), unless extended by the government.",
    ],
  },
  {
    subtitle: "Return Filing for Proprietorship:",
    desc: [
      "As a proprietor, you need to file your income tax return in ITR-3 form if you have income from business or profession. Here are the steps for filing your income tax return:",
    ],
    points: [
      "1. Calculate your income: You need to calculate your total income for the financial year, including income from your business, any other sources of income, and deductions.",
      "2. Prepare your tax return: You can prepare your tax return using online tax filing portals or through an accountant. You need to fill in the required information, including your personal details, income details, and tax calculations.",
      "3. Verify your tax return: Once you have prepared your tax return, you need to verify it. You can do this by signing and sending the ITR-V (Income Tax Return Verification) form to the Income Tax Department or by using digital signature.",
      "4. Submit your tax return: You can submit your tax return online through the Income Tax Department's e-filing portal or through a tax professional.",
    ],
  },
  {
    title: "Partnership Firm Tax Return Filing",
    desc: [
      "As a partnership firm, you are required to file your income tax return in India if your total income during the financial year exceeds the basic exemption limit, which is currently Rs 2.5 lakhs for individuals (as of 2023). Here are the steps for filing a tax return for a partnership firm in India:",
    ],
    points: [
      "1. Obtain a PAN card: The partnership firm must obtain a Permanent Account Number (PAN) from the Income Tax Department.",
      "2. Prepare financial statements: The partnership firm must prepare financial statements for the financial year, including a profit and loss account, balance sheet, and other relevant documents.",
      "3. Get the accounts audited: If the turnover of the partnership firm exceeds Rs 1 crore in a financial year, the accounts must be audited by a chartered accountant.",
      "4. Calculate taxable income: The partnership firm needs to calculate its taxable income, taking into account all sources of income and claiming all applicable deductions and exemptions.",
      "5. File the return: Once the taxable income has been calculated, the partnership firm needs to file its income tax return using Form ITR-5. The return should be filed online through the Income Tax Department's e-filing portal.",
      "6. Verify the return: After filing the return, the partnership firm must verify it using either digital signature or Aadhaar-based OTP.",
    ],
  },
  {
    title: "LLP Tax Return Filing",
    subtitle:
      "As an LLP (Limited Liability Partnership), you are required to file your income tax return in India if your total income during the financial year exceeds the basic exemption limit, which is currently Rs 2.5 lakhs for individuals (as of 2023). Here are the steps for filing a tax return for an LLP in India:",
    points: [
      "1. Obtain a PAN card: The LLP must obtain a Permanent Account Number (PAN) from the Income Tax Department.",
      "2. Prepare financial statements: The LLP must prepare financial statements for the financial year, including a profit and loss account, balance sheet, and other relevant documents.",
      "3. Get the accounts audited: If the turnover of the LLP exceeds Rs 40 lakhs in a financial year or the contribution of partners exceeds Rs 25 lakhs, the accounts must be audited by a chartered accountant.",
      "4. Calculate taxable income: The LLP needs to calculate its taxable income, taking into account all sources of income and claiming all applicable deductions and exemptions.",
      "5. File the return: Once the taxable income has been calculated, the LLP needs to file its income tax return using Form ITR-5. The return should be filed online through the Income Tax Department's e-filing portal.",
      "6. Verify the return: After filing the return, the LLP must verify it using either digital signature or Aadhaar-based OTP.",
    ],
  },
  {
    title: "Company Tax Return Filing",
    subtitle:
      "As a company in India, you are required to file your income tax return if your total income during the financial year exceeds the basic exemption limit, which is currently Rs 2.5 lakhs for individuals (as of 2023). Here are the steps for filing a tax return for a company in India:",
    points: [
      "1. Obtain a PAN card: The company must obtain a Permanent Account Number (PAN) from the Income Tax Department.",
      "2. Prepare financial statements: The company must prepare financial statements for the financial year, including a profit and loss account, balance sheet, and other relevant documents.",
      "3. Get the accounts audited: The accounts of the company must be audited by a chartered accountant.",
      "4. Calculate taxable income: The company needs to calculate its taxable income, taking into account all sources of income and claiming all applicable deductions and exemptions.",
      "5. File the return: Once the taxable income has been calculated, the company needs to file its income tax return using Form ITR-6. The return should be filed online through the Income Tax Department's e-filing portal.",
      "6. Verify the return: After filing the return, the company must verify it using either digital signature or Aadhaar-based OTP.",
    ],
  },
  {
    title: "Business Tax Return Filing FAQ's",
    subtitle: ["1. When must a company tax return be filed?", "22-Jun-22"],
    desc: [
      "The company /business subject to audit can file their returns by October 31 of the assessment year. If a taxpayer has an international or specified domestic transaction that is required to furnish a report in Form No. 3CEB, the due date is November 30.",
    ],
  },
  {
    subtitle: [
      "2. Which ITR should a company file?",
      "8-Feb-23",
      "The type of ITR (Income Tax Return) a company should file depends on the type of company it is.",
    ],
    points: [
      "Form ITR-4 – This ITR can be used to File ITR for those firms other than LLPs, which have a total income of up to ?50 lakhs, and that income is calculated under Sections 44AD, 44ADA, 44AE",
      "Form ITR-5 – This ITR can be used for filing ITR for LLPs and partnerships, not ITR 7.",
      "File ITR-6 – Those companies not claiming exemption under Section 11 can use ITR-6 to file an Income tax return.",
      "File ITR-7 – This can be used for those companies that are mandated to file returns from Sections 139(4A), 139(4B), 139(4C), and 139(4D) only.",
    ],
  },
  {
    subtitle: [
      "3. What happens if a company does not file ITR?",
      "8-Feb-23",
      "If a company fails to file its Income Tax Return (ITR), it may face the following consequences:",
    ],
    points: [
      "Penalty: The company may be levied with a penalty for non-filing of ITR; as per section 234F of the IT Act, a fine of Rs.10,000 will be charged for failing to file tax returns,",
      "Interest: In addition to the penalty, the company may also be charged with interest on the outstanding tax amount. Moreover, a delay in ITR filing can result in interest being charged under Section 234A of the Income Tax Act 1961",
      "Prosecution: In severe cases, the company may be prosecuted for non-compliance, leading to the imprisonment of up to 7 years and/or fines.",
      "Disqualification of Directors: The Company’s directors may be disqualified from being appointed directors of any company for up to 5 years.",
      "Loss of Eligibility for Government Contracts: The Company may be disqualified from bidding for government contracts or availing government facilities if it has not filed its ITR.",
    ],
  },
  {
    subtitle: [
      "4. What are the different types of Business Tax Return Filing?",
      "8-Feb-23",
      "The different types of business tax return filing are named based on business entities that are entitled to file these returns, i.e. different structure of businesses and their names accordingly.",
    ],
    points: [
      "Sole proprietorship tax return filing",
      "Partnership firm tax return filing",
      "Limited Liability Partnership tax return filing",
      "Company tax return filing",
    ],
  },
  {
    subtitle: ["5. Can ITR be filed for the previous year?", "22-Jun-22"],
    desc: [
      "Yes, you can file belated ITRs, anytime up to one year from the end of the relevant assessment year. You can submit tax returns up to three years late.",
    ],
  },
  {
    subtitle: [
      "6. For AY 2022-2023 Returns & Forms Applicable applicable to Individual who has business Income?",
      "22-Jun-22",
    ],
    points: [
      "ITR-3 - Applicable for Individual & HUF",
      "ITR-4 (SUGAM) – Applicable for Individual, HUF & Firm (Other than LLP)",
      "Form 16A & Form 26AS",
      "Form 3CB-CD & Form 3CEB",
      "Form 15G& Form 15",
    ],
  },
  {
    subtitle: [
      "7. Who should pay advance tax?",
      "22-Jun-22",
      "If the total tax liability is Rs.10000 or more in a Financial Year, then the (taxpayer) businessman has to pay advance tax in 4 installments:",
    ],
    points: ["15-Jun", "15-Sep", "15-Dec", "15-Mar"],
  },
  {
    subtitle: [
      "8. How Income Tax Is Calculated On Business Income?",
      "22-Jun-22",
    ],
    desc: [
      "Normal provision and presumptive taxation are two different ways to calculate the taxable business income. With normal provision, the taxable income is calculated by deducting the cost of sold goods and expenses from the total sales.",
    ],
  },
  {
    subtitle: [
      "9. What are the criteria for opting for the Presumptive Taxation Scheme?",
      "22-Jun-22",
    ],
    desc: [
      "Small businesses or firms that do not maintain books of accounts can opt for Presumptive Taxation. For businesses, the option is available for turnover or receipts up to Rs.2 Crore. Businesses opting this scheme should offer at least 8% of the turnover or receipts as income and 6% in case of payment via banking channels & electronic mode",
    ],
  },
  {
    subtitle: [
      "10. With presumptive taxation, the taxable income is a fixed percentage of the total sales. What Are the Tax Rate For Businesses?",
      "22-Jun-22",
    ],
    desc: [
      "For instance, if you own a business and are below 60 years, your income tax for business in India will be based on these slabs:",
    ],
    points: [
      "Income up to Rs. 2.5L- NIL",
      "Income between Rs. 2.5-5L lakh- 5%",
      "Income between Rs. 5-10L- 20%",
      "Income above Rs. 10L - 30",
    ],
  },
];
const dataTaxNotice = [
  {
    pagename: "TAX NOTICE",
  },
  {
    title: "TAX NOTICE",
    subtitle: ["Income Tax Notice Response"],
  },
  {
    subtitle: "Income Tax Notice",
    desc: [
      "The Income Tax Department sends the notices for various reasons like not filing the income tax returns, any defect while filing the returns, or other instances where the tax department is requiring any additional documents or information.",
      "Nothing is frightening or alarming about the notice that is received. But the taxpayer has to first understand the notice, the nature of the notice, the requestor's order in the notice, and take steps to comply.",
      "Dalmaf offers a comprehensive suite of services for families and businesses to help them in maintaining the compliances. In case an income tax notice is received get in touch with the Tax Expert at Dalmaf to understand the income tax notice and determine a course of action.",
    ],
  },
  {
    table: (
      <div>
        <h3 className="banner-heading after-line mb-3">
          Types of Income Tax Notice
        </h3>
        <div className="table">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Type of Notice</th>
                <th scope="col">Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Notice u/s 143(1) - Intimation</td>
                <td>
                  This is one of the most commonly received income tax notices.
                  The income tax department sends this notice seeking a response
                  to the errors/ incorrect claims/ inconsistencies in an income
                  tax return that was filed.If an individual wants to revise the
                  return after receiving this notice, it must be done within 15
                  days.Else, the tax return will be processed after making the
                  necessary adjustments mentioned in the 143(1) tax notice.
                </td>
              </tr>
              <tr>
                <td>Notice u/s 142(1) - Inquiry</td>
                <td>
                  This notice is addressed to the assessee when the return is
                  already filed and further details and documents are required
                  from the assessee to complete the process.This notice can also
                  be sent to necessitate a taxpayer to provide additional
                  documents and information.
                </td>
              </tr>
              <tr>
                <td>Notice u/s 139(1) - Defective Return</td>
                <td>
                  An income tax notice under Section 139(1) would be issued if
                  the income tax return filed does not contain all necessary
                  information or incorrect information.
                  <br />
                  If tax notice under Section 139(1) is issued, you should
                  rectify the defect in the return within 15 days.
                </td>
              </tr>
              <tr>
                <td>Notice u/s 143(2) - Scrutiny</td>
                <td>
                  An income tax notice under Section 143(2) is issued if the tax
                  officer was not satisfied with the documents and information
                  that was submitted by the taxpayer.Taxpayers who receive
                  notice under Section 142(2) have been selected for detailed
                  scrutiny by the Income Tax department and will have to submit
                  additional information.
                </td>
              </tr>
              <tr>
                <td>Notice u/s 156 - Demand Notice</td>
                <td>
                  This type of income tax notice is issued by the Income Tax
                  Department when any tax, interest, fine, or any other sum is
                  owed by the taxpayer.All demand tax notice will stipulate the
                  sum which is outstanding and due from the taxpayer.
                </td>
              </tr>
              <tr>
                <td>Notice Under Section 245</td>
                <td>
                  If the officer has reason to believe that tax has not been
                  paid for the previous years and he wants to set off the
                  current year's refund against that demand, a notice u/s 245
                  can be issued.However, the adjustment of demand and refund
                  could be done only if the individual has been provided proper
                  notice and an opportunity to be heard. The recipient has to
                  respond to the notice is 30 days from the day of receipt of
                  the notice.If the individual does not respond within the
                  specified timeline, the assessing officer can consider this as
                  consent and proceed with the assessment.Therefore, it is
                  advisable to respond to the notice at the earliest.
                </td>
              </tr>
              <tr>
                <td>Notice Under Section 148</td>
                <td>
                  The officer may have a reason to believe that you have not
                  disclosed your income correctly and therefore, you have paid
                  lower taxes.Or the individual may not have filed his return at
                  all, even if you must have filed it as per law. This is termed
                  as income escaping assessment. Under these circumstances, the
                  assessing officer is entitled to assess or reassess the
                  income, according to the case.Before making such an assessment
                  or reassessment, the assessing officer should serve a notice
                  to the assessee asking him to furnish his return of income.The
                  notice issued for this purpose is issued under the provisions
                  of Section 148.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    ),
  },
  {
    title: "Service of Income Tax Notice",
    subtitle: ["Recipient of the Notice:"],
    desc: [
      "In India, the recipient of an income tax notice can be any individual or entity that is registered with the Income Tax Department and is liable to pay income tax. This includes:",
    ],
    points: [
      "1. Individuals who have taxable income, including salaried individuals, self-employed professionals, and business owners.",
      "2. Companies and other business entities that are registered with the Registrar of Companies (ROC).",
      "3. Trusts, charitable institutions, and non-profit organizations that are registered with the Income Tax Department.",
      "4. Any person or entity that has been identified by the tax authorities as having potentially evaded taxes or underreported income.",
    ],
  },
  {
    subtitle: "Service by Post:",
    desc: [
      "In India, income tax notices are typically served by the tax authorities through registered post. If you receive an income tax notice by post, it is important to take prompt action and respond to the notice within the time period specified in the notice.",
      "The notice will be sent to the taxpayer's registered address as per the Income Tax Department's records. It is important for taxpayers to ensure that their registered address is up-to-date and accurate to avoid missing any important notices from the tax authorities.",
      "When you receive the income tax notice by post, carefully read the contents of the notice to understand the reason for the notice and the action that you need to take. The notice will typically require you to provide additional information or documentation, or to attend a meeting with a tax official.",
    ],
  },
  {
    title: "Service by Affixture: ",
    desc: [
      "In India, income tax notices can also be served by affixture in certain circumstances. This means that if the tax authorities are unable to serve the notice to the taxpayer by post or in person, they may serve the notice by affixing it to a conspicuous part of the property or premises of the taxpayer.",
      "Before serving the notice by affixture, the tax authorities will make a reasonable attempt to serve the notice by post or in person. If they are unable to do so, they will affix the notice to a conspicuous part of the property or premises, such as the front door or the notice board.",
      "If you receive an income tax notice by affixture, it is important to take prompt action and respond to the notice within the time period specified in the notice. You should carefully read the contents of the notice to understand the reason for the notice and the action that you need to take.",
    ],
  },
];
const dataIRT1 = [
  {
    pagename: "ITR-1 Return",
  },
  {
    title: "ITR 1 Filing Form",
    desc: [
      "The Income Tax Department has categorized the taxpayers into the group to ease the tax compliances this categorization is done based on the income and the sources of the income. ITR 1 Return filing in India is for people with an income of up to Rs.50 lakh.",
      "This article is a comprehensive guide to understand the ITR 1 Sahaj Form. Each category of the taxpayer has to compute the taxable income as it is laid down in the Income Tax Act,1961. Post computation it is necessary for the taxpayer to file the Income Tax Returns",
    ],
  },
  {
    title: ["Who can file ITR 1 Sahaj Form?"],
    subtitle: [
      "The ITR-1 form, also known as Sahaj, is designed for individuals with straightforward income sources. It can be filed by individuals who meet the following criteria:",
    ],
    points: [
      "Resident Individuals: The ITR-1 form can be filed by resident individuals, including individuals who are ordinarily resident or not ordinarily resident in India.",
    ],
  },
  {
    subtitle: [
      "Income Sources: The form is applicable for individuals who have income from the following sources:",
    ],
    points: [
      "a. Salary or Pension: Income received as a salary or pension.",
      "b. One House Property: Income from a single house property, excluding cases where losses are brought forward from previous years.",
      "c. Other Sources: Income from other sources like interest from savings accounts, fixed deposits, etc. Additionally, deductions under sections 80C to 80U can be claimed.",
    ],
  },
  {
    subtitle: [
      "It's important to note that there are certain conditions under which an individual cannot file the ITR-1 form. The form is not applicable if:",
    ],
    points: [
      "Total Income Limit: The total income exceeds Rs. 50 lakhs.",
      "Income from Business or Profession: The individual has income from business or profession.",
      "Ownership of Foreign Assets: The individual holds any foreign assets or has signing authority in any account located outside India.",
      "Agricultural Income: The individual has agricultural income exceeding Rs. 5,000.",
      "Resident but Not Ordinarily Resident (RNOR): Individuals who qualify as RNOR are not eligible to file the ITR-1 form.",
      "Please keep in mind that these criteria are based on general guidelines, and there may be specific circumstances where the ITR-1 form may not be suitable or other forms may be required. It is always advisable to consult a tax professional or refer to the official guidelines of the Income Tax Department for accurate information and to determine the appropriate form for filing your income tax return.",
    ],
  },
  {
    title: "Who cannot file ITR 1 Sahaj Form?",
    subtitle: ["The following individuals cannot file the ITR-1 Sahaj Form:"],
    points: [
      "Non-Resident Individuals: Non-resident individuals, including Non-Resident Indians (NRIs), cannot use the ITR-1 form to file their income tax returns. They are required to use the appropriate form applicable to their income and residential status, such as ITR-2 or ITR-3.",
      "HUFs (Hindu Undivided Families): The ITR-1 form is not applicable for HUFs. HUFs are separate legal entities that have their own tax filing requirements and should use the appropriate form, such as ITR-2 or ITR-3.",
      "Individuals with Income from Business or Profession: If an individual has income from business or profession, they cannot use the ITR-1 form. Individuals engaged in any form of business activity, whether as a proprietor or a partner in a partnership firm, need to use the appropriate form, such as ITR-3 or ITR-4.",
      "Individuals with Income from Capital Gains: If an individual has income from capital gains, such as gains from the sale of stocks, mutual funds, real estate, etc., they are not eligible to use the ITR-1 form. They should use the appropriate form, such as ITR-2 or ITR-3, depending on the nature of the capital gains.",
      "Individuals with More than One House Property: The ITR-1 form is not suitable for individuals who own more than one house property. If an individual has income from multiple house properties, they should use the appropriate form, such as ITR-2 or ITR-3.",
      "Individuals with Foreign Assets or Foreign Income: If an individual holds any foreign assets or has foreign income, they cannot use the ITR-1 form. They should use the appropriate form, such as ITR-2 or ITR-3, which have provisions for reporting foreign assets and foreign income.",
      "It's important to carefully consider your income sources, residential status, and other factors to determine the correct form for filing your income tax return. If you are unsure about the appropriate form or have complex tax situations, it is advisable to consult a tax professional or refer to the official guidelines of the Income Tax Department for accurate information and guidance.",
    ],
  },
  {
    title: "Documents required to file ITR 1 Sahaj Form",
    subtitle: ["What documents are required to file ITR 1 Sahaj Form?"],
    points: [
      "1. Form 16",
      "2. Salary slips",
      "3. Interest Certificates from the Post offices and Banks",
      "4. Form 16A/16B/16C",
      "5. Form 26AS",
      "6. Tax saving investment proof",
      "7. Deduction under the Section 80 D to 80 U",
      "8. Home Loan statement from the NBFC or the Bank",
      "9. Capital Gains.",
    ],
  },
  {
    title:
      "The income Tax rate under the Existing Tax Regime and the New Tax Regime ( For TDS return Filing)",
    subtitle: [
      "The taxpayers now have an option to choose between the old and the new tax regimes. The decision of opting for a tax regime has to be taken at the beginning of the financial year.",
    ],
    desc: ["The income tax rates according to the old tax regime are:"],
  },
  {
    table: (
      <div>
        <h3 className="banner-heading after-line mb-3">
          For individuals up to the age of 60 years
        </h3>
        <div className="table">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Taxable Income</th>
                <th scope="col">Income Tax Rate</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Up to INR 2,50,000</td>
                <td>Nil</td>
              </tr>
              <tr>
                <td>INR 2,50,000 - INR5,00,000</td>
                <td>5%</td>
              </tr>
              <tr>
                <td>INR 5,00,000 – INR 10,00,000</td>
                <td>20%</td>
              </tr>
              <tr>
                <td>Above INR 10,00,000</td>
                <td>30%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    ),
  },
  {
    table: (
      <div>
        <h3 className="banner-heading after-line mb-3">
          For individuals aged between 60-80 years (Senior Citizen)
        </h3>
        <div className="table">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Taxable Income</th>
                <th scope="col">Income Tax Rate</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Up to INR 3,00,000</td>
                <td>Nil</td>
              </tr>
              <tr>
                <td>INR 3,00,000 - INR5,00,000</td>
                <td>5%</td>
              </tr>
              <tr>
                <td>INR 5,00,000 – INR 10,00,000</td>
                <td>20%</td>
              </tr>
              <tr>
                <td>Above INR 10,00,000</td>
                <td>30%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    ),
  },
  {
    table: (
      <div>
        <h3 className="banner-heading after-line mb-3">
          For individuals above the age of 80 years ( Super senior citizens)
        </h3>
        <div className="table">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Taxable Income</th>
                <th scope="col">Income Tax Rate</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Up to INR 5,00,000</td>
                <td>Nil</td>
              </tr>
              <tr>
                <td>INR 5,00,000 – INR 10,00,000</td>
                <td>20%</td>
              </tr>
              <tr>
                <td>Above INR 10,00,000</td>
                <td>30%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    ),
  },
  {
    title:
      "The income Tax rate under the Existing Tax Regime and the New Tax Regime ( For TDS return Filing)",
    subtitle: [
      "The taxpayers now have an option to choose between the old and the new tax regimes. The decision of opting for a tax regime has to be taken at the beginning of the financial year.",
    ],
    desc: ["The income tax rates according to the old tax regime are:"],
  },
  {
    desc: [
      "The new tax regime is where the taxpayer has an option to choose either to pay taxes at a lower interest rate as per the new tax regime on the condition that they forgo certain permissible exemptions and deductions that are available the income tax.",
      "The taxpayer can continue paying taxes under the existing tax rates. The assessee can avail of the rebates and the exemption by staying in the old regime and pay taxes at the existing high rates.",
    ],
  },
  {
    table: (
      <div>
        <h3 className="banner-heading after-line mb-3">
          The income tax rates under the new tax regimes that is applicable for
          individuals and the HUFs is as follows:
        </h3>
        <div className="table">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Income Slab</th>
                <th scope="col">
                  New regime tax slab rate(Applicable for all individuals and
                  HUF)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td col>Up to INR 2,50,000</td>
                <td>Nil</td>
              </tr>
              <tr>
                <td>INR 2,50,000 - 3,00,000</td>
                <td rowspan="2">5% (Tax rebate u/s 87 a is available)</td>
              </tr>
              <tr>
                <td>INR 3,00,000 - 5,00,000</td>
              </tr>

              <tr>
                <td>INR 5,00,000 - 7,50,000</td>
                <td>10%</td>
              </tr>
              <tr>
                <td>INR 7,50,000 - 10,00,000</td>
                <td>15%</td>
              </tr>
              <tr>
                <td>INR 10,00,000 - 12,50,000</td>
                <td>20%</td>
              </tr>
              <tr>
                <td>INR 12,50,000 - 15,00,000</td>
                <td>25%</td>
              </tr>
              <tr>
                <td>More than INR 15,00,000</td>
                <td>30%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    ),
  },
  {
    subtitle: "Note:",
    desc: [
      "The tax rates in the New Tax regime are the same for all categories of individuals. Hence, there is no increased basic exemption limit benefit that will be available to the senior and the super senior citizens in the New Tax Regime.",
      "Individuals with Net Taxable Income less than or equal to Rs 5 lakh will be eligible for the tax rebate u/s 87 A the tax liability will be Nil for such individuals in both New and Old existing tax regimes.",
      "The exemption limit for NRIs is Rs. 2.5 lakh irrespective of age.",
      "Additional health and education cess at the rate of 4% will be added to the Income-tax liability in all cases ( Increased from 3% since FY 2018-19)",
    ],
  },
  {
    subtitle:
      "An applicable surcharge as per tax rates below in all categories mentioned above:",
    points: [
      "1. 10% of the income tax if total income > Rs. 50 lakh.",
      "2. 15% of the income tax if the total income > Rs.1 crore",
      "3. 25% of the income tax if the total income > Rs.2 crore.",
      "37% of the income tax is the total income > Rs.5 crore.",
    ],
  },
  {
    subtitle: "What are the conditions to opt for a new tax regime?",
    desc: [
      "The new tax regime in India, introduced in the Union Budget 2020, offers taxpayers the option to choose between the existing tax regime (with various exemptions and deductions) or a simplified tax regime with lower tax rates but limited exemptions and deductions. To opt for the new tax regime, taxpayers must meet the following conditions:",
    ],
  },
  {
    subtitle:
      "The list of common exemptions and deductions that are not allowed in the new Income tax regime are:",
    points: [
      "•	Leave travel Allowance",
      "•	House Rent Allowance",
      "•	Conveyance Allowance",
      "•	Daily expenses in the course of employment",
      "•	Relocation Allowance",
      "•	Helper Allowance",
      "•	Children Education Allowance",
      "•	Other Special Allowances [Section 10(4)]",
      "•	Standard deduction on salary",
      "•	Professional tax",
      "•	Interest on housing loan (Section 24)",
      "•	Deductions under Chapter VI A deduction (80C, 80D, 80E and so on) (Except Section 80CCD (2))",
    ],
  },
  {
    subtitle: "List of common deductions allowed the New Tax Regime",
    points: [
      "•	Transport allowance for specially-abled people",
      "•	Conveyance allowance for expenditure incurred for traveling to work.",
      "•	Investment in Notified Pension Scheme under Section 80 CCD(2)",
      "•	Deduction for the employment of new employees under Section 80JJAA",
      "•	Depreciation u/s 32 of the Income Tax Act except for additional depreciation.",
      "•	Any allowance for traveling for employment or on transfer.",
    ],
  },
  {
    title: "Major Amendments to ITR 1 filing for the AY 2021-2022",
    desc: [
      "For the Assessment Year 2021-2022 (FY 2020-2021), there were a few notable amendments to the ITR-1 filing requirements in India. Some of the major changes included:",
    ],
    points: [
      "1. Reporting of Salary Breakup: Taxpayers were required to provide a detailed breakup of their salary income, including allowances, perquisites, and deductions claimed under section 10. This aimed to bring more transparency to the salary structure.",
      "2. Disclosure of Cash Deposits: Individuals were required to disclose the aggregate amount of cash deposited in their bank accounts if the total amount exceeded Rs. 1 crore during the financial year. This was done to track high-value cash transactions.",
      "3. Opting for New Tax Regime: The new tax regime introduced in the Union Budget 2020 provided lower tax rates but limited exemptions and deductions. Taxpayers were required to indicate whether they opted for the new tax regime or continued with the existing tax regime while filing ITR-1.",
      "4. Dividend Income: Taxpayers were required to provide details of dividend income received during the financial year. Earlier, dividend income up to Rs. 10 lakhs was exempt from tax, but from FY 2020-2021, dividend income became taxable in the hands of the recipient.",
      "5. Exemption under 115BAC: Taxpayers opting for the new tax regime had to consider the exemption provisions under section 115BAC, which provided concessional tax rates. However, certain exemptions and deductions were not allowed under this section.",
    ],
  },
  {
    title: "Documents Required For ITR-1 Return Filing",
    points: ["1. Form 16", "2. Bank Statement", "Details of Other Income"],
  },
  {
    title: "ITR-1 Return Filing FAQ's",
    subtitle: ["Who can File ITR 1?"],
    points: [
      "ITR 1 can be used by individuals who is a resident of India for tax purposes only. Also, all the taxpayers with income only under any or all three heads of income that includes Salaries, Income from House Property, and Income from other sources are eligible to file ITR 1.",
    ],
  },
  {
    subtitle: "How to file ITR 1?",
    points: [
      "ITR 1 can be filed both offline as well online.",
      "While filing ITR 1 is it necessary to show the interest income from other sources",
    ],
  },
  {
    subtitle: "if the TDS is already deducted?",
    points: [
      "Yes, it is necessary to include interest income from other sources even if the tax is deducted by the bank.",
    ],
  },
  {
    subtitle: "How to file ITR 1 returns offline?",
    points: [
      "ITR 1 returns can be filed offline: By furnishing a return in Physical Paper form after which an acknowledgment is issued at the time of submission of the physical paper return.",
    ],
  },
  {
    subtitle: "How to file ITR 1 returns online?",
    points: [
      "ITR 1 returns can be filed online by transmitting the data electronically and then by submitting the verification of the return in Form ITR V to the CPC Bengaluru. By making the return filing online and then e-verifying the ITR V through net banking/Aadhar OTP/EVC. If the ITR 1 is submitted electronically then the acknowledgment will be sent to the registered via email. Also, it can be downloaded from the income tax website then sign and send to the income tax department's CPC office in Bangalore within 120 days of e-filing.",
    ],
  },
  {
    subtitle: "Is ITR 1 an annexure less form?",
    points: [
      "ITR 1 form is an annexure less return, there is no need to attach any documents such as Form 16 or Form 26 AS with the ITR 1 form.",
    ],
  },
];
const dataIRT2 = [
  {
    pagename: "ITR-2 Return",
  },
  {
    title: "ITR 2 Form Filing",
    desc: [
      "ITR 2 Form is an Important form income tax return form that is used by the Indian Citizens as well as the NRIs to file the return with the Income-tax department of India. The taxpayers who are not eligible to file ITR 1 can file ITR 1 to file the income tax returns. ITR 2 can be filed by the individuals and Hindu Undivided Families who have their income for the financial year through salary, pension, more than one property, income from capital gains, income from foreign assets, business or income from a profession as a partner and other sources that include lottery, Racehorses, legal gambling.",
      "Also, an individual who is not eligible to file using ITR 1 as the income is exceeding Rs.50 Lakh can file ITR 2 Form.",
    ],
  },
  {
    subtitle: "Who can file Form ITR-2?",
    points: [
      "Form ITR-2 is applicable for individuals and Hindu Undivided Families (HUFs) who have income from various sources, excluding income from profits and gains of business or profession. Here are the individuals who can file Form ITR-2:",
      "Individuals with Income from Salary/Pension: If you have income from salary or pension and no income from business or profession, you can use Form ITR-2. This includes salaried individuals, employees, or retirees.",
      "Income from House Property: If you own more than one house property or have income from house property that includes brought forward losses, you need to file Form ITR-2.",
      "Income from Capital Gains: If you have capital gains from the sale of assets such as stocks, mutual funds, real estate, etc., you should use Form ITR-2.",
      "Income from Other Sources: If you have income from other sources, such as interest income, rental income (excluding income from more than one house property), or any other income not covered in the previous categories, you need to file Form ITR-2.",
      "Resident but Not Ordinarily Resident (RNOR) and Non-Resident Individuals: If you are an RNOR or a non-resident individual, and have income from any of the above-mentioned sources, you should use Form ITR-2 to file your income tax return.",
    ],
  },
  {
    subtitle: "Who is not eligible to file Form ITR-2?",
    points: [
      "Form ITR-2 is designed to cater to individuals and Hindu Undivided Families (HUFs) who have income from various sources, excluding income from profits and gains of business or profession. However, certain individuals are not eligible to file Form ITR-2. Here are some categories of individuals who should not use Form ITR-2 for filing their income tax return:",
      "Individuals with Income from Business or Profession: If you have income from business or profession, including freelancing, consulting, or any form of self-employment, you should not file Form ITR-2. Instead, you should use the appropriate form, such as Form ITR-3 or Form ITR-4, depending on the specific criteria applicable to your business.",
      "Individuals with Income from Presumptive Taxation: Taxpayers who have opted for the presumptive taxation scheme under sections 44AD, 44ADA, or 44AE of the Income Tax Act, are not eligible to file Form ITR-2. They should file their income tax return using the relevant form associated with the applicable presumptive taxation scheme.",
      "Non-Resident Individuals: Non-resident individuals, including Non-Resident Indians (NRIs), are not eligible to file Form ITR-2. NRIs have separate tax filing requirements and need to use the appropriate form applicable to their income and residential status, such as Form ITR-2A or Form ITR-3, depending on their income sources.",
      "Individuals with More Than One House Property and Capital Gains: If you have more than one house property or have capital gains from the sale of assets, but do not have income from business or profession, you should not file Form ITR-2. In such cases, you should consider using Form ITR-3 or the applicable form that allows reporting of multiple house properties and capital gains.",
    ],
  },
  {
    title: "Documents required to file ITR 2",
    subtitle: ["What documents are required to file ITR 2 returns?"],
    points: [
      "1. Copy of the previous year’s tax return",
      "2. Bank Statement",
      "3. TDS Certificate",
      "4. Savings Certificate/ Deductions",
      "5. Interest Statement that shows the interest that is paid throughout the year.",
      "6. Balance sheet, P & I, Account Statement, and other Audit reports wherever they are applicable.",
    ],
  },
  {
    title: "How to file Form ITR 2?",
    subtitle:
      "The ITR 2 Form can be filed with the Income Tax Department either online or offline:",
    points: [
      "1. Either by furnishing the return in a paper for. When paper returns are made at the time of submission of the paper returns an acknowledgment is issued by the Income Tax Department to the assessee.",
      "2. By furnishing the return electronically using the Digital Signature. ITR 2 forms are submitted by the assessee electronically using the Digital Signature Certificate an acknowledgment is received to the registered Email Id.",
      "3. It can also be done by transmitting the data in the return electronically under an electronic verification code.",
      "4. It can be done by transmitting the data in the return electronically and thereafter submitting the verification of the return in Form ITR V.",
      "5. By furnishing a bar-coded return.",
    ],
  },
  {
    title: "Instructions to be filed while Form ITR 2",
    subtitle: [
      "While filing Form ITR-2 for your income tax return in India, it is important to follow these instructions:",
    ],
    desc: [
      "Select the Correct Assessment Year: Ensure that you are filing the return for the appropriate assessment year, which is the year following the financial year for which you are reporting income. For example, for the financial year 2020-2021, the assessment year would be 2021-2022.",
    ],
    points: [
      "1. Personal Information: Provide accurate personal information, including your name, address, PAN (Permanent Account Number), Aadhaar number (if applicable), contact details, and bank account details.",
      "2. Residential Status: Indicate your residential status as resident, non-resident, or resident but not ordinarily resident (RNOR) for the relevant financial year. This determines the tax implications based on your presence and residential status in India.",
      "3. Income Details: Report your income from different sources, such as salary, pension, house property, capital gains, and income from other sources. Fill in the details of each source separately and provide accurate information as per the supporting documents.",
      "4. Deductions and Exemptions: Mention the deductions under various sections of the Income Tax Act that you are eligible to claim. This may include deductions under Section 80C (for investments in specified instruments), Section 80D (for medical insurance premiums), Section 80G (for donations to specified funds), and others. Provide the necessary details and supporting documents for each deduction.",
      "5. Tax Computation: Calculate the tax payable or refundable based on the income and deductions reported. Use the applicable tax slabs and rates for the assessment year. Ensure accurate computation of tax and cross-verify the calculations.",
      "6. Schedule IT: If you have income from foreign assets or foreign income, provide the necessary details in Schedule IT. Report foreign bank accounts, foreign investments, and income earned from outside India, if applicable.",
      "7. Schedule FA: If you are a resident with income from a source outside India, report the details of foreign assets and income in Schedule FA.",
      "8. Verification and Signatures: Verify all the details provided in the form and ensure their accuracy. Sign the form at the appropriate place using a blue ink pen. If the return is being filed by a representative, ensure that the representative's details and signature are also provided.",
      "9. Supporting Documents: Maintain a record of all the supporting documents related to your income, deductions, investments, and other financial transactions. These documents should be retained for future reference and may be required for audit or verification purposes.",
      "10. E-filing or Physical Filing: Decide whether you will file the return electronically (e-filing) or submit a physical copy to the Income Tax Department's Centralized Processing Centre (CPC). Most taxpayers prefer e-filing as it is faster and more convenient. If you choose to e-file, generate the XML file and submit it through the official e-filing portal. If filing physically, print the ITR-V form, sign it, and send it to the CPC within the specified time.",
    ],
  },
  {
    title: "Structure of ITR 2 Form",
    subtitle: ["ITR-2 is divided into:"],
    points: [
      "1. Part A: General Information",
      "2. Schedule S: Details of income from salaries",
      "3. Schedule HP: Details of income from House Property",
      "4. Schedule CG: Computation of income under Capital gains",
      "5. Schedule OS: Computation of income under Income from other sources",
      "6. Schedule CYLA: Statement of income after set off of current year’s losses",
      "7. Schedule BFLA: Statement of income after set off of unabsorbed loss brought forward from earlier years",
      "8. Schedule CFL: Statement of losses to be carried forward to future years",
      "9. Schedule VIA: Statement of deductions (from total income) under Chapter VIA",
      "10. Schedule 80G: Statement of donations entitled for deduction under section 80G",
      "11. Schedule 80GGA: Statement of donations for scientific research or rural development",
      "12. Schedule AMT: Computation of Alternate Minimum Tax payable under section 115JC",
      "13. Schedule AMTC: Computation of tax credit under section 115JD",
      "14. Schedule SPI: Statement of income arising to spouse/ minor child/ son's wife or any other person or association of persons to be included in the income of the assessee in Schedules-HP, CG, and OS",
      "15. Schedule SI: Statement of income which is chargeable to tax at special rates",
      "16. Schedule EI: Details of Exempt Income",
      "17. Schedule PTI: Pass-through income details from business trust or investment fund as per Section 115UA, 115UB",
      "18. Schedule FSI: Statement of income accruing or arising outside India.",
      "19. Schedule TR: Details of Taxes paid outside India",
      "20. Schedule FA: Details of Foreign Assets and income from any source outside India",
      "21. Schedule 5A: Statement of apportionment of income between spouses governed by Portuguese Civil Code",
      "22. Schedule AL: Asset and liability at the year-end (applicable in case the total income exceeds Rs 50 lakhs)",
      "23. Schedule DI: Schedule of tax-saving investments or deposits or payments to claim deduction or exemption in the extended period from 1 April 2020 until 30 June 2020",
      "24. Part B-TI: Computation of Total Income",
      "25. Part B-TTI: Computation of tax liability on total income",
    ],
    footer: [
      "Details to be filled in if the return has been prepared by a Tax Return Preparer.",
    ],
  },
  {
    title: "Major changes were made to the ITR 2 Form for the AY 2021-22.",
    subtitle: ["The Changes that are incorporated in the ITR 3 Form are:"],
    desc: [
      "Under Section 115BAC of the Finance Act, 2020 ITR Forms are updated to include the declaration of choosing between the old or the new tax regime. Also if the assessee is paying the Taxes following the New Tax Regime then Form 10 E is to be submitted.",
    ],
    points: [
      "1. Finance Act 2020 has allowed deferring the payment or the deduction of the tax on the ESOPs that are allowed from an eligible startup covered under section 80-IAC. The Part B of the Schedule TTI seeks disclosure on this deferred tax.",
      "2. The Tax liability of the dividend income from the company's hand is shifted to the investor's hand since the Finance Act,2020. Section 10(34), 10 (35), 115-O, 115 BBDA are amended. A new row is added to schedule OS to allow the deduction of the expenses like the interest from the dividend income. One more new row is added under the Schedule OS to incorporate the details of the dividend income that is taxable in the hands of the unitholders of the business trust.",
      "3. It is necessary to provide the quarterly breakup of the dividend income for the interest calculation under Section 234C.",
      "4. It is necessary to include the marginal effect of the marginal relief by showing the surcharge calculated before the marginal relief as well as after the marginal relief. Previously there was no such requirement.",
      "Under Section 50 C the value of sale consideration in case of the land or building or both is considered. If this sale consideration is less than the stamp duty value then the stamp duty value is considered to be the full value of consideration except for the difference of 5%. The tolerance limit is increased from 5% to 10% and the changes are made to the ITR.",
      "Under schedule 80 GGA a separate disclosure of cash donation with the date is required in the ITR Form.",
      "Under the Scheule 112A and 115 AD (1)(b) (iii) the ITR 2 form is updated with a new column that provides the details of the nature of the securities that are transferred. Also, both schedules are updated to give the grandfathering clause effect by allowing to mention the details including the Sale price, FMV, and COA of the securities.",
    ],
  },
  {
    title: "Documents Required For ITR-2 Return Filing",
    points: ["1. Form 16", "2. Bank Statement", "3. Bank Statement"],
  },
  {
    title: "ITR-2 Return Filing FAQ's",
    subtitle: ["Is a balance sheet necessary for ITR 2 Filing?"],
    desc: [
      "Individuals and the HUFs that are carrying business or profession are required to file the details of the assets and the liabilities through a balance sheet. It is necessary to file schedule AL while filing ITR2 which includes the taxpayer above Rs.50 lakh.",
    ],
  },
  {
    subtitle: "Can ITR 2 be submitted online?",
    desc: [
      "ITR 2 is applicable for the individuals and the HUFs that do not have any income from business or profession. The ITR 2 filing can be done online on the e-filing portal or in excel utility.",
    ],
  },
  {
    subtitle: "How many parts is ITR 2 divided into?",
    desc: ["ITR 2 is divided into 2 parts, Part A and B."],
  },
  {
    subtitle: "What does Part A of ITR 2 include?",
    desc: ["Part A of Form ITR 2 includes all the general information."],
  },
  {
    subtitle: "What does Part B of Form ITR 2 include?",
    desc: [
      "Part B consists of the total income computation and the tax liability on the total income computation.",
    ],
  },
];
const dataIRT3 = [
  {
    pagename: "ITR-3 Return",
  },
  {
    title: "ITR 3 Form Filing",
    points: [
      "ITR 3 Form is applicable for the individuals and Hindu Undivided Families that earn profit and gains from business or profession.",
      "If the individual or the Hindu undivided family is having an income as a partner of a partnership firm that is carrying out business then ITR-3 cannot be filed as in such cases the individual is required to file ITR-2.",
      "Eligibility for filing ITR 3 Form",
    ],
  },
  {
    title: "Who can file ITR 3?",
    subtitle: [
      "Form ITR-3 is applicable for individuals and Hindu Undivided Families (HUFs) who have income from profits and gains of business or profession. Here are the individuals who can file Form ITR-3:",
    ],
    points: [
      "Individuals with Income from Business or Profession: If you have income from a proprietary business or a profession as a freelancer, consultant, doctor, lawyer, architect, etc., you should file Form ITR-3. This form is suitable for individuals who are engaged in a business or profession and have a more complex tax situation compared to individuals filing ITR-1 or ITR-2.",
      "Individuals with Income from Partnership Firm: If you are a partner in a partnership firm and have income from the firm, you should use Form ITR-3. It is important to report your share of profits from the partnership firm along with any other sources of income you may have.",
      "Individuals with Income from House Property, Capital Gains, and Other Sources: In addition to income from business or profession, if you have income from house property, capital gains (such as sale of stocks, mutual funds, real estate, etc.), or any other sources not covered in the above categories, you can still file Form ITR-3. This form allows you to report income from multiple sources.",
    ],
  },
  {
    title: "Who is not eligible to file ITR 3 Form?",
    desc: [
      "In case if the individual or the Hindu Undivided Family is functioning as a partner of the partnership firm that is carrying out business or profession then he cannot file form ITR 3 as he will be eligible to file Form ITR 2.",
    ],
  },
  {
    title: ["Structure of Form ITR 3"],
    subtitle: ["ITR-3 is divided into:"],
    points: [
      "Part A includes-",
      "Part A-GEN: General information and Nature of Business",
      "Part A-BS: Balance Sheet as of March 31, 2020, of the Proprietary Business or Profession",
      "Part A- Manufacturing Account: Manufacturing Account for the financial year 2019-20",
      "Part A- Trading Account: Trading Account for the financial year 2019-20",
      "Part A-P&L: Profit and Loss for the Financial Year 2019-20",
      "Part A-OI: Other Information (optional in a case not liable for audit under Section 44AB)",
      "Part A-QD: Quantitative Details (optional in a case not liable for audit under Section 44AB)",
    ],
  },
  {
    subtitle: "Schedules include:",
    points: [
      "Schedule-S: Computation of income under the head Salaries.",
      "Schedule-HP: Computation of income under the head Income from House Property",
      "Schedule BP: Computation of income from business or profession",
      "Schedule-DPM: Computation of depreciation on plant and machinery under the Income-tax Act",
      "Schedule DOA: Computation of depreciation on other assets under the Income-tax Act",
      "Schedule DEP: Summary of depreciation on all the assets under the Income-tax Act",
      "Schedule DCG: Computation of deemed capital gains on the sale of depreciable assets",
      "Schedule ESR: Deduction under section 35 (expenditure on scientific research)",
      "Schedule-CG: Computation of income under the head Capital gains.",
      "Schedule 112A: Details of Capital Gains where section 112A is applicable",
      "Schedule 115AD(1)(iii)Provision: For Non-Residents Details of Capital Gains where section 112A is applicable",
      "Schedule-OS: Computation of income under the head Income from other sources.",
      "Schedule-CYLA-BFLA: Statement of income after set off of current year’s losses and Statement of income after set off of unabsorbed loss brought forward from earlier years.",
      "Schedule-CYLA: Statement of income after set off of current year’s losses",
      "Schedule BFLA: Statement of income after set off of unabsorbed loss brought forward from earlier years.",
      "Schedule CFL: Statement of losses to be carried forward to future years.",
      "Schedule- UD: Statement of unabsorbed depreciation.",
      "Schedule ICDS: Effect of Income Computation Disclosure Standards on Profit",
      "Schedule- 10AA: Computation of deduction under section 10AA.",
      "Schedule 80G: Statement of donations entitled for deduction under section 80G.",
      "Schedule RA: Statement of donations to research associations etc. entitled for deduction under section 35(1)(ii) or 35(1)(iiA) or 35(1)(iii) or 35(2AA)",
      "Schedule- 80IA: Computation of deduction under section 80IA.",
      "Schedule- 80IB: Computation of deduction under section 80IB.",
      "Schedule- 80IC/ 80-IE: Computation of deduction under section 80IC/ 80-IE.",
      "Schedule VIA: Statement of deductions (from total income) under Chapter VIA.",
      "Schedule SPI-SI-IF: Income of specified persons(spouse, minor, etc) includable in the income of the assessee, Income chargeable at special rates, info partnership firms in which assessee is a partner.",
      "Schedule AMT: Computation of Alternate Minimum Tax Payable under Section 115JC",
      "Schedule AMTC: Computation of tax credit under section 115JD",
      "Schedule SPI: Statement of income arising to spouse/ minor child/ son's wife or any other person or association of persons to be included in the income of the assessee in Schedules-HP, BP, CG, and OS.",
      "Schedule SI: Statement of income which is chargeable to tax at special rates",
      "Schedule-IF: Information regarding partnership firms in which the assessee is a partner.",
      "Schedule EI: Statement of Income not included in total income (exempt incomes)",
      "Schedule PTI: Pass-through income details from a business trust or investment fund as per section 115UA, 115UB",
      "Schedule TPSA: Secondary adjustment to transfer price as per section 92CE(2A)",
      "Schedule FSI: Details of income from outside India and tax relief",
      "Schedule TR: Statement of tax relief claimed under section 90 or section 90A or section 91.",
      "Schedule FA: Statement of Foreign Assets and income from any source outside India.",
      "Schedule 5A: Information regarding apportionment of income between spouses governed by Portuguese Civil Code",
      "Schedule AL: Asset and Liability at the end of the year(applicable where the total income exceeds Rs 50 lakhs)",
      "Schedule DI: Schedule of tax-saving investments or deposits or payments to claim deduction or exemption in the extended period from 1 April 2020 until 30 June 2020",
      "Schedule GST: Information regarding turnover/ gross receipt reported for GST",
      "Part B-TI: Computation of Total Income.",
      "Part B-TTI: Computation of tax liability on total income.",
    ],
    footer: ["Verification"],
  },
  {
    title: "Instructions to be followed while filing ITR 3 Form",
    subtitle: [
      "Form ITR-3 is applicable for individuals and Hindu Undivided Families (HUFs) who have income from profits and gains of business or profession. Here are the individuals who can file Form ITR-3:",
    ],
    desc: [
      "Individuals with Income from Business or Profession: If you have income from a proprietary business or a profession as a freelancer, consultant, doctor, lawyer, architect, etc., you should file Form ITR-3. This form is suitable for individuals who are engaged in a business or profession and have a more complex tax situation compared to individuals filing ITR-1 or ITR-2.",
      "Individuals with Income from Partnership Firm: If you are a partner in a partnership firm and have income from the firm, you should use Form ITR-3. It is important to report your share of profits from the partnership firm along with any other sources of income you may have.",
      "Individuals with Income from House Property, Capital Gains, and Other Sources: In addition to income from business or profession, if you have income from house property, capital gains (such as sale of stocks, mutual funds, real estate, etc.), or any other sources not covered in the above categories, you can still file Form ITR-3. This form allows you to report income from multiple sources.",
    ],
  },
  {
    title: "How to file ITR 3 Form?",
    subtitle: ["ITR 3 Form can be filed either offline or online:"],
    points: [
      "1. Offline: The returns can be furnished in a paper format through a bar-coded return.",
      "2. Online: The returns can be furnished electronically using the Digital Signature Certificate. This data can be transmitted after submitting the verification of the return.",
    ],
    footer: [
      "The assessee must have two print copies of the ITR 3 Form when he is filing the returns online.",
    ],
  },
  {
    subtitle: "Electronic returns can be filed when:",
    points: [
      "The assessee is a resident of India or if the signing authority is located outside India.",
      "The assessees with more than Rs.5 lakh total income are required to furnish the returns electronically.",
      "Assessees that are claiming relief under Section 90, 90A,91 for whom the schedule FSI and Schedule TR that applies must file the returns electronically.",
    ],
  },
  {
    title: "Major Changes made to the ITR 3 Form for the AY 2021-2022",
    subtitle: ["The Changes that are incorporated in the ITR 3 Form are:"],
    points: [
      "The recipient of the dividend will be liable to pay taxes from 1st April 2020. Relevant Sections such as Section 10(34), 10(35), 115-O are amended in the Act and accordingly, suitable changes are incorporated in the ITR Form.",
      "In case if the dividend is not received the taxpayers are given relief payment of advance tax liability. So the ITR Form allows the taxpayers to enter the details of the dividend income every quarter so that the interest under Section 234C can be computed for default in the payment of advance tax.",
      "With the amendments in the Finance Bill 2021 in section 44AB the threshold limit of the tax audit is increased from Rs. 5 crores to Rs.10 crores if the cash payments are less than 5 percent of the total amount of sales or turnover. The corresponding amendment is incorporated in the ITR Form.",
      "The Schedule DI inserted for the AY 2020-2021 to claim the deduction for the investments or expenditures that are made in the extended period ( 1st April 2020 to June 30th, 2020) is removed in the ITR Form for the AY 2021- 2022.",
      "Schedule 112A and Schedule 115AD (1) (b) (iii) proviso are changed with an additional column to mention the details of the nature of the securities that are transferred for the resultant capital gains tax under the Section 112A or Section 115 AD (1) (b) ( iii) of the Income Tax Act. The schedules are also modified so that the taxpayers can provide information for the sale price, fair market value, and the cost of acquisition of the security.",
      "The Part A of the ITR 3 Form has general information that is modified where the taxpayer is given to choose the best alternative option of the new tax regime under Section 115 BAC.",
      "The assessee that earns income from business or profession and opting for an alternative tax regime is needed to mention the date of filing the Form 10-IE and the acknowledgment number.",
    ],
  },
  {
    title: "Documents Required For ITR-3 Return Filing",
    points: [
      "1. Form 16 / Salary Slips (in case of income from salary)",
      "2. Rental Agreement, if any",
      "3. Proof of Rental Income if any",
    ],
  },
  {
    title: "ITR-3 Return Filing FAQ's",
    subtitle: ["Is the balance sheet mandatory for ITR 3 Filing?"],
    desc: [
      "The list of documents necessary for balance ITR 3 filing are: Balance sheet and profit and loss statement, bank account statement, and the passbook.",
    ],
  },
  {
    subtitle:
      "Is ITR 3 applicable for all the assesses that have business income?",
    desc: [
      "No, ITR 3 will be applicable for all the individuals or the HUF that have business a professional income not chargeable under the presumptive taxation scheme.",
    ],
  },
  {
    subtitle: "Can we file an ITR 3 without an audit?",
    desc: [
      "Yes, ITR 3 filing without auditing and paying the taxes on the intraday turnover.",
    ],
  },
  {
    subtitle: "What is the difference between ITR 3 and ITR 4?",
    desc: [
      "Income Tax Return Form 3 is for all the individuals and HUFs with income under the head profits or gains from business or profession and who are not eligible to file ITR 4 should file ITR 3.",
    ],
  },
  {
    subtitle: "Is it possible to shift from ITR 3 to ITR 4?",
    desc: [
      "Switching from ITR 3 to ITR 4 is not possible unless the sales are declared in 44AD. Also, if the individual gets income from two residential houses he needs to choose ITR3.",
    ],
  },
  {
    subtitle:
      "Is ITR 3 Applicable for individuals under the presumptive taxation scheme?",
    desc: [
      "No in case the individual has opted for a presumptive taxation scheme ITR 3 is not applicable for him. He can file ITR4 instead.",
    ],
  },
];
const dataIRT4 = [
  {
    pagename: "ITR-4 Return",
  },
  {
    title: "ITR 4 Form Filing",
    desc: [
      "Form ITR 4 is filed by the taxpayers who have opted for the Presumptive Taxation Scheme under Section 44D, 44DA, 44AE of the Income Tax Act,1961. But this is subject to the business turnover limit i.e in case if the turnover is exceeding Rs.2 crore then the taxpayer is required to file ITR 3 Form.",
    ],
  },
  {
    title: "What is Presumptive Taxation in Scheme?",
    desc: [
      "Presumptive Taxation Scheme is a scheme that exempts small taxpayers from maintaining the books of accounts.",
    ],
  },
  {
    title: "Who is Eligible to file Form ITR 4?",
    subtitle: [
      "Form ITR-4, also known as Sugam, is specifically designed for individuals and Hindu Undivided Families (HUFs) who have opted for the presumptive taxation scheme under sections 44AD, 44ADA, or 44AE of the Income Tax Act. Here are the individuals who are eligible to file Form ITR-4:",
    ],
    desc: [
      "Individuals with Income from Business: If you are an individual running a business (excluding profession) and have opted for the presumptive taxation scheme under section 44AD, you can file Form ITR-4. This includes businesses such as small traders, retailers, manufacturers, etc., with a turnover of up to Rs. 2 crores.",
      "Professionals under Presumptive Taxation: If you are a professional, such as a doctor, lawyer, architect, engineer, etc., and have opted for the presumptive taxation scheme under section 44ADA, you can use Form ITR-4. This applies to professionals with gross receipts of up to Rs. 50 lakhs.",
      "Income from Goods Carriage: If you are an individual who owns and operates goods carriages and has opted for the presumptive taxation scheme under section 44AE, you can file Form ITR-4. This is applicable for individuals engaged in the business of plying, hiring, or leasing goods carriages.",
    ],
  },
  {
    title: "Who is not eligible for ITR 4 Form?",
    subtitle:
      "Certain individuals or entities are not eligible to file Form ITR-4. Here are some cases where individuals or entities are not eligible for Form ITR-4:",
    desc: [
      "Companies and Limited Liability Partnerships (LLPs): Form ITR-4 is specifically designed for individuals and Hindu Undivided Families (HUFs) who have opted for the presumptive taxation scheme. Companies and LLPs are not eligible to file Form ITR-4 as they have different tax filing requirements.",
      "Individuals with Income from Sources Other than Business, Profession, or Goods Carriage: If you have income from sources other than business, profession, or goods carriage, such as income from salary, capital gains, house property, or other sources, you are not eligible to file Form ITR-4. In such cases, you would need to choose the appropriate form that corresponds to your specific income sources.",
      "Individuals with Turnover or Gross Receipts above the Prescribed Limit: Form ITR-4 is applicable for individuals and HUFs who have opted for the presumptive taxation scheme and meet the turnover or gross receipts criteria specified under sections 44AD, 44ADA, or 44AE. If your turnover or gross receipts exceed the prescribed limit (Rs. 2 crores for business, Rs. 50 lakhs for professionals), you would not be eligible to file Form ITR-4.",
      "Individuals with Income from Specified Professions: While Form ITR-4 is suitable for professionals who have opted for the presumptive taxation scheme under section 44ADA, it is not applicable to professionals engaged in specified professions such as legal, medical, engineering, architecture, accounting, technical consultancy, etc., who are already covered under the section 44AA(1) and need to maintain books of accounts. Such professionals would need to use the appropriate form based on their specific income and profession.",
    ],
  },
  {
    title: "Components of ITR 4 Form",
    desc: [
      "The ITR-4 Form, also known as Sugam, is divided into different sections and schedules to capture various aspects of income and deductions. The components of the ITR-4 Form for the Assessment Year 2021-2022 (as of my knowledge cutoff in September 2021) include:",
    ],
  },
  {
    subtitle: "Part A: General Information:",
    desc: [
      "Personal information of the taxpayer, such as name, address, PAN, Aadhaar number (if applicable), etc.",
    ],
    footer: ["Filing status and residential status."],
  },
  {
    subtitle: "Part B: Gross Total Income:",
    desc: [
      "Income from business or profession under the presumptive taxation scheme (Section 44AD, 44ADA, or 44AE).",
    ],
  },
  {
    subtitle: "Income from other sources.",
    desc: [
      "Deductions under Chapter VI-A (e.g., Section 80C, 80D, 80G, etc.).",
    ],
  },
  {
    subtitle: "Part C: Deductions and Total Taxable Income:",
    desc: [
      "Calculation of total income after deducting the applicable deductions.",
    ],
  },
  {
    subtitle: "Part D: Computation of Tax Payable:",
    desc: ["Calculation of tax liability based on the total taxable income."],
  },
  {
    subtitle: "Part E: Other Information:",
    desc: ["Details of advance tax and self-assessment tax paid."],
  },
  {
    subtitle: "Schedule BP: Business Income:",
    desc: [
      "Calculation of business income including details of turnover, gross receipts, expenses, etc.",
    ],
  },
  {
    subtitle: "Schedule DPM: Depreciation:",
    desc: [
      "Calculation of depreciation on assets used for business or profession.",
    ],
  },
  {
    subtitle: "Schedule DOA: Other Assets:",
    desc: [
      "Details of other assets (excluding land, building, and furniture) used for business or profession.",
    ],
  },
  {
    subtitle: "Schedule DEP: Additional Depreciation:",
    desc: ["Details of additional depreciation claimed, if applicable."],
  },
  {
    subtitle: "Schedule DCG: Capital Gains:",
    desc: ["Calculation of capital gains, if any, from the sale of assets."],
  },
  {
    subtitle: "Schedule 80G: Deductions under Section 80G:",
    desc: [
      "Details of donations made eligible for deduction under Section 80G.",
    ],
  },
  {
    subtitle: "Schedule 80GGA: Deductions under Section 80GGA:",
    desc: [
      "Details of donations made for scientific research or rural development eligible for deduction under Section 80GGA.",
    ],
  },
  {
    subtitle: "Schedule DI: Details of Investment:",
    desc: [
      "Details of investments made during the financial year eligible for deduction.",
    ],
  },
  {
    subtitle: "Schedule 10AA: Deductions under Section 10AA:",
    desc: ["Deductions for units established in Special Economic Zones."],
  },
  {
    subtitle: "Schedule 80RRB: Deductions under Section 80RRB:",
    desc: [
      "Deductions for royalty income of authors of certain specified categories of books.",
    ],
  },
  {
    subtitle: "Schedule FSI: Statement of Financial Specifications:",
    desc: ["Disclosure of financial assets and liabilities."],
  },
  {
    title: "Presumptive Taxation Scheme",
    subtitle: "What are the features of the Presumptive Taxation Scheme?",
    desc: [
      "The Presumptive Taxation Scheme is a simplified method of calculating and reporting income for small businesses and professionals. It offers certain benefits and features that aim to reduce the compliance burden for taxpayers. Here are some key features of the Presumptive Taxation Scheme:",
    ],
    points: [
      "1. Applicable to Small Businesses and Professionals: The scheme is applicable to small businesses with turnover or gross receipts up to Rs. 2 crores (for Section 44AD) and professionals with gross receipts up to Rs. 50 lakhs (for Section 44ADA).",
      "2. Presumptive Income Calculation: Under the scheme, the income is presumed to be a certain percentage of the total turnover or gross receipts. For businesses, the presumptive income is calculated at 8% (6% for non-cash transactions) of the turnover. For professionals, the presumptive income is calculated at 50% of the gross receipts.",
      "3. Simplified Record Keeping: Taxpayers opting for the presumptive taxation scheme are not required to maintain detailed books of accounts. They can maintain a simplified record of income and expenses.",
      "4. No Need for Audit: Businesses and professionals under the presumptive taxation scheme are exempt from mandatory tax audits under Section 44AB. However, if the income is below the taxable limit and the taxpayer wants to claim lower profits or gains, a tax audit may be required.",
      "5. Easy Tax Compliance: The scheme simplifies tax compliance by reducing the documentation and reporting requirements. Taxpayers can file their tax returns using simplified forms such as ITR-4.",
      "6. Advance Tax Payment: Taxpayers under the presumptive taxation scheme are not required to pay advance tax in multiple installments. They can pay the entire tax liability in one installment by March 15th of the financial year.",
      "7. Limited Deductions: Deductions under various sections of the Income Tax Act are not allowed for income calculated under the presumptive taxation scheme. Taxpayers are presumed to have claimed all eligible deductions.",
    ],
  },
  {
    table: (
      <div>
        <h3 className="banner-heading after-line mb-3">
          Presumptive Taxation Scheme
        </h3>
        <div className="table">
          <table className="table table-bordered">
            <tbody>
              <tr>
                <th>Small businessmen</th>
                <th>Professionals</th>
                <th>Transporters</th>
                <td></td>
              </tr>
              <tr>
                <th>Applicable Income Tax Section</th>
                <th>Section 44AD</th>
                <th>Section44ADA</th>
                <th>Section44AE</th>
              </tr>
              <tr>
                <th>Section44AE</th>
                <td>
                  The taxpayer may be in any wholesaling, retailing, trading,
                  civil construction, or any other business
                </td>
                <td className="px-0">
                  <p className="mb-1 border-bottom px-2">Legal services</p>
                  <p className="mb-1 border-bottom px-2">
                    Technical consultancy
                  </p>
                  <p className="mb-1 border-bottom px-2">interior decoration</p>
                  <p className="mb-1 border-bottom px-2">
                    Engineering and architectural
                  </p>
                  <p className="mb-0 px-2">Medical</p>
                </td>
                <td>
                  Eligible business The taxpayer may be in any wholesaling,
                  retailing, trading, civil construction, or any other business
                  Legal services Entities of business involved in hiring,
                  plying, or leasing of goods carriages
                </td>
              </tr>
              <tr>
                <th>Maximum turnover limit</th>
                <td>Up to Rs 2 crore in a year</td>
                <td>Annual receipts of not more than Rs.50 lakh.</td>
                <td>Owning not more than 10 goods vehicles during the year.</td>
              </tr>
              <tr>
                <th>Computation</th>
                <td>
                  8% of total receipts and electronic receipts shall be charged
                  at 6% of gross turnover during the year.
                </td>
                <td>
                  50% of gross receipts. A higher income of more than 50% can be
                  declared
                </td>
                <td>
                  ? 7,500 per vehicle per month or part thereof based on the
                  duration for which the vehicle was owned by the person during
                  the year
                </td>
              </tr>
              <tr>
                <th>Deductions allowed</th>
                <td>No further deductions and exemptions are allowed</td>
                <td>No further deductions and exemptions are allowed</td>
                <td>
                  No further deductions and exemptions are allowed (A
                  partnership can claim deduction and interest to the partners
                  from the computed income at RS. 7500 vehicle per month)
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    ),
  },
  {
    title: "How to file ITR 4 Form?",
    desc: ["ITR-4 can be submitted both online and offline as well."],
  },
  {
    title: "Offline",
    subtitle: "The following individuals can file offline form:",
    points: [
      "⦁	Individuals at the age of 80 years or more",
      "⦁	The individual's income is less than Rs.5 Lakh and he does not have to claim a refund in the income tax return",
    ],
  },
  {
    subtitle: "ITR 4 can be filed offline :",
    desc: [
      "⦁	By furnishing a return in a physical paper form",
      "⦁	By furnishing a bar-coded return (An acknowledgment will be issued at the time of submission of the physical paper return)",
    ],
  },
  {
    title: "Online/Electronically",
    points: [
      "⦁	By furnishing the return digitally using the digital signature.",
      "⦁	By transmitting the data electronically and then submitting the verification of the return made in Form ITR-V.",
    ],
    footer: [
      "If the ITR-4 Form is under digital signature then an acknowledgment will be sent to the registered email id.",
      "Major Amendments made to the ITR 4 Form in AY 2021 - 2022",
    ],
  },
  {
    subtitle: ["The Changes that are incorporated in the ITR 3 Form are:"],
    desc: [
      "There are no major changes made in the ITR 4 Form in comparison to the changes that were made last year.",
    ],
    points: [
      "ITR 4 Form for the AY 2021-2022 has been updated with a declaration to choose between the old and the new tax regimes. This declaration is under Part A general information as  Are you opting for new tax regime under section 115 BAC where the tax taxpayer has to choose between 'Yes or No'. If in case it is Yes, the furnishing date of the Form 101E along with the acknowledgment number has to be filed.",
      "Part B - Under the Income from other sources a drop down like the interest from the saving account, deposit, etc is to be provided in the efiling utility along with specifying the nature of the income. In the case of the dividend income, the quarterly breakup has to be provided for allowing the applicable relief from the charge of the interest for default in the payment of advance tax under section 234C.",
    ],
    footer: [
      "Schedule DI that was inserted for AY 2020 - 2021 has been removed.",
    ],
  },
  {
    title: "Documents Required For ITR-4 Return Filing",
    points: [
      "1. Bank Statement",
      "2. PAN Card",
      "3. PAN card details of the individual.",
      "4. Aadhar Card",
      "5. Aadhaar card of the authorized signator",
    ],
  },
  {
    title: "ITR-4 Return Filing FAQ's",
    subtitle: "Is the balance sheet mandatory for ITR 4 Filing?",
    desc: [
      "In the case of ITR 4, it is not necessary to disclose the particulars of the balance sheet.",
    ],
  },
  {
    subtitle: "What is cash in hand for ITR 4?",
    desc: [
      "It is not necessary to disclose the personal assets in ITR4. Only the assets that are held to conduct the business are to be shown in ITR 4. You can show Nil (Zero) value concerning sundry creditors, inventors, and Cash in Hand. There will be no error on the validation and the returns can be filed.",
    ],
  },
  {
    subtitle: "Is it possible to convert ITR 1 to ITR 4?",
    desc: ["Yes, Form ITR 1 can be converted to ITR 4."],
  },
  {
    subtitle: "What is inventory in ITR 4?",
    desc: [
      "Inventories are the assets that include the finished goods, work in progress, and the raw materials that are held by a company for sales in the future. It also includes the goods are purchased that are held for resale and maintenance supplies, and the consumables used for the production process.",
    ],
  },
  {
    subtitle: "Is it possible to shift from ITR 3 to ITR 4?",
    desc: [
      "Switching from ITR 3 to ITR 4 cannot be done unless the sales are declared.",
    ],
  },
  {
    subtitle: "Can Doctors file ITR 4?",
    desc: [
      "Yes, doctors can file ITR 4 after opting for the presumptive scheme and still declare the profits higher than 50% of receipts. But if the receipts are under Rs.50 lakhs and the expenses are lower than 50% of the receipts then a significant amount is saved by opting for the scheme.",
    ],
  },
];
const dataIRT5 = [
  {
    pagename: "ITR-5 Return",
  },
  {
    title: "ITR 5 Form Filing",
    desc: [
      "The taxpayers are supposed to file the Income Tax returns based on the income that is earned by them in the financial year and the kind of entity that it falls under. ITR 5 Form filing is to be done by the Association of Persons, Limited Liability Partnerships, Body of Individuals, Estates of the deceased, Artificial judicial person, business trust, estates of the insolvent, business trust, and investment fund.",
    ],
    footer: [
      "This article is a comprehensive guide you can refer to while filing the Income Tax returns in Form 5.",
    ],
  },
  {
    title: "Who is eligible to file ITR 5 Form?",
    subtitle:
      "Form ITR-5 is applicable to entities other than individuals, Hindu Undivided Families (HUFs), companies, and persons filing Form ITR-7. It is designed for taxpayers who fall under the following categories:",
    points: [
      "1. Partnership Firms: Partnership firms, including limited liability partnerships (LLPs), can file their income tax returns using Form ITR-5.",
      "2. Association of Persons (AOPs): AOPs, also known as unincorporated associations, are eligible to file their tax returns using Form ITR-5. This includes bodies of individuals (BOIs) and other similar entities.",
      "3. Body of Individuals (BOIs): BOIs that are not treated as individuals under the Income Tax Act can use Form ITR-5 for filing their tax returns.",
      "4. Estate of Deceased Persons: If you are filing the tax return on behalf of the estate of a deceased individual, Form ITR-5 is applicable.",
      "5. Trusts: Irrevocable trusts and other types of trusts, except charitable/religious trusts eligible to file Form ITR-7, can use Form ITR-5 for tax filing.",
      "6. Limited Liability Partnerships (LLPs): LLPs that do not need to file the tax return under the presumptive taxation scheme specified under Section 44ADA can file their returns using Form ITR-5.",
    ],
  },
  {
    title: "Who is not eligible to file Form ITR 5?",
    subtitle:
      "While Form ITR-5 is applicable to a wide range of entities, there are certain entities that are not eligible to file this form. Here are some entities that are not eligible to file Form ITR-5:",
    points: [
      "1. Individuals: Form ITR-5 is not applicable to individual taxpayers. Individuals should file their income tax returns using the appropriate form based on their income sources and residential status, such as Form ITR-1 (Sahaj), ITR-2, ITR-3, ITR-4, etc.",
      "2. Hindu Undivided Families (HUFs): HUFs should file their income tax returns using the appropriate form designated for HUFs, such as Form ITR-2 or ITR-3, depending on the nature of income and their eligibility for the presumptive taxation scheme.",
      "3. Companies: Companies, including private limited companies, public limited companies, and other corporate entities, are not eligible to file Form ITR-5. Companies should file their income tax returns using Form ITR-6 or ITR-7, depending on their specific category.",
      "4. Individuals/Entities filing Form ITR-7: Form ITR-5 is not applicable to individuals or entities that are required to file their returns under Form ITR-7. Form ITR-7 is specifically designed for entities such as charitable trusts, political parties, institutions, and entities with income derived from property held under trust or legal obligation.",
    ],
  },
  {
    subtitle: "Instructions to be followed while ITR 5 Form",
    points: [
      "⦁	If the schedule is not applicable, Not Applicable has to be mentioned.",
      "⦁	If the item is inappropriate write “NA” against the item.",
      "⦁	The “Nil” stands for nil figures which identify the figures of zero value.",
      "⦁	For a negative figure denote it with " -
        " before the figure other than which is provided in the Form.",
      "⦁	All the figures are denoted in the round-off manner to the nearest one rupee.",
      "⦁	Similarly, the figures for the total income and the payable tax should be rounded off to the nearest multiple of ten rupees.",
    ],
  },
  {
    title: "Procedure for filing ITR 5 Form",
    subtitle: "How to File ITR 5 Form?",
    desc: [
      "The ITR 5 Form can be filed with the Income Tax Department either online or offline.",
    ],
    points: [
      "Offline Filing: The Form can be filed offline by furnishing the return in a paper form or by furnishing the bar-coded return. When the return is filed on paper an acknowledgment slip is paired along with the return form that is to be filed properly.",
      "Online Filing: ITR 5 Form can be filed online by furnishing the return electronically using the Digital Signature Certificate.",
      "Or",
      "By transmitting the data in the returns electronically followed by the submission of the return verification in the ITR V Form.",
      "While online filing the assessee is needed to print the copies of the ITR V Form. One copy of the ITR V must be duly signed by the assessee needs to be sent to",
      "Post Bag No. 1,",
      "Electronic City Office,",
      "Bengaluru–560100,",
      "(Karnataka). via ordinary post.",
    ],
  },
  {
    points: [
      "The other copy should be kept by the assessee with himself as a record.",
    ],
    footer: [
      "A firm should mandatorily furnish the return electronically under the digital signature of whose accounts are liable to audit under Section 4AB.",
    ],
  },
  {
    subtitle: "Is ITR 5 Form an annexure-less form?",
    desc: [
      "ITR 5 Form is an annexure-less form and it should be attached with the return form while filing ITR 5. All the documents that are enclosed with this Form will be detached and returned to the person that is filing the returns. The taxpayers are advised to match the taxes that are deducted/ collected /paid by or on behalf of them with their Tax Credit Statement Form 26AS.",
    ],
  },
  {
    subtitle: "How to fill the verification document?",
    points: [
      "1. Fill up the required information in the verification document.",
    ],
    footer: [
      "It is necessary to strike out whatever is not applicable. Please ensure that the verification has been signed before furnishing the return.",
    ],
  },
  {
    points: [
      "2. Choose the designation or the capacity of the person who is signing the return.",
    ],
    footer: [
      "Any person making a false statement in the return or the accompanying schedules will be liable to be prosecuted under Section 277 of the Income Tax Act,1961, and on Conviction, it is punishable under that section with rigorous imprisonment and with fine.",
    ],
  },
  {
    table: (
      <div>
        <h3 className="banner-heading after-line mb-3">
          Structure of ITR 5 Form
        </h3>
        <div className="table">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Part/Schedule</th>
                <th>Explanation</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Part A-GEN</td>
                <td>General information</td>
              </tr>
              <tr>
                <td>Part A-BS</td>
                <td>Balance sheet as on the last day of the previous year</td>
              </tr>
              <tr>
                <td>Part A – Manufacturing Account</td>
                <td>Manufacturing Account for the previous financial year</td>
              </tr>
              <tr>
                <td>Part A – Trading Account</td>
                <td>Trading Account for the previous financial year</td>
              </tr>
              <tr>
                <td>Part A – P&L</td>
                <td>Profit and Loss for the previous financial year</td>
              </tr>
              <tr>
                <td>Part A - OI</td>
                <td>
                  Other Information (this is optional in case the assessee is
                  not liable for audit under Section 44AB)
                </td>
              </tr>
              <tr>
                <td>Part A - QD</td>
                <td>
                  Quantitative Details (this is optional in case the assessee is
                  not liable for audit under Section 44AB)
                </td>
              </tr>
              <tr>
                <td>Schedule- HP</td>
                <td>Income computation under the head House Property</td>
              </tr>
              <tr>
                <td>Schedule - BP</td>
                <td>
                  Income computation under the head – Business or Profession
                </td>
              </tr>
              <tr>
                <td>Schedule DPM</td>
                <td>
                  Computing depreciation on Plant & Machinery under the Income
                  Tax Act
                </td>
              </tr>
              <tr>
                <td>Schedule DOA</td>
                <td>
                  Computation of Depreciation on other assets as per the Income
                  Tax Act
                </td>
              </tr>
              <tr>
                <td>Schedule DEP</td>
                <td>
                  Summary of depreciation on all assets as per the Income Tax
                  Act
                </td>
              </tr>
              <tr>
                <td>Schedule DCG</td>
                <td>
                  A computing of deemed capital gains on the sale of depreciable
                  assets
                </td>
              </tr>
              <tr>
                <td>Schedule ESR</td>
                <td>Making deduction under Section 35</td>
              </tr>
              <tr>
                <td>Schedule- CG</td>
                <td>Computing income under the head Capital Gains</td>
              </tr>
              <tr>
                <td>Schedule- OS</td>
                <td>
                  Computing income under the heading Income from other sources
                </td>
              </tr>
              <tr>
                <td>Schedule CYLA</td>
                <td>
                  Income statement after setting-off losses for the current year
                </td>
              </tr>
              <tr>
                <td>Schedule BFLA</td>
                <td>
                  Income statement after setting off the unabsorbed losses of
                  the previous year(s)
                </td>
              </tr>
              <tr>
                <td>Schedule CFL</td>
                <td>
                  Statement of losses which is to be carried forward to the
                  future years
                </td>
              </tr>
              <tr>
                <td>Schedule UD</td>
                <td>Statement regarding unabsorbed depreciation</td>
              </tr>
              <tr>
                <td>Schedule ICDS</td>
                <td></td>
              </tr>
              <tr>
                <td>Schedule 10AA</td>
                <td>Computing the deduction under Section 10AA</td>
              </tr>
              <tr>
                <td>Schedule 80G</td>
                <td>
                  Statement about donations which are entitled to deduction
                  under Section 80G
                </td>
              </tr>
              <tr>
                <td>Schedule RA</td>
                <td>
                  Statement of donations made to research associations etc.
                  which are entitled to deduction under Sections 35(1)(ii),
                  35(1)(iia), 35(1)(iii) or 35(2AA)
                </td>
              </tr>
              <tr>
                <td>Schedule 80IA</td>
                <td>Computing deduction to be made under Section 80IA</td>
              </tr>
              <tr>
                <td>Schedule 80IB</td>
                <td>Computing deduction under Section 80IB</td>
              </tr>
              <tr>
                <td>Schedule 80IC/80IE</td>
                <td>Computing deduction under Section 80IC/80IE</td>
              </tr>
              <tr>
                <td>Schedule 80P</td>
                <td>Deductions under Section 80P</td>
              </tr>
              <tr>
                <td>Schedule VIA</td>
                <td>Deductions statement under Chapter VIA</td>
              </tr>
              <tr>
                <td>Schedule AMT</td>
                <td>
                  Computing Alternate Minimum Tax under Section 115JC of the
                  Income Tax Act
                </td>
              </tr>
              <tr>
                <td>Schedule AMTC</td>
                <td>Calculation of tax credit under Section 115JD</td>
              </tr>
              <tr>
                <td>Schedule SPI</td>
                <td>
                  Statement of income that arises to minor child/spouse/son’s
                  wife or any other person or AOP that is to be included in the
                  income of the assessee in Schedules HP, CG, OS
                </td>
              </tr>
              <tr>
                <td>Schedule SI</td>
                <td>
                  Statement of income which is subject to chargeability at
                  special tax rates
                </td>
              </tr>
              <tr>
                <td>Schedule IF</td>
                <td>
                  Details of partnership firms in which assessee is a partner
                </td>
              </tr>
              <tr>
                <td>Schedule EI</td>
                <td>Exempt Income Details</td>
              </tr>
              <tr>
                <td>Schedule PTI</td>
                <td>
                  Details of pass-through income from an investment fund or
                  business trust under Section 115UA, 115UB
                </td>
              </tr>
              <tr>
                <td>Schedule FSI</td>
                <td>Details of income that accrues or arises out of India</td>
              </tr>
              <tr>
                <td>Schedule TR</td>
                <td>Details of any taxes that have been paid outside India</td>
              </tr>
              <tr>
                <td>Schedule FA</td>
                <td>
                  Details of any Foreign assets or income from a source outside
                  India
                </td>
              </tr>
              <tr>
                <td>Schedule GST</td>
                <td>Details of turnover/gross receipts reported for GST</td>
              </tr>
              <tr>
                <td>Part B-TI</td>
                <td>
                  Summary of total income and tax computation based on the
                  income that is chargeable to tax
                </td>
              </tr>
              <tr>
                <td>Part B – TTI</td>
                <td>Computing the tax liability on total income</td>
              </tr>
              <tr>
                <td>Tax Payments</td>
                <td>
                  Advance Tax, Tax Deducted at Source and Self-assessment tax
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    ),
  },
  {
    subtitle: "E filing Audit Reports",
    desc: [
      "It is mandatory for the assessee to furnish the audit report under Sections 10 (23C) (v), 10 (23C) (vi), 10 (23C) (via), 10A, 10AA, 12 A (1) (b), 44AB, 44DA, 50B, 80 IA, 80 IB, 80 IC, 80 ID, 80 JJ AA, 80 LA, 92 E, 115 JB, or 115VW, electronically or before the date of filing the income tax returns.",
    ],
  },
  {
    subtitle: "The due date for filing ITR 5 Form for the AY 2021 - 2022",
    desc: [
      "The due date for Filing ITR 5 Form for the AY 2021 - 2022 for Non- Audit Case is 31st July 2021 and 31st October 2021 for Audit cases.",
    ],
  },
  {
    subtitle:
      "Amendments made in the ITR V Form for the financial year 2021-2022",
    points: [
      "⦁	Details of recognition as a start-up by DPIIT",
      "⦁	Declaration Details filed in Form-2",
      "⦁	Information about the partnership firms where an individual is a partner",
      "⦁	Bifurcation of donations into cash and non-cash mode of payment.",
      "⦁	Information on turnover and the gross receipts reported for GST.",
    ],
  },
  {
    subtitle: "Documents Required For ITR-5 Return Filing",
    points: [
      "1. Monthly Purchase Bill",
      "2. Monthly Sales Bill",
      "3. Sales Return Details",
    ],
  },
  {
    title: "ITR-5 Return Filing FAQ's",
    subtitle: "Is the balance sheet mandatory for ITR 5 Filing?",
    desc: [
      "The reported figures of the balance sheet should match with the audited balance sheet in case the account was required to be audited. Any other activity that is not being accounted for in the book of accounts need not be included in the balance sheet.",
    ],
  },
  {
    subtitle: "What is ITR 5?",
    desc: [
      "ITR 5 Form is for firms LLPs, AOPs, BOIs, AJPs, estates of deceased, the estate of insolvent, business trust, and the investment fund.",
    ],
  },
  {
    subtitle: "How to file ITR 5 online?",
    desc: [
      "For the online filing of ITR 5, the assessee needs to print two copies of the ITR V form. One copy of the ITR V should be duly signed by the assessee and has to be sent to Post Bag No.1, Electronic City Office, Bengaluru – 560100 via ordinary post. Another copy should be kept with the assessee himself as a record.",
    ],
  },
  {
    subtitle: "Is ITR 5 an annexure less form?",
    desc: [
      "No documents are to attached with the ITR 5 which also includes the TDS certificate. The documents enclosed with this return form will be detached and returned to the person filing the return.",
    ],
  },
];
const dataIRT6 = [
  {
    pagename: "ITR-6 Return",
  },
  {
    title: "ITR 6 Filing Form",
    desc: [
      "ITR 6 Form is to be furnished by the Companies to e-file the income tax returns if they are not claiming exemption under Section 11 of the Income Tax Act 1961. Under the Income-tax Rules, the Companies that can claim an exemption under section 11 are those that have income from the property that is held for the charitable or religious person.",
      "Hence, ITR 6 is to be filed by the companies that do not claim an exemption under section 11.",
    ],
  },
  {
    subtitle: "Efiling audit reports",
    desc: [
      "If in case the assessee is liable for the audit u/s 44AB and the accountant has audited the accounts, then the details of the audit report, the auditor along with the date of furnishing is to be sent to the department electronically.",
    ],
  },
  {
    title: "Who is eligible to file ITR 6 Form?",
    subtitle:
      "Form ITR-6 is designed for companies other than those claiming exemption under section 11 of the Income Tax Act, 1961. Here are the entities that are eligible to file ITR-6:",
    desc: [
      "Companies: All types of companies, including private limited companies, public limited companies, one-person companies (OPCs), and foreign companies, are eligible to file Form ITR-6. This includes companies that are engaged in business activities or are not-for-profit organizations.",
      "Co-operative Societies: Co-operative societies, other than those that claim exemption under section 11 of the Income Tax Act (relating to income from property held for charitable or religious purposes), can file their tax returns using Form ITR-6.",
      "Business Trusts: Real Estate Investment Trusts (REITs) and Infrastructure Investment Trusts (InvITs), which are classified as business trusts, are required to file their tax returns using Form ITR-6.",
    ],
  },
  {
    title: "Who is not eligible to file ITR 6 Form?",
    subtitle:
      "Form ITR-6 is specifically designed for certain types of companies and entities. There are certain entities that are not eligible to file Form ITR-6. Here are some entities that are not eligible to file this form:",
    desc: [
      "Individuals: Individuals, including salaried individuals, self-employed individuals, and freelancers, are not eligible to file Form ITR-6. Individuals should file their income tax returns using the appropriate form based on their income sources and residential status, such as Form ITR-1 (Sahaj), ITR-2, ITR-3, ITR-4, etc.",
      "Hindu Undivided Families (HUFs): HUFs, which are joint families recognized under Hindu law, are not eligible to file Form ITR-6. HUFs should file their income tax returns using the appropriate form designated for HUFs, such as Form ITR-2 or ITR-3, depending on the nature of income and their eligibility for the presumptive taxation scheme.",
      "Individuals/Partnerships: Entities that are structured as partnerships, including general partnerships and limited liability partnerships (LLPs), are not eligible to file Form ITR-6. Partnerships should file their income tax returns using the appropriate form, such as Form ITR-3 or ITR-5, depending on the specific criteria.",
      "Entities filing Form ITR-7: Form ITR-6 is not applicable to entities that are required to file their returns under Form ITR-7. Form ITR-7 is specifically designed for entities such as charitable trusts, political parties, institutions, and entities with income derived from property held under trust or legal obligation.",
    ],
  },
  {
    title: "Structure of ITR 6 Form",
    desc: [
      "ITR 6 is divided into Part A and Part B ( along with the sub-sections) with multiple schedules that include information relevant to the income and tax of the taxpayer. Let us have a detailed look at this.",
      "The ITR-6 form is structured into different sections and schedules to gather relevant information for the filing of income tax returns by companies other than those claiming exemption under section 11 of the Income Tax Act, 1961. The structure of the ITR-6 form typically includes the following components:",
    ],
  },
  {
    subtitle: "Part A: General Information",
    points: [
      "This section collects general information such as the name of the company, PAN (Permanent Account Number), address, and contact details.",
    ],
  },
  {
    subtitle: "Part B: Outline of the Total Income and Tax Computation",
    points: [
      "This section includes information about the company's income, deductions, and tax computation.",
    ],
  },
  {
    subtitle: "Part C: Details of the Company",
    points: [
      "This section gathers detailed information about the company, such as its registration details, shareholders' information, and director's details.",
    ],
  },
  {
    subtitle: "Part D: Computation of Total Income",
    points: [
      "This section involves the computation of the company's total income, including income from various sources and deductions allowed under the Income Tax Act.",
    ],
  },
  {
    subtitle: "Part E: Other Information",
    points: [
      "This section collects additional information such as the details of the company's principal business activity and the nature of transactions with related parties.",
    ],
  },
  {
    subtitle: "Part F: Schedule of Tax Payments",
    points: [
      "This section requires the company to provide details of tax payments made during the financial year.",
    ],
  },
  {
    subtitle: "Part G: Schedule of TDS and TCS",
    points: [
      "This section captures information regarding tax deducted at source (TDS) and tax collected at source (TCS) applicable to the company.",
    ],
  },
  {
    subtitle: "Part H: Details of the Audited Balance Sheet",
    points: [
      "This section requires the company to provide details of the audited balance sheet, including assets, liabilities, and other financial information.",
    ],
  },
  {
    subtitle: "Part HA: Additional Information",
    points: [
      "This section collects any additional information that is relevant to the company's income tax return filing.",
    ],
  },
  {
    subtitle: "Verification",
    points: [
      "The final section includes a declaration and signature of the authorized person, certifying the accuracy and completeness of the information provided.",
    ],
  },
  {
    subtitle: "Annexures and Schedules",
    points: [
      "Depending on the company's specific activities and transactions, there may be additional annexures and schedules that need to be attached to the ITR-6 form. These annexures and schedules provide detailed information supporting the company's income and tax calculations.",
    ],
  },
  {
    table: (
      <div>
        <div className="table">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Schedules</th>
                <th>Purpose</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th></th>
                <td>Bank account details</td>
              </tr>
              <tr>
                <th>Schedule –HP</th>
                <td>
                  For computing incomes under the head Income from House
                  Property
                </td>
              </tr>
              <tr>
                <th>Schedule –BP</th>
                <td>
                  For computing incomes under the head “profit and gains from
                  business and profession
                </td>
              </tr>
              <tr>
                <th>Schedule –DPM</th>
                <td>For calculating depreciation on plant & machinery</td>
              </tr>
              <tr>
                <th>Schedule –DOA</th>
                <td>For a summary of depreciation on all assets</td>
              </tr>
              <tr>
                <th>Schedule –DCG</th>
                <td>
                  For calculating the deemed capital gain on sale of depreciable
                  assets
                </td>
              </tr>
              <tr>
                <th>Schedule –ESR</th>
                <td>
                  Deductions u/s 35, i.e. expenditure on scientific research
                </td>
              </tr>
              <tr>
                <th>Schedule –CG</th>
                <td>For calculating income under the head “Capital gains”</td>
              </tr>
              <tr>
                <th>Schedule –OS</th>
                <td>For calculating income from other sources</td>
              </tr>
              <tr>
                <th>Schedule –CYLA</th>
                <td>
                  Calculating the income after setting off the current year's
                  loss
                </td>
              </tr>
              <tr>
                <th>Schedule –BFLA</th>
                <td>
                  Calculation of income after setting off the unabsorbed loss of
                  the previous year
                </td>
              </tr>
              <tr>
                <th>Schedule –CFL</th>
                <td>
                  Statement of loss for carrying forward to a further year
                </td>
              </tr>
              <tr>
                <th>Schedule –UD</th>
                <td>Statements of unabsorbed depreciation and allowance</td>
              </tr>
              <tr>
                <th>Schedule –ICDS</th>
                <td>Income computation disclosure standards on profit</td>
              </tr>
              <tr>
                <th>Schedule –10AA</th>
                <td>Deductions u/s 10AA</td>
              </tr>
              <tr>
                <th>Schedule –80G</th>
                <td>Details of deduction u/s 80G relating to donations</td>
              </tr>
              <tr>
                <th>Schedule –80 GGA</th>
                <td>
                  Statement of donations for scientific research and rural
                  development
                </td>
              </tr>
              <tr>
                <th>Schedule –VIA</th>
                <td>
                  Statement of deductions from total income under chapter VI-A
                </td>
              </tr>
              <tr>
                <th>Schedule –SI</th>
                <td>Details of income chargeable at special tax rates</td>
              </tr>
              <tr>
                <th>Schedule –PTI</th>
                <td>
                  Details relating to passing through income from business trust
                  or investment fund
                </td>
              </tr>
              <tr>
                <th>Schedule –EI</th>
                <td>Statement of exempted incomes</td>
              </tr>
              <tr>
                <th>Schedule –MAT</th>
                <td>
                  Details of tax payable u/s 115JB (Minimum Alternate Tax)
                </td>
              </tr>
              <tr>
                <th>Schedule –DDT</th>
                <td>
                  statement of tax paid on dividend, i.e. “dividend distribution
                  tax”
                </td>
              </tr>
              <tr>
                <th>Schedule –BBS</th>
                <td>Details of tax distributed income on buyback of shares</td>
              </tr>
              <tr>
                <th>Schedule –ESI</th>
                <td>Statement of foreign incomes and tax relief thereto</td>
              </tr>
              <tr>
                <th>Schedule –IT</th>
                <td>Details on advance-tax paid and self-assessment tax</td>
              </tr>
              <tr>
                <th>Schedule –TDS</th>
                <td>Details of TDS on incomes other than salaries</td>
              </tr>
              <tr>
                <th>Schedule –TCS</th>
                <td>Statement of TCS (Tax collected at source)</td>
              </tr>
              <tr>
                <th>Schedule –FSI</th>
                <td>Statement of income accruing outside India</td>
              </tr>
              <tr>
                <th>Schedule –TR</th>
                <td>Details of tax relief claimed for foreign tax paid</td>
              </tr>
              <tr>
                <th>Schedule –FA</th>
                <td>Complete details of foreign assets and foreign income</td>
              </tr>
              <tr>
                <th>Schedule –SH1</th>
                <td>Details of shareholding of an unlisted company</td>
              </tr>
              <tr>
                <th>Schedule –SH2</th>
                <td>Details of shareholding of start-ups</td>
              </tr>
              <tr>
                <th>Schedule –AL1</th>
                <td>
                  Statement of assets and liabilities at the end of the year
                </td>
              </tr>
              <tr>
                <th>Schedule –GST</th>
                <td>Statement of turnover reported for GST</td>
              </tr>
              <tr>
                <th>Schedule –FD</th>
                <td>Details of payment/receipt made in foreign currency</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    ),
  },
  {
    subtitle: "How to file ITR 6 Form?",
    desc: [
      "The Income-tax department advises to fill the Income-tax returns in the following sequence:",
    ],
    points: [
      "⦁	Part A ( and its subsection) All the Schedules",
      "⦁	Part B ( its subsection) Verification",
    ],
    footer: [
      "This income tax return has to be filed online and is to be furnished electronically by affixing the DSC of the assessee. While filing the ITR 6 Form no document is required to be attached with return. ITR 6 Form is an annexure-less form.",
      "The taxpayer is required to tally the data with the tax credit statement Form 26 AS regarding the tax deduction, collection, and payment.",
    ],
  },
  {
    title: "Documents Required For ITR-6 Return Filing",
    points: [
      "1. Monthly Purchase Bill",
      "2. Monthly Sales Bill",
      "3. Sales Return Details",
    ],
  },
  {
    title: "ITR-6 Return Filing FAQ's",
    subtitle: "What is ITR 6 Form?",
    desc: [
      "Companies other than companies that claim an exemption under Section 11 should furnish their income tax return in ITR 6 Form.",
    ],
  },
  {
    subtitle: "Which companies claim an exemption under Section 11?",
    desc: [
      "Companies having income from property that is held for charitable or religious purposes than companies claiming exemption under Section 11.",
    ],
  },
  {
    subtitle: "What is the sequence for filing ITR 6 Form?",
    desc: [
      "ITR 6 should be filed in the following sequence: Part A Schedules Part B Verification",
    ],
  },
  {
    subtitle: "How to file ITR 6 Form?",
    desc: [
      "This ITR 6 form is to be compulsorily furnished electronically using the Digital Signatures with the Income Tax Department.",
    ],
  },
  {
    subtitle: "Are any documents to be attached with ITR 6?",
    desc: [
      "No documents, not even the TDS certificate should be attached while filing ITR 6 returns. The taxpayers are advised to match the deducted taxes on behalf of.",
    ],
  },
];
const dataIRT7 = [
  {
    pagename: "ITR-7 Return",
  },
  {
    title: "ITR 7 Form Filing",
    desc: [
      "ITR 7 Form filing is done by the companies who service the income from the properties that are of charitable or religious purposes. Properties that are held under the trusts or legal obligation in parts or even wholly are included in the category.",
      "ITR taxpayers can fill the ITR 7 Forms by providing the returns through a bard coded form, physical paperback forms, or the digital signature mode, or the submission of return verification through ITR Form V.",
      "ITR 7 Form is a file when the person and the companies fall under section 139(4A) or Section 139 (4B) or Section 139 (4C) or Section 139(4D).",
    ],
  },
  {
    title: "Eligibility for ITR 7 Form filing",
    subtitle: "Who is eligible for ITR 7 Form filing?",
    desc: [
      "Form ITR-7 is specifically designed for entities that are required to file their income tax returns under specific provisions of the Income Tax Act, 1961. The following entities are eligible to file Form ITR-7:",
    ],
    points: [
      "1. Charitable/Religious Trusts: Trusts that are registered as charitable or religious organizations under Section 12A or 12AA of the Income Tax Act can file their income tax returns using Form ITR-7. This includes entities such as public charitable trusts, religious trusts, educational institutions, hospitals, etc.",
      "2. Political Parties: Political parties that are registered under Section 29A of the Representation of the People Act, 1951, and are eligible for tax exemption under Section 13A of the Income Tax Act can file their income tax returns using Form ITR-7.",
      "3. Institutions or Associations: Institutions, associations, or entities that are involved in scientific research, news agencies, educational institutions, hospitals, trade unions, etc., and are not required to file returns under any other income tax return form can file their returns using Form ITR-7.",
      "4. Entities Holding Property for Charitable or Religious Purposes: Entities that hold property or income for charitable or religious purposes and are required to file returns under Section 139(4A) or Section 139(4B) or Section 139(4C) or Section 139(4D) can file their returns using Form ITR-7.",
    ],
  },
  {
    title: "Structure of ITR 7 Form",
    subtitle: "What is the structure of the ITR 7 Form?",
    desc: [
      "The ITR 7 Form has been divided into two parts and nineteen schedules.",
    ],
    points: [
      "⦁	Part A General information",
      "⦁	Part B Outline of the total income and tax computation concerning income chargeable to tax.",
    ],
  },
  {
    table: (
      <div>
        <div className="table">
          <table className="table table-bordered">
            <tbody>
              <tr>
                <th>Schedule-I</th>
                <td>
                  Details of amounts accumulated/ set apart within the meaning
                  of section 11(2) in last year’s viz., previous years relevant
                  to the current assessment year.
                </td>
              </tr>
              <tr>
                <th>Schedule-J</th>
                <td>
                  Statement showing the investment of all funds of the Trust or
                  Institution as on the last day of the previous year.
                </td>
              </tr>
              <tr>
                <th>Schedule-K</th>
                <td>
                  Statement of particulars regarding the Author(s)/ Founder(s)/
                  Trustee(s)/ Manager(s), etc., of the Trust or Institution.
                </td>
              </tr>
              <tr>
                <th>Schedule-LA</th>
                <td>Details in case of a political party.</td>
              </tr>
              <tr>
                <th>Schedule-ET</th>
                <td>Details in case of an Electoral Trust</td>
              </tr>
              <tr>
                <th>Schedule-HP</th>
                <td>
                  Computation of income under the head Income from House
                  Property.
                </td>
              </tr>
              <tr>
                <th>Schedule-CG</th>
                <td>Computation of income under the head ITR.</td>
              </tr>
              <tr>
                <th>Schedule-OS</th>
                <td>
                  Computation of income under the head Income from other
                  sources.
                </td>
              </tr>
              <tr>
                <th>Schedule-VC</th>
                <td>Details of Voluntary Contributions received</td>
              </tr>
              <tr>
                <th>Schedule-OA</th>
                <td>General information about business and profession</td>
              </tr>
              <tr>
                <th>Schedule-BP</th>
                <td>
                  Computation of income under the head “profit and gains from
                  business or profession
                </td>
              </tr>
              <tr>
                <th>Schedule-CYLA</th>
                <td>
                  Statement of income after set off of current year’s losses
                </td>
              </tr>
              <tr>
                <th>Schedule-MAT</th>
                <td>
                  Computation of Minimum Alternate Tax payable under section
                  115JB (n)
                </td>
              </tr>
              <tr>
                <th>Schedule-MATC</th>
                <td>Computation of tax credit under section 115JAA</td>
              </tr>
              <tr>
                <th>Schedule AMT</th>
                <td>
                  Computation of Alternate Minimum Tax payable under section
                  115JC (p)
                </td>
              </tr>
              <tr>
                <th>Schedule AMTC</th>
                <td>Computation of tax credit under section 115JD</td>
              </tr>
              <tr>
                <th>Schedule-SI</th>
                <td>
                  Statement of income which is chargeable to tax at special
                  rates
                </td>
              </tr>
              <tr>
                <th>Schedule-IT</th>
                <td>
                  Statement of payment of advance-tax and tax on
                  self-assessment.
                </td>
              </tr>
              <tr>
                <th>Schedule-TDS</th>
                <td>
                  Statement of tax deducted at source on income other than
                  salary.
                </td>
              </tr>
              <tr>
                <th>Schedule-TCS</th>
                <td>Statement of tax collected at source</td>
              </tr>
              <tr>
                <th>Schedule FSI</th>
                <td>Details of income accruing or arising outside India</td>
              </tr>
              <tr>
                <th>Schedule TR</th>
                <td>Details of Taxes paid outside India</td>
              </tr>
              <tr>
                <th>Schedule FA</th>
                <td>Details of Foreign Assets</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    ),
  },
  {
    title: "Sequence for filing ITR 7 Form",
    desc: [
      "The Income Tax Department has advised the assessees to follow the sequence that is mentioned below while filing the Income Tax return.",
    ],
    points: [
      "⦁	Part A",
      "⦁	Part B",
      "⦁	Schedules",
      "⦁	Verification.",

      "Enter the information required in the verification document and strike out the which is not applicable and this verification must be digitally signed before furnishing the final return.",
      "It is necessary for the person signing to mention his designation in the entity on behalf of whom the return is being filed.",
    ],
  },
  {
    title: "Procedure to file ITR 7 returns",
    subtitle: "How to file ITR 7 returns?",
    desc: [
      "ITR 7 can be filed with the Income Tax Department in the following ways:",
    ],
    points: [
      "⦁	By furnishing the returns in the electronic manner using the digital signature certificate",
      "⦁	By transmitting the data in the return and thereafter submitting the verification of the return in the ITR V Return Form",
      "After filing the return the assessee is required to print the copies of the ITR V Form. A copy of the ITR V which is duly signed by the assessee has to be sent by the ordinary post to",
      "Bag No.1, Electronic City Office, Bengaluru - 560100 ( Karnataka)",
      "The other copy can be retained by the assessee for his record.",
    ],
  },
  {
    subtitle: "How to fill the verification document?",
    points: [
      "The required information is to be filled in the verification document. Strikeout whatever is not applicable. Pleasure ensures that the verification has been signed before the returns are furnished. Choose the designation/ capacity of the person signing the return.",
      "It is to be noted that any person who is making a false statement in return or accompanying the schedules should be liable to be prosecuted under the section which can lead to imprisonment and also a fine.",
    ],
  },
  {
    subtitle: "E filing audit reports",
    points: [
      'If the assessee is liable for auditing u/s 44AB and the accounts have been audited by the accountant then the details of such audit reports along with the furnishing date are to be sent to the income tax department. It is to be under the head "Audit Information"',
    ],
  },
  {
    subtitle: "No annexures required",
    points: [
      "No documents are to be attached with this return while filing ITR 7 Form.",
      "The Taxpayers should match the taxes that are deducted/ collected paid by or on behalf of them with their Tax Credit statement form 26AS.",
    ],
  },
  {
    subtitle: "Instructions to be followed while filing ITR 7 Form",
    points: [
      "1. When filing Form ITR-7, which is specifically designed for entities such as charitable/religious trusts, political parties, and institutions, it is important to follow certain instructions to ensure accurate and compliant filing. Here are some key instructions to be followed while filing ITR-7:",
      "2. Select the Correct Assessment Year: Ensure that you are filing the income tax return for the appropriate Assessment Year (AY) as mentioned in the form.",
      "3. Fill in the Basic Information: Provide accurate details in the form, including the entity's name, PAN (Permanent Account Number), address, and contact information.",
      "4. Verify Taxpayer Type: Confirm that you fall under one of the eligible categories mentioned for filing Form ITR-7, such as charitable/religious trusts, political parties, or institutions.",
      "5. Provide Income Details: Disclose the entity's income from various sources, such as donations, investments, capital gains, rental income, etc., in the appropriate sections of the form.",
      "6. Claim Deductions: If eligible, claim deductions under relevant sections of the Income Tax Act, such as Section 80G for donations, Section 80GGA for donations to scientific research, or Section 80P for cooperative societies.",
      "7. Fill in Schedule A1: Provide details of the entity's activities and information regarding its registration under Section 12A/12AA/10(23C).",
      "8. Fill in Schedule EI: Report income exempted under various sections of the Income Tax Act.",
      "9. Attach Required Documents: Ensure all necessary supporting documents, including audited financial statements, audit reports, and relevant annexures, are attached with the ITR-7 form.",
      "10. Verify and Sign the Form: Verify the information provided in the form for accuracy, and sign the form in the designated space.",
      "11. Submit the Return: File the ITR-7 form electronically using a digital signature, or submit a physical copy to the relevant Income Tax office, if applicable.",
    ],
  },
  {
    title: "Documents Required For ITR-7 Return Filing",
    points: [
      "Statement from Bank",
      "Bank statement of the Business.",
      "Incorporation Certificate",
      "Certificate of incorporation provided by the Foreign Government",
    ],
  },
  {
    title: "ITR-7 Return Filing FAQ's",
    subtitle: "How is verification filed?",
    points: [
      "Fill the complete information on the verification document by striking out what doesn't apply to the entity. Verification must be duly signed before return filing.",
    ],
  },
  {
    subtitle:
      "Does ITR 7 require the furnishing of information about a Tax audit?",
    points: [
      "Yes, in case an entity is required to get its account audited u/s 44AB and its accounts are audited by a chartered accountant, such entity must furnish the details in ITR-7 like details about audit report and date of furnishing such report to the department.",
    ],
  },
  {
    subtitle: "What are the consequences of false statements in return?",
    points: [
      "Any person making false statements in return or schedules is liable for prosecution u/s 277 of Income Tax Act 1961 with imprisonment and fine.",
    ],
  },
  {
    subtitle: "Who cannot file through ITR 7?",
    points: [
      "ITR-7 cannot be filled by the following taxpayers: Individual, Companies, and HUFs Those entities who have filed the return through new ITR-5 and ITR-6",
    ],
  },
  {
    subtitle: "How to download Income tax returns form?",
    points: [
      "Download the Income Tax Return Forms through the following steps: Visit the official website of income tax Income Tax Dalmaf Click on the tab of Downloads on the homepage. A list of forms will be available thereafter. Select the form in an excel sheet or java utility. Continue to fill the downloaded form for filing the return",
    ],
  },
  {
    subtitle:
      "What are the requirements to be fulfilled by the assesse after filing ITR 7?",
    points: [
      "Once the return is filed, the assessee shall get the print of two copies of the ITR-V form, one copy shall be sent through the post to the CPC office and the second copy shall be retained for record.",
    ],
  },
  {
    subtitle: "Who can file ITR 7 Form?",
    points: [
      "ITR-7 must be filed by Companies, Firms, Local authorities, Association of Person (AOP), and Artificial Judiciary Person that are claiming exemption in any of the following categories: Having income from charitable /religious trust- Section 139 (4A) Having income from a political party – Section 139 (4B) Having income from scientific research institutions – Section 139 (4C) Having income from university or colleges or institutions or khadi and village industries – Section 139 (4D)",
    ],
  },
  {
    subtitle: "Who should not file ITR 7 Form?",
    points: [
      "ITR7 cannot be used by a tax assessee who is not claiming exemption under Section 139 (4A), Section 139 (4B), Section 139 (4C), or Section 139 (4D).",
    ],
  },
  {
    subtitle: "When should ITR 7 be filed?",
    points: [
      "ITR-7 form is to be used when the tax assessee is a Trust, filing as a Company, Firm, Local authority, Association of Person (AOP) or Artificial Judiciary Person and is claiming exemption under Section 139 (4A), Section 139 (4B), Section 139 (4C)or Section 139 (4D).",
    ],
  },
  {
    subtitle: "What is the due date for filing ITR 7 Form?",
    points: [
      "The due date for all Tax Assessees, whose accounts are required to be audited, is 30th September. The due date for Tax Assessees, whose accounts are not required to be audited, is 31st July.",
    ],
  },
];
const dataDirectMarketing = [
  {
    pagename: "Direct Marketing",
  },
  {
    title: "Direct Marketing",
    points: [
      "A successful direct marketing program are enable you to reach target individuals in an efficient way.",
      "help you to personalise and customise your messages.",
      "attract new customers and increase their value to your business over time.",
      "support long-term relationships and improve customer satisfaction.",
    ],
    marketingimg: (
      <img
        className="w-100 h-auto"
        src={require("../src/Assets/Images/dm1.jpg")}
        alt=""
      />
    ),
  },
];
const dataIndirectMarketing = [
  {
    pagename: "Indirect Marketing",
  },
  {
    title: "Indirect Marketing",
    points: [
      "indirect marketing revolves more around building awareness around your brand that will lead to more business over time",
      "we'll look at different types of indirect marketing and what each of them entails",
      "Content and social media marketing. SEO and link building. Influencer marketing. Referral and loyalty programs, Reviews. Sponsorships and product placement. A way of selling products and services that are not directly sold to customers, but rather through partnered organizations, like a reseller, agency, or affiliate marketing partner.",
    ],
    marketingimg: (
      <img
        className="w-100 h-auto"
        src={require("../src/Assets/Images/dm11.webp")}
        alt=""
      />
    ),
  },
];
const dataShowroomSalesSupport = [
  {
    pagename: "Showroom sales support",
  },
  {
    title: "Showroom sales support",
    points: [
      "The Showroom Sales Associate increases sales, market share, company profitability and customer satisfaction by working with the customers, contractors, architects, designers (and their clients) in choosing from a wide variety of natural or cultured stone, brick or related building material product. Sales executives help to drive a business forward by selling its products or services to customers.",
    ],
    marketingimg: (
      <img
        className="w-100 h-auto"
        src={require("../src/Assets/Images/dm2.webp")}
        alt=""
      />
    ),
  },
];
const dataProductLaunchSupport = [
  {
    pagename: "Product Launch Support",
  },
  {
    title: "Product Launch Support",
    points: [
      "A product launch is the process a company undertakes to bring its new or updated products to market.",
      "Tips for building a successful product launch include: Focusing on the customer experience.",
      "Using your product data to inform strategy. Creating a product launch Offer loyal customers an exclusive preview. Use a special introductory offer. ...",
      "Running a social media contest. Spread the word via email. Writing a blog post. ...",
      "We can Host an event. By Offering a complimentary upgrade. Making sure the team has the right tools. Offer self-service support options. key support skills. Foster effective problem solving. Make it easy for customers to get support.",
    ],
    marketingimg: (
      <img
        className="w-100 h-auto"
        src={require("../src/Assets/Images/dm3.webp")}
        alt=""
      />
    ),
  },
];
const dataMarketingHampers = [
  {
    pagename: "Marketing Hampers",
  },
  {
    title: "Marketing Hampers",
    points: [
      "Creating different baskets to meet specific customer needs will help you better promote your business, increase sales and generate profits.",
      "Segment the Market. Promote your gift basket business by helping people looking for special-occasion gifts.",
      "Creating a Website. Offer Referral Programs. Supplying Free Samples. Promotional Hampers also known as corporate hampers or gift baskets are a gift that's typically delivered to the home or workplace that includes a number items, usually consumable",
    ],
    marketingimg: (
      <img
        className="w-100 h-auto"
        src={require("../src/Assets/Images/dm4.webp")}
        alt=""
      />
    ),
  },
];
const dataStickerMarketing = [
  {
    pagename: "Sticker Marketing",
  },
  {
    title: "Sticker Marketing",
    points: [
      "Sticker marketing is one of the most cost-effective ways for businesses and brands to captivate the attention of both target and secondary audiences.",
      "Not only are stickers relatively inexpensive to produce, but they are also proven effective for promoting brands, products, and services in a memorable, lasting way.",
      "Sticker marketing play a important role in both offline and online marketing Strategies .",
      "As word of mouth is the most powerful marketing tool there is sticker get people to notice and talk about your brand as such stickers can boost brand awareness Campaign and create a positive buzz for your business service or products",
    ],
    marketingimg: (
      <img
        className="w-100 h-auto"
        src={require("../src/Assets/Images/dm5.webp")}
        alt=""
      />
    ),
  },
];
const dataPanelMarketing = [
  {
    pagename: "Panel Marketing",
  },
  {
    title: "Panel Marketing",
    points: [
      "Transit advertising is advertising placed in or on modes of public transportation or in public transportation areas. Using this method of advertising, ads can be placed anywhere from on the sides of buses, trains and taxis, to inside subway cars, inside bus stations and near train or bus platforms. Shops , Umbrellas ,Iron Shops ,Etc…",
    ],
    marketingimg: (
      <img
        className="w-100 h-auto"
        src={require("../src/Assets/Images/dm7.webp")}
        alt=""
      />
    ),
  },
];
const dataAfterSalesSuppor = [
  {
    pagename: "After Sales Support",
  },
  {
    title: "After Sales Support",
    points: [
      "After-sales service is any support provided to a customer after the product or service has already been purchased.",
      "We use to give after-sales support as a business strategy as it typically leads to higher customer satisfaction, brand loyalty, and even word-of-mouth-marketing Which multiples the Customers",
      "Providing great service during and after a sale shows your customers you want to build a long-term relationship with them, earn their loyalty and keep their business.",
      "Customers remember businesses that provide great customer service, and are more likely to tell others about your business.",
    ],
    marketingimg: (
      <img
        className="w-100 h-auto"
        src={require("../src/Assets/Images/dm6.webp")}
        alt=""
      />
    ),
  },
];
const dataUmbrellaCampaign = [
  {
    pagename: "Umbrella Campaign",
  },
  {
    title: "Umbrella Campaign",
    points: [
      "Umbrella campaigns are used first to establish a brand promise;",
      "reposition a brand;",
      "change consumer perception or build brand awareness;",
      "and secondly as a foundation for individual brand, benefit or product messages to spin off in separate executions or media vehicles.",
    ],
    marketingimg: (
      <img
        className="w-100 h-auto"
        src={require("../src/Assets/Images/dm12.webp")}
        alt=""
      />
    ),
  },
];
const dataKioskMarketing = [
  {
    pagename: "Kiosk Marketing",
  },
  {
    title: "Kiosk Marketing",
    points: [
      "The kiosk is a type of direct marketing techniques that businesses use The purpose is to send the message and increase the market share of a product/servicePerhaps the most appealing reason for using digital kiosks is the uptick in sales that they can help facilitate",
      "Interactive kiosks are remarkably effective marketing tools that can help influence your customers into buying certain products through the use of promotions, packages, discounts, and other enticing offers",
    ],
    marketingimg: (
      <img
        className="w-100 h-auto"
        src={require("../src/Assets/Images/dm8.webp")}
        alt=""
      />
    ),
  },
];
const dataRoadShowMarketing = [
  {
    pagename: "Road Show Marketing",
  },
  {
    title: "Road Show Marketing",
    points: [
      "A roadshow is a series of presentations made in various locations leading up to an initial public offering (IPO).",
      "The roadshow is a sales pitch or promotion made by the underwriting firm and a company's management team to potential investors before going public. A roadshow is a series of presentations made in various locations leading up to an initial public offering (IPO).",
      "The roadshow is a sales pitch or promotion made by the underwriting firm and a company's management team to potential investors before going public.",
      "A product launch roadshow is an activation to introduce clients and potential clients to new a product. This type of roadshow includes demonstrations of the new product and presentations or training on the new product.",
    ],
    marketingimg: (
      <img
        className="w-100 h-auto"
        src={require("../src/Assets/Images/dm9.webp")}
        alt=""
      />
    ),
  },
];
const dataAreaSpotMarketing = [
  {
    pagename: "Area Spot Marketing",
  },
  {
    title: "Area Spot Marketing",
    points: [
      "Area spot marketing is pointing a particular area were the business takes place and surrounding of 2 Kilometres we will mark and market your product at that particular area to improve your sales, Service",
      "",
      "",
      "",
    ],
    marketingimg: (
      <img
        className="w-100 h-auto"
        src={require("../src/Assets/Images/dm10.webp")}
        alt=""
      />
    ),
  },
];
const dataFlexiStaffing = [
  {
    pagename: "Flexi Staffing",
  },
  {
    title: "About Us",
    points: [
      "At DALMAF, we specialize in providing flexible staffing solutions to meet the dynamic needs of businesses across various industries. With our extensive experience and a vast network of skilled professionals, we are committed to delivering high-quality staffing services tailored to your specific requirements.",
    ],
  },
  {
    title: "Our Approach",
    points: [
      "We understand that businesses today require workforce flexibility to adapt to changing market conditions. Our approach to Flexi Staffing revolves around providing you with a scalable and agile workforce that can be quickly deployed and adjusted as per your needs. Whether you need temporary staff for seasonal demands, project-based teams, or additional resources for peak periods, we have got you covered.",
    ],
  },
  {
    title: "Our Services",
    subtitle: "Temporary Staffing",
    points: [
      "We offer a wide range of skilled and experienced temporary staff who can seamlessly integrate into your team and contribute to your business objectives. Our rigorous screening and selection process ensure that we provide you with top-notch professionals who are ready to hit the ground running",
    ],
  },
  {
    subtitle: "Contract Staffing",
    points: [
      "If you have long-term projects or specialized assignments, our contract staffing services can provide you with dedicated professionals who will work under your supervision for the desired duration. We take care of the administrative tasks, allowing you to focus on your core business activities",
    ],
  },
  {
    subtitle: "Project-Based Staffing",
    points: [
      "For short-term projects with specific deliverables, our project-based staffing solutions can help you assemble a team of experts who have the required skills and knowledge to meet your project goals effectively",
    ],
  },
  {
    subtitle: "On-Demand Staffing",
    points: [
      "When you require immediate resources to handle unexpected workloads or urgent deadlines, our on-demand staffing service ensures that you get qualified professionals quickly. We maintain a robust database of pre-screened candidates, enabling us to promptly meet your staffing needs",
    ],
  },
  {
    title: "Why Choose Us?",
    subtitle: "",
    points: [
      "1. Extensive Talent Pool: We have access to a vast network of skilled professionals across various industries and job functions, ensuring that we can fulfil your staffing requirements promptly.",
      "2. Rigorous Screening Process: Our stringent selection process guarantees that we present you with candidates who possess the right skills, experience, and attitude to excel in your organization",
      "3. Flexibility and Scalability: We provide flexible staffing solutions that can be customized to meet your evolving business needs. You can scale up or down the workforce as per your requirements",
      "4. Cost-Effective: Our staffing solutions are designed to optimize your costs by providing you with the right talent at competitive rates. We handle all administrative tasks, including payroll, benefits, and compliance, saving you time and resources.",
      "5. Customer Satisfaction: We prioritize client satisfaction and strive to build long-term partnerships. Our dedicated account managers ensure that your staffing needs are met efficiently and promptly",
    ],
  },
  {
    title: "Benefits of Flexi Staffing",
    subtitle: "",
    points: [
      "1. Reduced cost to the organization",
      "2. Improves competitive position",
      "3. Helpful in growth and development of the organization",
      "4. Employers can save money on medical benefits, sick pay, pensions, provident funds, etc.",
      "5. Organizations can experiment with potential employees",
      "6. Effortlessly accessible specialists",
      "7. Improvement in employee retention",
    ],
  },
  {
    desc: [
      "DALMAF experience in providing staff augmentation and contractual hiring services globally. With skilled developers and IT resources with rich industry expertise, we can help your business grow.",
    ],
    footer: [
      "Our services will help the organizations to achieve better productivity, enhance application and network performance optimize the workflow and reduce operational costs. Using our services, helps the organizations to save cost, the speed of hiring is high because of dedicated recruiters, it gives access to the wider and better pool, helps the organization in achieving the flexibility to choose the tenure of a resource basis performance and reduced manpower",
    ],
  },

  {
    marketingimg: (
      <img
        className="w-100 h-auto"
        src={require("../src/Assets/Images/dm10.webp")}
        alt=""
      />
    ),
  },
];
const dataContractEmployee = [
  {
    pagename: "Contract Employee",
  },
  {
    desc: [
      "We are a leading manpower agency specializing in providing highly skilled and qualified contract employees to meet the dynamic staffing needs of businesses across various industries. With years of experience and a strong network of professionals, we offer tailored staffing solutions to help organizations optimize their workforce and achieve their goals",
    ],
  },
  {
    title: "Our Contract Employee Services",
    subtitle: "",
    points: [
      "1. Flexible Workforce Solutions: We understand that businesses require flexibility in their staffing arrangements. Our contract employee services allow you to scale your workforce up or down based on your project requirements, seasonal demands, or fluctuating workloads. Our pool of talented professionals is available for short-term or long-term assignments, providing you with the agility and adaptability you need.",
      "2. Specialized Talents: We have a vast talent pool of specialized professionals across a wide range of industries and job functions. Whether you need IT experts, engineers, administrative personnel, sales representatives, or any other role, we can source and provide the right individuals with the skills and experience that match your specific requirements.",
      "3. Streamlined Recruitment Process: Finding the right contract employees can be a time-consuming and challenging task. We simplify the recruitment process by taking care of candidate sourcing, screening, and selection. Our experienced recruiters leverage their industry knowledge and networks to identify top talents who possess the necessary qualifications and fit your organizational culture",
      "4. Quality Assurance: We are committed to delivering excellence in our services. Our rigorous screening and selection process ensure that we only present you with highly qualified professionals who have undergone thorough background checks, reference verifications, and skill assessments. We strive to match your requirements with the right candidates to ensure a seamless integration into your team",
      "5. Employee Management: As a dedicated manpower agency, we take care of all employee-related administrative tasks, including payroll management, benefits administration, and contract management. This allows you to focus on your core business activities while we handle the employment logistics and ensure compliance with all relevant employment laws and regulations",
      "6. Ongoing Support: Our commitment to client satisfaction doesn't end with the placement of contract employees. We provide ongoing support throughout the contract duration, addressing any concerns, facilitating communication between the employer and employee, and resolving any issues that may arise. We value long-term relationships and strive to be your trusted partner in meeting your staffing needs",
      "7. Partner with DALMAF today and experience the benefits of our professional contract employee services. Contact us to discuss your specific requirements and let us help you build a skilled and flexible workforce to drive your business forward",
    ],
    footer:
      "Note: The above content is a general example and can be customized to fit the specific offerings and brand identity of your manpower agency",
  },
  {
    title: "What Is a Contract Employee?",
    subtitle: "",
    points: [
      "A contract employee is an individual who a company hires to work for a specific period. The contract terms are typically set in advance and outline the duties of the contract employee and the compensation they will receive. Employment Contract are often used to fill temporary positions or to complete specific projects for a company",
    ],
  },
  {
    title: "What Are the Rules of Contract Employment?",
    points: [
      "In the United States, employment contracts are governed by a patchwork of state and federal laws. However, some general principles apply to all contract employment relationships. This article will discuss the basics of contract employment law, including the types of contracts that are most commonly used and the key provisions that should be included in every contract",
    ],
  },
  {
    title: "What Are the Rules of Contract Employment?",
    points: [
      "There are a few fundamental rules to remember when it comes to contract employment. First and foremost, always get the terms of the contract in writing. This will protect you in case there are any misunderstandings later on. Secondly, make sure you understand your rights and responsibilities under the contract. If you’re unsure about something, ask your employer or consult an experienced employment lawyer. Finally, don’t forget to keep good records of your work and performance throughout the duration of the contract. This will come in handy if there are any disputes about the terms of the agreement or your job performance",
    ],
  },
  {
    title: "How to Find Contract Employment Opportunities?",
    points: [
      "There are many ways to find contract employment opportunities. The best way to find out about these opportunities is to ask around and network with people you know in the industry. You can also check online job boards and classifieds websites. Finally, you can contact a staffing agency specializing in finding contract employment opportunities",
    ],
  },
  {
    title: "What Are the Benefits of Being a Contract Employee?",
    points: [
      "There are several benefits of being a contract employee. One advantage is that you can be more flexible with your work schedule. For example, if you are a contract employee, you can choose to work fewer hours during the week or take a more extended lunch break. Additionally, contract employees often have the opportunity to work from home, which can save on childcare and commuting costs",
      "Another benefit of being a contract employee is that you may have more control over your workload. For instance, if you are working on a project-by-project basis, you can choose to take on as much or as little work as you want. This can be helpful if you want to create a better work-life balance for yourself.",
      "To Manage Contract Employees often have access to better health insurance and retirement plans than those employed full-time. This is because companies often see contract employees as more of an investment, and thus they are willing to offer these employees more perks and benefits",
      "Overall, being a contract employee can be a great way to enjoy more flexibility and benefits than you would receive in traditional employment",
    ],
  },
  {
    title: "How to Negotiate a Contract Employment Agreement?",
    points: [
      "When you’re employed by a company, you’re usually offered a contract that outlines your job duties and responsibilities. However, if you’re looking for contract employment, it’s important to know how to negotiate an agreement that works for both you and the company",
    ],
  },
  {
    subtitle:
      "Here are a few tips to keep in mind when negotiating a contract employment agreement:",
    points: [
      "1. Know your worth. Before you begin negotiating, do your research and make sure you have a good understanding of your worth in the marketplace. This will help you determine what salary and benefits you should be asking for",
      "2. Don’t be afraid to ask for what you want. Once you know your worth, don’t be afraid to ask for what you want in terms of salary and benefits. Remember, it’s important to be reasonable in your expectations so that the company can say yes to your request",
      "3. Be prepared to compromise. In any negotiation, there will always be some degree of compromise involved. Be prepared to give up some of what you want in order to reach an agreement that works for both parties",
      "4. Get everything in writing. Once you’ve reached an agreement with the company, make sure everything is put in writing",
    ],
  },
  {
    title: "What to Do if You’re Offered a Contract Employment Job",
    points: [
      "If you’re offered a contract employment job, there are a few things you should do to make sure the job is right for you. First, read the contract carefully. Make sure you understand all the terms and conditions of the job. If there are any questions, be sure to ask your potential employer before you sign anything. Next, check to see if the salary and benefits are fair. Compare the pay and benefits package to similar jobs in the industry to make sure you’re getting a good deal. If not, negotiate with your potential employer until you reach an agreement that’s fair for both parties",
      "Finally, make sure you’re comfortable with the company’s culture and working environment. Ask around to see what current and former employees think of the company. Once you’ve done your research, you can make an informed decision about whether or not a contract employment job is right for you.",
    ],
  },
  {
    title: "The Different Types of Contract Employment",
    subtitle:
      "There are a few different types of contract employment, and each type has its own set of rules. Here are the most common types of contract employment",
    points: [
      "1. Full-time employees. Full-time employees are those who work the traditional 40-hour work week. They are typically salaried, and they may be eligible for benefits such as health insurance and vacation time",
      "2. Part-time employees. Part-time employees typically work fewer hours than full-time employees, and they may not be eligible for benefits",
      "3. Contract workers. Contract workers are hired to complete a specific project or task, and they are usually not eligible for benefits. Once the project is completed, the contract worker is usually no longer employed by the company",
    ],
    footer:
      "Interns. Interns are usually college students or recent graduates who are working for a company to gain experience in their field of interest. Interns are typically not paid, but they may receive academic credit or a stipend for their work",
  },
];
const dataHouseMaids = [
  {
    pagename: "House Maids",
  },
  {
    desc: [
      "We specialize in providing reliable and skilled housemaids to meet your domestic staffing needs. Whether you require assistance with daily household chores, childcare, or elderly care, our agency is committed to delivering top-quality services tailored to your specific requirements. Here's an overview of what our agency offers",
    ],
  },
  {
    title: "Professional House Maids",
    points: [
      "We understand the importance of having trustworthy and competent housemaids to maintain a clean and organized home environment. Our agency carefully selects candidates who have undergone thorough background checks and possess the necessary skills and experience to handle various household tasks efficiently",
    ],
  },
  {
    title: "Comprehensive Screening Process",
    points: [
      "To ensure the safety and security of our clients, we have a rigorous screening process in place. Our candidates go through a series of interviews, reference checks, and verification procedures to assess their reliability, integrity, and work ethics",
    ],
  },
  {
    title: "Customized Staffing Solutions",
    points: [
      "Every household has unique needs and preferences. We work closely with our clients to understand their specific requirements and match them with suitable housemaids who possess the right skill set and temperament to excel in their roles. Whether you need a full-time live-in maid or part-time assistance, we can provide flexible staffing solutions",
    ],
  },
  {
    title: "Well-Trained and Experienced Maids",
    points: [
      "Our agency focuses on recruiting experienced housemaids who are well-versed in a wide range of household tasks, including cleaning, laundry, cooking, organizing, and more. We also provide training and support to our maids to enhance their skills and keep them updated with the latest practices",
    ],
  },
  {
    title: "Childcare and Elderly Care Services",
    points: [
      "If you require assistance with childcare or elderly care, our agency can connect you with housemaids who have experience in these areas. We understand the importance of finding caring and compassionate individuals who can provide dedicated support to your loved ones",
    ],
  },
  {
    title: "Reliable and Timely Service",
    points: [
      "We strive to provide a seamless and hassle-free experience for our clients. Our agency ensures that the housemaids are punctual, reliable, and committed to their duties. We maintain open lines of communication with our clients to address any concerns or feedback promptly",
    ],
  },
  {
    title: "Transparent Pricing",
    points: [
      "Our agency offers competitive and transparent pricing for our services. We believe in providing value for money while ensuring fair compensation for our housemaids. We will provide you with a clear breakdown of the costs involved, including any additional charges for specialized services or specific requirements",
    ],
  },
  {
    title: "Ongoing Support and Supervision",
    points: [
      "Our commitment to customer satisfaction extends beyond the initial placement. We provide ongoing support and supervision to our housemaids to ensure they consistently meet the expectations of our clients. We also encourage open communication between the clients and the housemaids to maintain a positive working relationship",
    ],
    footer: [
      "We are dedicated to providing reliable, skilled, and trustworthy housemaids to assist you with your household needs. Contact us today to discuss your requirements, and we will be delighted to assist you in finding the perfect housemaid for your home",
    ],
  },
];
const dataDrivers = [
  {
    pagename: "Drivers",
  },
  {
    desc: [
      "Specializing in driver recruitment and placement. We understand the critical role that drivers play in various industries, and we are committed to providing reliable, skilled, and professional drivers to meet your specific needs. Whether you require drivers for personal transportation, logistics, delivery services, or any other driving-related positions, we have you covered",
    ],
  },
  {
    title: "Our Services",
    subtitle: "Driver Recruitment",
    points: [
      "We have an extensive network and database of experienced drivers with various skill sets. We carefully screen and evaluate each candidate to ensure they possess the necessary qualifications, including valid driver's licenses, clean driving records, and relevant certifications",
    ],
  },
  {
    subtitle: "Driver Placement",
    points: [
      "We strive to match the right driver with the right job. Our team takes the time to understand your requirements, whether it's for a chauffeur, truck driver, bus driver, or any other driving position. We consider factors such as experience, specialization, and location to provide you with the most suitable driver for your specific needs",
    ],
  },
  {
    subtitle: "Temporary and Permanent Staffing",
    points: [
      "Whether you require drivers on a temporary or permanent basis, we can assist you. We have a flexible staffing solution to accommodate short-term projects, seasonal demands, or long-term driver placements based on your business requirements",
    ],
  },
  {
    subtitle: "Industry Specialization",
    points: [
      "Our agency caters to a wide range of industries, including transportation and logistics, retail and e-commerce, hospitality, construction, healthcare, and more. We understand the unique demands of each industry and can source drivers with the necessary expertise and knowledge to excel in their roles",
    ],
  },
  {
    subtitle: "Driver Training and Certification",
    points: [
      "In addition to driver recruitment and placement, we also offer driver training and certification services. We collaborate with reputable training institutes to provide comprehensive training programs to enhance driver skills, safety awareness, and regulatory compliance",
    ],
  },
  {
    title: "Why Choose Us",
    subtitle: "Extensive Network",
    points: [
      "Our agency has established a vast network of skilled drivers, ensuring a wide pool of talent to choose from. We leverage our connections to find the best-suited candidates for your organization",
    ],
  },
  {
    subtitle: "Rigorous Screening Process",
    points: [
      "We conduct thorough background checks, including driving record verification, license validation, reference checks, and criminal background screening. We ensure that every driver we recommend meets the highest standards of professionalism and reliability",
    ],
  },
  {
    subtitle: "Customized Solutions",
    points: [
      "We understand that each client has unique requirements. Our team works closely with you to tailor our services and find the most suitable drivers who align with your organization's values and objectives",
    ],
  },
  {
    subtitle: "Timely Support",
    points: [
      "We are committed to providing prompt and responsive service. Our dedicated team is available to address any queries, concerns, or staffing requirements you may have, ensuring a seamless experience throughout the recruitment and placement process",
    ],
  },
  {
    subtitle: "Quality Assurance",
    points: [
      "We prioritize quality in all aspects of our service. By continuously monitoring driver performance and seeking feedback from our clients, we ensure that the drivers we provide consistently meet and exceed expectations",
    ],
    footer: [
      "Contact us today to discuss your driver staffing needs and discover how our DALMAF can assist you in securing reliable and skilled drivers for your organization. We look forward to partnering with you and helping you drive success",
    ],
  },
];
const dataOfficeBoys = [
  {
    pagename: "Office Boys",
  },
  {
    points: [
      "Your trusted partner for all your Office Boy staffing needs. We specialize in providing highly skilled and reliable Office Boys who are committed to delivering exceptional support services to your organization. With our extensive network and rigorous selection process, we ensure that you receive the best talent to meet your specific requirements",
    ],
  },
  {
    subtitle:
      "Our Office Boys are well-trained professionals who possess a range of skills to assist you in maintaining a productive and organized work environment. They are equipped to handle a variety of tasks, including but not limited to:",
  },
  {
    title: "Office Maintenance",
    points: [
      "Our Office Boys are responsible for maintaining a clean and organized workspace. They ensure that common areas, such as conference rooms, reception areas, and kitchenettes, are kept tidy and presentable",
    ],
  },

  {
    title: "Document Handling",
    points: [
      "They assist with filing, photocopying, scanning, and distributing documents as per your instructions. Our Office Boys understand the importance of confidentiality and handle sensitive information with utmost care",
    ],
  },
  {
    title: "Refreshment Management",
    points: [
      "Whether it's setting up coffee and tea stations, managing pantry supplies, or organizing catered events, our Office Boys can handle all aspects of refreshment management, ensuring your employees have a pleasant and comfortable experience",
    ],
  },
  {
    title: "Office Support",
    points: [
      "From assisting with meeting room setups, audio-visual equipment, and IT troubleshooting to handling phone calls, scheduling appointments, and managing visitor registration, our Office Boys are versatile in providing comprehensive office support",
    ],
  },
  {
    title: "Inventory Management",
    points: [
      "Our Office Boys can help you manage office supplies and stationery inventory. They maintain accurate records, restock items when needed, and ensure that your team has the necessary resources to carry out their tasks efficiently",
    ],
    footer: [
      "At DALMAF, we understand that every organization has unique requirements. We work closely with you to understand your specific needs and tailor our services accordingly. Our dedicated team ensures seamless communication, prompt assistance, and reliable service delivery",
    ],
  },
  {
    title: "Why choose DALMAF for your Office Boy staffing?",
    subtitle: "Extensive Screening Process",
    points: [
      "We thoroughly screen and evaluate potential candidates to ensure their qualifications, experience, and reliability",
    ],
  },
  {
    subtitle: "Skillful Professionals",
    points: [
      "Our Office Boys possess the necessary skills, training, and knowledge to handle various office tasks efficiently",
    ],
  },
  {
    subtitle: "Timely Service",
    points: [
      "We understand the importance of punctuality and ensure that our Office Boys arrive on time, ready to assist you",
    ],
  },
  {
    subtitle: "Flexibility",
    points: [
      "Whether you need full-time, part-time, or temporary Office Boys, we can accommodate your staffing needs based on your schedule and requirements",
    ],
  },
  {
    subtitle: "Customer Satisfaction",
    points: [
      "We strive for excellence in customer service. Your satisfaction is our top priority, and we continuously seek feedback to improve our services",
    ],
    footer:
      "Partner with DALMAF today and experience the benefits of having skilled Office Boys who contribute to the smooth functioning of your office. Contact us now to discuss your requirements or schedule a consultation",
  },
];
const dataBabyCareTaker = [
  {
    pagename: "Baby Care Taker",
  },
  {
    desc: [
      "Your trusted partner in providing professional and reliable baby care takers. We understand the importance of finding the right caregiver for your little ones, and we are here to make the process easy and stress-free for you",
    ],
    title: "Our Baby Care Takers",
    points: [
      "At DALMAF, we take pride in recruiting highly skilled and experienced baby care takers who are passionate about nurturing and caring for infants and young children. Our caregivers undergo a rigorous selection process, including thorough background checks, interviews, and verification of qualifications, to ensure that only the most qualified individuals join our team",
    ],
  },
  {
    title: "Services We Offer",
    subtitle: "Qualified and Experienced Caregivers",
    points: [
      "We connect families with experienced baby care takers who have undergone specialized training in infant and child care. Our caregivers are well-versed in providing a safe, nurturing, and stimulating environment for your child's overall development",
    ],
  },
  {
    subtitle: "Flexible Scheduling",
    points: [
      "We understand that every family has unique needs and schedules. Whether you require full-time, part-time, live-in, or live-out care, we can tailor our services to accommodate your specific requirements",
    ],
  },
  {
    subtitle: "Customized Care Plans",
    points: [
      "We work closely with you to understand your child's specific needs, routines, and preferences. Based on this information, we develop personalized care plans to ensure that our caregivers provide individualized attention and care to your child",
    ],
  },
  {
    subtitle: "Safety and Security",
    points: [
      "Your child's safety is our top priority. We thoroughly screen all our caregivers and verify their credentials, including their background checks, references, and certifications. We strive to maintain the highest standards of safety and security in our recruitment process",
    ],
  },
  {
    subtitle: "Continuous Support",
    points: [
      "We believe in building long-term relationships with our clients. Our dedicated team is available to provide ongoing support, address any concerns or questions you may have, and ensure that your experience with our baby care takers is positive and rewarding",
    ],
  },
  {
    title: "How It Works",
    subtitle: "Consultation",
    points: [
      "Contact us to schedule a consultation, where we will discuss your specific needs, preferences, and any other requirements you may have for a baby care taker",
    ],
  },
  {
    subtitle: "Caregiver Selection",
    points: [
      "Based on the information gathered during the consultation, we will handpick suitable baby care takers from our pool of qualified professionals. We will provide you with detailed profiles of potential candidates for your review",
    ],
  },
  {
    subtitle: "Interview and Selection",
    points: [
      "You will have the opportunity to interview the selected candidates and make the final decision. We encourage open communication and invite you to ask questions and discuss expectations during the interview process",
    ],
  },
  {
    subtitle: "Placement and Follow-up",
    points: [
      "Once you have selected a baby care taker, we will assist with the necessary paperwork and ensure a smooth placement process. We will also conduct periodic follow-ups to ensure that both you and your child are satisfied with the caregiver's performance",
    ],
  },
  {
    title: "Contact Us",
    subtitle:
      "We look forward to helping you find the perfect baby care taker for your little one. To get started or learn more about our services, please contact us through the following channels:",
    footer: [
      " DALMAF is committed to providing exceptional baby care services, giving you peace of mind and allowing you to focus on your professional and personal commitments while your child is in safe hands. Trust us to provide the care and attention your child deserves.",
    ],
  },
];
const dataElderCareTaker = [
  {
    pagename: "Elder Care Taker",
  },
  {
    desc: [
      "Your trusted partner in providing professional and compassionate elder care services. We understand the importance of ensuring the well-being and comfort of our senior citizens, and our dedicated team of experienced elder care takers is here to offer exceptional care and support",
    ],
    title: "Our Services",
  },
  {
    subtitle: "Personalized Elder Care",
    points: [
      "We believe in tailoring our services to meet the unique needs of each individual. Our elder care takers are trained to provide personalized care, including assistance with daily activities, medication management, mobility support, and companionship",
    ],
  },
  {
    subtitle: "Companionship and Emotional Support",
    points: [
      "We recognize the significance of companionship and emotional well-being in the lives of seniors. Our caregivers foster meaningful relationships with the elderly, engaging in conversations, participating in activities, and offering a supportive presence to enhance their quality of life",
    ],
  },
  {
    subtitle: "Medication Management",
    points: [
      "Our experienced caregivers are trained to handle medication administration, ensuring that seniors take their prescribed medications at the right time and in the correct dosage. We maintain detailed records and work closely with healthcare professionals to ensure proper medication management",
    ],
  },
  {
    subtitle: "Assistance with Daily Activities",
    points: [
      "Our elder care takers assist with various daily activities to promote independence and ensure a comfortable lifestyle. This includes help with bathing, dressing, grooming, meal preparation, light housekeeping, and running errands",
    ],
  },
  {
    subtitle: "Mobility Support and Fall Prevention",
    points: [
      "We prioritize the safety of our seniors and provide assistance with mobility to prevent falls and accidents. Our caregivers are trained in techniques for transferring, using mobility aids, and creating a safe environment within the home",
    ],
  },
  {
    subtitle: "Respite Care",
    points: [
      "We understand that caring for an elderly loved one can be physically and emotionally demanding. Our respite care services offer temporary relief to family caregivers, allowing them to take a break while ensuring their loved one receives attentive care from our skilled professionals",
    ],
  },
  {
    title: "Why Choose Us",
  },
  {
    subtitle: "Highly Qualified Caregivers",
    points: [
      "Our elder care takers are carefully selected based on their qualifications, experience, and dedication to providing excellent care. We conduct thorough background checks and verification processes to ensure the safety and reliability of our staff",
    ],
  },
  {
    subtitle: "Compassionate and Trustworthy",
    points: [
      "We prioritize compassion, respect, and empathy in our approach to elder care. Our caregivers are not only highly skilled but also genuinely passionate about improving the lives of seniors. Trust is of utmost importance to us, and we strive to build strong relationships based on transparency and open communication",
    ],
  },
  {
    subtitle: "Customized Care Plans",
    points: [
      "We recognize that every individual has unique requirements. Our care plans are customized to accommodate specific needs, preferences, and schedules. We work closely with families to design personalized care solutions that meet the highest standards of quality",
    ],
  },
  {
    subtitle: "Continuity of Care",
    points: [
      "We understand the importance of consistency in care provision. Our agency ensures a consistent caregiver-client relationship, allowing seniors to build trust and familiarity with their dedicated elder care taker",
    ],
  },
  {
    subtitle: "Transparent Pricing",
    points: [
      "We believe in transparent pricing with no hidden costs. Our fee structure is designed to be affordable and competitive, reflecting the quality of care and support provided.",
    ],
    footer:
      "Contact us today to discuss your elder care needs. Our friendly team is ready to answer your questions, provide detailed information, and assist you in finding the perfect elder care taker for your loved one. Let us be your partner in providing exceptional care and improving the lives of seniors.",
  },
];
const dataBackOfficeStaffing = [
  {
    pagename: "Back Office Staffin",
  },
  {
    points: [
      "Your premier destination for efficient and reliable back office staffing solutions. With our extensive experience and a dedicated team of professionals, we specialize in connecting businesses with top-notch back office staff who can streamline operations and drive growth. Whether you need support in administrative tasks, data management, customer service, or any other back office function, we have the perfect talent to meet your requirements",
    ],
  },
  {
    title: "Our Approach",
    subtitle: "Comprehensive Understanding",
    points: [
      "We take the time to understand your unique business needs, organizational culture, and specific requirements for back office staff. This enables us to provide tailored solutions that align with your goals and enhance your operations",
    ],
  },
  {
    subtitle: "Extensive Talent Pool",
    points: [
      "Our agency boasts a vast network of qualified professionals with expertise in various back office functions. We rigorously screen and evaluate candidates to ensure they possess the right skills, knowledge, and experience to excel in their roles",
    ],
  },

  {
    subtitle: "Customized Staffing Solutions",
    points: [
      "We offer flexible staffing options to cater to your fluctuating demands. Whether you require temporary staff for short-term projects or permanent placements for long-term support, we can customize our services to suit your preferences",
    ],
  },

  {
    subtitle: "Proven Track Record",
    points: [
      "Over the years, we have successfully matched numerous businesses with exceptional back office staff. Our track record speaks volumes about our commitment to delivering reliable and competent professionals who can contribute to your success",
    ],
  },
  {
    title: "Our Back Office Staffing Services",
    subtitle: "Administrative Support",
    points: [
      "From managing schedules and coordinating appointments to handling correspondence and organizing documents, our administrative professionals ensure smooth day-to-day operations",
    ],
  },
  {
    subtitle: "Data Management",
    points: [
      "Our skilled data entry operators and data analysts assist in accurately capturing, organizing, and analysing large volumes of data, enabling you to make informed business decisions",
    ],
  },
  {
    subtitle: "Customer Service",
    points: [
      "We connect you with friendly and efficient customer service representatives who can handle inquiries, resolve issues, and provide exceptional support to your valued clients.",
    ],
  },
  {
    subtitle: "Finance and Accounting",
    points: [
      "Our finance and accounting professionals offer expertise in areas such as bookkeeping, accounts payable/receivable, financial analysis, payroll processing, and more, ensuring the financial health of your organization",
    ],
  },
  {
    subtitle: "Human Resources",
    points: [
      "From recruitment and on boarding to employee record management and HR policy implementation, our HR specialists provide comprehensive support to optimize your human capital",
    ],
  },
  {
    subtitle: "IT Support",
    points: [
      "Our technical experts offer IT support services, including software troubleshooting, network administration, system maintenance, and help desk assistance, ensuring your technology infrastructure runs smoothly",
    ],
  },
  {
    title: "Why Choose Us",
    subtitle: "Quality Assurance",
    points: [
      "We are committed to delivering excellence and ensure that all candidates undergo rigorous screening processes to meet the highest standards",
    ],
  },
  {
    subtitle: "Time and Cost Savings",
    points: [
      "By entrusting your back office staffing needs to us, you save valuable time and resources that can be redirected towards core business activities, leading to increased efficiency and cost-effectiveness",
    ],
  },

  {
    subtitle: "Flexible Solutions",
    points: [
      "Our staffing options are designed to accommodate your changing needs, enabling you to scale your workforce up or down as required",
    ],
  },

  {
    subtitle: "Reliable Partnerships",
    points: [
      "We prioritize building long-term relationships with our clients, based on trust, transparency, and open communication. Your satisfaction is our utmost priority",
    ],
    footer:
      "Contact us today to discuss your back office staffing requirements and let us provide you with the exceptional professionals you need to achieve your business objectives. Partner with DALMAF and experience the difference in back office staffing excellence",
  },

  {
    title: "What Is Back Office Staffing?",
    points: [
      "1. Back Office Staffing refers to positions within a company that aren't client-facing. The back office is essentially the department that provides support to the front office, or client-facing, positions with their various responsibilities. Employees in the back office handle several administrative tasks, including maintaining records and data management. For example, at a call centre, most of the jobs may be client-facing. However, the back office jobs at a call centre may include accounting, HR, analytics and office management.",
      "2. Typically, the back office generates less income for a company than the front office does. It is also important to note that though back-office employees don't interact with a company's clients, they do interact with those working in the front office. If you are looking for back office work, consider finding an industry that interests you and that has back office positions that fit your skill set",
    ],
  },
  {
    subtitle:
      "Here is a list of departments that are typically part of the back office, regardless of the industry they are in:",
    points: [
      "1. Human resources",
      "2. Operations",
      "3. IT",
      "4. Accounting",
      "5. Compliance",
    ],
  },
  {
    title: "Is Back Office Staffing a good career?",
    points: [
      "1. Back Office Staffing can be a good career for someone who prefers to work in an office or is interested in working with the same people most days. Back Office Staffing is versatile but typically takes place within regular working hours. It can allow for consistency in your schedule and does not require extensive travel. This type of work is a good career for someone who has familial obligations or does not want to have an unpredictable schedule. Here are some other benefits of a back office career:",
      "2. Not customer-facing: Back office workers do not work directly with customers. Instead, they work on teams of the same colleagues, or with other internal departments. An employee who does not enjoy meeting new people or handling customer service requests may prefer this type of work",
      "3. More relaxed: Customers rarely see back office workers, and they rarely represent the face of the company at conferences or networking events, so it can be a more relaxed environment in terms of dress code and professional etiquette",
      "4. Vital roles: Back Office Staffing can be vital to the progress and competent running of a business. As a back office worker, you may feel more secure in your contribution to the company you work for.",
      "5. Remote work: Technology has allowed many back office jobs to be done remotely with the use of networks and online databases. This allows more freedom for back office workers who are not on-site to perform duties or speak with customers",
    ],
  },
  {
    title: "What is a back office salary?",
    desc: [
      "Back office jobs can refer to a lot of positions. What you do and the qualifications your job requires can influence your salary. Where you work and how much experience you have can also affect how much money you make. Here is a list of back office positions and their salaries:",
    ],
  },
  {
    subtitle: "1. Finance assistant",
    points: ["National average salary: ₹4,20,633 per year"],
    footer:
      "Primary duties: A finance assistant supports the finance department with data entry, creating balance sheets and updating financial records. They are detail-oriented, organised and analytical. They also have mathematics skills and a strong understanding of accounting principles",
  },
  {
    subtitle: "2. Human resources assistant",
    points: ["National average salary: ₹14,361 per month"],
    footer:
      "Primary duties: A human resources assistant works in the HR department, assisting with administrative duties. They can help on-board new employees, set up payroll and maintain employee records. This position uses skills like organisation, communication and attention to detail",
  },
  {
    subtitle: "3. Staff accountant",
    points: ["National average salary: ₹15,910 per month"],
    footer:
      "Primary duties: A staff accountant works in an accounting office to organise and maintain financial records. They handle entering expenses and recording entries into various accounts. A staff accountant position is often an entry-level position that requires skill with mathematics and organisation, attention to detail and an understanding of accounting principles. Staff accountants often work on a team of accountants, which means they also use communication and teamwork skills",
  },
  {
    subtitle: "4. Operational manager",
    points: ["National average salary: ₹22,481 per month"],
    footer:
      "Primary duties: An operational manager handles creating and evaluating the processes by which a company conducts their business. They assist other departments in evaluating their needs and resources so that each department works optimally. People with exceptional communication, organisational and problem-solving skills work in this position. This type of work can also require knowledge of legal documents and local regulations",
  },
  {
    subtitle: "5. Data analyst",
    points: ["National average salary: ₹30,372 per month"],
    footer:
      "Primary duties: A data analyst works with data collected by the marketing or sales team. They use data collection and management software to compile data and analyse it for patterns. Their analysis can provide information about customer behaviour or industry trends. These positions are appropriate for people who have strong analytical skills, excel at problem-solving and have a basic understanding of mathematics principles",
  },
  {
    subtitle: "6. Compliance officer",
    points: ["National average salary: ₹37,201 per month"],
    footer:
      "Primary duties: A compliance officer makes sure that a company is adhering to the laws and local regulations regarding their industry. They stay up-to-date on changes to regulations. They monitor all procedures of the company and give recommendations about procedures that relate to those regulations. This position prevents the company from accruing any fines or being liable for any legal action. A compliance officer uses their strong attention to detail, excellent communication skills and analytical skills",
  },
  {
    title:
      "Here are some skills that you can use in a variety of back office jobs",
    subtitle: "Communication",
    points: [
      "Back office jobs are often positions that work in tandem with other departments, so having strong communication skills can be a benefit. Working with data or optimising procedures for other departments can require written communication skills. For example, you may write emails to communicate directions or clarify the needs of your co-workers, or you may write reports that detail your findings or explain your recommendations. You may also need excellent verbal communication skills to communicate with your team members during the day, as back office positions typically work with the same team for extended periods of time",
    ],
  },
  {
    subtitle: "Accounting",
    points: [
      "A common type of Back Office Staffing is in accounting. If your company is small, they may not have multiple people working in an accounting department. Instead, they may have one person dedicated to maintaining their financial records. If you can understand the basic principles of accounting, you can find work as a staff accountant or as a management assistant who handles the accounting for a small business operation. You can develop this skill into a lucrative career, or you can list it as an additional skill on your resume that appeals to a specific back office position",
    ],
  },
  {
    subtitle: "Data management",
    points: [
      "A variety of departments require data management, from HR to marketing to accounting. The purpose of data management is to make sure that you can collect, organise and maintain the integrity of data. Many companies use data management systems or databases that can help you organise and store data. If you can become familiar with these databases, or if you can show that you have superior attention to detail and can recognise patterns in data, you may have very strong data management skills that can help you do well in multiple back office positions.",
    ],
  },
  {
    subtitle: "Information technology (IT)",
    points: [
      "Many back office positions require the use of computers and software, so a basic information technology understanding may be useful. Many companies have IT departments to answer their questions and maintain their computers and network systems. If you can understand and improve upon these systems, you could be an invaluable member of a back office environment. People with IT skills can be useful in a variety of industries, so adding this skill to your resume can make your candidacy more appealing to companies hiring for back office positions.",
    ],
    footer:
      "Salary figures reflect data listed on Indeed Salaries at the time of writing. Salaries vary depending on the hiring organisation and candidate experience, academic background, and location.",
  },
];
const dataPermanentStaffing = [
  {
    pagename: "Permanent Staffing",
  },
  {
    points: [
      "Your trusted partner for Permanent Staffing solutions. We are a leading manpower agency dedicated to connecting employers with top-tier talent, ensuring long-term success for both businesses and professionals. Our comprehensive Permanent Staffing services are tailored to meet your unique requirements and help you build a strong and dynamic workforce",
    ],
  },
  {
    title: "Why Choose Us?",
    points: [
      "1. Extensive Industry Expertise: With years of experience in the staffing industry, we have developed an in-depth understanding of various sectors and their specific needs. Our team of experts possesses comprehensive knowledge and expertise in recruiting candidates for permanent positions across a wide range of industries",
      "2. Talent Acquisition: We specialize in identifying and attracting highly qualified professionals who are the perfect fit for your organization. Our rigorous screening and evaluation process ensures that we present you with candidates who possess the right skills, experience, and cultural fit to contribute to your company's growth",
      "3. Customized Solutions: We recognize that every organization has unique staffing requirements. Whether you need to fill executive positions or build a specialized team, we work closely with you to understand your needs and provide tailor-made staffing solutions that align with your goals and objectives",
      "4. Extensive Candidate Network: Our vast network of talented professionals allows us to source candidates from diverse backgrounds and skill sets. We utilize cutting-edge technology and industry-leading resources to identify and engage with top talent, ensuring that you have access to the best candidates in the market",
      "5. Streamlined Recruitment Process: We understand the importance of time and efficiency in the recruitment process. Our dedicated team of recruitment specialists follows a streamlined approach to ensure a smooth and hassle-free experience. From initial candidate sourcing and screening to interviews, reference checks, and offer negotiations, we handle every step of the process with precision and professionalism",
      "6. Long-Term Partnerships: At DALMAF, we value long-term relationships with our clients. We strive to be your trusted advisor and partner in all your staffing needs. Our commitment to excellence and customer satisfaction has earned us a reputation for delivering exceptional results and building lasting partnerships",
    ],
  },
  {
    title: "Our Services",
    points: [
      "1. Candidate Sourcing: We leverage our extensive network and advanced sourcing techniques to identify and attract high-calibre candidates who meet your specific job requirements",
      "2. Screening and Evaluation: Our team conducts comprehensive screening and evaluation processes, including skills assessments, behavioural interviews, and reference checks, to ensure that you only receive candidates who are a perfect fit for your organization",
      "3. Interview Coordination: We handle all aspects of interview coordination, including scheduling, logistics, and candidate preparation, to save you time and effort",
      "4. Offer Management: We assist with the negotiation and offer management process, ensuring a seamless transition from candidate selection to on boarding",
      "5. Post-placement Support: Our commitment to your success extends beyond the placement. We provide post-placement support to facilitate a smooth integration of new hires into your organization, helping them acclimate to their roles and responsibilities",
    ],
    footer:
      "Contact us today to discuss your Permanent Staffing needs and discover how DALMAF can help you build a talented and dedicated workforce. Our team of experts is ready to partner with you and provide customized solutions that drive your business forward",
  },
  {
    title: "Permanent Staffing Services ",
    points: [
      "Hiring permanent employees and absorbing them into your organisation is a serious commitment, so you better make sure that you’re making this commitment to the right people. We are here to help you pick out just the right ones",
    ],
    footer:
      "Alp’s permanent staffing solutions give you access to fully screened and qualified candidates in any industry of your choice. We have a 200-member strong team of consultants who dedicate themselves to helping your company achieve its business objectives. Our process is fool proof and ensures that we offer our clients the best candidates at any level, with the fastest turnaround time",
  },
  {
    title: "WHAT WE DO",
    points: [
      "1. Before we start looking for candidates, we first study your requirements in detail, undertake comprehensive research on the industry, and create interest around your company to attract the best talent. No other permanent placement staffing agency will be able to match the quality of our candidates which we identify through our tried-and-tested processes",
      "2. We will customise our talent search to your specific requirements so that we can shortlist the individuals or teams that are most capable of handling the unique challenges that they will face at the organisation",
      "3. Our experience in permanent staffing services and the network of recruiting experts use client specific and consultative strategies to pick out just the right fit for your company's culture and business needs. We have a streamlined sourcing and delivery process which minimizes all costs that are usually incurred when you recruit, on-board, and train new employees",
    ],
  },
];
const dataProductAdvertising = [
  {
    pagename: "Product Advertising",
  },
  {
    points: [
      "Your trusted partner in Product Advertising! We specialize in providing skilled professionals and talent solutions to meet your advertising needs. With our extensive network and industry expertise, we ensure that you have access to the best resources and manpower to drive successful product advertising campaigns",
    ],
  },
  {
    title: "Our Services",
    subtitle: "",
    points: [
      "1. Talent Acquisition: We understand the importance of having the right people on your advertising team. Our talent acquisition specialists are dedicated to sourcing, screening, and recruiting top-notch professionals in the field of product advertising. Whether you need creative copywriters, graphic designers, digital marketers, or advertising strategists, we can help you find the perfect fit for your requirements",
      "2. Staffing Solutions: Finding temporary or contract-based professionals for your advertising projects can be challenging. Our staffing solutions provide you with flexible and scalable options to quickly ramp up your advertising team. We have a vast pool of skilled individuals who are readily available to join your projects and contribute to your advertising success.",
      "3. Project-Based Staffing: If you have short-term advertising campaigns or specific projects that require specialized expertise, our project-based staffing services are designed for you. We can assemble a team of professionals with the skills and experience necessary to deliver exceptional results within the specified timeline.",
      "4. Freelancer Management: We understand that sometimes you may require freelancers for your advertising initiatives. Our freelancer management services simplify the process of finding, on boarding, and managing freelancers. We handle all administrative tasks, ensuring smooth collaboration and effective utilization of freelance resources",
      "5. Training and Development: Continuous learning and development are crucial in the ever-evolving advertising industry. We offer training programs and workshops to enhance the skills of your existing advertising team or provide customized training for specific roles. Our aim is to equip your workforce with the latest tools, techniques, and industry knowledge to excel in product advertising.",
    ],
  },
  {
    title: "Why Choose Us",
    points: [
      "1. Extensive Industry Knowledge: Our team of experts possesses in-depth knowledge and experience in product advertising. We stay updated with the latest industry trends and best practices, enabling us to provide valuable insights and solutions tailored to your specific advertising requirements",
      "2. Robust Talent Pool: Our vast network of talented professionals is thoroughly screened and assessed to ensure their skills and expertise align with your needs. We handpick the best candidates who possess the right blend of creativity, technical prowess, and strategic thinking to drive successful product advertising campaigns",
      "3. Personalized Approach: We understand that every advertising project is unique. We take a personalized approach, working closely with you to understand your goals, brand identity, and target audience. Our solutions are customized to align with your vision and deliver impactful advertising results",
      "4. Timely Delivery: We value your time and understand the importance of meeting deadlines. Our streamlined processes and efficient candidate selection ensure that we provide you with suitable professionals within the agreed-upon timeframe, allowing you to execute your advertising campaigns seamlessly",
      "5. Long-Term Partnerships: Building strong and enduring relationships with our clients is at the core of our business philosophy. We strive to become your trusted partner, providing ongoing support and assistance in meeting your evolving product advertising needs. Your success is our success.",
    ],
    footer:
      "Contact DALMAF today to discuss your product advertising requirements and discover how our services can propel your advertising efforts to new heights. Let us be your strategic manpower partner in achieving advertising excellence!",
  },
  {
    title: "Types of product advertisements",
    points: [
      "There are three main types of product advertisements. The product's stage within the product life cycle determines which type of product advertising to use. A product may be in the introduction, growth, maturity or decline stage of the product life cycle",
    ],
  },
  {
    subtitle: "The three main types of product advertising are:",
  },
  {
    title: "Comparative advertising",
    points: [
      "Comparative advertising compares your product with one or more competing brands. Consider using this method for products in the growth or maturity stages of the product life cycle. These advertisements often focus on specific attributes or features, whether indirectly or directly, to influence consumers to choose one product over another. Comparative advertising helps consumers make a logical purchase decision by helping them better understand their options and which product they would prefer to use",
    ],
  },
  {
    title: "Competitive advertising",
    points: [
      "Competitive advertising attempts to generate demand for a specific brand. Consider using this strategy for products in the growth or maturity stages of the product life cycle. These advertisements focus on the benefits and attributes a product has that may not be available in similar products from other brands. The goal of competitive advertising is to convince audiences that the company's products are better than similar products in the marketplace",
    ],
  },

  {
    title: "Pioneering advertising",
    points: [
      "1. Pioneering advertising creates initial demand for a new product. This method is most often used for products in the introduction phase of the product life cycle. These advertisements include in-depth explanations about the benefits of the product. The goal of pioneering advertising is to improve public awareness regarding a new product",
      "2. Ways to use product advertisements",
    ],
  },

  {
    subtitle:
      "Here are some ways to use product advertising in common advertising channels:",
  },
  {
    title: "Online advertisements",
    points: [
      "Consider using online advertisements on social networking websites to display your product to customers. This allows you to target extremely specific audience groups who are likely to purchase your product. Use online advertisements that prominently feature photographs of your individual products and encourage customers to click and purchase immediately.",
    ],
  },
  {
    title: " Print advertisements",
    points: [
      "Print advertisements allow you to provide customers with detailed explanations of the benefits of using your product. Also, some types of print advertisements, such as those in magazines, allow you to target audiences with specific interests. For example, a company selling a new type of fishing line that's stronger than others may consider placing an advertisement in a magazine focused on fishing or outdoor activities",
    ],
  },
  {
    title: "Television advertisements",
    points: [
      "Television advertisements may be beneficial for showing the value of your product and how it may enhance a consumer's life. Many companies use television advertisements to make comparisons between their products versus their competitors. For example, a paper towel company may develop a television advertisement that shows a side-by-side comparison of how effectively their paper towel cleans up messes versus how poorly their competitor cleans up the same mess.",
    ],
  },
  {
    title: "Billboard advertisements",
    points: [
      "Billboard advertisements allow you to provide information to consumers quickly. They can be effective for communicating the availability and proximity of your product to consumers, such as a billboard advertising a fast-food restaurant is available in a few miles. Billboard advertisements may also be effective for making striking comparisons between your product and a competitor's. For example, a fertilizer company may show two types of flowers side by side to compare how well their fertilizer helped the plants grow versus how their competitor's product did not",
    ],
  },
];
const dataProductMarketing = [
  {
    pagename: "Product Marketing",
  },
  {
    desc: [
      "At DALMAF, we specialize in providing top-notch talent for product marketing roles. Our agency understands the unique challenges and demands of the product marketing industry, and we are committed to connecting businesses with skilled professionals who can drive their marketing strategies and propel their products to success.",
    ],
    points: [
      "Our Mission: Our mission is to bridge the gap between businesses in need of product marketing expertise and talented professionals who can deliver exceptional results. We strive to be the go-to resource for companies looking to build effective product marketing teams and individuals seeking exciting opportunities in this dynamic field",
    ],
  },
  {
    title: "What We Offer",
    points: [
      "1. Talent Acquisition: We have a vast network of highly skilled and experienced product marketing professionals. Our team meticulously screens and evaluates candidates to ensure they possess the right skills, experience, and cultural fit for your organization. We understand that every business has unique requirements, and we are committed to finding the perfect match for your specific needs",
      "2. Comprehensive Services: Our agency offers end-to-end solutions to meet your product marketing staffing needs. From entry-level roles to executive positions, we provide a full spectrum of talent for various functions, including product positioning, market research, competitive analysis, branding, campaign management, and more. Whether you need short-term contractors or permanent employees, we can find the right individuals for your team.",
      "3. Industry Expertise: With years of experience in the product marketing industry, our team possesses in-depth knowledge of the latest market trends, emerging technologies, and best practices. We stay updated with the evolving landscape of product marketing, allowing us to identify candidates with the right skills and competencies to drive your marketing initiatives forward",
      "4. Customized Solutions: We believe in personalized service and understand that each business has its own unique requirements. Our agency takes the time to understand your organization's goals, culture, and specific needs. We tailor our recruitment process to match your expectations, ensuring that the candidates we present align with your vision and contribute to your success",
      "5. Long-Term Partnerships: Building strong relationships with our clients is at the core of what we do. We strive to be more than just a recruitment agency; we aim to become your trusted partner in sourcing and nurturing top product marketing talent. We are committed to your long-term success and will continue to support you even after successful placements.",
    ],
    footer:
      "Contact us today and let us help you find the perfect product marketing professionals who can elevate your business to new heights. Partner with DALMAF and experience the difference in quality and service.",
  },
  {
    subtitle:
      "We look forward to collaborating with you and contributing to your product marketing success!",
  },
  {
    title: "What is product marketing?",
    points: [
      "1. Product marketing is the process of bringing a company’s products to market. But where does product marketing begin and end? There’s no straightforward answer to this question",
      "2. Some companies have product marketer’s laser-focused on launching new products. But others expand the scope of product marketing to inform and guide product development, as well as ongoing sales strategy, years after a product’s debut.",
      "3. To take a broader view, product marketing is the intersection between products and the market. The demands of the market will inform which products are made and how they are presented to consumers in every marketing campaign. In this sense, product marketing can encompass every aspect of developing, launching, and selling a product",
    ],
  },
  {
    title: "What do product marketers do?",
    desc: [
      "Because the definition of product marketing can vary, it follows that the roles of product marketers (or product marketing managers, as they’re often known) can be quite different, depending on their industry and their company",
    ],
  },
  {
    subtitle:
      "Product marketing can be a responsibility shared across several different people and departments, or assigned to a product marketing manager. There could even be a dedicated product marketing team. The key responsibilities of a product marketer include:",
    points: [
      "Conducting and analysing market research",
      "⦁	Assisting in product development",
      "⦁	Determining the positioning and messaging for new products",
      "⦁	Developing and executing a go-to-market strategy for product launches",
      "⦁	Measuring product and campaign success via customer feedback and key performance indicators",
    ],
  },
];
const dataSkilledTraining = [
  {
    pagename: "Skilled Training",
  },
  {
    points: [
      "Your premier destination for skilled training and employment solutions. We specialize in connecting talented individuals with industry-specific training programs to enhance their skills and open up new career opportunities. With our extensive network of partners and our commitment to excellence, we strive to be your trusted partner in achieving professional success",
    ],
  },
  {
    title: "Our Services",
    points: [
      "1. Skilled Training Programs: We offer a wide range of comprehensive training programs designed to develop and upgrade your skills in various industries. Our training courses are conducted by industry experts and cover both theoretical knowledge and practical hands-on experience. Whether you are looking to acquire new skills or enhance your existing ones, we have the right training program for you",
      "2. Job Placement Assistance: Once you have completed your training, our dedicated team of recruitment professionals will assist you in finding suitable employment opportunities. We have established strong relationships with leading companies across different sectors, enabling us to match your skills and qualifications with the right job openings. Our goal is to connect you with employers who value your expertise and provide fulfilling career prospects",
      "3. Career Counselling: We understand that choosing the right career path can be a challenging decision. Our experienced career counsellors are here to guide you through the process, helping you identify your strengths, interests, and career goals. Through one-on-one counselling sessions, we provide personalized advice and recommendations to help you make informed decisions about your professional journey",
      "4. Skill Assessment: If you are unsure about your current skill level or need guidance on which training program to pursue, our skill assessment services can provide valuable insights. Our assessment tools evaluate your knowledge and proficiency in specific areas, allowing us to recommend the most suitable training options tailored to your needs",
    ],
  },
  {
    title: "Why Choose Us",
    points: [
      "1. Extensive Industry Network: We have developed strong partnerships with industry-leading companies and organizations, enabling us to stay updated with the latest industry trends and requirements. Our network connections provide us with valuable insights into emerging job opportunities and skill demands, giving you a competitive edge in the job market",
      "2. Quality Training Programs: Our training programs are meticulously designed to meet industry standards and are continuously updated to incorporate the latest advancements. We prioritize quality education and ensure that our trainers are experts in their respective fields, providing you with the best learning experience possible",
      ,
      "3. Personalized Approach: We believe in understanding your unique needs and aspirations to deliver personalized solutions. Our team takes the time to listen to your goals and aspirations, tailoring our training and job placement services to match your individual requirements. Your success is our priority",
      "4. Commitment to Success: We are dedicated to your success and strive to exceed your expectations. From the moment you join our training program to the point of securing a rewarding job, we provide continuous support and guidance. Our commitment to your professional growth remains unwavering",
    ],
    footer:
      "Take the first step towards a successful career by partnering with DALMAF. Contact us today to explore our training programs, job placement assistance, and career counselling services. Let us empower you to unlock your true potential and achieve your career aspirations.",
  },
  {
    title: "Definition of skilled training",
    points: [
      "Skilled training is defined as a concept that helps to create scope and opportunities for the employee by developing his talent and enhancing his skills and know-how. The emphasis of skilled training is to provide proper training that will support and guide the employees in their chosen field",
    ],
  },
  {
    title: "Meaning",
    subtitle: [
      "Skills training include training in both hard and soft skills and include training via case studies, practical experiences, brainstorming sessions, group discussions, games, etc. in",
    ],
    points: [
      "1. Employability skills",
      "2. Behavioural skills",
      "3. Management skills",
      "4. Personality development skills",
      "5. Positive thinking skills",
      "6. Technical skills",
      "7. Conflict resolution",
      "8. Critical thinking skills",
      "9. Communication skills",
      "10. Time management",
      "11. Work ethics",
      "12. Industry-specific skills",
      "13. Supervisory skills",
      "14. Teamwork",
      "15. Business acumen skills",
    ],
    footer:
      "The objective of offering the opportunity of skills training is to boost confidence, improve efficiency, increase productivity and give a proper direction to the employee. The development of skills is an important step that takes the employee nearer to their goals and makes them a perfect fit for the job",
  },
  {
    title: "Reasons for skilled training",
    points: [
      "There was a time when an employee was hired based on his knowledge, educational qualification, and work experience. Times have changed and rapid growth in the fields of technology has changed the hiring requirements of a company",
      "Even though an employee has solid technical skills and is proficient in soft skills the organization is still making skills training mandatory and the reason for such a step is-",
    ],
  },
  {
    subtitle: "1. The current employees are not skilled enough",
    points: [
      "In most cases, it is seen that the employees are not as proficient at handling their duties as the company would like them to be. The skill shortage poses a threat and the only solution is offering the necessary skills training program.",
      "This way the firm will be able to boost confidence and increase the appetite for knowledge by introducing its employees to new strategies, ideas, and know-how. The result will be favourable for both the employee and the company as the employee will now be adaptable to change and open for fresh challenges in the workplace.",
    ],
  },
  {
    subtitle: "2. Keep up-to-date with hard skills ",
    points: [
      "Advancement in science and technology has brought about numerous changes. New roles have emerged, and this requires advanced skills training because the previous skills and know-how are no longer sufficient to meet the rising demands of the workplace",
      "Organizations have started implementing continuous skills training programs because employee development and growth is the key to safeguarding their business interest",
    ],
  },
  {
    subtitle: "3. Soft skills are essential ",
    points: [
      "An essential reason for implementing a skills training program in the workplace is to make the employees proficient in soft skills. The number of recruits with soft skills is comparatively less and it becomes necessary for a firm to give them the training so that they can deal with other employees, clients, and customers with enhanced negotiation skills, emotional intelligence, and empathy",
    ],
  },
  {
    subtitle: "4. Skilled workforce leads to customer loyalty ",
    points: [
      "Technological advances have reshaped the consumer market and elevated customer expectations. They now expect premium services as their right. The organizations are introducing skills training programs so that the employees become adept at handling the customers and make their buying experiences memorable",
    ],
    footer:
      "A sharp and skilled workforce will be able to result in customer loyalty easily",
  },
];
const dataMatrimonialIssues = [
  {
    pagename: "Matrimonial Issues",
  },
  {
    title: "Matrimonial Issues",
    points: [
      "1. Matrimonial Issues in India refer to legal cases that arise out of disputes between married couples. These cases can include a variety of issues, such as divorce, child custody, maintenance, and property disputes",
      "2. In India, matrimonial cases are primarily governed by the Hindu Marriage Act, 1955, the Muslim Personal Law (Shariat) Application Act, 1937, and the Special Marriage Act, 1954. These laws provide guidelines for the legal process of separation and divorce, as well as the distribution of property, custody of children, and alimony",
      "3. Matrimonial cases in India are generally heard in family courts, which are specialized courts established to handle family law matters. Family courts are present in most of the cities and towns in India and have jurisdiction over a wide range of family law issues, including matrimonial cases",
      "4. The procedure for filing a matrimonial case in India typically involves filing a petition in the family court, which outlines the issues in dispute and the relief sought by the petitioner. The respondent then has an opportunity to respond to the petition and present their case. The court may hold hearings and allow the submission of evidence by both parties before rendering a judgment",
    ],
    footer:
      "Overall, matrimonial cases in India play an important role in providing a legal mechanism for resolving disputes between married couples. These cases can be emotionally and financially stressful, and the Indian legal system is designed to ensure that the parties involved are treated fairly and justly. Matrimonial cases in India help to ensure that disputes between married couples are resolved in a peaceful and fair manner, with the interests of both parties and their children taken into consideration",
  },
];
const dataRestitutionConjugalRights = [
  {
    pagename: "Restitution Conjugal Rights",
  },
  {
    title: "Restitution Conjugal Rights",
    points: [
      "1. Restitution of Conjugal Rights is a legal term used in India that refers to a type of matrimonial case filed by a spouse seeking the return of conjugal rights. It is a legal remedy available to married individuals who have been deserted or abandoned by their spouse.",
      "2. The concept of restitution of conjugal rights is based on the idea that marriage is a sacred union that should not be easily dissolved, and that spouses have a duty to live together and fulfill their marital obligations. If one spouse abandons or separates from the other without any legal justification, the other spouse can file a case seeking restitution of conjugal rights.",
      "3. The procedure for filing a case for restitution of conjugal rights involves filing a petition in the appropriate court, stating that the other spouse has abandoned or separated from the petitioner without any reasonable cause. The respondent spouse is then given an opportunity to respond to the petition and present their case.",
      "4. If the court finds that there has been an unjustified desertion or separation, it may issue a decree of restitution of conjugal rights, ordering the respondent spouse to return to the petitioner and fulfill their marital obligations. However, if the respondent spouse still refuses to return, the court cannot force them to do so and the petitioner may have to seek other legal remedies such as divorce.",
      "5. It is important to note that restitution of conjugal rights is a controversial issue in India, as some people argue that it violates an individual's right to personal liberty and autonomy. However, the Indian legal system still recognizes this as a valid legal remedy for married individuals seeking the return of their spouse and the restoration of their conjugal rights.",
    ],
  },
];
const dataJudicialSeparation = [
  {
    pagename: "Judicial Separation",
  },
  {
    title: "Judicial Separation",
    points: [
      "1. Judicial separation in India is a legal process in which a married couple can obtain a court order to live separately without dissolving their marriage. It is an alternative to divorce, and the couple remains legally married after obtaining a decree of judicial separation",
      "2.The Hindu Marriage Act, 1955, provides for the procedure of judicial separation in India. A petition for judicial separation can be filed by either spouse on the grounds of cruelty, desertion, adultery, or any other valid ground recognized by law. The petitioner must provide evidence to support their case, and the court will consider the evidence before granting the decree of judicial separation.",
      "3. Once a decree of judicial separation is granted, the spouses are no longer required to live together, and they are free to live separately. However, they remain legally married and are not free to remarry. The decree may also include provisions for maintenance, custody of children, and division of property.",
      "4. Judicial separation can be a useful option for couples who are not ready for divorce but need some time apart to resolve their differences or work on their marriage. It allows them to live separately and obtain some relief from the difficulties they are facing in their relationship, without dissolving the marriage altogether.",
      "5. It is important to note that judicial separation is a legal process and requires the intervention of the court. It is not the same as a trial separation, which is an informal arrangement between spouses to live separately for a period of time.",
    ],
  },
];
const dataDivorceinDifferentReligion = [
  {
    pagename: "Divorce in Different Religion",
  },
  {
    title: "Divorce in Different Religion",
    desc: [
      "In India, the concept of judicial separation is recognized by different personal laws that apply to different religions. The procedure and grounds for obtaining judicial separation may differ based on the personal law applicable to the couple. Here's a brief explanation of judicial separation for each religion in India:",
    ],
    points: [
      "1. Hinduism: In Hinduism, judicial separation is governed by the Hindu Marriage Act, 1955. Either spouse can file a petition for judicial separation on the grounds of cruelty, adultery, desertion, conversion, mental disorder, or any other ground recognized by law. The decree of judicial separation may also include provisions for maintenance, custody of children, and division of property",
      "2. Islam: In Islam, judicial separation is governed by the Dissolution of Muslim Marriage Act, 1939. A wife can file a petition for judicial separation on the grounds of cruelty, impotency, desertion, or any other ground recognized by Muslim law. The court may pass a decree of judicial separation if it is satisfied that the grounds for separation exist.",
      "3. Christianity: In Christianity, judicial separation is governed by the Indian Divorce Act, 1869. Either spouse can file a petition for judicial separation on the grounds of adultery, cruelty, desertion, or conversion. The court may pass a decree of judicial separation if it is satisfied that the grounds for separation exist.",
      "4. Sikhism: In Sikhism, judicial separation is governed by the Anand Marriage Act, 1909. Either spouse can file a petition for judicial separation on the grounds of adultery, cruelty, desertion, or any other ground recognized by law. The decree of judicial separation may also include provisions for maintenance, custody of children, and division of property.",
      "5. Parsi: In Parsi, judicial separation is governed by the Parsi Marriage and Divorce Act, 1936. Either spouse can file a petition for judicial separation on the grounds of adultery, cruelty, desertion, or any other ground recognized by law. The decree of judicial separation may also include provisions for maintenance, custody of children, and division of property.",
    ],
    footer:
      "Overall, the concept of judicial separation is recognized by different personal laws applicable to different religions in India. The grounds for obtaining judicial separation may differ based on the personal law applicable to the couple, but the procedure for obtaining the decree of judicial separation is similar across religions.",
  },
];
const dataDomesticViolenceIssues = [
  {
    pagename: "Domestic Violence Issues",
  },
  {
    title: "Domestic Violence Issues",
    points: [
      "1. Domestic violence cases in India are legal cases that arise out of acts of violence, abuse, or harassment that occur within the context of a domestic relationship. Domestic violence can be physical, sexual, emotional, or economic in nature, and can affect individuals of any gender or age group",
      "2. In India, domestic violence cases are governed by the Protection of Women from Domestic Violence Act, 2005. The Act provides for a legal framework to protect women from domestic violence and provides various remedies and reliefs to victims of domestic violence.",
      "3. The Act defines domestic violence as any act or omission that harms or injures a woman with the purpose of coercing her or any person related to her to meet any unlawful demand for any property or valuable security, or is likely to cause her physical or mental injury or harm. The Act recognizes several forms of domestic violence, including physical abuse, sexual abuse, emotional abuse, verbal abuse, and economic abuse.",
      "4. The procedure for filing a domestic violence case in India involves filing a complaint with the appropriate court or tribunal, which can be done by the victim or any person on her behalf. The court will then issue a notice to the accused and conduct an inquiry into the allegations. If the court finds that domestic violence has taken place, it may pass orders for protection, residence, and maintenance, among other things.",
      "5. The Protection of Women from Domestic Violence Act, 2005, is an important piece of legislation that provides legal protection to women from domestic violence in India. It recognizes the various forms of domestic violence and provides for a legal mechanism to seek redressal and relief for victims of domestic violence. The Act emphasizes the importance of prevention and intervention in cases of domestic violence and seeks to provide support and assistance to victims of domestic violence.",
    ],
  },
];
const dataSection7FamilyCourtActs = [
  {
    pagename: "Section 7 Family Court Acts",
  },
  {
    title: "Section 7 Family Court Acts",
    points: [
      "1. Section 7 of the Family Courts Act, 1984, outlines the jurisdiction and powers of family courts in India. It provides that a family court shall have jurisdiction over all suits and proceedings of a civil nature arising out of family disputes",
      "2. The family disputes covered under Section 7 include disputes between spouses, disputes between parents and children, and disputes related to guardianship and custody of children. These disputes may be related to maintenance, division of property, and other issues arising out of family relationships.",
      "3. Section 7 also provides for the powers of the family court, which include the power to summon and enforce the attendance of witnesses, the power to order the discovery and production of documents, and the power to examine witnesses under oath.",
      "4. The primary objective of the Family Courts Act is to provide speedy and effective resolution of family disputes, and Section 7 emphasizes the importance of the family court in achieving this objective. The family court is empowered to use its discretion in determining the most appropriate method of resolving a family dispute, including counselling, mediation, and conciliation.",
    ],
    footer:
      "Overall, Section 7 of the Family Courts Act plays a critical role in establishing the jurisdiction and powers of family courts in India, and in promoting the effective and speedy resolution of family disputes in a fair and just manner.",
  },
];
const dataGuardianshipChildCustody = [
  {
    pagename: "Guardianship & Child Custody",
  },
  {
    title: "Guardianship & Child Custody",
    points: [
      "1. Guardianship and custody cases in India are legal cases that involve disputes over the care and upbringing of minor children. These cases are governed by the Guardians and Wards Act, 1890, and the Hindu Minority and Guardianship Act, 1956, depending on the personal law applicable to the parties involved.",
      "2. Guardianship and custody cases can arise due to various reasons, such as divorce, separation, death of a parent, or when the parents are unable to provide proper care for the child. The dispute can be related to the custody of the child, visitation rights, and financial support.",
      "3. The procedure for filing a guardianship and custody case involves filing a petition in the appropriate court, which can be the district court, family court, or the high court, depending on the nature of the dispute and the jurisdiction of the court. The court will consider the evidence presented by both parties, including the welfare of the child, and make a decision based on the best interests of the child.",
      "4. The court may grant custody to one or both parents, or to a third party, such as a grandparent or a legal guardian. The court may also grant visitation rights to the non-custodial parent or other family members, and may order the payment of maintenance or support for the child.",
    ],
    footer:
      "Overall, guardianship and custody cases in India are important legal proceedings that aim to ensure the well-being and welfare of minor children. The Indian legal system is designed to provide a fair and just resolution to disputes related to the custody and care of children, with the interests of the child being the primary consideration in all decisions.",
  },
];
const dataAdoption = [
  {
    pagename: "Adoption",
  },
  {
    title: "Adoption",
    desc: [
      "Adoption cases in India involve the legal process of adopting a child and are governed by the relevant adoption laws in the country. Adoption is the legal process by which a person or a couple becomes the legal parent(s) of a child who is not biologically related to them.",
    ],
  },
  {
    desc: [
      "In India, the legal framework for adoption is provided by the Juvenile Justice (Care and Protection of Children) Act, 2015, and the Adoption Regulations, 2017. These laws set out the legal procedures and requirements for adoption, including the eligibility criteria for adoptive parents, the procedures for adoption, and the requirements for the welfare and protection of the child.",
    ],
  },
  {
    subtitle:
      "The adoption process in India involves several stages, including:",
    points: [
      "1. Application: Prospective adoptive parents must submit an application to the authorized adoption agency, along with the necessary documents and fees.",
      "2. Home Study: The authorized adoption agency will conduct a home study to assess the suitability of the prospective adoptive parents for adoption.",
      "3. Matching: The authorized adoption agency will match the child with the prospective adoptive parents based on various factors, including the age, health, and needs of the child, and the preferences of the adoptive parents.",
      "4. Legal Process: Once the child is matched with the adoptive parents, a legal process will be initiated to obtain an adoption order from the court.",
      "5. Post-Adoption Follow-Up: After the adoption is finalized, post-adoption follow-up visits will be conducted to ensure the welfare and protection of the child.",
    ],
    footer:
      "Adoption cases in India are critical legal proceedings that aim to ensure the welfare and protection of children who are in need of a permanent family. The Indian legal system is designed to ensure that the adoption process is fair, transparent, and in the best interests of the child.",
  },
];
const dataMaintenanceandAlimony = [
  {
    pagename: "Maintenance and Alimony",
  },
  {
    title: "Maintenance and Alimony",
    points: [
      "1. Maintenance and alimony cases in India refer to legal proceedings that are initiated to obtain financial support from a spouse or former spouse. Maintenance refers to the financial support that is provided by one spouse to the other during the subsistence of the marriage or after the dissolution of the marriage. Alimony, on the other hand, refers to the financial support that is provided by one spouse to the other after a divorce or separation.",
      "2. In India, maintenance and alimony cases are governed by various laws, depending on the personal law applicable to the parties involved. These laws include the Hindu Marriage Act, 1955, the Muslim Women (Protection of Rights on Divorce) Act, 1986, and the Code of Criminal Procedure, 1973, among others.",
      "3. The procedure for filing a maintenance or alimony case in India involves filing a petition in the appropriate court, which can be the family court, district court, or the high court, depending on the nature of the dispute and the jurisdiction of the court. The court will consider the evidence presented by both parties and make a decision based on the facts and circumstances of the case.",
      "4. The court may order the payment of maintenance or alimony to the spouse based on several factors, including the financial status of the parties, the needs of the spouse seeking maintenance, and the ability of the other spouse to pay. The court may also consider the length of the marriage, the standard of living of the parties, and the presence of any children.",
    ],
    footer:
      "Overall, maintenance and alimony cases in India are important legal proceedings that provide financial support to spouses or former spouses who may be in need of it. The Indian legal system is designed to provide a fair and just resolution to disputes related to maintenance and alimony, with the interests of both parties being taken into consideration.",
  },
];
const dataAnnulmentofMarriage = [
  {
    pagename: "Annulment of Marriage",
  },
  {
    title: "Annulment of Marriage",
    desc: [
      "Annulment of marriage cases in India refer to legal proceedings that are initiated to declare a marriage as null and void from its inception, thereby legally erasing it as if it never took place. In other words, an annulment is a legal procedure that cancels a marriage and declares it to be void ab initio (from the beginning). An annulment in India is different from a divorce, which is the legal termination of a valid marriage.",
    ],
  },
  {
    subtitle:
      "The grounds for annulment of marriage in India are limited and vary based on the personal law applicable to the parties involved. The grounds for annulment generally include:",
    points: [
      "1. Fraud: If a party has been induced into the marriage by fraud or misrepresentation, the marriage can be annulled.",
      "2. Coercion: If a party has been forced into the marriage under duress or coercion, the marriage can be annulled.",
      "3. Impotence: If either party is unable to consummate the marriage due to physical or psychological reasons, the marriage can be annulled.",
      "4. Consanguinity: If the parties are closely related by blood, the marriage can be annulled.",
      "5. Mental incapacity: If either party is mentally incapacitated or has a mental disorder, the marriage can be annulled",
    ],
    footer:
      "The procedure for filing an annulment of marriage case in India involves filing a petition in the appropriate court, which can be the district court, family court, or the high court, depending on the nature of the dispute and the jurisdiction of the court. The court will consider the evidence presented by both parties and make a decision based on the facts and circumstances of the case.",
  },
  {
    footer:
      "Overall, annulment of marriage cases in India are legal proceedings that are initiated to declare a marriage as null and void from its inception. The Indian legal system is designed to provide a fair and just resolution to disputes related to annulment of marriage, with the interests of both parties being taken into consideration.",
  },
];
const dataRentControlIssues = [
  {
    pagename: "Rent Control Issues",
  },
  {
    title: "Rent Control Issues",
    points: [
      "1. Rent control cases in India refer to legal disputes that arise between landlords and tenants in relation to the payment of rent and other related issues. Rent control laws in India regulate the relationship between landlords and tenants and aim to protect tenants from arbitrary eviction and excessive rent increases.",
      ,
      "2. The Rent Control Acts in India are state-specific, with each state having its own set of laws governing landlord-tenant relationships. Some common issues that can lead to rent control cases in India include disputes over the amount of rent to be paid, payment of security deposits, eviction notices, and maintenance and repair of the rental property.",
      "3. The process for resolving rent control cases in India varies depending on the nature and complexity of the dispute. In some cases, parties may be able to reach an amicable settlement through negotiation or mediation. However, in other cases, legal proceedings may be initiated by one or both parties.",
      "4. In rent control cases, the landlord or tenant can initiate legal proceedings by filing a petition before the Rent Control Court or Rent Control Tribunal, depending on the state-specific laws. The court or tribunal may then issue notice to the other party and conduct a hearing to consider the evidence and arguments presented by both parties.",
      "5. In rent control cases, the court or tribunal may issue orders related to payment of rent, eviction, or other relevant issues. The court or tribunal's decision may be appealed to a higher court if either party is dissatisfied with the outcome.",
      "6. Rent control cases in India provide an important mechanism for resolving disputes between landlords and tenants and ensuring that the rights of both parties are protected. The process ensures that a fair and transparent mechanism is in place for the resolution of rent control-related issues, which helps to promote the overall stability and growth of the Indian economy.",
    ],
  },
];
const dataLandlordTenantIssues = [
  {
    pagename: "Landlord – Tenant Issues ",
  },
  {
    title: "Landlord – Tenant Issues ",
    subtitle:
      "Landlord-tenant issues in India are common, and they can arise due to various reasons, such as disputes over rent, security deposits, maintenance, and repair of the rental property, eviction, and others. Some common landlord issues with tenants in India include:",
    points: [
      "1. Non-payment of rent: The tenant may fail to pay rent on time or may not pay rent at all, leading to disputes with the landlord.",
      "2. Property damage: The tenant may cause damage to the rental property, which can lead to disputes over repair and maintenance expenses.",
      "3. Violation of rental agreement: The tenant may violate the terms of the rental agreement, such as subletting the property without permission or using the property for illegal activities.",
      "4. Illegal possession: The tenant may refuse to vacate the property even after the rental agreement has expired or after receiving a notice for eviction.",
      "To resolve landlord-tenant issues in India, the first step is to communicate with the other party and try to reach an amicable settlement. If that is not possible, legal proceedings can be initiated by either party by filing a petition before the Rent Control Court or Rent Control Tribunal, depending on the state-specific laws. The court or tribunal may then issue notice to the other party and conduct a hearing to consider the evidence and arguments presented by both parties.",
      "In landlord-tenant issues, the court or tribunal may issue orders related to payment of rent, eviction, or other relevant issues. The court or tribunal's decision may be appealed to a higher court if either party is dissatisfied with the outcome.",
    ],
    footer:
      "Overall, resolving landlord-tenant issues in India requires a fair and transparent mechanism that protects the rights of both parties. Proper communication, negotiation, and adherence to the laws and regulations can help in preventing and resolving such disputes in a timely and efficient manner.",
  },
];
const dataTenantLandlordIssues = [
  {
    pagename: "Tenant – Landlord Issues",
  },
  {
    title: "Tenant – Landlord Issues",
    subtitle:
      "Tenant-landlord issues in India are common, and they can arise due to various reasons, such as disputes over rent, security deposits, maintenance and repair of the rental property, eviction, and others. Some common tenant issues with landlords in India include:",
    points: [
      "1. Rent hikes: The landlord may increase the rent arbitrarily, leading to disputes with the tenant.",
      "2. Security deposit: The landlord may refuse to return the security deposit to the tenant or may deduct an unreasonable amount from it.",
      "3. Property maintenance: The landlord may not maintain the property adequately, leading to issues with the tenant's comfort and safety.",
      "4. Illegal eviction: The landlord may try to evict the tenant illegally or without proper notice.",
      "To resolve tenant-landlord issues in India, the first step is to communicate with the other party and try to reach an amicable settlement. If that is not possible, legal proceedings can be initiated by either party by filing a petition before the Rent Control Court or Rent Control Tribunal, depending on the state-specific laws. The court or tribunal may then issue notice to the other party and conduct a hearing to consider the evidence and arguments presented by both parties.",
      "In tenant-landlord issues, the court or tribunal may issue orders related to payment of rent, security deposit, eviction, or other relevant issues. The court or tribunal's decision may be appealed to a higher court if either party is dissatisfied with the outcome.",
    ],
    footer:
      "Overall, resolving tenant-landlord issues in India requires a fair and transparent mechanism that protects the rights of both parties. Proper communication, negotiation, and adherence to the laws and regulations can help in preventing and resolving such disputes in a timely and efficient manner.",
  },
];
const dataEmployeeandEmployerIssues = [
  {
    pagename: "Employee and Employer Issues",
  },
  {
    title: "Employee and Employer Issues",
    points: [
      "1. Employee and employer cases in India refer to legal disputes that arise between employers and employees in relation to employment-related issues. These cases may be related to the employment contract, wages, working conditions, discrimination, and termination of employment, among others.",
      "2. The primary laws governing employment in India include the Industrial Disputes Act, 1947, the Minimum Wages Act, 1948, the Payment of Wages Act, 1936, the Employee Provident Fund and Miscellaneous Provisions Act, 1952, and the Maternity Benefit Act, 1961.",
      "3. Employee and employer cases may be initiated by either party by filing a petition before the appropriate court or tribunal. For example, labour disputes are generally heard by the Labour Court or Industrial Tribunal, while disputes related to wages or payment of gratuity are heard by the appropriate labour commissioner or authority.",
      "4. In employee and employer cases, the court or tribunal may issue orders related to payment of wages, reinstatement of employment, compensation, or other relevant issues. The court or tribunal's decision may be appealed to a higher court if either party is dissatisfied with the outcome.",
    ],
    footer:
      "Overall, resolving employee and employer cases in India requires a fair and transparent mechanism that protects the rights of both parties. Proper communication, negotiation, and adherence to the laws and regulations can help in preventing and resolving such disputes in a timely and efficient manner.",
  },
];
const dataESIIssues = [
  {
    pagename: "ESI Issues",
  },
  {
    title: "ESI Issues",
    points: [
      "1. ESI (Employee State Insurance) issues in India refer to legal disputes or grievances that arise between employees and their employers regarding the Employees' State Insurance Act, 1948, and the corresponding scheme. The ESI Act is a social security legislation that provides medical benefits, cash benefits, and other facilities to employees who are covered under the scheme.",
      "2. Common ESI issues in India may include non-payment or delayed payment of contributions by employers, disputes over the amount of contribution, and grievances related to the eligibility criteria and benefits under the scheme.",
      "3. To address ESI issues, employees can file a complaint or grievance with their employer, or with the Employees' State Insurance Corporation (ESIC). The ESIC provides a grievance redressal mechanism through its online portal, which allows employees to lodge complaints and track their status.",
      "4. Employers are required to pay their employees' contributions to the ESIC on a monthly basis, and any delay or non-payment can result in legal action against them. The ESIC has the power to take action against employers who fail to comply with the provisions of the Act, including the power to levy penalties and interest on delayed payments.",
    ],
    footer:
      "Overall, addressing ESI issues in India requires a transparent and efficient mechanism that protects the rights of employees and ensures compliance by employers. Proper communication, negotiation, and adherence to the laws and regulations can help in preventing and resolving ESI issues in a timely and efficient manner.",
  },
];
const dataProfessionalTax = [
  {
    pagename: "Professional Tax",
  },
  {
    title: "Professional Tax",
    points: [
      "1. Professional tax issues in India refer to legal disputes or grievances that arise between professionals and their employers regarding the payment of professional tax, which is a tax levied by state governments on income earned by professionals such as doctors, lawyers, engineers, and consultants.",
      "2. Professional tax is a state subject and the rates and rules vary across states. Employers are responsible for deducting the professional tax from the salaries of employees and paying it to the state government. However, issues may arise if the employer fails to deduct or pay the professional tax, or if there are disputes over the amount of professional tax to be paid.",
      "3. To address professional tax issues, professionals can file a complaint or grievance with their employer or with the appropriate state government authority. The state government may have a designated department or agency that is responsible for the administration and enforcement of professional tax laws.",
      "4. Employers who fail to comply with the provisions of the professional tax laws may be subject to penalties and legal action by the state government. The state government may also have the power to initiate recovery proceedings to collect any unpaid professional tax.",
    ],
    footer:
      "Overall, addressing professional tax issues in India requires a transparent and efficient mechanism that protects the rights of professionals and ensures compliance by employers. Proper communication, negotiation, and adherence to the laws and regulations can help in preventing and resolving professional tax issues in a timely and efficient manner.",
  },
];
const dataSalaryIssueswithEmployer = [
  {
    pagename: "Salary Issues with Employer",
  },
  {
    title: "Salary Issues with Employer",
    desc: [
      "Employee salary issues with employers in India refer to a range of disputes or grievances that arise due to non-payment or underpayment of salaries, bonuses, or other benefits. These issues can affect the financial stability and overall wellbeing of employees, and can result in stress, frustration, and other negative consequences.",
    ],
  },
  {
    subtitle: "Common salary issues faced by employees in India include:",
    points: [
      "1. Non-payment or delayed payment of salaries, bonuses, or other benefits",
      "2. Deductions made from salaries without proper explanation or consent",
      "3. Disputes over the calculation of salaries or the terms of employment contracts",
      "4. Non-compliance with labour laws related to minimum wages, overtime, and other benefits",
      "5. Retaliation or discrimination against employees who raise salary issues or report workplace issues.",
      "Employees who face salary issues with their employers in India can seek legal remedies to address these issues. This may involve filing a complaint with the Labour Department, initiating legal action, or approaching the Labour Court for resolution. Mediation or arbitration can also be used to resolve salary issues without going to court.",
    ],
    footer:
      "To address salary issues, employees should maintain accurate records of their salaries, bonuses, and other benefits. They should also be aware of their rights under labor laws and their employment contracts, and seek legal advice if necessary. Employers should ensure compliance with labor laws and establish transparent and fair policies for salary and benefit payments to avoid any disputes or grievances.",
  },
];
const dataRemediesforSalaryIssuesforEmployee = [
  {
    pagename: "Remedies for Salary Issues for Employee",
  },
  {
    title: "Remedies for Salary Issues for Employee",
    desc: [
      "If an employee has salary issues with their employer in India, there are several legal remedies available to them. These remedies can vary depending on the nature of the issue and the terms of the employment agreement.",
    ],
  },
  {
    subtitle:
      "Some of the common legal remedies available to employees for salary issues in India include:",
    points: [
      "1. Filing a complaint with the Labour Department: The employee can file a complaint with the Labour Department or the relevant government authority to address the salary issues. The department will investigate the issue and take appropriate action against the employer.",
      "2. Initiating legal action: The employee can initiate legal action against the employer by filing a civil suit for recovery of salary or breach of employment agreement. The employee may also file a criminal complaint if the employer has committed a criminal offense.",
      "3. Approaching the Labour Court: The employee can approach the Labor Court to resolve salary issues. The Labour Court has the power to order the employer to pay the salary and other benefits owed to the employee.",
      "4. Seeking mediation or arbitration: The employee can opt for mediation or arbitration to resolve the salary issues with the employer. Mediation and arbitration are alternative dispute resolution mechanisms that can help parties resolve their disputes without going to court.",
    ],
    footer:
      "It is recommended that the employee seek legal advice from a qualified lawyer to determine the best legal remedy for their salary issues with the employer. The employee should also maintain records and evidence of their salary and benefits to support their claim.",
  },
];
const dataRemediesforDelayinSalaryIssuesforEmployee = [
  {
    pagename: "Remedies for Delay in Salary Issues for Employee",
  },
  {
    title: "Remedies for Delay in Salary Issues for Employee",
    points: [
      "Delay in salary by the employer is a common issue faced by employees in India. Such cases may arise due to various reasons such as financial difficulties faced by the company, errors in payroll processing, disputes over the terms of employment contract, etc.",
      "Delay in salary by the employer cases in India may lead to financial distress for employees, who may have to face difficulties in meeting their daily expenses, paying bills, and other financial obligations.",
    ],
  },
  {
    subtitle:
      "Employees facing delay in salary by the employer can take the following steps:",
    points: [
      "1. Communicate with the employer: The employee can try to communicate with the employer and find out the reasons for the delay in salary. The employee can request the employer to expedite the payment of salary.",
      "2. Seek legal advice: If communication with the employer does not work, the employee can seek legal advice from a labor lawyer. The lawyer can advise on the legal options available to the employee.",
      "3. Approach labor authorities: The employee can approach the labor authorities, such as the Labor Commissioner, and file a complaint against the employer for delay in payment of salary. The labor authorities can help in resolving the matter through conciliation and mediation.",
      "4. File a civil suit: The employee can file a civil suit against the employer for breach of contract and claim damages.",
    ],
    footer:
      "It is important for employers to ensure timely payment of salaries to employees to avoid such disputes. Employees should keep a record of their salary payments and employment contract to help them in case of any dispute with the employer.",
  },
];
const dataMentalHarassment = [
  {
    pagename: "Mental Harassment",
  },
  {
    title: "Mental Harassment",
    points: [
      "Mental harassment in the workplace in India refers to any behaviour that causes emotional or psychological distress to an employee. Such harassment can take many forms, including verbal abuse, threats, intimidation, isolation, exclusion, or discrimination based on factors such as gender, caste, religion, race, or sexual orientation",
      "In India, the law recognizes mental harassment in the workplace as a form of sexual harassment under the Sexual Harassment of Women at Workplace (Prevention, Prohibition and Redressal) Act, 2013. This act defines sexual harassment to include any unwelcome verbal, non-verbal, or physical conduct of a sexual nature that creates an intimidating, hostile, or offensive work environment.",
    ],
  },
  {
    subtitle:
      "Employers are required to take preventive and corrective measures to address mental harassment in the workplace, including:",
    points: [
      "1. Creating a policy against sexual harassment and making it available to all employees",
      "2. Establishing an Internal Complaints Committee (ICC) to receive and investigate complaints of sexual harassment",
      "3. Providing support and protection to complainants against retaliation or victimization",
      "4. Taking disciplinary action against perpetrators of sexual harassment.",
      "Employees who face mental harassment in the workplace in India can file a complaint with the ICC or the relevant government authority. They can also seek legal remedies under civil or criminal law for compensation or damages.",
    ],
    footer:
      "It is recommended that employees maintain a record of any incidents of mental harassment, including the time, date, location, and witnesses present. Seeking support from colleagues, friends, or a professional counsellor can also help employees to cope with the emotional impact of mental harassment in the workplace.",
  },
];
const dataSexualHarassmentinWorkplace = [
  {
    pagename: "Sexual Harassment in Workplace",
  },
  {
    title: "Sexual Harassment in Workplace",
    points: [
      "1. Sexual harassment in the workplace is a serious issue in India that affects many employees, particularly women. It refers to any unwelcome behaviour of a sexual nature that creates an intimidating, hostile, or offensive work environment. Such behaviour can take many forms, including physical, verbal, or nonverbal actions such as unwanted touching, sexual comments or jokes, and gestures or facial expressions of a sexual nature.",
      "2. In India, the Sexual Harassment of Women at Workplace (Prevention, Prohibition and Redressal) Act, 2013, is the primary legislation that addresses sexual harassment in the workplace. The act defines sexual harassment as any unwelcome verbal, nonverbal, or physical conduct of a sexual nature that has the purpose or effect of violating the dignity of a woman, or creating an intimidating, hostile, degrading, humiliating, or offensive environment for her.",
      "3. Under the act, employers are required to establish an Internal Complaints Committee (ICC) to receive and investigate complaints of sexual harassment. The ICC must be headed by a woman and include at least two members from among employees who are committed to women's rights or have experience in social work or the field of women's welfare.",
      "4. Employees who experience sexual harassment in the workplace can file a complaint with the ICC or the relevant government authority. The act provides protection to complainants against retaliation or victimization and requires employers to take appropriate disciplinary action against perpetrators of sexual harassment.",
    ],
    footer:
      "It is recommended that employees maintain records of any incidents of sexual harassment, including the time, date, location, and witnesses present. Seeking support from colleagues, friends, or a professional counsellor can also help employees to cope with the emotional impact of sexual harassment in the workplace.",
  },
];
const dataRemedyforSexualHarassmentinWorkplace = [
  {
    pagename: "Remedy for Sexual Harassment in Workplace",
  },
  {
    title: "Remedy for Sexual Harassment in Workplace",
    desc: [
      "The Sexual Harassment of Women at Workplace (Prevention, Prohibition, and Redressal) Act, 2013 provides a legal framework for the prevention and redressal of sexual harassment in the workplace in India. The act recognizes the right of every woman to a safe workplace and provides a mechanism for redressal in case of sexual harassment.",
    ],
  },
  {
    subtitle:
      "The remedies available to a victim of sexual harassment in the workplace in India include:",
    points: [
      "1. Filing a complaint with the Internal Complaints Committee (ICC): The victim can file a complaint of sexual harassment with the ICC of the organization. The ICC is required to conduct an inquiry and provide a report within 90 days of receiving the complaint. The ICC can recommend disciplinary action against the perpetrator and/or provide monetary compensation to the victim.",
      "2. Filing a complaint with the Local Complaints Committee (LCC): If the organization does not have an ICC or if the complaint is against the employer, the victim can file a complaint with the LCC of the district. The LCC is required to conduct an inquiry and provide a report within 60 days of receiving the complaint. The LCC can recommend disciplinary action against the perpetrator and/or provide monetary compensation to the victim.",
      "3. Filing a complaint with the police: If the victim wants to pursue criminal action against the perpetrator, they can file a complaint with the police. The police are required to conduct an investigation and file a charge sheet if they find sufficient evidence. The perpetrator can be punished with imprisonment and/or fine under the Indian Penal Code.",
      "4. Filing a complaint with the court: The victim can file a civil suit for damages against the perpetrator in the court. The court can award compensation to the victim for the harm suffered due to sexual harassment.",
    ],
    footer:
      "It is recommended that the victim maintains a record of the incidents of sexual harassment, including the date, time, location, and witnesses present. Seeking legal advice from a qualified lawyer can also help the victim to understand their legal rights and options to address sexual harassment in the workplace.",
  },
];
const dataEmployeeNoticePeriodIssues = [
  {
    pagename: "Employee Notice Period Issues",
  },
  {
    title: "Employee Notice Period Issues",
    desc: [
      "Employee notice period issues in India refer to disputes or grievances that arise between an employee and their employer over the notice period requirement mentioned in the employment agreement. The notice period is the time period an employee must serve before resigning from their job or being terminated by their employer.",
    ],
  },
  {
    subtitle:
      "Some common issues related to the notice period faced by employees in India include:",
    points: [
      "1. Employer not honouring the notice period requirement in the employment agreement",
      "2. Employer forcing the employee to serve a longer notice period than mentioned in the employment agreement",
      "3. Employee not serving the notice period as required by the employment agreement",
      "4. Disputes over the calculation of salary during the notice period",
      "5. Non-payment of salary or benefits during the notice period.",
      "To address notice period issues, employees should refer to their employment agreement and follow the notice period requirement as mentioned. If there is any dispute or grievance related to the notice period, the employee should first communicate with the employer and try to resolve the issue amicably.",
      "If the employer does not honour the notice period requirement, the employee can approach the Labour Department or the relevant government authority to file a complaint. The employee can also initiate legal action or approach the Labour Court to resolve the issue.",
    ],
    footer:
      "It is recommended that employees maintain records and evidence of their employment agreement, communication with the employer, and any other relevant information related to the notice period issue. Seeking legal advice from a qualified lawyer can also help employees to understand their legal rights and options to address notice period issues.",
  },
];
const dataRefusaltoGiveOriginalCertificates = [
  {
    pagename: "Refusal to Give Original Certificates",
  },
  {
    title: "Refusal to Give Original Certificates",
    points: [
      "1. Refusal by an employer to return original certificates to an employee is a violation of the employee's legal rights. When an employee joins an organization, they are required to submit certain original certificates such as educational certificates, experience certificates, and other relevant documents. The employer may keep these certificates for record-keeping purposes or verification purposes.",
      "2. However, the employer is legally bound to return the original certificates to the employee upon their request. If the employer refuses to return the original certificates to the employee, it can create significant problems for the employee. The employee may need the original certificates for various purposes such as further studies, job applications, or government-related work.",
      "3. In India, the refusal to return original certificates to an employee is a violation of Section 14 of the Payment of Wages Act, 1936, which mandates that every employer shall provide the employee with a wage slip and return any certificate or document submitted by the employee as proof of eligibility for employment.",
      "4. An employee who faces refusal by their employer to return original certificates may take legal recourse. The employee can approach the labor commissioner or the civil court to file a complaint against the employer for violating their legal rights. The employee may also seek damages or compensation for any loss suffered due to the employer's refusal to return their original certificates. It is advisable for employees to keep a copy of all their original certificates for their records.",
    ],
  },
];
const dataGratuityProblem = [
  {
    pagename: "Gratuity Problem",
  },
  {
    title: "Gratuity Problem",
    desc: [
      "Gratuity is a form of retirement benefit paid to an employee in India upon completion of five years of continuous service in an organization. It is governed by the Payment of Gratuity Act, 1972.",
    ],
  },
  {
    subtitle:
      "Gratuity issues in India may arise due to various reasons such as:",
    points: [
      "1. Non-payment of gratuity: If an employee who has completed five years of continuous service is not paid gratuity by the employer, the employee may file a complaint with the controlling authority appointed under the Payment of Gratuity Act.",
      "2. Calculation of gratuity: Sometimes, disputes may arise over the calculation of gratuity payable to an employee. This may happen when there is a disagreement over the salary component on which gratuity is to be calculated or when there is confusion over the formula used for the calculation. In such cases, the employee can approach the controlling authority appointed under the Payment of Gratuity Act for resolution of the dispute.",
      "3. Delay in payment of gratuity: Employers are required to pay gratuity to employees within 30 days from the date of separation from service. If an employer fails to pay gratuity within the specified time frame, the employee can approach the controlling authority for redressal.",
      "4. Dispute over eligibility for gratuity: In some cases, there may be a dispute over the eligibility of an employee for gratuity. For example, if an employee has resigned before completing five years of continuous service, the employer may refuse to pay gratuity. In such cases, the employee can approach the controlling authority for resolution of the dispute.",
    ],
    footer:
      "It is important for both employers and employees to be aware of the provisions of the Payment of Gratuity Act to avoid disputes and ensure that gratuity issues are resolved in a timely and fair manner.",
  },
];
const dataNotAcceptingtheResignationbytheEmployer = [
  {
    pagename: "Not Accepting the Resignation by the Employer",
  },
  {
    title: "Not Accepting the Resignation by the Employer",
    desc: [
      "Sometimes, an employer may refuse to accept an employee's resignation despite the employee having given due notice period as required under the employment contract or applicable laws in India. This can create a dispute between the employer and the employee.",
    ],
  },
  {
    subtitle:
      "Not accepting the resignation by the employer cases in India may arise due to various reasons such as:",
    points: [
      "1. Contractual obligations: In some cases, the employment contract may contain clauses that require the employer to accept the resignation of an employee only after fulfilling certain obligations, such as completing a particular project or serving a notice period.",
      "2. Dispute over notice period: The employer may dispute the notice period served by the employee and may refuse to accept the resignation until the notice period is completed as per the terms of the employment contract.",
      "3. Retention of key employees: Employers may refuse to accept the resignation of key employees, especially in critical or sensitive roles, to avoid disruption to business operations.",
      "4. Financial obligations: The employer may refuse to accept the resignation if the employee has outstanding dues or liabilities towards the company.",
    ],
    footer:
      "In case an employer refuses to accept an employee's resignation, the employee can try to resolve the matter through communication and negotiation. If the issue cannot be resolved amicably, the employee may approach the labour authorities or the court for redressal. It is important for both the employer and the employee to follow the terms of the employment contract and applicable laws in India to avoid disputes and ensure smooth functioning of the workplace.",
  },
];

const dataSessionCase = [
  {
    pagename: "Session Case Significance",
  },
  {
    title: "Session Case Significance",
    points: [
      "1. In India, a Sessions Case refers to a criminal case that is tried before a Sessions Court. A Sessions Court is a court of law that has the power to hear and try serious criminal offenses such as murder, rape, and other similar offenses that are punishable by imprisonment for a term of more than seven years.",
      "2. Sessions Courts are presided over by a Sessions Judge, who is appointed by the State Government. The Sessions Judge has the power to impose the maximum sentence of imprisonment that is prescribed by law for the offense that is being tried.",
      "3. The trial of a Sessions Case involves several stages, including framing of charges, examination of witnesses, recording of evidence, arguments by the prosecution and the defense, and finally, the delivery of the verdict. The accused in a Sessions Case has the right to a fair trial and the presumption of innocence until proven guilty.",
    ],
  },
];
const dataIPRResolutions = [
  {
    pagename: "IPR Resolutions ",
  },
  {
    title: "IPR Resolutions ",
    points: [
      "IPR (Intellectual Property Rights) refers to the legal rights that are granted to individuals or entities for their creations or inventions that are a result of their intellect or creativity. Intellectual property includes patents, trademarks, copyrights, industrial designs, geographical indications, and trade secrets. These rights enable the creators to control and commercialize their creations, and prevent others from using them without their consent.",
      "In India, the legal framework for intellectual property is governed by various acts, including the Patents Act, the Trade Marks Act, the Copyright Act, the Geographical Indications of Goods (Registration and Protection) Act, and the Designs Act. The main objective of these acts is to provide protection to the creators of intellectual property and encourage innovation, creativity, and economic growth in the country.",
      "The Indian government has also established institutions such as the Intellectual Property India, which is responsible for the administration of the intellectual property rights system in India, and the National Intellectual Property Rights Policy, which aims to create a comprehensive and dynamic intellectual property ecosystem in the country.",
    ],
  },
];
const dataTrademarkInfringement = [
  {
    pagename: "Trademark Infringement",
  },
  {
    title: "Trademark Infringement",
    points: [
      "Trademark infringement cases in India arise when someone uses a trademark that is identical or similar to a registered trademark without the owner's permission or authorization. A trademark is a distinctive symbol, logo, design, word, or phrase that is used by a business or an individual to identify and distinguish their products or services from those of others.",
      "Under the Indian Trademarks Act, 1999, a trademark owner has the exclusive right to use their trademark and prevent others from using it without their consent. Any unauthorized use of a trademark that causes confusion among consumers or is likely to dilute the distinctive character of the trademark is considered trademark infringement.",
      "To prove trademark infringement in India, the trademark owner must demonstrate that the defendant's use of the mark is likely to cause confusion among consumers or dilute the distinctive character of the trademark. The trademark owner can file a lawsuit in the appropriate court and seek various remedies, including injunctions, damages, and accounts of profits.",
      "Trademark infringement cases in India can be complex and require expert legal advice. It is advisable for businesses and individuals to register their trademarks and take steps to protect their intellectual property rights to avoid trademark infringement disputes.",
    ],
  },
];
const dataCopyrightInfringementCases = [
  {
    pagename: "Copyright Infringement Cases",
  },
  {
    title: "Copyright Infringement Cases",
    points: [
      "Copyright infringement cases in India arise when someone uses, reproduces, distributes, or displays a copyrighted work without the owner's permission or authorization. A copyright is a legal right granted to the creator of an original work of literature, music, film, art, or other creative works to control how their work is used and prevent others from using it without their consent.",
      "Under the Indian Copyright Act, 1957, a copyright owner has the exclusive right to reproduce, distribute, perform, display, or make derivative works based on their copyrighted work. Any unauthorized use of a copyrighted work without the owner's consent is considered copyright infringement.",
      "To prove copyright infringement in India, the copyright owner must demonstrate that the defendant has used their copyrighted work without authorization and that the use falls within the scope of the exclusive rights granted to the copyright owner. The copyright owner can file a lawsuit in the appropriate court and seek various remedies, including injunctions, damages, and accounts of profits.",
      "Copyright infringement cases in India can be complex and require expert legal advice. It is advisable for creators of original works to register their copyright and take steps to protect their intellectual property rights to avoid copyright infringement disputes.",
    ],
  },
];
const dataDesignInfringement = [
  {
    pagename: "Design Infringement",
  },
  {
    title: "Design Infringement",
    points: [
      "1. Design infringement cases in India arise when someone copies or imitates a design that has been registered by someone else without their permission or authorization. A design refers to the visual appearance of a product or an article that results from the features of shape, configuration, pattern, ornamentation, or composition of lines or colours.",
      "2. Under the Indian Designs Act, 2000, a design owner has the exclusive right to use their design and prevent others from using it without their consent. Any unauthorized use of a registered design that is identical or similar to the registered design is considered design infringement.",
      "3. To prove design infringement in India, the design owner must demonstrate that the defendant has used their registered design without authorization and that the use falls within the scope of the exclusive rights granted to the design owner. The design owner can file a lawsuit in the appropriate court and seek various remedies, including injunctions, damages, and accounts of profits.",
      "4. Design infringement cases in India can be complex and require expert legal advice. It is advisable for businesses and individuals to register their designs and take steps to protect their intellectual property rights to avoid design infringement disputes.",
    ],
  },
];
const dataTradeSecretInfringement = [
  {
    pagename: "Trade Secret Infringement ",
  },
  {
    title: "Trade Secret Infringement ",
    points: [
      "1. Trade secret infringement in India occurs when someone discloses or uses a trade secret without the owner's permission or authorization. A trade secret refers to confidential business information that gives a company a competitive advantage, such as customer lists, manufacturing processes, marketing strategies, or technical know-how.",
      "2. Under the Indian law, trade secrets are not registered or protected by any specific legislation, but they are considered to be a form of intellectual property. Trade secret owners have the exclusive right to use and control their trade secrets and prevent others from using them without their consent.",
      "3. To prove trade secret infringement in India, the trade secret owner must demonstrate that the defendant has used their trade secret without authorization and that the use has caused harm to the trade secret owner's business. The trade secret owner can file a lawsuit in the appropriate court and seek various remedies, including injunctions, damages, and accounts of profits.",
      "4. Trade secret infringement cases in India can be complex and require expert legal advice. It is advisable for businesses and individuals to take measures to protect their trade secrets and avoid trade secret infringement disputes. This may involve confidentiality agreements, employee training, and security measures to safeguard confidential information.",
    ],
  },
];
const dataTribunalsResolution = [
  {
    pagename: "Tribunals Resolution",
  },
  {
    title: "Tribunals Resolution",
    points: [
      "Tribunals in India are specialized quasi-judicial bodies that are established to provide an efficient and speedy resolution of disputes and grievances arising in various sectors of the economy. These tribunals have been created to address the shortcomings of the traditional court system, which is often burdened with a large number of cases and is perceived to be slow and expensive.",
      "The tribunals in India are established under specific laws and are empowered to adjudicate on disputes related to their respective sectors. The decisions of these tribunals are binding and can be appealed to the higher courts.",
    ],
  },
];

const dataWorkplaceSafetyIssues = [
  {
    pagename: "Workplace Safety Issues",
  },
  {
    desc: [
      "Workplace safety issues in India refer to the risks and hazards that workers may face in their workplaces, which can cause physical or mental harm to them. Workplace safety is a crucial aspect of employee welfare and is regulated by various laws and regulations in India.",
    ],
  },
  {
    subtitle: "Common workplace safety issues in India include:",
    points: [
      "1. Physical hazards: These include risks such as exposure to harmful chemicals, noise pollution, electrical hazards, unsafe working conditions, and lack of personal protective equipment.",
      "2. Psychosocial hazards: These include risks such as workplace bullying, harassment, and discrimination, which can affect the mental health and well-being of employees.",
      "3. Ergonomic hazards: These include risks such as poorly designed workstations, repetitive motion injuries, and back pain due to incorrect posture.",
      "4. Biological hazards: These include risks such as exposure to infectious diseases and biological agents in certain workplaces such as healthcare, laboratories, and animal husbandry.",
      "To address workplace safety issues in India, employers are required to comply with various laws and regulations such as the Factories Act, the Mines Act, and the Building and Other Construction Workers Act. Employers should provide a safe working environment to employees, conduct regular safety audits, and provide training to employees on safety measures.",
    ],
    footer:
      "Employees can also play an important role in ensuring workplace safety by reporting any hazards or risks to their employers and following safety protocols and guidelines.",
  },
];
const dataWrongfulTermination = [
  {
    pagename: "Wrongful Termination",
  },
  {
    desc: [
      "Wrongful termination refers to the act of terminating an employee's employment contract without any valid reason or in violation of the terms of the employment agreement or the law. In India, wrongful termination is considered a violation of the employee's legal rights and can result in legal action against the employer",
    ],
  },
  {
    subtitle:
      "The following are some of the common forms of wrongful termination in India:",
    points: [
      "1. Breach of employment agreement: Employers may terminate an employee's contract in violation of the terms of the employment agreement. For example, if the agreement specifies a notice period for termination, but the employer terminates the employee without providing the required notice, it would be considered wrongful termination.",
      "2. Discrimination: Employers may not terminate an employee's contract based on their race, gender, religion, or other personal characteristics. Discriminatory termination is illegal under the Indian Constitution and can result in legal action against the employer.",
      "3. Retaliation: Employers may not terminate an employee's contract as a form of retaliation for the employee engaging in protected activities, such as filing a complaint of harassment or discrimination.",
      "4. Failure to follow due process: Employers must follow proper procedures while terminating an employee's contract, such as conducting an investigation, providing the employee with an opportunity to respond, and issuing a written termination notice. Failure to follow due process can result in a wrongful termination claim.",
    ],
    footer:
      "Employees who believe they have been wrongfully terminated can seek legal recourse by filing a complaint with the labour authorities or approaching a labour lawyer to file a civil suit against the employer. The employee may be entitled to compensation for the losses suffered as a result of the wrongful termination.",
  },
];
const dataRemedyforWrongfulTermination = [
  {
    pagename: "Remedy for Wrongful Termination",
  },
  {
    subtitle:
      "Employees who have been wrongfully terminated in India may seek legal remedies to address the injustice. The following are some of the remedies available for wrongful termination:",
    points: [
      "1. Reinstatement: An employee who has been wrongfully terminated may seek reinstatement, i.e., the restoration of their job position and benefits as they were before the wrongful termination. However, reinstatement may not always be possible or feasible, especially if there has been a breakdown of trust and communication between the employer and the employee.",
      "2. Compensation: An employee who has been wrongfully terminated may be entitled to receive compensation for the losses suffered as a result of the termination. This compensation may include back wages, benefits, and damages for emotional distress, among other things.",
      "3. Legal action: The employee may approach the labour authorities, such as the Labour Commissioner or the Assistant Labour Commissioner, and file a complaint against the employer for wrongful termination. The employee may also file a civil suit against the employer seeking reinstatement or compensation.",
      "4. Negotiated settlement: The employee and the employer may agree to a negotiated settlement outside of the legal system. This may involve a financial settlement or other forms of compensation for the employee.",
    ],
    footer:
      "It is important for employees to seek legal advice from a labour lawyer to understand their rights and the available remedies in case of wrongful termination. Employers should also ensure that they follow the due process of law and have valid reasons for terminating an employee's contract to avoid potential legal liabilities.",
  },
];
const dataViolationofPrivacythings = [
  {
    pagename: "Violation of Privacy things in Workplace",
  },
  {
    subtitle:
      "The violation of privacy in the workplace in India refers to any unwarranted intrusion into an employee's personal space, privacy, or personal information without their consent. The following are some examples of privacy violations that may occur in the workplace:",
    points: [
      "1. Monitoring of electronic communication: Monitoring an employee's emails, messages, or browsing activity without their consent.",
      "2. Surveillance: Secretly recording or monitoring an employee's conversations or activities.",
      "3. Disclosure of personal information: Sharing an employee's personal information without their consent, such as their medical records or financial information.",
      "4. Background checks: Conducting background checks on employees without their knowledge or consent.",
      "5. Drug and alcohol testing: Conducting drug or alcohol tests without proper consent or justification.",
      "6. Intrusion into personal space: Searching an employee's personal belongings or invading their personal space without their consent.",
    ],
    footer:
      "Violation of privacy in the workplace is prohibited by various laws in India, such as the Information Technology Act, the Indian Penal Code, and the Constitution of India. Employers are required to protect the privacy of their employees and ensure that they are not subjected to any unwarranted intrusions into their personal space or information. Employees who experience a violation of privacy in the workplace should immediately report it to the appropriate authorities and seek legal remedies if necessary",
  },
];
const dataIndustrialDisputesAct1974 = [
  {
    pagename: "Industrial Disputes Act, 1974",
  },
  {
    points: [
      "1. The Industrial Disputes Act, 1947 is a comprehensive legislation in India that governs the settlement of industrial disputes between employers and employees. Industrial disputes refer to any conflict or disagreement between employers and employees that may arise out of their employment relationship.",
      "2. The Industrial Disputes Act cases in India refer to legal disputes that arise between employers and employees in relation to the settlement of industrial disputes. These cases may be related to issues such as wages, working conditions, employment conditions, bonus, gratuity, layoffs, and retrenchments.",
      "3. The Industrial Disputes Act provides for the establishment of different authorities such as Conciliation Officers, Boards of Conciliation, Courts of Inquiry, Labor Courts, Industrial Tribunals, and National Tribunals for the resolution of industrial disputes.",
      "4. The process for resolving Industrial Disputes Act cases in India involves filing a complaint or application before the appropriate authority. The authority may then issue notice to the other party and conduct a hearing to consider the evidence and arguments presented by both parties. The authority may also attempt to mediate a settlement between the parties.",
      "5. In Industrial Disputes Act cases, the authority may issue orders related to payment of wages, reinstatement of employment, compensation, or other relevant issues. The decision of the authority may be appealed to a higher court if either party is dissatisfied with the outcome.",
    ],
    footer:
      "Overall, resolving Industrial Disputes Act cases in India requires a fair and transparent mechanism that protects the rights of both parties. Proper communication, negotiation, and adherence to the laws and regulations can help in preventing and resolving such disputes in a timely and efficient manner.",
  },
];
const dataMinimumWagesAct1948 = [
  {
    pagename: "Minimum Wages Act, 1948 ",
  },
  {
    points: [
      "1. The Minimum Wages Act, 1948 is a legislation in India that ensures workers are paid a minimum wage that is adequate to sustain their basic needs. The Act provides for fixing the minimum wages for workers in different industries, trades, and occupations.",
      "2. Minimum Wages Act cases in India refer to legal disputes that arise between employers and employees in relation to non-payment or under-payment of minimum wages. These cases may be related to issues such as non-payment of minimum wages, non-payment of overtime wages, non-payment of gratuity, or other related issues.",
      "3. The Minimum Wages Act provides for the establishment of different authorities such as Inspectors and Advisory Boards for the enforcement and implementation of the Act. The Inspector may conduct an inspection of the establishment to ensure that the minimum wages are being paid to the workers.",
      "4. The process for resolving Minimum Wages Act cases in India involves filing a complaint or application before the appropriate authority. The authority may then issue notice to the other party and conduct a hearing to consider the evidence and arguments presented by both parties.",
      "5. In Minimum Wages Act cases, the authority may issue orders related to payment of minimum wages, overtime wages, or other relevant issues. The decision of the authority may be appealed to a higher court if either party is dissatisfied with the outcome.",
    ],
    footer:
      "Overall, resolving Minimum Wages Act cases in India requires a fair and transparent mechanism that protects the rights of both parties. Proper communication, negotiation, and adherence to the laws and regulations can help in preventing and resolving such disputes in a timely and efficient manner.",
  },
];
const dataPaymentofWagesAct1936 = [
  {
    pagename: "Payment of Wages Act, 1936",
  },
  {
    points: [
      "1. The Payment of Wages Act, 1936 is a legislation in India that regulates the payment of wages to employees. The Act ensures that employees receive their wages on time and without any unauthorized deductions. It also provides for the establishment of appropriate authorities for the enforcement and implementation of the Act.",
      "2. Payment of Wages Act cases in India refer to legal disputes that arise between employers and employees in relation to the payment of wages. These cases may be related to issues such as non- payment or under-payment of wages, unauthorized deductions, or delayed payment of wages.",
      "3. The Payment of Wages Act provides for the establishment of different authorities such as Inspectors and Advisory Boards for the enforcement and implementation of the Act. The Inspector may conduct an inspection of the establishment to ensure that the wages are being paid on time and without any unauthorized deductions.",
      "4. The process for resolving Payment of Wages Act cases in India involves filing a complaint or application before the appropriate authority. The authority may then issue notice to the other party and conduct a hearing to consider the evidence and arguments presented by both parties.",
      "5. In Payment of Wages Act cases, the authority may issue orders related to payment of wages, overtime wages, or other relevant issues. The decision of the authority may be appealed to a higher court if either party is dissatisfied with the outcome.",
    ],
    footer:
      "Overall, resolving Payment of Wages Act cases in India requires a fair and transparent mechanism that protects the rights of both parties. Proper communication, negotiation, and adherence to the laws and regulations can help in preventing and resolving such disputes in a timely and efficient manner.",
  },
];
const dataEmployerProvidentFundandMiscAct1952 = [
  {
    pagename: "Employer Provident Fund and Misc Act, 1952",
  },
  {
    points: [
      "1. The Employees' Provident Funds and Miscellaneous Provisions Act, 1952 is a legislation in India that aims to provide social security to employees in the form of a provident fund, pension, and insurance. The Act provides for the establishment of the Employees' Provident Fund Organization (EPFO) for the administration and management of the provident fund scheme.",
      "2. Employees' Provident Fund and Miscellaneous Provisions Act cases in India refer to legal disputes that arise between employers and employees in relation to the provident fund, pension, and insurance schemes provided under the Act. These cases may be related to issues such as non-payment or under- payment of contributions, withdrawal of funds, or other related issues.",
      "3. The Employees' Provident Funds and Miscellaneous Provisions Act provides for the establishment of different authorities such as Regional Provident Fund Commissioners and Appellate Tribunals for the enforcement and implementation of the Act.",
      "4. The process for resolving Employees' Provident Fund and Miscellaneous Provisions Act cases in India involves filing a complaint or application before the appropriate authority. The authority may then issue notice to the other party and conduct a hearing to consider the evidence and arguments presented by both parties.",
      "5. In Employees' Provident Fund and Miscellaneous Provisions Act cases, the authority may issue orders related to payment of contributions, withdrawal of funds, or other relevant issues. The decision of the authority may be appealed to a higher court if either party is dissatisfied with the outcome.",
    ],
    footer:
      "Overall, resolving Employees' Provident Fund and Miscellaneous Provisions Act cases in India requires a fair and transparent mechanism that protects the rights of both parties. Proper communication, negotiation, and adherence to the laws and regulations can help in preventing and resolving such disputes in a timely and efficient manner.",
  },
];
const dataMaternityBenefitsAct1961 = [
  {
    pagename: "Maternity Benefits Act, 1961 ",
  },
  {
    points: [
      "1. The Maternity Benefit Act, 1961 is a legislation in India that aims to provide maternity benefits to women employees who are pregnant or have recently given birth. The Act provides for paid leave, medical benefits, and other facilities to women employees during the maternity period.",
      "2. Maternity Benefit Act cases in India refer to legal disputes that arise between employers and women employees in relation to the maternity benefits provided under the Act. These cases may be related to issues such as non-payment or under-payment of maternity benefits, discrimination against women employees due to pregnancy, or other related issues.",
      "3. The Maternity Benefit Act provides for the establishment of different authorities such as Inspectors and Appellate Authorities for the enforcement and implementation of the Act. The Inspector may conduct an inspection of the establishment to ensure that the maternity benefits are being provided to women employees as per the Act.",
      "4. The process for resolving Maternity Benefit Act cases in India involves filing a complaint or application before the appropriate authority. The authority may then issue notice to the other party and conduct a hearing to consider the evidence and arguments presented by both parties.",
      "5. In Maternity Benefit Act cases, the authority may issue orders related to payment of maternity benefits, medical benefits, or other relevant issues. The decision of the authority may be appealed to a higher court if either party is dissatisfied with the outcome.",
    ],
    footer:
      "Overall, resolving Maternity Benefit Act cases in India requires a fair and transparent mechanism that protects the rights of women employees. Proper communication, negotiation, and adherence to the laws and regulations can help in preventing and resolving such disputes in a timely and efficient manner.",
  },
];
const dataPreventionProhibitionandRedressalAct2013 = [
  {
    pagename: "Prevention, Prohibition and Redressal Act, 2013",
  },
  {
    points: [
      "1. The Prevention of Sexual Harassment of Women at Workplace (Prevention, Prohibition, and Redressal) Act, 2013, is a legislation in India that provides a legal framework for the prevention and redressal of sexual harassment of women in the workplace. The act defines sexual harassment as any unwelcome behaviour of a sexual nature that creates an intimidating, hostile, or offensive work environment.",
      "2. Under the act, it is mandatory for every organization in India with more than 10 employees to establish an Internal Complaints Committee (ICC) to receive and investigate complaints of sexual harassment. The ICC must be headed by a woman and include at least two members from among employees who are committed to women's rights or have experience in social work or the field of women's welfare.",
      "3. The act also provides protection to complainants against retaliation or victimization and requires employers to take appropriate disciplinary action against perpetrators of sexual harassment. The remedies available to a victim of sexual harassment in the workplace include filing a complaint with the ICC or the relevant government authority, seeking legal action through the police or the court, and seeking monetary compensation.",
      "4. Apart from sexual harassment cases, the act also covers other forms of discrimination against women in the workplace, including unequal pay, denial of promotion, and denial of maternity leave. The act aims to provide a safe and conducive work environment for women and to ensure that women are not subjected to discrimination or harassment at the workplace.",
    ],
  },
];
const dataFactoryAct1948 = [
  {
    pagename: "Factory Act, 1948",
  },
  {
    desc: [
      "The Factory Act, 1948 is a key legislation in India that regulates the working conditions in factories. The Act lays down various provisions related to health, safety, welfare, working hours, and employment conditions of workers in factories.",
    ],
  },
  {
    subtitle:
      "Factory Act cases in India may involve disputes related to non-compliance with the provisions of the Act, and may include the following:",
    points: [
      "1. Disputes related to working hours and overtime: The Act lays down provisions related to maximum working hours, overtime hours, and rest periods for workers in factories. Cases may arise where the employer does not comply with these provisions and forces workers to work for longer hours without adequate compensation.",
      "2. Disputes related to health and safety: The Act lays down provisions related to health and safety of workers in factories. Cases may arise where workers are exposed to hazardous substances, poor ventilation, and unsafe working conditions, leading to accidents and illnesses.",
      "3. Disputes related to welfare facilities: The Act lays down provisions related to welfare facilities such as canteens, restrooms, and first-aid facilities for workers in factories. Cases may arise where the employer does not provide these facilities or provides inadequate facilities to workers.",
      "4. Disputes related to employment conditions: The Act lays down provisions related to employment conditions such as leave, wages, and termination of employment. Cases may arise where the employer does not comply with these provisions and violates the rights of workers.",
    ],
    footer:
      "Employees can approach the labour authorities such as the Inspector of Factories or the Labour Commissioner in case of any violations of the Factory Act. They can also seek legal advice from a labour lawyer and file a civil suit against the employer for non-compliance with the Act. It is important for employers to comply with the provisions of the Factory Act to ensure the safety and well-being of workers in factories.",
  },
];
const dataBuildingandOtherConstructionWorkersAct1996 = [
  {
    pagename: "Building and Other Construction Workers Act, 1996",
  },
  {
    desc: [
      "construction workers. The Act applies to every establishment employing ten or more construction workers and lays down various provisions related to their welfare, safety, and health.",
    ],
  },
  {
    subtitle:
      "Construction Workers Act cases in India may involve disputes related to non-compliance with the provisions of the Act, and may include the following:",
    points: [
      "1. Disputes related to registration of workers: The Act requires employers to register construction workers with the appropriate authorities and provide them with an identity card. Cases may arise where employers fail to register workers or provide them with an identity card, leading to denial of benefits under the Act.",
      "2. Disputes related to welfare facilities: The Act lays down provisions related to welfare facilities such as restrooms, canteens, and medical facilities for workers. Cases may arise where employers do not provide these facilities or provide inadequate facilities to workers.",
      "3. Disputes related to safety and health: The Act lays down provisions related to safety and health of workers, such as the provision of safety equipment, safety training, and medical examinations. Cases may arise where employers do not provide these facilities or do not ensure the safety and well-being of workers.",
      "4. Disputes related to employment conditions: The Act lays down provisions related to employment conditions such as wages, hours of work, and leave. Cases may arise where employers violate these provisions and deny workers their rightful benefits.",
    ],
    footer:
      "Employees can approach the labour authorities such as the Labour Commissioner or the Assistant Labour Commissioner in case of any violations of the Building and Other Construction Workers Act. They can also seek legal advice from a labour lawyer and file a civil suit against the employer for non- compliance with the Act. It is important for employers to comply with the provisions of the Act to ensure the safety and well-being of construction workers.",
  },
];
const dataNonPerformingAssets = [
  {
    pagename: "Non – Performing Assets",
  },
  {
    title: "Non – Performing Assets",
    points: [
      "NPA, or Non-Performing Asset, is a term used in the banking and finance sector to describe a loan or advance for which the borrower has failed to make scheduled payments for a certain period of time. In the context of the Securitisation and Reconstruction of Financial Assets and Enforcement of Security Interest (SARFAESI) Act, 2002, NPAs are considered to be financial assets that are in default and for which the lender is seeking to recover the outstanding debt.",
    ],
  },
];
const dataSarfaesiActIssues = [
  {
    pagename: "Sarfaesi Act Issues",
  },
  {
    title: "Demand Notice U/s.13(2) Sarfaesi – Demand Notice",
    points: [
      "1. A Demand Notice U/s.13(2) of the Securitisation and Reconstruction of Financial Assets and Enforcement of Security Interest (SARFAESI) Act, 2002 is a notice that is issued by a lender, such as a bank or financial institution, to a borrower who has defaulted on a loan. The purpose of the Demand Notice is to demand payment of the outstanding debt and to inform the borrower of the consequences of failing to make payment.",
      "2. Under Section 13(2) of the SARFAESI Act, a lender is authorized to issue a Demand Notice to a borrower who has defaulted on a loan. The Demand Notice must specify the amount of the outstanding debt and the date by which payment must be made.",
      "3. If the borrower fails to make payment within the specified timeframe, the lender may take action to recover the outstanding debt, including taking possession of the collateral (such as property or other assets) pledged by the borrower and selling it to repay the outstanding loan.",
      "4. The Demand Notice U/s.13(2) of the SARFAESI Act is an important step in the process of recovering an outstanding debt, and is designed to provide a clear and concise demand for payment to the borrower. The Demand Notice is a formal notice that is used to inform the borrower of their obligations under the loan agreement and the consequences of failing to make payment.",
    ],
  },
  {
    title: "Possession Notice U/s.13(4) Sarfaesi – Possession Notice",
    points: [
      "1. A Possession Notice U/s.13(4) of the Securitisation and Reconstruction of Financial Assets and Enforcement of Security Interest (SARFAESI) Act, 2002 is a notice that is issued by a lender, such as a bank or financial institution, to a borrower who has defaulted on a loan and failed to make payment in response to a Demand Notice. The purpose of the Possession Notice is to inform the borrower that the lender intends to take possession of the collateral pledged by the borrower to secure the loan.",
      "2. Under Section 13(4) of the SARFAESI Act, a lender is authorized to issue a Possession Notice to a borrower who has defaulted on a loan and failed to make payment in response to a Demand Notice. The Possession Notice must specify the amount of the outstanding debt, the date by which payment must be made, and the consequences of failing to make payment, including the lender's intention to take possession of the collateral.",
      "3. Once the Possession Notice has been issued, the lender may take possession of the collateral and sell it to recover the outstanding debt. The Possession Notice is a formal notice that is used to inform the borrower of the consequences of defaulting on the loan and to give the borrower an opportunity to make payment before the lender takes possession of the collateral.",
    ],
  },
  {
    title: "Auction Notice in Sarfaesi",
    points: [
      "1. An Auction Notice in the context of the Securitisation and Reconstruction of Financial Assets and Enforcement of Security Interest (SARFAESI) Act, 2002, is a notice that is issued by a lender, such as a bank or financial institution, to inform the public that the lender intends to sell a borrower's collateral to recover an outstanding debt.",
      "2. Under the SARFAESI Act, a lender may take possession of a borrower's collateral in the event of default and sell it to recover the outstanding debt. The Auction Notice is a formal notice that is used to inform the public of the sale of the collateral and to invite bids for the purchase of the collateral.",
      "3. The Auction Notice must specify the details of the collateral being sold, including a description of the property or other assets, the location of the property, and the terms and conditions of the sale. The Auction Notice must also specify the date, time, and place of the auction, and the minimum bid amount that must be submitted to participate in the auction.",
      "4. The Auction Notice is an important step in the process of recovering an outstanding debt, and is designed to provide transparency and fairness in the sale of the collateral. The Auction Notice is a formal notice that is used to inform the public of the sale of the collateral and to give potential buyers an opportunity to purchase the collateral.",
    ],
  },
  {
    title: "Physical Possession delay for Auction Purchaser in Sarfaesi Act",
    desc: [
      "Under the Securitisation and Reconstruction of Financial Assets and Enforcement of Security Interest (SARFAESI) Act in India, physical possession delay refers to the delay in the transfer of possession of a secured asset from the borrower to the purchaser who has acquired it through auction.",
    ],
    points: [
      "After the sale of a secured asset through auction, the purchaser is entitled to take physical possession of the asset. However, in some cases, the borrower may refuse to hand over the possession, causing a delay in the transfer of possession. This delay in physical possession can cause financial losses to the purchaser, as the asset may deteriorate or lose value during the delay period.",
      "To address this issue, the SARFAESI Act provides for the speedy transfer of possession of the secured asset to the purchaser. Section 14 of the Act provides that the authorized officer can take possession of the secured asset and deliver it to the purchaser, and if necessary, use force to do so. Furthermore, Section 14A of the Act provides for compensation to the purchaser for any loss or damage caused due to delay in the transfer of possession.",
    ],
  },
];
const dataRemediesforBorrowerswhenbankinitiatesSarfaesiProceedings = [
  {
    pagename: "Remedies for Borrowers when bank initiates Sarfaesi Proceedings",
  },
  {
    title: "Remedies for Borrowers when bank initiates Sarfaesi Proceedings",
    desc: [
      "The Securitisation and Reconstruction of Financial Assets and Enforcement of Security Interest (SARFAESI) Act, 2002 provides a framework for the securitisation and reconstruction of financial assets and the enforcement of security interests in India. While the act provides banks and financial institutions with the authority to take action to recover outstanding debts, it also provides remedies for borrowers in the event that the SARFAESI proceedings are initiated against them.",
    ],
  },
  {
    subtitle: [
      "Some of the remedies available to borrowers when bankers initiate SARFAESI proceedings in India include:",
    ],
    points: [
      "1. Right to be heard: Borrowers have the right to be heard by the debt recovery tribunal before any action is taken to enforce the security interest. This means that the borrower must be given an opportunity to present their case and to argue against the enforcement of the security interest.",
      "2. Appeal: Borrowers have the right to appeal the decision of the debt recovery tribunal to a higher court. This provides the borrower with an opportunity to challenge the decision and to have the case heard by a higher authority.",
      "3. Stay of proceedings: In some cases, borrowers may be able to obtain a stay of proceedings, which means that the enforcement of the security interest will be postponed until the court makes a final decision.",
      "4. Negotiations: Borrowers may be able to negotiate with the lender to reach an agreement that is more favorable to both parties. This may involve negotiating a new repayment plan, a reduction in the outstanding debt, or other terms that are more favorable to the borrower.",
      "It is important to note that the remedies available to borrowers in SARFAESI proceedings can be complex and time-consuming, and may involve the submission of extensive documentation and expert evidence. Borrowers who are facing SARFAESI proceedings should seek the advice of a qualified legal professional to ensure that their rights are protected.",
    ],
    footer:
      "Overall, the remedies available to borrowers in SARFAESI proceedings play an important role in ensuring that the enforcement of security interests is conducted in a fair and transparent manner, and in protecting the rights of borrowers.",
  },
];
const dataExorbitantInterestRate = [
  {
    pagename: "Exorbitant Interest Rate",
  },
  {
    title: "Exorbitant Interest Rate",
    points: [
      "1. Exorbitant interest rate refers to an excessively high rate of interest charged by a lender on a loan or credit facility. In India, the issue of exorbitant interest rates is a significant concern for borrowers, particularly for those belonging to economically weaker sections.",
      "2. The practice of charging exorbitant interest rates is prevalent in the informal sector, including moneylenders and loan sharks who exploit the vulnerable position of borrowers to charge rates as high as 60-120% per annum. Such high-interest rates can lead to a debt trap where borrowers find it challenging to repay the loan and end up taking further loans to meet their repayment obligations, resulting in a cycle of indebtedness.",
      "3. Even in the formal banking sector, there have been instances of lenders charging exorbitant interest rates on certain loans, such as microfinance loans, personal loans, and credit card loans. The interest rates on such loans can be as high as 30-40% per annum, which can make it challenging for borrowers to repay the loan and can lead to default and financial distress.",
      "4. To address the issue of exorbitant interest rates, the Reserve Bank of India (RBI) has introduced various measures, including capping the interest rates on certain loans, such as microfinance loans, and issuing guidelines to banks and other lenders to ensure transparency and fairness in their lending practices. Additionally, the government has also launched various initiatives to provide financial literacy and promote access to affordable credit to vulnerable sections of the population.",
    ],
  },
];
const dataLoanEMIIssues = [
  {
    pagename: "Loan EMI Issues",
  },
  {
    title: "Loan EMI Issues",
    subtitle:
      "Loan EMI (Equated Monthly Instalment) issues in India refer to problems or disputes that arise between loan borrowers and banks or financial institutions with respect to the repayment of loans. Some common loan EMI issues in India include:",
    points: [
      "1. Missed or delayed EMI payments: Loan borrowers may miss or delay their EMI payments, which can result in penalties, increased interest rates, and negative impact on their credit score.",
      "2. Affordability issues: Loan borrowers may face affordability issues, such as difficulty in making their EMI payments due to a reduction in income or unexpected expenses.",
      "3. EMI calculation errors: Loan borrowers may dispute the calculation of their EMI payments if they believe that the bank or financial institution has made a mistake in the calculation.",
      "4. EMI amount increase: Loan borrowers may face an increase in their EMI amount due to changes in interest rates or other factors.",
      "5. Repayment terms and conditions: Loan borrowers may have issues with the repayment terms and conditions set by the bank or financial institution, such as difficulty in making early repayments or obtaining a loan extension.",
      "In the event of a loan EMI issue, loan borrowers in India should contact their bank or financial institution to resolve the issue. If the issue cannot be resolved through direct communication with the bank or financial institution, loan borrowers may consider seeking the assistance of a qualified legal professional or a consumer protection organization.",
    ],
    footer:
      "Overall, loan EMI issues are a common problem for loan borrowers in India and can result in difficulties in repaying the loan. It is important for loan borrowers to be aware of their rights and to take appropriate action to resolve any issues that may arise.",
  },
];
const dataCreditCardIssues = [
  {
    pagename: "Credit Card Issues",
  },
  {
    title: "Credit Card Issues",
    subtitle:
      "Credit card issues from banks in India refer to problems or disputes that arise between credit cardholders and banks with respect to the use of credit cards. Some common credit card issues from banks in India include:",
    points: [
      "1. Billing disputes: Credit cardholders may dispute the charges on their credit card statements if they believe that they have been overcharged or that fraudulent charges have been made.",
      "2. Interest rates and fees: Credit cardholders may have concerns about the interest rates and fees charged by their bank, such as annual fees, late payment fees, or over-limit fees.",
      "3. Rewards and benefits: Credit cardholders may have issues with the rewards and benefits offered by their bank, such as difficulty redeeming rewards points or problems with the redemption process.",
      "4. Credit limit reductions: Banks may reduce a credit cardholder's credit limit, which can result in difficulties for the credit cardholder in meeting their obligations or in obtaining credit in the future.",
      "5. Fraud and identity theft: Credit cardholders may be victims of fraud or identity theft, which can result in unauthorized charges being made to their credit card account.",
      "6. Customer service: Credit cardholders may have issues with the customer service provided by their bank, such as difficulty resolving disputes or obtaining information about their account.",
      "In the event of a credit card issue, credit cardholders in India should contact their bank to resolve the issue. If the issue cannot be resolved through direct communication with the bank, credit cardholders may consider filing a complaint with the banking ombudsman or taking legal action.",
    ],
    footer:
      "Overall, credit card issues from banks in India are a common problem and can result in difficulties for credit cardholders. It is important for credit cardholders to be aware of their rights and to take appropriate action to resolve any issues that may arise.",
  },
];
const dataCreditCardHarassment = [
  {
    pagename: "Credit Card Harassment",
  },
  {
    title: "Credit Card Harassment",
    points: [
      "1. Credit card harassment in India refers to the abusive or coercive behavior of credit card companies or their agents towards cardholders, often to recover outstanding dues or fees. Credit card harassment can take various forms, such as frequent calls or messages demanding payment, threats of legal action or harassment, and using aggressive and intimidating language.",
      "2. The practice of credit card harassment is a significant concern for cardholders in India, and it can cause mental and emotional stress, affecting their overall well-being. Many cardholders have reported instances of credit card companies making repeated calls and sending messages to recover outstanding dues, sometimes even to the extent of calling their family members or employers. Such behaviour can be humiliating and invasive and can impact the cardholder's personal and professional life.",
      "3. To address the issue of credit card harassment, the Reserve Bank of India (RBI) has issued guidelines to credit card companies and other lenders on fair practices in debt collection. These guidelines prohibit lenders from using abusive or threatening language or engaging in any behaviour that amounts to harassment of the borrower or their family members. The guidelines also require lenders to adopt a customer-friendly approach while communicating with the borrower and provide adequate notice before taking any legal action.",
      "4. If a cardholder faces credit card harassment, they can file a complaint with the credit card company's customer care department or approach the Banking Ombudsman or the Consumer Court for redressal. Cardholders can also take preventive measures such as keeping track of their credit card usage, monitoring their credit card statements regularly, and paying their bills on time to avoid falling into debt and becoming vulnerable to harassment by credit card companies.",
    ],
  },
];
const dataCreditCardSettlement = [
  {
    pagename: "Credit Card Settlement",
  },
  {
    subtitle:
      "If you have outstanding credit card debt in India and are unable to make payments, you may need to consider settling your debt in a legal way. Here are some steps you can take:",
    points: [
      "1. Contact your credit card issuer: The first step in settling your outstanding credit card debt is to contact your credit card issuer and explain your financial situation. They may be able to offer you a settlement plan or an affordable repayment plan that suits your needs.",
      "2. Negotiate a settlement: If you are unable to pay off the entire outstanding amount, you can negotiate a settlement with your credit card issuer. This involves offering to pay a portion of the outstanding balance in exchange for the remaining debt being written off. The credit card issuer may agree to this if they believe that they will be unable to recover the entire outstanding balance from you.",
      "3. Seek legal advice: If negotiations with your credit card issuer are unsuccessful, you may want to seek legal advice. A lawyer can help you understand your legal rights and options, and can advise you on the best course of action to take.",
      "4. File for bankruptcy: If you are unable to settle your credit card debt through negotiations or legal means, you may need to consider filing for bankruptcy. This is a legal process that can help you discharge your debts, including credit card debt, if you are unable to pay them.",
    ],
    footer:
      "It is important to note that settling your credit card debt in a legal way can have an impact on your credit score. However, if you are unable to pay off your debts, it may be the best option for you to avoid further financial hardship.",
  },
];
const dataOneTimeSettlementforNPA = [
  {
    pagename: "One Time Settlement for NPA",
  },
  {
    points: [
      "1. In India, a One Time Settlement (OTS) scheme is a program offered by banks and financial institutions to borrowers who are unable to repay their loans in full. Under this scheme, the borrower is offered the option to settle their outstanding dues by paying a lump sum amount that is less than the total amount owed. This scheme is typically offered to borrowers who are facing financial difficulties or are in default of their loan payments.",
      "2. The OTS scheme is a way for banks and financial institutions to recover at least a portion of the outstanding loan amount, while allowing the borrower to clear their debt and avoid further legal action. The terms and conditions of the OTS scheme may vary depending on the individual's financial situation and the specific policies of the bank or financial institution.",
      "3. To participate in an OTS scheme, the borrower must typically submit an application to the bank or financial institution, indicating their willingness to settle their outstanding dues. The bank or financial",
      "4. institution will then review the application and determine the amount that the borrower is required to pay to settle their debt. Once the payment is made, the loan account is considered settled and the borrower is no longer liable for any further payments.",
      "5. It is important to note that opting for an OTS scheme can have an impact on the borrower's credit score, as it indicates that they were unable to repay the loan in full. However, it may be a viable option for those who are facing financial difficulties and are unable to make regular loan payments.",
    ],
  },
];
const dataInsurance = [
  {
    pagename: "Insurance",
  },
  {
    subtitle:
      "Insurance problems in India refer to issues or disputes that arise between insurance companies and policyholders with respect to insurance policies and claims. Some common insurance problems in India include:",
    points: [
      "1. Claim denial: Insurance companies may deny claims made by policyholders, either in whole or in part, for various reasons, such as policy exclusions or non-compliance with policy terms and conditions.",
      "2. Claim delay: Insurance companies may delay the payment of claims, either due to administrative reasons or because of disputes over the amount of the claim. Policy cancellation: Insurance companies may cancel policies for various reasons, such as non- payment of premiums or fraud.",
      "3. Policy terms and conditions: Policyholders may have issues with the terms and conditions of their insurance policies, such as disputes over the coverage provided or the calculation of premiums.",
      "4. Customer service: Policyholders may have issues with the customer service provided by insurance companies, such as difficulty resolving disputes or obtaining information about their policies.",
      "In the event of an insurance problem, policyholders in India should contact their insurance company to resolve the issue. If the issue cannot be resolved through direct communication with the insurance company, policyholders may consider seeking the assistance of the Insurance Ombudsman or taking legal action.",
      "Insurance cases in India are generally heard by the civil courts, including the district courts and the high courts, and may be appealed to higher courts, such as the Supreme Court of India.",
      "In some cases, insurance cases may also be heard by alternative dispute resolution forums, such as the Insurance Ombudsman, which is a specialized forum that is empowered to hear and resolve disputes between insurance companies and policyholders.",
      "Insurance cases in India can be complex and time-consuming, and may require the submission of extensive documentation and expert evidence. Policyholders who are involved in insurance cases should seek the advice of a qualified legal professional to ensure that their rights are protected and that their case is presented in the strongest possible manner.",
      "Overall, insurance problems are a common issue for policyholders in India and can result in difficulties in obtaining the coverage that they are entitled to under their insurance policies. It is important for policyholders to be aware of their rights and to take appropriate action to resolve any issues that may arise.",
      "",
    ],
  },
];
const dataCyberCrimeCases = [
  {
    pagename: "Cyber Crime Cases",
  },
  {
    title: "Cyber Crime Cases",
    subtitle:
      "Cybercrime cases in India refer to criminal activities that are committed using the internet, computer systems, or other forms of digital technology. Some common examples of cybercrime cases in India include:",
    points: [
      "1. Hacking: Hacking involves unauthorized access to computer systems, networks, or websites to steal or manipulate sensitive information.",
      "2. Phishing: Phishing involves the use of emails or websites that appear to be from a legitimate source, such as a bank or other financial institution, to trick individuals into revealing their personal and financial information.",
      "3. Cyberstalking: Cyberstalking involves the use of the internet or other digital technology to harass, intimidate, or threaten individuals.",
      "4. Identity theft: Identity theft involves the unauthorized use of an individual's personal and financial information for criminal purposes, such as opening bank accounts or obtaining loans.",
      "5. Fraud: Fraudulent activities committed using the internet or other digital technology, such as lottery scams or investment frauds.",
      "6. Cyberbullying: Cyberbullying involves the use of digital technology to bully, harass, or threaten individuals, particularly children and teenagers.",
      "Cybercrime cases are a growing concern in India and can result in significant harm to individuals, businesses, and the economy. The Indian government has enacted several laws, such as the Information Technology Act, 2000, to address cybercrime and to provide a legal framework for the investigation and prosecution of cybercrime cases.",
    ],
    footer:
      "Overall, cybercrime cases are a serious issue in India and can have a significant impact on individuals, businesses, and society. It is important for individuals and organizations to be aware of the risks and to take appropriate measures to protect themselves from cybercrime.",
  },
];
const dataChequeBounce = [
  {
    pagename: "Cheque Bounce",
  },
  {
    title: "Cheque Bounce",
    points: [
      "1. Cheque bounce in India refers to a situation where a cheque issued by a payer is returned by the bank due to insufficient funds in the payer's account. Cheque bounce is a criminal offense under the Negotiable Instruments Act, 1881 in India and can result in criminal prosecution and financial penalties for the payer.",
      "2. When a cheque bounces, the payee, or the person who was to receive payment from the cheque, may take legal action against the payer. The payee may file a complaint with the local police or may initiate a legal proceeding in a criminal court.",
      "3. In a legal proceeding for cheque bounce, the payee must prove that the cheque was issued with the intention to pay, that the cheque was dishonoured by the bank, and that the payer had insufficient funds in their account at the time the cheque was presented for payment.",
    ],
    footer:
      "Overall, cheque bounce is a serious issue in India and can result in criminal prosecution and financial penalties for the payer. It is important for individuals and businesses to ensure that they have sufficient funds in their accounts before issuing cheques, and to take appropriate action to resolve any issues that may arise.",
  },
];
const dataChequeBounceRemedy = [
  {
    pagename: "Cheque Bounce Remedy for the Complainant",
  },
  {
    title: "Cheque Bounce Remedy for the Complainant",
    subtitle:
      "If you are a complainant in a cheque bounce case in India, the following are the remedies available to you:",
    points: [
      "1. Criminal prosecution: The complainant can file a criminal complaint under Section 138 of the Negotiable Instruments Act against the accused for the dishonour of the cheque. If the court finds the accused guilty, they may be liable to pay a fine or face imprisonment.",
      "2. Civil suit: The complainant can also file a civil suit against the accused for recovery of the amount due under the cheque.",
      "3. Compounding of the offence: The accused may offer to compound the offence, i.e., offer to settle the matter outside the court by paying the amount due along with any additional expenses incurred by the complainant. If the complainant accepts the offer, the matter will be settled.",
      "4. Negotiation: The complainant may also negotiate with the accused for the settlement of the matter and the payment of the amount due.",
      "5. Legal recourse: If the accused fails to make the payment or settle the matter, the complainant can take legal recourse by filing a complaint in the appropriate court or seeking legal advice.",
    ],
    footer:
      "It is important to note that the remedies available to the complainant may vary depending on the specific circumstances of each case.",
  },
];
const dataChequeBounceProcedure = [
  {
    pagename: "Filing Procedure of Cheque Bounce",
  },
  {
    subtitle:
      "In India, a cheque bounce case typically goes through the following stages:",
    points: [
      "1. Issuing of the cheque: The first stage is when the cheque is issued by the drawer to the payee.",
      "2. Presentation of the cheque: The payee presents the cheque for payment to the bank on which it is drawn.",
      '3. Return of the cheque: If the bank is unable to honour the cheque, it returns it to the payee with the reason for the same. This is known as a "cheque bounce" or "dishonour" case.',
      "4. Notice to the drawer: The payee may send a legal notice to the drawer of the cheque within 30 days of the cheque being returned by the bank, demanding payment of the amount due.",
      "5. Reply to the notice: The drawer must respond to the notice within 15 days, either by making the payment or disputing the claim.",
      "6. Filing of complaint: If the payment is not made and the claim is not disputed, the payee can file a complaint under Section 138 of the Negotiable Instruments Act in the court having jurisdiction.",
      "7. Summons to the drawer: The court will issue summons to the drawer, requiring them to appear in court on a specified date.",
      "8. Trial: The court will hear the case and decide whether the cheque bounce was due to insufficient funds or any other reason. If the court finds the drawer guilty, they may be liable to pay a fine or imprisonment.",
      "9. Appeal: Either party can appeal the decision of the trial court in a higher court.",
    ],
    footer:
      "It is important to note that the above steps may vary depending on the specific circumstances of each case.",
  },
];
const dataMedicalNegligenceCases = [
  {
    pagename: "Medical Negligence Cases",
  },
  {
    desc: [
      "Medical negligence refers to a situation where a healthcare professional, such as a doctor or nurse, fails to provide the expected standard of care to a patient, resulting in harm, injury or even death to the patient. In India, medical negligence cases are governed by the law of torts and the Consumer Protection Act, 2019.",
    ],
  },
  {
    subtitle:
      "In order to establish a case of medical negligence, the plaintiff must prove the following elements:",
    points: [
      "1. Duty of care: The healthcare professional owed a duty of care to the patient.",
      "2. Breach of duty: The healthcare professional breached this duty of care by failing to provide the expected standard of care.",
      "3. Causation: The breach of duty caused harm, injury or death to the patient.",
      "4. Damage: The patient suffered damages as a result of the breach of duty.",
      "If these elements are established, the patient can file a civil suit for compensation for the damages suffered due to the medical negligence. The compensation can cover the cost of medical treatment, loss of income, and other related expenses.",
      "In addition to civil liability, healthcare professionals may also face criminal charges if the medical negligence is found to be grossly negligent or intentional, resulting in the death of the patient.",
      "To prevent medical negligence, healthcare professionals are expected to follow the standard of care that is generally accepted by the medical community and take appropriate steps to inform the patient about the risks and benefits of a medical procedure or treatment.",
    ],
  },
];
const dataDoctorsLiabilityinMedicalNegligence = [
  {
    pagename: "Doctors Liability in Medical Negligence ",
  },
  {
    title: "Doctors Liability in Medical Negligence ",
    points: [
      "1. In India, the liability of a doctor in a medical negligence case is determined by the principles of tort law. The doctor is liable for medical negligence if it is established that he/she had a duty of care towards the patient, and that he/she breached that duty of care.",
      "2. The duty of care arises from the doctor-patient relationship, and the doctor is required to provide treatment with reasonable care and skill. The breach of this duty of care may occur in various ways, such as incorrect diagnosis, improper treatment, failure to refer to a specialist, etc.",
      "3. To establish liability, the patient must show that the doctor's breach of duty of care caused the injury or harm suffered by the patient. The patient must also prove that the injury or harm suffered was not due to any pre-existing condition or other factors beyond the control of the doctor.",
      "4. In India, the Supreme Court has laid down the standard of care that is expected from a doctor in medical negligence cases. The doctor is required to exercise the degree of skill and knowledge that is ordinarily possessed by a practitioner in the same field, and he/she should also take all necessary precautions to avoid any harm to the patient.",
      "5. In addition to this, the Indian Medical Council has also issued guidelines and codes of ethics for medical practitioners to ensure that they provide medical treatment with due care and diligence, and avoid any acts of negligence.",
    ],
  },
];
const dataSurrogacy = [
  {
    pagename: "Surrogacy",
  },
  {
    title: "Surrogacy",
    points: [
      "1. Surrogacy is a process in which a woman agrees to carry and give birth to a child on behalf of another person or couple who is unable to conceive or carry a child themselves. In India, surrogacy is regulated by the Surrogacy (Regulation) Bill, 2020.",
      "2. Under the bill, surrogacy is allowed only for Indian citizens who are married for at least five years and are between the ages of 23 and 50 years old for women, and 26 and 55 years old for men. Surrogacy is also allowed for widows and divorcees who meet certain conditions. Same-sex couples and single individuals are not allowed to use surrogacy in India.",
      "3. The bill allows for altruistic surrogacy, where the surrogate mother can only be compensated for medical expenses and insurance related to the pregnancy. Commercial surrogacy, where the surrogate mother is paid a fee, is not allowed.",
      "4. The intended parents must also obtain a certificate of essentiality and a certificate of eligibility from the appropriate authorities before proceeding with surrogacy. The surrogate mother must give her informed consent, and the rights of the child born through surrogacy are protected by law.",
      "5. The Surrogacy (Regulation) Bill, 2020 also provides for a National Surrogacy Board and State Surrogacy Boards to regulate and monitor surrogacy in India, and to ensure that all parties involved in surrogacy are treated fairly and ethically.",
    ],
  },
];
const dataCompensationMedicalNegligence = [
  {
    pagename: "Compensation for Medical Negligence",
  },
  {
    title: "Compensation for Medical Negligence",
    points: [
      "Compensation in medical negligence cases in India refers to the monetary amount awarded to a victim of medical negligence as a form of redress for the harm or injury caused by the medical professional or institution. The amount of compensation awarded is determined based on various factors such as the extent of the injury, the severity of the medical negligence, and the impact on the victim's quality of life. The compensation may cover medical expenses, lost wages, pain and suffering, and other related costs. In India, compensation for medical negligence cases can be awarded through civil litigation or consumer forums",
    ],
  },
];
const dataIndianMedicineCentralCouncilAct = [
  {
    pagename: "Indian Medicine Central Council Act",
  },
  {
    title: "Indian Medicine Central Council Act",
    points: [
      "1. The Indian Medicine Central Council Act is an Indian law enacted in 1970 to regulate the education and practice of Indian systems of medicine, including Ayurveda, Siddha, and Unani.",
      "2. The Act provides for the constitution of a Central Council of Indian Medicine, which is responsible for the maintenance of standards in education and practice of Indian medicine, as well as the recognition of medical qualifications in the field.",
      "3. The Council also has the power to prescribe minimum standards of education and curricula for various courses in Indian medicine, and to conduct inspections of medical colleges and institutions to ensure compliance with these standards.",
      "4. The Act also establishes a system of registration for practitioners of Indian medicine and provides for the maintenance of a Central Register of Indian Medicine.",
      "5. The Indian Medicine Central Council Act is an important legislation that ensures",
    ],
  },
];
const dataMotorVehicleAccidentCases = [
  {
    pagename: "Motor Vehicle Accident Cases",
  },
  {
    title: "Motor Vehicle Accident Cases",
    desc: [
      "Motor accident claim cases in India refer to legal disputes that arise as a result of injuries or damages caused by a motor vehicle accident. These cases involve seeking compensation from the party responsible for the accident, which could be the driver, owner, or insurance company of the vehicle.",
    ],
  },
  {
    subtitle:
      "The Motor Vehicles Act, 1988 in India provides for the legal framework for such cases, and the compensation is determined based on the extent of damage and injury caused. Some common issues that may arise in motor accident claim cases include:",
    points: [
      "1. Liability determination: The first step in a motor accident claim case is to determine the liability of the parties involved in the accident. This could involve examining the evidence, such as the police report and eyewitness testimony, to determine who was at fault.",
      "2. Compensation calculation: Once liability is established, the compensation is determined based on the extent of damage and injury caused. This could include compensation for medical expenses, loss of income, and pain and suffering.",
      "3. Insurance claims: In some cases, the compensation may be paid by the insurance company of the responsible party. However, disputes may arise over the extent of coverage and the amount of compensation.",
    ],
    footer:
      "Overall, motor accident claim cases in India aim to ensure that the victims of motor vehicle accidents are adequately compensated for their losses. The Indian legal system is designed to provide a fair and just resolution to disputes related to motor accidents, with the interests of all parties involved being taken into consideration.",
  },
];
const dataPaymentofCompensation = [
  {
    pagename: "Payment of Compensation",
  },
  {
    title: "Payment of Compensation",
    points: [
      "1. Section 163A of the Motor Vehicles Act, 1988 in India provides for the payment of compensation in case of a motor vehicle accident that results in the death or permanent disablement of the victim. This provision is applicable in cases where the accident was caused by the fault of the owner or the driver of the vehicle.",
      "2. Under Section 163A, the owner of the vehicle or the insurer is required to pay a fixed amount of compensation to the victim or their legal heirs. The amount of compensation is fixed based on the nature of the injury or disability caused and ranges from INR 50,000 to INR 5 lakhs.",
      "3. Section 163A provides for a no-fault liability regime, which means that the victim is entitled to compensation even if they were partially responsible for the accident. This provision is designed to provide prompt and expeditious relief to the victims of motor vehicle accidents, without the need for a lengthy legal process.",
    ],
    footer:
      "Overall, Section 163A of the Motor Vehicles Act, 1988 in India is an important provision that aims to ensure that the victims of motor vehicle accidents are adequately compensated, regardless of fault. The Indian legal system is designed to provide a fair and just resolution to disputes related to motor accidents, with the interests of all parties involved being taken into consideration.",
  },
];
const dataPolicetoInvestigatetheAccident = [
  {
    pagename: "Police to Investigate the Accident",
  },
  {
    title: "Police to Investigate the Accident",
    points: [
      "1. Section 165 of the Motor Vehicles Act, 1988 in India empowers the police to investigate accidents involving motor vehicles. This provision is applicable in cases where a motor vehicle accident has resulted in the death or injury of a person, or damage to property.",
      "2. Under Section 165, the police officer in charge of the police station in the area where the accident occurred is required to investigate the accident and file a report. The report should include details such as the cause of the accident, the extent of damage or injury caused, and the names and addresses of the parties involved.",
      "3. Section 165 also empowers the police officer to examine any person who was driving or in charge of the vehicle at the time of the accident, and to seize the vehicle for investigation purposes. The police officer may also take statements from witnesses and collect any other evidence that may be relevant to the investigation.",
      "4. The purpose of Section 165 is to ensure that the investigation of motor vehicle accidents is conducted promptly and efficiently, with a view to determining the cause of the accident and holding the responsible party accountable. This provision is designed to promote road safety and reduce the incidence of motor vehicle accidents.",
    ],
    footer:
      "Overall, Section 165 of the Motor Vehicles Act, 1988 in India is an important provision that empowers the police to investigate motor vehicle accidents and ensure that the responsible party is held accountable for their actions. The Indian legal system is designed to provide a fair and just resolution to disputes related to motor accidents, with the interests of all parties involved being taken into consideration.",
  },
];
const dataHitandRunCaseCompensation = [
  {
    pagename: "Hit and Run Case Compensation",
  },
  {
    title: "Hit and Run Case Compensation",
    points: [
      "1. Section 140 of the Motor Vehicles Act, 1988 in India provides for the payment of compensation to the victims of hit-and-run accidents. This provision is applicable in cases where the driver responsible for the accident flees the scene without providing any assistance or information to the victim.",
      "2. Under Section 140, the victim or their legal heirs are entitled to compensation from the Solatium Fund, which is a fund created by the government for the purpose of providing compensation to the victims of hit-and-run accidents. The amount of compensation is fixed at INR 25,000 in case of death and INR 12,500 in case of grievous hurt.",
      "3. The victim or their legal heirs may file a claim for compensation with the Claims Tribunal, which is a specialized court established for this purpose. The Claims Tribunal will examine the evidence presented by both parties and make a decision based on the facts and circumstances of the case.",
      "4. The purpose of Section 140 is to ensure that the victims of hit-and-run accidents are adequately compensated, even if the driver responsible for the accident cannot be identified or traced. This provision is designed to promote road safety and reduce the incidence of hit-and-run accidents.",
    ],
    footer:
      "Overall, Section 140 of the Motor Vehicles Act, 1988 in India is an important provision that aims to ensure that the victims of hit-and-run accidents are adequately compensated for their losses. The Indian legal system is designed to provide a fair and just resolution to disputes related to motor accidents, with the interests of all parties involved being taken into consideration.",
  },
];
const dataAccidentsresultsinDeathInjury = [
  {
    pagename: "Accidents results in Death & Injury",
  },
  {
    title: "Accidents results in Death & Injury",
    subtitle:
      "In India, the Motor Vehicles Act, 1988 deals with motor vehicle accidents resulting in death and injury. The following are the definitions of these terms:",
    points: [
      "1. Death: The act defines death as the permanent disappearance of all evidence of life at any time after birth, including the absence of a heartbeat or respiration.",
      "2. Grievous hurt: The act defines grievous hurt as any injury that endangers life or causes severe physical pain or disability.",
    ],
  },
  {
    subtitle:
      "In case of an accident resulting in death or grievous hurt, the following are the legal provisions:",
    points: [
      "1. Duty to report: The driver of the vehicle involved in the accident must report the accident to the nearest police station as soon as possible and within 24 hours of the accident.",
      "2. Medical assistance: The driver of the vehicle must also provide medical assistance to the injured person and take them to the nearest medical facility for treatment.",
      "3. Liability: The owner of the vehicle is liable for the payment of compensation to the victim or their legal heirs in case of death or grievous hurt.",
      "4. Investigation: The police will investigate the accident and submit a report to the court.",
      "5. Compensation: The court may order the payment of compensation to the victim or their legal heirs by the owner or insurer of the vehicle.",
    ],
    footer:
      "It is important to note that the legal provisions may vary depending on the specific circumstances of each case.",
  },
];
const dataThirdPartyInsuranceClaims = [
  {
    pagename: "Third Party Insurance Claims",
  },
  {
    title: "Third Party Insurance Claims",
    desc: [
      "In India, third party insurance is mandatory for all motor vehicles under the Motor Vehicles Act, 1988. A third-party insurance policy covers the liability of the vehicle owner in case of any damage or injury caused to a third party in an accident involving the insured vehicle. A third-party insurance claim can be made by the injured person or their legal representative against the owner of the vehicle.",
    ],
  },
  {
    subtitle:
      "The following are the key features of a third-party insurance claim in motor vehicle accident cases in India:",
    points: [
      "1. Reporting the accident: The injured person must report the accident to the nearest police station as soon as possible and within 24 hours of the accident.",
      "2. Filing the claim: The injured person or their legal representative can file a claim for compensation against the owner of the vehicle with the insurance company of the vehicle.",
      "3. Investigation: The insurance company will investigate the accident and assess the liability of the vehicle owner.",
      "4. Compensation: If the insurance company finds the vehicle owner liable, they will pay compensation to the injured person or their legal representative.",
      "5. Dispute resolution: In case of any dispute regarding the liability or amount of compensation, the injured person or their legal representative can approach the Motor Accident Claims Tribunal (MACT) to resolve the matter.",
    ],
    footer:
      "It is important to note that the compensation amount in a third-party insurance claim is usually limited to a specific amount as prescribed by the Motor Vehicles Act, and it may vary depending on the specific circumstances of each case.",
  },
];
const dataLimitationofLiability = [
  {
    pagename: "Limitation of Liability",
  },
  {
    title: "Limitation of Liability",
    desc: "In India, the Motor Vehicles Act, 1988 provides for the limitation of liability of the owner of a motor vehicle in case of accidents resulting in death or injury. The following are the key provisions:",
    points: [
      "1. No-fault liability: The liability of the vehicle owner is considered a no-fault liability, which means that the victim does not have to prove the negligence or fault of the owner to claim compensation.",
      "2. Limitation of liability: The liability of the owner of the vehicle is limited to a certain amount as prescribed by the Motor Vehicles Act, 1988. The current limit for death is Rs. 50,00,000 (50 lakhs) and for grievous hurt is Rs. 25,00,000 (25 lakhs).",
      "3. Exceptions to the limitation: The limitation of liability does not apply if the accident was caused due to the owner's intentional or wilful act or gross negligence. In such cases, the owner may be liable to pay compensation over and above the prescribed limit.",
      "4. Motor Accident Claims Tribunal (MACT): In case of any dispute regarding the liability or amount of compensation, the injured person or their legal representative can approach the MACT to resolve the matter.",
    ],
    footer:
      "It is important to note that the limitation of liability applies only to the owner of the vehicle and not to the driver. If the driver is found to be negligent or at fault, they may be liable to pay compensation over and above the prescribed limit.",
  },
];
const dataCalculationofCompensation = [
  {
    pagename: "Calculation of Compensation",
  },
  {
    title: "Calculation of Compensation",
    subtitle:
      "In India, the Motor Vehicles Act, 1988 provides for the calculation of compensation in motor vehicle accident cases resulting in death or injury. The following factors are taken into consideration while calculating the compensation:",
    points: [
      "⦁	Nature and extent of the injury: The severity and nature of the injury suffered by the victim is one of the key factors in determining the compensation amount.",
      "⦁	Loss of income: The compensation also takes into account the loss of income or earning capacity of the victim due to the injury or death.",
      "⦁	Medical expenses: The medical expenses incurred by the victim due to the injury or death are also taken into account while calculating the compensation amount.",
      "⦁	Funeral expenses: In case of death, the compensation also covers the funeral expenses of the deceased.",
      "⦁	Future expenses: The compensation may also take into account any future expenses that the victim may incur due to the injury or death, such as medical expenses, rehabilitation costs, etc.",
      "⦁	Age of the victim: The age of the victim is also taken into consideration while calculating the compensation amount, as younger victims are likely to have a longer life expectancy and may incur more expenses in the future.",
      "⦁	Contributory negligence: If the victim is found to have contributed to the accident, their share of negligence is also taken into account while calculating the compensation amount.",
    ],
    footer:
      "It is important to note that the compensation amount may vary depending on the specific circumstances of each case, and the final amount is determined by the Motor Accident Claims Tribunal (MACT) after considering all the relevant factors.",
  },
];
const dataNegligenceClaims = [
  {
    pagename: "Negligence Claims",
  },
  {
    title: "Negligence Claims",
    desc: [
      "In India, a negligence claim in motor vehicle accident cases arises when one party alleges that the other party was negligent and that negligence resulted in the accident and the resulting damage or injury. Negligence is the failure to take reasonable care to prevent harm or injury to others.",
    ],
  },
  {
    subtitle:
      "The following are the key elements of a negligence claim in motor vehicle accident cases in India:",
    points: [
      "⦁	Duty of care: Every driver has a duty of care towards other road users to ensure their safety and prevent accidents.",
      "⦁	Breach of duty: A driver can be held liable for negligence if they breach their duty of care towards other road users, such as by violating traffic rules or driving recklessly.",
      "⦁	Causation: The driver's breach of duty must be the cause of the accident and the resulting damage or injury.",
      "⦁	Damage or injury: The victim must have suffered damage or injury as a result of the accident caused by the driver's negligence.",
      "⦁	Contributory negligence: If the victim is found to have contributed to the accident, their share of negligence may also be taken into account while determining liability.",
      "In a negligence claim, the injured party can claim compensation from the driver who was at fault. The amount of compensation depends on the severity of the injury, loss of income, medical expenses, future expenses, and other factors as determined by the Motor Accident Claims Tribunal (MACT).",
      "It is important to note that negligence claims are usually filed in civil courts, and the burden of proof lies with the injured party to prove the driver's negligence.",
    ],
  },
];
const dataDisputeOverCompensation = [
  {
    pagename: "Dispute Over Compensation ",
  },
  {
    title: "Dispute Over Compensation ",
    subtitle: [
      "In motor vehicle accident cases in India, a dispute over compensation may arise when the injured party or their legal representative is not satisfied with the compensation offered by the owner of the vehicle or their insurance company. The following are some common reasons for disputes over compensation:",
    ],
    points: [
      "⦁	Amount of compensation: The injured party or their legal representative may dispute the amount of compensation offered, arguing that it does not adequately cover the expenses incurred due to the accident.",
      "⦁	Liability: The injured party may dispute the liability of the owner of the vehicle, arguing that the accident was caused by the driver's negligence or fault.",
      "⦁	Delay in payment: The injured party may dispute the delay in payment of compensation by the owner of the vehicle or their insurance company.",
      "⦁	Dispute over facts: There may be a dispute over the facts of the accident, such as the location of the accident, the sequence of events leading up to the accident, or the extent of the injuries suffered.",
      "⦁	Dispute over legal provisions: There may be a dispute over the interpretation of legal provisions related to the liability and compensation in motor vehicle accidents.",
      "To resolve disputes over compensation, the injured party or their legal representative can approach the Motor Accident Claims Tribunal (MACT) to seek redressal. The MACT will hear the case, consider the evidence presented by both parties, and make a decision regarding the liability and amount of compensation to be paid.",
      "It is important to note that disputes over compensation may prolong the legal proceedings, and it is advisable to seek the assistance of a legal professional to resolve such disputes.",
    ],
  },
];
const dataLiabilityDisputes = [
  {
    pagename: "Liability Disputes ",
  },
  {
    title: "Liability Disputes ",
    subtitle:
      "In India, the Motor Vehicles Act, 1988 defines liability in motor vehicle accident cases as the legal responsibility of the owner or driver of the vehicle for the damages or injuries caused to others in the accident. The following are the key aspects of liability in motor vehicle accident cases:",
    points: [
      "⦁	No-fault liability: The liability of the owner or driver of the vehicle is considered a no-fault liability, which means that the victim does not have to prove the negligence or fault of the owner or driver to claim compensation.",
      "⦁	Third party liability insurance: The Motor Vehicles Act, 1988 makes it mandatory for all motor vehicles to have third party liability insurance to cover the liability of the owner or driver in case of an accident resulting in damage or injury to a third party.",
      "⦁	Limitation of liability: The liability of the owner of the vehicle is limited to a certain amount as prescribed by the Motor Vehicles Act, 1988. The current limit for death is Rs. 50,00,000 (50 lakhs) and for grievous hurt is Rs. 25,00,000 (25 lakhs).",
      "⦁	Exceptions to the limitation: The limitation of liability does not apply if the accident was caused due to the owner's intentional or wilful act or gross negligence. In such cases, the owner may be liable to pay compensation over and above the prescribed limit.",
      "⦁	Burden of proof: In case of a dispute over liability, the burden of proof lies with the injured party or their legal representative to prove the negligence or fault of the owner or driver.",
    ],
    footer:
      "It is important to note that liability in motor vehicle accident cases may vary depending on the specific circumstances of each case, and the final determination of liability is made by the Motor Accident Claims Tribunal (MACT).",
  },
];
const dataArbitrationConciliation = [
  {
    pagename: "Arbitration & Conciliation",
  },
  {
    title: "Arbitration & Conciliation",
    points: [
      "1. Arbitration and Conciliation in India are alternative dispute resolution mechanisms that are used to resolve disputes between parties outside of the traditional court system. Arbitration and conciliation are governed by the Arbitration and Conciliation Act, 1996, which provides a comprehensive framework for the conduct of arbitrations and conciliations in India.",
      "2. Arbitration is a process in which a dispute is referred to one or more arbitrators, who are appointed by the parties or by a designated authority. The arbitrator(s) then hear the evidence and arguments presented by the parties and make a binding decision, known as an arbitral award.",
      "3. Conciliation is a process in which a third party, known as a conciliator, assists the parties in reaching a mutually acceptable settlement of their dispute. The conciliator does not have the power to make a binding decision, but rather facilitates the negotiation process between the parties.",
      "4. Arbitration and conciliation are often preferred by parties who wish to resolve their disputes quickly and efficiently, without the costs and delays associated with traditional court proceedings. These mechanisms are particularly well-suited for commercial disputes, but can also be used to resolve disputes in other areas such as family law, labor law, and intellectual property law.",
    ],
    footer:
      "Overall, arbitration and conciliation are important alternative dispute resolution mechanisms that offer parties a flexible and efficient way to resolve their disputes outside of the traditional court system. The Indian legal system recognizes and supports these mechanisms, and the Arbitration and Conciliation Act, 1996 provides a comprehensive framework for their conduct and enforcement.",
  },
];
const dataArbitrationExecution = [
  {
    pagename: "Arbitration Execution",
  },
  {
    title: "Arbitration Execution",
    points: [
      "1. Arbitration execution in India refers to the process by which an arbitral award is enforced and executed by the parties involved in an arbitration. An arbitral award is a final and binding decision made by the arbitrator(s) in an arbitration proceeding, and is equivalent to a court judgment.",
      "2. The execution of an arbitral award is governed by the Arbitration and Conciliation Act, 1996, which provides for the enforcement of arbitral awards in India. Under the Act, an arbitral award can be enforced in the same manner as a court judgment, with the award being recognized as a decree of the court.",
    ],
  },
  {
    subtitle:
      "The execution of an arbitral award typically involves the following steps:",
    ponits: [
      "1. Filing of the award: The party seeking to enforce the arbitral award must file the award with the court, along with any necessary supporting documents.",
      "2. Verification of award: The court will then verify the award and ensure that it meets the requirements for enforcement under the Arbitration and Conciliation Act.",
      "3. Issuance of execution order: If the award is found to be enforceable, the court will issue an execution order directing the party against whom the award is made to comply with the terms of the award.",
      "4. Compliance with award: The party against whom the award is made is required to comply with the terms of the award, and failure to do so can result in further legal action.",
    ],
    footer:
      "Overall, the execution of an arbitral award in India is a straightforward process that involves the recognition of the award as a court decree and the enforcement of its terms. The Indian legal system recognizes and supports the use of arbitration as an alternative dispute resolution mechanism, and the enforcement of arbitral awards is an important aspect of this process.",
  },
];
const dataArbitrationExecutioninCommercialCasesinIndia = [
  {
    pagename: "Arbitration Execution in Commercial Cases",
  },
  {
    title: "Arbitration Execution in Commercial Cases",
    points: [
      "1. Arbitration execution in commercial cases in India refers to the process of enforcing arbitral awards in commercial disputes. Commercial disputes may arise between parties engaged in business or commercial activities, and may include disputes related to Contracts, Partnerships, Joint Ventures, Intellectual property, and other commercial matters.",
      "2. In commercial cases, the execution of an arbitral award is governed by the Arbitration and Conciliation Act, 1996, which provides a comprehensive framework for the conduct of arbitrations and the enforcement of arbitral awards in India.",
      "3. Under the Act, an arbitral award in a commercial case may be enforced in the same manner as a court judgment, with the award being recognized as a decree of the court. The party seeking to enforce the award must file the award with the court and follow the procedure for execution of the award as prescribed by law.",
    ],
  },
  {
    subtitle:
      "The execution of an arbitral award in commercial cases typically involves the following steps:",
    points: [
      "1. Filing of the award: The party seeking to enforce the arbitral award must file the award with the court, along with any necessary supporting documents.",
      "2. Verification of award: The court will then verify the award and ensure that it meets the requirements for enforcement under the Arbitration and Conciliation Act.",
      "3. Issuance of execution order: If the award is found to be enforceable, the court will issue an execution order directing the party against whom the award is made to comply with the terms of the award.",
      "4. Compliance with award: The party against whom the award is made is required to comply with the terms of the award, and failure to do so can result in further legal action.",
    ],
    footer:
      "Overall, the execution of an arbitral award in commercial cases in India is a crucial aspect of the arbitration process, as it ensures that parties to a commercial dispute are able to enforce the terms of the award and receive the compensation or relief that they are entitled to. The Indian legal system recognizes and supports the use of arbitration as an alternative dispute resolution mechanism, and the enforcement of arbitral awards is an important aspect of this process.",
  },
];
const dataProcedureforfilingofArbitrationProceedings = [
  {
    pagename: "Procedure for filing of Arbitration Proceedings",
  },
  {
    title: "Procedure for filing of Arbitration Proceedings",
    subtitle:
      "The procedure for filing of arbitration proceedings in India is as follows:",
    points: [
      "1. Drafting the Notice of Arbitration: The party initiating arbitration must first send a written notice to the other party invoking the arbitration clause of the agreement. The notice should include the nature of the dispute, the relief sought, and the appointment of an arbitrator.",
      "2. Filing the Request for Arbitration: Once the notice has been sent, the party seeking arbitration must file a Request for Arbitration with the designated arbitration institution or arbitrator, as specified in the arbitration agreement or the relevant laws. The Request for Arbitration should contain details such as the nature of the dispute, the relief sought, the names and addresses of the parties, and a copy of the arbitration agreement.",
      "3. Appointment of Arbitrator(s): The arbitration institution or the parties themselves will appoint one or more arbitrators to preside over the arbitration proceedings. The arbitrator(s) must be impartial and independent.",
      "4. Pre-Arbitration Conference: The arbitrator(s) will conduct a pre-arbitration conference to clarify the issues in dispute, set the timetable for the proceedings, and establish the procedural rules for the arbitration.",
      "5. Conducting the Arbitration Proceedings: The arbitration proceedings will be conducted in accordance with the rules and procedures agreed upon by the parties, the arbitration agreement, and the law. The parties will have the opportunity to present their evidence, call witnesses, and make submissions.",
      "6. Issuance of the Award: After considering the evidence and arguments of the parties, the arbitrator(s) will issue an award. The award is binding on the parties and is enforceable through the courts.",
      "7. Enforcement of the Award: If the losing party fails to comply with the award voluntarily, the winning party may apply to the court to enforce the award. The court will generally enforce the award unless there are grounds for setting it aside under the Arbitration and Conciliation Act, 1996.",
    ],
  },
];
const dataRegularCivilAppeal = [
  {
    pagename: "Regular Civil Appeal",
  },

  {
    title: "Regular Civil Appeal",
    points: [
      "1. Regular civil appeal cases in India refer to the appeals filed by parties dissatisfied with the decision of a lower court in a civil case. These appeals are heard by a higher court, which reviews the decision of the lower court and either upholds or overturns the decision.",
      "2. Regular civil appeals are governed by the Civil Procedure Code, which sets out the procedure for filing and hearing appeals. Under the Code, a party dissatisfied with a decision of a lower court may file an appeal within the prescribed time limit, along with any necessary documents and fees.",
      "3. Once an appeal is filed, the higher court will examine the record of the case and hear arguments from both parties. The court may also consider additional evidence or information as necessary. After hearing the arguments and reviewing the record, the court will issue a decision, which may either affirm, modify, or reverse the decision of the lower court.",
      "4. Regular civil appeals are an important aspect of the legal system in India, as they provide parties with a mechanism to challenge decisions of lower courts that they believe to be unjust or incorrect. The appeals process ensures that decisions are reviewed and scrutinized by a higher court, which helps to promote fairness and accuracy in the legal system.",
    ],
  },
];
const dataFirstAppeal = [
  {
    pagename: "First Appeal",
  },
  {
    title: "First Appeal",
    points: [
      "1. First Appeal cases in India refer to appeals filed before a higher court against the decision of a lower court. These appeals are governed by the Code of Civil Procedure and are generally filed in civil cases.",
      "2. A First Appeal is filed before the District Court against a decision made by a subordinate court. The appeal can be filed by any aggrieved party who is not satisfied with the decision of the lower court. The appeal must be filed within a specified time period, along with necessary fees and documents.",
      "3. The First Appeal is heard by a higher court, which reviews the decision of the lower court and either upholds, modifies, or reverses it. The court may also call for additional evidence or information as necessary, and may hear arguments from both parties.",
      "4. If the First Appeal is not successful, the party may file a Second Appeal before the High Court. A Second Appeal is generally only allowed in certain circumstances, such as if there has been a substantial question of law that has not been properly considered in the First Appeal.",
    ],
    footer:
      "Overall, First Appeal cases in India provide an important mechanism for parties to challenge decisions made by lower courts in civil cases. The appeals process ensures that decisions are reviewed and scrutinized by a higher court, which helps to promote fairness and accuracy in the legal system.",
  },
];
const dataSecondAppeal = [
  {
    pagename: "Second Appeal",
  },
  {
    title: "Second Appeal",
    points: [
      "1. Second Appeal cases in India refer to appeals filed before the High Court against the decision of a lower court. These appeals are governed by the Code of Civil Procedure and are generally filed in civil cases.",
      "2. A Second Appeal can be filed by any party who is not satisfied with the decision of the lower court, including a First Appellate Court. A Second Appeal is generally only allowed in certain circumstances, such as if there has been a substantial question of law that has not been properly considered in the First Appeal.",
      "3. The Second Appeal must be filed within a specified time period, along with necessary fees and documents. The High Court will review the decision of the lower court and either uphold, modify, or reverse it. The High Court may also call for additional evidence or information as necessary, and may hear arguments from both parties.",
      "4. If the Second Appeal is not successful, the party may file a Special Leave Petition before the Supreme Court. A Special Leave Petition is generally only allowed in certain circumstances, such as if there has been a substantial question of law that has not been properly considered in the Second Appeal.",
    ],
    footer:
      "Overall, Second Appeal cases in India provide an important mechanism for parties to challenge decisions made by lower courts in civil cases. The appeals process ensures that decisions are reviewed and scrutinized by a higher court, which helps to promote fairness and accuracy in the legal system.",
  },
];
const dataCommercialAppeal = [
  {
    pagename: "Commercial Appeal Cases",
  },
  {
    title: "Commercial Appeal Cases",
    points: [
      "1. Commercial Appeal cases in India refer to appeals filed before the High Court against the decision of a lower court in commercial disputes. These appeals are governed by the Commercial Courts Act, 2015 and are filed in commercial disputes that meet certain criteria, including a minimum value of the dispute.",
      "2. A Commercial Appeal can be filed by any party who is not satisfied with the decision of the lower court in a commercial dispute. The appeal must be filed within a specified time period, along with necessary fees and documents. The High Court will review the decision of the lower court and either uphold, modify, or reverse it.",
      "3. The Commercial Courts Act, 2015 provides for the speedy disposal of commercial disputes, and the appeals process is an important aspect of this process. The appeals process ensures that decisions are reviewed and scrutinized by a higher court, which helps to promote fairness and accuracy in the legal system.",
    ],
    footer:
      "Overall, Commercial Appeal cases in India provide an important mechanism for parties to challenge decisions made by lower courts in commercial disputes. The appeals process ensures that decisions are reviewed and scrutinized by a higher court, which helps to promote fairness and accuracy in the legal system.",
  },
];
const dataOtherAppeals = [
  {
    pagename: "Other Appeal Cases",
  },
  {
    title: "Other Appeal Cases",
    points: [
      "1. Other appeals cases in India refer to appeals filed before a higher court against the decision of a lower court in a wide range of cases. These appeals may be filed in civil, criminal, or other types of cases, and are governed by the relevant laws and procedures.",
      "2. The types of other appeals cases in India may include appeals against decisions made by lower courts in family law cases, labour law cases, property law cases, taxation cases, and intellectual property cases, among others.",
      "3. The appeals process in other cases generally follows a similar process to that of first and second appeals in civil cases, with the appellant filing an appeal before a higher court within a specified time period, along with necessary fees and documents. The higher court will review the decision of the lower court and either uphold, modify, or reverse it.",
    ],
    footer:
      "Overall, other appeals cases in India provide an important mechanism for parties to challenge decisions made by lower courts in a wide range of cases. The appeals process ensures that decisions are reviewed and scrutinized by a higher court, which helps to promote fairness and accuracy in the legal system.",
  },
];
const dataCivilRevision = [
  {
    pagename: "Civil Revision",
  },
  {
    title: "Civil Revision",
    points: [
      "1. Civil Revision cases in India refer to applications filed before a higher court challenging the orders passed by a subordinate court in civil cases. The Civil Revision jurisdiction is provided under the Code of Civil Procedure, 1908.",
      "2. A Civil Revision can be filed by any party aggrieved by an order passed by a subordinate court in civil proceedings, who seeks the revision of the order. The application for revision must be filed within a specified time period, along with necessary fees and documents.",
      "3. The purpose of Civil Revision cases is to ensure that there is no miscarriage of justice as a result of any irregularity, impropriety or illegality on the part of the subordinate court. The higher court will consider the application and pass an order either allowing or dismissing the revision application.",
      "4. It is important to note that the scope of revisional jurisdiction is limited and the higher court can only interfere if it finds that there was an error on the part of the subordinate court in exercising its jurisdiction or that the order passed was illegal, improper, or unjust.",
    ],
    footer:
      "Overall, Civil Revision cases in India provide an important mechanism for parties to seek a review of orders passed by subordinate courts in civil proceedings, and help to promote fairness and accuracy in the legal system.",
  },
];
const dataExecutionPetition = [
  {
    pagename: "Execution Petition",
  },
  {
    title: "Execution Petition",
    desc: [
      "Execution in civil suits in India refers to the process of enforcing a court order or decree, such as a judgment, decree, or order of a civil court. Execution is an important aspect of the legal system, as it ensures that the orders and decisions of the courts are enforced and complied with",
    ],
  },
  {
    subtitle:
      "The execution of a court order or decree in civil suits in India typically involves the following steps:",
    points: [
      "1. Filing an execution petition: The party seeking to enforce the court order or decree must file an execution petition with the court that issued the order or decree.",
      "2. Verification of petition: The court will then verify the execution petition and ensure that it meets the requirements for enforcement under the law.",
      "3. Issuance of execution order: If the petition is found to be valid, the court will issue an execution order directing the party against whom the order or decree is made to comply with the terms of the order or decree.",
      "4. Compliance with order or decree: The party against whom the order or decree is made is required to comply with the terms of the order or decree, and failure to do so can result in further legal action.",
    ],
    footer:
      "Overall, the execution of a court order or decree in civil suits in India is a crucial aspect of the legal system, as it ensures that the orders and decisions of the courts are enforced and complied with. The Indian legal system recognizes and supports the use of execution as a mechanism to enforce court orders and protect the rights of parties involved in civil disputes.",
  },
];
const dataRepaymentApplication = [
  {
    pagename: "Repayment Application",
  },
  {
    title: "Repayment Application",
    desc: [
      "Repayment application in India refers to a legal process through which a judgment-debtor can seek repayment of a debt that has been paid twice, or paid to the wrong person. The Code of Civil Procedure provides a mechanism for repayment in such cases.",
    ],
  },
  {
    subtitle:
      "Under the Code, the process for repayment typically involves the following steps:",
    points: [
      "1. Filing of repayment application: The judgment-debtor files a repayment application in the court that issued the original judgment or decree. The application typically includes details about the debt, the amount that was paid twice or to the wrong person, and the grounds for repayment.",
      "2. Notice to the other party: The court may issue a notice to the other party, giving them an opportunity to contest the repayment application.",
      "3. Hearing: The court will conduct a hearing to consider the repayment application and any objections or representations from interested parties.",
      "4. Order for repayment: If the court is satisfied that the amount has been paid twice or to the wrong person, the court may issue an order for repayment. The order typically includes details about the amount to be repaid and the method of repayment.",
    ],
    footer:
      "Repayment applications provide an important mechanism for ensuring that individuals are not required to pay debts twice, or pay debts to the wrong person. The process ensures that a fair and transparent mechanism is in place for the repayment of debts in such cases, which helps to promote the overall stability and growth of the Indian economy.",
  },
];
const dataInsolvency = [
  {
    pagename: "Insolvency Proceedings",
  },
  {
    title: "Insolvency Proceedings",
    desc: [
      "Insolvency cases in India refer to legal proceedings that are initiated when an individual, partnership or company is unable to repay its debts. The Insolvency and Bankruptcy Code, 2016 provides a consolidated framework for the insolvency resolution and bankruptcy of individuals, partnerships, and companies in India.",
    ],
  },
  {
    subtitle:
      "Under the Code, insolvency cases are typically initiated by the creditor(s) of the individual or company that has defaulted on their debts. The process involves the following steps:",
    points: [
      "1. Filing of insolvency application: The creditor(s) file an application for insolvency before the National Company Law Tribunal (NCLT) or Debt Recovery Tribunal (DRT), depending on the type of debtor.",
      "2. Appointment of interim resolution professional: Once the application is admitted, an interim resolution professional is appointed to manage the affairs of the debtor and to prepare a resolution plan.",
      "3. Meeting of creditors: The interim resolution professional convenes a meeting of creditors to seek their approval for the resolution plan.",
      "4. Submission of resolution plan: The interim resolution professional submits the resolution plan to the NCLT or DRT for approval.",
      "5. Approval of resolution plan: If the resolution plan is approved by the NCLT or DRT, it becomes binding on the debtor and the creditors.",
      "6. Liquidation: If a resolution plan cannot be approved or implemented, the debtor may be ordered to undergo liquidation, in which the assets of the debtor are sold off to repay the creditors.",
    ],
    footer:
      "Insolvency cases in India provide an important mechanism for the resolution of debt-related issues and the protection of the interests of both the debtor and creditor. The process ensures that a fair and transparent mechanism is in place for the resolution of insolvency-related issues, which helps to promote the overall stability and growth of the Indian economy.",
  },
];
const dataCustomsCentralExcise = [
  {
    pagename: "Customs & Central Excise",
  },
  {
    title: "Customs & Central Excise ",
    subtitle:
      "Customs and central excise cases in India refer to legal cases related to the imposition and collection of customs and excise duties by the Central Government of India. The following are some examples of customs and central excise cases in India:",
    points: [
      "1. Customs duty evasion: Cases where individuals or companies evade customs duty by undervaluing imported goods or mis declaring them.",
      "2. Excise duty evasion: Cases where individuals or companies evade excise duty by misrepresenting the quantity or quality of goods produced.",
      "3. Refund claims: Cases where individuals or companies claim refunds for customs or excise duties paid, due to reasons such as exports or deemed exports.",
      "4. Seizure and confiscation: Cases where goods are seized and confiscated by customs authorities due to violations of customs or excise regulations.",
      "5. Classification disputes: Cases where there are disputes over the classification of goods for the purpose of customs or excise duty.",
      "6. Valuation disputes: Cases where there are disputes over the value of imported or exported goods for the purpose of customs duty.",
    ],
    footer:
      "Customs and central excise cases in India are handled by the Customs, Excise and Service Tax Appellate Tribunal (CESTAT) and other relevant authorities such as the Directorate General of Customs and Central Excise Intelligence (DGCEI) and the Central Board of Indirect Taxes and Customs (CBIC). The aim of these cases is to ensure compliance with customs and excise regulations and to prevent revenue losses to the government.",
  },
];
const dataExportQualityandInspectionAct = [
  {
    pagename: "Export Quality and Inspection Act",
  },
  {
    title: "Export Quality and Inspection Act",
    subtitle: "",
    points: [
      "1. The Export Quality Control and Inspection Act is an Indian law that was enacted in 1963 with the objective of ensuring the quality of exports from India. The law aims to provide a legal framework for the inspection and certification of export products to ensure that they meet the required quality standards.",
      "2. The act established the Export Inspection Council (EIC), which is responsible for overseeing the inspection and certification of export goods in India. The EIC is empowered to establish and maintain laboratories and other facilities for testing and analysis of export products. It is also responsible for appointing inspection agencies to carry out inspections and issue certificates of quality.",
      "3. Under the Export Quality Control and Inspection Act, exporters of certain products are required to obtain an inspection certificate before their goods can be exported. The products covered under the act include meat, poultry, dairy products, fish and fishery products, fruits and vegetables, processed foods, and chemicals.",
      "4. The act also provides for the appointment of quality control officers who are responsible for carrying out inspections and ensuring that the export products comply with the relevant quality standards. These officers may inspect goods at various stages of production and may also take samples for testing and analysis.",
      "5. The Export Quality Control and Inspection Act is intended to promote exports from India by ensuring that export products meet the required quality standards and are competitive in the international market.",
    ],
  },
];
const dataTheCentralExciseAct = [
  {
    pagename: "The Central Excise Act",
  },
  {
    title: "The Central Excise Act",
    points: [
      "1. The Central Excise Act is a central government law in India that was enacted in 1944. The act governs the levy and collection of excise duty on goods manufactured or produced in India, with the aim of generating revenue for the government.",
      "2. The Central Excise Act establishes the Central Board of Excise and Customs (CBEC) as the central authority responsible for administering the act. The CBEC is also responsible for the formulation of policies relating to excise duty, the classification of goods, and the determination of the rates of excise duty applicable to different products.",
      "3. Under the Central Excise Act, manufacturers and producers of excisable goods are required to obtain a central excise registration and pay the appropriate duty on the goods produced or manufactured. The act also provides for the determination of the value of excisable goods and the imposition of penalties in cases of non-compliance.",
      "4. The Central Excise Act has undergone several amendments over the years to keep pace with changing economic conditions and to simplify and streamline the administration of the excise duty system. In 2017, the act was replaced by the Goods and Services Tax (GST) regime, which subsumed various indirect taxes including central excise duty, and introduced a unified tax system across India.",
    ],
    footer:
      "Overall, the Central Excise Act played a crucial role in the collection of revenue for the central government through the imposition of excise duty on goods manufactured or produced in India.",
  },
];
const dataCustomsAct = [
  {
    pagename: "Customs Act",
  },
  {
    points: [
      "1. The Customs Act is a central government law in India that was enacted in 1962 with the objective of regulating the import and export of goods in India. The act aims to ensure that the movement of goods across international borders is properly monitored, and that customs duties are collected on imported and exported goods.",
      "2. The Customs Act provides the legal framework for the levy and collection of customs duties on imported and exported goods. It establishes the Central Board of Indirect Taxes and Customs (CBIC) as the central authority responsible for administering the act. The CBIC is also responsible for formulating policies relating to customs duty, the classification of goods, and the determination of the rates of customs duty applicable to different products.",
      "3. Under the Customs Act, importers and exporters are required to comply with various procedures and formalities, including the submission of declarations, the payment of customs duties and taxes, and compliance with various regulatory requirements. The act also provides for the detention, seizure, and forfeiture of goods in cases of non-compliance.",
      "4. The Customs Act has undergone several amendments over the years to keep pace with changing economic conditions and to simplify and streamline the customs administration system. The act is an important tool for the government to regulate the movement of goods across international borders, collect customs duties, and prevent the smuggling of goods into and out of India.",
    ],
    footer:
      "Overall, the Customs Act plays a crucial role in regulating the import and export of goods in India, and ensuring compliance with various regulatory requirements and customs duties.",
  },
];
const dataTheSmugglersAndForeignExchangeManipulatorsAct = [
  {
    pagename: "The Smugglers And Foreign Exchange Manipulators Act",
  },
  {
    title: "The Smugglers And Foreign Exchange Manipulators Act",
    points: [
      "1. The Smugglers and Foreign Exchange Manipulators (Forfeiture of Property) Act, also known as SAFEMA, is a law enacted by the Indian government in 1976. The primary objective of the act is to provide for the forfeiture of property that has been acquired by smugglers, foreign exchange manipulators, and other persons involved in certain criminal activities.",
      "2. Under the act, if a person is found guilty of smuggling or foreign exchange manipulation, the government can confiscate his or her property, both movable and immovable. The act also provides for the appointment of a competent authority, which is responsible for identifying and seizing such properties. The competent authority is empowered to initiate proceedings against the person who has acquired the property by illegal means.",
    ],
  },
];
const dataForeignExchangeMaintenanceAct = [
  {
    pagename: "Foreign Exchange Maintenance Act (FEMA)",
  },
  {
    title: "Foreign Exchange Maintenance Act (FEMA)",
    points: [
      "1. The Foreign Exchange Management Act (FEMA) is a law enacted by the Indian government in 1999 to consolidate and amend the law relating to foreign exchange. The primary objective of the act is to facilitate external trade and payments, and promote the orderly development and maintenance of the foreign exchange market in India.",
      "2. Under FEMA, the Reserve Bank of India (RBI) is responsible for administering the foreign exchange regulations in the country. The act provides for the regulation of all transactions involving foreign exchange, foreign securities, and any immovable property situated outside India that is owned by a person resident in India. It also governs the establishment, operation, and winding up of branches or subsidiaries outside India by Indian companies.",
      "3. FEMA prohibits any person from dealing in foreign exchange or foreign securities without the permission of the RBI. It also prohibits the holding of any foreign exchange or foreign securities except through authorized dealers or in accordance with the general or special permission of the RBI.",
      "4. The act provides for penalties for contravention of its provisions, including fines, imprisonment, and confiscation of property. The penalties imposed under FEMA can be severe, and may include imprisonment of up to seven years and fines that may exceed the value of the property involved.",
    ],
    footer:
      "Overall, the FEMA act is an important tool in the hands of the government to regulate foreign exchange transactions and ensure that they are carried out in a lawful and orderly manner. However, it is also subject to criticism for being overly complex and difficult to comply with, and for imposing significant burdens on businesses and individuals engaged in foreign exchange transactions.",
  },
];
const dataConservationofForeignExchangeandPreventionofSmugglingActivitiesAct = [
  {
    pagename:
      "Conservation of Foreign Exchange and Prevention of Smuggling Activities Act",
  },
  {
    title:
      "Conservation of Foreign Exchange and Prevention of Smuggling Activities Act(COFEPOSA)",
    points: [
      "1. The Conservation of Foreign Exchange and Prevention of Smuggling Activities Act (COFEPOSA) is an Indian law that was enacted in 1974 to prevent and control smuggling activities and the illegal export of goods. The act empowers the government to detain any person who is suspected of engaging in smuggling activities or illegally exporting goods.",
      "2. Under COFEPOSA, the government has the power to detain any person for a period of up to one year if there is reason to believe that the person is likely to engage in smuggling activities or has already done so. The detention order can be extended for a period of up to two years if the government believes that the person continues to be a threat.",
      "3. The act also provides for the constitution of an advisory board, which is responsible for reviewing the detention orders issued under COFEPOSA. The board can recommend the release of the detained person or the extension of the detention order, based on its review of the evidence and circumstances of the case.",
      "4. COFEPOSA provides for severe penalties for violations of its provisions, including imprisonment for a period of up to seven years and fines that may exceed the value of the goods involved in the smuggling activities.",
    ],
  },
];
const dataLandAcquisitionCases = [
  {
    pagename: "Land Acquisition Cases",
  },
  {
    title: "Land Acquisition Cases",
    points: [
      "1. Land acquisition case in India refers to a legal proceeding initiated to acquire private land for public purposes, such as building infrastructure, constructing roads, and setting up industries. Land acquisition is a process by which the government acquires private land for public purposes by compensating the landowner for their loss of land and property.",
      "2. In India, land acquisition cases are governed by the Land Acquisition Act, 1894, which provides the legal framework for the acquisition of land for public purposes. The act outlines the process of land acquisition, including the notification of the acquisition, the assessment of compensation, and the payment of compensation to the landowner.",
      "3. The procedure for filing a land acquisition case involves filing a petition in the appropriate court, which can be the district court, the high court, or the Supreme Court, depending on the nature of the dispute and the jurisdiction of the court. The landowner can challenge the acquisition by arguing that the acquisition was not for a public purpose or that the compensation provided was inadequate.",
      "4. The court will consider the evidence presented by both parties and make a decision based on the facts and circumstances of the case. If the court finds that the acquisition was not for a public purpose or that the compensation provided was inadequate, it may set aside the acquisition or order a higher compensation.",
    ],
    footer:
      "Overall, land acquisition cases in India are important legal proceedings that aim to balance the public interest in acquiring land for public purposes with the rights of the landowners to be adequately compensated for their loss of land and property. The Indian legal system is designed to provide a fair and just resolution to disputes related to land acquisition, with the interests of all parties involved being taken into consideration.",
  },
];
const dataCompensationfortheLandlord = [
  {
    pagename: "Section 30 Compensation for the Landlord",
  },
  {
    title: "Section 30 Compensation for the Landlord",
    points: [
      "Section 30 of the Land Acquisition Act, 1894 in India deals with the determination of the compensation to be paid to the landowner for the acquisition of their land. It provides the framework for the assessment of the compensation to be paid to the landowner for their loss of land and property.",
      "Under section 30, the collector, who is the officer responsible for the acquisition of land, is required to determine the compensation to be paid to the landowner, after taking into consideration various factors, such as the market value of the land, the value of any standing crops or trees, and any damage to property or disturbance of business.",
      "The collector is required to make an award specifying the amount of compensation to be paid to the landowner. If the landowner is not satisfied with the amount of compensation provided, they may file an objection before the collector, who will then refer the matter to the appropriate court.",
      "The court will consider the evidence presented by both parties and make a decision based on the facts and circumstances of the case. If the court finds that the compensation provided was inadequate, it may order a higher compensation to be paid to the landowner.",
      "",
    ],
    footer:
      "Overall, section 30 of the Land Acquisition Act, 1894 is an important provision that aims to ensure that the landowner is adequately compensated for their loss of land and property. The Indian legal system is designed to provide a fair and just resolution to disputes related to land acquisition, with the interests of all parties involved being taken into consideration.",
  },
];
const dataPreliminaryNotification = [
  {
    pagename: "Section 18 Preliminary Notification",
  },
  {
    title: "Section 18 Preliminary Notification",
    points: [
      "1. Section 18 of the Land Acquisition Act, 1894 in India deals with the process of making a preliminary notification for the acquisition of land. It provides the framework for the acquisition of land for public purposes by the government.",
      "2. Under section 18, the appropriate government must publish a notification in the official gazette, stating that the government intends to acquire a particular land or property for a public purpose. The notification must also specify the location and boundaries of the land to be acquired, and the purpose for which it is intended to be used.",
      "3. Once the notification is published, the collector, who is the officer responsible for the acquisition of land, is required to give notice to the landowner, specifying the nature of the proposed acquisition and the purpose for which it is intended to be used.",
      "4. The landowner is also given the opportunity to file objections to the proposed acquisition within a specified period. The collector is then required to hold an inquiry into the objections and submit a report to the appropriate government.",
      "5. Based on the report of the collector, the appropriate government may then decide to proceed with the acquisition or to abandon it. If the government decides to proceed with the acquisition, it must issue a declaration to that effect, which is then published in the official gazette.",
    ],
    footer:
      "Overall, section 18 of the Land Acquisition Act, 1894 is an important provision that aims to ensure that the acquisition of land for public purposes is done in a transparent and fair manner. The Indian legal system is designed to provide a fair and just resolution to disputes related to land acquisition, with the interests of all parties involved being taken into consideration.",
  },
];
const dataCompensationofMarketValue = [
  {
    pagename: "Section 23 Determination Compensation of Market Value ",
  },
  {
    title: "Section 23 Determination Compensation of Market Value ",
    points: [
      "1. Section 23 of the Land Acquisition Act, 1894 in India deals with the determination of the compensation to be paid to the landowner for the acquisition of their land. It provides the framework for the assessment of the compensation to be paid to the landowner for their loss of land and property.",
      "2. Under Section 23, the collector, who is the officer responsible for the acquisition of land, is required to determine the market value of the land and the amount of compensation to be paid to the landowner, after taking into consideration various factors, such as the market value of the land, the value of any standing crops or trees, and any damage to property or disturbance of business.",
      "3. The collector is required to make an award specifying the amount of compensation to be paid to the landowner. If the landowner is not satisfied with the amount of compensation provided, they may file an objection before the collector, who will then refer the matter to the appropriate court.",
      "4. The court will consider the evidence presented by both parties and make a decision based on the facts and circumstances of the case. If the court finds that the compensation provided was inadequate, it may order a higher compensation to be paid to the landowner.",
    ],
    footer:
      "Overall, Section 23 of the Land Acquisition Act, 1894 is an important provision that aims to ensure that the landowner is adequately compensated for their loss of land and property. The Indian legal system is designed to provide a fair and just resolution to disputes related to land acquisition, with the interests of all parties involved being taken into consideration.",
  },
];
const dataRehabilitationandResettlement = [
  {
    pagename: "Rehabilitation and Resettlement cases",
  },
  {
    title: "Rehabilitation and Resettlement cases",
    desc: [
      "1. Rehabilitation and resettlement cases in Land Acquisition Act in India arise when the government acquires land for public purposes and the landowners and other affected persons are required to relocate to a new area. The Right to Fair Compensation and Transparency in Land Acquisition, Rehabilitation and Resettlement Act, 2013 (RFCTLARR) provides for the rehabilitation and resettlement of the affected persons and aims to ensure that they are adequately compensated for their loss of land and property.",
    ],
  },
  {
    subtitle:
      "If the government fails to provide for such rehabilitation and resettlement, the affected persons may file a case before the appropriate court. Some common issues that may arise in rehabilitation and resettlement cases include:",
    points: [
      "1. Adequacy of compensation: The landowners and other affected persons may challenge the adequacy of the compensation provided by the government for their loss of land and property.",
      "2. Availability of alternate land: The government may promise to provide alternate land to the affected persons for their rehabilitation and resettlement. However, if the alternate land provided is not suitable for their livelihood or is not in close proximity to their original land, the affected persons may challenge the decision.",
      "3. Implementation of rehabilitation and resettlement measures: The RFCTLARR requires the government to provide for rehabilitation and resettlement measures, such as housing, employment, and social infrastructure. If the government fails to implement these measures adequately, the affected persons may file a case challenging the same.",
    ],
    footer:
      "Overall, rehabilitation and resettlement cases in Land Acquisition Act in India aim to ensure that the affected persons are adequately compensated and rehabilitated for their loss of land and property. The Indian legal system is designed to provide a fair and just resolution to disputes related to land acquisition, with the interests of all parties involved being taken into consideration",
  },
];
const dataValidityOfAcquisition = [
  {
    pagename: "Validity Of Acquisition",
  },
  {
    title: "Validity Of Acquisition",
    desc: [
      "The validity of acquisition in Land Acquisition Act in India refers to the legal validity of the process of acquiring private land for public purposes. The Land Acquisition Act, 1894 provides for the legal framework for the acquisition of land for public purposes by the government.",
    ],
  },
  {
    subtitle:
      "The landowner may challenge the validity of the acquisition on various grounds, such as non- compliance with the legal procedures, non-existence of public purpose, or non-payment of adequate compensation. Some common issues that may arise in cases related to the validity of acquisition include:",
    points: [
      "1. Compliance with legal procedures: The government is required to follow certain legal procedures, such as issuing a preliminary notification, conducting an inquiry, and issuing a final notification for the acquisition of land. If the government fails to comply with these procedures, the landowner may challenge the validity of the acquisition.",
      "2. Public purpose: The government is required to acquire land only for public purposes, such as building infrastructure, constructing roads, and setting up industries. If the landowner believes that the acquisition is not for a public purpose, they may challenge the validity of the acquisition.",
      "3. Adequacy of compensation: The landowner is entitled to receive adequate compensation for their loss of land and property. If the compensation provided by the government is inadequate, the landowner may challenge the validity of the acquisition.",
    ],
    footer:
      "Overall, the validity of acquisition in Land Acquisition Act in India is an important legal issue that aims to ensure that the acquisition of land for public purposes is done in a transparent and fair manner. The Indian legal system is designed to provide a fair and just resolution to disputes related to land acquisition, with the interests of all parties involved being taken into consideration.",
  },
];
const dataDelayinAcquisitionProcess = [
  {
    pagename: "Delay in Acquisition Process",
  },
  {
    title: "Delay in Acquisition Process",
    desc: [
      "Delay in acquisition process in Land Acquisition Act in India refers to situations where the government delays the acquisition process without any valid reason, leading to losses for the landowner. The Land Acquisition Act, 1894 provides for the legal framework for the acquisition of land for public purposes by the government.",
    ],
  },
  {
    subtitle:
      "If the government delays the acquisition process without any valid reason, the landowner may file a case for compensation for the delay. Some common issues that may arise in cases related to the delay in the acquisition process include:",
    points: [
      "1. Validity of delay: The landowner may challenge the validity of the delay and argue that it is not justifiable. The government may provide reasons for the delay, such as environmental clearances, but if the reasons are not valid, the landowner may seek compensation.",
      "2. Calculation of compensation: The landowner may seek compensation for the delay, which may include loss of income, loss of opportunity, and increase in the value of land. The calculation of compensation may vary depending on the facts and circumstances of the case.",
      "3. Dispute resolution: The landowner may file a case before the appropriate court for compensation for the delay. The court will consider the evidence presented by both parties and make a decision based on the facts and circumstances of the case.",
    ],
    footer:
      "Overall, delay in acquisition process in Land Acquisition Act in India is an important legal issue that aims to ensure that the government acquires land for public purposes in a timely and fair manner. The Indian legal system is designed to provide a fair and just resolution to disputes related to land acquisition, with the interests of all parties involved being taken into consideration.",
  },
];
const dataCaveatApplication = [
  {
    pagename: "Caveat Application",
  },
  {
    title: "Caveat Application",
    points: [
      "1. In India, a caveat application is a legal notice that is filed by a person who is apprehensive that someone else may file a legal case against them in a court of law. The caveat application essentially allows the person to be notified if any legal proceedings are initiated against them.",
      "2. The purpose of a caveat application is to ensure that a party is given the opportunity to be heard before any orders are passed by the court. A caveat application is typically filed in cases where there is a risk of an ex-parte order being passed by the court, which means that the court may pass an order without hearing both parties.",
      "3. When a caveat application is filed, the court is required to give notice to the person who filed the application if any legal proceedings are initiated against them. This ensures that the party is given an opportunity to appear before the court and present their case.",
      "4. Caveat applications are commonly filed in civil and criminal cases, and can be filed in various courts in India, including the High Court and Supreme Court. The process of filing a caveat application involves submitting a written application to the relevant court, along with the requisite court fees.",
    ],
    footer:
      "Overall, a caveat application is an important legal tool that allows parties to be heard and ensures that the principles of natural justice are upheld in legal proceedings in India.",
  },
];
const dataCompaniesAct2013 = [
  {
    pagename: "Companies Act 2013",
  },
  {
    title: "Companies Act 2013",
    points: [
      "1. The Companies Act in India is a comprehensive law that governs the formation, management, and dissolution of companies in India. The act defines the legal framework and procedures for registering, running, and winding up companies in India. The first Companies Act was enacted in 1956, and it has since been amended several times to keep up with the changing business environment.",
      "2. The Companies Act, 2013 is the latest legislation that governs the functioning of companies in India. It replaced the Companies Act, 1956, and introduced several significant changes to the corporate governance framework in India. The act defines the legal structure for the formation, management, and dissolution of companies, the rights and duties of shareholders, directors, and officers, and the rules for conducting business.",
    ],
    footer:
      "Some of the key provisions of the Companies Act, 2013 include the requirement for companies to have a minimum of two directors, the introduction of the concept of One Person Company (OPC), the mandatory appointment of independent directors, the establishment of the National Company Law Tribunal (NCLT) and the National Company Law Appellate Tribunal (NCLAT), and the introduction of new provisions related to corporate social responsibility (CSR). The act also mandates the filing of various forms and returns with the Registrar of Companies (RoC) to ensure compliance with the law.",
  },
];
const dataWindingUpProceedings = [
  {
    pagename: "Winding Up Proceedings",
  },
  {
    title: "Winding Up Proceedings",
    desc: [
      "Winding up proceedings in India refer to the legal process by which a company is dissolved or liquidated, and its assets are distributed among its creditors and shareholders. The process of winding up can be initiated voluntarily by the company or involuntarily by its creditors or members.",
    ],
  },
  {
    subtitle: "The Companies Act, 2013 provides for two modes of winding up:",
    points: [
      "1. Voluntary Winding Up: In voluntary winding up, the company's members pass a special resolution to wind up the company. The winding-up process is supervised by a liquidator who is appointed by the members. The liquidator's role is to collect the assets of the company, settle its debts, and distribute the remaining assets among the shareholders.",
      "2. Compulsory Winding Up: In compulsory winding up, the winding-up process is initiated by a court order. This can happen when the company is unable to pay its debts, or it is in the public interest to wind up the company. The court appoints an official liquidator to take charge of the winding-up process. The official liquidator's role is similar to that of a liquidator in voluntary winding up, but they have additional powers to investigate the affairs of the company and take legal action against the company's officers if necessary.",
    ],
    footer:
      "The winding-up process can be a lengthy and complex legal procedure, involving various stakeholders and regulatory authorities. It is important for companies to comply with the legal requirements and cooperate with the liquidator or official liquidator to ensure a smooth and efficient winding-up process. The winding-up process is intended to ensure that the company's assets are distributed fairly among its creditors and shareholders, and that the company's affairs are wound up in a proper and lawful manner.",
  },
];
const dataInsolvencyProceedings = [
  {
    pagename: "Insolvency Proceedings",
  },
  {
    title: "Insolvency Proceedings",
    points: [
      "Insolvency proceedings in India refer to the legal process by which individuals or entities who are unable to pay their debts are declared insolvent and their assets are liquidated to pay off their creditors. The insolvency process in India is governed by the Insolvency and Bankruptcy Code, 2016 (IBC), which provides a comprehensive framework for dealing with insolvency and bankruptcy cases in India.",
    ],
  },
  {
    subtitle: "The IBC provides for two types of insolvency proceedings:",
    points: [
      "1. Corporate Insolvency Resolution Process (CIRP): This is the insolvency process for companies, limited liability partnerships (LLPs), and other corporate entities. The CIRP is initiated when a company defaults on its debt, and a creditor or a group of creditors files an application with the National Company Law Tribunal (NCLT) to initiate the process. The NCLT appoints an insolvency professional to manage the affairs of the company and develop a resolution plan to repay the creditors.",
      "2. Individual Insolvency Resolution Process (IIRP): This is the insolvency process for individuals and partnerships. The IIRP is initiated when an individual or partnership defaults on their debt, and a creditor or the individual or partnership themselves files an application with the Debt Recovery Tribunal (DRT) to initiate the process. The DRT appoints an insolvency professional to manage the affairs of the individual or partnership and develop a resolution plan to repay the creditors.",
    ],
    footer:
      "The insolvency process under the IBC is designed to be time-bound and creditor-friendly, with the aim of maximizing the value of the assets of the insolvent entity and ensuring a fair distribution of the proceeds among the creditors. The IBC provides for a moratorium period during which the creditors cannot take any legal action against the insolvent entity, and a resolution plan must be developed within a specified time period. If a resolution plan is not developed, the entity is liquidated, and its assets are sold to pay off the creditors.",
  },
];
const dataCorporateFraudCases = [
  {
    pagename: "Corporate Fraud Cases ",
  },
  {
    title: "Corporate Fraud Cases ",
    points: [
      "1. Corporate fraud cases in India refer to incidents where individuals or entities engage in illegal or unethical practices to deceive stakeholders, gain personal benefits, or manipulate financial statements. These cases often involve violations of various laws, including the Companies Act, Securities and Exchange Board of India (SEBI) regulations, and the Prevention of Money Laundering Act, among others.",
      "2. Corporate fraud can take many forms, including accounting fraud, insider trading, bribery and corruption, money laundering, and Ponzi schemes, among others. These practices can have serious consequences for the affected stakeholders, including shareholders, creditors, employees, and customers.",
      "3. Corporate fraud cases in India have been on the rise in recent years, leading to a growing concern about the country's corporate governance practices and regulatory oversight. The government and regulatory authorities have taken several measures to strengthen the legal framework and improve transparency in corporate transactions to prevent such incidents from occurring in the future.",
      "4. Some of the measures taken by the government and regulatory authorities to prevent corporate fraud include strengthening the Companies Act, introducing the Insolvency and Bankruptcy Code, 2016, improving the enforcement of SEBI regulations, and establishing specialized agencies such as the Serious Fraud Investigation Office (SFIO) and the National Financial Reporting Authority (NFRA).",
      "5. It is important for companies to adhere to ethical business practices and comply with the relevant laws and regulations to prevent corporate fraud and protect the interests of their stakeholders.",
    ],
    footer: "",
  },
];
const dataInsiderTrading = [
  {
    pagename: "Insider Trading",
  },
  {
    title: "Insider Trading",
    points: [
      "1. Insider trading in India refers to the illegal practice of buying or selling securities by individuals who have access to material non-public information about a company. This information could include financial results, business strategies, upcoming announcements, mergers, acquisitions, and other confidential information that could significantly affect the company's stock price.",
      '2. Insider trading is prohibited under the Securities and Exchange Board of India (Prohibition of Insider Trading) Regulations, 2015, which define insider trading as "dealing in securities of a company by its insiders based on unpublished price-sensitive information." The regulations require companies to maintain a list of designated persons who have access to unpublished price-sensitive information and prohibit them from trading in the companies securities during certain periods, known as "trading windows."',
      "3. Insider trading is a serious offense in India, and the consequences can be severe. The penalties for insider trading include fines, imprisonment, disgorgement of profits, and a ban on trading in the securities market. The Securities and Exchange Board of India (SEBI) is responsible for investigating and prosecuting cases of insider trading and has the power to impose penalties and sanctions on individuals and companies found guilty of the offense.",
      "4. In recent years, there have been several high-profile cases of insider trading in India, highlighting the need for stronger regulatory oversight and enforcement. The government and regulatory authorities have taken several measures to improve the legal framework and enhance transparency in corporate transactions to prevent insider trading and protect the interests of investors and other stakeholders.",
    ],
    footer: "",
  },
];
const dataSecurityFraud = [
  {
    pagename: "Security Fraud ",
  },
  {
    title: "Security Fraud ",
    desc: [
      "The Companies Act in India provides various provisions to prevent and punish security fraud. Security fraud in India refers to the illegal activities related to the issuance, trading, or manipulation of securities, which can harm investors and the integrity of the securities market.",
    ],
  },
  {
    subtitle:
      "The following are some of the provisions in the Companies Act, 2013 to prevent security fraud:",
    points: [
      "1. Disclosure Requirements: Companies are required to disclose all material information related to their securities, including financial performance, risks, and uncertainties, to investors and regulators. The act mandates the filing of various forms and returns with the Registrar of Companies (RoC) to ensure compliance with disclosure requirements.",
      "2. Prohibition of Insider Trading: The act prohibits insider trading, which involves the illegal buying or selling of securities by individuals who have access to material non-public information about a company. Insider trading is punishable by fines, imprisonment, disgorgement of profits, and a ban on trading in the securities market.",
      "3. Prevention of Fraudulent and Unfair Trade Practices: The act prohibits companies and their officers from engaging in fraudulent or unfair trade practices that can deceive investors or manipulate the securities market. The act also empowers SEBI to investigate and prosecute cases of fraudulent and unfair trade practices.",
      "4. Protection of Investors: The act provides various measures to protect the interests of investors, including the establishment of Investor Education and Protection Fund (IEPF) and the National Company Law Tribunal (NCLT), which hears cases related to investor grievances.",
    ],
    footer:
      "Companies found guilty of security fraud can face severe penalties, including fines, imprisonment, disgorgement of profits, and a ban on trading in the securities market. The Securities and Exchange Board of India (SEBI) and other regulatory authorities are responsible for investigating and prosecuting cases of security fraud and ensuring compliance with the provisions of the Companies Act.",
  },
];
const dataViolationofCorporateGovernanceNorms = [
  {
    pagename: "Violation of Corporate Governance Norms ",
  },
  {
    title: "Violation of Corporate Governance Norms ",
    desc: [
      "Corporate governance refers to the system of rules, practices, and processes by which a company is directed and controlled to achieve its objectives, protect the interests of its stakeholders, and ensure transparency, accountability, and fairness in its operations. Violations of corporate governance norms in India can result in severe consequences for companies and their stakeholders.",
    ],
  },
  {
    subtitle:
      "The Companies Act, 2013, and SEBI regulations provide various provisions for ensuring compliance with corporate governance norms. Some of the key corporate governance norms include:",
    points: [
      "1. Composition of Board of Directors: The act mandates the appointment of independent directors, and the presence of at least one woman director on the board of directors of certain companies. The board should also have a mix of executive and non-executive directors to ensure the proper functioning of the company.",
      "2. Audit and Risk Management: The act requires companies to have a robust internal audit system and risk management framework to identify and mitigate risks. The audit committee of the board oversees the audit process and risk management practices of the company.",
      "3. Related Party Transactions: The act requires companies to disclose all related party transactions and obtain the approval of the audit committee and the board for such transactions. Related party transactions can create conflicts of interest and lead to violations of corporate governance norms.",
      "4. Disclosures and Transparency: The act mandates the disclosure of various information related to the company's operations, including financial statements, management discussion and analysis, and corporate governance reports. The company should also have a proper system of disclosure and communication with its stakeholders.",
    ],
    footer:
      "Violations of corporate governance norms in India can result in penalties and legal action by regulatory authorities. The Securities and Exchange Board of India (SEBI) is responsible for ensuring compliance with corporate governance norms and can impose fines, penalties, and other sanctions on companies and their directors for violations. It is essential for companies to adhere to corporate governance norms to protect the interests of their stakeholders and maintain the integrity of the corporate sector in India.",
  },
];
const dataOppressionofMismanagementofCompanies = [
  {
    pagename: "Oppression of Mismanagement of Companies",
  },
  {
    title: "Oppression of Mismanagement of Companies",
    points: [
      "1. Oppression and mismanagement are two grounds for a shareholder to file a petition under Section 241 and 242 of the Companies Act, 2013, in the National Company Law Tribunal (NCLT). These provisions aim to provide a remedy to the shareholders who feel that the affairs of the company are being conducted in an oppressive or prejudicial manner, or that there is a mismanagement in the company.",
      "2. Oppression: Oppression refers to the conduct of the majority shareholders or the controlling group that unfairly prejudicial or unfairly discriminates against the interests of minority shareholders, or that is against the company's interest as a whole. Examples of oppressive conduct include denial of fundamental rights, unequal treatment, disregard of minority interests, and exclusion from management decisions.",
      "3. Mismanagement: Mismanagement refers to the inefficient, negligent, or fraudulent conduct of the company's affairs by its directors or officers, which has led to losses, misappropriation of funds, or harm to the company's interests. Examples of mismanagement include lack of accountability, breach of fiduciary duties, insider trading, and misuse of company assets.",
      "4. The NCLT has the power to pass orders for relief in cases of oppression and mismanagement, which can include removal of directors or officers, appointment of new directors, winding up of the company, and other such measures as deemed necessary. The NCLT may also order an investigation into the affairs of the company to determine the extent of oppression and mismanagement.",
      "5. The provisions of the Companies Act relating to oppression and mismanagement are designed to protect the interests of shareholders and ensure that the company's affairs are conducted in a fair and transparent manner. It is essential for companies to adhere to good corporate governance practices and maintain transparency and accountability to avoid any allegations of oppression and mismanagement.",
    ],
    footer: "",
  },
];
const dataNationalCompanyLawTribunal = [
  {
    pagename: "National Company Law Tribunal (NCLT)",
  },
  {
    title: "National Company Law Tribunal (NCLT)",
    desc: [
      "The National Company Law Tribunal (NCLT) is a quasi-judicial body established under the Companies Act, 2013, to adjudicate on disputes related to the corporate sector in India. The NCLT was established in 2016 by merging the Company Law Board (CLB) and the Board for Industrial and Financial Reconstruction (BIFR).",
    ],
  },
  {
    subtitle:
      "The NCLT has jurisdiction over various matters related to the corporate sector, including:",
    points: [
      "1. Mergers and Amalgamations: The NCLT is responsible for approving or rejecting schemes of mergers and amalgamations between companies.",
      "2. Oppression and Mismanagement: The NCLT can hear petitions filed by shareholders and other stakeholders alleging oppression and mismanagement in the affairs of the company.",
      "3. Insolvency and Bankruptcy: The NCLT is responsible for initiating insolvency proceedings against companies that have defaulted on their debt obligations.",
      "4. Winding Up: The NCLT can order the winding up of a company in cases of insolvency, or if it is just and equitable to do so.",
      "The NCLT has benches located in various cities across India, and each bench is presided over by a judicial member and a technical member. The NCLT is headed by a President, who is a retired judge of the High Court or Supreme Court of India.",
    ],
    footer:
      "The decisions of the NCLT can be appealed to the National Company Law Appellate Tribunal (NCLAT), which is a higher appellate body established under the Companies Act, 2013. The NCLT plays a crucial role in resolving disputes related to the corporate sector in India and has significantly reduced the burden on traditional courts in this regard.",
  },
];
const dataSecuritiesAppellateTribunal = [
  {
    pagename: "Securities Appellate Tribunal (SAT)",
  },
  {
    title: "Securities Appellate Tribunal (SAT)",
    points: [
      "The Securities Appellate Tribunal (SAT) is a quasi-judicial body established under the Securities and Exchange Board of India (SEBI) Act, 1992, to adjudicate on appeals against the decisions of SEBI,",
      "which is the regulator of the securities market in India. The SAT was established in 1995 to provide a specialized forum for resolving disputes related to the securities market.",
    ],
  },
  {
    subtitle:
      "The SAT has jurisdiction over various matters related to securities market regulation, including:",
    points: [
      "1. Appeals against SEBI orders: The SAT can hear appeals against the orders of SEBI, including orders related to insider trading, fraudulent and unfair trade practices, and violations of disclosure norms.",
      "2. Regulatory issues: The SAT can hear disputes related to regulatory issues such as listing requirements, delisting of securities, and takeover regulations.",
      "3. Securities market intermediaries: The SAT can hear appeals related to disputes between securities market intermediaries, such as stockbrokers, depositories, and mutual funds.",
      "The SAT is presided over by a presiding officer, who is a retired judge of the High Court, and two members who are experts in the field of securities market regulation. The decisions of the SAT are binding and can be appealed to the Supreme Court of India.",
      "The SAT plays a crucial role in ensuring that the decisions of SEBI are fair and transparent and that the interests of investors and other stakeholders in the securities market are protected. ",
      "The establishment of the SAT has helped in reducing the burden on traditional courts and providing a specialized forum for resolving disputes related to the securities market in India.",
    ],
  },
];
const dataIncomeTaxAppellateTribunal = [
  {
    pagename: "Income Tax Appellate Tribunal (ITAT)",
  },
  {
    title: "Income Tax Appellate Tribunal (ITAT)",
    desc: [
      "The Income Tax Appellate Tribunal (ITAT) is a quasi-judicial body established under the Income Tax Act, 1961, to adjudicate on appeals against the orders of the Income Tax Department. The ITAT was established in 1941 and is the second appellate authority in the hierarchy of courts dealing with income tax matters in India.",
    ],
  },
  {
    subtitle:
      "The ITAT has jurisdiction over various matters related to income tax, including:",
    points: [
      "1. Appeals against assessment orders: The ITAT can hear appeals against assessment orders passed by the Income Tax Department, including orders related to tax liability, penalty, and interest.",
      "2. Appeals against penalty orders: The ITAT can hear appeals against penalty orders passed by the Income Tax Department, including orders related to concealment of income and filing of incorrect returns.",
      "3. Appeals against rectification orders: The ITAT can hear appeals against rectification orders passed by the Income Tax Department, which correct errors or omissions in assessment orders.",
      "The ITAT is presided over by a president and vice-president, who are retired judges of the High Court or senior income tax officials. The decisions of the ITAT are binding on the Income Tax Department and the taxpayer and can be appealed to the High Court or the Supreme Court of India.",
      "The ITAT plays a crucial role in ensuring that the decisions of the Income Tax Department are fair and transparent and that the interests of taxpayers are protected. The establishment of the ITAT has helped in reducing the burden on traditional courts and providing a specialized forum for resolving disputes related to income tax matters in India.",
    ],
    footer: "",
  },
];
const dataCentralAdministrativeTribunal = [
  {
    pagename: "Central Administrative Tribunal (CAT)",
  },
  {
    title: "Central Administrative Tribunal (CAT)",
    desc: [
      "The Central Administrative Tribunal (CAT) is a quasi-judicial body established under the Administrative Tribunals Act, 1985, to adjudicate on disputes related to the recruitment, service conditions, and other administrative matters of employees of the Central Government and public sector undertakings.",
    ],
  },
  {
    subtitle:
      "The CAT has jurisdiction over various matters related to the administrative matters of government employees, including:",
    points: [
      "1. Recruitment and Promotion: The CAT can hear appeals related to the recruitment and promotion of government employees, including disputes related to selection procedures, eligibility criteria, and seniority.",
      "2. Service Conditions: The CAT can hear disputes related to the service conditions of government employees, including disputes related to pay and allowances, leave, retirement benefits, and disciplinary proceedings.",
      "3. Other Administrative Matters: The CAT can also hear appeals related to other administrative matters, including disputes related to transfers, suspension, and termination of employment.",
      "The CAT is presided over by a chairman and vice-chairman, who are retired judges of the High Court or Supreme Court of India, and members who are experts in the field of administrative law. The decisions of the CAT are binding on the government and the aggrieved employees and can be appealed to the High Court or the Supreme Court of India.",
      "The CAT plays a crucial role in ensuring that the service conditions and other administrative matters of government employees are fair and transparent and that the interests of employees are protected.",
    ],
    footer:
      "The establishment of the CAT has helped in reducing the burden on traditional courts and providing a specialized forum for resolving disputes related to the administrative matters of government employees in India.",
  },
];
const dataIntellectualPropertyAppellateBoard = [
  {
    pagename: "Intellectual Property Appellate Board (IPAB)",
  },
  {
    title: "Intellectual Property Appellate Board (IPAB)",
    desc: [
      "The Intellectual Property Appellate Board (IPAB) is a quasi-judicial body established under the Trade Marks Act, 1999, to adjudicate on appeals against the decisions of the Registrar of Trademarks and the Controller of Patents. The IPAB was established in 2003 to provide a specialized forum for resolving disputes related to intellectual property rights in India.",
    ],
  },
  {
    subtitle:
      "The IPAB has jurisdiction over various matters related to intellectual property rights, including:",
    points: [
      "1. Trademarks: The IPAB can hear appeals against the decisions of the Registrar of Trademarks related to the registration and infringement of trademarks.",
      "2. Patents: The IPAB can hear appeals against the decisions of the Controller of Patents related to the grant and revocation of patents.",
      "3. Copyrights: The IPAB can hear appeals against the decisions of the Copyright Board related to the registration and infringement of copyrights.",
      "The IPAB is presided over by a chairman and vice-chairman, who are experts in the field of intellectual property law, and members who are experts in the fields of law, science, and technology. The decisions of the IPAB are binding on the Registrar of Trademarks, the Controller of Patents, and the Copyright Board and can be appealed to the High Court or the Supreme Court of India.",
      "The IPAB plays a crucial role in ensuring that the decisions related to intellectual property rights are fair and transparent and that the interests of intellectual property owners and users are protected. The establishment of the IPAB has helped in reducing the burden on traditional courts and providing a specialized forum for resolving disputes related to intellectual property rights in India.",
    ],
  },
];
const dataTribunalforForfeitedProperty = [
  {
    pagename: "Tribunal for Forfeited Property",
  },
  {
    title: "Tribunal for Forfeited Property",
    points: [
      "1. The Appellate Tribunal for Forfeited Property is a legal body in India established under the Smugglers and Foreign Exchange Manipulators (Forfeiture of Property) Act, 1976. Its primary function is to hear and decide on appeals against orders of forfeiture passed by the Competent Authority under the Act.",
      "2. The Competent Authority, appointed by the Central Government, has the power to seize and forfeit any property which is believed to be the proceeds of smuggling or foreign exchange manipulation. Such forfeited properties are then transferred to the custody of the Appellate Tribunal.",
      "3. The Appellate Tribunal is composed of a chairperson and such other members as the Central Government may appoint. The Chairperson must be a person who is, or has been, a judge of a High Court, and the other members must be persons who are, or have been, officers of the Indian Revenue Service or the Indian Legal Service.",
      "4. Any person aggrieved by an order of the Competent Authority may file an appeal before the Appellate Tribunal within 45 days of the order. The Tribunal is empowered to examine the legality and correctness of the forfeiture order and can uphold, modify, or reverse the order as it deems fit.",
      "5. The decisions of the Appellate Tribunal are final and binding, subject to the provision for appeal to the High Court on a point of law. The Tribunal is an important institution in the fight against illegal activities such as smuggling and foreign exchange manipulation, and its role in the forfeiture of properties earned through such activities is significant in the Indian legal system.",
    ],
    footer: "",
  },
];
const dataTribunalforNDPS = [
  {
    pagename: "Tribunal for NDPS",
  },
  {
    title: "Tribunal for NDPS",
    points: [
      "1. The Appellate Tribunal for NDPS (Narcotic Drugs and Psychotropic Substances) in India is a quasi- judicial body established under the provisions of the Narcotic Drugs and Psychotropic Substances Act, 1985. The tribunal is responsible for hearing appeals against orders passed by the Adjudicating Authorities under the Act, which include confiscation of property, imposition of fines and penalties, and other related matters.",
      "2. The Appellate Tribunal for NDPS consists of a Chairperson and two members, who are appointed by the central government. The Chairperson must be a sitting or retired judge of a High Court, while the members must have experience in dealing with cases related to narcotic drugs and psychotropic substances.",
      "3. The tribunal has the power to regulate its own procedure, and its decisions are final and binding. It also has the power to issue directions, review its own decisions, and pass orders for the enforcement of its decisions.",
    ],
    footer:
      "Overall, the Appellate Tribunal for NDPS plays a crucial role in ensuring that the provisions of the Narcotic Drugs and Psychotropic Substances Act are properly implemented and enforced in India.",
  },
];
const dataTribunalforPMLA = [
  {
    pagename: "Tribunal for PMLA",
  },
  {
    title: "Tribunal for PMLA",
    points: [
      "1. The Appellate Tribunal for PMLA (Prevention of Money Laundering Act) in India is a quasi-judicial body that hears appeals against orders passed by the Adjudicating Authority under the PMLA, 2002. The tribunal was established under Section 25 of the PMLA and is responsible for adjudicating on matters related to money laundering and confiscation of proceeds of crime.",
      "2. The Appellate Tribunal for PMLA consists of a Chairperson and at least two members, who are appointed by the central government. The Chairperson must be a sitting or retired judge of a High Court, while the members must have experience in dealing with cases related to money laundering.",
      "3. The tribunal has the power to regulate its own procedure and has the same powers as a civil court. It can summon and enforce the attendance of witnesses, examine them on oath, and compel the production of documents. The tribunal also has the power to review its own decisions and pass orders for the enforcement of its decisions.",
    ],
    footer:
      "Overall, the Appellate Tribunal for PMLA plays a critical role in ensuring that the provisions of the PMLA are properly implemented and enforced in India. Its decisions are final and binding, and it has the power to levy heavy penalties and confiscate assets acquired through money laundering activities.",
  },
];
const dataTribunalforArmedForce = [
  {
    pagename: "Tribunal for Armed Force",
  },
  {
    title: "Tribunal for Armed Force",
    points: [
      "1. The Appellate Tribunal for Armed Forces in India is a quasi-judicial body established under the Armed Forces Tribunal Act, 2007. The tribunal hears appeals and petitions related to service matters and disputes of the members of the armed forces in India.",
      "2. The Appellate Tribunal for Armed Forces has jurisdiction over all members of the Army, Navy, and Air Force, including retired personnel and their families. It also has jurisdiction over certain civilian employees working for the armed forces.",
      "3. The tribunal is headed by a Chairperson who is a retired judge of the Supreme Court or a retired Chief Justice of a High Court. The other members of the tribunal include judicial and administrative members who have experience in dealing with matters related to the armed forces.",
      "4. The tribunal has the power to hear appeals against orders and decisions of the Service Tribunal and other authorities related to service matters, including promotions, appointments, and disciplinary matters. It also has the power to review its own decisions and issue directions for the enforcement of its orders.",
    ],
    footer:
      "Overall, the Appellate Tribunal for Armed Forces plays a crucial role in ensuring that the rights and interests of the members of the armed forces are protected, and their disputes and grievances are addressed in a fair and just manner.",
  },
];
const dataTribunalforDebtRecoveryTribunal = [
  {
    pagename: "Tribunal for Debt Recovery Tribunal ",
  },
  {
    title: "Tribunal for Debt Recovery Tribunal ",
    points: [
      "1. The Debt Recovery Tribunal (DRT) in India is a quasi-judicial body established under the Recovery of Debts Due to Banks and Financial Institutions Act, 1993. The DRT is responsible for hearing and deciding on cases related to the recovery of unpaid loans and debts of banks and financial institutions.",
      "2. The Debt Recovery Tribunal is headed by a Presiding Officer, who is a qualified judicial officer appointed by the central government. The tribunal has the power to regulate its own procedures and has the same powers as a civil court. It can summon and enforce the attendance of witnesses, examine them on oath, and compel the production of documents.",
      "3. The DRT has jurisdiction over cases where the amount of debt or loan due is more than Rs. 20 lakhs. It also has jurisdiction over cases involving guarantees, securities, or hypothecated property. The tribunal has the power to pass orders for the recovery of the outstanding amount, including the sale of the secured assets and other measures to enforce the order.",
      "4. The Debt Recovery Tribunal plays a critical role in the banking and financial sector of India, as it helps in the speedy recovery of unpaid loans and debts, which in turn improves the financial health of banks and financial institutions. Its decisions are binding, and it has the power to impose penalties and sanctions for non-compliance with its orders.",
    ],
  },
];
const dataTribunalforCopyrightBoard = [
  {
    pagename: "Tribunal for Copyright Board",
  },
  {
    title: "Tribunal for Copyright Board",
    points: [
      "1. The Copyright Board in India is a quasi-judicial body established under the Copyright Act, 1957. The Board is responsible for hearing and adjudicating on matters related to copyright, including registration of works, licensing, and disputes related to royalty and copyright infringement.",
      "2. The Copyright Board consists of a Chairperson and a minimum of two and a maximum of fourteen members, who are appointed by the central government. The Chairperson must be a person who is or has been a judge of a High Court, while the members must have expertise in copyright law, music, literature, or the fine arts.",
      "3. The Copyright Board has the power to regulate its own procedures and has the same powers as a civil court. It can summon and enforce the attendance of witnesses, examine them on oath, and compel the production of documents. The Board also has the power to review its own decisions and pass orders for the enforcement of its decisions.",
    ],
    footer:
      "Overall, the Copyright Board plays a critical role in protecting and promoting creativity and innovation in India. Its decisions are binding, and it has the power to levy penalties and impose sanctions for non- compliance with copyright laws.",
  },
];
const dataNationalGreenTribunal = [
  {
    pagename: "National Green Tribunal ",
  },
  {
    title: "National Green Tribunal",
    points: [
      "1. The National Green Tribunal (NGT) in India is a specialized judicial body established under the National Green Tribunal Act, 2010. The NGT is responsible for hearing and deciding on cases related to environmental protection and conservation.",
      "2. The National Green Tribunal consists of a Chairperson, who is a retired judge of the Supreme Court of India, and other judicial and expert members who have expertise in environmental matters. The tribunal has its principal bench in New Delhi and regional benches in various cities across India.",
      "3. The NGT has the power to hear cases related to the enforcement of laws related to environmental protection and conservation, including the Water (Prevention and Control of Pollution) Act, 1974, the Air (Prevention and Control of Pollution) Act, 1981, and the Environment (Protection) Act, 1986. The tribunal has the power to issue directions, impose fines and penalties, and pass orders for the protection and conservation of the environment.",
      "4. The National Green Tribunal plays a crucial role in protecting and conserving the environment in India. Its decisions are binding, and it has the power to levy heavy penalties and impose sanctions for non- compliance with its orders. The tribunal is also responsible for ensuring that the principles of sustainable development and intergenerational equity are upheld in environmental matters.",
    ],
    footer: "",
  },
];
const dataSEBI = [
  {
    pagename: "SEBI",
  },
  {
    title: "SEBI",
    points: [
      "1. SEBI stands for the Securities and Exchange Board of India. It is the regulatory body for the securities market in India, established under the Securities and Exchange Board of India Act, 1992.",
      "2. The primary objective of SEBI is to protect the interests of investors in the securities market and to promote the development of the securities market in India. It regulates various entities in the securities market, including stock exchanges, brokers, merchant bankers, mutual funds, and other intermediaries.",
      "3. SEBI is responsible for regulating and overseeing the functioning of the securities market in India by formulating policies and regulations, conducting inspections and investigations, and imposing penalties for non-compliance. It also provides guidelines and regulations for disclosure and transparency in the securities market, to ensure fair and equal treatment for all investors.",
      "4. In addition, SEBI also has the responsibility of promoting investor education and awareness, and protecting the interests of investors in the securities market. It operates various initiatives and programs to educate investors on their rights and responsibilities, and to provide them with the necessary information and knowledge to make informed investment decisions.",
    ],
    footer:
      "Overall, SEBI plays a critical role in ensuring the proper functioning of the securities market in India and in protecting the interests of investors.",
  },
];
const dataTrademarkRegistry = [
  {
    pagename: "Trademark Registry",
  },
  {
    title: "Trademark Registry",
    points: [
      "1. The Trade Marks Registry in India is a government agency responsible for the registration and protection of trademarks under the Trade Marks Act, 1999. The registry operates under the Department for Promotion of Industry and Internal Trade (DPIIT), Ministry of Commerce and Industry, Government of India",
      "2. The Trade Marks Registry is responsible for the examination of trademark applications, registration of trademarks, and maintenance of the trademark registry. It maintains a database of registered trademarks and provides information on trademark registration, searches, and renewals.",
      "3. The Trade Marks Registry also plays a crucial role in protecting the rights of trademark owners in India. It has the power to cancel or rectify the registration of trademarks in case of non-use, infringement, or other violations. It also has the power to impose penalties and sanctions for the violation of trademark laws.",
      "4. Overall, the Trade Marks Registry in India is responsible for the administration and enforcement of trademark laws in India. Its primary objective is to protect the interests of trademark owners and to promote the development and growth of businesses and industries in India through the registration and protection of trademarks.",
    ],
  },
];
const dataRERA = [
  {
    pagename: "RERA",
  },
  {
    title: "RERA",
    points: [
      "1. RERA stands for the Real Estate (Regulation and Development) Act, 2016. It is a regulatory framework for the real estate sector in India, aimed at protecting the interests of homebuyers and promoting transparency and accountability in the real estate industry.",
      "2. RERA provides for the establishment of a regulatory authority in each state and union territory of India, to regulate and oversee the real estate sector in the respective regions. The regulatory authority is responsible for the registration and regulation of real estate projects and real estate agents, and for resolving disputes between homebuyers and developers.",
      "3. Under RERA, developers are required to register their real estate projects with the regulatory authority, and provide all relevant information about the project, including its completion date, carpet area, and other details. The regulatory authority is responsible for ensuring that the developers adhere to the rules and regulations under RERA, and for imposing penalties and sanctions for non-compliance.",
      "4. RERA also provides for the establishment of a Real Estate Appellate Tribunal in each state and union territory, to hear appeals against the decisions of the regulatory authority. The Appellate Tribunal is headed by a judicial officer, and has the power to hear and decide on appeals related to RERA.",
    ],
    footer:
      "Overall, RERA plays a crucial role in regulating and overseeing the real estate sector in India. It has improved the transparency and accountability of the real estate industry, and has provided a mechanism for resolving disputes between homebuyers and developers.",
  },
];
const dataWakfBoardSignificance = [
  {
    pagename: "Wakf Board Significance",
  },
  {
    title: "Wakf Board Significance",
    points: [
      "1. In India, a Wakf Board is a legal entity that is responsible for the management and administration of properties dedicated to Islamic religious or charitable purposes, known as Wakf properties. The term 'Wakf' refers to a permanent dedication of movable or immovable property for religious or charitable purposes under Islamic law.",
      "2. The Wakf Board is established by the state government under the provisions of the Wakf Act, 1995, which is the primary legislation governing Wakf properties in India. The Act provides for the constitution and functions of Wakf Boards, and also defines the powers and duties of the Boards.",
      "3. The Wakf Board is responsible for the registration, maintenance, and preservation of Wakf properties, and for ensuring that they are used for the purposes for which they were dedicated. The Board is also authorized to supervise and control the management of the Wakf properties, and to take action against any misuse or mismanagement.",
      "4. The Wakf Board is headed by a Chairman, who is appointed by the state government, and includes members who are nominated by the government and elected by the community. The Board is required to hold regular meetings, maintain accounts and records, and submit an annual report to the state government.",
      "5. The Wakf Board is an important institution for the protection and promotion of Wakf properties in India, and for ensuring that they are used for the benefit of the community. The Board plays a crucial role in preserving the cultural and religious heritage of the Muslim community, and in promoting social welfare and charitable activities.",
    ],
  },
  {
    subtitle:
      "There are several types of cases related to Wakf Boards in India. Here are some of the most common types of cases:",
    points: [
      "1. Disputes over Wakf property ownership: These cases involve disputes over the ownership of Wakf properties, such as land, buildings, and other assets. The disputes may arise between different Wakf Boards, between Wakf Boards and other organizations, or between members of the Muslim community.",
      "2. Mismanagement of Wakf properties: These cases involve allegations of mismanagement or misuse of Wakf properties. The allegations may include corruption, embezzlement, or diversion of funds meant for charitable purposes.",
      "3. Encroachment on Wakf properties: These cases involve allegations of unauthorized occupation or encroachment on Wakf properties. The allegations may include construction of illegal buildings or structures on Wakf properties or the diversion of Wakf lands for other purposes.",
      "4. Appointment of Wakf Board members: These cases involve disputes over the appointment of members to the Wakf Board. The disputes may arise over the eligibility criteria for board membership or the procedures for nomination or election of board members.",
      "5. Violation of Wakf Board rules and regulations: These cases involve violations of the rules and regulations governing the functioning of Wakf Boards. The violations may include failure to hold regular meetings, maintain records and accounts, or submit annual reports.",
    ],
    footer:
      "These cases are typically heard in the High Courts or Supreme Court of India, depending on the nature and location of the dispute. The courts play a crucial role in regulating the Wakf Board and ensuring that Wakf properties are managed in a transparent and accountable manner. The courts also help to resolve disputes and uphold the rights of the Muslim community in the management of Wakf properties.",
  },
];
const dataWritsSignificance = [
  {
    pagename: "Writs Significance",
  },
  {
    title: "Writs Significance",
    points: [
      "1. A writ petition in India is a formal written application made to the Supreme Court or a High Court, seeking the issuance of a writ or order for the enforcement of fundamental rights or legal rights of an individual or a group of individuals. Writ petitions are an important mechanism to seek legal remedy and protection of fundamental rights guaranteed under the Indian Constitution.",
      "2. The Constitution of India provides for five types of writs - Habeas Corpus, Mandamus, Certiorari, Prohibition, and Quo Warranto - to protect fundamental rights of citizens. These writs are issued by the Supreme Court or High Courts under Article 32 and Article 226 respectively.",
      "3. A writ petition can be filed by any person or organization, including individuals, non-governmental organizations, and government bodies, who feel that their fundamental rights have been violated by the actions or inactions of any public authority or individual. The petition must clearly state the legal grounds on which relief is being sought and must be accompanied by relevant documents supporting the petitioner's case.",
      "4. Upon receiving the petition, the court will review the documents, consider the arguments presented, and decide whether to issue the writ or order requested. The court may also hear oral arguments from the petitioner and respondent before making a decision.",
      "5. Writ petitions are an important tool for the protection of fundamental rights and the rule of law in India. They enable individuals to challenge arbitrary and unconstitutional actions by public authorities and ensure that the government operates within the confines of the Constitution.",
    ],
  },
];
const dataHabeausCorpus = [
  {
    pagename: "Habeaus Corpus",
  },
  {
    title: "Habeaus Corpus",
    points: [
      '1. Habeas Corpus is a Latin term that means "you shall have the body". In legal terms, it refers to a writ, which is a court order, that can be issued by a court or judicial authority to produce a person who has been arrested or detained, and to ensure that they are not being held unlawfully or deprived of their liberty without sufficient cause or due process.',
      "2. In India, the right to habeas corpus is a fundamental right guaranteed by the Constitution under Article 21, which states that no person shall be deprived of their life or personal liberty except according to procedure established by law. This right allows any person who has been unlawfully detained to approach the court for relief and to seek their immediate release.",
      "3. A writ of habeas corpus can be filed by the person who is being detained or by anyone else on their behalf. The writ can be filed in the High Court or Supreme Court of India, depending on the nature and location of the detention.",
      "4. Once the writ is filed, the court will issue a notice to the detaining authority to produce the detained person before the court. The court will then review the case and determine whether the detention is lawful or not. If the court finds that the detention is unlawful, it will order the immediate release of the detained person.",
      "5. The right to habeas corpus is a crucial safeguard against arbitrary detention and is essential for protecting the personal liberty and freedom of individuals in India.",
    ],
  },
];
const dataMandamus = [
  {
    pagename: "Mandamus",
  },
  {
    title: "Mandamus",
    points: [
      '1. Mandamus is a Latin term that means "we command". In legal terms, it refers to a writ, which is a court order, that can be issued by a court or judicial authority to a public official, government agency, or lower court to perform a specific duty that they are legally obligated to perform but have failed to do so.',
      "2. In India, the writ of mandamus is issued by the High Court or Supreme Court under Article 226 of the Constitution of India. This writ is generally used to direct government officials or public authorities to perform their legal duties or to refrain from acting outside their legal authority.",
      "3. A writ of mandamus can be filed by any person or organization that has a legal interest in the performance of the duty that is being sought. The petition must clearly state the legal duty that the official or agency is required to perform, and must also show that the duty is mandatory and not discretionary.",
      "4. Once the writ is filed, the court will review the case and determine whether the official or agency has a legal duty to perform the action that is being sought. If the court finds that the duty is mandatory and has not been performed, it will issue a writ of mandamus directing the official or agency to perform the duty immediately.",
      "5. The writ of mandamus is an important tool for holding government officials and agencies accountable for their legal duties, and for ensuring that they act within the confines of the law. It is a key element of the Indian legal system that helps to ensure the rule of law and the protection of citizens' rights.",
    ],
  },
];
const dataProhibition = [
  {
    pagename: "Prohibition",
  },
  {
    title: "Prohibition",
    points: [
      "1. Prohibition, in the context of a writ petition, refers to a writ, which is a court order, that can be issued by a court or judicial authority to a lower court or tribunal, prohibiting it from exceeding its jurisdiction or acting in excess of its legal authority.",
      "2. In India, the writ of prohibition is issued by the High Court or Supreme Court under Article 226 of the Constitution of India. This writ is generally used to prevent a lower court or tribunal from exceeding its jurisdiction or acting beyond the powers conferred upon it by law.",
      "3. A writ of prohibition can be filed by any person or organization that has a legal interest in the matter and who feels that the lower court or tribunal is acting outside its legal authority. The petition must clearly state the legal basis for the writ, and must show that the lower court or tribunal is acting in excess of its jurisdiction.",
      "4. Once the writ is filed, the court will review the case and determine whether the lower court or tribunal is acting beyond its legal authority. If the court finds that the lower court or tribunal is acting outside its jurisdiction or beyond its legal authority, it will issue a writ of prohibition prohibiting the lower court or tribunal from continuing with the proceedings.",
      "5. The writ of prohibition is an important tool for maintaining the rule of law and ensuring that lower courts and tribunals act within their legal authority. It helps to prevent abuse of power by lower courts and tribunals and ensures that justice is administered in a fair and impartial manner.",
    ],
  },
];
const dataCertiorari = [
  {
    pagename: "Certiorari",
  },
  {
    title: "Certiorari",
    points: [
      "1. Certiorari, in the context of a writ petition, refers to a writ, which is a court order, that can be issued by a court or judicial authority to a lower court or tribunal, quashing an order or decision that it has made in excess of its jurisdiction or beyond the powers conferred upon it by law.",
      "2. In India, the writ of certiorari is issued by the High Court or Supreme Court under Article 226 of the Constitution of India. This writ is generally used to review the decisions of lower courts or tribunals and to quash any order or decision that is made beyond the scope of their legal authority.",
      "3. A writ of certiorari can be filed by any person or organization that has a legal interest in the matter and who feels that the lower court or tribunal has acted outside its legal authority. The petition must clearly state the legal basis for the writ, and must show that the lower court or tribunal has acted in excess of its jurisdiction or beyond the powers conferred upon it by law.",
      "4. Once the writ is filed, the court will review the case and determine whether the lower court or tribunal has acted beyond its legal authority. If the court finds that the lower court or tribunal has exceeded its jurisdiction or acted beyond its powers, it will issue a writ of certiorari quashing the order or decision.",
      "5. The writ of certiorari is an important tool for maintaining the rule of law and ensuring that lower courts and tribunals act within their legal authority. It helps to prevent abuse of power by lower courts and tribunals and ensures that justice is administered in a fair and impartial manner.",
    ],
  },
];
const dataiprResolutions = [
  {
    pagename: "IPR Resolutions",
  },
  {
    title: "IPR Resolutions",
    points: [
      "1. IPR (Intellectual Property Rights) refers to the legal rights that are granted to individuals or entities for their creations or inventions that are a result of their intellect or creativity. Intellectual property includes patents, trademarks, copyrights, industrial designs, geographical indications, and trade secrets. These rights enable the creators to control and commercialize their creations, and prevent others from using them without their consent.",
      "2. In India, the legal framework for intellectual property is governed by various acts, including the Patents Act, the Trade Marks Act, the Copyright Act, the Geographical Indications of Goods (Registration and Protection) Act, and the Designs Act. The main objective of these acts is to provide protection to the creators of intellectual property and encourage innovation, creativity, and economic growth in the country.",
      "3. The Indian government has also established institutions such as the Intellectual Property India, which is responsible for the administration of the intellectual property rights system in India, and the National Intellectual Property Rights Policy, which aims to create a comprehensive and dynamic intellectual property ecosystem in the country.",
    ],
  },
];
const dataInfringement = [
  {
    pagename: "Trademark Infringement",
  },
  {
    title: "Trademark Infringement",
    points: [
      "1. Trademark infringement cases in India arise when someone uses a trademark that is identical or similar to a registered trademark without the owner's permission or authorization. A trademark is a distinctive symbol, logo, design, word, or phrase that is used by a business or an individual to identify and distinguish their products or services from those of others.",
      "2. Under the Indian Trademarks Act, 1999, a trademark owner has the exclusive right to use their trademark and prevent others from using it without their consent. Any unauthorized use of a trademark that causes confusion among consumers or is likely to dilute the distinctive character of the trademark is considered trademark infringement.",
      "3. To prove trademark infringement in India, the trademark owner must demonstrate that the defendant's use of the mark is likely to cause confusion among consumers or dilute the distinctive character of the trademark. The trademark owner can file a lawsuit in the appropriate court and seek various remedies, including injunctions, damages, and accounts of profits.",
      "4. Trademark infringement cases in India can be complex and require expert legal advice. It is advisable for businesses and individuals to register their trademarks and take steps to protect their intellectual property rights to avoid trademark infringement disputes.",
    ],
  },
];
const dataCopyrightCases = [
  {
    pagename: "Copyright Infringement Cases",
  },
  {
    title: "Copyright Infringement Cases",
    points: [
      "1. Copyright infringement cases in India arise when someone uses, reproduces, distributes, or displays a copyrighted work without the owner's permission or authorization. A copyright is a legal right granted to the creator of an original work of literature, music, film, art, or other creative works to control how their work is used and prevent others from using it without their consent.",
      "2. Under the Indian Copyright Act, 1957, a copyright owner has the exclusive right to reproduce, distribute, perform, display, or make derivative works based on their copyrighted work. Any unauthorized use of a copyrighted work without the owner's consent is considered copyright infringement.",
      "3. To prove copyright infringement in India, the copyright owner must demonstrate that the defendant has used their copyrighted work without authorization and that the use falls within the scope of the exclusive rights granted to the copyright owner. The copyright owner can file a lawsuit in the appropriate court and seek various remedies, including injunctions, damages, and accounts of profits.",
      "4. Copyright infringement cases in India can be complex and require expert legal advice. It is advisable for creators of original works to register their copyright and take steps to protect their intellectual property rights to avoid copyright infringement disputes.",
    ],
  },
];
const dataMinesandMineralsSignificance = [
  {
    pagename: "Mines and Minerals Significance",
  },
  {
    title: "Mines and Minerals Significance",
    points: [
      "1. In India, Mines and Minerals refer to natural resources found beneath the earth's surface, such as minerals, coal, oil, natural gas, and similar resources. The regulation of mines and minerals falls under the purview of the Ministry of Mines, which is responsible for formulating policies and laws related to the mining sector.",
      "2. The Mines and Minerals (Development and Regulation) Act, 1957, is the primary legislation governing the mining sector in India. This law provides for the regulation of the mining industry, including the allocation of mineral rights, the grant of mining leases, and the conservation and development of mineral resources.",
      "3. The Ministry of Mines also manages the Indian Bureau of Mines (IBM), which is responsible for the scientific exploration and assessment of mineral resources. IBM carries out mineral exploration, mapping, and research to identify potential mining areas and assess the quality and quantity of mineral reserves.",
      "4. In India, the mining industry plays a vital role in the economy, contributing significantly to the country's GDP and providing employment opportunities to millions of people. The mining sector is a key source of revenue for the government through the collection of royalties, taxes, and fees.",
      "5. However, the mining sector in India also faces several challenges, including issues related to illegal mining, environmental degradation, and the displacement of local communities. The government has taken several measures to address these challenges, including the enactment of laws and regulations to prevent illegal mining, and the promotion of sustainable mining practices.",
    ],
  },
  {
    subtitle:
      "There are several types of cases related to mines and minerals in India. Here are some of the most common types of cases:",
    points: [
      "1. Environmental Cases: These cases deal with environmental concerns related to mining, such as air and water pollution, forest destruction, and damage to wildlife habitats. Environmental cases are typically filed by environmental groups or affected communities.",
      "2. Mineral Rights Cases: These cases involve disputes over the ownership and allocation of mineral rights. For example, a mining company may file a case to claim a mineral deposit that is located on land owned by a private individual or the government.",
      "3. Illegal Mining Cases: These cases involve illegal mining activities, such as mining without the necessary permits or in violation of environmental laws. Illegal mining cases can be filed by government agencies or affected communities.",
      "4. Lease and Royalty Cases: These cases involve disputes over mining leases and royalty payments. For example, a mining company may file a case to challenge the cancellation of its mining lease or to dispute the amount of royalty it is required to pay.",
      "5. Rehabilitation and Resettlement Cases: These cases involve the displacement of local communities due to mining activities. The affected communities may file cases to seek compensation, resettlement, or rehabilitation.",
    ],
    footer:
      "These cases are typically heard in the High Courts or Supreme Court of India, depending on the nature and location of the dispute. The courts play a crucial role in regulating the mining sector and ensuring that mineral resources are managed in a sustainable and equitable manner.",
  },
];
const dataEssentialCommoditiesActSignificance = [
  {
    pagename: "Essential Commodities Act Significance",
  },
  {
    title: "Essential Commodities Act Significance",
    points: [
      "1. The Essential Commodities Act (ECA) is a law enacted by the Government of India in 1955, which gives the central government the power to regulate the production, supply, and distribution of essential commodities to maintain their availability at fair prices. The Act is aimed at ensuring that essential commodities are available to consumers at reasonable prices and that there is no artificial shortage of such commodities in the market.",
      "2. The ECA empowers the central government to regulate the production, supply, and distribution of essential commodities, such as food grains, edible oils, sugar, petroleum products, drugs, and fertilizers. The government can issue orders to regulate the storage, transportation, and sale of these commodities, and can also fix the prices at which they are sold.",
      "3. Under the ECA, the government can also issue control orders to prevent hoarding, black marketing, and profiteering in essential commodities. The Act empowers the government to seize and confiscate any essential commodity that is hoarded, black-marketed, or sold at an exorbitant price.",
      "4. The ECA also provides for the establishment of a National Committee on Essential Commodities, which is responsible for advising the government on matters related to the regulation of essential commodities. The Committee includes representatives from various government departments, as well as experts from the private sector.",
      "5. The Essential Commodities Act is an important tool for the government to ensure that essential commodities are available to consumers at reasonable prices, and to prevent hoarding and black marketing. However, the Act has also been criticized for its potential to discourage investment and innovation in the production and distribution of essential commodities, and for its potential to create artificial scarcity in the market.",
    ],
  },
];
const dataHoardingofEssentialCommodities = [
  {
    pagename: "Hoarding of Essential Commodities",
  },
  {
    title: "Hoarding of Essential Commodities",
    points: [
      "1. Hoarding of essential commodities refers to the practice of accumulating large quantities of essential commodities, such as food grains, edible oils, sugar, petroleum products, drugs, and fertilizers, with the intention of artificially creating a shortage in the market, and driving up prices for personal profit.",
      "2. Hoarding of essential commodities can lead to a supply shortage, which can result in price escalation and cause significant harm to the common people, especially the poor and vulnerable sections of society. The practice of hoarding is detrimental to the interests of consumers, and can cause inflation and economic instability.",
      "3. To prevent hoarding of essential commodities, the Government of India has enacted the Essential Commodities Act, which gives the government the power to regulate the production, supply, and distribution of essential commodities. Under the Act, the government can issue orders to regulate the storage, transportation, and sale of these commodities, and can also fix the prices at which they are sold. The Act empowers the government to seize and confiscate any essential commodity that is hoarded, black-marketed, or sold at an exorbitant price.",
      "4. The government also takes various measures to prevent hoarding of essential commodities, such as increasing the supply of essential commodities, creating a buffer stock, monitoring the stock levels of essential commodities, and taking strict action against those who engage in hoarding and black- marketing.",
      "5. Preventing hoarding of essential commodities is essential to ensure that the prices of these commodities remain stable, and that they are available to consumers at reasonable prices.",
    ],
  },
];
const dataBlackMarketingofEssentialConditions = [
  {
    pagename: "Black Marketing of Essential Conditions",
  },
  {
    title: "Black Marketing of Essential Conditions",
    points: [
      "1. Black marketing of essential commodities refers to the illegal practice of selling essential commodities, such as food grains, edible oils, sugar, petroleum products, drugs, and fertilizers, at prices higher than the government-fixed prices or market rates, with the aim of making illegal profits.",
      "2. Black marketing can lead to an artificial shortage of essential commodities, which can cause prices to escalate and cause significant harm to the common people, especially the poor and vulnerable sections of society. Black marketing is detrimental to the interests of consumers and can cause inflation and economic instability.",
      "3. To prevent black marketing of essential commodities, the Government of India has enacted the Essential Commodities Act, which gives the government the power to regulate the production, supply, and distribution of essential commodities. Under the Act, the government can issue orders to regulate the storage, transportation, and sale of these commodities, and can also fix the prices at which they are sold. The Act empowers the government to seize and confiscate any essential commodity that is black-marketed or sold at an exorbitant price.",
      "4. The government also takes various measures to prevent black marketing of essential commodities, such as increasing the supply of essential commodities, creating a buffer stock, monitoring the stock levels of essential commodities, and taking strict action against those who engage in black-marketing and hoarding.",
      "5. Preventing black marketing of essential commodities is essential to ensure that the prices of these commodities remain stable and that they are available to consumers at reasonable prices. It is also crucial to protect the interests of the common people, especially the poor and vulnerable sections of society.",
    ],
  },
];
const dataAdulterationofEssentialCommodities = [
  {
    pagename: "Adulteration of Essential Commodities",
  },
  {
    title: "Adulteration of Essential Commodities",
    points: [
      "1. Adulteration of essential commodities refers to the practice of adding substandard or harmful substances to essential commodities, such as food grains, edible oils, sugar, petroleum products, drugs, and fertilizers, with the intention of making illegal profits.",
      "2. Adulteration of essential commodities can lead to serious health hazards, and can cause significant harm to the common people, especially the poor and vulnerable sections of society. Adulteration is a serious offense that poses a threat to public health and safety.",
      "3. To prevent adulteration of essential commodities, the Government of India has enacted several laws, such as the Food Safety and Standards Act, 2006, which provides for the regulation of food safety and standards in India. The Act lays down standards for food products, and prohibits the manufacture, storage, distribution, or sale of any food product that is adulterated or misbranded.",
      "4. The government also takes various measures to prevent adulteration of essential commodities, such as increasing surveillance and monitoring, conducting regular inspections of food processing and manufacturing units, and imposing strict penalties on those found guilty of adulteration.",
      "5. Preventing adulteration of essential commodities is essential to ensure that the products are safe and of good quality, and that they do not pose any threat to public health and safety. It is also important to protect the interests of consumers and to prevent unethical practices that can harm the common people, especially the poor and vulnerable sections of society.",
    ],
  },
];
const dataContraventionofGovernmentOrders = [
  {
    pagename: "Contravention of Government Orders ",
  },
  {
    title: "Contravention of Government Orders ",
    points: [
      "1. Contravention of government orders refers to the violation or non-compliance of the orders or directives issued by the government or its authorized agencies. The government issues various orders and directives to regulate and control different aspects of society, such as economic, social, and political activities, to maintain law and order, and to protect the interests of the citizens.",
      "2. Contravention of government orders can take different forms, such as disobeying orders related to public health and safety, environmental protection, labour laws, tax regulations, or national security. The violation of government orders can have serious consequences, including legal action, penalties, fines, or imprisonment.",
      "3. To prevent contravention of government orders, the government has established various regulatory bodies, such as the police, judiciary, and other law enforcement agencies, which are responsible for enforcing the orders and directives of the government. The government also takes various measures to create awareness among the citizens about the importance of complying with government orders and directives.",
      "4. Preventing contravention of government orders is essential to ensure the smooth functioning of society, and to protect the interests of the citizens. It is also crucial to maintain law and order, and to promote good governance and democratic values.",
    ],
  },
];
const dataFalsificationofRecords = [
  {
    pagename: "Falsification of Records",
  },
  {
    title: "Falsification of Records",
    points: [
      "1. Falsification of records in Essential Commodities Act refers to the act of intentionally providing false information or making false entries in the records related to the production, supply, or distribution of essential commodities, with the intention of deceiving or defrauding others. Falsification of records in the context of Essential Commodities Act can take various forms, such as misreporting the quantities or quality of essential commodities produced or distributed, or falsifying the records related to the purchase or sale of essential commodities.",
      "2. Falsification of records in Essential Commodities Act is a serious offense that can lead to legal action, penalties, and imprisonment. The act of falsifying records in the production or distribution of essential commodities can have serious consequences, such as artificial scarcity of the commodities, price escalation, and harm to the consumers, especially the poor and vulnerable sections of society.",
      "3. To prevent falsification of records in Essential Commodities Act, the government takes various measures, such as regular inspections and audits, establishment of surveillance and monitoring systems, and strict penalties for violators. It is also essential to create awareness among the stakeholders, such as the producers, distributors, and consumers, about the importance of maintaining accurate and truthful records and the consequences of falsifying records.",
      "4. Preventing falsification of records in Essential Commodities Act is crucial to ensure the availability and accessibility of essential commodities at fair prices, and to protect the interests of the consumers, especially the poor and vulnerable sections of society.",
    ],
  },
];
const dataCivilSuits = [
  {
    pagename: "Civil Suits",
  },
  {
    title: "Civil Suits",
    points: [
      "1. In India, a civil suit is a legal action initiated by one person or entity (the plaintiff) against another person or entity (the defendant) seeking compensation or some other form of relief for a civil wrong committed by the defendant. Civil suits typically arise from disputes related to contract disputes, property rights, personal injury, breach of trust, defamation, and other matters.",
      "2. The procedure for filing a civil suit in India is governed by the Civil Procedure Code, 1908. The plaintiff must file a plaint in the appropriate court, which must specify the facts of the case, the relief sought, and the legal basis for the claim. The defendant may file a written statement in response, and the court may hold hearings and allow for the submission of evidence before rendering a judgement.",
      "3. If the court finds in favor of the plaintiff, it may order the defendant to pay damages, return property, or perform some other form of relief. Conversely, if the court finds in favor of the defendant, the plaintiff may be required to pay court costs and other expenses incurred by the defendant during the proceedings.",
    ],
    footer:
      "Overall, civil suits play an important role in the Indian legal system by providing a mechanism for resolving disputes between individuals and entities in a peaceful and fair manner.",
  },
];
const dataCivilCourtsInIndia = [
  {
    pagename: "Civil Courts In India",
  },
  {
    title: "Civil Courts In India",
    desc: [
      "In India, Civil Courts are courts that deal with civil matters and disputes between individuals, companies, organizations, and other entities. The primary function of civil courts in India is to resolve disputes related to civil wrongs and provide remedies to the parties involved.",
    ],
  },
  {
    subtitle:
      "There are various types of civil courts in India, ranging from the lowest level of courts to the highest level of courts. These courts are established at different levels based on the hierarchy of the judiciary system in India. The civil courts in India are broadly classified as follows:",
    points: [
      "1. District Courts: These are the lowest level of civil courts and are established in every district of the country. District courts deal with cases related to property disputes, matrimonial disputes, contractual disputes, etc.",
      "2. High Courts: Each state in India has a High Court, which is the second-highest level of civil courts in the country. High courts have the power of judicial review over the decisions of lower courts and can hear appeals against the judgments passed by the lower courts.",
      "3. Supreme Court: The Supreme Court of India is the highest level of civil court in the country. It is the final court of appeal and has the power of judicial review over the decisions of lower courts.",
      "In addition to these courts, there are also specialized civil courts in India that deal with specific types of disputes, such as consumer courts, labour courts, family courts, and commercial courts, among others.",
    ],
    footer:
      "Overall, Civil Courts play an important role in the Indian legal system by providing a platform for resolving disputes between individuals and entities in a fair and just manner.",
  },
];
const dataOriginalCivilSuits = [
  {
    pagename: "Original Civil Suits",
  },
  {
    title: "Original Civil Suits",
    points: [
      '1. In India, the term "original side" refers to the jurisdiction of a High Court to hear and decide original civil cases. The High Court is the highest court in each state or union territory in India, and it has both original and appellate jurisdiction.',
      "2. The original side of a High Court deals with matters that are brought directly to the High Court, as opposed to matters that are brought to the High Court on appeal from lower courts. These cases can involve complex legal issues, substantial sums of money, and significant disputes between parties.",
      "3. Examples of cases that fall within the original jurisdiction of a High Court include disputes relating to the interpretation and enforcement of contracts, property rights, intellectual property, and tort claims. Matters relating to company law, arbitration, and taxation can also fall within the original jurisdiction of a High Court.",
      "4. When a case is filed in the original side of a High Court, it is typically heard by a single judge. However, if the case is of significant importance or complexity, it may be heard by a division bench consisting of two or more judges.",
      "5. The procedures for filing and conducting cases in the original side of a High Court are governed by the rules of the court, and parties are required to follow the prescribed procedures and timelines. The decision of the High Court in an original civil case can be appealed to the Supreme Court of India, the highest court in the country.",
    ],
  },
];
const dataPrescribedAuthority = [
  {
    pagename: "Prescribed Authority Cases in Civil Suits",
  },
  {
    title: "Prescribed Authority Cases in Civil Suits",
    points: [
      "1. In India, prescribed authority civil suit cases refer to legal cases that are heard by a specific court or authority designated by law. These courts are established to handle cases that pertain to a specific subject matter or legal dispute and have the power to hear and adjudicate such cases.",
      "2. One of the most common examples of prescribed authority civil suit cases in India is related to land disputes. The government designates a specific authority, such as a land tribunal, to hear and decide cases related to land ownership, title disputes, and other related issues.",
      "3. Similarly, in certain cases related to employment, such as disputes between employees and employers regarding wages, working conditions, or disciplinary actions, there may be prescribed authorities, such as labour tribunals, set up to hear and adjudicate such cases.",
      "4. Prescribed authority civil suit cases are designed to provide a specialized forum for the resolution of disputes related to a particular subject matter. This helps to ensure that cases are heard and decided by authorities with specific expertise and knowledge in the relevant legal areas.",
      "5. The procedure for filing a prescribed authority civil suit case in India is similar to that of other civil suits. The plaintiff must file a plaint in the appropriate court or authority, which must specify the facts of the case, the relief sought, and the legal basis for the claim. The defendant then has the opportunity to file a written statement in response to the plaintiff's claim. The court or authority may hold hearings and allow the submission of evidence by both parties before rendering a judgment.",
    ],
    footer:
      "Overall, prescribed authority civil suit cases in India play an important role in providing a specialized forum for resolving disputes related to a specific subject matter, ensuring that such cases are heard and decided by experts in the relevant legal areas.",
  },
];
const dataCommercialSuits = [
  {
    pagename: "Commercial Suits ",
  },
  {
    title: "Commercial Suits ",
    points: [
      "1. Commercial cases in civil suits in India refer to legal cases that are related to commercial disputes between businesses or individuals involving business transactions. Commercial cases in civil suits can be filed for a variety of reasons, such as breach of contract, non-payment of debts, disputes over property rights, partnership disputes, and other matters related to commercial dealings.",
      "2. The Indian legal system provides for a specialized forum to handle commercial disputes, known as commercial courts or commercial divisions of high courts. These courts are designed to provide fast and efficient resolution of commercial disputes, as commercial disputes often involve complex legal and technical issues that require specialized expertise.",
      "3. The Commercial Courts Act, 2015, was enacted to provide for the establishment of commercial courts and commercial divisions in high courts for speedy and efficient disposal of commercial disputes. Commercial courts are established at the district level, while commercial divisions are established at the High Court level.",
      "4. The jurisdiction of commercial courts in India extends to cases arising out of ordinary transactions of merchants, bankers, financiers, and traders, such as disputes over contracts for the sale of goods or provision of services, partnership disputes, disputes over intellectual property rights, and other commercial matters.",
      "5. The procedure for filing a commercial case in civil suits in India is similar to that of other civil suits. The plaintiff must file a plaint in the appropriate court, which must specify the facts of the case, the relief sought, and the legal basis for the claim. The defendant then has the opportunity to file a written statement in response to the plaintiff's claim. The court may hold hearings and allow the submission of evidence by both parties before rendering a judgment.",
    ],
    footer:
      "Overall, commercial cases in civil suits in India play an important role in resolving disputes between businesses and individuals in a fair and just manner. They provide a legal framework for peaceful resolution of conflicts and promote justice and fairness in society. Commercial cases in civil suits in India help to ensure that commercial disputes are resolved efficiently, allowing businesses to focus on their core activities and contribute to the growth of the Indian economy.",
  },
];
const dataOtherCivilSuits = [
  {
    pagename: "Other Civil Suits ",
  },
  {
    title: "Other Civil Suits ",
    points: [
      "1. In India, other suits in civil suits refer to legal cases that are not classified under any specific category of civil suits. These types of suits may include a variety of claims and disputes, including personal injury claims, environmental disputes, defamation claims, and many others.",
      "2. Other suits in civil suits in India are heard and adjudicated by the appropriate court, depending on the nature of the dispute and the jurisdiction of the court. The procedure for filing a suit and the rules of evidence and civil procedure is similar to those for other types of civil suits.",
      "3. In general, other suits in civil suits are filed when a dispute arises between two or more parties, and a legal remedy is sought for the wrong committed. The plaintiff must file a plaint in the appropriate court, which must specify the facts of the case, the relief sought, and the legal basis for the claim. The defendant then has the opportunity to file a written statement in response to the plaintiff's claim. The court may hold hearings and allow the submission of evidence by both parties before rendering a judgment.",
      "4. The Indian legal system provides for a broad range of courts that can hear other suits in civil suits, including district courts, high courts, and the Supreme Court. Depending on the nature and complexity of the dispute, other specialized courts may also be available, such as environmental courts or consumer courts.",
    ],
    footer:
      "Overall, other suits in civil suits in India provide a legal mechanism for parties to seek redressal for a wide range of grievances and disputes. The Indian legal system is designed to ensure that disputes are resolved in a fair and just manner, and that parties have access to the appropriate court or tribunal to hear their case.",
  },
];
const dataAttachmentBeforeJudgement = [
  {
    pagename: "Attachment Before Judgement",
  },
  {
    title: "Attachment Before Judgement",
    desc: [
      "Order 39 Rule 2A cases in India refer to cases filed by parties in civil suits seeking attachment before judgment of property belonging to the other party. This attachment is granted by the court to prevent the other party from disposing of or encumbering their property, which may be required to satisfy the judgment if the plaintiff is successful in the suit.",
    ],
  },
  {
    subtitle:
      "Under Order 39 Rule 2A of the Code of Civil Procedure, a party may apply for attachment before judgment if they can satisfy the court that:",
    points: [
      "1. There is a prima facie case in their favour",
      "2. The balance of convenience is in their favour",
      "3. They are likely to suffer irreparable loss or injury if the attachment is not granted",
      "4. The defendant is likely to dispose of or encumber their property to defeat the decree",
      "The application for attachment must be filed along with the original suit or at any time after the filing of the suit, but before the judgment is passed. The court will examine the application and hear both parties before deciding whether to grant the attachment or not.",
    ],
    footer:
      "Overall, Order 39 Rule 2A cases in India provide an important mechanism for parties in civil suits to protect their interests by preventing the other party from disposing of or encumbering their property until the final judgment is passed. The process ensures that parties have access to effective and speedy relief, which helps to promote fairness and accuracy in the legal system.",
  },
];
const dataTemporaryInjunction = [
  {
    pagename: "Temporary Injunction Against Other Property",
  },
  {
    title: "Temporary Injunction Against Other Property",
    desc: [
      "Order 39 Rule 4 cases in India refer to cases filed by parties in civil suits seeking temporary injunctions against the other party. A temporary injunction is a court order that prohibits a party from doing something that would cause harm to the other party until the court has had the opportunity to fully consider the matter.",
    ],
  },
  {
    subtitle:
      "Under Order 39 Rule 4 of the Code of Civil Procedure, a party may apply for a temporary injunction if they can satisfy the court that:",
    points: [
      "1. There is a prima facie case in their favor",
      "2. The balance of convenience is in their favor",
      "3. They are likely to suffer irreparable loss or injury if the injunction is not granted",
      "4. The defendant is likely to dispose of or encumber their property to defeat the decree",
      "The application for a temporary injunction must be filed along with the original suit or at any time after the filing of the suit, but before the judgment is passed. The court will examine the application and hear both parties before deciding whether to grant the temporary injunction or not.",
      "If the court grants the temporary injunction, it will remain in force until the final judgment is passed, or until the court revokes or modifies the order.",
      "Overall, Order 39 Rule 4 cases in India provide an important mechanism for parties in civil suits to protect their interests by preventing the other party from doing something that would cause harm until the final judgment is passed. The process ensures that parties have access to effective and speedy relief, which helps to promote fairness and accuracy in the legal system.",
    ],
  },
];
const dataSetasideExParteDecree = [
  {
    pagename: "Set aside Ex Parte Decree",
  },
  {
    title: "Set aside Ex Parte Decree",
    desc: [
      "Order 9 Rule 13 cases in India refer to cases filed by parties seeking to set aside an ex parte decree passed against them in a civil suit. An ex parte decree is a decree passed by the court in favor of one party in a civil suit in the absence of the other party.",
    ],
  },
  {
    subtitle:
      "Under Order 9 Rule 13 of the Code of Civil Procedure, a party against whom an ex parte decree has been passed may apply to the court to set aside the decree if they can satisfy the court that:",
    points: [
      "1. They were prevented by any sufficient cause from appearing when the suit was called for hearing",
      "2. They have a valid defence to the suit",
      "3. They have acted with due diligence in prosecuting the application",
      "The application to set aside the ex parte decree must be filed within 30 days from the date of the decree or within such time as the court may allow. The court will examine the application and hear both parties before deciding whether to set aside the ex parte decree or not.",
      "If the court sets aside the ex parte decree, the case will be restored to the stage where it was before the ex parte decree was passed, and both parties will be given an opportunity to be heard and present their case.",
    ],
    footer:
      "Overall, Order 9 Rule 13 cases in India provide an important mechanism for parties in civil suits to seek relief from an ex parte decree passed against them. The process ensures that parties have access to effective and speedy relief, which helps to promote fairness and accuracy in the legal system.",
  },
];
const dataProbateLetterAdministration = [
  {
    pagename: "Probate Letter Administration",
  },
  {
    title: "Probate Letter Administration",
    points: [
      "1. Probate and Letter of Administration cases in India refer to legal proceedings involving the distribution of the estate of a deceased person.",
      "2. A probate is a legal process in which the court validates the last will and testament of the deceased person and authorizes the executor of the will to distribute the assets of the estate as per the terms of the will.",
      "3. A Letter of Administration, on the other hand, is granted by the court when a person dies without leaving a valid will or without naming an executor in their will. In such cases, the court appoints an administrator to distribute the assets of the estate as per the laws of inheritance.",
      "4. In both cases, the executor or administrator is responsible for collecting the assets of the estate, paying off any debts or liabilities, and distributing the remaining assets to the beneficiaries as per the terms of the will or the laws of inheritance.",
      "5. Probate and Letter of Administration cases are filed in the District Court or High Court, depending on the value of the estate. The court will examine the application and hear the parties involved before granting the probate or Letter of Administration.",
    ],
    footer:
      "Overall, Probate and Letter of Administration cases in India provide an important mechanism for the distribution of the estate of a deceased person, ensuring that the assets are distributed fairly and as per the wishes of the deceased person or the laws of inheritance.",
  },
];
const dataIssuanceofanInjunction = [
  {
    pagename: "Issuance of an Injunction ",
  },
  {
    title: "Issuance of an Injunction ",
    points: [
      "1. Section 91 of the Code of Civil Procedure in India provides for the filing of an application by a party to the court for the issuance of an injunction or order in any suit or legal proceeding in order to prevent or stop any act or conduct that may cause harm to the public or a section of the public.",
      "2. Applications under Section 91 CPC are filed by parties seeking relief on behalf of the public or a section of the public. For example, an application may be filed by a group of individuals seeking an injunction to prevent a company from engaging in activities that may cause environmental harm.",
      "3. The court may grant an injunction or order under Section 91 CPC if it is satisfied that such an injunction or order is necessary to prevent harm to the public or a section of the public. The court may also direct the party against whom the injunction or order is issued to pay any costs or compensation that may be required to remedy the harm caused.",
      "4. Applications under Section 91 CPC provide an important mechanism for parties to seek relief on behalf of the public or a section of the public, ensuring that the interests of the public are protected and that any harm caused is appropriately addressed. The process ensures that parties have access to effective and speedy relief, which helps to promote fairness and accuracy in the legal system.",
    ],
  },
];
const dataInterventionAdministration = [
  {
    pagename: "Intervention in Administration of the Trust or Institution",
  },
  {
    title: "Intervention in Administration of the Trust or Institution",
    desc: [
      "Section 92 of the Code of Civil Procedure in India provides for the filing of an application by a person interested in a trust or a public religious or charitable institution, seeking the court's intervention in certain matters relating to the administration of the trust or institution.Section 92 of the Code of Civil Procedure in India provides for the filing of an application by a person interested in a trust or a public religious or charitable institution, seeking the court's intervention in certain matters relating to the administration of the trust or institution.",
    ],
  },
  {
    subtitle:
      "Applications under Section 92 CPC are filed by persons interested in a trust or a public religious or charitable institution, seeking the court's intervention in the following matters:",
    points: [
      "1. Removal of a trustee",
      "2. Appointment of a new trustee",
      "3. Filling up of a vacancy in the trusteeship",
      "4. Direction for the proper administration of the trust or institution",
      "5. Recovery of possession of trust property",
      "6. Determination of any question arising in the administration of the trust or institution",
      "The court may grant any relief as it deems necessary in the interests of the trust or institution, including the appointment of a new trustee, removal of a trustee, and recovery of possession of trust property. The court may also direct the trustee to provide accounts and take other measures for the proper administration of the trust or institution.",
      "Applications under Section 92 CPC provide an important mechanism for persons interested in a trust or public religious or charitable institution to seek the court's intervention in the administration of such trusts or institutions, ensuring that the interests of the trust or institution are protected and that it is properly administered. The process ensures that parties have access to effective and speedy relief, which helps to promote fairness and accuracy in the legal system.",
    ],
  },
];
const dataIndigentPauperApplication = [
  {
    pagename: "Indigent & Pauper Application",
  },
  {
    title: "Indigent & Pauper Application",
    points: [
      "1. Indigent and Pauper applications in India refer to legal proceedings that are initiated by individuals who are unable to pay the court fees and other charges associated with a particular legal case.",
      "2. Under the Code of Civil Procedure, an indigent person is defined as someone who is not financially capable of paying the fees and expenses of a legal proceeding. A pauper is someone who is so poor that they are unable to provide for their basic needs, such as food, clothing, and shelter.",
      "3. Indigent and Pauper applications in India are typically filed by individuals who are seeking justice but are unable to pay the court fees and other charges associated with the legal proceedings. The application must include a statement of facts explaining the financial condition of the applicant and why they are unable to pay the fees.",
      "4. Once an Indigent or Pauper application is filed, the court may review the application and determine if the applicant qualifies for the waiver of court fees and other charges. If the court finds that the applicant is indigent or a pauper, they may be exempted from paying the fees and expenses associated with the legal proceeding.",
      "5. Indigent and Pauper applications provide an important mechanism for ensuring that everyone has access to justice, regardless of their financial condition. The process ensures that individuals who are unable to pay the fees and expenses associated with legal proceedings are not denied justice due to their financial circumstances.",
    ],
  },
];
const dataElectionCases = [
  {
    pagename: "Election Cases",
  },
  {
    title: "Election Cases",
    points: [
      "1. Election cases in India refer to legal disputes that arise out of the conduct of elections, including general elections, state assembly elections, and local body elections. These cases are filed in the appropriate court or tribunal to seek redressal for any irregularities or violations that may have occurred during the election process.",
      "2. In India, election cases are governed by the Representation of the People Act, 1951, and the Conduct of Elections Rules, 1961. These laws set out the legal framework for the conduct of elections, including the eligibility criteria for candidates, the procedure for nomination, the conduct of polls, and the resolution of disputes.",
      "3. The procedure for filing an election case in India involves filing a petition in the appropriate court or tribunal, which can be the high court, the election commission, or the appropriate district court, depending on the nature of the dispute and the jurisdiction of the court. The court or tribunal will consider the evidence presented by both parties and make a decision based on the facts and circumstances of the case.",
      "4. Election cases can be filed by any aggrieved party, including candidates, political parties, or voters. Some common grounds for filing election cases include allegations of electoral malpractices, such as rigging, booth capturing, and use of illegal means to influence voters.",
    ],
    footer:
      "Overall, election cases in India are important legal proceedings that aim to ensure the fairness and integrity of the electoral process. The Indian legal system is designed to provide a fair and just resolution to disputes related to the conduct of elections, with the interests of all parties involved being taken into consideration.",
  },
];
export const sections = {
  areaSpotMarketing: dataAreaSpotMarketing,
  roadShowMarketing: dataRoadShowMarketing,
  kioskMarketing: dataKioskMarketing,
  umbrellaCampaign: dataUmbrellaCampaign,
  afterSalesSuppor: dataAfterSalesSuppor,
  panelMarketing: dataPanelMarketing,
  stickerMarketing: dataStickerMarketing,
  marketingHampers: dataMarketingHampers,
  productLaunchSupport: dataProductLaunchSupport,
  showroomSalesSupport: dataShowroomSalesSupport,
  indirectMarketing: dataIndirectMarketing,
  directMarketing: dataDirectMarketing,
  taxNotice: dataTaxNotice,
  businessFiling: dataBusinessFiling,
  personalTaxFiling: dataPersonalTaxFiling,
  provisionalpatent: dataProvisionalpatent,
  patentRegistration: dataPatentRegistration,
  designRegistration: dataDesignRegistration,
  copyrightResgistraion: datacopyrightResgistraion,
  trademarkRenewal: dataTrademarkRenewal,
  inputTaxcredit: dataInputTaxcredit,
  ewaybill: dataEwaybill,
  gsteInvoice: dataGSTeInvoice,
  gstAnnualFiling: dataGSTAnnualFiling,
  registrationCancellation: dataRegistrationCancellation,
  gstLUTFiling: dataGSTLUTFiling,
  gstReturnFiling: dataGSTReturnFiling,
  gst: dataGSTRegistration,
  onepersoncompany: dataOnePersonCompany,
  ecode: dataECodeRegistration,
  startup: dataStartupIndia,
  digitalsignature: dataDigitalSignature,
  proprietorship: dataProprietorship,
  privatelimitedcompany: dataprivatelimitedcompany,
  partnershipfirm: dataPartnershipFirm,
  llp: dataLLPRegistration,
  trademarkobjection: dataTrademarkObjection,
  trademark: dataTrademarkRegistration,
  trademarkOposition: dataTrademarkOposition,
  fssai: dataFSSAIRegistration,
  udayam: dataUdayamRegistration,
  professionaltax: data,
  irt1: dataIRT1,
  irt2: dataIRT2,
  irt3: dataIRT3,
  irt4: dataIRT4,
  irt5: dataIRT5,
  irt6: dataIRT6,
  irt7: dataIRT7,
  FlexiStaffing: dataFlexiStaffing,
  ContractEmployee: dataContractEmployee,
  HouseMaids: dataHouseMaids,
  Drivers: dataDrivers,
  OfficeBoys: dataOfficeBoys,
  BabyCareTaker: dataBabyCareTaker,
  ElderCareTaker: dataElderCareTaker,
  BackOfficeStaffing: dataBackOfficeStaffing,
  PermanentStaffing: dataPermanentStaffing,
  ProductAdvertising: dataProductAdvertising,
  ProductMarketing: dataProductMarketing,
  SkilledTraining: dataSkilledTraining,
  MatrimonialIssues: dataMatrimonialIssues,
  RentControlIssues: dataRentControlIssues,
  RestitutionConjugalRights: dataRestitutionConjugalRights,
  JudicialSeparation: dataJudicialSeparation,
  DivorceinDifferentReligion: dataDivorceinDifferentReligion,
  DomesticViolenceIssues: dataDomesticViolenceIssues,
  Section7FamilyCourtActs: dataSection7FamilyCourtActs,
  GuardianshipChildCustody: dataGuardianshipChildCustody,
  Adoption: dataAdoption,
  MaintenanceandAlimony: dataMaintenanceandAlimony,
  AnnulmentofMarriage: dataAnnulmentofMarriage,
  LandlordTenantIssues: dataLandlordTenantIssues,
  TenantLandlordIssues: dataTenantLandlordIssues,
  EmployeeandEmployerIssues: dataEmployeeandEmployerIssues,
  ESIIssues: dataESIIssues,
  ProfessionalTax: dataProfessionalTax,
  SalaryIssueswithEmployer: dataSalaryIssueswithEmployer,
  RemediesforSalaryIssuesforEmployee: dataRemediesforSalaryIssuesforEmployee,
  RemediesforDelayinSalaryIssuesforEmployee:
    dataRemediesforDelayinSalaryIssuesforEmployee,
  MentalHarassment: dataMentalHarassment,
  SexualHarassmentinWorkplace: dataSexualHarassmentinWorkplace,
  RemedyforSexualHarassmentinWorkplace:
    dataRemedyforSexualHarassmentinWorkplace,
  EmployeeNoticePeriodIssues: dataEmployeeNoticePeriodIssues,
  RefusaltoGiveOriginalCertificates: dataRefusaltoGiveOriginalCertificates,
  GratuityProblem: dataGratuityProblem,
  NotAcceptingtheResignationbytheEmployer:
    dataNotAcceptingtheResignationbytheEmployer,

  SessionCase: dataSessionCase,
  IPRResolutions: dataIPRResolutions,
  TrademarkInfringement: dataTrademarkInfringement,
  CopyrightInfringementCases: dataCopyrightInfringementCases,
  DesignInfringement: dataDesignInfringement,
  TradeSecretInfringement: dataTradeSecretInfringement,
  TribunalsResolution: dataTribunalsResolution,

  WorkplaceSafetyIssues: dataWorkplaceSafetyIssues,
  WrongfulTermination: dataWrongfulTermination,
  RemedyforWrongfulTermination: dataRemedyforWrongfulTermination,
  ViolationofPrivacythings: dataViolationofPrivacythings,
  IndustrialDisputesAct1974: dataIndustrialDisputesAct1974,
  MinimumWagesAct1948: dataMinimumWagesAct1948,
  PaymentofWagesAct1936: dataPaymentofWagesAct1936,
  EmployerProvidentFundandMiscAct1952: dataEmployerProvidentFundandMiscAct1952,
  MaternityBenefitsAct1961: dataMaternityBenefitsAct1961,
  PreventionProhibitionandRedressalAct2013:
    dataPreventionProhibitionandRedressalAct2013,
  FactoryAct1948: dataFactoryAct1948,
  BuildingandOtherConstructionWorkersAct1996:
    dataBuildingandOtherConstructionWorkersAct1996,
  NonPerformingAssets: dataNonPerformingAssets,
  SarfaesiActIssues: dataSarfaesiActIssues,
  RemediesforBorrowerswhenbankinitiatesSarfaesiProceedings:
    dataRemediesforBorrowerswhenbankinitiatesSarfaesiProceedings,
  ExorbitantInterestRate: dataExorbitantInterestRate,
  LoanEMIIssues: dataLoanEMIIssues,
  CreditCardIssues: dataCreditCardIssues,
  CreditCardHarassment: dataCreditCardHarassment,
  CreditCardSettlement: dataCreditCardSettlement,
  OneTimeSettlementforNPA: dataOneTimeSettlementforNPA,
  Insurance: dataInsurance,
  CyberCrimeCases: dataCyberCrimeCases,
  ChequeBounce: dataChequeBounce,
  ChequeBounceRemedy: dataChequeBounceRemedy,
  ChequeBounceProcedure: dataChequeBounceProcedure,
  MedicalNegligenceCases: dataMedicalNegligenceCases,
  DoctorsLiabilityinMedicalNegligence: dataDoctorsLiabilityinMedicalNegligence,
  Surrogacy: dataSurrogacy,
  CompensationMedicalNegligence: dataCompensationMedicalNegligence,
  IndianMedicineCentralCouncilAct: dataIndianMedicineCentralCouncilAct,
  MotorVehicleAccidentCases: dataMotorVehicleAccidentCases,
  PaymentofCompensation: dataPaymentofCompensation,
  PolicetoInvestigatetheAccident: dataPolicetoInvestigatetheAccident,
  HitandRunCaseCompensation: dataHitandRunCaseCompensation,
  AccidentsresultsinDeathInjury: dataAccidentsresultsinDeathInjury,
  ThirdPartyInsuranceClaims: dataThirdPartyInsuranceClaims,
  LimitationofLiability: dataLimitationofLiability,
  CalculationofCompensation: dataCalculationofCompensation,
  NegligenceClaims: dataNegligenceClaims,
  DisputeOverCompensation: dataDisputeOverCompensation,
  LiabilityDisputes: dataLiabilityDisputes,
  ArbitrationConciliation: dataArbitrationConciliation,
  ArbitrationExecution: dataArbitrationExecution,
  ArbitrationExecutioninCommercialCasesinIndia:
    dataArbitrationExecutioninCommercialCasesinIndia,
  ProcedureforfilingofArbitrationProceedings:
    dataProcedureforfilingofArbitrationProceedings,
  RegularCivilAppeal: dataRegularCivilAppeal,
  FirstAppeal: dataFirstAppeal,
  SecondAppeal: dataSecondAppeal,
  CommercialAppeal: dataCommercialAppeal,
  OtherAppeals: dataOtherAppeals,
  CivilRevision: dataCivilRevision,
  ExecutionPetition: dataExecutionPetition,
  RepaymentApplication: dataRepaymentApplication,
  Insolvency: dataInsolvency,
  CustomsCentralExcise: dataCustomsCentralExcise,
  ExportQualityandInspectionAct: dataExportQualityandInspectionAct,
  TheCentralExciseAct: dataTheCentralExciseAct,
  CustomsAct: dataCustomsAct,
  TheSmugglersAndForeignExchangeManipulatorsAct:
    dataTheSmugglersAndForeignExchangeManipulatorsAct,
  ForeignExchangeMaintenanceAct: dataForeignExchangeMaintenanceAct,
  ConservationofForeignExchangeandPreventionofSmugglingActivitiesAct:
    dataConservationofForeignExchangeandPreventionofSmugglingActivitiesAct,
  LandAcquisitionCases: dataLandAcquisitionCases,
  CompensationfortheLandlord: dataCompensationfortheLandlord,
  PreliminaryNotification: dataPreliminaryNotification,
  CompensationofMarketValue: dataCompensationofMarketValue,
  RehabilitationandResettlement: dataRehabilitationandResettlement,
  ValidityOfAcquisition: dataValidityOfAcquisition,
  DelayinAcquisitionProcess: dataDelayinAcquisitionProcess,
  CaveatApplication: dataCaveatApplication,
  WindingUpProceedings: dataWindingUpProceedings,
  CompaniesAct2013: dataCompaniesAct2013,
  InsolvencyProceedings: dataInsolvencyProceedings,
  CorporateFraudCases: dataCorporateFraudCases,
  InsiderTrading: dataInsiderTrading,
  SecurityFraud: dataSecurityFraud,
  ViolationofCorporateGovernanceNorms: dataViolationofCorporateGovernanceNorms,
  OppressionofMismanagementofCompanies:
    dataOppressionofMismanagementofCompanies,
  NationalCompanyLawTribunal: dataNationalCompanyLawTribunal,
  SecuritiesAppellateTribunal: dataSecuritiesAppellateTribunal,
  IncomeTaxAppellateTribunal: dataIncomeTaxAppellateTribunal,
  CentralAdministrativeTribunal: dataCentralAdministrativeTribunal,
  TribunalforForfeitedProperty: dataTribunalforForfeitedProperty,
  TribunalforNDPS: dataTribunalforNDPS,
  TribunalforPMLA: dataTribunalforPMLA,
  TribunalforArmedForce: dataTribunalforArmedForce,
  IntellectualPropertyAppellateBoard: dataIntellectualPropertyAppellateBoard,
  TribunalforDebtRecoveryTribunal: dataTribunalforDebtRecoveryTribunal,
  TribunalforCopyrightBoard: dataTribunalforCopyrightBoard,
  NationalGreenTribunal: dataNationalGreenTribunal,
  SEBI: dataSEBI,
  TrademarkRegistry: dataTrademarkRegistry,
  RERA: dataRERA,
  WakfBoardSignificance: dataWakfBoardSignificance,
  WritsSignificance: dataWritsSignificance,
  HabeausCorpus: dataHabeausCorpus,
  Mandamus: dataMandamus,
  Prohibition: dataProhibition,
  Certiorari: dataCertiorari,
  iprResolutions: dataiprResolutions,
  Infringement: dataInfringement,
  CopyrightCases: dataCopyrightCases,
  MinesandMineralsSignificance: dataMinesandMineralsSignificance,
  EssentialCommoditiesActSignificance: dataEssentialCommoditiesActSignificance,
  HoardingofEssentialCommodities: dataHoardingofEssentialCommodities,
  BlackMarketingofEssentialConditions: dataBlackMarketingofEssentialConditions,
  AdulterationofEssentialCommodities: dataAdulterationofEssentialCommodities,
  ContraventionofGovernmentOrders: dataContraventionofGovernmentOrders,
  FalsificationofRecords: dataFalsificationofRecords,
  CivilSuits: dataCivilSuits,
  CivilCourtsInIndia: dataCivilCourtsInIndia,
  OriginalCivilSuits: dataOriginalCivilSuits,
  PrescribedAuthority: dataPrescribedAuthority,
  CommercialSuits: dataCommercialSuits,
  OtherCivilSuits: dataOtherCivilSuits,
  AttachmentBeforeJudgement: dataAttachmentBeforeJudgement,
  TemporaryInjunction: dataTemporaryInjunction,
  SetasideExParteDecree: dataSetasideExParteDecree,
  ProbateLetterAdministration: dataProbateLetterAdministration,
  InterventionAdministration: dataInterventionAdministration,
  IssuanceofanInjunction: dataIssuanceofanInjunction,
  IndigentPauperApplication: dataIndigentPauperApplication,
  ElectionCases: dataElectionCases,
  GSTAdvisoryServices: dataGSTAdvisoryServices,
};
