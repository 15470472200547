import React, { useEffect, useState } from 'react';
import './ServiceBody.css'; // Import CSS file for styling
import {
    EmailValidation,
    NonEmptyValidation,
    PhoneNumberValidation,
    StringValidation,
} from "../../../Store/validate";
import { UserServiceRequest } from '../../../Api/Api';
import { showToastErrorMsg, showToastSuccessMsg } from '../../../Store/toastMsg';
import { useLocation } from 'react-router-dom';

export default function ServiceBanner({ contentProps, stateProps }) {

    const locationSearch = useLocation();
    const [selectedTab, setSelectedTab] = useState("");
    const [selectedSubTab, setSelectedSubTab] = useState("check");
    const [selectedSubTabCheck, setSelectedSubTabCheck] = useState("check");
    useEffect(() => {
        window.scrollTo(0, 0);
        const search = locationSearch.search;
        const tab = new URLSearchParams(search).get("tab");
        const subtab = new URLSearchParams(search).get("subtab");

        if (tab) {
            setSelectedTab(tab);
            setSelectedSubTab(subtab.split(" ")[0]);
            setSelectedSubTabCheck(subtab);
            // console.log("selectedSubTab", selectedSubTab)
            // console.log("setSelectedSubTabCheck", selectedSubTabCheck)
        }
        if (stateProps?.auth?.isLoggedIn) {
            setServiceReqValue1("name", stateProps?.auth?.auth?.name)
            setServiceReqValue1("email", stateProps?.auth?.auth?.email)
            setServiceReqValue1("mobileNumber", stateProps?.auth?.auth?.mobileNumber || '')

        }
    }, []);

    const [serviceReqValidation1, setServiceReqValidation1] = useState({});
    const [serviceReq1, setServiceReq1] = useState({});
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [form, setForm] = useState(false);

    const setServiceReqValue1 = (key, value) => {
        setServiceReq1({ ...serviceReq1, [key]: value });
        if (serviceReqValidation1[key]) delete serviceReqValidation1[key];
    };
    const setServiceReqValidationValue1 = (key, value) => {
        setServiceReqValidation1({ ...serviceReqValidation1, [key]: value });
    };

    const handleSubmitServiceform = async (e) => {
        e.preventDefault();
        setIsButtonDisabled(true);
        let Payload;
        if (!stateProps?.auth?.isLoggedIn) {
            Payload = {
                name: serviceReq1.name,
                email: serviceReq1.email,
                subject: serviceReq1.subject || '',  // Ensure subject is an empty string if null or undefined
                question: serviceReq1.question || '',  // Ensure question is an empty string if null or undefined
                mobileNumber: serviceReq1.mobileNumber,
                type: "service",
                servicesCategory: contentProps[0]?.mainServiceId?.title || '',
                servicesSubcategory: contentProps[0]?.subServiceL1Id?.title || '',
                servicesTitle: contentProps[0]?.subServiceL2Id?.title || '',
            };
        }else{
            Payload = {
                subject: serviceReq1.subject || '',  // Ensure subject is an empty string if null or undefined
                question: serviceReq1.question || '',  // Ensure question is an empty string if null or undefined
                type: "service",
                servicesCategory: contentProps[0]?.mainServiceId?.title || '',
                servicesSubcategory: contentProps[0]?.subServiceL1Id?.title || '',
                servicesTitle: contentProps[0]?.subServiceL2Id?.title || '',
                userId:stateProps?.auth?.auth?._id
            };
        }
        
        const response = await UserServiceRequest(Payload);
        if (response && response.statusCode === 200) {
            showToastSuccessMsg(response.message);
            setServiceReq1({});
            setForm(false);
        } else {
            showToastErrorMsg(
                response.message || response.error.response.data.message
            );
        }
        setIsButtonDisabled(false);
    };

    return (
        <div style={{
            backgroundImage: 'url("https://static.vecteezy.com/system/resources/previews/002/909/374/non_2x/luxury-abstract-banner-background-modern-geometric-shapes-an-d-gold-line-art-wallpaper-design-for-website-prints-cover-backdrop-wall-art-and-wall-decoration-vector.jpg")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }}>
            <div className="container py-5">
                <div className="row">
                    <div className="col-lg-8 col-md-8 mb-4 d-flex justify-content-center align-items-center">
                        <div style={{ width: "80%" }}>
                            <h1>{contentProps[0]?.subServiceL2Id?.title?contentProps[0]?.subServiceL2Id?.title:selectedSubTabCheck.split("|")[1]}</h1>
                            <p style={{ fontSize: "18px" }}>Claim your {contentProps[0]?.subServiceL2Id?.title?contentProps[0]?.subServiceL2Id?.title:selectedSubTabCheck.split("|")[1]} effortlessly with Dalmaf. Partner with us for expert guidance and maximize your returns.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        {/* <div className="request-callback-form sticky-top p-4 border rounded shadow"> */}
                        <div className="request-callback-form p-4 border rounded shadow">
                            <h5>REQUEST A CALL BACK</h5>
                            <form onSubmit={handleSubmitServiceform}>
                                <div className="mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Your Name"
                                        aria-invalid={Boolean(serviceReqValidation1?.name?.status === false)}
                                        value={stateProps?.auth?.auth?.name || serviceReq1?.name || ''}
                                        onChange={(e) => setServiceReqValue1("name", e.target.value)}
                                        onBlur={(e) => setServiceReqValidationValue1("name", StringValidation(e.target.value))}
                                        disabled={stateProps?.auth?.auth?.name}  // Corrected the disabled attribute
                                    />

                                    <small className="text-danger">
                                        {serviceReqValidation1?.name?.message ? `Name ${serviceReqValidation1?.name?.message}` : ""}
                                    </small>
                                </div>
                                <div className="mb-3">
                                    <input
                                        disabled={stateProps?.auth?.auth?.mobileNumber}
                                        type="tel"
                                        maxLength={10}
                                        className="form-control"
                                        placeholder="Enter Your Mobile No"
                                        aria-invalid={Boolean(serviceReqValidation1?.mobileNumber?.status === false)}
                                        value={stateProps?.auth?.auth?.mobileNumber || serviceReq1?.mobileNumber || ''}
                                        onChange={(e) => setServiceReqValue1("mobileNumber", e.target.value)}
                                        onBlur={(e) => setServiceReqValidationValue1("mobileNumber", PhoneNumberValidation(e.target.value))}
                                    />
                                    <small className="text-danger">
                                        {serviceReqValidation1?.mobileNumber?.message ? `Mobile no ${serviceReqValidation1?.mobileNumber?.message}` : ""}
                                    </small>
                                </div>
                                <div className="mb-3">
                                    <input
                                        disabled={stateProps?.auth?.auth?.email}
                                        type="email"
                                        className="form-control"
                                        placeholder="Enter Your E-Mail ID"
                                        aria-invalid={Boolean(serviceReqValidation1?.email?.status === false)}
                                        value={stateProps?.auth?.auth?.email || serviceReq1?.email?.toLowerCase() || ''}
                                        onChange={(e) => setServiceReqValue1("email", e.target.value.toLowerCase())}
                                        onBlur={(e) => setServiceReqValidationValue1("email", EmailValidation(e.target.value.toLowerCase()))}
                                    />
                                    <small className="text-danger">
                                        {serviceReqValidation1?.email?.message ? `Email ${serviceReqValidation1?.email?.message}` : ""}
                                    </small>
                                </div>
                                <div className="mb-3">
                                    <textarea
                                        rows="2"
                                        className="form-control"
                                        placeholder="Enter Your Comments/Needs"
                                        value={serviceReq1?.subject || ''}
                                        onChange={(e) => setServiceReqValue1("subject", e.target.value)}
                                    />
                                </div>
                                <div className="text-center">
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        disabled={isButtonDisabled}
                                    >
                                        {/* Get Started */}
                                        {isButtonDisabled ? (
                                            <>
                                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                Submiting...
                                            </>
                                        ) : (
                                            'Get Started'
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}
