import axios from "axios";
import { url } from "../../BaseUrl";

const APIBaseUrl = url;


//------------------- Profile Start -------------------//

export const updatePassword = async (Payload,token) => {
    const response = await axios
        .post(`${APIBaseUrl}/updatePassword?token=`+token, Payload, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => ({ error: error }));
    return response;
};

