//React Default packageImport
import { useSelector } from "react-redux";
//Reusable Component Import
import { showToastErrorMsg } from "../Store/toastMsg"
//API Include
import { CreatePaymentServiceCC, CreatePaymentServiceCCNL, CreatePaymentSRCC, CreatePaymentSRCCNL, CreatePaymentCustomPkg } from "../Api/Api"
import { toast } from 'react-toastify';

export function generateOrderId(purchaseType){
    const today = new Date();
    let currentYear;
    if (purchaseType === 'package')
        currentYear = 'DALMaf-PKG-' + today.getFullYear() + "/" + (today.getMonth() + 1) + "/" + today.getDate() + "/" + today.getHours() + today.getMinutes() + today.getMilliseconds();
    if (purchaseType === 'service')
        currentYear = 'DALMaf-SR-' + today.getFullYear() + "/" + (today.getMonth() + 1) + "/" + today.getDate() + "/" + today.getHours() + today.getMinutes() + today.getMilliseconds();
    if (purchaseType === 'custom-package')
        currentYear = 'DALMaf-CSTMPKG-' + today.getFullYear() + "/" + (today.getMonth() + 1) + "/" + today.getDate() + "/" + today.getHours() + today.getMinutes() + today.getMilliseconds();
    return currentYear;
};

export async function redirectURL(url){
    window.open(url, "", "noreferrer");
    // window.location.replace(url);
};
export async function  HandleCCAvenuePaymentPkg(price, id,isLoggedIn,token){
    // const state = useSelector((state) => state);
    const orderId = generateOrderId("package");
    const Payload = {
        orderId: orderId,
        purchaseType: "package",
        packagePurchaseId: id,
        price: price,
        paymentInfo: "",
        status: "Not Paid",
        is_logedin: isLoggedIn,
    };
    localStorage?.setItem(
        "url",
        window.location.href
    );
    // const token = state?.auth?.auth?.token;
    const response = await CreatePaymentServiceCC(Payload, token);
    if (response && response.statusCode === 200) {
        redirectURL(response.data.url)
    } else {
        showToastErrorMsg(
            response.message || response.error.response.data.message
        );
    }
};
export async function  HandleCCAvenuePaymentPkgNL(price, id, isLoggedIn, name, mobileNumber, email){
    // const state = useSelector((state) => state);
    const orderId = generateOrderId("package");
    const Payload = {
        orderId: orderId,
        purchaseType: "package",
        packagePurchaseId: id,
        price: price,
        paymentInfo: "",
        status: "Not Paid",
        is_logedin: isLoggedIn,
        name: name,
        mobileNumber: mobileNumber,
        email: email,
    };
    localStorage?.setItem(
        "url",
        window.location.href
    );
    // const token = state?.auth?.auth?.token;
    const response = await CreatePaymentServiceCCNL(Payload);
    if (response && response.statusCode === 200) {
        redirectURL(response.data.url)
    } else {
        showToastErrorMsg(
            response.message || response.error.response.data.message
        );
    }
};

export async function  HandleCCAvenuePaymentSrv(price, id, isLoggedIn,token){
    // const state = useSelector((state) => state);
    const orderId = generateOrderId("service");
    const Payload = {
        orderId: orderId,
        purchaseType: "service",
        contentId: id,
        price: price,
        paymentInfo: "",
        status: "Not Paid",
        is_logedin: isLoggedIn,
    };
    localStorage?.setItem(
        "url",
        window.location.href
    );
    // const token = state?.auth?.auth?.token;
    const response = await CreatePaymentSRCC(Payload, token);
    if (response && response.statusCode === 200) {
        redirectURL(response.data.url)
    } else {
        showToastErrorMsg(
            response.message || response.error.response.data.message
        );
    }
};

export async function  HandleCCAvenuePaymentSrvNL(price, id, isLoggedIn,name, mobileNumber, email){
    // const state = useSelector((state) => state);
    const orderId = generateOrderId("service");
    const Payload = {
        orderId: orderId,
        purchaseType: "service",
        contentId: id,
        price: price,
        paymentInfo: "",
        status: "Not Paid",
        is_logedin: isLoggedIn,
        name: name,
        mobileNumber: mobileNumber,
        email: email,
    };
    localStorage?.setItem(
        "url",
        window.location.href
    );
    console.log(Payload)
    // const token = state?.auth?.auth?.token;
    const response = await CreatePaymentSRCCNL(Payload);
    if (response && response.statusCode === 200) {
       
        redirectURL(response.data.url)
        
    } else {
        showToastErrorMsg(
            response.message || response.error.response.data.message
        );
    }
};




export async function  HandleCCAvenuePaymentCustSrv(orderId,id,token){
    // const state = useSelector((state) => state);
    // const orderId = generateOrderId("custom-package");
    const Payload = {
        orderId: orderId,
        // purchaseType: "custom-package",
        payment_id: id,
        // price: amount,
        // serviceRequestId:SRId,
        // paymentInfo: "",
        // status: "Not Paid",
        // is_logedin: isLoggedIn,
    };
    localStorage?.setItem(
        "url",
        window.location.href
    );
    console.log(Payload)
    // const token = state?.auth?.auth?.token;
    const response = await CreatePaymentCustomPkg(Payload, token);
    if (response && response.statusCode === 200) {
        redirectURL(response.data.url)
    } else {
        showToastErrorMsg(
            response.message || response.error.response.data.message
        );
    }
};

export async function  HandleCCAvenuePaymentCustPack(id,price,isLoggedIn,token){
    // const state = useSelector((state) => state);
    const orderId = generateOrderId("custom-package");
    const Payload = {
        orderId: orderId,
        purchaseType: "custom-package",
        packagePurchaseId: id,
        price: price,
        paymentInfo: "",
        status: "Not Paid",
        is_logedin: isLoggedIn,
    };
    localStorage?.setItem(
        "url",
        window.location.href
    );
    console.log(Payload)
    // const token = state?.auth?.auth?.token;
    const response = await CreatePaymentServiceCC(Payload, token);
    if (response && response.statusCode === 200) {
        redirectURL(response.data.url)
    } else {
        showToastErrorMsg(
            response.message || response.error.response.data.message
        );
    }
};


export function showErrorMsg(errMsg){
    toast.error(errMsg, {
        position: toast.POSITION.TOP_RIGHT,
        className: "toast-message",
        autoClose: 5000,
    });
};

//success
export function showSuccessMsg(successMsg) {
    toast.success(successMsg, {
        position: toast.POSITION.TOP_RIGHT,
        className: "toast-message",
        autoClose: 3000,
    });
};