import React, { useEffect, useState } from "react";
import RFA from "../../Assets/Images/rfa.webp";
import AC1 from "../../Assets/Images/ac1.webp";
import AC2 from "../../Assets/Images/ac2.webp";
import AC3 from "../../Assets/Images/ac3.webp";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { ToastContainer, toast } from "react-toastify";
import AOS from "aos";
import "aos/dist/aos.css";

import {
  EmailValidation,
  NonEmptyValidation,
  PhoneNumberValidation,
  StringValidation,
} from "../../Store/validate";
import { GetUser, UseraboutContent } from "../../Api/Api";
import { useSelector } from "react-redux";
import ServicesAllpages from "../ServicesAllpages";

export default function AuctionContent() {
  useEffect(() => {
    AOS.init();
  }, []);


  
  const [auctionDetail, setAuctionDetails] = useState({});

  const [auctionValidation, setAuctionValidation] = useState({});

  const setAuctionDetailsValue = (key, value) => {
    setAuctionDetails({ ...auctionDetail, [key]: value });
    if (auctionValidation[key]) delete auctionValidation[key];
  };

  const setAuctionValidationValue = (key, value) => {
    setAuctionValidation({ ...auctionValidation, [key]: value });
  };

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  const [userAuctionProfile, setUserAuctionProfile] = useState([]);
  useEffect(() => {
    if (state?.auth?.isLoggedIn === true) {
      getUserAuctionProfile();
    }
  }, []);
  const getUserAuctionProfile = async () => {
    const id = state?.auth?.auth?._id;
    const token = state.auth.auth.token;
    const response = await GetUser(token, id);
    if (response.statusCode === 200) {
      setUserAuctionProfile(response.data);
    } else {
      setUserAuctionProfile([]);

      //console.log("error while getting user profile info");
    }
  };

  //console.log(userAuctionProfile);
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    let validate = {};
    validate.name = StringValidation(auctionDetail?.name);
    validate.emailId = EmailValidation(auctionDetail?.emailId);
    validate.subject = NonEmptyValidation(auctionDetail?.subject);
    validate.question = NonEmptyValidation(auctionDetail?.question);
    validate.mobileNumber = PhoneNumberValidation(auctionDetail?.mobileNumber);
  validate.servicesType = NonEmptyValidation(auctionDetail?.servicesType);

    setAuctionValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        emailId: auctionDetail.emailId?.toLowerCase(),
        name: auctionDetail.name,
        subject: auctionDetail.subject,
        question: auctionDetail.question,
        mobileNumber: auctionDetail.mobileNumber,
        servicesType: auctionDetail.servicesType,
      };
      const response = await UseraboutContent(Payload);
      if (response && response.statusCode === 200) {
        
        showToastSuccessMsg(response.message);
        window.location.reload();
        

         setAuctionDetails({});
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } else {
      //console.log("Error while update user details");
    }
    // }
  };
  const state = useSelector((state) => state);




  return (
    <div className="overflow-pages">
      <ToastContainer />
      <div className="about-banner2 pt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-6 my-2 m-auto" data-aos="fade-left">
              <div className="about-img">
                <img className="w-100 h-auto" src={RFA} alt="" />
              </div>
            </div>
            <div className="col-lg-7 col-md-6 my-2 m-auto" data-aos="fade-left">
              <div className="about-content">
                <h2 className="banner-heading after-line mb-5">
                  Residential Flat Auction
                </h2>
                <p>
                  <span className="fw-bold">
                    {" "}
                    Hiring An Experienced Divorce Lawyer Is The Best Way You Get
                    Peace Of Mind{" "}
                  </span>{" "}
                  when dealing with matrimonial cases, child custody, alimony,
                  and mutual divorce or contested divorce proceedings. Use
                  DALMAF to consult a top-rated divorce lawyer for marriage
                  issues in India to file or defend your mutual divorce
                  petition, contested divorce, alimony, domestic violence (DV),
                  interim maintenance, 125 CrPC, dowry harassment u/s 498a,
                  women cell complaints, or any other related matters.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 m-auto" data-aos="fade-left">
              <div className="about-content">
                <h2 className="banner-heading after-line mb-5">
                  Category Auction
                </h2>
                <p>
                  when dealing with matrimonial cases, child custody, alimony,
                  and mutual divorce or contested divorce proceedings. Use
                  DALMAF to consult a top-rated divorce lawyer for marriage
                  issues in India to file or defend your mutual divorce
                  petition.
                </p>
              </div>
            </div>
            <div className="col-lg-7 h-100 px-0 m-auto" data-aos="fade-left">
              <div className=" ">
                <OwlCarousel
                  className="owl-theme"
                  loop
                  autoplay={true}
                  items="3"
                  dots={true}
                  smartSpeed={2000}
                  nav={false}
                  margin={20}
                  center={true}
                  autoplayTimeout={5000}
                  responsive={{
                    360: {
                      items: "1",
                    },
                    414: {
                      items: "1",
                    },
                    670: {
                      items: "2",
                    },
                    992: {
                      items: "3",
                    },
                    1200: {
                      items: "3",
                    },
                  }}
                >
                  <div className="">
                    <div className="psw-icon-section">
                      <img className="w-100 h-auto" src={AC1} alt="..." />
                      <div className="br-8 overlay">
                        <h6 className="fw-bold rel-content">
                          {" "}
                          Vehicle Auction
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="psw-icon-section">
                      <img className="w-100 h-auto" src={AC2} alt="..." />
                      <div className="br-8 overlay">
                        <h6 className="fw-bold rel-content">Gold Auction</h6>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="psw-icon-section">
                      <img className="w-100 h-auto" src={AC3} alt="..." />
                      <div className="br-8 overlay">
                        <h6 className="fw-bold rel-content">
                          Robert D. Willems
                        </h6>
                      </div>
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="client-left-section py-5">
        <div className="container my-4">
          <h2 className="banner-heading after-line mb-5">FEEL FREE TO ASK</h2>
          <div className="row">
            <div className="col-lg-6" data-aos="fade-left">
              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.823571029211!2d80.23700327559305!3d13.046899587275508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267c25ec3b519%3A0xb10841584ccc73c4!2sDALMAF%20Private%20Limited!5e0!3m2!1sen!2sin!4v1688118977225!5m2!1sen!2sin"
                  width="100%"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            <div className="col-lg-6" data-aos="fade-left">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Enter Your Name"
                      aria-invalid={Boolean(
                        auctionValidation?.name?.status === false
                      )}
                      value={auctionDetail?.name || ""}
                      onChange={(e) => {
                        setAuctionDetailsValue("name", e.target.value);
                      }}
                      onBlur={(e) => {
                        setAuctionValidationValue(
                          "name",
                          StringValidation(e.target.value)
                        );
                      }}
                      autoComplete="off"
                    />
                    <small className="text-danger">
                      {auctionValidation?.name?.message
                        ? `Name ${auctionValidation?.name?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      Mobile No <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      maxLength={10}
                      className="form-control form-control-lg"
                      placeholder="Enter Your Mobile No"
                      aria-invalid={Boolean(
                        auctionValidation?.mobileNumber?.status === false
                      )}
                      value={auctionDetail?.mobileNumber || ""}
                      onChange={(e) => {
                        setAuctionDetailsValue("mobileNumber", e.target.value);
                      }}
                      onBlur={(e) => {
                        setAuctionValidationValue(
                          "mobileNumber",
                          PhoneNumberValidation(e.target.value)
                        );
                      }}
                      autoComplete="off"
                    />
                    <small className="text-danger">
                      {auctionValidation?.mobileNumber?.message
                        ? `Mobile Number ${auctionValidation?.mobileNumber?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      E- Mail ID <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control form-control-lg"
                      placeholder="Enter Your E- Mail ID"
                      aria-invalid={Boolean(
                        auctionValidation?.emailId?.status === false
                      )}
                      value={auctionDetail?.emailId?.toLowerCase() || ""}
                      onChange={(e) => {
                        setAuctionDetailsValue(
                          "emailId",
                          e.target.value?.toLowerCase()
                        );
                      }}
                      onBlur={(e) => {
                        setAuctionValidationValue(
                          "emailId",
                          EmailValidation(e.target.value?.toLowerCase())
                        );
                      }}
                      autoComplete="off"
                    />
                    <small className="text-danger">
                      {auctionValidation?.emailId?.message
                        ? `Email id ${auctionValidation?.emailId?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label ">
                      Subject <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Enter Your  Subject "
                      aria-invalid={Boolean(
                        auctionValidation?.subject?.status === false
                      )}
                      value={auctionDetail?.subject || ""}
                      onChange={(e) => {
                        setAuctionDetailsValue("subject", e.target.value);
                      }}
                      onBlur={(e) => {
                        setAuctionValidationValue(
                          "subject",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                      autoComplete="off"
                    />
                    <small className="text-danger">
                      {auctionValidation?.subject?.message
                        ? `Subject ${auctionValidation?.subject?.message}`
                        : ""}
                    </small>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="mb-4">
                    <label className="form-label">
                      Service Type  
                      {/* <span className="text-danger">*</span> */}
                    </label>
                    <select
                      className="form-control form-control-lg"
                      value={auctionDetail?.servicesType || ""}
                      onChange={(e) => {
                        setAuctionDetailsValue("servicesType", e.target.value);
                      }}
                      onBlur={(e) => {
                        setAuctionValidationValue(
                          "servicesType",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                    >
                      <option value="">---Select Service Type---</option>
                      <option value="Legal">Legal</option>
                      <option value="Audit">Audit</option>
                      <option value="Manpower">Manpower</option>
                      <option value="Auction">Auction</option>
                      <option value="Buy/Sell">Buy/Sell</option>
                    </select>
                   
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-4">
                    <label className="form-label">
                      Questions <span className="text-danger">*</span>
                    </label>
                    <textarea
                      rows="6"
                      className="form-control"
                      placeholder="Enter Questions"
                      aria-invalid={Boolean(
                        auctionValidation?.question?.status === false
                      )}
                      value={auctionDetail?.question || ""}
                      onChange={(e) => {
                        setAuctionDetailsValue("question", e.target.value);
                      }}
                      onBlur={(e) => {
                        setAuctionValidationValue(
                          "question",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                      autoComplete="off"
                    />
                    <small className="text-danger">
                      {auctionValidation?.question?.message
                        ? `Question ${auctionValidation?.question?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-12">
                  

                  <button
                    type="button"
                    className="btn login-btn w-100"
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="number-counter py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6" data-aos="fade-in">
              <div className="counter-content text-start">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <h1 className="mb-0">
                      <span className="material-symbols-rounded align-middle about-call filled-icon">
                        call
                      </span>
                    </h1>
                  </div>
                  <div className="">
                    <h5 className="fs-6 mb-0">Toll Free</h5>
                    <h5 className="mb-0 fs-4">1800-309-8445</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="fade-in">
              <div className="counter-content text-start">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <h1 className="mb-0">
                      <span className="material-symbols-rounded align-middle about-call filled-icon">
                        mail
                      </span>
                    </h1>
                  </div>
                  <div className="">
                    <h5 className="fs-6 mb-0">Email ID</h5>
                    <h5 className="mb-0 fs-4">info@dalmaf.com</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="fade-in">
              <div className="counter-content text-start">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <h1 className="mb-0">
                      <i className="fa-brands fa-whatsapp about-whatsapp-icon"></i>
                    </h1>
                  </div>
                  <div className="">
                    <h5 className="fs-6 mb-0">WhatsApp Us</h5>
                    <h5 className="mb-0 fs-4">+91 96000 38059</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ServicesAllpages />
      </div>
    </div>
  );
}
