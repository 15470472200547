import React, { useEffect, useState } from "react";

import Norecord from "../../Assets/Images/MicrosoftTeams-image.webp";

import AOS from "aos";
import "aos/dist/aos.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  EmailValidation,
  NonEmptyValidation,
  PhoneNumberValidation,
  StringValidation,
} from "../../Store/validate";
import {
  GetGold,
  GetUser,
  UseraboutContent,
  UserServiceRequest,
} from "../../Api/Api";
import moment from "moment";
import CustomModal from "../CustomModal";
import ServicesAllpages from "../ServicesAllpages";

export default function BSGoldContent() {
  useEffect(() => {
    AOS.init();
  }, []);

  const [selectedTab, setSelectedTab] = React.useState("Whats Included");
  const AllTabs = [
    "Whats Included",
    "Whats Not Included",
    "You May Also Want To Know",
  ];

  const state = useSelector((state) => state);

  const navigate = useNavigate()

  const [bsGold, setBsGold] = useState({});

  const [bsGoldValidation, setBsGoldValidation] = useState({});

  const setBsGoldValue = (key, value) => {
    setBsGold({ ...bsGold, [key]: value });
    if (bsGoldValidation[key]) delete bsGoldValidation[key];
  };

  const setBsGoldValidationValue = (key, value) => {
    setBsGoldValidation({ ...bsGoldValidation, [key]: value });
  };

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 3000,
    });
  };

  const [BsGoldProfile, setBsGoldProfile] = useState([]);
  useEffect(() => {
    if (state?.auth?.isLoggedIn === true) {
      getBsGoldProfile();
    }
    getBsGoldfilter();
  }, []);
  const getBsGoldProfile = async () => {
    const id = state?.auth?.auth?._id;
    const token = state.auth.auth.token;
    const response = await GetUser(token,id);
    if (response.statusCode === 200) {
      setBsGoldProfile(response.data);

    } else {
      setBsGoldProfile([]);
      //console.log("error while getting user profile info");
    }
  };

  const naviGation = useNavigate();

  //User Registration api call

  const handleSubmit = async (e) => {
    let local = localStorage?.getItem("category")?.split("&")
    let local1 = local[0]?.split("/")
    e.preventDefault();
    
      let validate = {};
      validate.name = StringValidation(bsGold?.name);
      validate.emailId = EmailValidation(bsGold?.emailId);
      validate.subject = NonEmptyValidation(bsGold?.subject);
      validate.question = NonEmptyValidation(bsGold?.question);
      validate.mobileNumber = PhoneNumberValidation(bsGold?.mobileNumber);
  validate.servicesType = NonEmptyValidation(bsGold?.servicesType);

      setBsGoldValidation(validate);
      if (Object.values(validate).every((v) => v.status === true)) {
        const Payload = {
          name: bsGold.name,
          emailId: bsGold.emailId,
          subject: bsGold.subject,
          question: bsGold.question,
          mobileNumber: bsGold.mobileNumber,
          userId: state?.auth?.auth?._id,
          servicesType: bsGold.servicesType,

        };
        // const token = state?.auth?.auth?.token
        const response = await UseraboutContent(Payload);
        if (response && response.statusCode === 200) {
          
          showToastSuccessMsg(response.message);
          window.location.reload();
          bsGold({});
          setBsGold({});
        } else {
          showToastErrorMsg(
            response.message || response.error.response.data.message
          );
          //setfailureModal(true);
        }
      } else {
        //console.log("Error while submit a service request");
      }
    // }
  };

  const [projectBsGoldList, setProjectBsGoldList] = useState([]);
  const [loading, setLoading] = useState(true);
  

 
  const getBsGoldfilter = async () => {
    setLoading(true)
    const token = state?.auth?.auth?.token+"&type=Buy/Sell"
    //   const searchParams = new URLSearchParams();
    //   Object.keys(data).forEach((key) => searchParams.append(key, data[key]));
    const response = await GetGold(token);
    if (response && response.statusCode === 200) {
      setProjectBsGoldList(
        response.data)
        setLoading(false)
    } else {
      setProjectBsGoldList(
        [])
        setLoading(false)
      showToastErrorMsg(response.message);
    }
  };

  const handleSubmitService = async (e,id) => {
    e.preventDefault();
    let local = localStorage?.getItem("category")?.split("&");
    let local1 = local[0]?.split("/");
    if (state?.auth?.isLoggedIn) {
      const Payload = {
        name: BsGoldProfile.name,
        email: BsGoldProfile.email,
        subject: " ",
        question: " ",
        mobileNumber: BsGoldProfile.phoneNumber,
        userId: state?.auth?.auth?._id,
        goldauction_id: id,
        type: "gold",
        
      };
      const token = state?.auth?.auth?.token;
      const response = await UserServiceRequest(Payload);
      if (response && response.statusCode === 200) {
        setBsGold({});
        showToastSuccessMsg(response.message);
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    }
  };

  const [bsGold1, setBsGoldReq1] = useState({});
  const [form, setForm] = useState(false);
  const [vId, setVId] = useState("");

const [bsGoldValidation1, setBsGoldValidation1] = useState({});

const setBsGoldValue1 = (key, value) => {
  setBsGoldReq1({ ...bsGold1, [key]: value });
  if (bsGoldValidation1[key]) delete bsGoldValidation1[key];
};

const setBsGoldValidationValue1 = (key, value) => {
  setBsGoldValidation1({ ...bsGoldValidation1, [key]: value });
};


  let cat = localStorage?.getItem("category")?.split("?");

const handleSubmitServiceform = async (e) => {
  e.preventDefault()
    const Payload = {
      name: bsGold1.name,
      email: bsGold1.email,
      subject: bsGold1.subject,
      question: bsGold1.question,
      mobileNumber: bsGold1.mobileNumber,
      type: "gold",
      twowheeler_id: vId,
     
    };
    // const token = state?.auth?.auth?.token
    //console.log(Payload);
    const response = await UserServiceRequest(Payload);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      setBsGoldReq1({})
      setForm(false)
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
};
 
  return (
    <div className="overflow-pages">
      <ToastContainer />
      <div className="about-banner2 py-5">
        <div className="container">
          <section className="property">
            <div className="container">
              <div className="propertycardbottomalign">
                <h1
                  className="blogtitle my-auto"
                  data-aos="zoom-out-right"
                  data-aos-duration="2000"
                >
                  Gold
                </h1>
              </div>
              <div className="projectrow">
                <div className="row">
                  <div className="col-lg-11 mx-auto ms-auto">
                    <div className="row">
                      {projectBsGoldList?.length > 0 ? (
                        <>
                          {projectBsGoldList?.map((e, index) => {
                            return (
                              <div className="col-lg-3 mb-3" key={index}>
                                <div className="admin-service-gold">
                                  <div className="small-banner-img-head">
                                    
                                  </div>
                                  <div className="mt-3">
                                    {/* <h6 className="color-maroon fw-bold">
                                      {e?.goldType?.toUpperCase()}
                                    </h6>
                                    <p className="small">
                                      <b>Carat:</b> {e?.carat}
                                    </p>

                                    
                                    <p className="small">
                                      <b>Price :</b> {e?.bidingPrice}
                                    </p>
                                    <p className="small">
                                      <b>End Date :</b>{" "}
                                      {moment(e?.endDate).format("DD/MM/YYYY")}
                                    </p> */}
                                    <table className="table">
                                      <tr>
                                        <td>Reference No</td>
                                        <td><b>{e?.referenceNo?.toUpperCase()}</b></td>
                                      </tr>
                                      <tr>
                                        <td>Gold Type</td>
                                        <td>{e?.goldType?.toUpperCase()}</td>
                                      </tr>
                                      <tr>
                                        <td>Auction End Date</td>
                                        <td>{moment(e?.endDate).format("DD/MM/YYYY")}</td>
                                      </tr>
                                      <tr>
                                        <td>Carat</td>
                                        <td>{e?.carat}</td>
                                      </tr>
                                      <tr>
                                        <td>Gram</td>
                                        <td>{e?.gram}</td>
                                      </tr>
                                      <tr>
                                        <td>Reserved Price</td>
                                        <td>₹ {e?.reservedPrice}</td>
                                      </tr>
                                    </table>

                                    <button
                                      type="button"
                                      className="btn btn-admin w-100"
                                      onClick={(val) => {
                                        if (state?.auth?.isLoggedIn) {
                                          handleSubmitService(val, e._id);
                                        } else {
                                          setForm(true);
                                        }

                                        // navigate(`/GoldDeatails/${e._id}`);
                                      }}
                                    >
                                      Show Interest
                                    </button>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <img
                          src={Norecord}
                          alt=""
                          style={{
                            width: "200px",
                            height: "200px",
                            marginLeft: "500px",
                          }}
                          className="img-fluid"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="client-left-section py-5">
        <div className="container my-4">
          <h1 className="banner-heading after-line mb-5">FEEL FREE TO ASK</h1>
          <div className="row">
            <div className="col-lg-6" data-aos="fade-left">
              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.823571029211!2d80.23700327559305!3d13.046899587275508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267c25ec3b519%3A0xb10841584ccc73c4!2sDALMAF%20Private%20Limited!5e0!3m2!1sen!2sin!4v1688118977225!5m2!1sen!2sin"
                  width="100%"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            <div className="col-lg-6 col-md-12" data-aos="fade-left">
            
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Name"
                      aria-invalid={Boolean(
                        bsGoldValidation?.name?.status === false
                      )}
                      value={bsGold?.name || ""}
                      onChange={(e) => {
                        setBsGoldValue("name", e.target.value);
                      }}
                      onBlur={(e) => {
                        setBsGoldValidationValue(
                          "name",
                          StringValidation(e.target.value)
                        );
                      }}
                    />
                    <small className="text-danger">
                      {bsGoldValidation?.name?.message
                        ? `Name ${bsGoldValidation?.name?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      Mobile No <span className="text-danger">*</span>
                    </label>
                    <input
                      type="tel"
                      maxLength={10}
                      className="form-control"
                      placeholder="Enter Your Mobile No"
                      aria-invalid={Boolean(
                        bsGoldValidation?.mobileNumber?.status === false
                      )}
                      value={bsGold?.mobileNumber || ""}
                      onChange={(e) => {
                        setBsGoldValue("mobileNumber", e.target.value);
                      }}
                      onBlur={(e) => {
                        setBsGoldValidationValue(
                          "mobileNumber",
                          PhoneNumberValidation(e.target.value)
                        );
                      }}
                    />
                    <small className="text-danger">
                      {bsGoldValidation?.mobileNumber?.message
                        ? `Mobile no ${bsGoldValidation?.mobileNumber?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      E- Mail ID <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Your E- Mail ID"
                      aria-invalid={Boolean(
                        bsGoldValidation?.emailId?.status === false
                      )}
                      value={bsGold?.emailId?.toLowerCase() || ""}
                      onChange={(e) => {
                        setBsGoldValue("emailId", e.target.value);
                      }}
                      onBlur={(e) => {
                        setBsGoldValidationValue(
                          "emailId",
                          EmailValidation(e.target.value)
                        );
                      }}
                    />
                    <small className="text-danger">
                      {bsGoldValidation?.emailId?.message
                        ? `Email ${bsGoldValidation?.emailId?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      Questions <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Questions "
                      aria-invalid={Boolean(
                        bsGoldValidation?.question?.status === false
                      )}
                      value={bsGold?.question || ""}
                      onChange={(e) => {
                        setBsGoldValue("question", e.target.value);
                      }}
                      onBlur={(e) => {
                        setBsGoldValidationValue(
                          "question",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                    />
                    <small className="text-danger">
                      {bsGoldValidation?.question?.message
                        ? `Question ${bsGoldValidation?.question?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-4">
                    <label className="form-label">
                      Service Type
                       {/* <span className="text-danger">*</span> */}
                    </label>
                    <select
                      className="form-control form-control-lg"
                      value={bsGold?.servicesType || ""}
                      onChange={(e) => {
                        setBsGoldValue("servicesType", e.target.value);
                      }}
                      onBlur={(e) => {
                        setBsGoldValidationValue(
                          "servicesType",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                    >
                      <option value="">---Select Service Type---</option>
                      <option value="Legal">Legal</option>
                      <option value="Audit">Audit</option>
                      <option value="Manpower">Manpower</option>
                      <option value="Auction">Auction</option>
                      <option value="Buy/Sell">Buy/Sell</option>
                    </select>
                   
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="mb-4">
                    <label className="form-label">
                      Subject <span className="text-danger">*</span>
                    </label>
                    <textarea
                      rows="6"
                      className="form-control"
                      placeholder="Enter Subject"
                      aria-invalid={Boolean(
                        bsGoldValidation?.subject?.status === false
                      )}
                      value={bsGold?.subject || ""}
                      onChange={(e) => {
                        setBsGoldValue("subject", e.target.value);
                      }}
                      onBlur={(e) => {
                        setBsGoldValidationValue(
                          "subject",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                    ></textarea>
                    <small className="text-danger">
                      {bsGoldValidation?.subject?.message
                        ? `Subject ${bsGoldValidation?.subject?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-12">
                  

                  <button
                    type="button"
                    className="btn login-btn w-100"
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
      <div className="number-counter py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6" data-aos="fade-in">
              <div className="counter-content text-start">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <h1 className="mb-0">
                      <span className="material-symbols-rounded align-middle about-call filled-icon">
                        call
                      </span>
                    </h1>
                  </div>
                  <div className="">
                    <h5 className="fs-6 mb-0">Toll Free</h5>
                    <h5 className="mb-0 fs-4">1800-309-8445</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="fade-in">
              <div className="counter-content text-start">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <h1 className="mb-0">
                      <span className="material-symbols-rounded align-middle about-call filled-icon">
                        mail
                      </span>
                    </h1>
                  </div>
                  <div className="">
                    <h5 className="fs-6 mb-0">Email ID</h5>
                    <h5 className="mb-0 fs-4">info@dalmaf.com</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="fade-in">
              <div className="counter-content text-start">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <h1 className="mb-0">
                      <i className="fa-brands fa-whatsapp about-whatsapp-icon"></i>
                    </h1>
                  </div>
                  <div className="">
                    <h5 className="fs-6 mb-0">WhatsApp Us</h5>
                    <h5 className="mb-0 fs-4">+91 96000 38059</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ServicesAllpages />
      </div>
      <CustomModal
        open={form}
        onClickOutside={() => {
          setForm(false);
        }}
      >
        <div className="modal-content w-50 m-auto">
          <div className="modal-body p-0 ">
            <div className="container ">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{
                  marginRight: "30px",
                  marginTop: "50px",
                  textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                }}
                onClick={() => {
                  setForm(false);
                }}
              >
                close
              </span>
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className=" my-5 px-3">
                        <div className="row justify-content-center mt-2">
                          <div className="mb-2">
                            <b>
                              Please enter your information below, <br />
                              Our staff will contact you as soon as possible.
                            </b>
                          </div>
                          <hr />
                          <div className="col-lg-6 col-md-6">
                            <div className="mb-4">
                              <label className="form-label">
                                Name <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Your Name"
                                aria-invalid={Boolean(
                                  bsGoldValidation1?.name?.status === false
                                )}
                                defaultValue={bsGold1?.name}
                                onChange={(e) => {
                                  setBsGoldValue1("name", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setBsGoldValidationValue1(
                                    "name",
                                    StringValidation(e.target.value)
                                  );
                                }}
                              />
                              <small className="text-danger">
                                {bsGoldValidation1?.name?.message
                                  ? `Name ${bsGoldValidation1?.name?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="mb-4">
                              <label className="form-label">
                                Mobile No <span className="text-danger">*</span>
                              </label>
                              <input
                                type="tel"
                                maxLength={10}
                                className="form-control"
                                defaultValue={bsGold1?.mobileNumber}
                                placeholder="Enter Your Mobile No"
                                aria-invalid={Boolean(
                                  bsGoldValidation1?.mobileNumber
                                    ?.status === false
                                )}
                                onChange={(e) => {
                                  setBsGoldValue1(
                                    "mobileNumber",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setBsGoldValidationValue1(
                                    "mobileNumber",
                                    PhoneNumberValidation(e.target.value)
                                  );
                                }}
                              />
                              <small className="text-danger">
                                {bsGoldValidation1?.mobileNumber?.message
                                  ? `Mobile no ${bsGoldValidation1?.mobileNumber?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="mb-4">
                              <label className="form-label">
                                E- Mail ID{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Enter Your E- Mail ID"
                                aria-invalid={Boolean(
                                  bsGoldValidation1?.email?.status === false
                                )}
                                defaultValue={
                                  bsGold1?.email?.toLowerCase() || ""
                                }
                                onChange={(e) => {
                                  setBsGoldValue1(
                                    "email",
                                    e.target.value?.toLowerCase()
                                  );
                                }}
                                onBlur={(e) => {
                                  setBsGoldValidationValue1(
                                    "email",
                                    EmailValidation(
                                      e.target.value
                                    )?.toLowerCase()
                                  );
                                }}
                              />
                              <small className="text-danger">
                                {bsGoldValidation1?.email?.message
                                  ? `Email ${bsGoldValidation1?.email?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="mb-4">
                              <label className="form-label">Questions</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Your Questions "
                                aria-invalid={Boolean(
                                  bsGoldValidation1?.question?.status ===
                                    false
                                )}
                                onChange={(e) => {
                                  setBsGoldValue1(
                                    "question",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">
                                Service Type{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <select
                                className="form-control form-control-lg"
                                value={bsGold1?.servicesType}
                                onChange={(e) => {
                                  setBsGoldValue1(
                                    "servicesType",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setBsGoldValidationValue1(
                                    "servicesType",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                              >
                                <option value="">
                                  ---Select Service Type---
                                </option>
                                <option value="Legal">Legal</option>
                                <option value="Audit">Audit</option>
                                <option value="Manpower">Manpower</option>
                                <option value="Auction">Auction</option>
                                <option value="Buy/Sell">Buy/Sell</option>
                              </select>
                              <small className="text-danger">
                                {bsGoldValidation1?.servicesType?.message
                                  ? `Service Type ${bsGoldValidation1?.servicesType?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">Subject</label>
                              <textarea
                                rows="6"
                                className="form-control"
                                placeholder="Enter Subject"
                                aria-invalid={Boolean(
                                  bsGoldValidation1?.subject?.status ===
                                    false
                                )}
                                onChange={(e) => {
                                  setBsGoldValue1(
                                    "subject",
                                    e.target.value
                                  );
                                }}
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <button
                              type="button"
                              className="btn login-btn w-100"
                              onClick={(e) => {
                                handleSubmitServiceform(e);
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}
