import React, { useEffect, useState } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {
  GetUser,
  AdminCreateJob,
  GetJobs,
  GetSpecificJob,
  UpdateJob,
} from "../../../Api/Api";

import { alpha, styled } from "@mui/material/styles";
import { pink } from "@mui/material/colors";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useSelector } from "react-redux";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NonEmptyValidation, StringValidation } from "../../../Store/validate";

import CustomModal from "../../../Components/CustomModal";
import Spinner from "../../../Spinner/Spinner";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";

let htmlToDraft = null;
if (typeof window === "object") {
  htmlToDraft = require("html-to-draftjs").default;
}

export default function JobManagement() {
  const [selectedTab, setSelectedTab] = React.useState("Active Jobs");
  const AllTabs = ["Active Jobs", "Inactive Jobs"];
  const navigate = useNavigate();
  const [jobTask, setJobTask] = useState(true);
  const [jobeditModal, setJobEditModal] = useState(false);

  const [loading, setLoading] = useState(true);

  const [getJob, setGetJob] = useState([]);

  const state = useSelector((state) => state);

  const GetJobManagement = async (status, value) => {
    setLoading(true);
    // const token = state.auth.auth.token;
    let data = await GetJobs(status);
    if (data && data.statusCode === 200) {
      // data?.data.filter((e) => e.customPackage === value);
      let temp = [];
      data.data.map((data, i) => {
        let dummy = {
          _id: data?._id,
          jobTitle: data?.jobTitle,
          location: data?.location,

          createdAt: moment(data?.createdAt).format("DD/MM/YYYY"),
          status: data?.status,
        };
        temp.push(dummy);
        return null;
      });
      setGetJob(temp);
      setLoading(false);
    } else {
      setGetJob([]);
      setLoading(false);
      //console.log("Error while getting my task");
    }
  };

  const [myJob1, setMyJob1] = useState({});
  const [myJobValidation1, setMyJobValidation1] = useState({});

  const setMyJobValue1 = (key, value) => {
    setMyJob1({ ...myJob1, [key]: value });
    if (myJobValidation1[key]) delete myJobValidation1[key];
  };

  const setMyJobValidationValue1 = (key, value) => {
    setMyJobValidation1({ ...myJobValidation1, [key]: value });
  };
  const GetMySpecificTsks = async (id) => {
    setLoading(true);
    // const token = state.auth.auth.token;
    let data = await GetSpecificJob(id);
    if (data && data.statusCode === 200) {
      let dummy = data.data;
      let cmsDescription = EditorState.createEmpty();
      let cmsDescription1 = EditorState.createEmpty();
      if (dummy.description) {
        let blocksFromHtml = htmlToDraft(dummy.description);
        let { contentBlocks, entityMap } = blocksFromHtml;
        let contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );

        cmsDescription = EditorState.createWithContent(contentState);
      }
      if (dummy.requirement) {
        let blocksFromHtml = htmlToDraft(dummy.requirement);
        let { contentBlocks, entityMap } = blocksFromHtml;
        let contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        cmsDescription1 = EditorState.createWithContent(contentState);
      }
      dummy.description = cmsDescription;
      dummy.requirement = cmsDescription1;
      setMyJob1(dummy);
      setLoading(false);
    } else {
      setMyJob1([]);
      setLoading(false);
      //console.log("Error while getting my specific task");
    }
  };

  const [profile, setProfileDetails] = useState([]);
  async function fetchDataProfile() {
    setLoading(true);
    const id = state?.auth?.auth?._id;
    const token = state?.auth?.auth?.token;
    const getBasicDetails = await GetUser(token, id);
    if (getBasicDetails && getBasicDetails?.statusCode === 200) {
      setProfileDetails(getBasicDetails.data);
      setLoading(false);
    } else {
      setProfileDetails([]);
      setLoading(false);
      //console.log("error while getting user profile info");
    }
  }

  useEffect(() => {
    GetJobManagement("active");
    fetchDataProfile();
  }, []);

  const [myTask, setMyTask] = useState({});
  const [myTaskValidation, setMyTaskValidation] = useState({});

  const setMyTaskValue = (key, value) => {
    setMyTask({ ...myTask, [key]: value });
    if (myTaskValidation[key]) delete myTaskValidation[key];
  };

  const setMyTaskValidationValue = (key, value) => {
    setMyTaskValidation({ ...myTaskValidation, [key]: value });
  };

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 3000,
    });
  };

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleEditorChange = (state) => {
    setEditorState(state);
    let isNotEmpty = state && state.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(convertToRaw(state.getCurrentContent()));
      setMyTaskValue("description", html);
    }
  };

  const [editorState1, setEditorState1] = useState(() =>
    EditorState.createEmpty()
  );

  const handleEditorChange1 = (state) => {
    setEditorState1(state);
    let isNotEmpty = state && state.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(convertToRaw(state.getCurrentContent()));
      setMyTaskValue("requirement", html);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.jobTitle = NonEmptyValidation(myTask?.jobTitle);
    validate.location = StringValidation(myTask?.location);
    validate.description = NonEmptyValidation(myTask?.description);

    validate.requirement = NonEmptyValidation(myTask?.requirement);

    setMyTaskValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        jobTitle: myTask.jobTitle,
        location: myTask.location,
        description: myTask.description,
        requirement: myTask.requirement,
      };
      const token = state?.auth?.auth?.token;
      const response = await AdminCreateJob(Payload, token);
      if (response && response.statusCode === 200) {
        const editorStateReset = EditorState.push(
          editorState,
          ContentState.createFromText("")
        );
        setEditorState(editorStateReset);
        const editorStateReset1 = EditorState.push(
          editorState1,
          ContentState.createFromText("")
        );
        setEditorState1(editorStateReset1);
        setMyTask({});
        setJobTask(true);
        GetJobManagement("active");
        showToastSuccessMsg(response.message);
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } else {
      //console.log("Error while update user details");
    }
  };
  //console.log(ContentState);

  const handleSubmitUpdate = async (e, value, id) => {
    e.preventDefault();
    const Payload = {
      _id: id,
      status: value,
    };
    const token = state?.auth?.auth?.token;
    const response = await UpdateJob(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      if (value !== "active") {
        setSelectedTab("Active Members");
        GetJobManagement("active");
      } else {
        setSelectedTab("Inactive Members");
        GetJobManagement("inactive");
      }
      // GetJobManagement();
      //  window.location.reload()
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setfailureModal(true);
    }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.jobTitle = NonEmptyValidation(myJob1?.jobTitle);
    validate.location = StringValidation(myJob1?.location);
    // validate.description = NonEmptyValidation(myJob1?.description);

    // validate.requirement = NonEmptyValidation(myJob1?.requirement);

    setMyJobValidation1(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      let html;
      let html1;
      let Payload;

      html = draftToHtml(convertToRaw(myJob1.description.getCurrentContent()));
      html1 = draftToHtml(convertToRaw(myJob1.requirement.getCurrentContent()));
      Payload = {
        _id: myJob1._id,
        jobTitle: myJob1.jobTitle,
        location: myJob1.location,
        description: html,
        requirement: html1,
        status: myJob1.status,
      };

      const token = state?.auth?.auth?.token;
      const response = await UpdateJob(Payload, token);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        setJobEditModal(false);
        GetJobManagement("active");
        window.scrollTo(0, 0);
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } else {
      //console.log("Error while update user details");
    }
  };

  // const handleSubmitDelete = async (e, id,value) => {
  //   e.preventDefault();

  //   const token = state?.auth?.auth?.token;
  //   const response = await DeleteJob(id, token);
  //   if (response && response.statusCode === 200) {
  //     showToastSuccessMsg(response.message);

  //        GetJobManagement(value);

  //     //  window.location.reload()
  //   } else {
  //     showToastErrorMsg(
  //       response.message || response.error.response.data.message
  //     );
  //     //setfailureModal(true);
  //   }
  // };

  const PinkSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: pink[600],
      "&:hover": {
        backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: pink[600],
    },
  }));

  const label = { inputProps: { "aria-label": "Color switch demo" } };

  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "jobTitle",
      label: "Job Title",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <div
                style={{ cursor: "pointer" }}
                onClick={(val) => {
                  navigate(`/JobApplications/${tableMeta.rowData[0]}`);
                  //  navigate(`/JobEdit/${tableMeta.rowData[0]}`)
                }}
              >
                {value}
              </div>
            </div>
          );
        },
      },
    },

    {
      name: "location",
      label: "Location",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "createdAt",
      label: "Created Date",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="row">
              <div className="col-4">
                {value === "inactive" ? (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <PinkSwitch
                          {...label}
                          onChange={(e) => {
                            handleSubmitUpdate(
                              e,
                              "active",
                              tableMeta.rowData[0]
                            );
                          }}
                        />
                      }
                    />
                  </FormGroup>
                ) : (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <PinkSwitch
                          {...label}
                          defaultChecked
                          onChange={(e) => {
                            handleSubmitUpdate(
                              e,
                              "inactive",
                              tableMeta.rowData[0]
                            );
                          }}
                        />
                      }
                    />
                  </FormGroup>
                )}
              </div>
              <div className="col-4 mt-2">
                <i
                  onClick={() => {
                    GetMySpecificTsks(tableMeta.rowData[0]);
                    // setJobEditModal(true);
                     navigate(`/JobEdit/${tableMeta.rowData[0]}`)
                  }}
                  className="fa fa-edit"
                  style={{
                    color: "#2596be",
                    fontSize: "16px",
                    cursor: "pointer",
                    marginLeft:'10px',
                  }}
                ></i>
              </div>
              <div className="col-4 mt-2">
                {/* <i
                  onClick={(e) => {
                    Swal.fire({
                      title: "Are you sure?",
                      text: "You want to delete !",
                      // icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes, delete",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        handleSubmitDelete(e, tableMeta.rowData[0], value);
                      }
                    });
                  }}
                  // onClick={(e) => {
                  //   handleSubmitDelete(e,tableMeta.rowData[0],value)
                  // }}
                  className="fa fa-trash-can"
                  style={{
                    color: "#E34242",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                ></i> */}
              </div>
            </div>
          );
        },
      },
    },

    // {
    //   name: "Action",
    //   label: "Action",
    //   options: {
    //     customBodyRender: (value, tableMeta) => {
    //       return (
    //         <div className="text-center">
    //           <i
    //             onClick={(e) => {
    //               Swal.fire({
    //                 title: "Are you sure?",
    //                 text: "You want to delete !",
    //                 // icon: "warning",
    //                 showCancelButton: true,
    //                 confirmButtonColor: "#3085d6",
    //                 cancelButtonColor: "#d33",
    //                 confirmButtonText: "Yes, delete",
    //               }).then((result) => {
    //                 if (result.isConfirmed) {
    //                   handleSubmitDelete(
    //                     e,
    //                     tableMeta.rowData[0],
    //                     tableMeta.rowData[5]
    //                   );
    //                 }
    //               });
    //             }}
    //             // onClick={(e) => {
    //             //   handleSubmitDelete(e,tableMeta.rowData[0],value)
    //             // }}
    //             className="fa fa-trash-can"
    //             style={{
    //               color: "#E34242",
    //               fontSize: "16px",
    //               cursor: "pointer",
    //             }}
    //           ></i>
    //         </div>
    //       );
    //     },
    //   },
    // },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    setRowProps: (row) => {
      if (row[5] === false) {
        return {
          style: { background: "#DBDFEA", cursor: "pointer" },
        };
      } else {
        return {
          style: { background: "white", cursor: "pointer" },
        };
      }
    },
  };

  return (
    <div>
      <ToastContainer />
      <AdminNavbar />
      <div className="main">
        {loading ? (
          <Spinner />
        ) : (
          <>
            {jobTask ? (
              <div>
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="mb-0 fw-bold color-maroon">Jobs</h5>
                  <div>
                    <button
                      type="button"
                      className="btn py-1 login-btn"
                      onClick={() => {
                        setJobTask(false);
                      }}
                    >
                      Create Job
                    </button>
                  </div>
                </div>
                <div className="my-4">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-7 px-0">
                        <div className="tab-section">
                          <ul className="ps-0">
                            {AllTabs.map((tab, index) => {
                              return (
                                <div
                                  key={index}
                                  className={
                                    selectedTab === tab
                                      ? "serviceTab active"
                                      : "serviceTab inactive"
                                  }
                                  onClick={() => {
                                    GetJobManagement(
                                      tab === "Inactive Jobs"
                                        ? "inactive"
                                        : "active"
                                    );
                                    setSelectedTab(tab);
                                  }}
                                >
                                  <li>
                                    <span className="align-middle">{tab}</span>
                                  </li>
                                </div>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <MUIDataTable
                    title={"Dalmaf - Jobs "}
                    data={getJob}
                    columns={columns}
                    options={options}
                  />

                  {/* <table className="table">
                    <thead className="bg-historytablehead">
                      <tr>
                        <th scope="col" style={{ borderTopLeftRadius: "0" }}>
                          S. No
                        </th>
                        <th scope="col">Name</th>
                        <th scope="col">Job Title</th>
                        <th scope="col">Location</th>
                        <th scope="col">Created Date</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>

                    {getJob?.map((e, index) => (
                      <tbody className="historytablebody">
                        <tr
                        style={{cursor:"pointer"}}
                          onClick={() => {
                            navigate(`/JobEdit/${e._id}`)
                          }}
                        >
                          <td>
                            <div className="">
                              <h6 className="fw-bold">{index + 1}</h6>
                            </div>
                          </td>
                          <td scope="row">
                            <div className="d-flex align-items-center ">
                              <div className="flex-shrink-0">
                                <img
                                 
                                  style={{ width: "50px", height: "50px" }}
                                  src={
                                    profile?.profileImage
                                      ? profile?.profileImage
                                      : ADclient
                                  }
                                  alt="..."
                                />
                              </div>
                              <div className=" ms-3">
                                <h6 className="fw-bold">{profile?.name}</h6>
                                <p className="mb-0 small">
                                  {profile?.phoneNumber}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className=" ">
                              <h6 className="fw-bold">{e.jobTitle}</h6>
                            </div>
                          </td>
                          <td>
                            <div className=" ">
                              <h6 className="fw-bold">{e.location}</h6>
                            </div>
                          </td>
                          <td>
                            <h6 className="fw-bold">
                              {moment(e.createdAt).format("DD/MM/YYYY")}
                            </h6>
                          </td>
                         
                          <td>
                            <h6 className="fw-bold color-red">{e.status}</h6>
                            
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table> */}
                </div>
              </div>
            ) : (
              <div>
                <div className="">
                  <button
                    type="button"
                    className="btn login-btn ms-2 "
                    onClick={() => {
                      setJobTask(true);
                    }}
                  >
                    Back
                  </button>
                </div>
                <div className=" my-4">
                  <div className="bg-historytablehead rounded-3 py-3 px-3">
                    <h6 className="mb-0">Create Job</h6>
                  </div>
                  <div className="container">
                    <div className="row justify-content-center py-5">
                      <div className="col-lg-11">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">Job Title</label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter Job Title"
                                aria-invalid={Boolean(
                                  myTaskValidation?.jobTitle?.status === false
                                )}
                                value={myTask?.jobTitle}
                                onChange={(e) => {
                                  setMyTaskValue("jobTitle", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setMyTaskValidationValue(
                                    "jobTitle",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {myTaskValidation?.jobTitle?.message
                                  ? `Job Title ${myTaskValidation?.jobTitle?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">Location</label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter the Location "
                                aria-invalid={Boolean(
                                  myTaskValidation?.location?.status === false
                                )}
                                value={myTask?.location}
                                onChange={(e) => {
                                  setMyTaskValue("location", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setMyTaskValidationValue(
                                    "location",
                                    StringValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {myTaskValidation?.location?.message
                                  ? `Location ${myTaskValidation?.location?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">
                                Job Description
                              </label>
                              <div
                                className="form-control form-control-lg"
                                style={{ overflowY: "scroll", height: "400px" }}
                              >
                                <Editor
                                  wrapperClassName="wrapper-class"
                                  editorClassName="editor-class"
                                  toolbarClassName="toolbar-class"
                                  editorState={editorState}
                                  onEditorStateChange={(e) =>
                                    handleEditorChange(e)
                                  }
                                />
                              </div>
                              {/* <JoditEditor
                                
                                className="form-control form-control-lg"
                                aria-invalid={Boolean(
                                  myTaskValidation?.description?.status ===
                                    false
                                )}
                                value={myTask?.description}
                                onChange={(e) => {
                                  setMyTaskValue(
                                    "description",
                                    e
                                  );
                                }}
                                onBlur={(e) => {
                                  setMyTaskValidationValue(
                                    "description",
                                    NonEmptyValidation(e)
                                  );
                                }}
                                autoComplete="off"
                              /> */}
                              <small className="text-danger">
                                {myTaskValidation?.description?.message
                                  ? `Job description ${myTaskValidation?.description?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">
                                Job Requirement
                              </label>
                              <div
                                className="form-control form-control-lg"
                                style={{ overflowY: "scroll", height: "400px" }}
                              >
                                <Editor
                                  wrapperClassName="wrapper-class"
                                  editorClassName="editor-class"
                                  toolbarClassName="toolbar-class"
                                  editorState={editorState1}
                                  onEditorStateChange={(e) =>
                                    handleEditorChange1(e)
                                  }
                                />
                              </div>
                              {/* <JoditEditor
                                
                                className="form-control form-control-lg"
                                aria-invalid={Boolean(
                                  myTaskValidation?.requirement?.status ===
                                    false
                                )}
                                value={myTask?.requirement}
                                onChange={(e) => {
                                  setMyTaskValue(
                                    "requirement",
                                    e
                                  );
                                }}
                                onBlur={(e) => {
                                  setMyTaskValidationValue(
                                    "requirement",
                                    NonEmptyValidation(e)
                                  );
                                }}
                                autoComplete="off"
                              /> */}
                              <small className="text-danger">
                                {myTaskValidation?.requirement?.message
                                  ? `Job requirement ${myTaskValidation?.requirement?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>

                          <div className="col-lg-12">
                            {/* <button
                              type="button"
                              className="btn white-btn me-2 "
                              onClick={(e) => {
                                setMyTask({});
                              }}
                            >
                              Cancel
                            </button> */}
                            <button
                              type="button"
                              className="btn login-btn ms-2 "
                              onClick={(e) => {
                                handleSubmit(e);
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <CustomModal
              open={jobeditModal}
              onClickOutside={() => {
                setJobEditModal(false);
              }}
            >
              <div className="modal-content w-75 m-auto">
                <div className="modal-body p-0 ">
                  <div className="container">
                    <span
                      className="material-symbols-rounded filled-icon close-icon"
                      style={{
                        marginRight: "30px",
                        marginTop: "50px",
                        textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                      }}
                      onClick={() => {
                        setJobEditModal(false);
                      }}
                    >
                      close
                    </span>
                    <div className="row d-flex align-items-center justify-content-center  mt-0">
                      <div className="container ">
                        <div className="row justify-content-center">
                          <div className="col-lg-12">
                            <div className=" my-5 px-3">
                              <div className="row justify-content-center mt-2">
                                <div className="col-lg-12">
                                  <div className="admin-service">
                                    <div
                                      className="small-banner-img-head"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <h5 className="w-100 h-auto text-center">
                                        Update Job
                                      </h5>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Job Title
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            placeholder="Enter The JOB Title"
                                            aria-invalid={Boolean(
                                              myJobValidation1?.jobTitle
                                                ?.status === false
                                            )}
                                            defaultValue={myJob1?.jobTitle}
                                            onChange={(e) => {
                                              setMyJobValue1(
                                                "jobTitle",
                                                e.target.value
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setMyJobValidationValue1(
                                                "jobTitle",
                                                NonEmptyValidation(
                                                  e.target.value
                                                )
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {myJobValidation1?.jobTitle?.message
                                              ? `Job Title ${myJobValidation1?.jobTitle?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-12">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Location
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            placeholder=" Enter The Location"
                                            aria-invalid={Boolean(
                                              myJobValidation1?.location
                                                ?.status === false
                                            )}
                                            defaultValue={myJob1?.location}
                                            onChange={(e) => {
                                              setMyJobValue1(
                                                "location",
                                                e.target.value
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setMyJobValidationValue1(
                                                "location",
                                                StringValidation(e.target.value)
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {myJobValidation1?.location?.message
                                              ? `Location ${myJobValidation1?.location?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-12">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Job Description
                                          </label>
                                          <div
                                className="form-control"
                                style={{ overflowY: "scroll", height: "400px" }}
                              >
                                            <Editor
                                              wrapperClassName="wrapper-class"
                                              editorClassName="editor-class"
                                              toolbarClassName="toolbar-class"
                                              editorState={myJob1?.description}
                                              onEditorStateChange={(e) =>
                                                setMyJobValue1("description", e)
                                              }
                                            />
                                          </div>
                                          {/* <JoditEditor
                                
                                className="form-control form-control-lg"
                                aria-invalid={Boolean(
                                  myJobValidation1?.description?.status ===
                                    false
                                )}
                                defaultValue={myJob1?.description}
                                onChange={(e) => {
                                  setMyJobValue1(
                                    "description",
                                    e
                                  );
                                }}
                                onBlur={(e) => {
                                  setMyJobValidationValue1(
                                    "description",
                                    NonEmptyValidation(e)
                                  );
                                }}
                                autoComplete="off"
                              /> */}
                                          <small classtaskDescription="text-danger">
                                            {myJobValidation1?.description
                                              ?.message
                                              ? `Job description ${myJobValidation1?.description?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-12">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Job Requirement
                                          </label>
                                          <div
                                className="form-control"
                                style={{ overflowY: "scroll", height: "400px" }}
                              >
                                            <Editor
                                              wrapperClassName="wrapper-class"
                                              editorClassName="editor-class"
                                              toolbarClassName="toolbar-class"
                                              editorState={myJob1?.requirement}
                                              onEditorStateChange={(e) =>
                                                setMyJobValue1("requirement", e)
                                              }
                                            />
                                          </div>
                                          {/* <JoditEditor
                                
                                className="form-control form-control-lg"
                                aria-invalid={Boolean(
                                  myJobValidation1?.requirement?.status ===
                                    false
                                )}
                                defaultValue={myJob1?.requirement}
                                onChange={(e) => {
                                  setMyJobValue1(
                                    "requirement",
                                    e
                                  );
                                }}
                                onBlur={(e) => {
                                  setMyJobValidationValue1(
                                    "requirement",
                                    NonEmptyValidation(e)
                                  );
                                }}
                                autoComplete="off"
                              /> */}
                                          <small classtaskDescription="text-danger">
                                            {myJobValidation1?.requirement
                                              ?.message
                                              ? `Job requirement ${myJobValidation1?.requirement?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label ">
                                            Status
                                          </label>

                                          <select
                                            className="form-control form-control-lg"
                                            defaultValue={myJob1?.status}
                                            onChange={(e) => {
                                              setMyJobValue1(
                                                "status",
                                                e.target.value
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setMyJobValidationValue1(
                                                "status",
                                                NonEmptyValidation(
                                                  e.target.value
                                                )
                                              );
                                            }}
                                          >
                                            <option value="active">
                                              Active
                                            </option>

                                            <option value="inactive">
                                              Inactive
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-lg-12">
                                        <button
                                          type="button"
                                          className="btn login-btn ms-2 "
                                          onClick={(e) => {
                                            handleUpdateSubmit(e);
                                          }}
                                        >
                                          Update
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CustomModal>
          </>
        )}
      </div>
    </div>
  );
}
