import React, { useEffect, useState } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";
import { useSelector } from "react-redux";

import MUIDataTable from "mui-datatables";

import CustomModal from "../../../Components/CustomModal";
import moment from "moment";
import {
  GetAllEmployeeRole,
  GetAllSubAdminAndAdmin,
  UpdateLeave,
  createLeavemoduleapi,
  leaveManagement,
  leaveManagementAll,
  leaveManagementEmp,
  leaveManagementEmpAll,
  leaveSpecific,
} from "../../../Api/Api";
// import { toast } from "react-toastify";
import { ToastContainer, toast } from "react-toastify";

import Profile from "../../../Assets/Images/navbar-img/f1.webp";

// import Profile from "../../../Assets/adclient.webp";

import {
  ExpireDateValidation,
  ExpireDateValidationn,
  NonEmptyValidation,
} from "../../../Store/validate";
import { DateValidationn } from "../../../Store/validate";
import Spinner from "../../../Spinner/Spinner";
export default function LeaveModule() {
  const state = useSelector((state) => state);
  const [selectedTab, setSelectedTab] = React.useState("All");
  const AllTabs = [
    "All",
    "Pending",
    "Approved",
    "Rejected"
  ];

  useEffect(() => {
    getleaveAll();
    GetAllSubadmins();
    getListEmployeeRole();
    window.scroll(0, 0);
  }, []);

  const [leavedetailsModal, setLeavedetailsModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const [createLeaveValidation, setcreateLeaveValidation] = useState({});

  const [leaveModal, setleaveModal] = useState(false);
  const [leavelist, setleavelist] = useState([]);

  const [createLeavemodule, setCreateLeavemodule] = useState({});

  const [durationDate, setDurationDate] = React.useState("Single Day");

  const [specificleaveList, setSpecificleaveList] = useState({});

  const AllDurationType = ["Single Day", "Multiple Days"];

  const getleave = async (status) => {
    setLoading(true);
    if (state?.auth?.auth?.role === "superadmin"||state?.auth?.auth?.role === "admin") {
      const token = state?.auth?.auth?.token;
      const response = await leaveManagement(token, status);
      if (response && response?.statusCode === 200) {

        let temp = [];
        response.data.map((data, i) => {
          let dummy = {
            _id: data?._id,
            type: data?.type,
            username: data?.username,
            startDate: moment(data?.startDate).format("DD/MM/YYYY"),
            status: data?.status,
            endDate: moment(data?.endDate).format("DD/MM/YYYY"),
          };
          temp.push(dummy);
          return null;
        });

        setleavelist(temp.reverse());
        setLoading(false);
      } else {
        setleavelist([]);
        setLoading(false);
        //console.log(response.message);
      }
    } else {
      const token = state?.auth?.auth?.token;
      const id = state?.auth?.auth?._id;
      const response = await leaveManagementEmp(token, id, status);
      if (response && response?.statusCode === 200) {
        let temp = [];
        response.data.map((data, i) => {
          let dummy = {
            _id: data?._id,
            type: data?.type,
            username: data?.username,
            startDate: moment(data?.startDate).format("DD/MM/YYYY"),
            status: data?.status,
            endDate: moment(data?.endDate).format("DD/MM/YYYY"),
          };
          temp.push(dummy);
          return null;
        });

        setleavelist(temp.reverse());
        setLoading(false);
      } else {
        setleavelist([]);
        setLoading(false);
        //console.log(response.message);
      }
    }
  };

  const getleaveAll = async () => {
    setLoading(true);
    if (state?.auth?.auth?.role === "superadmin"||state?.auth?.auth?.role === "admin") {
      const token = state?.auth?.auth?.token;
      const response = await leaveManagementAll(token);
      if (response && response?.statusCode === 200) {

        let temp = [];
        response.data.map((data, i) => {
          let dummy = {
            _id: data?._id,
            type: data?.type,
            username: data?.username,
            startDate: moment(data?.startDate).format("DD/MM/YYYY"),
            status: data?.status,
            endDate: moment(data?.endDate).format("DD/MM/YYYY"),
          };
          temp.push(dummy);
          return null;
        });

        setleavelist(temp.reverse());
        setLoading(false);
      } else {
        setleavelist([]);
        setLoading(false);
        //console.log(response.message);
      }
    } else {
      const token = state?.auth?.auth?.token;
      const id = state?.auth?.auth?._id;
      const response = await leaveManagementEmpAll(token, id);
      if (response && response?.statusCode === 200) {
        let temp = [];
        response.data.map((data, i) => {
          let dummy = {
            _id: data?._id,
            type: data?.type,
            username: data?.username,
            startDate: moment(data?.startDate).format("DD/MM/YYYY"),
            status: data?.status,
            endDate: moment(data?.endDate).format("DD/MM/YYYY"),
          };
          temp.push(dummy);
          return null;
        });

        setleavelist(temp.reverse());
        setLoading(false);
      } else {
        setleavelist([]);
        setLoading(false);
        //console.log(response.message);
      }
    }
  };

  // // const [specificleaveList, setSpecificleaveList] = useState({});
  // const GetAllSpecificLeave = async (id) => {
  //   setLoading(true);

  //   const token = state?.auth?.auth?.token;
  //   let data = await leaveSpecific(token,id);
  //   if (data && data.statusCode === 200) {
  //     setSpecificleaveList(data.data);
  //     setLoading(false);
  //   } else {
  //     setSpecificleaveList([]);
  //     setLoading(false);
  //     console.error("Error while getting all subadmins");
  //   }
  // };

  const [allSubadmin, setAllSubadmin] = useState([]);
  const GetAllSubadmins = async () => {
    setLoading(true);
    const role = "subadmin";
    const token = state?.auth?.auth?.token;
    let data = await GetAllSubAdminAndAdmin(token, role, "active");
    if (data && data.statusCode === 200) {
      setAllSubadmin(data.data);
      setLoading(false);
    } else {
      setAllSubadmin([]);
      setLoading(false);
      console.error("Error while getting all subadmins");
    }
  };

  const successMsg = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const errorMsg = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const setLeaveModuleData = (key, value) => {
    setCreateLeavemodule({ ...createLeavemodule, [key]: value });
    if (createLeaveValidation[key]) delete createLeaveValidation[key];
  };
  const setCreateValidationValue = (key, value) => {
    setcreateLeaveValidation({ ...createLeaveValidation, [key]: value });
  };

  const [multi, setMulti] = useState(false);

  const handleleaveModuleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    let payload;

    validate.type = NonEmptyValidation(createLeavemodule?.type);
    validate.duration = NonEmptyValidation(createLeavemodule?.duration);
    validate.startDate = NonEmptyValidation(createLeavemodule?.startDate);

    validate.content = NonEmptyValidation(createLeavemodule?.content);
    if (createLeavemodule?.endDate) {
      validate.endDate = NonEmptyValidation(createLeavemodule?.endDate);
    }
    setcreateLeaveValidation(validate);

    if (Object.values(validate).every((v) => v.status === true)) {
      if (createLeavemodule.duration === "Single Day") {
        payload = {
          userId: state?.auth?.auth?._id,
          type: createLeavemodule.type,
          duration: "singleday",
          startDate: createLeavemodule.startDate,
          content: createLeavemodule.content,
        };
      } else {
        payload = {
          userId: state?.auth?.auth?._id,
          type: createLeavemodule.type,
          duration: "multipleday",
          startDate: createLeavemodule.startDate,
          endDate: createLeavemodule.endDate,

          content: createLeavemodule.content,
        };
      }

      // if (CheckleaveValidation()) {
      const token = state?.auth?.auth?.token;
      const response = await createLeavemoduleapi(token, payload);
      if (response && response.statusCode === 200) {
        successMsg(response.message);
        setleaveModal(false);
        setCreateLeavemodule({});
        getleaveAll();
      } else {
        errorMsg(response.message);
      }
      // }
    } else {
      //console.log("Form is invalid. Please fix the errors.");
    }
  };

  //   const setLeaveModuleData = (key, value) => {
  //   setCreateLeavemodule({ ...createLeavemodule, [key]: value });
  //   if (createLeaveValidation[key]) delete createLeaveValidation[key];
  // };

  const handleSubmitUpdate = async (e, value, id) => {
    e.preventDefault();
    const Payload = {
      _id: id,
      status: value,
      approvedBy: state?.auth?.auth?._id,
    };

    const token = state?.auth?.auth?.token;
    const response = await UpdateLeave(Payload, token);
    if (response && response.statusCode === 200) {
      successMsg(response.message);
      getleave();
    } else {
      errorMsg(response.message || response.error.response.data.message);
      //setfailureModal(true);
    }
  };

  const [roleList, setRoleList] = useState([]);
  const getListEmployeeRole = async () => {
    setLoading(true);
    const token = state?.auth?.auth?.token;
    const response = await GetAllEmployeeRole(token, "active");
    if (response.statusCode === 200) {
      setRoleList(response.data);
      setLoading(false);
    } else {
      setRoleList([]);
      setLoading(false);
    }
  };

  //specific get
  const fetchDataProfile = async (id) => {
    setLeaveModuleData(
      "duration",
      "Single Day"
    );
    const token = state?.auth?.auth?.token;
    const response = await leaveSpecific(token, id);
    if (response && response?.statusCode === 200) {
      setSpecificleaveList(response?.data?.slice().reverse());
    } else {
      setSpecificleaveList({});
      //console.log("error while getting user profile info");
    }
  };

  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },

    {
      name: "type",
      label: "Leave Type",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  fetchDataProfile(tableMeta.rowData[0]);
                  setLeavedetailsModal(true);
                }}
              >
                {value}
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "startDate",
      label: "Start Date",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>
        },
      },
    },
    {
      name: "endDate",
      label: "End Date",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>

        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <div
                // style={{ cursor: "pointer" }}
                // onClick={() => {
                //   fetchDataProfile(tableMeta.rowData[0]);
                //   setLeavedetailsModal(true);
                // }}
                className={
                  value === "approved"
                    ? "text-primary"
                    : "" || value === "rejected"
                      ? "text-danger"
                      : "" || value === "pending"
                        ? "text-warning"
                        : ""
                }
                style={{ textTransform: "capitalize" }}
              >
                {value}
              </div>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
  };

  const columnsForAdmin = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "username",
      label: "Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                fetchDataProfile(tableMeta.rowData[0]);
                setLeavedetailsModal(true);
              }}
            >
              {value}
            </div>
          );
        },
      },
    },
    {
      name: "type",
      label: "Leave Type",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  fetchDataProfile(tableMeta.rowData[0]);
                  setLeavedetailsModal(true);
                }}
              >
                {value}
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "startDate",
      label: "Start Date",
      options: {
        customBodyRender: (value) => {
          return (
            <div>
              <div>{value}</div>
            </div>
          );
        },
      },
    },
    {
      name: "endDate",
      label: "End Date",
      options: {
        customBodyRender: (value) => {
          return (
            <div>
              <div>{value}</div>
            </div>
          );
        },
      },
    },

    {
      name: "status",
      label: "Approval",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <select
              className="form-control form-control-lg"
              value={value}
              onChange={(e) => {
                handleSubmitUpdate(e, e.target.value, tableMeta.rowData[0]);
              }}
            >
              {value === "approved" ? (
                <option value="approved">Approved</option>
              ) : (
                <option value="approved">Approve</option>
              )}
              <option value="pending">Pending</option>
              {value === "rejected" ? (
                <option value="rejected">Rejected</option>
              ) : (
                <option value="rejected">Reject</option>
              )}
            </select>
          );
        },
      },
    },
  ];

  const optionsForAdmin = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
  };

  return (
    <div>
      <ToastContainer />

      <AdminNavbar />

      <div className="main">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div className="container">
              <div className="d-flex mb-4 justify-content-between align-items-center">
                <h5 className="mb-0  fw-bold color-maroon">Leave Module</h5>
                <div>
                  <button
                    type="button"
                    onClick={() => setleaveModal(true)}
                    // onClick={() => setLeavedetailsModal(true)}
                    className="btn py-1 px-2 login-btn  d-flex justify-content-end align-items-center"
                  >
                    <span className="material-symbols-rounded me-1 align-middle">
                      add_circle
                    </span>
                    <span>Apply Leave</span>{" "}
                  </button>
                </div>
              </div>

              <div className="my-4">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 px-0">
                      <div className="tab-section">
                        <ul className="ps-0">
                          {AllTabs.map((tab, index) => {
                            return (
                              <div
                                key={index}
                                className={
                                  selectedTab === tab
                                    ? "serviceTab active"
                                    : "serviceTab inactive"
                                }
                                onClick={() => {
                                  if (tab === "All") {
                                    getleaveAll();
                                  } else if (tab === "Approved") {
                                    getleave("approved");
                                  } else if (tab === "Rejected") {
                                    getleave("rejected");
                                  } else if (tab === "Pending") {
                                    getleave("pending");
                                  }

                                  // else if (tab === "Hold") {
                                  //   GetMyTsks("Hold");
                                  // }

                                  setSelectedTab(tab);
                                }}
                              >
                                <li>
                                  <span className="align-middle">{tab}</span>
                                </li>
                              </div>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-12 my-2"> */}
                {(state?.auth?.auth?.role === "superadmin" || state?.auth?.auth?.role === "admin") ? (
                  <MUIDataTable
                    title={" Dalmaf - Leave Module"}
                    data={leavelist}
                    columns={columnsForAdmin}
                    options={optionsForAdmin}
                  />
                ) : (
                  <MUIDataTable
                    title={"My Leave"}
                    data={leavelist}
                    columns={columns}
                    options={options}
                  />
                )}
                {/* </div> */}
              </div>
            </div>
          </>
        )}
      </div>
      <CustomModal
        open={leaveModal}
        onClickOutside={() => {
          setleaveModal(false);
          setCreateLeavemodule({});
        }}
      >
        <div className="modal-content w-75 m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{
                  marginRight: "30px",
                  marginTop: "50px",
                  textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                }}
                onClick={() => {
                  setleaveModal(false);
                  setCreateLeavemodule({});
                }}
              >
                close
              </span>
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className=" mt-4 mb-3 px-3">
                        <div className="row justify-content-center mt-2">
                          <div className="col-lg-11">
                            <div className="mb-5">
                              <h4 className="fw-bold mt-2">Apply Leave</h4>
                            </div>

                            <div className="row ">
                              <div className="col-lg-3 col-md-3 col-3 mb-2">
                                <label className="form-label">Leave Type</label>
                              </div>
                              <div className="col-lg-9 col-md-9 col-9 mb-2">
                                <select
                                  className="form-control form-control-lg"
                                  aria-invalid={Boolean(
                                    createLeaveValidation?.type?.status === false
                                  )}
                                  value={createLeavemodule?.type}
                                  onChange={(e) =>
                                    setLeaveModuleData("type", e.target.value)
                                  }
                                  onBlur={(e) => {
                                    setCreateValidationValue(
                                      "type",
                                      NonEmptyValidation(e.target.value)
                                    );
                                  }}
                                  autoComplete="off"
                                >
                                  <option value="">---Select Type---</option>
                                  <option value="Casual Leave ">
                                    Casual Leave
                                  </option>

                                  <option value="Sick Leave">Sick Leave</option>

                                </select>
                                <small className="text-danger">
                                  {createLeaveValidation?.type?.message
                                    ? `Leave Type ${createLeaveValidation?.type?.message}`
                                    : ""}
                                </small>

                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-3 col-md-3 col-3 mb-2">
                                <label className="form-label">Duration</label>

                              </div>
                              <div className="col-lg-9 col-md-9 col-9 mb-2">
                                {/* <div className="d-flex "> */}
                                  <select
                                    className="form-control form-control-lg"
                                    aria-invalid={Boolean(
                                      createLeaveValidation?.duration?.status === false
                                    )}
                                    value={createLeavemodule?.duration}
                                    onChange={(e) => {
                                      setDurationDate(e.target.value);
                                      setLeaveModuleData("duration", e.target.value);
                                    }}
                                    onBlur={(e) => {
                                      setCreateValidationValue(
                                        "duration",
                                        NonEmptyValidation(e.target.value)
                                      );
                                    }}
                                    autoComplete="off"
                                  >
                                    <option value="">---Select Leave Type---</option>
                                    {AllDurationType?.map((item, index) => (
                                      <option key={index} value={item}>
                                        {item}
                                      </option>
                                    ))}
                                  </select>
                                  <small className="text-danger">
                                    {createLeaveValidation?.duration?.message
                                      ? `Duration ${createLeaveValidation?.duration?.message}`
                                      : ""}
                                  </small>
                                {/* </div> */}
                              </div>
                            </div>
                            {durationDate === "Single Day" && (
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-3 mb-2">
                                  <label className="form-label">Date</label>
                                  /
                                </div>
                                <div className="col-lg-9 col-md-9 col-9 mb-2">
                                  <input
                                    id="Date"
                                    type="date"
                                    className="form-control"
                                    placeholder=""
                                    required
                                    aria-invalid={Boolean(
                                      createLeaveValidation?.startDate?.status ===
                                      false
                                    )}
                                    value={createLeavemodule?.startDate}
                                    onChange={(e) => {
                                      setMulti(true);
                                      setLeaveModuleData(
                                        "startDate",
                                        e.target.value
                                      );
                                    }}
                                    onBlur={(e) => {
                                      setCreateValidationValue(
                                        "startDate",
                                        NonEmptyValidation(e.target.value)
                                      );
                                    }}
                                  />
                                  <small className="text-danger">
                                    {createLeaveValidation?.startDate?.message
                                      ? `Start Date ${createLeaveValidation?.startDate?.message}`
                                      : ""}
                                  </small>
                                </div>
                              </div>
                            )}
                            {durationDate === "Multiple Days" && (
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-3 mb-2">
                                  <label className="form-label">
                                    Start Date
                                  </label>
                                  /
                                </div>
                                <div className="col-lg-9 col-md-9 col-9 mb-2">
                                  <input
                                    id="Date"
                                    type="Date"
                                    className="form-control"
                                    placeholder=""
                                    required
                                    aria-invalid={Boolean(
                                      createLeaveValidation?.startDate?.status ===
                                      false
                                    )}
                                    value={createLeavemodule?.startDate}
                                    onChange={(e) =>
                                      setLeaveModuleData(
                                        "startDate",
                                        e.target.value
                                      )
                                    }
                                    onBlur={(e) => {
                                      setCreateValidationValue(
                                        "startDate",
                                        DateValidationn(e.target.value)
                                      );
                                    }}
                                  />
                                  <small className="text-danger">
                                    {createLeaveValidation?.startDate?.message
                                      ? `Start Date ${createLeaveValidation?.startDate?.message}`
                                      : ""}
                                  </small>
                                </div>
                                <div className="col-lg-3 col-md-3 col-3 mb-2">
                                  <label className="form-label">End Date</label>

                                </div>
                                <div className="col-lg-9 col-md-9 col-9 mb-2">
                                  <input
                                    id="Date"
                                    type="Date"
                                    className="form-control"
                                    placeholder=""
                                    required
                                    value={createLeavemodule?.endDate}
                                    aria-invalid={Boolean(
                                      createLeaveValidation?.endDate?.status ===
                                      false
                                    )}
                                    onChange={(e) =>
                                      setLeaveModuleData(
                                        "endDate",
                                        e.target.value
                                      )
                                    }
                                    onBlur={(e) => {
                                      setCreateValidationValue(
                                        "endDate",
                                        ExpireDateValidationn(createLeavemodule?.startDate, e.target.value)
                                      );
                                    }}
                                  />
                                  <small className="text-danger">
                                    {createLeaveValidation?.endDate?.message
                                      ? `End Date ${createLeaveValidation?.endDate?.message}`
                                      : ""}
                                  </small>
                                </div>
                              </div>
                            )}

                            <div className="row ">
                              <div className="col-lg-3 col-md-3 col-3 mb-2">
                                <label className="form-label">Reason</label>

                              </div>
                              <div className="col-lg-9 col-md-9 col-9 mb-2">
                                <textarea
                                  rows={3}
                                  className="form-control"
                                  placeholder=""
                                  required
                                  aria-invalid={Boolean(
                                    createLeaveValidation?.content?.status === false
                                  )}
                                  defaultValue={createLeavemodule?.content}
                                  onChange={(e) =>
                                    setLeaveModuleData(
                                      "content",
                                      e.target.value
                                    )
                                  }
                                  onBlur={(e) => {
                                    setCreateValidationValue(
                                      "content",
                                      NonEmptyValidation(e.target.value)
                                    );
                                  }}
                                />
                                <small className="text-danger">
                                  {createLeaveValidation?.content?.message
                                    ? `Reason ${createLeaveValidation?.content?.message}`
                                    : ""}
                                </small>
                              </div>
                            </div>
                            <div className="mb-3 mt-3">
                              <div className="d-flex justify-content-end">
                                <button
                                  type="button"
                                  onClick={() => {
                                    setleaveModal(false);

                                    setCreateLeavemodule({});
                                  }}
                                  className="btn py-1 me-3 px-2 btn-light fw-bold  d-flex justify-content-end align-items-center"
                                >
                                  <span>Close</span>{" "}
                                </button>
                                <button
                                  type="button"
                                  className="btn py-1 px-2 login-btn  d-flex justify-content-end align-items-center"
                                  onClick={handleleaveModuleSubmit}
                                >
                                  <span className="material-symbols-rounded me-1 align-middle">
                                    task_alt
                                  </span>
                                  <span>Apply Leave</span>{" "}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={leavedetailsModal}
        onClickOutside={() => {
          setLeavedetailsModal(false);
        }}
      >
        <div className="modal-content w-75 m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{
                  marginRight: "30px",
                  marginTop: "50px",
                  textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                }}
                onClick={() => {
                  setLeavedetailsModal(false);
                }}
              >
                close
              </span>
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className=" mt-4 mb-3 px-3">
                        <div className="row justify-content-center mt-2">
                          <div className="col-lg-11">
                            <div className="mb-4">
                              <h4 className="fw-bold mt-2">
                                Application Details
                              </h4>
                            </div>
                            <div className="mb-3">
                              <div className="d-flex align-item-center ">
                                <img
                                  className=""
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: "50px",
                                  }}
                                  src={
                                    specificleaveList?.userimage
                                      ? specificleaveList?.userimage
                                      : Profile
                                  }
                                  alt=""
                                />
                                <div className="ms-3">
                                  <h6>{specificleaveList?.username}</h6>
                                  <h6 className="fw-bold color-maroon">
                                    {roleList
                                      ?.filter(
                                        (e) =>
                                          e._id ===
                                          specificleaveList?.userDesignation
                                      )
                                      .map((i) => (
                                        <div>{i?.title}</div>
                                      ))}
                                  </h6>
                                </div>
                              </div>
                            </div>

                            <div className="">
                              <table className="table table-hover">
                                <tbody>
                                  <tr>
                                    <th scope="row">Leave type</th>
                                    <td>
                                      <p className=" greenbox-bg mb-0">
                                        {specificleaveList?.type}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Date</th>
                                    <td>
                                      {moment(
                                        specificleaveList?.startDate
                                      ).format("DD/MM/YYYY")}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Duration</th>
                                    <td>
                                      {moment(
                                        specificleaveList?.endDate
                                      ).format("DD/MM/YYYY")}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Reason</th>
                                    <td> {specificleaveList?.content}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Status</th>
                                    <td>
                                      <th
                                        scope="row"
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {specificleaveList?.status}
                                      </th>

                                    </td>
                                  </tr>
                                  {specificleaveList?.status === "approved" ? (
                                    <tr>
                                      <th scope="row">Approved by</th>
                                      <td>
                                        <select
                                          className="form-control form-control-lg"
                                          value={specificleaveList?.approvedBy}
                                          disabled
                                        >
                                          {allSubadmin?.map((e) => (
                                            <option value={e?._id}>
                                              {e?.name}
                                            </option>
                                          ))}
                                        </select>
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )}
                                </tbody>
                              </table>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}
