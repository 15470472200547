import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './css/CreateTaskComponent.module.css';  // Adjust the path as necessary
import { useSelector } from 'react-redux';
import { createAPI, fetchWithQueryAPI, updateAPI, UploadDocument } from '../../../../Api/v1/Util/Api';
import { showErrorMsg, showSuccessMsg } from '../../../../Store/util';
import { useNavigate, useParams } from 'react-router-dom';
import { task_create, task_fetch_by_find_particular, task_update } from '../../../../Api/APIUrl';
import TaskCommentComponent from './TaskCommentComponent';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ToastContainer } from 'react-toastify';

export default function ViewSpecificTaskComponent({ showFullNav, routeProps, lableProps, userTypeProps, isClient }) {
    const navigate = useNavigate();
    const state = useSelector((state) => state);
    const { id, taskId } = useParams();

    const initialTaskState = {
        // reference_id: id,
        assign_to: state.auth.auth._id,
        title: '',
        description: '',
        points: 1,
        collaborators: [],
        status: 'To do',
        start_date: '',
        end_date: '',
        priority: '',
        document: '',
    };

    const [task, setTask] = useState(initialTaskState);
    const [errors, setErrors] = useState({});
    const [priorities] = useState([
        { value: 'Low', label: 'Low' },
        { value: 'Medium', label: 'Medium' },
        { value: 'Priority', label: 'Priority' }
    ]);

    const [status] = useState([
        { value: 'To do', label: 'To do' },
        { value: 'Inprogress', label: 'In Progress' },
        { value: 'Hold', label: 'Hold' },
        { value: 'Done', label: 'Done' }
    ]);

    useEffect(() => {
        handleFetchTask();
    }, []);

    const handleFetchTask = async () => {
        try {
            const response = await fetchWithQueryAPI(state.auth.auth.token, task_fetch_by_find_particular + taskId);
            if (response.statusCode === 200) {
                const data = response.data;
                setTask({
                    _id: data._id || '',
                    // reference_id: id,
                    assign_to: data.assign_to || '',
                    title: data.title || '',
                    description: data.description || '',
                    points: data.points || 1,
                    collaborators: data.collaborators || [],
                    status: data.status || 'To do',
                    start_date: data.start_date || '',
                    end_date: data.end_date || '',
                    priority: data.priority || 'Low',
                    document: data.document || '',
                });
            }
        } catch (error) {
            console.error('Error fetching task:', error);
        }
    };

    const handleInputChange = (e, field) => {
        setTask({ ...task, [field]: e.target.value });
    };

    const handleSelectChange = (selectedOption, field) => {
        setTask({ ...task, [field]: selectedOption });
    };

    const handleDescriptionChange = (value) => {
        setTask((prevTask) => ({ ...prevTask, description: value }));
    };

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                const response = await UploadDocument(state.auth.auth.token, file);
                setTask({ ...task, document: response.url });
            } catch (error) {
                console.error('Error uploading document:', error);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = {};
        if (!task.title) newErrors.title = 'Title is required';
        if (!task.description) newErrors.description = 'Description is required';
        if (!task.start_date) newErrors.start_date = 'Start Date is required';
        if (!task.end_date) newErrors.end_date = 'End Date is required';
        if (!task.priority) newErrors.priority = 'Priority is required';
        if (!task.status) newErrors.status = 'Status is required';
        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) return;

        const updatedTask = {
            ...task,
            status: task.status.value || task.status,
            priority: task.priority.value || task.priority,
        };

        const response = await updateAPI(state.auth.auth.token, task_update, updatedTask);
        if (response && response.statusCode === 200) {
            showSuccessMsg(response.message);
            setTask(initialTaskState);
            if(routeProps!='task')
                navigate(`/${userTypeProps}/${routeProps}/view-${routeProps}/${id}`); 
            else{
                navigate('/entrepreneurui/task/my-task-list');
            }
            
        } else {
            showErrorMsg(response.message || response.error.response.data.message);
        }
    };

    return (
        <>
            <ToastContainer/>
            <form className={showFullNav ? styles.taskForm : styles.taskFormSmallNav} onSubmit={handleSubmit}>
                <div className='row'>
                    <div className='col-md-6'>
                        <h2>Task Info</h2>
                    </div>
                    <div className='col-md-6'>
                        <button type="button" className="btn btn-primary" style={{ float: 'right' }}
                             onClick={() => { 
                                if(routeProps!='task')
                                    navigate(`/${userTypeProps}/${routeProps}/view-${routeProps}/${id}`); 
                                else{
                                    navigate('/entrepreneurui/task/my-task-list');
                                }
                            }
                        }>
                            <ArrowBackIcon />
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className={styles.formGroup}>
                            <div className={styles.inputContainer}>
                                <input
                                    type="text"
                                    id="title"
                                    value={task.title}
                                    onChange={(e) => handleInputChange(e, 'title')}
                                    className={`${styles.input} ${errors.title ? styles.errorInput : ''}`}
                                    placeholder=" "
                                />
                                <label htmlFor="title" className={`${styles.placeholder} ${task.title ? styles.filled : ''}`}>
                                    Title
                                </label>
                            </div>
                            {errors.title && <span className={styles.error}>{errors.title}</span>}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className={styles.formGroup}>
                            <div className={styles.inputContainer}>
                                <input
                                    type="date"
                                    id="start_date"
                                    value={task.start_date ? new Date(task.start_date).toISOString().split('T')[0] : ''}
                                    onChange={(e) => handleInputChange(e, 'start_date')}
                                    className={`${styles.input} ${errors.start_date ? styles.errorInput : ''}`}
                                    placeholder=" "
                                />
                                <label htmlFor="start_date" className={`${styles.placeholder} ${task.start_date ? styles.filled : ''}`}>
                                    Start Date
                                </label>
                            </div>
                            {errors.start_date && <span className={styles.error}>{errors.start_date}</span>}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className={styles.formGroup}>
                            <div className={styles.inputContainer}>
                                <input
                                    type="date"
                                    id="end_date"
                                    value={task.end_date ? new Date(task.end_date).toISOString().split('T')[0] : ''}
                                    onChange={(e) => handleInputChange(e, 'end_date')}
                                    className={`${styles.input} ${errors.end_date ? styles.errorInput : ''}`}
                                    placeholder=" "
                                />
                                <label htmlFor="end_date" className={`${styles.placeholder} ${task.end_date ? styles.filled : ''}`}>
                                    End Date
                                </label>
                            </div>
                            {errors.end_date && <span className={styles.error}>{errors.end_date}</span>}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className={styles.formGroup}>
                            <label htmlFor="priority" className={`${styles.placeholder} ${task.priority ? styles.filled : ''}`}>
                                Priority
                            </label>
                            <Select
                                id="priority"
                                value={priorities.find(priority => priority.value === task.priority)}
                                onChange={(selectedOption) => handleSelectChange(selectedOption, 'priority')}
                                options={priorities}
                                placeholder="Select Priority"
                            />
                            {errors.priority && <span className={styles.error}>{errors.priority}</span>}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className={styles.formGroup}>
                            <label htmlFor="status" className={`${styles.placeholder} ${task.status ? styles.filled : ''}`}>
                                Status
                            </label>
                            <Select
                                id="status"
                                value={status.find(stat => stat.value === task.status)}
                                onChange={(selectedOption) => handleSelectChange(selectedOption, 'status')}
                                options={status}
                                placeholder="Select Status"
                            />
                            {errors.status && <span className={styles.error}>{errors.status}</span>}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className={styles.formGroup}>
                            <div className={styles.inputContainer}>
                                <input
                                    type="text"
                                    id="points"
                                    value={task.points}
                                    onChange={(e) => handleInputChange(e, 'points')}
                                    className={`${styles.input} ${errors.points ? styles.errorInput : ''}`}
                                    placeholder=" "
                                />
                                <label htmlFor="points" className={`${styles.placeholder} ${task.points ? styles.filled : ''}`}>
                                    Points
                                </label>
                            </div>
                            {errors.points && <span className={styles.error}>{errors.points}</span>}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className={styles.formGroup}>
                            <ReactQuill
                                value={task.description}
                                style={{ height: '200px' }}
                                onChange={handleDescriptionChange}
                                className={`${errors.description ? styles.errorInput : ''}`}
                                placeholder="Enter task description..."
                            />
                            {errors.description && <span className={styles.error}>{errors.description}</span>}
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className={styles.formGroup} style={{ marginTop: "50px" }}>
                            <label htmlFor="document" className={`${styles.placeholder} ${task.document ? styles.filled : ''}`}>
                                Document
                            </label>
                            <input
                                type="file"
                                id="document"
                                onChange={handleFileUpload}
                                className={`${errors.document ? styles.errorInput : ''}`}
                            />
                            {errors.document && <span className={styles.error}>{errors.document}</span>}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <button type="submit" className="btn btn-primary">Update Task</button>
                    </div>
                </div>
            </form>
            <TaskCommentComponent showFullNav={showFullNav} />
        </>
    );
}
