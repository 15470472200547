import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import AdminNavbar from "../Navbar/AdminNavbar";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import InvestorDetails from "./InvestorDetails";

export default function InvestorList() {
  const [value, setValue] = React.useState('New');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div>
      <ToastContainer />
      <AdminNavbar />
      <div className="main">
      {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}> */}
          <Tabs value={value} onChange={handleChange} aria-label="Investor Request List">
            <Tab label="New" value="New" />
            <Tab label="Review" value="Review" />
            <Tab label="Pending" value="Pending" />
            <Tab label="Rejected" value="Rejected" />
            <Tab label="Approved" value="Converted" />
          </Tabs>
        {/* </Box> */}
        <InvestorDetails status={value} />
      </div>
    </div>
  );
}
