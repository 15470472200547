import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import styles from './css/ViewLeadComponent.module.css';
import LeadInfoComponent from './LeadInfoComponent';
import ListLeadContactComponent from './ListLeadContactComponent';
import ListNotesComponent from './ListNotesComponent';
import ListTaskComponent from './ListTaskComponent';
import ListEstimate from './ListEstimate';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { updateAPI } from '../../../../Api/v1/Util/Api';
import { convert_to_client } from '../../../../Api/APIUrl';
import { showErrorMsg, showSuccessMsg } from '../../../../Store/util';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import DasboardComponent from './DasboardComponent';

export default function ListLeadComponent({showFullNav,routeProps,lableProps,userTypeProps,isClientProps}) {
    const navigate = useNavigate();
    const { id } = useParams();
    const state = useSelector((state) => state);

    const handleTabSelect = (index) => {
        setSelectedTab(index);
        localStorage.setItem(`selectedSubTab-${routeProps}`, index);
    }; 

    const [selectedTab, setSelectedTab] = useState(0);

    useEffect(() => {
        const savedTab = localStorage.getItem(`selectedSubTab-${routeProps}`);
        if (savedTab !== null) {
        setSelectedTab(Number(savedTab));
        }
    }, [routeProps]);

    const markAsClient = async () => {
        Swal.fire({
            title: "Hey, great to hear!",
            text: "Just make sure the client has confirmed your proposal and agreed to proceed as your client.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Yes, make it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await updateAPI(state.auth.auth.token, convert_to_client, { _id: id });
                    if (response && response.statusCode === 200) {
                        showSuccessMsg(response.message);
                    } else {
                        showErrorMsg(response.message || response.error?.response?.data?.message || 'An error occurred');
                    }
                } catch (error) {
                    showErrorMsg("An error occurred while marking as client"); // Updated the error message
                }
            }
        });
    };
    return (
        <>
            <ToastContainer />
            
            <div className={showFullNav ? styles.leadsContainer : styles.leadsContainerSmallNav}>
                <div className='row'>
                    <div className='col-md-6'>
                        <h3>{lableProps} Details</h3>
                    </div>
                    <div className='col-md-6'>
                        <button className='btn btn-primary' style={{float:'right'}} onClick={()=>{navigate(`/${userTypeProps}/${routeProps}/my-${routeProps}-list`);}}><ArrowBackIcon /></button>
                        {!isClientProps &&<button className='btn' style={{float:'right',border:'2px solid',marginRight:'5px'}} onClick={markAsClient}>Mark as Client</button>}
                    </div>
                </div>
            
            <Tabs selectedIndex={selectedTab} onSelect={handleTabSelect}>
                <TabList>
                    <Tab>Dashboard</Tab>
                    <Tab>Contacts</Tab>
                    <Tab>{lableProps} Info</Tab>
                    <Tab>Task</Tab>
                    <Tab>Estimate</Tab>
                    <Tab>Proposals</Tab>
                    <Tab>Notes</Tab>
                </TabList>
                <TabPanel>
                    <DasboardComponent routeProps={routeProps} lableProps={lableProps} userTypeProps={userTypeProps} showFullNav={showFullNav} isClientProps={isClientProps}/>
                </TabPanel>
                <TabPanel>
                    <ListLeadContactComponent routeProps={routeProps} lableProps={lableProps} userTypeProps={userTypeProps} showFullNav={showFullNav} isClientProps={isClientProps}/>
                </TabPanel>
                <TabPanel>
                    <LeadInfoComponent routeProps={routeProps} lableProps={lableProps} userTypeProps={userTypeProps} showFullNav={showFullNav} isClientProps={isClientProps}/>
                </TabPanel>
                <TabPanel>
                    <ListTaskComponent routeProps={routeProps} lableProps={lableProps} userTypeProps={userTypeProps} showFullNav={showFullNav} isClientProps={isClientProps}/>
                </TabPanel>
                <TabPanel>
                    <ListEstimate titleProps='Estimate' typeProps='Estimation' routeProps={routeProps} lableProps={lableProps} userTypeProps={userTypeProps} showFullNav={showFullNav} isClientProps={isClientProps}/>
                </TabPanel>
                <TabPanel>
                <ListEstimate titleProps='Proposal' typeProps='Proposal' routeProps={routeProps} lableProps={lableProps} userTypeProps={userTypeProps} showFullNav={showFullNav} isClientProps={isClientProps}/>
                </TabPanel>
                <TabPanel>
                    <ListNotesComponent routeProps={routeProps} lableProps={lableProps} userTypeProps={userTypeProps} showFullNav={showFullNav} isClientProps={isClientProps}/>
                </TabPanel>
            </Tabs>
            </div>
        </>
    );
}
