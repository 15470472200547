import { all, put, call, takeEvery } from "redux-saga/effects";
import { url } from "../../Api/BaseUrl";
import { actionTypes, loginSuccess, logOutSuccess } from "./action";
const APIURL = url;

// polyfill();
const modalSuccess = (type) => {
  alert("You have successfully Logged In!");
};

const modalWarning = (type) => {
  alert("Your account has been logged out!");
};

const modalError = (message) => {
  alert(message);
};

function* loginSaga({ type, payload }) {
  try {
    const data = yield call(APIURL + "/login", payload);
    if (data && data.authorization) {
      data.isLoggedIn = true;
      yield put(loginSuccess(data, type));
      modalSuccess("success");
    } else if (data.data === null) {
      modalWarning(data.message);
    } else {
      if (JSON.parse(data.error).message.includes("404")) {
        modalWarning("not found");
      }
      if (JSON.parse(data.error).message.includes("400")) {
        modalError("Invalid Password.");
      }
    }
  } catch (err) {
    modalWarning("warning");
  }
}

function* logOutSaga() {
  try {
    yield put(logOutSuccess());
    modalWarning("warning");
    // Router.push("/dashboard/");
  } catch (err) {}
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.LOGIN_REQUEST, loginSaga)]);
  yield all([takeEvery(actionTypes.LOGOUT, logOutSaga)]);
}
