import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import { pink } from "@mui/material/colors";
import { alpha, styled } from "@mui/material/styles";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { AdminwheelerPost, GetWheeler, UpdateWheeler } from "../../../Api/Api";
import Spinner from "../../../Spinner/Spinner";
import { NonEmptyValidation, NumberValidation } from "../../../Store/validate";
import AdminNavbar from "../Navbar/AdminNavbar";

const AdmincreateWheeler = () => {
  const state = useSelector((state) => state);
  const [selectedTab, setSelectedTab] = React.useState("Two Wheeler");
  const AllTabs = ["Two Wheeler", "Four Wheeler"];
  const navigate = useNavigate();
  useEffect(() => {
    window.scroll(0, 0);

    getWheelerList("twoWheeler");
  }, []);

  const [loading, setLoading] = useState(true);
  const [newWheelerTask, setNewWheelerTask] = useState(true);

  const [propertyWheelerList, setPropertyWheelerList] = useState([]);
  const getWheelerList = async (value) => {
    setLoading(true);
    const token = state?.auth?.auth?.token;
    const data = await GetWheeler(token);
    if (data && data.statusCode === 200) {
      let temp = [];
      let data1 = data.data.filter((e)=>e.wheelerType===value)
      data1.map((data, i) => {
        let dummy = {
          _id: data?._id,
          wheelerType: data?.wheelerType,
          referenceNo:data?.referenceNo,
          brandName: data?.brandName,
          modelName: data?.modelName,
          type: data?.type,
          bidingPrice: data?.bidingPrice,
          endDate: moment(data?.endDate).format("DD/MM/YYYY"),
          createdAt: moment(data?.createdAt).format("DD/MM/YYYY"),
          description: data?.description,
          status: data?.status,
        };
        temp.push(dummy);
        return null;
      });
      setPropertyWheelerList(temp);
      setLoading(false);
    } else {
      setPropertyWheelerList([]);
      setLoading(false);
      errorMsg(data.message);
    }
  };

  // const createProperty = async () => {
  //   const formData = new FormData();
  //   formData.append("file", file);
  //   //console.log(file, "testfile");
  //   const token = state?.auth?.auth?.token;
  //   const response = await CreateBulkUpload(token, formData);
  //   if (response && response.statusCode === 200) {
  //     successMsg(response.message);
  //     setPropertyModal(false);
  //   } else {
  //     errorMsg(response.message);
  //   }
  // };

  const successMsg = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const errorMsg = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const [myWheelerTask, setMyWheelerTask] = useState({});
  const [myWheelerTaskValidation, setMyWheelerTaskValidation] = useState({});

  const setMyTaskValue = (key, value) => {
    setMyWheelerTask({ ...myWheelerTask, [key]: value });
    if (myWheelerTaskValidation[key]) delete myWheelerTaskValidation[key];
  };

  const setWheelerValidationValue = (key, value) => {
    setMyWheelerTaskValidation({ ...myWheelerTaskValidation, [key]: value });
  };

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleEditorChange = (state) => {
    setEditorState(state);
    let isNotEmpty = state && state.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(convertToRaw(state.getCurrentContent()));
      // setData(html, "property_desc");
      setMyTaskValue("description", html)
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.wheelerType = NonEmptyValidation(myWheelerTask?.wheelerType);
    validate.referenceNo = NonEmptyValidation(myWheelerTask?.referenceNo);
    validate.brandName = NonEmptyValidation(myWheelerTask?.brandName);
    validate.color = NonEmptyValidation(myWheelerTask?.color);
    validate.modelName = NonEmptyValidation(myWheelerTask?.modelName);
    validate.type = NonEmptyValidation(myWheelerTask?.type);

    validate.bidingPrice = NumberValidation(myWheelerTask?.bidingPrice);
    validate.modelName = NonEmptyValidation(myWheelerTask?.modelName);
    // validate.endDate = NonEmptyValidation(myWheelerTask?.endDate);
    validate.description = NonEmptyValidation(myWheelerTask?.description);
    validate.modelYear = NumberValidation(myWheelerTask?.modelYear);

    setMyWheelerTaskValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        wheelerType: myWheelerTask.wheelerType,
        referenceNo:myWheelerTask.referenceNo,
        brandName: myWheelerTask.brandName,
        color: myWheelerTask.color,
        type: myWheelerTask.type,
        modelName: myWheelerTask.modelName,
        endDate: Date.UTC,//myWheelerTask.endDate,
        modelYear: myWheelerTask.modelYear,
        bidingPrice: myWheelerTask.bidingPrice,
        description: myWheelerTask.description,
      };
      let type = myWheelerTask.wheelerType;
      const token = state?.auth?.auth?.token;
      const response = await AdminwheelerPost(Payload, token);
      if (response && response.statusCode === 200) {
        successMsg(response.message);
        setMyWheelerTaskValidation({});
        setMyWheelerTask({});
        setNewWheelerTask(true);
        getWheelerList(type);
        if(type==="twoWheeler"){
          setSelectedTab("Two Wheeler");
        }else{
          setSelectedTab("Four Wheeler");
        }
        
        
        // setpropertyPost(response.data);
      } else {
        errorMsg(response.message || response.error.response.data.message);
      }
    } else {
      //console.log("Error while update user details");
    }
  };

  const handleSubmitStatusUpdate = async (e, value, id, type) => {
    e.preventDefault();
    const Payload = {
      _id: id,
      status: value,
    };
    const token = state?.auth?.auth?.token;
    const response = await UpdateWheeler(Payload, token);
    if (response && response.statusCode === 200) {
      successMsg(response.message);
      getWheelerList(type);
      // window.location.reload()
    } else {
      errorMsg(response.message || response.error.response.data.message);
      //setfailureModal(true);
    }
  };

  const PinkSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: pink[600],
      "&:hover": {
        backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: pink[600],
    },
  }));

  const label = { inputProps: { "aria-label": "Color switch demo" } };

  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "referenceNo",
      label: "Reference No",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={{textTransform:"capitalize"}}>
              {/* <div
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`/goldAuction/${tableMeta.rowData[0]}`)}
              >
                {value}

                
              </div> */}

              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`/WheelerEdit/${tableMeta.rowData[0]}`);
                }}
              >
                {value}
              </div>
            </div>
          );
        },
      },
      // options: {
      //   filter: false,
      //   customBodyRender: (value, meta) => {
      //     return meta.rowIndex + 1;
      //   },
      // },
    },
    {
      name: "wheelerType",
      label: "Vehicle Type",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={{textTransform:"capitalize"}}>
              {/* <div
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`/goldAuction/${tableMeta.rowData[0]}`)}
              >
                {value}

                
              </div> */}

              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`/WheelerEdit/${tableMeta.rowData[0]}`);
                }}
              >
                {value}
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "brandName",
      label: "brand",
    },
    {
      name: "modelName",
      label: "model",
    },
    {
      name: "type",
      label: "Type",
    },

    {
      name: "bidingPrice",
      label: "biding Price",
    },

    // {
    //   name: "endDate",
    //   label: "Deadline Date",
    //   options: {
    //     customBodyRender: (value) => {
    //       return <div>{moment(value).format("DD/MM/YYYY")}</div>;
    //     },
    //   },
    // },
    {
      name: "createdAt",
      label: "created Date",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    // {
    //   name: "description",
    //   label: "description",
    // },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              {value === "inactive" ? (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <PinkSwitch
                        {...label}
                        onChange={(e) => {
                          handleSubmitStatusUpdate(
                            e,
                            "active",
                            tableMeta.rowData[0],
                            tableMeta.rowData[1]
                          );
                        }}
                      />
                    }
                  />
                </FormGroup>
              ) : (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <PinkSwitch
                        {...label}
                        defaultChecked
                        onChange={(e) => {
                          handleSubmitStatusUpdate(
                            e,
                            "inactive",
                            tableMeta.rowData[0]
                          );
                        }}
                      />
                    }
                  />
                </FormGroup>
              )}
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
  };

  return (
    <div>
      <ToastContainer />
      <AdminNavbar />
      <div className="main">
        {loading ? (
          <Spinner />
        ) : (
          <>
            {newWheelerTask ? (
              <div>
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="mb-0 fw-bold color-maroon">Vehicle Auction</h5>
                  <div>
                    <button
                      type="button"
                      className="btn py-1 login-btn"
                      onClick={() => {
                        setNewWheelerTask(false);
                      }}
                    >
                      Create Vehicle
                    </button>
                  </div>
                </div>
                <div className="my-4">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-7 px-0">
                        <div className="tab-section">
                          <ul className="ps-0">
                            {AllTabs.map((tab, index) => {
                              return (
                                <div
                                  key={index}
                                  className={
                                    selectedTab === tab
                                      ? "serviceTab active"
                                      : "serviceTab inactive"
                                  }
                                  onClick={() => {
                                    if (tab === "Two Wheeler") {
                                      getWheelerList("twoWheeler");
                                    } else if (tab === "Four Wheeler") {
                                      getWheelerList("fourWheeler");
                                    }
                                    setSelectedTab(tab);
                                  }}
                                >
                                  <li>
                                    <span className="align-middle">{tab}</span>
                                  </li>
                                </div>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <MUIDataTable
                    title={"Dalmaf - Vehicle Auction "}
                    data={propertyWheelerList}
                    columns={columns}
                    options={options}
                  />
                </div>
                {/* <div className="my-4">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-7 px-0">
                       
                      </div>
                    </div>
                  </div>
                  <MUIDataTable
                    title={"Vehicle Auction"}
                    data={propertyWheelerList}
                    columns={columns}
                    options={options}
                  />
                </div> */}
              </div>
            ) : (
              <div>
                <div className="">
                  <button
                    type="button"
                    className="btn login-btn ms-2 "
                    onClick={() => {
                      setNewWheelerTask(true);
                      setMyWheelerTaskValidation({});
                      setMyWheelerTask({});
                      // window.location.reload();
                    }}
                  >
                    Back
                  </button>
                </div>
                <div className=" my-4">
                  <div className="bg-historytablehead rounded-3 py-3 px-3">
                    <h6 className="mb-0">Create Vehicle Auction</h6>
                  </div>
                  <div className="container">
                    <div className="row justify-content-center py-5">
                      <div className="col-lg-11">
                        <div className="row">
                        <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label">Reference Number</label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter Reference Number"
                                aria-invalid={Boolean(
                                  myWheelerTaskValidation?.referenceNo?.status ===
                                    false
                                )}
                                value={myWheelerTask?.referenceNo}
                                onChange={(e) => {
                                  setMyTaskValue("referenceNo", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setWheelerValidationValue(
                                    "referenceNo",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                              />
                              <small className="text-danger">
                                {myWheelerTaskValidation?.referenceNo?.message
                                  ? `Reference No ${myWheelerTaskValidation?.referenceNo?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label">Vehicle Type</label>
                              <select
                                className="form-control form-control-lg"
                                defaultValue={myWheelerTask?.wheelerType}
                                onChange={(e) => {
                                  setMyTaskValue("wheelerType", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setWheelerValidationValue(
                                    "wheelerType",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                              >
                                <option value="">
                                  ---Select Vehicle Type---
                                </option>
                                <option value="twoWheeler">Two Wheeler</option>
                                <option value="fourWheeler">
                                  Four Wheeler
                                </option>
                              </select>
                              {/* <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter the Wheeler Type"
                                aria-invalid={Boolean(
                                  myWheelerTaskValidation?.wheelerType?.status ===
                                    false
                                )}
                                value={myWheelerTask?.wheelerType}
                                onChange={(e) => {
                                  setMyTaskValue("wheelerType", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setWheelerValidationValue(
                                    "wheelerType",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              /> */}
                              <small className="text-danger">
                                {myWheelerTaskValidation?.wheelerType?.message
                                  ? `Wheeler Type ${myWheelerTaskValidation?.wheelerType?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>

                          {/* <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label">End Date</label>
                              <input
                                type="date"
                                className="form-control form-control-lg"
                                placeholder="Enter end date"
                                aria-invalid={Boolean(
                                  myWheelerTaskValidation?.endDate?.status ===
                                    false
                                )}
                                value={myWheelerTask?.endDate}
                                onChange={(e) => {
                                  setMyTaskValue("endDate", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setWheelerValidationValue(
                                    "endDate",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                              />
                              <small className="text-danger">
                                {myWheelerTaskValidation?.endDate?.message
                                  ? `End Date${myWheelerTaskValidation?.endDate?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div> */}

                          {/* <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">brandName</label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter the brandName"
                                aria-invalid={Boolean(
                                  myWheelerTaskValidation?.brandName?.status === false
                                )}
                                value={myWheelerTask?.brandName}
                                onChange={(e) => {
                                  setMyTaskValue("brandName", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setWheelerValidationValue(
                                    "brandName",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {myWheelerTaskValidation?.brandName?.message
                                  ? `brandName ${myWheelerTaskValidation?.brandName?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div> */}

                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label"> Color </label>
                              <input
                                type="tel"
                                className="form-control form-control-lg"
                                placeholder="Enter Your color"
                                aria-invalid={Boolean(
                                  myWheelerTaskValidation?.color?.status ===
                                    false
                                )}
                                value={myWheelerTask?.color}
                                onChange={(e) => {
                                  setMyTaskValue("color", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setWheelerValidationValue(
                                    "color",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {myWheelerTaskValidation?.color?.message
                                  ? `color ${myWheelerTaskValidation?.color?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label">Model Name</label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter the model name"
                                aria-invalid={Boolean(
                                  myWheelerTaskValidation?.modelName?.status ===
                                    false
                                )}
                                value={myWheelerTask?.modelName}
                                onChange={(e) => {
                                  setMyTaskValue("modelName", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setWheelerValidationValue(
                                    "modelName",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {myWheelerTaskValidation?.modelName?.message
                                  ? `Model Name ${myWheelerTaskValidation?.modelName?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label">Brand Name</label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter the Brand Name"
                                aria-invalid={Boolean(
                                  myWheelerTaskValidation?.brandName?.status ===
                                    false
                                )}
                                value={myWheelerTask?.brandName}
                                onChange={(e) => {
                                  setMyTaskValue("brandName", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setWheelerValidationValue(
                                    "brandName",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {myWheelerTaskValidation?.brandName?.message
                                  ? `Brand Name ${myWheelerTaskValidation?.brandName?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label">Bidding Price</label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter the bidingPrice"
                                aria-invalid={Boolean(
                                  myWheelerTaskValidation?.bidingPrice
                                    ?.status === false
                                )}
                                value={myWheelerTask?.bidingPrice}
                                onChange={(e) => {
                                  setMyTaskValue("bidingPrice", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setWheelerValidationValue(
                                    "bidingPrice",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {myWheelerTaskValidation?.bidingPrice?.message
                                  ? `Bidding Price ${myWheelerTaskValidation?.bidingPrice?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label">Year of Make</label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter the modelYear"
                                aria-invalid={Boolean(
                                  myWheelerTaskValidation?.modelYear?.status ===
                                    false
                                )}
                                value={myWheelerTask?.modelYear}
                                onChange={(e) => {
                                  setMyTaskValue("modelYear", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setWheelerValidationValue(
                                    "modelYear",
                                    NumberValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {myWheelerTaskValidation?.modelYear?.message
                                  ? `Year of Make ${myWheelerTaskValidation?.modelYear?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label"> Type</label>
                              <select
                                className="form-control form-control-lg"
                                value={myWheelerTask?.type}
                                onChange={(e) => {
                                  setMyTaskValue("type", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setWheelerValidationValue(
                                    "type",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                              >
                                <option value="">---Select Type---</option>
                                <option value="Auction">Auction</option>
                                <option value="Buy/Sell">Buy/Sell</option>
                              </select>

                              <small className="text-danger">
                                {myWheelerTaskValidation?.type?.message
                                  ? `Type ${myWheelerTaskValidation?.type?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label"> Description</label>
                              <Editor
                                    editorStyle={{ height: '400px',border: "1px solid" }}
                                    wrapperClassName="wrapper-class"
                                    editorClassName="editor-class"
                                    toolbarClassName="toolbar-class"
                                    editorState={editorState}
                                    
                                    onEditorStateChange={(e) =>
                                      handleEditorChange(e)
                                      
                                    }
                                  />
                              {/* <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter the description"
                                aria-invalid={Boolean(
                                  myWheelerTaskValidation?.description
                                    ?.status === false
                                )}
                                value={myWheelerTask?.description}
                                onChange={(e) => {
                                  setMyTaskValue("description", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setWheelerValidationValue(
                                    "description",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              /> */}
                              <small className="text-danger">
                                {myWheelerTaskValidation?.description?.message
                                  ? `Description ${myWheelerTaskValidation?.description?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>

                          <div className="col-lg-12 mt-4">
                            <button
                              type="button"
                              className="btn white-btn me-2 "
                              onClick={() => {
                                setMyWheelerTaskValidation({});
                                setMyWheelerTask({});
                                // window.location.reload();
                              }}
                            >
                              Cancel
                            </button>

                            <button
                              type="button"
                              className="btn login-btn ms-2 "
                              onClick={(e) => {
                                handleSubmit(e);
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AdmincreateWheeler;
