import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import ReactQuill from 'react-quill'; // Importing ReactQuill
import 'react-quill/dist/quill.snow.css'; // Importing styles for ReactQuill
import styles from './css/CreateLeadComponent.module.css';  // Adjust the path as necessary
import { useSelector } from 'react-redux';
import { createAPI, createLabelAPI, fetchLabelAPI } from '../../../../Api/v1/Util/Api';
import { showErrorMsg, showSuccessMsg } from '../../../../Store/util';
import { createNewLeadAPI } from '../../../../Api/v1/FreelancerPartner/leads';
import { useNavigate, useParams } from 'react-router-dom';
import { estimate_create, notes_create } from '../../../../Api/APIUrl';
import { ToastContainer } from 'react-toastify';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function CreateEstimateComponent({ showFullNav, routeProps, lableProps, userTypeProps, isClient }) { 
    const navigate = useNavigate();
    const state = useSelector((state) => state);
    const { id } = useParams();

    const initialLeadState = {
        reference_id: id,
        title: '',
        notes: '',
        estimate_date: '',
        valid_until: '',
        tax: 0,
        second_tax: 0,
        type: lableProps,
        amount:0
    };

    const [estimate, setEstimate] = useState(initialLeadState);
    const [errors, setErrors] = useState({});

    const handleInputChange = (e, field) => {
        setEstimate({ ...estimate, [field]: e.target.value });
    };

    const handleDescriptionChange = (value) => {
        setEstimate({ ...estimate, notes: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = {};
        if (!estimate.title) newErrors.title = 'Title is required';
        if (!estimate.notes) newErrors.notes = 'Notes are required';
        if (!estimate.estimate_date) newErrors.estimate_date = 'Estimate date is required';
        if (!estimate.valid_until) newErrors.valid_until = 'Valid until date is required';
        if (!estimate.amount) newErrors.amount = 'Amount is required';
        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) return;

        const response = await createAPI(state.auth.auth.token, estimate_create, estimate);
        if (response && response.statusCode === 200) {
            showSuccessMsg(response.message);
            setEstimate(initialLeadState);
            navigate(`/${userTypeProps}/${routeProps}/view-${routeProps}/${id}`);
        } else {
            showErrorMsg(response.message || response.error.response.data.message);
        }
    };

    return (
        <form className={styles.leadForm} onSubmit={handleSubmit}>
            <ToastContainer />

            <div className="row">
                <div className="col-md-6">
                    <h2>Create {lableProps}</h2>
                </div>
                <div className="col-md-6">
                    <button type="button" className="btn-primary btn" onClick={() => { navigate(`/${userTypeProps}/${routeProps}/view-${routeProps}/${id}`); }} style={{ marginLeft: '20px', float: 'right' }}><ArrowBackIcon /></button>
                </div>
                <div className="col-md-4">
                    <div className={styles.formGroup}>
                        <div className={styles.inputContainer}>
                            <input
                                type="text"
                                id="title"
                                value={estimate.title}
                                onChange={(e) => handleInputChange(e, 'title')}
                                className={`${styles.input} ${errors.title ? styles.errorInput : ''}`}
                                placeholder=" "
                            />
                            <label htmlFor="title" className={`${styles.placeholder} ${estimate.title ? styles.filled : ''}`}>
                                Title
                            </label>
                        </div>
                        {errors.title && <span className={styles.error}>{errors.title}</span>}
                    </div>
                </div>
                <div className="col-md-4">
                    <div className={styles.formGroup}>
                        <div className={styles.inputContainer}>
                            <input
                                type="number"
                                id="tax"
                                value={estimate.tax}
                                onChange={(e) => handleInputChange(e, 'tax')}
                                className={`${styles.input} ${errors.tax ? styles.errorInput : ''}`}
                                placeholder=" "
                            />
                            <label htmlFor="tax" className={`${styles.placeholder} ${estimate.tax ? styles.filled : ''}`}>
                                Primary Tax Amount
                            </label>
                        </div>
                        {errors.tax && <span className={styles.error}>{errors.tax}</span>}
                    </div>
                </div>
                <div className="col-md-4">
                    <div className={styles.formGroup}>
                        <div className={styles.inputContainer}>
                            <input
                                type="number"
                                id="second_tax"
                                value={estimate.second_tax}
                                onChange={(e) => handleInputChange(e, 'second_tax')}
                                className={`${styles.input} ${errors.second_tax ? styles.errorInput : ''}`}
                                placeholder=" "
                            />
                            <label htmlFor="second_tax" className={`${styles.placeholder} ${estimate.second_tax ? styles.filled : ''}`}>
                                Secondary Tax Amount
                            </label>
                        </div>
                        {errors.second_tax && <span className={styles.error}>{errors.second_tax}</span>}
                    </div>
                </div>
                <div className="col-md-4">
                    <div className={styles.formGroup}>
                        <div className={styles.inputContainer}>
                            <input
                                type="date"
                                id="estimate_date"
                                value={estimate.estimate_date}
                                onChange={(e) => handleInputChange(e, 'estimate_date')}
                                className={`${styles.input} ${errors.estimate_date ? styles.errorInput : ''}`}
                                placeholder=" "
                            />
                            <label htmlFor="estimate_date" className={`${styles.placeholder} ${estimate.estimate_date ? styles.filled : ''}`}>
                                Estimate Date
                            </label>
                        </div>
                        {errors.estimate_date && <span className={styles.error}>{errors.estimate_date}</span>}
                    </div>
                </div>
                <div className="col-md-4">
                    <div className={styles.formGroup}>
                        <div className={styles.inputContainer}>
                            <input
                                type="date"
                                id="valid_until"
                                value={estimate.valid_until}
                                onChange={(e) => handleInputChange(e, 'valid_until')}
                                className={`${styles.input} ${errors.valid_until ? styles.errorInput : ''}`}
                                placeholder=" "
                            />
                            <label htmlFor="valid_until" className={`${styles.placeholder} ${estimate.valid_until ? styles.filled : ''}`}>
                                Valid Until
                            </label>
                        </div>
                        {errors.valid_until && <span className={styles.error}>{errors.valid_until}</span>}
                    </div>
                </div>
                <div className="col-md-4">
                    <div className={styles.formGroup}>
                        <div className={styles.inputContainer}>
                            <input
                                type="number"
                                id="amount"
                                value={estimate.amount}
                                onChange={(e) => handleInputChange(e, 'amount')}
                                className={`${styles.input} ${errors.amount ? styles.errorInput : ''}`}
                                placeholder=" "
                            />
                            <label htmlFor="amount" className={`${styles.placeholder} ${estimate.amount ? styles.filled : ''}`}>
                                Amount
                            </label>
                        </div>
                        {errors.amount && <span className={styles.error}>{errors.amount}</span>}
                    </div>
                </div>
                <div className="col-md-12">
                    <div className={styles.formGroup}>
                        <div className={styles.inputContainer}>
                            <ReactQuill
                                value={estimate.notes}  // Set the value of ReactQuill to the estimate.notes state
                                onChange={handleDescriptionChange}
                                placeholder="Notes"
                                className={styles.editor}
                                style={{ height: "200px" }}
                            />
                        </div>
                        {errors.notes && <span className={styles.error}>{errors.notes}</span>}
                    </div>
                </div>
                <div className="col-md-12" style={{ marginTop: '40px' }}>
                    <button type="submit" className="btn btn-primary">Create {lableProps}</button>
                </div>
            </div>
        </form>
    );
}
