import React, {useEffect } from 'react';
import Footer from "../Components/Footer/Footer";
import Navigation from "../Components/Navbar/Navigation";
import HouseBanner from "../Components/Property/HouseBanner";
import HouseSpecific from "../Components/Property/HouseSpecific";
export default function HouseDetails() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="innerpages-nav">
        <div id="container">
      <Navigation />
      <HouseBanner />
      <HouseSpecific />
      <Footer />
      </div>
    </div>
  );
}
