import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { format, startOfToday, endOfToday, startOfWeek, endOfWeek, startOfMonth, endOfMonth, subDays, subMonths } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { fetchWithQueryAPI } from "../../../Api/v1/Util/Api";
import { attendance_automatic_clockout_list_admin_url } from "../../../Api/APIUrl";
import { utils, writeFileXLSX } from 'xlsx';

const AttendanceDefaulterList = () => {
  const state = useSelector((state) => state);
  const [defaulterData, setDefaulterData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fromDate, setFromDate] = useState(startOfToday());
  const [toDate, setToDate] = useState(endOfToday());

  const filterOptions = [
    // { label: "Today", calculateDates: () => [startOfToday(), endOfToday()] },
    { label: "Yesterday", calculateDates: () => [subDays(startOfToday(), 1), subDays(endOfToday(), 1)] },
    { label: "This Week", calculateDates: () => [startOfWeek(new Date()), endOfWeek(new Date())] },
    { label: "Last Week", calculateDates: () => [startOfWeek(subDays(new Date(), 7)), endOfWeek(subDays(new Date(), 7))] },
    { label: "This Month", calculateDates: () => [startOfMonth(new Date()), endOfMonth(new Date())] },
    { label: "Last Month", calculateDates: () => [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))] },
    { label: "Last 3 Months", calculateDates: () => [startOfMonth(subMonths(new Date(), 3)), endOfMonth(new Date())] },
    { label: "Last 6 Months", calculateDates: () => [startOfMonth(subMonths(new Date(), 6)), endOfMonth(new Date())] },
    { label: "Custom", custom: true }
  ];

  const handleAttendanceDefaulterList = async () => {
    if (!fromDate || !toDate) return;

    const formattedFromDate = format(fromDate, 'yyyy-MM-dd');
    const formattedToDate = format(toDate, 'yyyy-MM-dd');

    try {
      const response = await fetchWithQueryAPI(
        state.auth.auth.token,
        `${attendance_automatic_clockout_list_admin_url}?from_date=${formattedFromDate}&to_date=${formattedToDate}`
      );
      if (response.statusCode === 200) {
        setDefaulterData(response.data);
      } else {
        setError(response.message || "Failed to fetch data");
      }
    } catch (error) {
      setError("Error fetching defaulter data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleAttendanceDefaulterList();
  }, [fromDate, toDate]);

  const handleFilterChange = (filterOption) => {
    const [from, to] = filterOption.calculateDates();
    setFromDate(from);
    setToDate(to);
  };

  const formatDate = (date) => format(new Date(date), 'dd-MM-yyyy');

  const exportToExcel = () => {
    const ws = utils.json_to_sheet(defaulterData.map(item => ({
      Name: item.name,
      Email: item.email,
      Phone: item.phoneNumber,
      "Number of Clockouts": item.no_of_times,
      "Clockout Dates": item.automatic_clockout_dates.map(date => formatDate(date)).join(', '),
    })));

    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Attendance Defaulters");
    writeFileXLSX(wb, "AttendanceDefaulters.xlsx");
  };

  return (
    <div className="container mt-4">
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="text-danger">{error}</p>
      ) : (
        <>
          <div className="row">
            <div className="col-md-10">
              <table className="table table-striped mt-4">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Number of Missed Clockouts</th>
                    <th>Not Clockout Dates</th>
                  </tr>
                </thead>
                <tbody>
                  {defaulterData.map((item) => (
                    <tr key={item.employee_id}>
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                      <td>{item.phoneNumber}</td>
                      <td>{item.no_of_times}</td>
                      <td>
                        {item.automatic_clockout_dates.map(date => (
                          <span key={date}>{formatDate(date)}<br /></span>
                        ))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button className="btn btn-primary mt-4" onClick={exportToExcel}>Download Excel</button>
            </div>
            <div className="col-md-2">
              <label>Filter By</label>
              <select
                className="form-select"
                onChange={(e) => handleFilterChange(filterOptions[e.target.value])}
              >
                {filterOptions.map((option, index) => (
                  <option key={index} value={index}>{option.label}</option>
                ))}
              </select>
              <br />
              <label>From Date</label>
              <DatePicker
                selected={fromDate}
                onChange={(date) => setFromDate(date)}
                maxDate={new Date()}
                className="form-control"
                dateFormat="dd-MM-yyyy"
              />
              <br /><br />
              <label>To Date</label>
              <DatePicker
                selected={toDate}
                onChange={(date) => setToDate(date)}
                maxDate={new Date()}
                className="form-control"
                dateFormat="dd-MM-yyyy"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AttendanceDefaulterList;
