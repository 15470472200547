import React, { useEffect } from 'react';
import Footer from '../Components/Footer/Footer'
import Navigation from '../Components/Navbar/Navigation'
import { Retainership } from '../Components/Retainership/Retainership';

export default function Retainer() {
    useEffect(() =>{
        window.scroll(0,0)
      },[])
      
  return (
    <div className="innerpages-nav">
       <div id="container">
      <Navigation />
      <Retainership/>
      <Footer/>
      </div>
    </div>
  )
}
