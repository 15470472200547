import { useEffect, useState } from "react";
import UpdateLeadComponent from "../../../../Components/v1/FreelancerPartner/Leads/UpdateLeadComponent";
import FreelancerPartnerNav from "../../../../Components/v1/NavBar/FreelancerPartnerNav";
import EditContactComponent from "../../../../Components/v1/FreelancerPartner/Leads/EditContactComponent";


export default function UpdateContact({ userTypeProps, lableProps,isClient,routeProps }) {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  const [show, setShow] = useState(true); 
  const handleToggle = () => {setShow(!show);};


  return (
    <>
      <FreelancerPartnerNav showFullNav={show} onToggle={handleToggle} userTypeProps={userTypeProps} routeProps={routeProps} />
      <EditContactComponent showFullNav={show} lableProps={lableProps} userTypeProps={userTypeProps} isClient={isClient} routeProps={routeProps}/>
    </>
  );
}
