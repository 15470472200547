import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import AdminNavbar from "../Navbar/AdminNavbar";
// import { convertToRaw, EditorState } from "draft-js";
import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from "draftjs-to-html";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UpdateWheeler, WheelerSpecific } from "../../../Api/Api";

import { useNavigate, useParams } from "react-router-dom";
import {
  NonEmptyValidation,

  NumberValidation
} from "../../../Store/validate";

export default function WheelerEdit() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const { id } = useParams();
  useEffect(() => {
    GetWheelerSpecificTsks();
    window.scroll(0, 0);
  }, []);

  const [myWheelerTask, setMyWheelerTask] = useState({});
  const [myWheelerTaskValidation, setMyWheelerTaskValidation] = useState({});

  const setWheelerValue = (key, value) => {
    setMyWheelerTask({ ...myWheelerTask, [key]: value });
    if (myWheelerTaskValidation[key]) delete myWheelerTaskValidation[key];
  };

  const setWheelerValidationValue = (key, value) => {
    setMyWheelerTaskValidation({ ...myWheelerTaskValidation, [key]: value });
  };
  const GetWheelerSpecificTsks = async () => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await WheelerSpecific(token, id);
    if (data && data.statusCode === 200) {
      setMyWheelerTask(data.data);
      // console.log('test',data.data)
      setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(
        convertFromHTML(data.data.description)
      )))
      setLoading(false);
    } else {
      setMyWheelerTask([]);
      setLoading(false);
      //console.log("Error while getting my specific task");
    }
  };

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const handleEditorChange = (state) => {
    setEditorState(state);
    let isNotEmpty = state && state.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(convertToRaw(state.getCurrentContent()));
      // setData(html, "property_desc");
      setWheelerValue("description", html)
    }
  };



  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.wheelerType = NonEmptyValidation(myWheelerTask?.wheelerType);
    validate.referenceNo = NonEmptyValidation(myWheelerTask?.referenceNo);
    validate.brandName = NonEmptyValidation(myWheelerTask?.brandName);
    validate.color = NonEmptyValidation(myWheelerTask?.color);
    validate.modelName = NonEmptyValidation(myWheelerTask?.modelName);

    // validate.bidingPrice = NonEmptyValidation(myWheelerTask?.bidingPrice);
    validate.modelName = NonEmptyValidation(myWheelerTask?.modelName);
    // validate.endDate = NonEmptyValidation(myWheelerTask?.endDate);
    validate.description = NonEmptyValidation(myWheelerTask?.description);
    // validate.modelYear = NonEmptyValidation(myWheelerTask?.modelYear);



    setMyWheelerTaskValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        _id: id,
        wheelerType: myWheelerTask.wheelerType,
        referenceNo: myWheelerTask.referenceNo,
        brandName: myWheelerTask.brandName,
        color: myWheelerTask.color,
        type: myWheelerTask.type,
        modelName: myWheelerTask.modelName,
        //  endDate: myWheelerTask.endDate,
        modelYear: myWheelerTask.modelYear,
        bidingPrice: myWheelerTask.bidingPrice,
        description: myWheelerTask.description,
      };
      const token = state?.auth?.auth?.token;
      const response = await UpdateWheeler(Payload, token);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        window.scrollTo(0, 0);
        // GetWheelerSpecificTsks();
        navigate("/Vehicle")
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } else {
      //console.log("Error while update user details");
    }
  };



  return (
    <div>
      <AdminNavbar />
      <ToastContainer />
      <div className="main">
        <div>
          <div className="">
            <button
              type="button"
              className="btn login-btn ms-2 "
              onClick={(e) => {
                navigate(`/Vehicle`);
              }}
            >
              Back
            </button>
          </div>
          <div className=" my-4">
            <div className="bg-historytablehead rounded-3 py-3 px-3">
              <h6 className="mb-0">Update Wheeler</h6>
            </div>
            <div className="container">
              <div className="row justify-content-center py-5">
                <div className="col-lg-11">
                  <div className="row">

                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label">Reference No</label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Enter Reference No"
                          aria-invalid={Boolean(
                            myWheelerTaskValidation?.referenceNo?.status === false
                          )}
                          value={myWheelerTask?.referenceNo}
                          onChange={(e) => {
                            setWheelerValue("referenceNo", e.target.value);
                          }}
                          onBlur={(e) => {
                            setWheelerValidationValue(
                              "referenceNo",
                              NonEmptyValidation(e.target.value)
                            );
                          }}
                        />
                        <small className="text-danger">
                          {myWheelerTaskValidation?.referenceNo?.message
                            ? `End Date${myWheelerTaskValidation?.referenceNo?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label">Vehicle Type</label>
                        <select
                          className="form-control form-control-lg"
                          value={myWheelerTask?.wheelerType}
                          onChange={(e) => {
                            setWheelerValue("wheelerType", e.target.value);
                          }}
                          onBlur={(e) => {
                            setWheelerValidationValue(
                              "wheelerType",
                              NonEmptyValidation(e.target.value)
                            );
                          }}
                        >

                          <option value="twoWheeler">Two Wheeler</option>
                          <option value="fourWheeler">Four Wheeler</option>
                        </select>
                        <small className="text-danger">
                          {myWheelerTaskValidation?.wheelerType?.message
                            ? `Vehicle Type ${myWheelerTaskValidation?.wheelerType?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>

                    {/* <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label">End Date</label>
                        <input
                          type="date"
                          className="form-control form-control-lg"
                          placeholder="Enter end date"
                          aria-invalid={Boolean(
                            myWheelerTaskValidation?.endDate?.status === false
                          )}
                          value={moment(myWheelerTask?.endDate).format("YYYY-MM-DD")}
                          onChange={(e) => {
                            setWheelerValue("endDate", e.target.value);
                          }}
                          onBlur={(e) => {
                            setWheelerValidationValue(
                              "endDate",
                              NonEmptyValidation(e.target.value)
                            );
                          }}
                        />
                        <small className="text-danger">
                          {myWheelerTaskValidation?.endDate?.message
                            ? `End Date${myWheelerTaskValidation?.endDate?.message}`
                            : ""}
                        </small>
                      </div>
                    </div> */}

                    {/* <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">brandName</label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter the brandName"
                                aria-invalid={Boolean(
                                  myWheelerTaskValidation?.brandName?.status === false
                                )}
                                value={myWheelerTask?.brandName}
                                onChange={(e) => {
                                  setWheelerValue("brandName", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setWheelerValidationValue(
                                    "brandName",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {myWheelerTaskValidation?.brandName?.message
                                  ? `brandName ${myWheelerTaskValidation?.brandName?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div> */}

                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label"> Color </label>
                        <input
                          type="tel"
                          className="form-control form-control-lg"
                          placeholder="Enter Your color"
                          aria-invalid={Boolean(
                            myWheelerTaskValidation?.color?.status === false
                          )}
                          value={myWheelerTask?.color}
                          onChange={(e) => {
                            setWheelerValue("color", e.target.value);
                          }}
                          onBlur={(e) => {
                            setWheelerValidationValue(
                              "color",
                              NonEmptyValidation(e.target.value)
                            );
                          }}
                          autoComplete="off"
                        />
                        <small className="text-danger">
                          {myWheelerTaskValidation?.color?.message
                            ? `color ${myWheelerTaskValidation?.color?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label">Model Name</label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Enter the model name"
                          aria-invalid={Boolean(
                            myWheelerTaskValidation?.modelName?.status === false
                          )}
                          value={myWheelerTask?.modelName}
                          onChange={(e) => {
                            setWheelerValue("modelName", e.target.value);
                          }}
                          onBlur={(e) => {
                            setWheelerValidationValue(
                              "modelName",
                              NonEmptyValidation(e.target.value)
                            );
                          }}
                          autoComplete="off"
                        />
                        <small className="text-danger">
                          {myWheelerTaskValidation?.modelName?.message
                            ? `Model Name ${myWheelerTaskValidation?.modelName?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label">Brand Name</label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Enter the Brand Name"
                          aria-invalid={Boolean(
                            myWheelerTaskValidation?.brandName?.status === false
                          )}
                          value={myWheelerTask?.brandName}
                          onChange={(e) => {
                            setWheelerValue("brandName", e.target.value);
                          }}
                          onBlur={(e) => {
                            setWheelerValidationValue(
                              "brandName",
                              NonEmptyValidation(e.target.value)
                            );
                          }}
                          autoComplete="off"
                        />
                        <small className="text-danger">
                          {myWheelerTaskValidation?.brandName?.message
                            ? `Brand Name ${myWheelerTaskValidation?.brandName?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label">Bidding Price</label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Enter the bidingPrice"
                          aria-invalid={Boolean(
                            myWheelerTaskValidation?.bidingPrice?.status === false
                          )}
                          value={myWheelerTask?.bidingPrice}
                          onChange={(e) => {
                            setWheelerValue("bidingPrice", e.target.value);
                          }}
                          onBlur={(e) => {
                            setWheelerValidationValue(
                              "bidingPrice",
                              NonEmptyValidation(e.target.value)
                            );
                          }}
                          autoComplete="off"
                        />
                        <small className="text-danger">
                          {myWheelerTaskValidation?.bidingPrice?.message
                            ? `Bidding Price ${myWheelerTaskValidation?.bidingPrice?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label"> Model Year</label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Enter the modelYear"
                          aria-invalid={Boolean(
                            myWheelerTaskValidation?.modelYear?.status === false
                          )}
                          defaultValue={myWheelerTask?.modelYear}
                          onChange={(e) => {
                            setWheelerValue("modelYear", e.target.value);
                          }}
                          onBlur={(e) => {
                            setWheelerValidationValue(
                              "modelYear",
                              NumberValidation(e.target.value)
                            );
                          }}
                          autoComplete="off"
                        />
                        <small className="text-danger">
                          {myWheelerTaskValidation?.modelYear?.message
                            ? `Model year ${myWheelerTaskValidation?.modelYear?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label"> Type</label>
                        <select
                          className="form-control form-control-lg"
                          value={myWheelerTask?.type}
                          onChange={(e) => {
                            setWheelerValue("type", e.target.value);
                          }}
                          onBlur={(e) => {
                            setWheelerValidationValue(
                              "type",
                              NonEmptyValidation(e.target.value)
                            );
                          }}
                        >
                          <option value="Auction">Auction</option>
                          <option value="Buy/Sell">Buy/Sell</option>
                        </select>

                        <small className="text-danger">
                          {myWheelerTaskValidation?.type?.message
                            ? `Type ${myWheelerTaskValidation?.type?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-4">
                        <label className="form-label"> Description</label>
                        {/* <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Enter the description"
                          aria-invalid={Boolean(
                            myWheelerTaskValidation?.description?.status === false
                          )}
                          value={myWheelerTask?.description}
                          onChange={(e) => {
                            setWheelerValue("description", e.target.value);
                          }}
                          onBlur={(e) => {
                            setWheelerValidationValue(
                              "description",
                              NonEmptyValidation(e.target.value)
                            );
                          }}
                          autoComplete="off"
                        /> */}
                        {/* {console.log("myWheelerTask?.description",myWheelerTask?.description)} */}
                        <Editor
                          editorStyle={{ height: '400px', border: "1px solid" }}
                          wrapperClassName="wrapper-class"
                          editorClassName="editor-class"
                          toolbarClassName="toolbar-class"
                          editorState={editorState}

                          onEditorStateChange={(e) =>
                            handleEditorChange(e)

                          }
                        />
                        <small className="text-danger">
                          {myWheelerTaskValidation?.description?.message
                            ? `Description ${myWheelerTaskValidation?.description?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>



                    <div className="col-lg-12 mt-4">
                      {/* <button
                        type="button"
                        className="btn white-btn me-2 "
                        onClick={() => {
                          setMyWheelerTaskValidation({});
                          setMyWheelerTask({});
                          window.location.reload();
                        }}
                      >
                        Reset
                      </button> */}

                      <button
                        type="button"
                        className="btn login-btn ms-2 "
                        onClick={(e) => {
                          handleSubmit(e);
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
