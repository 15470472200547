import React, { useEffect, useState } from "react";
import { ListinglogOnRole } from "../../../Api/Api";
import AdminNavbar from "../Navbar/AdminNavbar";

import moment from "moment";
import { useSelector } from "react-redux";
import Spinner from "../../../Spinner/Spinner";
import MUIDataTable from "mui-datatables";

export default function LogManagement() {
  useEffect(() => {
    window.scroll(0, 0);

    getListLog();
  }, []);

  const [loading, setLoading] = useState(true);
  const [listLogManagement, setListLogManagement] = useState([]);
  const state = useSelector((state) => state);
  const getListLog = async () => {
    setLoading(true);
    const token = state?.auth?.auth?.token;

    const data = await ListinglogOnRole(token);
    if (data && data.statusCode === 200) {
      let temp = [];
      data.data.map((data, i) => {
        let dummy = {
          _id: data?._id,

          name: data?.userId?.name,
          email: data?.userId?.email,
          phoneNumber: data?.userId?.phoneNumber,
          lastLogin: moment(data?.lastLogin).format("DD/MM/YYYY ,h:mm:ss a"),
          createdAt: moment(data?.createdAt).format("DD/MM/YYYY ,h:mm:ss a"),
          URL: data?.URL,
          description: data?.description,
        };
        temp.push(dummy);
        return null;
      });
      setListLogManagement(temp);
      setLoading(false);
    } else {
      setListLogManagement([]);
      setLoading(false);
    }
  };

  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "email",
      label: "E- Mail ID",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "phoneNumber",
      label: "Phone No",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },

    {
      name: "lastLogin",
      label: "last Login",
      options: {
        customBodyRender: (value) => {
          return (
            <div>
              <div>{value}</div>
            </div>
          );
        },
      },
    },
    {
      name: "createdAt",
      label: "Created At",
      options: {
        customBodyRender: (value) => {
          return (
            <div>
              <div>{moment(value?.createdAt).format("DD.MMM.YYYY")}</div>
              <p className="mb-0">
                At {moment(value?.createdAt).format("h:mm:ss a")}
              </p>
            </div>
          );
        },
      },
    },

    {
      name: "description",
      label: "Description",
    },
    {
      name: "URL",
      label: "URL",
    },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    setRowProps: (row) => {
      if (row[5] === false) {
        return {
          style: { background: "#DBDFEA", cursor: "pointer" },
        };
      } else {
        return {
          style: { background: "white", cursor: "pointer" },
        };
      }
    },
  };

  return (
    <div>
      <AdminNavbar />
      <div className="main">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div>
              <div className="">
                <h5 className="mb-0 fw-bold color-maroon">Log Management</h5>
              </div>
              <div className="my-4">
                <MUIDataTable
                  title={"Dalmaf - Log Management"}
                  data={listLogManagement}
                  columns={columns}
                  options={options}
                />
                {/* <table className="table">
              <thead className="bg-historytablehead">
                <tr>
                  <th scope="col">S. No</th>
                  <th scope="col">Name</th>
                  <th scope="col">E- Mail ID </th>
                  <th scope="col">Role </th>
                  <th scope="col">Joining Date</th>
                </tr>
              </thead>

              <tbody className="historytablebody">
           
                {listLogManagement &&
                  listLogManagement.length > 0 &&
                  listLogManagement.map((list, index) => {
                    return (
                      <tr>
                        <td>
                          <div className="">
                            <h6 className="fw-bold"> {index + 1}</h6>
                          </div>
                        </td>
                        <td scope="row">
                          <div className="d-flex align-items-center ">
                            <div className="flex-shrink-0">
                              {list?.userId?.profileImage ? (
                                <img
                                  style={{ width: "60px", height: "60px" }}
                                  src={list?.userId?.profileImage}
                                  alt="..."
                                />
                              ) : (
                                <span className="material-symbols-rounded filled-icon dash-icon">
                                  person
                                </span>
                              )}
                            </div>
                            <div className=" ms-3">
                              <h6 className="fw-bold">{list?.userId?.name}</h6>
                              <p className="mb-0 small">
                                {list?.userId?.phoneNumber}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className=" ">
                            <h6 className="fw-bold"> {list?.userId?.email} </h6>
                          </div>
                        </td>
                        <td>
                          <h6 className="fw-bold">{list?.userId?.role}</h6>
                        </td>
                        <td>
                          <h6 className="fw-bold">
                            {moment(list?.userId?.createdAt).format(
                              "DD.MMM.YYYY"
                            )}
                          </h6>
                          <p className="mb-0">
                            At{" "}
                            {moment(list?.userId?.createdAt).format(
                              "h:mm:ss a"
                            )}
                          </p>
                        </td>
                      </tr>
                    );
                  })}
             


              
              </tbody>
            </table> */}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
