import React from 'react';
import styles from './PropertyListComponent.module.css';

const formatNumber = (number) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0,
    }).format(number);
  };
export default function PropertyListComponent({ properties, viewMore }) {
  return (
    // <div className="container">
      <div className="row">
        {properties.map((property) => (
          <div key={property._id} className={`col-lg-4 col-md-4 col-sm-12 ${styles.propertyItem}`}>
            <div className={styles.propertyDetail}>
              <span className={styles.label}>Property Id:</span>
              <span className={styles.value}>{property.property_id}</span>
            </div>
            <div className={styles.propertyDetail}>
              <span className={styles.label}>Location:</span>
              <span className={styles.value}>{property.location}</span>
            </div>
            <div className={styles.propertyDetail}>
              <span className={styles.label}>Type:</span>
              <span className={styles.value}>{property.property_type}</span>
            </div>
            <div className={styles.propertyDetail}>
              <span className={styles.label}>Area:</span>
              <span className={styles.value}>{property.area}</span>
            </div>
            <div className={styles.propertyDetail}>
              <span className={styles.label}>City:</span>
              <span className={styles.value}>{property.city}</span>
            </div>
            <div className={styles.propertyDetail}>
              <span className={styles.label}>Reserve Price:</span>
              <span className={styles.value}>{formatNumber(property.reserve_price || 0)}</span>
            </div>
            <div className={styles.propertyDetail}>
              <span className={styles.label}>EMD Amount:</span>
              <span className={styles.value}>{formatNumber(property.emd_amount || 0)}</span>
            </div>
            <div className={styles.viewMore}>
              <button onClick={() => viewMore(property._id)} style={{backgroundColor:'#dc3545'}}>View More</button>
            </div>
          </div>
        ))}
      </div>
    // </div>
  );
}
