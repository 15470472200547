import React, { useEffect, useState } from "react";

import Norecord from "../../Assets/Images/MicrosoftTeams-image.webp";
import AOS from "aos";
import "aos/dist/aos.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  EmailValidation,
  NonEmptyValidation,
  PhoneNumberValidation,
  StringValidation,
} from "../../Store/validate";
import {
  GetUser,
  GetWheeler,
  UserServiceRequest,
  UseraboutContent, 
} from "../../Api/Api";
import moment from "moment";
import CustomModal from "../CustomModal";
import ServicesAllpages from "../ServicesAllpages";

export default function BSTwoWheelerContent() {
  useEffect(() => {
    AOS.init();
  }, []);

  const [selectedTab, setSelectedTab] = React.useState("Whats Included");
  const AllTabs = [
    "Whats Included",
    "Whats Not Included",
    "You May Also Want To Know",
  ];

  const state = useSelector((state) => state);

  const navigate = useNavigate();

  const [serviceReq, setServiceReq] = useState({});

  const [serviceReqValidation, setServiceReqValidation] = useState({});

  const setServiceReqValue = (key, value) => {
    setServiceReq({ ...serviceReq, [key]: value });
    if (serviceReqValidation[key]) delete serviceReqValidation[key];
  };

  const setServiceReqValidationValue = (key, value) => {
    setServiceReqValidation({ ...serviceReqValidation, [key]: value });
  };

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 3000,
    });
  };

  const [userProfile, setUserProfile] = useState([]);
  useEffect(() => {
    if (state?.auth?.isLoggedIn === true) {
      getUserProfile();
    }
    getPropertytypefilterList();
  }, []);
  const getUserProfile = async () => {
    const id = state?.auth?.auth?._id;
    const token = state.auth.auth.token;
    const response = await GetUser(token, id);
    if (response.statusCode === 200) {
      setUserProfile(response.data);
    } else {
      setUserProfile([]);
      //console.log("error while getting user profile info");
    }
  };

  const naviGation = useNavigate();

  //User Registration api call

  const handleSubmit = async (e) => {
    let local = localStorage?.getItem("category")?.split("&");
    let local1 = local[0]?.split("/");
    e.preventDefault();

    let validate = {};
    validate.name = StringValidation(serviceReq?.name);
    validate.emailId = EmailValidation(serviceReq?.emailId);
    validate.subject = NonEmptyValidation(serviceReq?.subject);
    validate.question = NonEmptyValidation(serviceReq?.question);
    validate.mobileNumber = PhoneNumberValidation(serviceReq?.mobileNumber);
    validate.servicesType = NonEmptyValidation(serviceReq?.servicesType);

    setServiceReqValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        name: serviceReq.name,
        emailId: serviceReq.emailId,
        subject: serviceReq.subject,
        question: serviceReq.question,
        mobileNumber: serviceReq.mobileNumber,
        userId: state?.auth?.auth?._id,
        servicesType: serviceReq.servicesType,
      };
      const response = await UseraboutContent(Payload);
      if (response && response.statusCode === 200) {
       
        showToastSuccessMsg(response.message);
        window.location.reload();
        serviceReq({});
        setServiceReq({});
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        //setfailureModal(true);
      }
    } else {
      //console.log("Error while submit a service request");
    }
    // }
  };

  const [projectList, setProjectList] = useState([]);
  const [loading, setLoading] = useState(true);

  const getPropertytypefilterList = async () => {
    setLoading(true);
    const token = state?.auth?.auth?.token;

    const response = await GetWheeler(token);
    if (response && response.statusCode === 200) {
      setProjectList(
        response.data.filter(
          (e) => e.wheelerType === "twoWheeler" && e.type === "Buy/Sell"
        )
      );
      setLoading(false);
    } else {
      setProjectList([]);
      setLoading(false);
      showToastErrorMsg(response.message);
    }
  };

  const handleSubmitService = async (e, id) => {
    e.preventDefault();
   
    if (state?.auth?.isLoggedIn) {
      const Payload = {
        name: userProfile.name,
        email: userProfile.email,
        subject: " ",
        question: " ",
        mobileNumber: userProfile.phoneNumber,
        twowheeler_id: id,
        type: "twowheeler",
      
      };
      const response = await UserServiceRequest(Payload);
      if (response && response.statusCode === 200) {
        setServiceReq({});
        showToastSuccessMsg(response.message);
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    }
  };

  const [serviceReq1, setServiceReq1] = useState({});
  const [form, setForm] = useState(false);
  const [vId, setVId] = useState("");

  const [serviceReqValidation1, setServiceReqValidation1] = useState({});

  const setServiceReqValue1 = (key, value) => {
    setServiceReq1({ ...serviceReq1, [key]: value });
    if (serviceReqValidation1[key]) delete serviceReqValidation1[key];
  };

  const setServiceReqValidationValue1 = (key, value) => {
    setServiceReqValidation1({ ...serviceReqValidation1, [key]: value });
  };


  const handleSubmitServiceform = async (e) => {
    e.preventDefault();
    const Payload = {
      name: serviceReq1.name,
      email: serviceReq1.email,
      subject: serviceReq1.subject,
      question: serviceReq1.question,
      mobileNumber: serviceReq1.mobileNumber,
      type: "twowheeler",
      twowheeler_id: vId,
    };
    // const token = state?.auth?.auth?.token
    //console.log(Payload);
    const response = await UserServiceRequest(Payload);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      setServiceReq1({});
      setForm(false);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };

  return (
    <div className="overflow-pages">
      <ToastContainer />
      <div className="about-banner2 py-5">
        <div className="container">
          <section className="property">
            <div className="container">
              <div className="propertycardbottomalign">
                <h1
                  className="blogtitle my-auto"
                  data-aos="zoom-out-right"
                  data-aos-duration="2000"
                >
                  Two Wheeler
                </h1>
              </div>
              <div className="projectrow">
                <div className="row">
                  <div className="col-lg-11 mx-auto ms-auto">
                    <div className="row">
                      {projectList?.length > 0 ? (
                        <>
                          {projectList?.map((e, index) => {
                            return (
                              <div className="col-lg-3 mb-3" key={index}>
                                <div className="admin-service-wheeler">
                                  <div className="small-banner-img-head"></div>
                                  <div className="mt-3">
                                    <h6 className="color-maroon fw-bold">
                                      {/* {e?.wheelerType?.toUpperCase()} */}

                                    </h6>
                                    {/* <p className="small">
                                      <b>Reference No:</b> {e?.referenceNo}
                                    </p>
                                    <p className="small">
                                      <b>Brand Name:</b> {e?.brandName}
                                    </p>

                                    <p className="small">
                                      <b>Model Name :</b> {e?.modelName}
                                    </p>
                                    <p className="small">
                                      <b>model Year :</b> {e?.modelYear}
                                    </p>
                                    <p className="small">
                                      <b>Bidding Price :</b> {e?.bidingPrice}
                                    </p>
                                    <p className="small">
                                      <b>color :</b> {e?.color}
                                    </p>
                                    <p className="small">
                                      <b>End Date :</b>{" "}
                                      {moment(e?.endDate).format("DD/MM/YYYY")}
                                    </p> */}
                                    <table className="table">
                                      <tr>
                                        <td>Reference No</td>
                                        <td><b>{e?.referenceNo?.toUpperCase()}</b></td>
                                      </tr>
                                      <tr>
                                        <td>Brand Name</td>
                                        <td>{e?.brandName}</td>
                                      </tr>
                                      <tr>
                                        <td>Model Name</td>
                                        <td>{e?.modelName}</td>
                                      </tr>
                                      <tr>
                                        <td>Model Year</td>
                                        <td>{e?.modelYear}</td>
                                      </tr>
                                      <tr>
                                        <td>Bidding Price</td>
                                        <td>₹ {e?.bidingPrice}</td>
                                      </tr>
                                      <tr>
                                        <td>Color</td>
                                        <td>{e?.color}</td>
                                      </tr>
                                      <tr>
                                        <td>Year of Make</td>
                                        <td>{e?.modelYear}</td>
                                      </tr>
                                    </table>

                                    {!state?.auth?.isLoggedIn ? (
                                      <button
                                        type="button"
                                        className="btn btn-admin w-100"
                                        onClick={() => {
                                          setVId(e._id);
                                          setForm(true);
                                        }}
                                      >
                                        Show Interest
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="btn btn-admin w-100"
                                        onClick={(val) => {
                                          handleSubmitService(val, e._id);
                                        }}
                                      >
                                        Show Interest
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <img
                          src={Norecord}
                          alt=""
                          style={{
                            width: "200px",
                            height: "200px",
                            marginLeft: "500px",
                          }}
                          className="img-fluid"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="client-left-section py-5">
        <div className="container my-4">
          <h1 className="banner-heading after-line mb-5">FEEL FREE TO ASK</h1>
          <div className="row">
            <div className="col-lg-6" data-aos="fade-left">
              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.823571029211!2d80.23700327559305!3d13.046899587275508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267c25ec3b519%3A0xb10841584ccc73c4!2sDALMAF%20Private%20Limited!5e0!3m2!1sen!2sin!4v1688118977225!5m2!1sen!2sin"
                  width="100%"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            <div className="col-lg-6 col-md-12" data-aos="fade-left">
              
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Name"
                      aria-invalid={Boolean(
                        serviceReqValidation?.name?.status === false
                      )}
                      value={serviceReq?.name || ""}
                      onChange={(e) => {
                        setServiceReqValue("name", e.target.value);
                      }}
                      onBlur={(e) => {
                        setServiceReqValidationValue(
                          "name",
                          StringValidation(e.target.value)
                        );
                      }}
                    />
                    <small className="text-danger">
                      {serviceReqValidation?.name?.message
                        ? `Name ${serviceReqValidation?.name?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      Mobile No <span className="text-danger">*</span>
                    </label>
                    <input
                      type="tel"
                      maxLength={10}
                      className="form-control"
                      placeholder="Enter Your Mobile No"
                      aria-invalid={Boolean(
                        serviceReqValidation?.mobileNumber?.status === false
                      )}
                      value={serviceReq?.mobileNumber || ""}
                      onChange={(e) => {
                        setServiceReqValue("mobileNumber", e.target.value);
                      }}
                      onBlur={(e) => {
                        setServiceReqValidationValue(
                          "mobileNumber",
                          PhoneNumberValidation(e.target.value)
                        );
                      }}
                    />
                    <small className="text-danger">
                      {serviceReqValidation?.mobileNumber?.message
                        ? `Mobile no ${serviceReqValidation?.mobileNumber?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      E- Mail ID <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Your E- Mail ID"
                      aria-invalid={Boolean(
                        serviceReqValidation?.emailId?.status === false
                      )}
                      value={serviceReq?.emailId?.toLowerCase() || ""}
                      onChange={(e) => {
                        setServiceReqValue("emailId", e.target.value);
                      }}
                      onBlur={(e) => {
                        setServiceReqValidationValue(
                          "emailId",
                          EmailValidation(e.target.value)
                        );
                      }}
                    />
                    <small className="text-danger">
                      {serviceReqValidation?.emailId?.message
                        ? `Email ${serviceReqValidation?.emailId?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      Questions <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Questions "
                      aria-invalid={Boolean(
                        serviceReqValidation?.question?.status === false
                      )}
                      onChange={(e) => {
                        setServiceReqValue("question", e.target.value);
                      }}
                      value={serviceReq?.question || ""}
                      onBlur={(e) => {
                        setServiceReqValidationValue(
                          "question",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                    />
                    <small className="text-danger">
                      {serviceReqValidation?.question?.message
                        ? `Question ${serviceReqValidation?.question?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-4">
                    <label className="form-label">
                      Service Type
                      {/* <span className="text-danger">*</span> */}
                    </label>
                    <select
                      className="form-control form-control-lg"
                      value={serviceReq?.servicesType || ""}
                      onChange={(e) => {
                        setServiceReqValue("servicesType", e.target.value);
                      }}
                      onBlur={(e) => {
                        setServiceReqValidationValue(
                          "servicesType",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                    >
                      <option value="">---Select Service Type---</option>
                      <option value="Legal">Legal</option>
                      <option value="Audit">Audit</option>
                      <option value="Manpower">Manpower</option>
                      <option value="Auction">Auction</option>
                      <option value="Buy/Sell">Buy/Sell</option>
                    </select>
                   
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="mb-4">
                    <label className="form-label">
                      Subject <span className="text-danger">*</span>
                    </label>
                    <textarea
                      rows="6"
                      className="form-control"
                      placeholder="Enter Subject"
                      aria-invalid={Boolean(
                        serviceReqValidation?.subject?.status === false
                      )}
                      value={serviceReq?.subject || ""}
                      onChange={(e) => {
                        setServiceReqValue("subject", e.target.value);
                      }}
                      onBlur={(e) => {
                        setServiceReqValidationValue(
                          "subject",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                    ></textarea>
                    <small className="text-danger">
                      {serviceReqValidation?.subject?.message
                        ? `Subject ${serviceReqValidation?.subject?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-12">
                  

                  <button
                    type="button"
                    className="btn login-btn w-100"
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
      <div className="number-counter py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6" data-aos="fade-in">
              <div className="counter-content text-start">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <h1 className="mb-0">
                      <span className="material-symbols-rounded align-middle about-call filled-icon">
                        call
                      </span>
                    </h1>
                  </div>
                  <div className="">
                    <h5 className="fs-6 mb-0">Toll Free</h5>
                    <h5 className="mb-0 fs-4">1800-309-8445</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="fade-in">
              <div className="counter-content text-start">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <h1 className="mb-0">
                      <span className="material-symbols-rounded align-middle about-call filled-icon">
                        mail
                      </span>
                    </h1>
                  </div>
                  <div className="">
                    <h5 className="fs-6 mb-0">Email ID</h5>
                    <h5 className="mb-0 fs-4">info@dalmaf.com</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="fade-in">
              <div className="counter-content text-start">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <h1 className="mb-0">
                      <i className="fa-brands fa-whatsapp about-whatsapp-icon"></i>
                    </h1>
                  </div>
                  <div className="">
                    <h5 className="fs-6 mb-0">WhatsApp Us</h5>
                    <h5 className="mb-0 fs-4">+91 96000 38059</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ServicesAllpages />
      </div>
      <CustomModal
        open={form}
        onClickOutside={() => {
          setForm(false);
        }}
      >
        <div className="modal-content w-50 m-auto">
          <div className="modal-body p-0 ">
            <div className="container ">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{
                  marginRight: "30px",
                  marginTop: "50px",
                  textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                }}
                onClick={() => {
                  setForm(false);
                }}
              >
                close
              </span>
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className=" my-5 px-3">
                        <div className="row justify-content-center mt-2">
                          <div className="mb-2">
                            <b>
                              Please enter your information below, <br />
                              Our staff will contact you as soon as possible.
                            </b>
                          </div>
                          <hr />
                          <div className="col-lg-6 col-md-6">
                            <div className="mb-4">
                              <label className="form-label">
                                Name <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Your Name"
                                aria-invalid={Boolean(
                                  serviceReqValidation1?.name?.status === false
                                )}
                                defaultValue={serviceReq1?.name}
                                onChange={(e) => {
                                  setServiceReqValue1("name", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setServiceReqValidationValue1(
                                    "name",
                                    StringValidation(e.target.value)
                                  );
                                }}
                              />
                              <small className="text-danger">
                                {serviceReqValidation1?.name?.message
                                  ? `Name ${serviceReqValidation1?.name?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="mb-4">
                              <label className="form-label">
                                Mobile No <span className="text-danger">*</span>
                              </label>
                              <input
                                type="tel"
                                maxLength={10}
                                className="form-control"
                                defaultValue={serviceReq1?.mobileNumber}
                                placeholder="Enter Your Mobile No"
                                aria-invalid={Boolean(
                                  serviceReqValidation1?.mobileNumber
                                    ?.status === false
                                )}
                                onChange={(e) => {
                                  setServiceReqValue1(
                                    "mobileNumber",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setServiceReqValidationValue1(
                                    "mobileNumber",
                                    PhoneNumberValidation(e.target.value)
                                  );
                                }}
                              />
                              <small className="text-danger">
                                {serviceReqValidation1?.mobileNumber?.message
                                  ? `Mobile no ${serviceReqValidation1?.mobileNumber?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="mb-4">
                              <label className="form-label">
                                E- Mail ID{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Enter Your E- Mail ID"
                                aria-invalid={Boolean(
                                  serviceReqValidation1?.email?.status === false
                                )}
                                defaultValue={
                                  serviceReq1?.email?.toLowerCase() || ""
                                }
                                onChange={(e) => {
                                  setServiceReqValue1(
                                    "email",
                                    e.target.value?.toLowerCase()
                                  );
                                }}
                                onBlur={(e) => {
                                  setServiceReqValidationValue1(
                                    "emailId",
                                    EmailValidation(
                                      e.target.value
                                    )?.toLowerCase()
                                  );
                                }}
                              />
                              <small className="text-danger">
                                {serviceReqValidation1?.email?.message
                                  ? `Email ${serviceReqValidation1?.email?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="mb-4">
                              <label className="form-label">Questions</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Your Questions "
                                aria-invalid={Boolean(
                                  serviceReqValidation1?.question?.status ===
                                    false
                                )}
                                onChange={(e) => {
                                  setServiceReqValue1(
                                    "question",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">
                                Service Type{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <select
                                className="form-control form-control-lg"
                                value={serviceReq1?.servicesType}
                                onChange={(e) => {
                                  setServiceReqValue1(
                                    "servicesType",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setServiceReqValidationValue1(
                                    "servicesType",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                              >
                                <option value="">
                                  ---Select Service Type---
                                </option>
                                <option value="Legal">Legal</option>
                                <option value="Audit">Audit</option>
                                <option value="Manpower">Manpower</option>
                                <option value="Auction">Auction</option>
                                <option value="Buy/Sell">Buy/Sell</option>
                              </select>
                              <small className="text-danger">
                                {serviceReqValidation1?.servicesType?.message
                                  ? `Service Type ${serviceReqValidation1?.servicesType?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">Subject</label>
                              <textarea
                                rows="6"
                                className="form-control"
                                placeholder="Enter Subject"
                                aria-invalid={Boolean(
                                  serviceReqValidation1?.subject?.status ===
                                    false
                                )}
                                onChange={(e) => {
                                  setServiceReqValue1(
                                    "subject",
                                    e.target.value
                                  );
                                }}
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <button
                              type="button"
                              className="btn login-btn w-100"
                              onClick={(e) => {
                                handleSubmitServiceform(e);
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}
