import React, { useEffect, useRef, useState } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import {
  GetUser,
  UserCreateMyTask,
  UpdateMyTaskRequest,
  GetServicemyRole,
  GetAllSubAdminAndAdmin,
  UploadDocument,
  Adminspecificlist,
  AdminspecificlistScroll,
  AdminspecificlistCreator,
} from "../../../Api/Api";
import { useSelector } from "react-redux";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  DateValidationn,
  NonEmptyValidation,
  NonEmptyValidationForDate,
} from "../../../Store/validate";
import { ExpireDateValidation } from "../../../Store/validate";
import CustomModal from "../../../Components/CustomModal";
import Spinner from "../../../Spinner/Spinner";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";

export default function MyTask() {
  
  const [selectedTab, setSelectedTab] = React.useState("All");
  const AllTabs = [
    "All",
    "To Do",
    "In Progress",
    "Hold",
    "Completed",
    "Over Due",
    "Assigned By Me",
  ];
  const [newTask, setNewTask] = useState(true);

  const [specificTaskModal, setSpecificTaskModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [serviceReq, setServiceReq] = useState([]);

  const [getMyTask, setGetMyTask] = useState([]);

  const state = useSelector((state) => state);

  const GetMyTsks = async (status) => {
    setLoading(true);
    const token = state.auth.auth.token;
    const id = state.auth.auth._id;
    if (status === "All") {
      let data = await AdminspecificlistScroll(token, id);
      if (data && data.statusCode === 200) {
        let temp = [];

        if(status!="Overdue"){
          data.data.map((data, i) => {
            let dummy = {
              _id: data?._id,
              taskType: data?.taskType,
              assignedTo: data?.assignedTo?.name,
              creator_id: data?.creator_id?.name,
              startDate: moment(data?.startDate).format("DD/MM/YYYY"),
              status: data?.status,
              admin_read: data?.admin_read,
              subadmin_read: data?.subadmin_read,
              overdue: data?.overdue,
              deadlineDate: moment(data?.deadlineDate).format("DD/MM/YYYY"),
            };
            temp.push(dummy);
          }
          );
          
        }else{
          data.data.map((data, i) => {
            let dummy = {
              _id: data?._id,
              taskType: data?.taskType,
              assignedTo: data?.assignedDetails,
              creator_id: data?.creatorDetails,
              startDate: moment(data?.startDate).format("DD/MM/YYYY"),
              status: data?.status,
              admin_read: data?.admin_read,
              subadmin_read: data?.subadmin_read,
              overdue: data?.overdue,
              deadlineDate: moment(data?.deadlineDate).format("DD/MM/YYYY"),
            };
            temp.push(dummy);
          }
          );
        }
        // data.data.map((data, i) => {
        //   let dummy = {
        //     _id: data?._id,
        //     taskType: data?.taskType,
        //     assignedTo: data?.assignedTo?.name,
        //     creator_id: data?.creator_id?.name,
        //     startDate: moment(data?.startDate).format("DD/MM/YYYY"),
        //     status: data?.status,
        //     admin_read: data?.admin_read,
        //     subadmin_read: data?.subadmin_read,
        //     overdue: data?.overdue,
        //     deadlineDate: moment(data?.deadlineDate).format("DD/MM/YYYY"),
        //   };
        //   temp.push(dummy);
        //   return null;
        // });
        setGetMyTask(temp);
        setLoading(false);
      } else {
        setGetMyTask([]);
        setLoading(false);
        //console.log("Error while getting my task");
      }
    } else if (status === "Assigned By Me") {
      let data = await AdminspecificlistCreator(token, id);
      if (data && data.statusCode === 200) {
        let temp = [];
        data.data.map((data, i) => {
          let dummy = {
            _id: data?._id,
            taskType: data?.taskType,
            assignedTo: data?.assignedTo?.name,
            creator_id: data?.creator_id?.name,
            startDate: moment(data?.startDate).format("DD/MM/YYYY"),
            status: data?.status,
            admin_read: data?.admin_read,
            subadmin_read: data?.subadmin_read,
            overdue: data?.overdue,
            deadlineDate: moment(data?.deadlineDate).format("DD/MM/YYYY"),
          };
          temp.push(dummy);
          return null;
        });
        setGetMyTask(temp);
        setLoading(false);
      } else {
        setGetMyTask([]);
        setLoading(false);
        //console.log("Error while getting my task");
      }
    } else {
      let data = await Adminspecificlist(token, id, status);
      if (data && data.statusCode === 200) {
        let temp = [];
        data.data.map((data, i) => {
          let dummy = {
            _id: data?._id,
            taskType: data?.taskType,
            assignedTo: data?.assignedTo?.name,
            creator_id: data?.creator_id?.name,
            startDate: moment(data?.startDate).format("DD/MM/YYYY"),
            status: data?.status,
            admin_read: data?.admin_read,
            subadmin_read: data?.subadmin_read,
            overdue: data?.overdue,
            deadlineDate: moment(data?.deadlineDate).format("DD/MM/YYYY"),
          };
          temp.push(dummy);
          return null;
        });
        setGetMyTask(temp);
        setLoading(false);
      } else {
        setGetMyTask([]);
        setLoading(false);
        //console.log("Error while getting my task");
      }
    }
  };
  const [specificTask, setSpecificTask] = useState([]);

  const [profileMytaskDetails, setProfileMytaskDetails] = useState([]);
  async function fetchDataProfile() {
    setLoading(true);
    const id = state?.auth?.auth?._id;
    const token = state?.auth?.auth?.token;
    const GetMytaskDetails = await GetUser(token, id);
    if (GetMytaskDetails && GetMytaskDetails?.statusCode === 200) {
      setProfileMytaskDetails(GetMytaskDetails.data);
      setLoading(false);
    } else {
      setProfileMytaskDetails([]);
      setLoading(false);
      //console.log("error while getting user profile info");
    }
  }

  const [allMytaskSubmin, setAllMytaskSubmin] = useState([]);
  const GetAllSubadmins = async () => {
    setLoading(true);
    // const role = state?.auth?.auth?.role;
    const token = state?.auth?.auth?.token;
    let data = await GetAllSubAdminAndAdmin(token);
    if (data && data.statusCode === 200) {
      setAllMytaskSubmin(data.data);
      setLoading(false);
    } else {
      setAllMytaskSubmin([]);
      setLoading(false);
      //console.log("Error while getting all subadmins");
    }
  };

  let assignName = allMytaskSubmin?.filter((e) => {
    return e._id === specificTask?.assignedTo;
  });

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleEditorChange = (state) => {
    setEditorState(state);
    let isNotEmpty = state && state.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(convertToRaw(state.getCurrentContent()));
      setMyTaskValue("taskDescription", html);
    }
  };

  useEffect(() => {
    if (state?.auth?.auth?.role === "superadmin") {
      getMytaskServiceReq("assigned");
    } else {
      getMytaskServiceReq();
    }

    GetAllSubadmins();
  }, []);

  const getMytaskServiceReq = async (status) => {
    setLoading(true);
    const role = state?.auth?.auth?.role;
    const token = state.auth.auth.token;
    let response;
    if (state?.auth?.auth?.role === "superadmin") {
      response = await GetServicemyRole(token, role, status);
    } else {
      response = await GetServicemyRole(token, role);
    }

    if (response.statusCode === 200) {
      setServiceReq(response.data);
      setLoading(false);
    } else {
      setServiceReq([]);
      setLoading(false);
      //console.log("error while getting user profileMytaskDetails info");
    }
  };

  useEffect(() => {
    GetMyTsks("All");
    GetAllSubadmins();
    fetchDataProfile();
  }, []);

  const CeoRef = useRef();
  const [myTask, setMyTask] = useState({});
  const [myTaskValidation, setMyTaskValidation] = useState({});

  const setMyTaskValue = (key, value) => {
    setMyTask({ ...myTask, [key]: value });
    if (myTaskValidation[key]) delete myTaskValidation[key];
  };

  const setMyTaskValidationValue = (key, value) => {
    setMyTaskValidation({ ...myTaskValidation, [key]: value });
  };

  const navigate = useNavigate();

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  var todayDate = new Date();
  var month = todayDate.getMonth();
  var year = todayDate.getUTCFullYear() - 0;
  var tdate = todayDate.getDate();
  if (month < 10) {
    month = "0" + month;
  }
  if (tdate < 10) {
    tdate = "0" + tdate;
  }
  var maxDate = year + "-" + month + "-" + tdate;
  document?.getElementById("startDate")?.setAttribute("min", maxDate);

  const uploadCeoPic = async (e) => {
    const file = e.target.files[0];
    if (file) {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("fileName", e.target.files[0].name);
      formData.append("path", "Image/");

      let response = await UploadDocument(formData);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        setMyTaskValue("fileUpload", response.data);
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.taskType = NonEmptyValidation(myTask?.taskType);
    validate.taskName = NonEmptyValidation(myTask?.taskType);
    validate.taskDescription = NonEmptyValidation(myTask?.taskDescription);
    validate.startDate = NonEmptyValidationForDate(myTask?.startDate);
    validate.deadlineDate = ExpireDateValidation(
      myTask?.startDate,
      myTask?.deadlineDate
    );
    validate.assignedTo = NonEmptyValidation(myTask?.assignedTo);

    setMyTaskValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        taskType: myTask.taskType,
        taskName: myTask.taskName,
        taskDescription: myTask.taskDescription,
        startDate: myTask.startDate,
        deadlineDate: myTask.deadlineDate,
        assignedTo: myTask.assignedTo,
        fileUpload: myTask.fileUpload,
        creator_id: state?.auth?.auth?._id,
      };
      const token = state?.auth?.auth?.token;
      const response = await UserCreateMyTask(Payload, token);
      if (response && response.statusCode === 200) {
        setNewTask(true);
        GetMyTsks("Todo");
        showToastSuccessMsg(response.message);
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } else {
      //console.log("Error while update user details");
    }
  };

  const handleSubmitUpdate = async (e, userId) => {
    e.preventDefault();
    let Payload;
    if (state?.auth?.auth?.role === "subadmin") {
      Payload = {
        _id: userId,
        subadmin_read: true,
        // admin_read:"",
      };
    } else {
      Payload = {
        _id: userId,
        // subadmin_read:"",
        admin_read: true,
      };
    }

    const token = state.auth.auth.token;

    const response = await UpdateMyTaskRequest(Payload, token);
    if (response && response.statusCode === 200) {
      // showToastSuccessMsg(response.message);
      GetMyTsks("Overdue");
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };

  const handleSubmitUpdateStatus = async (e, value, id, value1) => {
    e.preventDefault();
    const Payload = {
      _id: id,
      status: value,
    };
    const token = state?.auth?.auth?.token;

    const response = await UpdateMyTaskRequest(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      GetMyTsks(value1);
      // if (value1 === "Inprogress") {
      //   setSelectedTab("In Progress");
      //   GetMyTsks("Inprogress");
      // } else if(value1 === "Completed"){
      //   setSelectedTab("Completed");
      //   GetMyTsks("Completed");
      // }else if(value1 === "Overdue"){
      //   setSelectedTab("Over Due");
      //   GetMyTsks("Overdue");
      // }
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setfailureModal(true);
    }
  };

  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "taskType",
      label: "Task Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <div
                style={{ cursor: "pointer" }}
                onClick={(val) => {
                  console.log(tableMeta);
                  if (state?.auth?.auth?.role === "superadmin") {
                    if (tableMeta.rowData[7] === false) {
                      handleSubmitUpdate(val, tableMeta.rowData[0]);
                    }
                  }
                  if (tableMeta.rowData[8] === false) {
                    handleSubmitUpdate(val, tableMeta.rowData[0]);
                  }
                  // navigate(`/MyTaskEdit/${tableMeta.rowData[0]}`);
                }}
              >
                {value}
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "assignedTo",
      label: "Assigned To",
      options: {
        customBodyRender: (value) => {
          return (
            <div>{value}</div>
            // <div>
            // {allMytaskSubmin
            // ?.filter((e) => e._id === value)
            // .map((i) => (
            //   <div>{i.name}</div>
            // ))}</div>
          );
        },
      },
    },
    {
      name: "creator_id",
      label: "Assigned By",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
          // <div>
          // {allMytaskSubmin
          // ?.filter((e) => e._id === value)
          // .map((i) => (
          //   <div>{i.name}</div>
          // ))}</div>)
        },
      },
    },
    {
      name: "startDate",
      label: "Start Date",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "deadlineDate",
      label: "Deadline",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>{value}</div>
            // <select
            //   className="form-control form-control-lg"
            //   defaultValue={value}
            //   onChange={(e) => {
            //     handleSubmitUpdateStatus(
            //       e,
            //       e.target.value,
            //       tableMeta.rowData[0],
            //       value
            //     );
            //   }}
            // >
            //   <option value="Overdue">Over Due</option>
            //   <option value="ToDo">To Do</option>
            //   <option value="Inprogress">In Progress</option>
            //   <option value="Completed">Completed</option>
            // </select>
          );
        },
      },
    },
    {
      name: "admin_read",
      label: "Status",
      options: {
        display: false,
      },
    },
    {
      name: "subadmin_read",
      label: "Status",
      options: {
        display: false,
      },
    },
    {
      name: "overdue",
      label: "Overdue",
      options: {
        display: false,
      },
    },
  ];

  const handleRowClick = (rowData, rowMeta) => {
    const id = getMyTask[rowMeta?.dataIndex]?._id;
    if (state?.auth?.auth?.role === "superadmin") {
      if (getMyTask[rowMeta?.dataIndex]?.admin_read === false) {
        handleSubmitUpdate(null, id);
      }
    }
    if (getMyTask[rowMeta?.dataIndex]?.subadmin_read === false) {
      handleSubmitUpdate(null, id);
    }
    navigate(`/MyTaskEdit/${id}`);
  };

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    onRowClick: handleRowClick,
    setRowProps: (row) => {
      if (state?.auth?.auth?.role==="superadmin" && row[7] === false) {
        return {
          style: { background: "#DBDFEA", cursor: "pointer" },
        };
      }
      else if(state?.auth?.auth?.role==="subadmin" && row[8]===false){
        return {
          style: { background: "#DBDFEA", cursor:"pointer" },
        };
      }
      else {
        return {
          style: { background: "white", cursor: "pointer" },
        };
      }
    },
  };
  return (
    <div>
      <ToastContainer />
      <AdminNavbar />
      <div className="main">
        {loading ? (
          <Spinner />
        ) : (
          <>
            {newTask ? (
              <div>
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="mb-0 fw-bold color-maroon">My Task</h5>
                  <div>
                    <button
                      type="button"
                      className="btn py-1 login-btn"
                      onClick={() => {
                        setNewTask(false);
                      }}
                    >
                      Create Task
                    </button>
                  </div>
                </div>
                <div className="my-4">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 px-0">
                        <div className="tab-section">
                          <ul className="ps-0">
                            {AllTabs.map((tab, index) => {
                              return (
                                <div
                                  key={index}
                                  className={
                                    selectedTab === tab
                                      ? "serviceTab active"
                                      : "serviceTab inactive"
                                  }
                                  onClick={() => {
                                    if (tab === "All") {
                                      GetMyTsks("All");
                                    } else if (tab === "Completed") {
                                      GetMyTsks("Completed");
                                    } else if (tab === "In Progress") {
                                      GetMyTsks("Inprogress");
                                    } else if (tab === "Hold") {
                                      GetMyTsks("Hold");
                                    } else if (tab === "Over Due") {
                                      GetMyTsks("Overdue");
                                    } else if (tab === "To Do") {
                                      GetMyTsks("Todo");
                                    } else if (tab === "Assigned By Me") {
                                      GetMyTsks("Assigned By Me");
                                    }
                                    // else if (tab === "Hold") {
                                    //   GetMyTsks("Hold");
                                    // }

                                    setSelectedTab(tab);
                                  }}
                                >
                                  <li>
                                    <span className="align-middle">{tab}</span>
                                  </li>
                                </div>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <MUIDataTable
                    title={"Dalmaf - My Task"}
                    data={getMyTask}
                    columns={columns}
                    options={options}
                  />
                  {/* <table className="table">
                    <thead className="bg-historytablehead">
                      <tr>
                        <th scope="col" style={{ borderTopLeftRadius: "0" }}>
                          S. No
                        </th>
                        <th scope="col">Name</th>
                        <th scope="col">Task Name</th>
                        <th scope="col">Start Date</th>
                        <th scope="col">Deadline</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>

                    {getMyTask?.map((e, index) => (
                      <tbody className="historytablebody">
                        <tr
                          style={{
                            cursor: "pointer",
                            backgroundColor:
                              e.admin_read || e.subadmin_read ? "" : "#DBDFEA",
                          }}
                          onClick={(val) => {
                            GetMySpecificTsks(e._id);
                            if (!e.admin_read || !e.subadmin_read) {
                              handleSubmitUpdate(val, e._id);
                            }
                            setSpecificTaskModal(true);
                          }}
                        >
                          <td>
                            <div className="">
                              <h6 className="fw-bold">{index + 1}</h6>
                            </div>
                          </td>
                          <td scope="row">
                            <div className="d-flex align-items-center ">
                              <div className="flex-shrink-0">
                                <img
                                  // className="w-100 h-auto"
                                  style={{ width: "50px", height: "50px" }}
                                  src={
                                    profileMytaskDetails?.profileImage
                                      ? profileMytaskDetails?.profileImage
                                      : ADclient
                                  }
                                  alt="..."
                                />
                              </div>
                              
                              <div className=" ms-3">
                                <h6 className="fw-bold">{profileMytaskDetails?.name}</h6>
                                <p className="mb-0 small">
                                  {profileMytaskDetails?.phoneNumber}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className=" ">
                              <h6 className="fw-bold">{e.taskType}</h6>
                            </div>
                          </td>
                          <td>
                            <h6 className="fw-bold">
                              {moment(e.startDate).format("DD/MM/YYYY")}
                            </h6>
                          </td>
                          <td>
                            <h6 className="fw-bold">
                              {moment(e.deadlineDate).format("DD/MM/YYYY")}
                            </h6>
                          </td>
                          <td>
                            <h6 className="fw-bold color-red">{e.status}</h6>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table> */}
                </div>
              </div>
            ) : (
              <div>
                <div className="">
                  <button
                    className="btn login-btn ms-2"
                    onClick={() => {
                      setNewTask(true);
                      GetMyTsks("Overdue");
                    }}
                  >
                    Back
                  </button>
                </div>
                <div className=" my-4">
                  <div className="bg-historytablehead rounded-3 py-3 px-3">
                    <h6 className="mb-0">New Task</h6>
                  </div>
                  <div className="container">
                    <div className="row justify-content-center py-5">
                      <div className="col-lg-11">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">Task Name</label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter the Task Name"
                                aria-invalid={Boolean(
                                  myTaskValidation?.taskType?.status === false
                                )}
                                value={myTask?.taskType}
                                onChange={(e) => {
                                  setMyTaskValue("taskType", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setMyTaskValidationValue(
                                    "taskType",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {myTaskValidation?.taskType?.message
                                  ? `Task name ${myTaskValidation?.taskType?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label for="services" className="form-label">
                                Service Type
                              </label>
                              <select
                                className="form-control"
                                onChange={(e) => {
                                  setMyTaskValue("taskName", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setMyTaskValidationValue(
                                    "taskName",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                              >
                                <option value="">---Select---</option>
                                <option value="audit">Audit</option>
                                <option value="legal">Legal</option>
                                <option value="manPower">Man Power</option>
                                <option value="auction">Auction</option>
                                <option value="buySell">Buy/Sell</option>
                                <option value="In House">In House</option>
                              </select>
                              <small className="text-danger">
                                {myTaskValidation?.taskName?.message
                                  ? `Service type ${myTaskValidation?.taskName?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">
                                Task Description
                              </label>
                              <div
                                className="form-control form-control-lg"
                                style={{ height: "250px" }}
                              >
                                <Editor
                                  wrapperClassName="wrapper-class"
                                  editorClassName="editor-class"
                                  toolbarClassName="toolbar-class"
                                  editorState={editorState}
                                  onEditorStateChange={(e) =>
                                    handleEditorChange(e)
                                  }
                                />
                              </div>
                              {/* <textarea
                                rows={6}
                                className="form-control form-control-lg"
                                aria-invalid={Boolean(
                                  myTaskValidation?.taskDescription?.status ===
                                    false
                                )}
                                value={myTask?.taskDescription}
                                onChange={(e) => {
                                  setMyTaskValue(
                                    "taskDescription",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setMyTaskValidationValue(
                                    "taskDescription",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              /> */}
                              <small className="text-danger">
                                {myTaskValidation?.taskDescription?.message
                                  ? `Task description ${myTaskValidation?.taskDescription?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label">Start Date</label>
                              <input
                                type="date"
                                id="startDate"
                                // min={maxDate}
                                className="form-control form-control-lg"
                                placeholder="select the  start date"
                                aria-invalid={Boolean(
                                  myTaskValidation?.startDate?.status === false
                                )}
                                value={myTask?.startDate}
                                onChange={(e) => {
                                  setMyTaskValue("startDate", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setMyTaskValidationValue(
                                    "startDate",
                                    DateValidationn(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {myTaskValidation?.startDate?.message
                                  ? `Start date ${myTaskValidation?.startDate?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label">
                                Deadline Date
                              </label>
                              <input
                                type="date"
                                className="form-control form-control-lg"
                                placeholder="select the Deadline Date"
                                aria-invalid={Boolean(
                                  myTaskValidation?.deadlineDate?.status ===
                                    false
                                )}
                                value={myTask?.deadlineDate}
                                min={myTask?.startDate}
                                onChange={(e) => {
                                  setMyTaskValue(
                                    "deadlineDate",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setMyTaskValidationValue(
                                    "deadlineDate",
                                    ExpireDateValidation(
                                      myTask?.startDate,
                                      e.target.value
                                    )
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {myTaskValidation?.deadlineDate?.message
                                  ? `Deadline date ${myTaskValidation?.deadlineDate?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label ">
                                Team Member Name
                              </label>

                              <select
                                className="form-control form-control-lg"
                                onChange={(e) => {
                                  setMyTaskValue("assignedTo", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setMyTaskValidationValue(
                                    "assignedTo",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                              >
                                <option value="selectmembername">
                                  ---Select Member Name---
                                </option>
                                {allMytaskSubmin?.map((e) => (
                                  <option value={e._id}>{e.name}</option>
                                ))}
                              </select>
                              <small className="text-danger">
                                {myTaskValidation?.assignedTo?.message
                                  ? `Member name ${myTaskValidation?.assignedTo?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-6 my">
                            <div className="mb-3">
                              <label
                                htmlFor="fileUpload"
                                className="form-label"
                              >
                                Select File
                              </label>
                              <input
                                type="file"
                                className="form-control"
                                id="file"
                                ref={CeoRef}
                                onChange={(e) => {
                                  uploadCeoPic(e);
                                }}
                              />
                              {/* <button
                              className="btn btn-primary mt-2"
                              onClick={() => CeoRef.current()}
                            >
                              Upload
                            </button> */}
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <button
                              type="button"
                              className="btn white-btn me-2 "
                              onClick={() => {
                                setMyTask({});
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="btn login-btn ms-2 "
                              onClick={(e) => {
                                handleSubmit(e);
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <CustomModal
        open={specificTaskModal}
        onClickOutside={() => {
          setSpecificTaskModal(false);
        }}
      >
        <div className="modal-content w-50 m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{ textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px" }}
                onClick={() => {
                  setSpecificTaskModal(false);
                }}
              >
                close
              </span>
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className=" my-5 px-3">
                        <div className="row justify-content-center mt-2">
                          <div className="col-lg-12">
                            <div className="admin-service">
                              <div
                                className="small-banner-img-head"
                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  className=""
                                  style={{ width: "525px", height: "200px" }}
                                  src={
                                    profileMytaskDetails?.profileImage
                                      ? profileMytaskDetails?.profileImage
                                      : ""
                                  }
                                  hidden={!profileMytaskDetails?.profileImage}
                                  alt=""
                                />
                                {/* <p className="mb-0 cost-pack">₹{e.price}</p> */}
                              </div>
                              <div className="mt-3">
                                <h5 className="color-maroon fw-bold">
                                  {specificTask?.taskType}
                                </h5>
                                <p className="small">
                                  <h6 className="color-maroon fw-bold">
                                    Description:
                                  </h6>
                                  {specificTask?.taskDescription}
                                </p>

                                {assignName?.map((i) => (
                                  <p className="small">
                                    <h6 className="color-maroon fw-bold">
                                      Assigned To:
                                    </h6>{" "}
                                    {i.name}
                                  </p>
                                ))}

                                <p className="small">
                                  <h6 className="color-maroon fw-bold">
                                    Status:
                                  </h6>{" "}
                                  {specificTask?.status}
                                </p>
                                <p className="small">
                                  <h6 className="color-maroon fw-bold">
                                    Valid:
                                  </h6>{" "}
                                  {moment(specificTask?.startDate).format(
                                    "DD/MM/YYYY"
                                  )}{" "}
                                  -{" "}
                                  {moment(specificTask?.deadlineDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                </p>
                                <p className="small">
                                  {/* <h6 className="color-maroon fw-bold">Service Title:</h6> {e.serviceTitle} */}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}
