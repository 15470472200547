import React, {  useEffect } from 'react';
import Footer from "../Components/Footer/Footer";
import Navigation from "../Components/Navbar/Navigation";
import VillaBanner from "../Components/Property/VillaBanner";
import VillaContent from "../Components/Property/VillaContent";
import {Helmet} from "react-helmet";
export default function Villa() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);


  return (
    <div className="innerpages-nav">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Villa Details</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="" />
        <meta name="keywords" content="Villa Details" />
        <meta name="author" content="DALMaf" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>
    
        <div id="container">
      <Navigation />
      <VillaBanner />
      <VillaContent />
      <Footer />
      </div>
    </div>
  );
}
