import axios from "axios";
export const EmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PasswordRegex = /^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[.,:;\'!@#$%^&*_+=|(){}[?\-\]\/\\]).*$/;
export function GetInitials(name) {
  if (!name) {
    return "";
  }
  name = name.trim();
  if (name && /\s/.test(name)) {
    return name
      .split(/\s/)
      .map((part) => part.substring(0, 1).toUpperCase())
      .filter((v) => !!v)
      .slice(0, 2)
      .join("")
      .toUpperCase();
  } else if (name) {
    return name.substring(0, 2).toUpperCase();
  } else {
    return "";
  }
}
export const DownloadFiles = async (fileUrl, fileName) => {
  await axios({
    url: fileUrl,
    method: "GET",
    responseType: "blob", // Important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob(response.data));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  });
};
