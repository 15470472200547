import React, { useEffect, useRef } from "react";

export default function CustomerLogo() {
  const logos = [
    "https://alce.co.in/static/media/image.fc1d7a51bf59b88d4eab.png",
    "https://dev.prosperoustech.com/wp-content/uploads/2022/03/logo-1.png",
    "https://auroraacademy.co.in/assert/images/logo.webp",
    "https://daco.co.in/images/logo.png",
    "https://quantzi.co/images/quantzi-logo.webp",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdxpB8Ee8QPYapjx0JDy6lPXfxU_-5zK52qHxqvGoeHgRRZh-MmaPkn0hPTDtmtqJMzD8&usqp=CAU",
    "https://www.bluestonefoundation.in/images/logo.jpg",
    "https://5.imimg.com/data5/RG/PF/KW/SELLER-4109314/untitled-1-90x90.jpg",
    "https://speedy.uenicdn.com/d472f065-8cdd-4e1c-83e2-26d69cee6fef/n128_128a/image/upload/v1572447204/business/d472f065-8cdd-4e1c-83e2-26d69cee6fef/JKSFOODS-LOGOjpg.jpg",
    "https://www.bharathuniv.ac.in/images/bharath-logo.png",
    "img/client-logo/Datayaan.png",
    "img/client-logo/cms-w-lg.svg",
    "img/client-logo/Perfect-Computers-logo.png",
    "img/client-logo/SR-Residency-Logo.png",
    "img/client-logo/Udhayam-Printers-logo.png",
    "img/client-logo/logotdy.png",
    "img/client-logo/tina-logo.jpeg",
    "img/client-logo/ramco-logo.webp",
    "img/client-logo/Hi-Tech-Enterprises.webp",
    "img/client-logo/Group-251@2x-pvfb7la6kv04jq46abe18pjfn3wejvmt4v5dmwffga.png",
    "img/client-logo/logo_6465a7e5a218a686814273.png"
  ];

  const logos_2 = [
    "img/client-logo/logo6.a40ea70c6ece078011f9.png",
    "img/client-logo/logo7.3c353fc8d04b1caaef0b.png",
    "img/client-logo/logo8.e111aff5213e932fbc4d.png",
    "img/client-logo/logo3.f2a61c8c1e1569296be7.png",
    "img/client-logo/venus_exports_logo.jpg",
    "img/client-logo/sreechandra_logo.png",
    "img/client-logo/sreebogarprojects_logo.png",
    "img/client-logo/thirutravels_logo.png",
    "img/client-logo/alucoatindia_logo.png",
    "img/client-logo/nazaindia-logo.png",
    "img/client-logo/vivimed-logo.png",
    "img/client-logo/Marc_Realty_Logo_grey.svg",
    "img/client-logo/mutharamman_logo.png",
    "img/client-logo/sisha_logo.webp",
    "img/client-logo/bharath-leathers.png",
    "img/client-logo/S&P-logo.png",
    "img/client-logo/offimartbiz-logo-1719449748.jpg",
    "img/client-logo/JD-Constructions-logo.png",
    "img/client-logo/RKC-Constructions-logo.png",
  ];

  const scrollContainerRef1 = useRef(null);
  const scrollContainerRef2 = useRef(null);
  const animationRef1 = useRef(null);
  const animationRef2 = useRef(null);

  const startScrolling = (containerRef, direction, animationRef) => {
    const container = containerRef.current;
    if (!container) return;
  
    let scrollPosition = container.scrollLeft;
    const speed = .5; // Adjust this value to control speed (higher = faster)
  
    const scroll = () => {
      scrollPosition += direction === "left" ? speed : -speed; // Adjust speed here
      container.scrollLeft = scrollPosition;
  
      // Reset for seamless scrolling
      if (scrollPosition >= container.scrollWidth / 2) {
        scrollPosition = 0;
      } else if (scrollPosition <= 0) {
        scrollPosition = container.scrollWidth / 2;
      }
  
      animationRef.current = requestAnimationFrame(scroll);
    };
  
    animationRef.current = requestAnimationFrame(scroll);
  };
  

  const stopScrolling = (animationRef) => {
    cancelAnimationFrame(animationRef.current);
  };

  useEffect(() => {
    startScrolling(scrollContainerRef1, "left", animationRef1);
    startScrolling(scrollContainerRef2, "right", animationRef2);

    return () => {
      stopScrolling(animationRef1);
      stopScrolling(animationRef2);
    }; // Cleanup on component unmount
  }, []);

  const styles = {
    wrapper: {
      padding: "30px 0",
      background: "linear-gradient(90deg, #e3f2fd, #f9fbe7)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "20px",
      overflow: "hidden",
    },
    scrollContainer: {
      display: "flex",
      gap: "20px",
      whiteSpace: "nowrap",
      overflowX: "hidden",
      width: "100%",
    },
    logoItem: {
      flexShrink: 0,
      width: "120px",
      height: "90px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#fff",
      borderRadius: "15px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      overflow: "hidden",
      padding: "10px",
      cursor: "pointer",
      transition: "transform 0.3s ease, box-shadow 0.3s ease",
    },
    logoImage: {
      maxWidth: "100%",
      maxHeight: "100%",
      objectFit: "contain",
    },
  };

  return (
    <div style={styles.wrapper}>
      {/* First Layer */}
      <div
        ref={scrollContainerRef1}
        style={styles.scrollContainer}
        onMouseEnter={() => stopScrolling(animationRef1)}
        onMouseLeave={() => startScrolling(scrollContainerRef1, "left", animationRef1)}
      >
        {logos.concat(logos).map((logo, index) => (
          <div style={styles.logoItem} key={`layer1-${index}`}>
            <img
              src={logo}
              alt={`Customer Logo ${index + 1}`}
              style={styles.logoImage}
            />
          </div>
        ))}
      </div>

      {/* Second Layer */}
      <div
        ref={scrollContainerRef2}
        style={styles.scrollContainer}
        onMouseEnter={() => stopScrolling(animationRef2)}
        onMouseLeave={() => startScrolling(scrollContainerRef2, "right", animationRef2)}
      >
        {logos_2.concat(logos_2).map((logo, index) => (
          <div style={styles.logoItem} key={`layer2-${index}`}>
            <img
              src={logo}
              alt={`Customer Logo ${index + 1}`}
              style={styles.logoImage}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
