import { useEffect, useState } from "react";
import ListLeadComponent from "../../../../Components/v1/FreelancerPartner/Leads/ListLeadComponent";
import FreelancerPartnerNav from "../../../../Components/v1/NavBar/FreelancerPartnerNav";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'; // Ensure you have the CSS for react-tabs
import { useNavigate } from "react-router-dom";
import ViewTaskComponent from "../../../../Components/v1/FreelancerPartner/Leads/ViewTaskComponent";
import ViewSpecificTaskComponent from "../../../../Components/v1/FreelancerPartner/Leads/ViewSpecificTaskComponent";

export default function ViewSpecificTask({routeProps,lableProps,userTypeProps,isClient}) {

  const navigate = useNavigate();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
 
  const [show, setShow] = useState(true);
  const handleToggle = () => { setShow(!show); };


  return (
    <>
      <FreelancerPartnerNav showFullNav={show} onToggle={handleToggle} userTypeProps={userTypeProps} routeProps={routeProps} />
      <ViewSpecificTaskComponent showFullNav={show} lableProps={lableProps} userTypeProps={userTypeProps} isClient={isClient} routeProps={routeProps}/>
    </>
  );
}
