import { ToastContainer, toast } from "react-toastify";

export function showToastErrorMsg(errMsg){
    toast.error(errMsg, {
        position: toast.POSITION.TOP_RIGHT,
        className: "toast-message",
        autoClose: 5000,
    });
}

//success
export function showToastSuccessMsg (successMsg){
    toast.success(successMsg, {
        position: toast.POSITION.TOP_RIGHT,
        className: "toast-message",
        autoClose: 3000,
    });
}